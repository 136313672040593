import { gql } from 'graphql-request'

export const createDefaultTypeCoffeePruningDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_COFFEE_PRUNING_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createDefaultTypeCoffeePruningDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypeCoffeePruningDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_COFFEE_PRUNING_DASHBOARD(
    $defaultTypeCoffeePruningId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultTypeCoffeePruningDashboard(
      defaultTypeCoffeePruningId: $defaultTypeCoffeePruningId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypeCoffeePruningDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_COFFEE_PRUNING_DASHBOARD(
    $defaultTypeCoffeePruningId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypeCoffeePruningDashboard(
      defaultTypeCoffeePruningId: $defaultTypeCoffeePruningId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypeCoffeePruningDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_COFFEE_PRUNING_DASHBOARD(
    $defaultTypeCoffeePruningId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypeCoffeePruningDashboard(
      defaultTypeCoffeePruningId: $defaultTypeCoffeePruningId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypeCoffeePruningDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_COFFEE_PRUNING_DASHBOARD(
    $defaultTypeCoffeePruningId: Int!
  ) {
    deleteForeverDefaultTypeCoffeePruningDashboard(
      defaultTypeCoffeePruningId: $defaultTypeCoffeePruningId
    )
  }
`

export const getDefaultsTypesCoffeesPruningsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_PRUNINGS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesCoffeesPruningsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesCoffeesPruningsContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_PRUNINGS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsTypesCoffeesPruningsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
