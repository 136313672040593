import { createSlice } from '@reduxjs/toolkit'
import { AgriculturalInputType } from 'types/agriculturals-inputs/agriculturals-inputs-types-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  agriculturalInputTypeSelected: null | AgriculturalInputType
  deleteModalOpen: boolean
  agriculturalInputTypeIdToDelete: null | string
  data: AgriculturalInputType[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  agriculturalInputTypeSelected: null,
  agriculturalInputTypeIdToDelete: null,
  data: [],
  total: 0,
}

const agriculturalInputTypeSlice = createSlice({
  initialState,
  name: 'agriculturals-inputs-types-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectAgriculturalInputType(
      state,
      { payload }: { payload: AgriculturalInputType | null },
    ) {
      state.agriculturalInputTypeSelected = payload
    },
    setData(state, { payload }: { payload: AgriculturalInputType[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: AgriculturalInputType }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: AgriculturalInputType }) {
      state.data = state.data.map((agriculturalInputType) => {
        return agriculturalInputType.id === payload.id
          ? { ...agriculturalInputType, ...payload }
          : agriculturalInputType
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (agriculturalInputType) => +agriculturalInputType.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.agriculturalInputTypeIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.agriculturalInputTypeIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectAgriculturalInputType,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = agriculturalInputTypeSlice.actions
export default agriculturalInputTypeSlice.reducer
