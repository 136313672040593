import { gql } from 'graphql-request'

export const createUserTypeDashboardGQL = gql`
  mutation CREATE_USER_TYPE_DASHBOARD($name: String!, $description: String!) {
    createUserTypeDashboard(name: $name, description: $description) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateUserTypeDashboardGQL = gql`
  mutation UPDATE_USER_TYPE_DASHBOARD(
    $userTypeId: Int!
    $name: String
    $description: String
  ) {
    updateUserTypeDashboard(
      userTypeId: $userTypeId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteUserTypeDashboardGQL = gql`
  mutation DELETE_USER_TYPE_DASHBOARD($userTypeId: Int!, $deleted: Boolean!) {
    deleteUserTypeDashboard(userTypeId: $userTypeId, deleted: $deleted) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeUserTypeDashboardGQL = gql`
  mutation ACTIVE_USER_TYPE_DASHBOARD($userTypeId: Int!, $active: Boolean!) {
    activeUserTypeDashboard(userTypeId: $userTypeId, active: $active) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverUserTypeDashboardGQL = gql`
  mutation DELETE_FOREVER_USER_TYPE_DASHBOARD($userTypeId: Int!) {
    deleteForeverUserTypeDashboard(userTypeId: $userTypeId)
  }
`

export const getUsersTypesDashboardGQL = gql`
  query GET_USERS_TYPES_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getUsersTypesDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUsersTypesContainsDashboardGQL = gql`
  query GET_USERS_TYPES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getUsersTypesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
