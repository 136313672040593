import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetOrdersServicesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-orders-services-dashboard'
import { useGetEmployeesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-employees-dashboard'

const schemaFilter = z.object({
  farmId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('orders-services-employees-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('orders-services-employees-farm-field-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  orderServiceId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('orders-services-employees-order-service-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  employeeId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('orders-services-employees-employee-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('orders-services-employees-name')),

  active: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('orders-services-employees-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('orders-services-employees-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    farmId: '',
    farmFieldId: '',
    orderServiceId: '',
    employeeId: '',
    name: '',
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    getValues,
  } = form

  const farmFieldInfo = useGetFarmsFieldsDashboard()
  const farmInfo = useGetFarmsDashboard()
  const orderServiceInfo = useGetOrdersServicesDashboard()
  const employeeInfo = useGetEmployeesDashboard()

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    farmFieldInfo,
    farmInfo,
    orderServiceInfo,
    employeeInfo,
    register,
    resetFields,
    getValues,
  }
}
