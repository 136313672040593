import { graphClient } from 'services/graph-client'
import {
  activeTypeMeasurementDashboardGQL,
  deleteTypeMeasurementDashboardGQL,
  deleteForeverTypeMeasurementDashboardGQL,
  createTypeMeasurementDashboardGQL,
  updateTypeMeasurementDashboardGQL,
} from './queries'
import { TypeMeasurement } from 'types/types-coffees/types-measurements-types'

interface ResponseCreate {
  createTypeMeasurementDashboard: {
    data: TypeMeasurement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeMeasurementDashboard: {
    data: TypeMeasurement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeMeasurementDashboard: {
    data: TypeMeasurement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeMeasurementDashboard: {
    data: TypeMeasurement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeMeasurementDashboard: string
}

interface DataActive {
  typeMeasurementId: number
  active: boolean
}

interface DataDeleted {
  typeMeasurementId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeMeasurementId: number
}

interface DataCreate {
  name: string
  description: string
}

interface DataUpdate {
  typeMeasurementId: number
  name: string
  description: string
}

export const activeTypeMeasurement = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeTypeMeasurementDashboardGQL,
    { ...data },
  )
}

export const deleteTypeMeasurement = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteTypeMeasurementDashboardGQL,
    { ...data },
  )
}

export const deleteForeverTypeMeasurement = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeMeasurementDashboardGQL,
    { ...data },
  )
}

export const createTypeMeasurement = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createTypeMeasurementDashboardGQL,
    { ...data },
  )
}

export const updateTypeMeasurement = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateTypeMeasurementDashboardGQL,
    { ...data },
  )
}
