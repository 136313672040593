import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeCoffeeTreatmentDashboardGQL,
  deleteDefaultTypeCoffeeTreatmentDashboardGQL,
  deleteForeverDefaultTypeCoffeeTreatmentDashboardGQL,
  createDefaultTypeCoffeeTreatmentDashboardGQL,
  updateDefaultTypeCoffeeTreatmentDashboardGQL,
} from './queries'
import { DefaultTypeCoffeeTreatment } from 'types/defaults/defaults-types-coffees-treatments-types'

interface ResponseCreate {
  createDefaultTypeCoffeeTreatmentDashboard: {
    data: DefaultTypeCoffeeTreatment
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeCoffeeTreatmentDashboard: {
    data: DefaultTypeCoffeeTreatment
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeCoffeeTreatmentDashboard: {
    data: DefaultTypeCoffeeTreatment
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeCoffeeTreatmentDashboard: {
    data: DefaultTypeCoffeeTreatment
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeCoffeeTreatmentDashboard: string
}

interface DataActive {
  defaultTypeCoffeeTreatmentId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeCoffeeTreatmentId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeCoffeeTreatmentId: number
}

interface DataCreate {
  name: string
  description?: string
  typeToolId: number
}

interface DataUpdate {
  defaultTypeCoffeeTreatmentId: number
  name: string
  description?: string
  typeToolId: number
}

export const activeDefaultTypeCoffeeTreatment = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeCoffeeTreatmentDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeCoffeeTreatment = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeCoffeeTreatmentDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeCoffeeTreatment = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeCoffeeTreatmentDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeCoffeeTreatment = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeCoffeeTreatmentDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeCoffeeTreatment = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeCoffeeTreatmentDashboardGQL,
    { ...data },
  )
}
