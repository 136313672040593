import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'
import { formatSelectPhoneToFields } from 'helpers/form/format-select-phone-to-fields'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import {
  createCompanyOwner,
  updateCompanyOwner,
} from 'services/settings/companies-owners/companies-owners-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectCompanyOwner,
} from 'slices/settings/companies-owners-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z.string().min(1, t('inform-field') + ' ' + t('companies-owners-name')),

  document: z.string(),
  documentTwo: z.string(),
  documentThree: z.string(),
  email: z
    .string()
    .min(1, t('inform-field') + ' ' + t('companies-owners-email')),

  phone: z
    .object({
      phone: z
        .string()
        .min(1, t('invalid-phone-field') + ' ' + t('companies-owners-phone')),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('companies-owners-phone'),
      path: ['phone'],
    }),
  priorityContact: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('companies-owners-priority-contact')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),
})

export type FormData = z.infer<typeof schema>

const companyOwnerModalFormSelector = (state: StateRoot) => {
  return state.companiesOwnersReducer
}

export const selectorResult = createSelector(
  companyOwnerModalFormSelector,
  (companyOwnerReducer) => {
    return companyOwnerReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, companyOwnerSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('companies_owners')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
    setValue,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectCompanyOwner(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createCompanyOwnerFn, isPending } = useMutation({
    mutationFn: createCompanyOwner,
  })

  const { mutateAsync: updateCompanyOwnerFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateCompanyOwner,
    })

  const cleanFields: FormData = {
    name: '',
    document: '',
    documentTwo: '',
    documentThree: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
    priorityContact: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!companyOwnerSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: companyOwnerSelected.name,
      document: companyOwnerSelected.document,
      documentTwo: companyOwnerSelected.documentTwo,
      documentThree: companyOwnerSelected.documentThree,
      email: companyOwnerSelected.email,
      phone: formatSelectPhoneToFields(companyOwnerSelected.phone),
      priorityContact: `${companyOwnerSelected.priorityContact}`,
    }

    reset(companyOwnerSelected ? fields : cleanFields)
  }, [companyOwnerSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedPriorityContact = formatToSendData(data?.priorityContact)

      const formatedData = {
        name: data?.name,
        document: data?.document,
        documentTwo: data?.documentTwo,
        documentThree: data?.documentThree,
        email: data?.email,
        phone: data?.phone.phone,
        priorityContact: formatInputBooleanValue(selectedPriorityContact),
      }

      if (companyOwnerSelected && companyOwnerSelected?.id) {
        const { updateCompanyOwnerDashboard } = await updateCompanyOwnerFn({
          ...formatedData,
          companyOwnerId: companyOwnerSelected?.id,
        })

        dispatch(editUniqueData(updateCompanyOwnerDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createCompanyOwnerFn(formatedData)

      dispatch(pushData(responseData?.createCompanyOwnerDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    companyOwnerSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    handleSelectCountry,
    resetFields,
  }
}
