import { useAuth } from 'context/use-auth'
import { useUserDetails } from 'lib/hooks/use-user-details'
import { useState } from 'react'

export const useProfileDropdown = () => {
  const { handleSignedOut } = useAuth()

  const { user } = useUserDetails()

  // const history = useNavigate()

  const [isProfileDropdownVisible, setIsProfileDropdownVisible] =
    useState(false)

  const toggleProfileDropdown = () => {
    setIsProfileDropdownVisible((prevstate) => !prevstate)
  }

  const logout = () => {
    handleSignedOut()
    // history('/login')
  }

  return {
    isProfileDropdownVisible,
    user,
    toggleProfileDropdown,
    logout,
  }
}
