import { useEffect, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import { Card, CardHeader } from 'reactstrap'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import { sendFile } from 'lib/send-file'
import toast from 'react-hot-toast'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

interface Props {
  label: string
  value?: string
  shouldLeave?: boolean
  onChange: (value: string) => void
}

export const SendFile = ({ label, shouldLeave, onChange }: Props) => {
  const [files, setFiles] = useState<any>([])

  useEffect(() => {
    if (!shouldLeave) return

    onChange('')
    setFiles([])
  }, [shouldLeave])

  return (
    <Card className="border shadow-none">
      <CardHeader className="d-flex justify-content-between align-items-center ">
        <h4 className="card-title">{label}</h4>
      </CardHeader>

      <div className="p-3">
        <FilePond
          files={files}
          onupdatefiles={setFiles}
          allowMultiple={false}
          name="files"
          className="filepond filepond-input-multiple"
          onremovefile={() => onChange('')}
          server={{
            process: async (fieldName, file, metadata, load) => {
              const data = new FormData()
              data.append('file', file)

              const response = await sendFile(data)
              if (response?.status === 200) {
                toast.success('Enviado com sucesso!')
              }

              load('completed')
            },
          }}
        />
      </div>
    </Card>
  )
}
