import { graphClient } from 'services/graph-client'
import {
  activeHarvestProductionDashboardGQL,
  deleteHarvestProductionDashboardGQL,
  deleteForeverHarvestProductionDashboardGQL,
  createHarvestProductionDashboardGQL,
  updateHarvestProductionDashboardGQL,
} from './queries'
import { HarvestProduction } from 'types/harvests/harvests-productions-types'

interface ResponseCreate {
  createHarvestProductionDashboard: {
    data: HarvestProduction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateHarvestProductionDashboard: {
    data: HarvestProduction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeHarvestProductionDashboard: {
    data: HarvestProduction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteHarvestProductionDashboard: {
    data: HarvestProduction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverHarvestProductionDashboard: string
}

interface DataActive {
  harvestProductionId: number
  active: boolean
}

interface DataDeleted {
  harvestProductionId: number
  deleted: boolean
}

interface DataDeleteForever {
  harvestProductionId: number
}

interface DataCreate {
  farmId?: number
  farmFieldId?: number
  employeeId: number
  name: string
  dateHarvest: string
  measurements: number
  liters: number
}

interface DataUpdate {
  harvestProductionId: number
  farmId?: number
  farmFieldId?: number
  employeeId: number
  name: string
  dateHarvest: string
  measurements: number
  liters: number
}

export const activeHarvestProduction = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeHarvestProductionDashboardGQL,
    { ...data },
  )
}

export const deleteHarvestProduction = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteHarvestProductionDashboardGQL,
    { ...data },
  )
}

export const deleteForeverHarvestProduction = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverHarvestProductionDashboardGQL,
    { ...data },
  )
}

export const createHarvestProduction = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createHarvestProductionDashboardGQL,
    { ...data },
  )
}

export const updateHarvestProduction = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateHarvestProductionDashboardGQL,
    { ...data },
  )
}
