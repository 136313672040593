import { createSlice } from '@reduxjs/toolkit'
import { DefaultTypeCoffeePruning } from 'types/defaults/defaults-types-coffees-prunings-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  defaultTypeCoffeePruningSelected: null | DefaultTypeCoffeePruning
  deleteModalOpen: boolean
  defaultTypeCoffeePruningIdToDelete: null | string
  data: DefaultTypeCoffeePruning[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  defaultTypeCoffeePruningSelected: null,
  defaultTypeCoffeePruningIdToDelete: null,
  data: [],
  total: 0,
}

const defaultTypeCoffeePruningSlice = createSlice({
  initialState,
  name: 'defaults-types-coffees-prunings-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectDefaultTypeCoffeePruning(
      state,
      { payload }: { payload: DefaultTypeCoffeePruning | null },
    ) {
      state.defaultTypeCoffeePruningSelected = payload
    },
    setData(state, { payload }: { payload: DefaultTypeCoffeePruning[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: DefaultTypeCoffeePruning }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: DefaultTypeCoffeePruning }) {
      state.data = state.data.map((defaultTypeCoffeePruning) => {
        return defaultTypeCoffeePruning.id === payload.id
          ? { ...defaultTypeCoffeePruning, ...payload }
          : defaultTypeCoffeePruning
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (defaultTypeCoffeePruning) =>
          +defaultTypeCoffeePruning.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.defaultTypeCoffeePruningIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.defaultTypeCoffeePruningIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectDefaultTypeCoffeePruning,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = defaultTypeCoffeePruningSlice.actions
export default defaultTypeCoffeePruningSlice.reducer
