import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { ControlledInputPhone } from 'components/controlled-components/controlled-input-phone'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetUsersTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-users-types-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  userTypeInfo: ReturnType<typeof useGetUsersTypesDashboard>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  register: UseFormRegister<FormData>
  handleSelectCountry: (country: string) => void
}

export const FormControl = ({
  control,
  errors,
  userTypeInfo,
  farmInfo,
  register,
  handleSelectCountry,
}: Props) => {
  const permissions = useGetPermissions('users')
  const { userSelected } = useSelector(selectorResult)
  const disabled = !!(userSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={userTypeInfo?.usersTypesDashboard}
            info={userTypeInfo}
            label={t('users-user-type-id')}
            name="userTypeId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('users-farm-id')}
            name="farmId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="emailVerified"
            label={t('users-email-verified')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="phoneVerified"
            label={t('users-phone-verified')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="twoFactorEnabled"
            label={t('users-two-factor-enabled')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.firstName?.message}
            {...register('firstName')}
            label={t('users-first-name')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.lastName?.message}
            {...register('lastName')}
            label={t('users-last-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('users-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            type="email"
            error={errors?.email?.message}
            {...register('email')}
            label={t('users-email')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputPhone
            control={control}
            name="phone.phone"
            label={t('users-phone')}
            handleSelectCountry={handleSelectCountry}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.password?.message}
            {...register('password')}
            label={t('users-password')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.photo?.message}
            {...register('photo')}
            label={t('users-photo')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.smsCode?.message}
            {...register('smsCode')}
            label={t('users-sms-code')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            type="email"
            error={errors?.emailCode?.message}
            {...register('emailCode')}
            label={t('users-email-code')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.googleId?.message}
            {...register('googleId')}
            label={t('users-google-id')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.appleId?.message}
            {...register('appleId')}
            label={t('users-apple-id')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.appleToken?.message}
            {...register('appleToken')}
            label={t('users-apple-token')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.country?.message}
            {...register('country')}
            label={t('users-country')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.state?.message}
            {...register('state')}
            label={t('users-state')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.utc?.message}
            {...register('utc')}
            label={t('users-utc')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.language?.message}
            {...register('language')}
            label={t('users-language')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.gender?.message}
            {...register('gender')}
            label={t('users-gender')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="birthday"
            label={t('users-birthday')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
