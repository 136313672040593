import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import { createRole, updateRole } from 'services/users/roles/roles-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectRole,
} from 'slices/users/roles-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  userTypeId: z
    .string()
    .min(1, t('inform-field') + ' ' + t('roles-user-type-id')),

  name: z.string().min(1, t('inform-field') + ' ' + t('roles-name')),

  description: z
    .string()
    .min(1, t('inform-field') + ' ' + t('roles-description')),

  nameIndex: z.string().min(1, t('inform-field') + ' ' + t('roles-name-index')),

  canMenu: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('roles-can-menu')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canAdd: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('roles-can-add')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canDuplicate: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('roles-can-duplicate')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canEdit: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('roles-can-edit')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canDeleteForever: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('roles-can-delete-forever')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canExport: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('roles-can-export')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canImport: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('roles-can-import')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canActive: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('roles-can-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canDeleted: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('roles-can-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),
})

export type FormData = z.infer<typeof schema>

const roleModalFormSelector = (state: StateRoot) => {
  return state.rolesReducer
}

export const selectorResult = createSelector(
  roleModalFormSelector,
  (roleReducer) => {
    return roleReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, roleSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('roles')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectRole(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createRoleFn, isPending } = useMutation({
    mutationFn: createRole,
  })

  const { mutateAsync: updateRoleFn, isPending: isLoadingUpdate } = useMutation(
    {
      mutationFn: updateRole,
    },
  )

  const cleanFields: FormData = {
    userTypeId: '',
    name: '',
    description: '',
    nameIndex: '',
    canMenu: '',
    canAdd: '',
    canDuplicate: '',
    canEdit: '',
    canDeleteForever: '',
    canExport: '',
    canImport: '',
    canActive: '',
    canDeleted: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!roleSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      userTypeId: `${roleSelected.userTypeId}`,
      name: roleSelected.name,
      description: roleSelected.description,
      nameIndex: roleSelected.nameIndex,
      canMenu: `${roleSelected.canMenu}`,
      canAdd: `${roleSelected.canAdd}`,
      canDuplicate: `${roleSelected.canDuplicate}`,
      canEdit: `${roleSelected.canEdit}`,
      canDeleteForever: `${roleSelected.canDeleteForever}`,
      canExport: `${roleSelected.canExport}`,
      canImport: `${roleSelected.canImport}`,
      canActive: `${roleSelected.canActive}`,
      canDeleted: `${roleSelected.canDeleted}`,
    }

    reset(roleSelected ? fields : cleanFields)
  }, [roleSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedCanMenu = formatToSendData(data?.canMenu)
      const selectedCanAdd = formatToSendData(data?.canAdd)
      const selectedCanDuplicate = formatToSendData(data?.canDuplicate)
      const selectedCanEdit = formatToSendData(data?.canEdit)
      const selectedCanDeleteForever = formatToSendData(data?.canDeleteForever)
      const selectedCanExport = formatToSendData(data?.canExport)
      const selectedCanImport = formatToSendData(data?.canImport)
      const selectedCanActive = formatToSendData(data?.canActive)
      const selectedCanDeleted = formatToSendData(data?.canDeleted)

      const formatedData = {
        userTypeId: +data?.userTypeId,
        name: data?.name,
        description: data?.description,
        nameIndex: data?.nameIndex,
        canMenu: formatInputBooleanValue(selectedCanMenu),
        canAdd: formatInputBooleanValue(selectedCanAdd),
        canDuplicate: formatInputBooleanValue(selectedCanDuplicate),
        canEdit: formatInputBooleanValue(selectedCanEdit),
        canDeleteForever: formatInputBooleanValue(selectedCanDeleteForever),
        canExport: formatInputBooleanValue(selectedCanExport),
        canImport: formatInputBooleanValue(selectedCanImport),
        canActive: formatInputBooleanValue(selectedCanActive),
        canDeleted: formatInputBooleanValue(selectedCanDeleted),
      }

      if (roleSelected && roleSelected?.id) {
        const { updateRoleDashboard } = await updateRoleFn({
          ...formatedData,
          roleId: roleSelected?.id,
        })

        dispatch(editUniqueData(updateRoleDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createRoleFn(formatedData)

      dispatch(pushData(responseData?.createRoleDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    roleSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
