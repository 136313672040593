import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeDefaultAgriculturalInputModeAction,
  deleteDefaultAgriculturalInputModeAction,
} from 'services/defaults/defaults-agriculturals-inputs-modes-actions/defaults-agriculturals-inputs-modes-actions-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectDefaultAgriculturalInputModeAction,
} from 'slices/defaults/defaults-agriculturals-inputs-modes-actions-reducer'
import { DefaultAgriculturalInputModeAction } from 'types/defaults/defaults-agriculturals-inputs-modes-actions-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions(
    'defaults_agriculturals_inputs_modes_actions',
  )
  const dispatch = useDispatch()
  const item = cell?.row?.original as DefaultAgriculturalInputModeAction

  const handleOpenForm = () => {
    dispatch(selectDefaultAgriculturalInputModeAction(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(
      selectDefaultAgriculturalInputModeAction(
        itemWithoutId as DefaultAgriculturalInputModeAction,
      ),
    )
    dispatch(openForm())
  }

  const { mutateAsync: deleteDefaultAgriculturalInputModeActionDashboardFn } =
    useMutation({
      mutationFn: deleteDefaultAgriculturalInputModeAction,
    })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteDefaultAgriculturalInputModeActionDashboard } =
      await deleteDefaultAgriculturalInputModeActionDashboardFn({
        defaultAgriculturalInputModeActionId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(
      editUniqueData(deleteDefaultAgriculturalInputModeActionDashboard?.data),
    )
  }

  const { mutateAsync: activeDefaultAgriculturalInputModeActionDashboardFn } =
    useMutation({
      mutationFn: activeDefaultAgriculturalInputModeAction,
    })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeDefaultAgriculturalInputModeActionDashboard } =
      await activeDefaultAgriculturalInputModeActionDashboardFn({
        defaultAgriculturalInputModeActionId: +item?.id,
        active: !item?.active,
      })

    dispatch(
      editUniqueData(activeDefaultAgriculturalInputModeActionDashboard?.data),
    )
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
