import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormDataFilter } from '../modal/filter/use-filter-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetOrdersServicesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-orders-services-dashboard'
import { useGetEmployeesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-employees-dashboard'

interface Props {
  control: Control<FormDataFilter>
  errors: FieldErrors<FormDataFilter>
  farmFieldInfo: ReturnType<typeof useGetFarmsFieldsDashboard>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  orderServiceInfo: ReturnType<typeof useGetOrdersServicesDashboard>
  employeeInfo: ReturnType<typeof useGetEmployeesDashboard>
  register: UseFormRegister<FormDataFilter>
}

export const FormFilterControl = ({
  control,
  errors,
  farmFieldInfo,
  farmInfo,
  orderServiceInfo,
  employeeInfo,
  register,
}: Props) => {
  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmFieldInfo?.farmsFieldsDashboard}
            info={farmFieldInfo}
            label={t('orders-services-employees-farm-field-id')}
            name="farmFieldId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('orders-services-employees-farm-id')}
            name="farmId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={orderServiceInfo?.ordersServicesDashboard}
            info={orderServiceInfo}
            label={t('orders-services-employees-order-service-id')}
            name="orderServiceId"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={employeeInfo?.employeesDashboard}
            info={employeeInfo}
            label={t('orders-services-employees-employee-id')}
            name="employeeId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="active"
            label={t('orders-services-employees-active')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="deleted"
            label={t('orders-services-employees-deleted')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('orders-services-employees-name')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtInitial"
            label={t('orders-services-employees-created-at-initial')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtFinal"
            label={t('orders-services-employees-created-at-final')}
          />
        </Col>
      </Row>
    </Container>
  )
}
