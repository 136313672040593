import { gql } from 'graphql-request'

export const createAgriculturalInputCultivationDashboardGQL = gql`
  mutation CREATE_AGRICULTURAL_INPUT_CULTIVATION_DASHBOARD(
    $agriculturalInputId: Int!
    $typeCultivationId: Int!
    $name: String!
  ) {
    createAgriculturalInputCultivationDashboard(
      agriculturalInputId: $agriculturalInputId
      typeCultivationId: $typeCultivationId
      name: $name
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        typeCultivationId
        typeCultivation {
          id
          name
        }
        agriculturalInputId
        agriculturalInput {
          id
          name
        }
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateAgriculturalInputCultivationDashboardGQL = gql`
  mutation UPDATE_AGRICULTURAL_INPUT_CULTIVATION_DASHBOARD(
    $agriculturalInputCultivationId: Int!
    $agriculturalInputId: Int
    $typeCultivationId: Int
    $name: String
  ) {
    updateAgriculturalInputCultivationDashboard(
      agriculturalInputCultivationId: $agriculturalInputCultivationId
      agriculturalInputId: $agriculturalInputId
      typeCultivationId: $typeCultivationId
      name: $name
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        typeCultivationId
        typeCultivation {
          id
          name
        }
        agriculturalInputId
        agriculturalInput {
          id
          name
        }
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteAgriculturalInputCultivationDashboardGQL = gql`
  mutation DELETE_AGRICULTURAL_INPUT_CULTIVATION_DASHBOARD(
    $agriculturalInputCultivationId: Int!
    $deleted: Boolean!
  ) {
    deleteAgriculturalInputCultivationDashboard(
      agriculturalInputCultivationId: $agriculturalInputCultivationId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        agriculturalInputId
        typeCultivationId
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeAgriculturalInputCultivationDashboardGQL = gql`
  mutation ACTIVE_AGRICULTURAL_INPUT_CULTIVATION_DASHBOARD(
    $agriculturalInputCultivationId: Int!
    $active: Boolean!
  ) {
    activeAgriculturalInputCultivationDashboard(
      agriculturalInputCultivationId: $agriculturalInputCultivationId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        agriculturalInputId
        typeCultivationId
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverAgriculturalInputCultivationDashboardGQL = gql`
  mutation DELETE_FOREVER_AGRICULTURAL_INPUT_CULTIVATION_DASHBOARD(
    $agriculturalInputCultivationId: Int!
  ) {
    deleteForeverAgriculturalInputCultivationDashboard(
      agriculturalInputCultivationId: $agriculturalInputCultivationId
    )
  }
`

export const getAgriculturalsInputsCultivationsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_CULTIVATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $agriculturalInputId: Int
    $typeCultivationId: Int
    $name: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getAgriculturalsInputsCultivationsDashboard(
      take: $take
      skip: $skip
      agriculturalInputId: $agriculturalInputId
      typeCultivationId: $typeCultivationId
      name: $name
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        typeCultivationId
        typeCultivation {
          id
          name
        }
        agriculturalInputId
        agriculturalInput {
          id
          name
        }
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCultivationDashboardIdGQL = gql`
  query GET_TYPE_CULTIVATION_DASHBOARD_ID($typeCultivationId: Int!) {
    getTypeCultivationDashboard(typeCultivationId: $typeCultivationId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCultivationsDashboardGQL = gql`
  query GET_TYPES_CULTIVATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCultivationsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalInputDashboardIdGQL = gql`
  query GET_AGRICULTURAL_INPUT_DASHBOARD_ID($agriculturalInputId: Int!) {
    getAgriculturalInputDashboard(agriculturalInputId: $agriculturalInputId) {
      error
      status
      data {
        id
        companyId
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        name
        description
        tradeMark
        formulation
        activeIngredient
        isPesticide
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getAgriculturalsInputsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        name
        description
        tradeMark
        formulation
        activeIngredient
        isPesticide
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsCultivationsContainsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_CULTIVATIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
  ) {
    getAgriculturalsInputsCultivationsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        typeCultivationId
        typeCultivation {
          id
          name
        }
        agriculturalInputId
        agriculturalInput {
          id
          name
        }
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
