import { gql } from 'graphql-request'

export const createAgriculturalInputTypeDashboardGQL = gql`
  mutation CREATE_AGRICULTURAL_INPUT_TYPE_DASHBOARD(
    $name: String!
    $description: String!
  ) {
    createAgriculturalInputTypeDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateAgriculturalInputTypeDashboardGQL = gql`
  mutation UPDATE_AGRICULTURAL_INPUT_TYPE_DASHBOARD(
    $agriculturalInputTypeId: Int!
    $name: String
    $description: String
  ) {
    updateAgriculturalInputTypeDashboard(
      agriculturalInputTypeId: $agriculturalInputTypeId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteAgriculturalInputTypeDashboardGQL = gql`
  mutation DELETE_AGRICULTURAL_INPUT_TYPE_DASHBOARD(
    $agriculturalInputTypeId: Int!
    $deleted: Boolean!
  ) {
    deleteAgriculturalInputTypeDashboard(
      agriculturalInputTypeId: $agriculturalInputTypeId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeAgriculturalInputTypeDashboardGQL = gql`
  mutation ACTIVE_AGRICULTURAL_INPUT_TYPE_DASHBOARD(
    $agriculturalInputTypeId: Int!
    $active: Boolean!
  ) {
    activeAgriculturalInputTypeDashboard(
      agriculturalInputTypeId: $agriculturalInputTypeId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverAgriculturalInputTypeDashboardGQL = gql`
  mutation DELETE_FOREVER_AGRICULTURAL_INPUT_TYPE_DASHBOARD(
    $agriculturalInputTypeId: Int!
  ) {
    deleteForeverAgriculturalInputTypeDashboard(
      agriculturalInputTypeId: $agriculturalInputTypeId
    )
  }
`

export const getAgriculturalsInputsTypesDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_TYPES_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getAgriculturalsInputsTypesDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsTypesContainsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_TYPES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getAgriculturalsInputsTypesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
