import { graphClient } from 'services/graph-client'
import {
  Company,
  TypeCultivation,
  AgriculturalInput,
  AgriculturalInputCultivation,
} from 'types/agriculturals-inputs/agriculturals-inputs-cultivations-types'

import {
  getCompaniesDashboardGQL,
  getTypesCultivationsDashboardGQL,
  getAgriculturalsInputsDashboardGQL,
  getAgriculturalsInputsCultivationsDashboardGQL,
  getAgriculturalsInputsCultivationsContainsDashboardGQL,
} from './queries'

interface Response {
  getAgriculturalsInputsCultivationsDashboard: {
    data: AgriculturalInputCultivation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getAgriculturalsInputsCultivationsContainsDashboard: {
    data: AgriculturalInputCultivation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
}

export interface GetParams {
  page?: number
  agriculturalInputId?: number
  typeCultivationId?: number
  name?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getAgriculturalsInputsCultivationsDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getAgriculturalsInputsCultivationsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getAgriculturalsInputsCultivationsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getAgriculturalsInputsCultivationsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getAgriculturalsInputsCultivationsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getAgriculturalsInputsCultivationsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseTypeCultivation {
  getTypesCultivationsDashboard: {
    data: TypeCultivation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCultivationsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeCultivation>(
    getTypesCultivationsDashboardGQL,
    { take, skip },
  )
}

interface ResponseAgriculturalInput {
  getAgriculturalsInputsDashboard: {
    data: AgriculturalInput[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAgriculturalsInputsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseAgriculturalInput>(
    getAgriculturalsInputsDashboardGQL,
    { take, skip },
  )
}
