import { createSlice } from '@reduxjs/toolkit'
import { CoffeePruning } from 'types/farms/coffees-prunings-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  coffeePruningSelected: null | CoffeePruning
  deleteModalOpen: boolean
  coffeePruningIdToDelete: null | string
  data: CoffeePruning[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  coffeePruningSelected: null,
  coffeePruningIdToDelete: null,
  data: [],
  total: 0,
}

const coffeePruningSlice = createSlice({
  initialState,
  name: 'coffees-prunings-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectCoffeePruning(state, { payload }: { payload: CoffeePruning | null }) {
      state.coffeePruningSelected = payload
    },
    setData(state, { payload }: { payload: CoffeePruning[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: CoffeePruning }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: CoffeePruning }) {
      state.data = state.data.map((coffeePruning) => {
        return coffeePruning.id === payload.id
          ? { ...coffeePruning, ...payload }
          : coffeePruning
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (coffeePruning) => +coffeePruning.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.coffeePruningIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.coffeePruningIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectCoffeePruning,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = coffeePruningSlice.actions
export default coffeePruningSlice.reducer
