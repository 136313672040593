import { graphClient } from 'services/graph-client'
import {
  activeHarvestCostAgriculturalInputDashboardGQL,
  deleteHarvestCostAgriculturalInputDashboardGQL,
  deleteForeverHarvestCostAgriculturalInputDashboardGQL,
  createHarvestCostAgriculturalInputDashboardGQL,
  updateHarvestCostAgriculturalInputDashboardGQL,
} from './queries'
import { HarvestCostAgriculturalInput } from 'types/harvests/harvests-costs-agriculturals-inputs-types'

interface ResponseCreate {
  createHarvestCostAgriculturalInputDashboard: {
    data: HarvestCostAgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateHarvestCostAgriculturalInputDashboard: {
    data: HarvestCostAgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeHarvestCostAgriculturalInputDashboard: {
    data: HarvestCostAgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteHarvestCostAgriculturalInputDashboard: {
    data: HarvestCostAgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverHarvestCostAgriculturalInputDashboard: string
}

interface DataActive {
  harvestCostAgriculturalInputId: number
  active: boolean
}

interface DataDeleted {
  harvestCostAgriculturalInputId: number
  deleted: boolean
}

interface DataDeleteForever {
  harvestCostAgriculturalInputId: number
}

interface DataCreate {
  farmId?: number
  farmFieldId?: number
  harvestId: number
  name: string
  description?: string
  expectedNumberService?: number
  numberService?: number
  serviceValueApportionment?: number
  valueAgriculturalInput?: number
}

interface DataUpdate {
  harvestCostAgriculturalInputId: number
  farmId?: number
  farmFieldId?: number
  harvestId: number
  name: string
  description?: string
  expectedNumberService?: number
  numberService?: number
  serviceValueApportionment?: number
  valueAgriculturalInput?: number
}

export const activeHarvestCostAgriculturalInput = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeHarvestCostAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const deleteHarvestCostAgriculturalInput = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteHarvestCostAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const deleteForeverHarvestCostAgriculturalInput = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverHarvestCostAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const createHarvestCostAgriculturalInput = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createHarvestCostAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const updateHarvestCostAgriculturalInput = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateHarvestCostAgriculturalInputDashboardGQL,
    { ...data },
  )
}
