import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatInputDateValue } from 'helpers/form/format-input-date-value'
import { formatDateToFields } from 'helpers/form/format-date-to-fields'
import { formatCurrencyToSend } from 'helpers/format/format-currency'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import {
  createOrderService,
  updateOrderService,
} from 'services/orders/orders-services/orders-services-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectOrderService,
} from 'slices/orders/orders-services-reducer'
import { z } from 'zod'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetTypesCoffeesTreatmentsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-treatments-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('orders-services-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('orders-services-farm-field-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeTreatmentId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('orders-services-type-coffee-treatment-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z.string().min(1, t('inform-field') + ' ' + t('orders-services-name')),

  dateService: z.union([z.date(), z.string().min(1)]),

  description: z
    .string()
    .min(1, t('inform-field') + ' ' + t('orders-services-description')),

  optionalDescription: z.string(),
  quantityHectare: z.string(),
  isBrush: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('orders-services-is-brush')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  isPulverization: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('orders-services-is-pulverization')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  isFertilizing: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('orders-services-is-fertilizing')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),
})

export type FormData = z.infer<typeof schema>

const orderServiceModalFormSelector = (state: StateRoot) => {
  return state.ordersServicesReducer
}

export const selectorResult = createSelector(
  orderServiceModalFormSelector,
  (orderServiceReducer) => {
    return orderServiceReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, orderServiceSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('orders_services')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const farmFieldInfo = useGetFarmsFieldsDashboard()
  const farmInfo = useGetFarmsDashboard()
  const typeCoffeeTreatmentInfo = useGetTypesCoffeesTreatmentsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectOrderService(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createOrderServiceFn, isPending } = useMutation({
    mutationFn: createOrderService,
  })

  const { mutateAsync: updateOrderServiceFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateOrderService,
    })

  const cleanFields: FormData = {
    farmId: '',
    farmFieldId: '',
    typeCoffeeTreatmentId: '',
    name: '',
    dateService: '',
    description: '',
    optionalDescription: '',
    quantityHectare: '',
    isBrush: '',
    isPulverization: '',
    isFertilizing: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!orderServiceSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      farmFieldId: formatSelectValueToFields(orderServiceSelected.farmField, [
        'id',
        'name',
      ]),
      farmId: formatSelectValueToFields(orderServiceSelected.farm, [
        'id',
        'name',
      ]),
      typeCoffeeTreatmentId: formatSelectValueToFields(
        orderServiceSelected.typeCoffeeTreatment,
        ['id', 'name'],
      ),
      name: orderServiceSelected.name,
      dateService: formatDateToFields(orderServiceSelected.dateService),
      description: orderServiceSelected.description,
      optionalDescription: orderServiceSelected.optionalDescription,
      quantityHectare: `${orderServiceSelected.quantityHectare}`,
      isBrush: `${orderServiceSelected.isBrush}`,
      isPulverization: `${orderServiceSelected.isPulverization}`,
      isFertilizing: `${orderServiceSelected.isFertilizing}`,
    }

    reset(orderServiceSelected ? fields : cleanFields)
  }, [orderServiceSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedFarmField = +formatToSendData(data?.farmFieldId)
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedTypeCoffeeTreatment = +formatToSendData(
        data?.typeCoffeeTreatmentId,
      )
      const selectedIsBrush = formatToSendData(data?.isBrush)
      const selectedIsPulverization = formatToSendData(data?.isPulverization)
      const selectedIsFertilizing = formatToSendData(data?.isFertilizing)

      const formatedData = {
        farmFieldId: selectedFarmField,
        farmId: selectedFarm,
        typeCoffeeTreatmentId: selectedTypeCoffeeTreatment,
        name: data?.name,
        dateService: formatInputDateValue(data?.dateService),
        description: data?.description,
        optionalDescription: data?.optionalDescription,
        quantityHectare: formatCurrencyToSend(data?.quantityHectare),
        isBrush: formatInputBooleanValue(selectedIsBrush),
        isPulverization: formatInputBooleanValue(selectedIsPulverization),
        isFertilizing: formatInputBooleanValue(selectedIsFertilizing),
      }

      if (orderServiceSelected && orderServiceSelected?.id) {
        const { updateOrderServiceDashboard } = await updateOrderServiceFn({
          ...formatedData,
          orderServiceId: orderServiceSelected?.id,
        })

        dispatch(editUniqueData(updateOrderServiceDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createOrderServiceFn(formatedData)

      dispatch(pushData(responseData?.createOrderServiceDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    orderServiceSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    farmFieldInfo,
    farmInfo,
    typeCoffeeTreatmentInfo,
  }
}
