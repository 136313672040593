import { Polygon } from '@react-google-maps/api'
import { useSelector } from 'react-redux'
import { mapReducer } from '../google-map/map'
import { useRef } from 'react'
import { AreaName } from './area-name'
import { Form } from 'slices/map/map-reducer'

interface Props {
  handleGetCenterPolygon: (area: Form) => google.maps.LatLng
  onPolygonComplete: (polygon: google.maps.Polygon) => void
}

export const Polygons = ({
  handleGetCenterPolygon,
  onPolygonComplete,
}: Props) => {
  const {
    formAreaSelected,
    isAreaControlOpen,
    isEditing,
    areas,
    showResultPolygon,
    forms,
  } = useSelector(mapReducer)

  const polygonRef = useRef<google.maps.Polygon>()

  return (
    <div>
      {formAreaSelected?.name && isEditing && (
        <AreaName
          area={formAreaSelected}
          position={handleGetCenterPolygon(formAreaSelected)}
        />
      )}

      {areas
        .filter((area) => area.id !== formAreaSelected?.id)
        .map((area) => (
          <div key={area.id}>
            {area.name && (
              <AreaName area={area} position={handleGetCenterPolygon(area)} />
            )}

            <Polygon
              paths={area.paths}
              editable={false}
              options={{
                strokeColor: '#fff',
                fillColor: '#fff',
                strokeOpacity: 1,
                fillOpacity: 0.3,
              }}
            />
          </div>
        ))}

      {showResultPolygon &&
        forms.map((form) => (
          <div key={form.id}>
            <AreaName area={form} position={handleGetCenterPolygon(form)} />

            <Polygon
              onLoad={(ref) => (polygonRef.current = ref)}
              paths={form.paths}
              editable={!isAreaControlOpen}
              onMouseUp={() => onPolygonComplete(polygonRef.current!)}
              options={{
                strokeColor: 'yellow',
                fillColor: 'yellow',
                strokeOpacity: 1,
                fillOpacity: 0.3,
              }}
            />
          </div>
        ))}
    </div>
  )
}
