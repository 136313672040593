import React from 'react'
import { Link } from 'react-router-dom'

import logoDark from '../assets/images/logo-dark.png'
import logoLight from '../assets/images/logo-light.png'
import logoSm from '../assets/images/logo-sm.png'

import FullScreenDropdown from '../components/common/full-screen-dropdown'
import LanguageDropdown from '../components/common/language-dropdown'
import LightDark from '../components/common/light-dark'
import MyCartDropdown from '../components/common/my-cart-dropdown'
import NotificationDropdown from '../components/common/notification-dropdown'
import ProfileDropdown from '../components/common/profile-dropdown'
import SearchOption from '../components/common/search-option'
import WebAppsDropdown from '../components/common/web-apps-dropdown'

import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { changeSidebarVisibility } from '../slices/thunks'

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }: any) => {
  const dispatch: any = useDispatch()

  const selectDashboardData = createSelector(
    (state) => state.Layout.sidebarVisibilitytype,
    (sidebarVisibilitytype) => sidebarVisibilitytype,
  )

  const sidebarVisibilitytype = useSelector(selectDashboardData)

  const toogleMenuBtn = () => {
    const windowSize = document.documentElement.clientWidth
    const humberIcon = document.querySelector('.hamburger-icon') as HTMLElement
    dispatch(changeSidebarVisibility('show'))

    if (windowSize > 767) {
      humberIcon.classList.toggle('open')
    }

    if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
      document.body.classList.contains('menu')
        ? document.body.classList.remove('menu')
        : document.body.classList.add('menu')
    }

    if (
      sidebarVisibilitytype === 'show' &&
      (document.documentElement.getAttribute('data-layout') === 'vertical' ||
        document.documentElement.getAttribute('data-layout') === 'semibox')
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable')
        document.documentElement.getAttribute('data-sidebar-size') === 'sm'
          ? document.documentElement.setAttribute('data-sidebar-size', '')
          : document.documentElement.setAttribute('data-sidebar-size', 'sm')
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable')
        document.documentElement.getAttribute('data-sidebar-size') === 'lg'
          ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
          : document.documentElement.setAttribute('data-sidebar-size', 'lg')
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable')
        document.documentElement.setAttribute('data-sidebar-size', 'lg')
      }
    }

    if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
      document.body.classList.contains('twocolumn-panel')
        ? document.body.classList.remove('twocolumn-panel')
        : document.body.classList.add('twocolumn-panel')
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="col-12 col-md-auto ">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="17" />
                  </span>
                </Link>
              </div>

              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              <SearchOption />
            </div>

            <div className="align-items-center d-flex">
              <LanguageDropdown />
              <WebAppsDropdown />
              <MyCartDropdown />
              <FullScreenDropdown />
              <LightDark
                layoutMode={layoutModeType}
                onChangeLayoutMode={onChangeLayoutMode}
              />

              <NotificationDropdown />
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
