import { graphClient } from 'services/graph-client'
import {
  activeOrderServiceAgriculturalInputDashboardGQL,
  deleteOrderServiceAgriculturalInputDashboardGQL,
  deleteForeverOrderServiceAgriculturalInputDashboardGQL,
  createOrderServiceAgriculturalInputDashboardGQL,
  updateOrderServiceAgriculturalInputDashboardGQL,
} from './queries'
import { OrderServiceAgriculturalInput } from 'types/orders/orders-services-agriculturals-inputs-types'

interface ResponseCreate {
  createOrderServiceAgriculturalInputDashboard: {
    data: OrderServiceAgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateOrderServiceAgriculturalInputDashboard: {
    data: OrderServiceAgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeOrderServiceAgriculturalInputDashboard: {
    data: OrderServiceAgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteOrderServiceAgriculturalInputDashboard: {
    data: OrderServiceAgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverOrderServiceAgriculturalInputDashboard: string
}

interface DataActive {
  orderServiceAgriculturalInputId: number
  active: boolean
}

interface DataDeleted {
  orderServiceAgriculturalInputId: number
  deleted: boolean
}

interface DataDeleteForever {
  orderServiceAgriculturalInputId: number
}

interface DataCreate {
  farmId?: number
  farmFieldId?: number
  orderServiceId: number
  agriculturalInputId: number
  name: string
  valueAgriculturalInput: number
  quantityPerPlant: number
  expectedTotalQuantity?: number
  realTotalQuantity?: number
}

interface DataUpdate {
  orderServiceAgriculturalInputId: number
  farmId?: number
  farmFieldId?: number
  orderServiceId: number
  agriculturalInputId: number
  name: string
  valueAgriculturalInput: number
  quantityPerPlant: number
  expectedTotalQuantity?: number
  realTotalQuantity?: number
}

export const activeOrderServiceAgriculturalInput = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeOrderServiceAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const deleteOrderServiceAgriculturalInput = async (
  data: DataDeleted,
) => {
  return await graphClient.request<ResponseDelete>(
    deleteOrderServiceAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const deleteForeverOrderServiceAgriculturalInput = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverOrderServiceAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const createOrderServiceAgriculturalInput = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createOrderServiceAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const updateOrderServiceAgriculturalInput = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateOrderServiceAgriculturalInputDashboardGQL,
    { ...data },
  )
}
