import { useDispatch } from 'react-redux'
import { Col, Button } from 'reactstrap'
import { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { mapReducer } from '../google-map/map'
import {
  Form,
  handleAreaControlOpen,
  handleClearForm,
  handleIsCreating,
  handleIsMap,
  handleRemoveArea,
  handleRemoveForm,
  handleSelectForm,
} from 'slices/map/map-reducer'

interface Props {
  form: Form
}

export const AreaForm = ({ form }: Props) => {
  const nameRef = useRef<HTMLInputElement>(null)
  const { isEditing, areas } = useSelector(mapReducer)
  const dispatch = useDispatch()

  const handleOperation = useCallback(() => {
    const name = nameRef.current?.value ?? ''

    dispatch(handleSelectForm({ ...form, name }))
    dispatch(handleAreaControlOpen(false))
  }, [areas, dispatch])

  const handleDraw = useCallback(() => {
    handleOperation()

    dispatch(handleIsCreating(true))
  }, [dispatch])

  const handleEdit = useCallback(() => {
    handleOperation()
  }, [dispatch])

  const handleRemoveStoraged = useCallback(() => {
    const areas = localStorage.getItem('@areas')
    const lastAreas: Form[] = areas ? JSON.parse(areas) : []

    const newAreas = lastAreas.filter((area) => {
      return area.id !== form.id
    })

    return localStorage.setItem('@areas', JSON.stringify(newAreas))
  }, [form, isEditing])

  const handleDelete = useCallback(() => {
    if (isEditing) {
      dispatch(handleRemoveArea(form))
      handleRemoveStoraged()
      dispatch(handleIsMap(false))
    }

    dispatch(handleRemoveForm(form.id))
    dispatch(handleClearForm())
  }, [form, isEditing, dispatch])

  return (
    <Col className="d-flex flex-row gap-2">
      <input
        id={`form-${form.id}`}
        defaultValue={form.name}
        ref={nameRef}
        type="text"
        placeholder="Nome"
        className="form-control w-50"
      />

      <input
        defaultValue={form.hectare}
        type="text"
        placeholder="Área (ha)"
        disabled
        className="form-control w-25"
      />
      <Button
        onClick={form?.hectare ? handleEdit : handleDraw}
        className={`${form.hectare ? 'bg-warning' : 'bg-success'} border-0`}
      >
        {form.hectare ? 'Editar' : 'Desenhar'}
      </Button>
      <Button onClick={handleDelete} className="bg-primary bg-danger border-0">
        <i className="ri-delete-bin-fill"></i>
      </Button>
    </Col>
  )
}
