import { createSlice } from '@reduxjs/toolkit'
import { HarvestReal } from 'types/harvests/harvests-reals-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  harvestRealSelected: null | HarvestReal
  deleteModalOpen: boolean
  harvestRealIdToDelete: null | string
  data: HarvestReal[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  harvestRealSelected: null,
  harvestRealIdToDelete: null,
  data: [],
  total: 0,
}

const harvestRealSlice = createSlice({
  initialState,
  name: 'harvests-reals-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectHarvestReal(state, { payload }: { payload: HarvestReal | null }) {
      state.harvestRealSelected = payload
    },
    setData(state, { payload }: { payload: HarvestReal[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: HarvestReal }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: HarvestReal }) {
      state.data = state.data.map((harvestReal) => {
        return harvestReal.id === payload.id
          ? { ...harvestReal, ...payload }
          : harvestReal
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (harvestReal) => +harvestReal.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.harvestRealIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.harvestRealIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectHarvestReal,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = harvestRealSlice.actions
export default harvestRealSlice.reducer
