import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import {
  createTypeCoffeeTreatment,
  updateTypeCoffeeTreatment,
} from 'services/types-coffees/types-coffees-treatments/types-coffees-treatments-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectTypeCoffeeTreatment,
} from 'slices/types-coffees/types-coffees-treatments-reducer'
import { z } from 'zod'
import { useGetTypesToolsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-tools-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  typeToolId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('types-coffees-treatments-type-tool-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('types-coffees-treatments-name')),

  description: z.string(),
})

export type FormData = z.infer<typeof schema>

const typeCoffeeTreatmentModalFormSelector = (state: StateRoot) => {
  return state.typesCoffeesTreatmentsReducer
}

export const selectorResult = createSelector(
  typeCoffeeTreatmentModalFormSelector,
  (typeCoffeeTreatmentReducer) => {
    return typeCoffeeTreatmentReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, typeCoffeeTreatmentSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('types_coffees_treatments')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const typeToolInfo = useGetTypesToolsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectTypeCoffeeTreatment(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createTypeCoffeeTreatmentFn, isPending } = useMutation({
    mutationFn: createTypeCoffeeTreatment,
  })

  const {
    mutateAsync: updateTypeCoffeeTreatmentFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateTypeCoffeeTreatment,
  })

  const cleanFields: FormData = {
    typeToolId: '',
    name: '',
    description: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!typeCoffeeTreatmentSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      typeToolId: formatSelectValueToFields(
        typeCoffeeTreatmentSelected.typeTool,
        ['id', 'name'],
      ),
      name: typeCoffeeTreatmentSelected.name,
      description: typeCoffeeTreatmentSelected.description,
    }

    reset(typeCoffeeTreatmentSelected ? fields : cleanFields)
  }, [typeCoffeeTreatmentSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedTypeTool = +formatToSendData(data?.typeToolId)

      const formatedData = {
        typeToolId: selectedTypeTool,
        name: data?.name,
        description: data?.description,
      }

      if (typeCoffeeTreatmentSelected && typeCoffeeTreatmentSelected?.id) {
        const { updateTypeCoffeeTreatmentDashboard } =
          await updateTypeCoffeeTreatmentFn({
            ...formatedData,
            typeCoffeeTreatmentId: typeCoffeeTreatmentSelected?.id,
          })

        dispatch(editUniqueData(updateTypeCoffeeTreatmentDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createTypeCoffeeTreatmentFn(formatedData)

      dispatch(pushData(responseData?.createTypeCoffeeTreatmentDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    typeCoffeeTreatmentSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    typeToolInfo,
  }
}
