import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetEmployeesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-employees-dashboard'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  employeeInfo: ReturnType<typeof useGetEmployeesDashboard>
  farmFieldInfo: ReturnType<typeof useGetFarmsFieldsDashboard>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  register: UseFormRegister<FormData>
}

export const FormControl = ({
  control,
  errors,
  employeeInfo,
  farmFieldInfo,
  farmInfo,
  register,
}: Props) => {
  const permissions = useGetPermissions('employees_days_workeds')
  const { employeeDayWorkedSelected } = useSelector(selectorResult)
  const disabled = !!(employeeDayWorkedSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={employeeInfo?.employeesDashboard}
            info={employeeInfo}
            label={t('employees-days-workeds-employee-id')}
            name="employeeId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmFieldInfo?.farmsFieldsDashboard}
            info={farmFieldInfo}
            label={t('employees-days-workeds-farm-field-id')}
            name="farmFieldId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('employees-days-workeds-farm-id')}
            name="farmId"
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={employeeInfo?.employeesDashboard}
            info={employeeInfo}
            label={t('employees-days-workeds-worked-other-society-employee-id')}
            name="workedOtherSocietyEmployeeId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="holiday"
            label={t('employees-days-workeds-holiday')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="worked"
            label={t('employees-days-workeds-worked')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="workedSociety"
            label={t('employees-days-workeds-worked-society')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="workedOtherSociety"
            label={t('employees-days-workeds-worked-other-society')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="absence"
            label={t('employees-days-workeds-absence')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('employees-days-workeds-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="dateWorked"
            label={t('employees-days-workeds-date-worked')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.reasonAbsence?.message}
            {...register('reasonAbsence')}
            label={t('employees-days-workeds-reason-absence')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
