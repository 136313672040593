import { createSelector } from 'reselect'
import { ExampleTable } from 'types/examples-tables/examples-tables-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverExampleTable } from 'services/examples-tables/examples-tables/examples-tables-cruds'
import {
  getExamplesTablesDashboard,
  GetParams,
  getExamplesTablesContainsDashboard,
} from 'services/examples-tables/examples-tables/examples-tables-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
  openExport,
  closeExport,
} from 'slices/examples-tables/examples-tables-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useColumns } from './components/columns'

const exampleTableListSelector = (state: StateRoot) =>
  state.examplesTablesReducer

const selectorResult = createSelector(
  exampleTableListSelector,
  (exampleTableListReducer) => {
    return exampleTableListReducer
  },
)

export const useExampleTableList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('examples_tables')
  const columns = useColumns()

  const {
    data: dataExampleTable,
    deleteModalOpen,
    exampleTableIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'exampleTable', 'list', { params }],
    queryFn: () => getExamplesTablesDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverExampleTable,
  })

  const handleStoreData = useCallback(
    (examplesTables: ExampleTable[], totalPages: number | null) => {
      if (!examplesTables) return

      dispatch(setData(examplesTables))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverExampleTable = useCallback(async () => {
    if (!exampleTableIdToDelete) return

    const response = await mutateAsync({
      exampleTableId: +exampleTableIdToDelete,
    })

    if (response?.deleteForeverExampleTableDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: exampleTableIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [exampleTableIdToDelete])

  useEffect(() => {
    const examplesTablesResult = data?.getExamplesTablesDashboard

    if (examplesTablesResult) {
      handleStoreData(
        examplesTablesResult?.data,
        examplesTablesResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getExamplesTablesContainsDashboard(value)
      const examplesTablesResult = response?.getExamplesTablesContainsDashboard

      handleStoreData(
        examplesTablesResult?.data,
        examplesTablesResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  const handleOpenExport = () => {
    if (!permissions.canExport) return
    dispatch(openExport())
  }

  const handleCloseExport = () => {
    dispatch(closeExport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    exampleTableIdToDelete,
    exampleTable: data?.getExamplesTablesDashboard?.data,
    isLoading,
    isFetching,
    dataExampleTable,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    columns,
    handleDeleteForeverExampleTable,
    handleOpenImport,
    handleCloseImport,
    handleOpenExport,
    handleCloseExport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
