import { graphClient } from 'services/graph-client'
import { Farm, FarmOwner } from 'types/farms/farms-owners-types'

import {
  getFarmsDashboardGQL,
  getFarmsOwnersDashboardGQL,
  getFarmsOwnersContainsDashboardGQL,
} from './queries'

interface Response {
  getFarmsOwnersDashboard: {
    data: FarmOwner[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getFarmsOwnersContainsDashboard: {
    data: FarmOwner[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  document?: string
  documentTwo?: string
  documentThree?: string
  email?: string
  phone?: string
}

export interface GetParams {
  page?: number
  farmId?: number
  name?: string
  document?: string
  documentTwo?: string
  documentThree?: string
  email?: string
  phone?: string
  priorityContact?: boolean
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getFarmsOwnersDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getFarmsOwnersDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getFarmsOwnersDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getFarmsOwnersDashboardGQL, {
    take,
    skip,
  })
}

export const getFarmsOwnersContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getFarmsOwnersContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseFarm {
  getFarmsDashboard: {
    data: Farm[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarm>(getFarmsDashboardGQL, {
    take,
    skip,
  })
}
