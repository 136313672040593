import { useInfiniteQuery } from '@tanstack/react-query'
import { getAreasDashboardHook } from 'services/_hooks/areas-gets'

export const useGetAreasDashboard = (take = 4) => {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['get', 'areasDashboard', 'list', 'total'],
      initialPageParam: 0,
      queryFn: ({ pageParam }) => {
        return getAreasDashboardHook(pageParam, take)
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const total = lastPage?.getAreasDashboard?.totalPages ?? 4
        const isLastPage = allPages?.length >= total

        if (isLastPage) {
          return null
        }

        return lastPageParam + 1
      },
    })

  const areasDashboard = data?.pages?.flatMap((page) => {
    return page?.getAreasDashboard?.data
  })

  return {
    areasDashboard: areasDashboard ?? [],
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  }
}
