import { gql } from 'graphql-request'

export const createDefaultCoffeePruningDashboardGQL = gql`
  mutation CREATE_DEFAULT_COFFEE_PRUNING_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createDefaultCoffeePruningDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultCoffeePruningDashboardGQL = gql`
  mutation UPDATE_DEFAULT_COFFEE_PRUNING_DASHBOARD(
    $defaultCoffeePruningId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultCoffeePruningDashboard(
      defaultCoffeePruningId: $defaultCoffeePruningId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultCoffeePruningDashboardGQL = gql`
  mutation DELETE_DEFAULT_COFFEE_PRUNING_DASHBOARD(
    $defaultCoffeePruningId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultCoffeePruningDashboard(
      defaultCoffeePruningId: $defaultCoffeePruningId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultCoffeePruningDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_COFFEE_PRUNING_DASHBOARD(
    $defaultCoffeePruningId: Int!
    $active: Boolean!
  ) {
    activeDefaultCoffeePruningDashboard(
      defaultCoffeePruningId: $defaultCoffeePruningId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultCoffeePruningDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_COFFEE_PRUNING_DASHBOARD(
    $defaultCoffeePruningId: Int!
  ) {
    deleteForeverDefaultCoffeePruningDashboard(
      defaultCoffeePruningId: $defaultCoffeePruningId
    )
  }
`

export const getDefaultsCoffeesPruningsDashboardGQL = gql`
  query GET_DEFAULTS_COFFEES_PRUNINGS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsCoffeesPruningsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsCoffeesPruningsContainsDashboardGQL = gql`
  query GET_DEFAULTS_COFFEES_PRUNINGS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsCoffeesPruningsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
