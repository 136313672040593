import { graphClient } from 'services/graph-client'
import {
  activeExampleOtherTwoDashboardGQL,
  deleteExampleOtherTwoDashboardGQL,
  deleteForeverExampleOtherTwoDashboardGQL,
  createExampleOtherTwoDashboardGQL,
  updateExampleOtherTwoDashboardGQL,
} from './queries'
import { ExampleOtherTwo } from 'types/examples-tables/examples-others-twos-types'

interface ResponseCreate {
  createExampleOtherTwoDashboard: {
    data: ExampleOtherTwo
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateExampleOtherTwoDashboard: {
    data: ExampleOtherTwo
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeExampleOtherTwoDashboard: {
    data: ExampleOtherTwo
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteExampleOtherTwoDashboard: {
    data: ExampleOtherTwo
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverExampleOtherTwoDashboard: string
}

interface DataActive {
  exampleOtherTwoId: number
  active: boolean
}

interface DataDeleted {
  exampleOtherTwoId: number
  deleted: boolean
}

interface DataDeleteForever {
  exampleOtherTwoId: number
}

interface DataCreate {
  name: string
}

interface DataUpdate {
  exampleOtherTwoId: number
  name: string
}

export const activeExampleOtherTwo = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeExampleOtherTwoDashboardGQL,
    { ...data },
  )
}

export const deleteExampleOtherTwo = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteExampleOtherTwoDashboardGQL,
    { ...data },
  )
}

export const deleteForeverExampleOtherTwo = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverExampleOtherTwoDashboardGQL,
    { ...data },
  )
}

export const createExampleOtherTwo = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createExampleOtherTwoDashboardGQL,
    { ...data },
  )
}

export const updateExampleOtherTwo = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateExampleOtherTwoDashboardGQL,
    { ...data },
  )
}
