import { gql } from 'graphql-request'

export const createCoffeePruningDashboardGQL = gql`
  mutation CREATE_COFFEE_PRUNING_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createCoffeePruningDashboard(name: $name, description: $description) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateCoffeePruningDashboardGQL = gql`
  mutation UPDATE_COFFEE_PRUNING_DASHBOARD(
    $coffeePruningId: Int!
    $name: String
    $description: String
  ) {
    updateCoffeePruningDashboard(
      coffeePruningId: $coffeePruningId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteCoffeePruningDashboardGQL = gql`
  mutation DELETE_COFFEE_PRUNING_DASHBOARD(
    $coffeePruningId: Int!
    $deleted: Boolean!
  ) {
    deleteCoffeePruningDashboard(
      coffeePruningId: $coffeePruningId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeCoffeePruningDashboardGQL = gql`
  mutation ACTIVE_COFFEE_PRUNING_DASHBOARD(
    $coffeePruningId: Int!
    $active: Boolean!
  ) {
    activeCoffeePruningDashboard(
      coffeePruningId: $coffeePruningId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverCoffeePruningDashboardGQL = gql`
  mutation DELETE_FOREVER_COFFEE_PRUNING_DASHBOARD($coffeePruningId: Int!) {
    deleteForeverCoffeePruningDashboard(coffeePruningId: $coffeePruningId)
  }
`

export const getCoffeesPruningsDashboardGQL = gql`
  query GET_COFFEES_PRUNINGS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getCoffeesPruningsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCoffeesPruningsContainsDashboardGQL = gql`
  query GET_COFFEES_PRUNINGS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getCoffeesPruningsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
