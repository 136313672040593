import { graphClient } from 'services/graph-client'
import { Company, UserType, Farm, User } from 'types/users/users-types'

import {
  getCompaniesDashboardGQL,
  getUsersTypesDashboardGQL,
  getFarmsDashboardGQL,
  getUsersDashboardGQL,
  getUsersContainsDashboardGQL,
} from './queries'

interface Response {
  getUsersDashboard: {
    data: User[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getUsersContainsDashboard: {
    data: User[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  firstName?: string
  lastName?: string
  name?: string
  email?: string
  phone?: string
  password?: string
  photo?: string
  smsCode?: string
  emailCode?: string
  googleId?: string
  appleId?: string
  appleToken?: string
  country?: string
  state?: string
  utc?: string
  language?: string
  gender?: string
}

export interface GetParams {
  page?: number
  userTypeId?: number
  farmId?: number
  firstName?: string
  lastName?: string
  name?: string
  email?: string
  phone?: string
  country?: string
  state?: string
  utc?: string
  language?: string
  gender?: string
  birthday?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getUsersDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getUsersDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getUsersDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getUsersDashboardGQL, {
    take,
    skip,
  })
}

export const getUsersContainsDashboard = async (params: GetContainsParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getUsersContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseUserType {
  getUsersTypesDashboard: {
    data: UserType[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getUsersTypesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseUserType>(
    getUsersTypesDashboardGQL,
    { take, skip },
  )
}

interface ResponseFarm {
  getFarmsDashboard: {
    data: Farm[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarm>(getFarmsDashboardGQL, {
    take,
    skip,
  })
}
