import { Control, FieldPath, FieldValues, useController } from 'react-hook-form'
import { InputComponent } from 'components/common/inputs/input'
import { onlyNumbers } from 'helpers/mask'
import { t } from 'i18next'

interface ControlledProps<T extends FieldValues> {
  control: Control<T>
  name: FieldPath<T>
  label: string
  disabled?: boolean
}

export function ControlledInputNumber<T extends FieldValues>({
  control,
  name,
  label,
  disabled,
}: ControlledProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name })

  return (
    <InputComponent
      error={error?.message}
      value={field.value ?? ''}
      label={t(label)}
      disabled={disabled}
      onChange={(e) => field.onChange(onlyNumbers(e?.target?.value))}
    />
  )
}
