import { graphClient } from 'services/graph-client'
import {
  activeTypeCultivationDashboardGQL,
  deleteTypeCultivationDashboardGQL,
  deleteForeverTypeCultivationDashboardGQL,
  createTypeCultivationDashboardGQL,
  updateTypeCultivationDashboardGQL,
} from './queries'
import { TypeCultivation } from 'types/types-coffees/types-cultivations-types'

interface ResponseCreate {
  createTypeCultivationDashboard: {
    data: TypeCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeCultivationDashboard: {
    data: TypeCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeCultivationDashboard: {
    data: TypeCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeCultivationDashboard: {
    data: TypeCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeCultivationDashboard: string
}

interface DataActive {
  typeCultivationId: number
  active: boolean
}

interface DataDeleted {
  typeCultivationId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeCultivationId: number
}

interface DataCreate {
  name: string
  description: string
}

interface DataUpdate {
  typeCultivationId: number
  name: string
  description: string
}

export const activeTypeCultivation = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeTypeCultivationDashboardGQL,
    { ...data },
  )
}

export const deleteTypeCultivation = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteTypeCultivationDashboardGQL,
    { ...data },
  )
}

export const deleteForeverTypeCultivation = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeCultivationDashboardGQL,
    { ...data },
  )
}

export const createTypeCultivation = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createTypeCultivationDashboardGQL,
    { ...data },
  )
}

export const updateTypeCultivation = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateTypeCultivationDashboardGQL,
    { ...data },
  )
}
