import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeCoffeeManagementDashboardGQL,
  deleteDefaultTypeCoffeeManagementDashboardGQL,
  deleteForeverDefaultTypeCoffeeManagementDashboardGQL,
  createDefaultTypeCoffeeManagementDashboardGQL,
  updateDefaultTypeCoffeeManagementDashboardGQL,
} from './queries'
import { DefaultTypeCoffeeManagement } from 'types/defaults/defaults-types-coffees-managements-types'

interface ResponseCreate {
  createDefaultTypeCoffeeManagementDashboard: {
    data: DefaultTypeCoffeeManagement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeCoffeeManagementDashboard: {
    data: DefaultTypeCoffeeManagement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeCoffeeManagementDashboard: {
    data: DefaultTypeCoffeeManagement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeCoffeeManagementDashboard: {
    data: DefaultTypeCoffeeManagement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeCoffeeManagementDashboard: string
}

interface DataActive {
  defaultTypeCoffeeManagementId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeCoffeeManagementId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeCoffeeManagementId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  defaultTypeCoffeeManagementId: number
  name: string
  description?: string
}

export const activeDefaultTypeCoffeeManagement = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeCoffeeManagementDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeCoffeeManagement = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeCoffeeManagementDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeCoffeeManagement = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeCoffeeManagementDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeCoffeeManagement = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeCoffeeManagementDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeCoffeeManagement = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeCoffeeManagementDashboardGQL,
    { ...data },
  )
}
