import { User } from 'types/auth/login-types'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

interface Props {
  signedIn: boolean
  handleSignedIn: (user: User) => void
  handleSignedOut: () => void
}

const AuthContext = createContext({} as Props)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [signedIn, setSignedIn] = useState(() => {
    const storageUser = localStorage.getItem('@user')

    return !!storageUser
  })

  const handleSignedIn = useCallback((user: User) => {
    setSignedIn(true)
    localStorage.setItem('@user', JSON.stringify(user))
  }, [])

  const handleSignedOut = useCallback(() => {
    setSignedIn(false)
    localStorage.removeItem('@user')
  }, [])

  return (
    <AuthContext.Provider
      value={{
        signedIn,
        handleSignedIn,
        handleSignedOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
