import { createSelector } from 'reselect'
import { DefaultAgriculturalInputCultivation } from 'types/defaults/defaults-agriculturals-inputs-cultivations-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverDefaultAgriculturalInputCultivation } from 'services/defaults/defaults-agriculturals-inputs-cultivations/defaults-agriculturals-inputs-cultivations-cruds'
import {
  getDefaultsAgriculturalsInputsCultivationsDashboard,
  GetParams,
  getDefaultsAgriculturalsInputsCultivationsContainsDashboard,
} from 'services/defaults/defaults-agriculturals-inputs-cultivations/defaults-agriculturals-inputs-cultivations-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/defaults/defaults-agriculturals-inputs-cultivations-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const defaultAgriculturalInputCultivationListSelector = (state: StateRoot) =>
  state.defaultsAgriculturalsInputsCultivationsReducer

const selectorResult = createSelector(
  defaultAgriculturalInputCultivationListSelector,
  (defaultAgriculturalInputCultivationListReducer) => {
    return defaultAgriculturalInputCultivationListReducer
  },
)

export const useDefaultAgriculturalInputCultivationList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions(
    'defaults_agriculturals_inputs_cultivations',
  )

  const {
    data: dataDefaultAgriculturalInputCultivation,
    deleteModalOpen,
    defaultAgriculturalInputCultivationIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: [
      'get',
      'defaultAgriculturalInputCultivation',
      'list',
      { params },
    ],
    queryFn: () => getDefaultsAgriculturalsInputsCultivationsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverDefaultAgriculturalInputCultivation,
  })

  const handleStoreData = useCallback(
    (
      defaultsAgriculturalsInputsCultivations: DefaultAgriculturalInputCultivation[],
      totalPages: number | null,
    ) => {
      if (!defaultsAgriculturalsInputsCultivations) return

      dispatch(setData(defaultsAgriculturalsInputsCultivations))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverDefaultAgriculturalInputCultivation =
    useCallback(async () => {
      if (!defaultAgriculturalInputCultivationIdToDelete) return

      const response = await mutateAsync({
        defaultAgriculturalInputCultivationId:
          +defaultAgriculturalInputCultivationIdToDelete,
      })

      if (
        response?.deleteForeverDefaultAgriculturalInputCultivationDashboard ===
        'deleted_forever'
      ) {
        dispatch(
          deleteUniqueData({
            id: defaultAgriculturalInputCultivationIdToDelete,
          }),
        )
      }

      dispatch(closeDeleteModal())
    }, [defaultAgriculturalInputCultivationIdToDelete])

  useEffect(() => {
    const defaultsAgriculturalsInputsCultivationsResult =
      data?.getDefaultsAgriculturalsInputsCultivationsDashboard

    if (defaultsAgriculturalsInputsCultivationsResult) {
      handleStoreData(
        defaultsAgriculturalsInputsCultivationsResult?.data,
        defaultsAgriculturalsInputsCultivationsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response =
        await getDefaultsAgriculturalsInputsCultivationsContainsDashboard(value)
      const defaultsAgriculturalsInputsCultivationsResult =
        response?.getDefaultsAgriculturalsInputsCultivationsContainsDashboard

      handleStoreData(
        defaultsAgriculturalsInputsCultivationsResult?.data,
        defaultsAgriculturalsInputsCultivationsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    defaultAgriculturalInputCultivationIdToDelete,
    defaultAgriculturalInputCultivation:
      data?.getDefaultsAgriculturalsInputsCultivationsDashboard?.data,
    isLoading,
    isFetching,
    dataDefaultAgriculturalInputCultivation,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverDefaultAgriculturalInputCultivation,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
