import { createSelector } from 'reselect'
import { DefaultAgriculturalInputModeAction } from 'types/defaults/defaults-agriculturals-inputs-modes-actions-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverDefaultAgriculturalInputModeAction } from 'services/defaults/defaults-agriculturals-inputs-modes-actions/defaults-agriculturals-inputs-modes-actions-cruds'
import {
  getDefaultsAgriculturalsInputsModesActionsDashboard,
  GetParams,
  getDefaultsAgriculturalsInputsModesActionsContainsDashboard,
} from 'services/defaults/defaults-agriculturals-inputs-modes-actions/defaults-agriculturals-inputs-modes-actions-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/defaults/defaults-agriculturals-inputs-modes-actions-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const defaultAgriculturalInputModeActionListSelector = (state: StateRoot) =>
  state.defaultsAgriculturalsInputsModesActionsReducer

const selectorResult = createSelector(
  defaultAgriculturalInputModeActionListSelector,
  (defaultAgriculturalInputModeActionListReducer) => {
    return defaultAgriculturalInputModeActionListReducer
  },
)

export const useDefaultAgriculturalInputModeActionList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions(
    'defaults_agriculturals_inputs_modes_actions',
  )

  const {
    data: dataDefaultAgriculturalInputModeAction,
    deleteModalOpen,
    defaultAgriculturalInputModeActionIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'defaultAgriculturalInputModeAction', 'list', { params }],
    queryFn: () => getDefaultsAgriculturalsInputsModesActionsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverDefaultAgriculturalInputModeAction,
  })

  const handleStoreData = useCallback(
    (
      defaultsAgriculturalsInputsModesActions: DefaultAgriculturalInputModeAction[],
      totalPages: number | null,
    ) => {
      if (!defaultsAgriculturalsInputsModesActions) return

      dispatch(setData(defaultsAgriculturalsInputsModesActions))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverDefaultAgriculturalInputModeAction =
    useCallback(async () => {
      if (!defaultAgriculturalInputModeActionIdToDelete) return

      const response = await mutateAsync({
        defaultAgriculturalInputModeActionId:
          +defaultAgriculturalInputModeActionIdToDelete,
      })

      if (
        response?.deleteForeverDefaultAgriculturalInputModeActionDashboard ===
        'deleted_forever'
      ) {
        dispatch(
          deleteUniqueData({
            id: defaultAgriculturalInputModeActionIdToDelete,
          }),
        )
      }

      dispatch(closeDeleteModal())
    }, [defaultAgriculturalInputModeActionIdToDelete])

  useEffect(() => {
    const defaultsAgriculturalsInputsModesActionsResult =
      data?.getDefaultsAgriculturalsInputsModesActionsDashboard

    if (defaultsAgriculturalsInputsModesActionsResult) {
      handleStoreData(
        defaultsAgriculturalsInputsModesActionsResult?.data,
        defaultsAgriculturalsInputsModesActionsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response =
        await getDefaultsAgriculturalsInputsModesActionsContainsDashboard(value)
      const defaultsAgriculturalsInputsModesActionsResult =
        response?.getDefaultsAgriculturalsInputsModesActionsContainsDashboard

      handleStoreData(
        defaultsAgriculturalsInputsModesActionsResult?.data,
        defaultsAgriculturalsInputsModesActionsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    defaultAgriculturalInputModeActionIdToDelete,
    defaultAgriculturalInputModeAction:
      data?.getDefaultsAgriculturalsInputsModesActionsDashboard?.data,
    isLoading,
    isFetching,
    dataDefaultAgriculturalInputModeAction,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverDefaultAgriculturalInputModeAction,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
