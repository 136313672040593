import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormDataFilter } from '../modal/filter/use-filter-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'

interface Props {
  control: Control<FormDataFilter>
  errors: FieldErrors<FormDataFilter>
  register: UseFormRegister<FormDataFilter>
}

export const FormFilterControl = ({ control, errors, register }: Props) => {
  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="installationDevice"
            label={t('users-devices-informations-installation-device')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="active"
            label={t('users-devices-informations-active')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="deleted"
            label={t('users-devices-informations-deleted')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('users-devices-informations-name')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.manufacturer?.message}
            {...register('manufacturer')}
            label={t('users-devices-informations-manufacturer')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.mac?.message}
            {...register('mac')}
            label={t('users-devices-informations-mac')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.baseOs?.message}
            {...register('baseOs')}
            label={t('users-devices-informations-base-os')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.bootLoader?.message}
            {...register('bootLoader')}
            label={t('users-devices-informations-boot-loader')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.brand?.message}
            {...register('brand')}
            label={t('users-devices-informations-brand')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.buildNumber?.message}
            {...register('buildNumber')}
            label={t('users-devices-informations-build-number')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.isCameraPresent?.message}
            {...register('isCameraPresent')}
            label={t('users-devices-informations-is-camera-present')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.networkOperator?.message}
            {...register('networkOperator')}
            label={t('users-devices-informations-network-operator')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.deviceId?.message}
            {...register('deviceId')}
            label={t('users-devices-informations-device-id')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.deviceName?.message}
            {...register('deviceName')}
            label={t('users-devices-informations-device-name')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.ipAddress?.message}
            {...register('ipAddress')}
            label={t('users-devices-informations-ip-address')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.systemVersion?.message}
            {...register('systemVersion')}
            label={t('users-devices-informations-system-version')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.uniqueId?.message}
            {...register('uniqueId')}
            label={t('users-devices-informations-unique-id')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.userAgent?.message}
            {...register('userAgent')}
            label={t('users-devices-informations-user-agent')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtInitial"
            label={t('users-devices-informations-created-at-initial')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtFinal"
            label={t('users-devices-informations-created-at-final')}
          />
        </Col>
      </Row>
    </Container>
  )
}
