import { graphClient } from 'services/graph-client'
import {
  activeDefaultAgriculturalInputModeActionDashboardGQL,
  deleteDefaultAgriculturalInputModeActionDashboardGQL,
  deleteForeverDefaultAgriculturalInputModeActionDashboardGQL,
  createDefaultAgriculturalInputModeActionDashboardGQL,
  updateDefaultAgriculturalInputModeActionDashboardGQL,
} from './queries'
import { DefaultAgriculturalInputModeAction } from 'types/defaults/defaults-agriculturals-inputs-modes-actions-types'

interface ResponseCreate {
  createDefaultAgriculturalInputModeActionDashboard: {
    data: DefaultAgriculturalInputModeAction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultAgriculturalInputModeActionDashboard: {
    data: DefaultAgriculturalInputModeAction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultAgriculturalInputModeActionDashboard: {
    data: DefaultAgriculturalInputModeAction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultAgriculturalInputModeActionDashboard: {
    data: DefaultAgriculturalInputModeAction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultAgriculturalInputModeActionDashboard: string
}

interface DataActive {
  defaultAgriculturalInputModeActionId: number
  active: boolean
}

interface DataDeleted {
  defaultAgriculturalInputModeActionId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultAgriculturalInputModeActionId: number
}

interface DataCreate {
  name: string
  description: string
}

interface DataUpdate {
  defaultAgriculturalInputModeActionId: number
  name: string
  description: string
}

export const activeDefaultAgriculturalInputModeAction = async (
  data: DataActive,
) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultAgriculturalInputModeActionDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultAgriculturalInputModeAction = async (
  data: DataDeleted,
) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultAgriculturalInputModeActionDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultAgriculturalInputModeAction = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultAgriculturalInputModeActionDashboardGQL,
    { ...data },
  )
}

export const createDefaultAgriculturalInputModeAction = async (
  data: DataCreate,
) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultAgriculturalInputModeActionDashboardGQL,
    { ...data },
  )
}

export const updateDefaultAgriculturalInputModeAction = async (
  data: DataUpdate,
) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultAgriculturalInputModeActionDashboardGQL,
    { ...data },
  )
}
