import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetAgriculturalsInputsSubsTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-subs-types-dashboard'
import { useGetAgriculturalsInputsModesActionsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-modes-actions-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  agriculturalInputSubTypeInfo: ReturnType<
    typeof useGetAgriculturalsInputsSubsTypesDashboard
  >
  agriculturalInputModeActionInfo: ReturnType<
    typeof useGetAgriculturalsInputsModesActionsDashboard
  >
  register: UseFormRegister<FormData>
}

export const FormControl = ({
  control,
  errors,
  agriculturalInputSubTypeInfo,
  agriculturalInputModeActionInfo,
  register,
}: Props) => {
  const permissions = useGetPermissions('agriculturals_inputs')
  const { agriculturalInputSelected } = useSelector(selectorResult)
  const disabled = !!(agriculturalInputSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={
              agriculturalInputSubTypeInfo?.agriculturalsInputsSubsTypesDashboard
            }
            info={agriculturalInputSubTypeInfo}
            label={t('agriculturals-inputs-agricultural-input-sub-type-id')}
            name="agriculturalInputSubTypeId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={
              agriculturalInputModeActionInfo?.agriculturalsInputsModesActionsDashboard
            }
            info={agriculturalInputModeActionInfo}
            label={t('agriculturals-inputs-agricultural-input-mode-action-id')}
            name="agriculturalInputModeActionId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isPesticide"
            label={t('agriculturals-inputs-is-pesticide')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isOrganic"
            label={t('agriculturals-inputs-is-organic')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isFertilizer"
            label={t('agriculturals-inputs-is-fertilizer')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('agriculturals-inputs-name')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('agriculturals-inputs-description')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.tradeMark?.message}
            {...register('tradeMark')}
            label={t('agriculturals-inputs-trade-mark')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.formulation?.message}
            {...register('formulation')}
            label={t('agriculturals-inputs-formulation')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.activeIngredient?.message}
            {...register('activeIngredient')}
            label={t('agriculturals-inputs-active-ingredient')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.toxicologicalClass?.message}
            {...register('toxicologicalClass')}
            label={t('agriculturals-inputs-toxicological-class')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.environmentalClass?.message}
            {...register('environmentalClass')}
            label={t('agriculturals-inputs-environmental-class')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
