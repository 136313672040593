import { Info } from 'components/controlled-components/controlled-select'
import { useCallback, useEffect, useRef, useState } from 'react'
import Select, { components, MenuListProps } from 'react-select'
import SimpleBar from 'simplebar-react'

export interface Input {
  label: string
  value: string
}

interface Props {
  minWidth: number
  value: string | Input
  label?: string
  options: { options: Input[] }[]
  loading?: boolean
  error?: string
  info?: Info
  disabled?: boolean
  onChange: (value: Input) => void
}

export const SelectComponent = ({
  minWidth,
  value,
  options,
  label,
  loading,
  error,
  info,
  disabled,
  onChange,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const simplebarRef = useRef<null | HTMLDivElement>(null)
  const divRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (!divRef.current || !simplebarRef.current) return

    const observer = new IntersectionObserver(
      (entries, obs) => {
        const { isIntersecting } = entries[0]

        if (!info?.hasNextPage) {
          obs.disconnect()
          return
        }

        if (isIntersecting && !info.isFetchingNextPage) {
          info.fetchNextPage()
        }
      },
      { root: simplebarRef.current },
    )

    observer.observe(divRef.current)

    return () => {
      observer.disconnect()
    }
  }, [
    isMenuOpen,
    info?.hasNextPage,
    info?.isFetchingNextPage,
    info?.fetchNextPage,
  ])

  const MenuList = useCallback(
    (props: MenuListProps<string | Input>) => (
      <components.MenuList {...props}>
        <SimpleBar
          scrollableNodeProps={{ ref: simplebarRef }}
          style={{ height: '100%' }}
        >
          {props.children}
          <div style={{ height: 1, width: 1 }} ref={divRef} />
        </SimpleBar>
      </components.MenuList>
    ),
    [],
  )

  return (
    <div style={{ minWidth: minWidth }}>
      <h6 className="fw-semibold">{label}</h6>

      <Select
        menuIsOpen={isMenuOpen}
        value={value}
        options={options}
        isLoading={loading}
        classNamePrefix="select-component"
        className="select-component-test"
        captureMenuScroll={false}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        isDisabled={disabled}
        onChange={(option) => {
          onChange(option as Input)
        }}
        components={{ MenuList: MenuList }}
        styles={{
          menuList: (baseStyles: object) => ({
            ...baseStyles,
            overflow: 'hidden',
            height: 150,
          }),
        }}
      />

      {error && <span style={{ color: 'red', fontSize: 12 }}>{error}</span>}
    </div>
  )
}
