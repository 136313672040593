import { gql } from 'graphql-request'

export const createHarvestDashboardGQL = gql`
  mutation CREATE_HARVEST_DASHBOARD(
    $farmId: Int
    $farmFieldId: Int
    $typeCultivationId: Int!
    $typeMeasurementId: Int!
    $name: String!
    $description: String
    $automaticCost: Boolean!
    $dateHarvestInitial: String!
    $dateHarvestFinal: String!
  ) {
    createHarvestDashboard(
      farmId: $farmId
      farmFieldId: $farmFieldId
      typeCultivationId: $typeCultivationId
      typeMeasurementId: $typeMeasurementId
      name: $name
      description: $description
      automaticCost: $automaticCost
      dateHarvestInitial: $dateHarvestInitial
      dateHarvestFinal: $dateHarvestFinal
    ) {
      error
      status
      data {
        id
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCultivationId
        typeCultivation {
          id
          name
        }
        typeMeasurementId
        typeMeasurement {
          id
          name
        }
        name
        description
        automaticCost
        active
        deleted
        dateHarvestInitial
        dateHarvestFinal
        createdAt
        updatedAt
      }
    }
  }
`

export const updateHarvestDashboardGQL = gql`
  mutation UPDATE_HARVEST_DASHBOARD(
    $harvestId: Int!
    $farmId: Int
    $farmFieldId: Int
    $typeCultivationId: Int
    $typeMeasurementId: Int
    $name: String
    $description: String
    $automaticCost: Boolean
    $dateHarvestInitial: String
    $dateHarvestFinal: String
  ) {
    updateHarvestDashboard(
      harvestId: $harvestId
      farmId: $farmId
      farmFieldId: $farmFieldId
      typeCultivationId: $typeCultivationId
      typeMeasurementId: $typeMeasurementId
      name: $name
      description: $description
      automaticCost: $automaticCost
      dateHarvestInitial: $dateHarvestInitial
      dateHarvestFinal: $dateHarvestFinal
    ) {
      error
      status
      data {
        id
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCultivationId
        typeCultivation {
          id
          name
        }
        typeMeasurementId
        typeMeasurement {
          id
          name
        }
        name
        description
        automaticCost
        active
        deleted
        dateHarvestInitial
        dateHarvestFinal
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteHarvestDashboardGQL = gql`
  mutation DELETE_HARVEST_DASHBOARD($harvestId: Int!, $deleted: Boolean!) {
    deleteHarvestDashboard(harvestId: $harvestId, deleted: $deleted) {
      error
      status
      data {
        id
        farmId
        farmFieldId
        typeCultivationId
        typeMeasurementId
        name
        description
        automaticCost
        active
        deleted
        dateHarvestInitial
        dateHarvestFinal
        createdAt
        updatedAt
      }
    }
  }
`

export const activeHarvestDashboardGQL = gql`
  mutation ACTIVE_HARVEST_DASHBOARD($harvestId: Int!, $active: Boolean!) {
    activeHarvestDashboard(harvestId: $harvestId, active: $active) {
      error
      status
      data {
        id
        farmId
        farmFieldId
        typeCultivationId
        typeMeasurementId
        name
        description
        automaticCost
        active
        deleted
        dateHarvestInitial
        dateHarvestFinal
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverHarvestDashboardGQL = gql`
  mutation DELETE_FOREVER_HARVEST_DASHBOARD($harvestId: Int!) {
    deleteForeverHarvestDashboard(harvestId: $harvestId)
  }
`

export const getHarvestsDashboardGQL = gql`
  query GET_HARVESTS_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $farmFieldId: Int
    $typeCultivationId: Int
    $typeMeasurementId: Int
    $name: String
    $description: String
    $automaticCost: Boolean
    $dateHarvestInitial: String
    $dateHarvestFinal: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getHarvestsDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      farmFieldId: $farmFieldId
      typeCultivationId: $typeCultivationId
      typeMeasurementId: $typeMeasurementId
      name: $name
      description: $description
      automaticCost: $automaticCost
      dateHarvestInitial: $dateHarvestInitial
      dateHarvestFinal: $dateHarvestFinal
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCultivationId
        typeCultivation {
          id
          name
        }
        typeMeasurementId
        typeMeasurement {
          id
          name
        }
        name
        description
        automaticCost
        active
        deleted
        dateHarvestInitial
        dateHarvestFinal
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmFieldDashboardIdGQL = gql`
  query GET_FARM_FIELD_DASHBOARD_ID($farmFieldId: Int!) {
    getFarmFieldDashboard(farmFieldId: $farmFieldId) {
      error
      status
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsFieldsDashboardGQL = gql`
  query GET_FARMS_FIELDS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsFieldsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCultivationDashboardIdGQL = gql`
  query GET_TYPE_CULTIVATION_DASHBOARD_ID($typeCultivationId: Int!) {
    getTypeCultivationDashboard(typeCultivationId: $typeCultivationId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCultivationsDashboardGQL = gql`
  query GET_TYPES_CULTIVATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCultivationsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeMeasurementDashboardIdGQL = gql`
  query GET_TYPE_MEASUREMENT_DASHBOARD_ID($typeMeasurementId: Int!) {
    getTypeMeasurementDashboard(typeMeasurementId: $typeMeasurementId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesMeasurementsDashboardGQL = gql`
  query GET_TYPES_MEASUREMENTS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesMeasurementsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getHarvestsContainsDashboardGQL = gql`
  query GET_HARVESTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getHarvestsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCultivationId
        typeCultivation {
          id
          name
        }
        typeMeasurementId
        typeMeasurement {
          id
          name
        }
        name
        description
        automaticCost
        active
        deleted
        dateHarvestInitial
        dateHarvestFinal
        createdAt
        updatedAt
      }
    }
  }
`
