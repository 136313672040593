import { useCallback, useEffect, useRef } from 'react'
import { center, options } from './config'
import { useJsApiLoader } from '@react-google-maps/api'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { mapReducer } from './map'
import {
  Form,
  handleAreaControlOpen,
  handleClearForm,
  handleIsCreating,
  handleSetAreaInfo,
  handleShowResultPolygon,
} from 'slices/map/map-reducer'

export const useMap = () => {
  const dispatch = useDispatch()
  const { isLoaded } = useJsApiLoader(options)

  const {
    showResultPolygon,
    formAreaSelected,
    isAreaControlOpen,
    isEditing,
    isCreating,
  } = useSelector(mapReducer)

  const searchBoxRef = useRef<google.maps.places.SearchBox>()
  const polygonRef = useRef<google.maps.Polygon>()
  const mapRef = useRef<google.maps.Map>()

  const onLoadMap = useCallback(
    (map: google.maps.Map) => {
      if (formAreaSelected?.paths) {
        map.setCenter(handleGetCenterPolygon(formAreaSelected))
      } else {
        map.setCenter(center)
      }

      mapRef.current = map
    },
    [formAreaSelected],
  )

  const onLoadSearch = useCallback((input: google.maps.places.SearchBox) => {
    searchBoxRef.current = input
  }, [])

  const onPolygonComplete = useCallback((polygon: google.maps.Polygon) => {
    dispatch(handleIsCreating(false))

    polygonRef.current = polygon
  }, [])

  const handleSaveEdit = useCallback(() => {
    const polygon = polygonRef.current
    const path = polygon?.getPath()

    if (path) {
      const areaLength = google.maps.geometry.spherical.computeArea(path)
      const hec = areaLength / 10_000

      const params = {
        hectare: hec.toFixed(2),
        paths: path?.getArray(),
      }

      dispatch(handleSetAreaInfo(params))
    }

    dispatch(handleShowResultPolygon(true))
    dispatch(handleAreaControlOpen(true))
  }, [polygonRef, isAreaControlOpen, isCreating, dispatch])

  useEffect(() => {
    const polygon = polygonRef.current
    if (isEditing) return

    polygon?.setVisible(false)
  }, [isAreaControlOpen, isEditing])

  const handleCancelEdit = useCallback(() => {
    if (isEditing) {
      const paths = formAreaSelected?.paths as google.maps.LatLngLiteral[]
      polygonRef.current?.setPath(paths)
      return dispatch(handleAreaControlOpen(true))
    }

    dispatch(handleClearForm())
    dispatch(handleAreaControlOpen(true))
    dispatch(handleIsCreating(false))
    polygonRef.current?.setPath([])
  }, [isEditing, formAreaSelected, dispatch])

  const handleGetCenterPolygon = useCallback(
    (area?: Form) => {
      const currentArea = area ?? formAreaSelected
      const bounds = new google.maps.LatLngBounds()
      currentArea?.paths?.forEach((areaPath) => {
        bounds.extend(areaPath)
      })
      return bounds.getCenter()
    },
    [formAreaSelected],
  )

  const handlePlacesChanged = useCallback(() => {
    const place = searchBoxRef.current?.getPlaces()?.[0]

    const lat = place?.geometry?.location?.lat()
    const lng = place?.geometry?.location?.lng()

    if (lat && lng) {
      mapRef.current?.setCenter({ lat, lng })
    }
  }, [searchBoxRef])

  return {
    isLoaded,
    polygonRef,
    showResultPolygon,
    formAreaSelected,
    isAreaControlOpen,
    isEditing,
    isCreating,
    onLoadMap,
    handlePlacesChanged,
    handleCancelEdit,
    handleSaveEdit,
    dispatch,
    onLoadSearch,
    onPolygonComplete,
    handleGetCenterPolygon,
  }
}
