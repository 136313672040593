import { gql } from 'graphql-request'

export const createExampleOtherTwoDashboardGQL = gql`
  mutation CREATE_EXAMPLE_OTHER_TWO_DASHBOARD($name: String!) {
    createExampleOtherTwoDashboard(name: $name) {
      error
      status
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateExampleOtherTwoDashboardGQL = gql`
  mutation UPDATE_EXAMPLE_OTHER_TWO_DASHBOARD(
    $exampleOtherTwoId: Int!
    $name: String
  ) {
    updateExampleOtherTwoDashboard(
      exampleOtherTwoId: $exampleOtherTwoId
      name: $name
    ) {
      error
      status
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteExampleOtherTwoDashboardGQL = gql`
  mutation DELETE_EXAMPLE_OTHER_TWO_DASHBOARD(
    $exampleOtherTwoId: Int!
    $deleted: Boolean!
  ) {
    deleteExampleOtherTwoDashboard(
      exampleOtherTwoId: $exampleOtherTwoId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeExampleOtherTwoDashboardGQL = gql`
  mutation ACTIVE_EXAMPLE_OTHER_TWO_DASHBOARD(
    $exampleOtherTwoId: Int!
    $active: Boolean!
  ) {
    activeExampleOtherTwoDashboard(
      exampleOtherTwoId: $exampleOtherTwoId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverExampleOtherTwoDashboardGQL = gql`
  mutation DELETE_FOREVER_EXAMPLE_OTHER_TWO_DASHBOARD(
    $exampleOtherTwoId: Int!
  ) {
    deleteForeverExampleOtherTwoDashboard(exampleOtherTwoId: $exampleOtherTwoId)
  }
`

export const getExamplesOthersTwosDashboardGQL = gql`
  query GET_EXAMPLES_OTHERS_TWOS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getExamplesOthersTwosDashboard(
      take: $take
      skip: $skip
      name: $name
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getExamplesOthersTwosContainsDashboardGQL = gql`
  query GET_EXAMPLES_OTHERS_TWOS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
  ) {
    getExamplesOthersTwosContainsDashboard(
      take: $take
      skip: $skip
      name: $name
    ) {
      error
      status
      totalPages
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
