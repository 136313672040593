import { gql } from 'graphql-request'

export const createDefaultTypeCoffeeVarietyDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_COFFEE_VARIETY_DASHBOARD(
    $name: String!
    $description: String
    $numbering: String
  ) {
    createDefaultTypeCoffeeVarietyDashboard(
      name: $name
      description: $description
      numbering: $numbering
    ) {
      error
      status
      data {
        id
        name
        description
        numbering
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypeCoffeeVarietyDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_COFFEE_VARIETY_DASHBOARD(
    $defaultTypeCoffeeVarietyId: Int!
    $name: String
    $description: String
    $numbering: String
  ) {
    updateDefaultTypeCoffeeVarietyDashboard(
      defaultTypeCoffeeVarietyId: $defaultTypeCoffeeVarietyId
      name: $name
      description: $description
      numbering: $numbering
    ) {
      error
      status
      data {
        id
        name
        description
        numbering
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypeCoffeeVarietyDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_COFFEE_VARIETY_DASHBOARD(
    $defaultTypeCoffeeVarietyId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypeCoffeeVarietyDashboard(
      defaultTypeCoffeeVarietyId: $defaultTypeCoffeeVarietyId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        numbering
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypeCoffeeVarietyDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_COFFEE_VARIETY_DASHBOARD(
    $defaultTypeCoffeeVarietyId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypeCoffeeVarietyDashboard(
      defaultTypeCoffeeVarietyId: $defaultTypeCoffeeVarietyId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        numbering
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypeCoffeeVarietyDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_COFFEE_VARIETY_DASHBOARD(
    $defaultTypeCoffeeVarietyId: Int!
  ) {
    deleteForeverDefaultTypeCoffeeVarietyDashboard(
      defaultTypeCoffeeVarietyId: $defaultTypeCoffeeVarietyId
    )
  }
`

export const getDefaultsTypesCoffeesVarietiesDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_VARIETIES_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $numbering: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesCoffeesVarietiesDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      numbering: $numbering
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        numbering
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesCoffeesVarietiesContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_VARIETIES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $numbering: String
  ) {
    getDefaultsTypesCoffeesVarietiesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      numbering: $numbering
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        numbering
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
