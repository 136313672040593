import { graphClient } from 'services/graph-client'
import { ExampleOtherOne } from 'types/examples-tables/examples-others-ones-types'

import { getExamplesOthersOnesDashboardGQL } from 'services/examples-tables/examples-others-ones/queries'

interface Response {
  getExamplesOthersOnesDashboard: {
    data: ExampleOtherOne[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getExamplesOthersOnesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getExamplesOthersOnesDashboardGQL,
    {
      take,
      skip,
    },
  )
}
