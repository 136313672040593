import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeCoffeeRenovationDashboardGQL,
  deleteDefaultTypeCoffeeRenovationDashboardGQL,
  deleteForeverDefaultTypeCoffeeRenovationDashboardGQL,
  createDefaultTypeCoffeeRenovationDashboardGQL,
  updateDefaultTypeCoffeeRenovationDashboardGQL,
} from './queries'
import { DefaultTypeCoffeeRenovation } from 'types/defaults/defaults-types-coffees-renovations-types'

interface ResponseCreate {
  createDefaultTypeCoffeeRenovationDashboard: {
    data: DefaultTypeCoffeeRenovation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeCoffeeRenovationDashboard: {
    data: DefaultTypeCoffeeRenovation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeCoffeeRenovationDashboard: {
    data: DefaultTypeCoffeeRenovation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeCoffeeRenovationDashboard: {
    data: DefaultTypeCoffeeRenovation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeCoffeeRenovationDashboard: string
}

interface DataActive {
  defaultTypeCoffeeRenovationId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeCoffeeRenovationId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeCoffeeRenovationId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  defaultTypeCoffeeRenovationId: number
  name: string
  description?: string
}

export const activeDefaultTypeCoffeeRenovation = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeCoffeeRenovationDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeCoffeeRenovation = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeCoffeeRenovationDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeCoffeeRenovation = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeCoffeeRenovationDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeCoffeeRenovation = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeCoffeeRenovationDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeCoffeeRenovation = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeCoffeeRenovationDashboardGQL,
    { ...data },
  )
}
