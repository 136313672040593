import { gql } from 'graphql-request'

export const createTypeMeasurementDashboardGQL = gql`
  mutation CREATE_TYPE_MEASUREMENT_DASHBOARD(
    $name: String!
    $description: String!
  ) {
    createTypeMeasurementDashboard(name: $name, description: $description) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateTypeMeasurementDashboardGQL = gql`
  mutation UPDATE_TYPE_MEASUREMENT_DASHBOARD(
    $typeMeasurementId: Int!
    $name: String
    $description: String
  ) {
    updateTypeMeasurementDashboard(
      typeMeasurementId: $typeMeasurementId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteTypeMeasurementDashboardGQL = gql`
  mutation DELETE_TYPE_MEASUREMENT_DASHBOARD(
    $typeMeasurementId: Int!
    $deleted: Boolean!
  ) {
    deleteTypeMeasurementDashboard(
      typeMeasurementId: $typeMeasurementId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeTypeMeasurementDashboardGQL = gql`
  mutation ACTIVE_TYPE_MEASUREMENT_DASHBOARD(
    $typeMeasurementId: Int!
    $active: Boolean!
  ) {
    activeTypeMeasurementDashboard(
      typeMeasurementId: $typeMeasurementId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverTypeMeasurementDashboardGQL = gql`
  mutation DELETE_FOREVER_TYPE_MEASUREMENT_DASHBOARD($typeMeasurementId: Int!) {
    deleteForeverTypeMeasurementDashboard(typeMeasurementId: $typeMeasurementId)
  }
`

export const getTypesMeasurementsDashboardGQL = gql`
  query GET_TYPES_MEASUREMENTS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getTypesMeasurementsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesMeasurementsContainsDashboardGQL = gql`
  query GET_TYPES_MEASUREMENTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getTypesMeasurementsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
