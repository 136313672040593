import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'
import { useGetExamplesOthersOnesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-examples-others-ones-dashboard'
import { useGetExamplesOthersTwosDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-examples-others-twos-dashboard'
import { useMutation } from '@tanstack/react-query'
import { exportExcelExamplesTables } from 'services/examples-tables/examples-tables/examples-tables-gets'

const schemaExport = z.object({
  name: z.string().min(1, t('inform-field') + ' ' + t('examples-tables-name')),

  typeText: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-tables-type-text')),

  typeBoolean: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('examples-tables-type-boolean')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeInt: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-tables-type-int')),

  typeDatetime: z.union([z.date(), z.string().min(1)]),

  typeEnum: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('examples-tables-type-enum')),
    z.object({ label: z.string(), value: z.string() }),
  ]),

  exampleOtherOneId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('examples-tables-example-other-one-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  exampleOtherTwoId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('examples-tables-example-other-two-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  email: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-tables-email')),

  phone: z
    .object({
      phone: z.string(),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('examples-tables-phone'),
      path: ['phone'],
    }),
  active: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('examples-tables-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('examples-tables-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataExport = z.infer<typeof schemaExport>

export const useModalFormExport = () => {
  const cleanFields: FormDataExport = {
    name: '',
    typeText: '',
    typeBoolean: '',
    typeInt: '',
    typeDatetime: '',
    typeEnum: '',
    exampleOtherOneId: '',
    exampleOtherTwoId: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataExport>({
    resolver: zodResolver(schemaExport),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = form

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  const exampleOtherOneInfo = useGetExamplesOthersOnesDashboard()
  const exampleOtherTwoInfo = useGetExamplesOthersTwosDashboard()

  const { mutateAsync } = useMutation({
    mutationFn: exportExcelExamplesTables,
  })

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    exampleOtherOneInfo,
    exampleOtherTwoInfo,
    register,
    handleSelectCountry,
    resetFields,
    getValues,
    exportExamplesTables: mutateAsync,
  }
}
