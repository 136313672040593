import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import {
  createDefaultTypeCoffeeRenovation,
  updateDefaultTypeCoffeeRenovation,
} from 'services/defaults/defaults-types-coffees-renovations/defaults-types-coffees-renovations-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectDefaultTypeCoffeeRenovation,
} from 'slices/defaults/defaults-types-coffees-renovations-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('defaults-types-coffees-renovations-name'),
    ),

  description: z.string(),
})

export type FormData = z.infer<typeof schema>

const defaultTypeCoffeeRenovationModalFormSelector = (state: StateRoot) => {
  return state.defaultsTypesCoffeesRenovationsReducer
}

export const selectorResult = createSelector(
  defaultTypeCoffeeRenovationModalFormSelector,
  (defaultTypeCoffeeRenovationReducer) => {
    return defaultTypeCoffeeRenovationReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, defaultTypeCoffeeRenovationSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('defaults_types_coffees_renovations')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectDefaultTypeCoffeeRenovation(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createDefaultTypeCoffeeRenovationFn, isPending } =
    useMutation({
      mutationFn: createDefaultTypeCoffeeRenovation,
    })

  const {
    mutateAsync: updateDefaultTypeCoffeeRenovationFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateDefaultTypeCoffeeRenovation,
  })

  const cleanFields: FormData = {
    name: '',
    description: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!defaultTypeCoffeeRenovationSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: defaultTypeCoffeeRenovationSelected.name,
      description: defaultTypeCoffeeRenovationSelected.description,
    }

    reset(defaultTypeCoffeeRenovationSelected ? fields : cleanFields)
  }, [defaultTypeCoffeeRenovationSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        name: data?.name,
        description: data?.description,
      }

      if (
        defaultTypeCoffeeRenovationSelected &&
        defaultTypeCoffeeRenovationSelected?.id
      ) {
        const { updateDefaultTypeCoffeeRenovationDashboard } =
          await updateDefaultTypeCoffeeRenovationFn({
            ...formatedData,
            defaultTypeCoffeeRenovationId:
              defaultTypeCoffeeRenovationSelected?.id,
          })

        dispatch(
          editUniqueData(updateDefaultTypeCoffeeRenovationDashboard?.data),
        )
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData =
        await createDefaultTypeCoffeeRenovationFn(formatedData)

      dispatch(
        pushData(
          responseData?.createDefaultTypeCoffeeRenovationDashboard?.data,
        ),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    defaultTypeCoffeeRenovationSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
