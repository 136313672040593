import { gql } from 'graphql-request'

export const createTypeCoffeeMaturationDashboardGQL = gql`
  mutation CREATE_TYPE_COFFEE_MATURATION_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createTypeCoffeeMaturationDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateTypeCoffeeMaturationDashboardGQL = gql`
  mutation UPDATE_TYPE_COFFEE_MATURATION_DASHBOARD(
    $typeCoffeeMaturationId: Int!
    $name: String
    $description: String
  ) {
    updateTypeCoffeeMaturationDashboard(
      typeCoffeeMaturationId: $typeCoffeeMaturationId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteTypeCoffeeMaturationDashboardGQL = gql`
  mutation DELETE_TYPE_COFFEE_MATURATION_DASHBOARD(
    $typeCoffeeMaturationId: Int!
    $deleted: Boolean!
  ) {
    deleteTypeCoffeeMaturationDashboard(
      typeCoffeeMaturationId: $typeCoffeeMaturationId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeTypeCoffeeMaturationDashboardGQL = gql`
  mutation ACTIVE_TYPE_COFFEE_MATURATION_DASHBOARD(
    $typeCoffeeMaturationId: Int!
    $active: Boolean!
  ) {
    activeTypeCoffeeMaturationDashboard(
      typeCoffeeMaturationId: $typeCoffeeMaturationId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverTypeCoffeeMaturationDashboardGQL = gql`
  mutation DELETE_FOREVER_TYPE_COFFEE_MATURATION_DASHBOARD(
    $typeCoffeeMaturationId: Int!
  ) {
    deleteForeverTypeCoffeeMaturationDashboard(
      typeCoffeeMaturationId: $typeCoffeeMaturationId
    )
  }
`

export const getTypesCoffeesMaturationsDashboardGQL = gql`
  query GET_TYPES_COFFEES_MATURATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getTypesCoffeesMaturationsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesMaturationsContainsDashboardGQL = gql`
  query GET_TYPES_COFFEES_MATURATIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getTypesCoffeesMaturationsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
