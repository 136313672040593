import { createSelector } from 'reselect'
import { DefaultAgriculturalInputSubType } from 'types/defaults/defaults-agriculturals-inputs-subs-types-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverDefaultAgriculturalInputSubType } from 'services/defaults/defaults-agriculturals-inputs-subs-types/defaults-agriculturals-inputs-subs-types-cruds'
import {
  getDefaultsAgriculturalsInputsSubsTypesDashboard,
  GetParams,
  getDefaultsAgriculturalsInputsSubsTypesContainsDashboard,
} from 'services/defaults/defaults-agriculturals-inputs-subs-types/defaults-agriculturals-inputs-subs-types-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/defaults/defaults-agriculturals-inputs-subs-types-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const defaultAgriculturalInputSubTypeListSelector = (state: StateRoot) =>
  state.defaultsAgriculturalsInputsSubsTypesReducer

const selectorResult = createSelector(
  defaultAgriculturalInputSubTypeListSelector,
  (defaultAgriculturalInputSubTypeListReducer) => {
    return defaultAgriculturalInputSubTypeListReducer
  },
)

export const useDefaultAgriculturalInputSubTypeList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions(
    'defaults_agriculturals_inputs_subs_types',
  )

  const {
    data: dataDefaultAgriculturalInputSubType,
    deleteModalOpen,
    defaultAgriculturalInputSubTypeIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'defaultAgriculturalInputSubType', 'list', { params }],
    queryFn: () => getDefaultsAgriculturalsInputsSubsTypesDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverDefaultAgriculturalInputSubType,
  })

  const handleStoreData = useCallback(
    (
      defaultsAgriculturalsInputsSubsTypes: DefaultAgriculturalInputSubType[],
      totalPages: number | null,
    ) => {
      if (!defaultsAgriculturalsInputsSubsTypes) return

      dispatch(setData(defaultsAgriculturalsInputsSubsTypes))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverDefaultAgriculturalInputSubType =
    useCallback(async () => {
      if (!defaultAgriculturalInputSubTypeIdToDelete) return

      const response = await mutateAsync({
        defaultAgriculturalInputSubTypeId:
          +defaultAgriculturalInputSubTypeIdToDelete,
      })

      if (
        response?.deleteForeverDefaultAgriculturalInputSubTypeDashboard ===
        'deleted_forever'
      ) {
        dispatch(
          deleteUniqueData({ id: defaultAgriculturalInputSubTypeIdToDelete }),
        )
      }

      dispatch(closeDeleteModal())
    }, [defaultAgriculturalInputSubTypeIdToDelete])

  useEffect(() => {
    const defaultsAgriculturalsInputsSubsTypesResult =
      data?.getDefaultsAgriculturalsInputsSubsTypesDashboard

    if (defaultsAgriculturalsInputsSubsTypesResult) {
      handleStoreData(
        defaultsAgriculturalsInputsSubsTypesResult?.data,
        defaultsAgriculturalsInputsSubsTypesResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response =
        await getDefaultsAgriculturalsInputsSubsTypesContainsDashboard(value)
      const defaultsAgriculturalsInputsSubsTypesResult =
        response?.getDefaultsAgriculturalsInputsSubsTypesContainsDashboard

      handleStoreData(
        defaultsAgriculturalsInputsSubsTypesResult?.data,
        defaultsAgriculturalsInputsSubsTypesResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    defaultAgriculturalInputSubTypeIdToDelete,
    defaultAgriculturalInputSubType:
      data?.getDefaultsAgriculturalsInputsSubsTypesDashboard?.data,
    isLoading,
    isFetching,
    dataDefaultAgriculturalInputSubType,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverDefaultAgriculturalInputSubType,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
