import { graphClient } from 'services/graph-client'
import {
  activeExampleOtherOneDashboardGQL,
  deleteExampleOtherOneDashboardGQL,
  deleteForeverExampleOtherOneDashboardGQL,
  createExampleOtherOneDashboardGQL,
  updateExampleOtherOneDashboardGQL,
} from './queries'
import { ExampleOtherOne } from 'types/examples-tables/examples-others-ones-types'

interface ResponseCreate {
  createExampleOtherOneDashboard: {
    data: ExampleOtherOne
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateExampleOtherOneDashboard: {
    data: ExampleOtherOne
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeExampleOtherOneDashboard: {
    data: ExampleOtherOne
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteExampleOtherOneDashboard: {
    data: ExampleOtherOne
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverExampleOtherOneDashboard: string
}

interface DataActive {
  exampleOtherOneId: number
  active: boolean
}

interface DataDeleted {
  exampleOtherOneId: number
  deleted: boolean
}

interface DataDeleteForever {
  exampleOtherOneId: number
}

interface DataCreate {
  name: string
}

interface DataUpdate {
  exampleOtherOneId: number
  name: string
}

export const activeExampleOtherOne = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeExampleOtherOneDashboardGQL,
    { ...data },
  )
}

export const deleteExampleOtherOne = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteExampleOtherOneDashboardGQL,
    { ...data },
  )
}

export const deleteForeverExampleOtherOne = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverExampleOtherOneDashboardGQL,
    { ...data },
  )
}

export const createExampleOtherOne = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createExampleOtherOneDashboardGQL,
    { ...data },
  )
}

export const updateExampleOtherOne = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateExampleOtherOneDashboardGQL,
    { ...data },
  )
}
