import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { InputCurrencyComponent } from 'components/common/inputs/input-currency'
import { InputDecimalComponent } from 'components/common/inputs/input-decimal'
import { ControlledInputPhone } from 'components/controlled-components/controlled-input-phone'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetEmployeesFunctionsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-employees-functions-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  employeeFunctionInfo: ReturnType<typeof useGetEmployeesFunctionsDashboard>
  register: UseFormRegister<FormData>
  handleSelectCountry: (country: string) => void
}

export const FormControl = ({
  control,
  errors,
  farmInfo,
  employeeFunctionInfo,
  register,
  handleSelectCountry,
}: Props) => {
  const permissions = useGetPermissions('employees')
  const { employeeSelected } = useSelector(selectorResult)
  const disabled = !!(employeeSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('employees-farm-id')}
            name="farmId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={employeeFunctionInfo?.employeesFunctionsDashboard}
            info={employeeFunctionInfo}
            label={t('employees-employee-function-id')}
            name="employeeFunctionId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="typeEmployee"
            label={t('employees-type-employee')}
            data={[
              { id: 'registered', name: 'registered' },
              { id: 'contracted', name: 'contracted' },
            ]}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('employees-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.documentOne?.message}
            {...register('documentOne')}
            label={t('employees-document-one')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.documentTwo?.message}
            {...register('documentTwo')}
            label={t('employees-document-two')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.photo?.message}
            {...register('photo')}
            label={t('employees-photo')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputCurrencyComponent
            locale="en-US" //pt-BR es-es etc..
            currency="USD" //BRL EUR etc..
            label={t('employees-month-salary-currency')}
            {...register('monthSalaryCurrency')}
            error={errors?.monthSalaryCurrency?.message}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputCurrencyComponent
            locale="en-US" //pt-BR es-es etc..
            currency="USD" //BRL EUR etc..
            label={t('employees-month-bonus-currency')}
            {...register('monthBonusCurrency')}
            error={errors?.monthBonusCurrency?.message}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputCurrencyComponent
            locale="en-US" //pt-BR es-es etc..
            currency="USD" //BRL EUR etc..
            label={t('employees-month-salary-family-currency')}
            {...register('monthSalaryFamilyCurrency')}
            error={errors?.monthSalaryFamilyCurrency?.message}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputCurrencyComponent
            locale="en-US" //pt-BR es-es etc..
            currency="USD" //BRL EUR etc..
            label={t('employees-day-salary-currency')}
            {...register('daySalaryCurrency')}
            error={errors?.daySalaryCurrency?.message}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="birthday"
            label={t('employees-birthday')}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            type="email"
            error={errors?.email?.message}
            {...register('email')}
            label={t('employees-email')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputPhone
            control={control}
            name="phone.phone"
            label={t('employees-phone')}
            handleSelectCountry={handleSelectCountry}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
