import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatCurrencyToSend } from 'helpers/format/format-currency'
import {
  createFarmField,
  updateFarmField,
} from 'services/farms/farms-fields/farms-fields-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectFarmField,
} from 'slices/farms/farms-fields-reducer'
import { z } from 'zod'
import { useGetAreasDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-areas-dashboard'
import { useGetCoffeesPruningsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-coffees-prunings-dashboard'
import { useGetFarmsFieldsPeriodsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-periods-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetTypesCoffeesColorsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-colors-dashboard'
import { useGetTypesCoffeesHarvestsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-harvests-dashboard'
import { useGetTypesCoffeesIrrigationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-irrigations-dashboard'
import { useGetTypesCoffeesManagementsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-managements-dashboard'
import { useGetTypesCoffeesMaturationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-maturations-dashboard'
import { useGetTypesCoffeesPruningsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-prunings-dashboard'
import { useGetTypesCoffeesSizesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-sizes-dashboard'
import { useGetTypesCoffeesVarietiesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-varieties-dashboard'
import { useGetTypesCoffeesRenovationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-renovations-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-fields-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  areaId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-fields-area-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldPeriodId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('farms-fields-farm-field-period-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeColorId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('farms-fields-type-coffee-color-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeSizeId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('farms-fields-type-coffee-size-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeMaturationId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-maturation-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeIrrigationId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-irrigation-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeVarietyId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-variety-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  coffeePruningId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('farms-fields-coffee-pruning-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeePruningId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-pruning-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeManagementId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-management-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeHarvestId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-harvest-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeRenovationId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-renovation-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z.string().min(1, t('inform-field') + ' ' + t('farms-fields-name')),

  yearPlanting: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-fields-year-planting')),

  monthPlanting: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-fields-month-planting')),

  lineSpacing: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-fields-line-spacing')),

  plantSpacing: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-fields-plant-spacing')),

  plantFailure: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-fields-plant-failure')),

  linearQuantity: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-fields-linear-quantity')),

  plantingQuantity: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-fields-planting-quantity')),

  geographicLocation: z.string(),
  hectareQuantity: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-fields-hectare-quantity')),

  usefullHectareQuantity: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('farms-fields-usefull-hectare-quantity'),
    ),
})

export type FormData = z.infer<typeof schema>

const farmFieldModalFormSelector = (state: StateRoot) => {
  return state.farmsFieldsReducer
}

export const selectorResult = createSelector(
  farmFieldModalFormSelector,
  (farmFieldReducer) => {
    return farmFieldReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, farmFieldSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('farms_fields')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const areaInfo = useGetAreasDashboard()
  const coffeePruningInfo = useGetCoffeesPruningsDashboard()
  const farmFieldPeriodInfo = useGetFarmsFieldsPeriodsDashboard()
  const farmInfo = useGetFarmsDashboard()
  const typeCoffeeColorInfo = useGetTypesCoffeesColorsDashboard()
  const typeCoffeeHarvestInfo = useGetTypesCoffeesHarvestsDashboard()
  const typeCoffeeIrrigationInfo = useGetTypesCoffeesIrrigationsDashboard()
  const typeCoffeeManagementInfo = useGetTypesCoffeesManagementsDashboard()
  const typeCoffeeMaturationInfo = useGetTypesCoffeesMaturationsDashboard()
  const typeCoffeePruningInfo = useGetTypesCoffeesPruningsDashboard()
  const typeCoffeeSizeInfo = useGetTypesCoffeesSizesDashboard()
  const typeCoffeeVarietyInfo = useGetTypesCoffeesVarietiesDashboard()
  const typeCoffeeRenovationInfo = useGetTypesCoffeesRenovationsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectFarmField(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createFarmFieldFn, isPending } = useMutation({
    mutationFn: createFarmField,
  })

  const { mutateAsync: updateFarmFieldFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateFarmField,
    })

  const cleanFields: FormData = {
    farmId: '',
    areaId: '',
    farmFieldPeriodId: '',
    typeCoffeeColorId: '',
    typeCoffeeSizeId: '',
    typeCoffeeMaturationId: '',
    typeCoffeeIrrigationId: '',
    typeCoffeeVarietyId: '',
    coffeePruningId: '',
    typeCoffeePruningId: '',
    typeCoffeeManagementId: '',
    typeCoffeeHarvestId: '',
    typeCoffeeRenovationId: '',
    name: '',
    yearPlanting: '',
    monthPlanting: '',
    lineSpacing: '',
    plantSpacing: '',
    plantFailure: '',
    linearQuantity: '',
    plantingQuantity: '',
    geographicLocation: '',
    hectareQuantity: '',
    usefullHectareQuantity: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!farmFieldSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      areaId: formatSelectValueToFields(farmFieldSelected.area, ['id', 'name']),
      coffeePruningId: formatSelectValueToFields(
        farmFieldSelected.coffeePruning,
        ['id', 'name'],
      ),
      farmFieldPeriodId: formatSelectValueToFields(
        farmFieldSelected.farmFieldPeriod,
        ['id', 'name'],
      ),
      farmId: formatSelectValueToFields(farmFieldSelected.farm, ['id', 'name']),
      typeCoffeeColorId: formatSelectValueToFields(
        farmFieldSelected.typeCoffeeColor,
        ['id', 'name'],
      ),
      typeCoffeeHarvestId: formatSelectValueToFields(
        farmFieldSelected.typeCoffeeHarvest,
        ['id', 'name'],
      ),
      typeCoffeeIrrigationId: formatSelectValueToFields(
        farmFieldSelected.typeCoffeeIrrigation,
        ['id', 'name'],
      ),
      typeCoffeeManagementId: formatSelectValueToFields(
        farmFieldSelected.typeCoffeeManagement,
        ['id', 'name'],
      ),
      typeCoffeeMaturationId: formatSelectValueToFields(
        farmFieldSelected.typeCoffeeMaturation,
        ['id', 'name'],
      ),
      typeCoffeePruningId: formatSelectValueToFields(
        farmFieldSelected.typeCoffeePruning,
        ['id', 'name'],
      ),
      typeCoffeeSizeId: formatSelectValueToFields(
        farmFieldSelected.typeCoffeeSize,
        ['id', 'name'],
      ),
      typeCoffeeVarietyId: formatSelectValueToFields(
        farmFieldSelected.typeCoffeeVariety,
        ['id', 'name'],
      ),
      typeCoffeeRenovationId: formatSelectValueToFields(
        farmFieldSelected.typeCoffeeRenovation,
        ['id', 'name'],
      ),
      name: farmFieldSelected.name,
      yearPlanting: `${farmFieldSelected.yearPlanting}`,
      monthPlanting: farmFieldSelected.monthPlanting,
      lineSpacing: `${farmFieldSelected.lineSpacing}`,
      plantSpacing: `${farmFieldSelected.plantSpacing}`,
      plantFailure: `${farmFieldSelected.plantFailure}`,
      linearQuantity: `${farmFieldSelected.linearQuantity}`,
      plantingQuantity: `${farmFieldSelected.plantingQuantity}`,
      geographicLocation: farmFieldSelected.geographicLocation,
      hectareQuantity: `${farmFieldSelected.hectareQuantity}`,
      usefullHectareQuantity: `${farmFieldSelected.usefullHectareQuantity}`,
    }

    reset(farmFieldSelected ? fields : cleanFields)
  }, [farmFieldSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedArea = +formatToSendData(data?.areaId)
      const selectedCoffeePruning = +formatToSendData(data?.coffeePruningId)
      const selectedFarmFieldPeriod = +formatToSendData(data?.farmFieldPeriodId)
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedTypeCoffeeColor = +formatToSendData(data?.typeCoffeeColorId)
      const selectedTypeCoffeeHarvest = +formatToSendData(
        data?.typeCoffeeHarvestId,
      )
      const selectedTypeCoffeeIrrigation = +formatToSendData(
        data?.typeCoffeeIrrigationId,
      )
      const selectedTypeCoffeeManagement = +formatToSendData(
        data?.typeCoffeeManagementId,
      )
      const selectedTypeCoffeeMaturation = +formatToSendData(
        data?.typeCoffeeMaturationId,
      )
      const selectedTypeCoffeePruning = +formatToSendData(
        data?.typeCoffeePruningId,
      )
      const selectedTypeCoffeeSize = +formatToSendData(data?.typeCoffeeSizeId)
      const selectedTypeCoffeeVariety = +formatToSendData(
        data?.typeCoffeeVarietyId,
      )
      const selectedTypeCoffeeRenovation = +formatToSendData(
        data?.typeCoffeeRenovationId,
      )

      const formatedData = {
        areaId: selectedArea,
        coffeePruningId: selectedCoffeePruning,
        farmFieldPeriodId: selectedFarmFieldPeriod,
        farmId: selectedFarm,
        typeCoffeeColorId: selectedTypeCoffeeColor,
        typeCoffeeHarvestId: selectedTypeCoffeeHarvest,
        typeCoffeeIrrigationId: selectedTypeCoffeeIrrigation,
        typeCoffeeManagementId: selectedTypeCoffeeManagement,
        typeCoffeeMaturationId: selectedTypeCoffeeMaturation,
        typeCoffeePruningId: selectedTypeCoffeePruning,
        typeCoffeeSizeId: selectedTypeCoffeeSize,
        typeCoffeeVarietyId: selectedTypeCoffeeVariety,
        typeCoffeeRenovationId: selectedTypeCoffeeRenovation,
        name: data?.name,
        yearPlanting: +data?.yearPlanting,
        monthPlanting: data?.monthPlanting,
        lineSpacing: formatCurrencyToSend(data?.lineSpacing),
        plantSpacing: formatCurrencyToSend(data?.plantSpacing),
        plantFailure: +data?.plantFailure,
        linearQuantity: formatCurrencyToSend(data?.linearQuantity),
        plantingQuantity: +data?.plantingQuantity,
        geographicLocation: data?.geographicLocation,
        hectareQuantity: formatCurrencyToSend(data?.hectareQuantity),
        usefullHectareQuantity: formatCurrencyToSend(
          data?.usefullHectareQuantity,
        ),
      }

      if (farmFieldSelected && farmFieldSelected?.id) {
        const { updateFarmFieldDashboard } = await updateFarmFieldFn({
          ...formatedData,
          farmFieldId: farmFieldSelected?.id,
        })

        dispatch(editUniqueData(updateFarmFieldDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createFarmFieldFn(formatedData)

      dispatch(pushData(responseData?.createFarmFieldDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    farmFieldSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    areaInfo,
    coffeePruningInfo,
    farmFieldPeriodInfo,
    farmInfo,
    typeCoffeeColorInfo,
    typeCoffeeHarvestInfo,
    typeCoffeeIrrigationInfo,
    typeCoffeeManagementInfo,
    typeCoffeeMaturationInfo,
    typeCoffeePruningInfo,
    typeCoffeeSizeInfo,
    typeCoffeeVarietyInfo,
    typeCoffeeRenovationInfo,
  }
}
