import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeAppLog,
  deleteAppLog,
} from 'services/settings/apps-logs/apps-logs-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectAppLog,
} from 'slices/settings/apps-logs-reducer'
import { AppLog } from 'types/settings/apps-logs-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('apps_logs')
  const dispatch = useDispatch()
  const item = cell?.row?.original as AppLog

  const handleOpenForm = () => {
    dispatch(selectAppLog(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectAppLog(itemWithoutId as AppLog))
    dispatch(openForm())
  }

  const { mutateAsync: deleteAppLogDashboardFn } = useMutation({
    mutationFn: deleteAppLog,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteAppLogDashboard } = await deleteAppLogDashboardFn({
      appLogId: +item?.id,
      deleted: !item?.deleted,
    })

    dispatch(editUniqueData(deleteAppLogDashboard?.data))
  }

  const { mutateAsync: activeAppLogDashboardFn } = useMutation({
    mutationFn: activeAppLog,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeAppLogDashboard } = await activeAppLogDashboardFn({
      appLogId: +item?.id,
      active: !item?.active,
    })

    dispatch(editUniqueData(activeAppLogDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
