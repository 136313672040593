import { gql } from 'graphql-request'

export const createOrderServiceEmployeeDashboardGQL = gql`
  mutation CREATE_ORDER_SERVICE_EMPLOYEE_DASHBOARD(
    $farmId: Int
    $farmFieldId: Int
    $orderServiceId: Int!
    $employeeId: Int!
    $name: String!
  ) {
    createOrderServiceEmployeeDashboard(
      farmId: $farmId
      farmFieldId: $farmFieldId
      orderServiceId: $orderServiceId
      employeeId: $employeeId
      name: $name
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        orderServiceId
        orderService {
          id
          name
        }
        employeeId
        employee {
          id
          name
        }
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateOrderServiceEmployeeDashboardGQL = gql`
  mutation UPDATE_ORDER_SERVICE_EMPLOYEE_DASHBOARD(
    $orderServiceEmployeeId: Int!
    $farmId: Int
    $farmFieldId: Int
    $orderServiceId: Int
    $employeeId: Int
    $name: String
  ) {
    updateOrderServiceEmployeeDashboard(
      orderServiceEmployeeId: $orderServiceEmployeeId
      farmId: $farmId
      farmFieldId: $farmFieldId
      orderServiceId: $orderServiceId
      employeeId: $employeeId
      name: $name
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        orderServiceId
        orderService {
          id
          name
        }
        employeeId
        employee {
          id
          name
        }
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteOrderServiceEmployeeDashboardGQL = gql`
  mutation DELETE_ORDER_SERVICE_EMPLOYEE_DASHBOARD(
    $orderServiceEmployeeId: Int!
    $deleted: Boolean!
  ) {
    deleteOrderServiceEmployeeDashboard(
      orderServiceEmployeeId: $orderServiceEmployeeId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        orderServiceId
        employeeId
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeOrderServiceEmployeeDashboardGQL = gql`
  mutation ACTIVE_ORDER_SERVICE_EMPLOYEE_DASHBOARD(
    $orderServiceEmployeeId: Int!
    $active: Boolean!
  ) {
    activeOrderServiceEmployeeDashboard(
      orderServiceEmployeeId: $orderServiceEmployeeId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        orderServiceId
        employeeId
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverOrderServiceEmployeeDashboardGQL = gql`
  mutation DELETE_FOREVER_ORDER_SERVICE_EMPLOYEE_DASHBOARD(
    $orderServiceEmployeeId: Int!
  ) {
    deleteForeverOrderServiceEmployeeDashboard(
      orderServiceEmployeeId: $orderServiceEmployeeId
    )
  }
`

export const getOrdersServicesEmployeesDashboardGQL = gql`
  query GET_ORDERS_SERVICES_EMPLOYEES_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $farmFieldId: Int
    $orderServiceId: Int
    $employeeId: Int
    $name: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getOrdersServicesEmployeesDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      farmFieldId: $farmFieldId
      orderServiceId: $orderServiceId
      employeeId: $employeeId
      name: $name
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        orderServiceId
        orderService {
          id
          name
        }
        employeeId
        employee {
          id
          name
        }
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmFieldDashboardIdGQL = gql`
  query GET_FARM_FIELD_DASHBOARD_ID($farmFieldId: Int!) {
    getFarmFieldDashboard(farmFieldId: $farmFieldId) {
      error
      status
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsFieldsDashboardGQL = gql`
  query GET_FARMS_FIELDS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsFieldsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getOrderServiceDashboardIdGQL = gql`
  query GET_ORDER_SERVICE_DASHBOARD_ID($orderServiceId: Int!) {
    getOrderServiceDashboard(orderServiceId: $orderServiceId) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        typeCoffeeTreatmentId
        name
        dateService
        description
        optionalDescription
        quantityHectare
        isBrush
        isPulverization
        isFertilizing
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getOrdersServicesDashboardGQL = gql`
  query GET_ORDERS_SERVICES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getOrdersServicesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        farmFieldId
        typeCoffeeTreatmentId
        name
        dateService
        description
        optionalDescription
        quantityHectare
        isBrush
        isPulverization
        isFertilizing
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getEmployeeDashboardIdGQL = gql`
  query GET_EMPLOYEE_DASHBOARD_ID($employeeId: Int!) {
    getEmployeeDashboard(employeeId: $employeeId) {
      error
      status
      data {
        id
        companyId
        farmId
        employeeFunctionId
        typeEmployee
        name
        documentOne
        documentTwo
        photo
        monthSalaryCurrency
        monthBonusCurrency
        monthSalaryFamilyCurrency
        daySalaryCurrency
        birthday
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getEmployeesDashboardGQL = gql`
  query GET_EMPLOYEES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getEmployeesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        employeeFunctionId
        typeEmployee
        name
        documentOne
        documentTwo
        photo
        monthSalaryCurrency
        monthBonusCurrency
        monthSalaryFamilyCurrency
        daySalaryCurrency
        birthday
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getOrdersServicesEmployeesContainsDashboardGQL = gql`
  query GET_ORDERS_SERVICES_EMPLOYEES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
  ) {
    getOrdersServicesEmployeesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        orderServiceId
        orderService {
          id
          name
        }
        employeeId
        employee {
          id
          name
        }
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
