import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import {
  createAgriculturalInputSubType,
  updateAgriculturalInputSubType,
} from 'services/agriculturals-inputs/agriculturals-inputs-subs-types/agriculturals-inputs-subs-types-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectAgriculturalInputSubType,
} from 'slices/agriculturals-inputs/agriculturals-inputs-subs-types-reducer'
import { z } from 'zod'
import { useGetAgriculturalsInputsTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-types-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  agriculturalInputTypeId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-subs-types-agricultural-input-type-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('agriculturals-inputs-subs-types-name'),
    ),

  description: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('agriculturals-inputs-subs-types-description'),
    ),
})

export type FormData = z.infer<typeof schema>

const agriculturalInputSubTypeModalFormSelector = (state: StateRoot) => {
  return state.agriculturalsInputsSubsTypesReducer
}

export const selectorResult = createSelector(
  agriculturalInputSubTypeModalFormSelector,
  (agriculturalInputSubTypeReducer) => {
    return agriculturalInputSubTypeReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, agriculturalInputSubTypeSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('agriculturals_inputs_subs_types')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const agriculturalInputTypeInfo = useGetAgriculturalsInputsTypesDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectAgriculturalInputSubType(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createAgriculturalInputSubTypeFn, isPending } =
    useMutation({
      mutationFn: createAgriculturalInputSubType,
    })

  const {
    mutateAsync: updateAgriculturalInputSubTypeFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateAgriculturalInputSubType,
  })

  const cleanFields: FormData = {
    agriculturalInputTypeId: '',
    name: '',
    description: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!agriculturalInputSubTypeSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      agriculturalInputTypeId: formatSelectValueToFields(
        agriculturalInputSubTypeSelected.agriculturalInputType,
        ['id', 'name'],
      ),
      name: agriculturalInputSubTypeSelected.name,
      description: agriculturalInputSubTypeSelected.description,
    }

    reset(agriculturalInputSubTypeSelected ? fields : cleanFields)
  }, [agriculturalInputSubTypeSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedAgriculturalInputType = +formatToSendData(
        data?.agriculturalInputTypeId,
      )

      const formatedData = {
        agriculturalInputTypeId: selectedAgriculturalInputType,
        name: data?.name,
        description: data?.description,
      }

      if (
        agriculturalInputSubTypeSelected &&
        agriculturalInputSubTypeSelected?.id
      ) {
        const { updateAgriculturalInputSubTypeDashboard } =
          await updateAgriculturalInputSubTypeFn({
            ...formatedData,
            agriculturalInputSubTypeId: agriculturalInputSubTypeSelected?.id,
          })

        dispatch(editUniqueData(updateAgriculturalInputSubTypeDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createAgriculturalInputSubTypeFn(formatedData)

      dispatch(
        pushData(responseData?.createAgriculturalInputSubTypeDashboard?.data),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    agriculturalInputSubTypeSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    agriculturalInputTypeInfo,
  }
}
