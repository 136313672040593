import { Control, FieldPath, FieldValues, useController } from 'react-hook-form'
import Flatpickr from 'react-flatpickr'
import { t } from 'i18next'
import { Label } from 'reactstrap'

interface ControlledProps<T extends FieldValues> {
  control: Control<T>
  name: FieldPath<T>
  label: string
  disabled?: boolean
}

export function ControlledInputDate<T extends FieldValues>({
  name,
  control,
  label,
  disabled,
}: ControlledProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control })

  return (
    <>
      <span className="form-label"></span>
      <Label className="form-label">{t(label)}</Label>

      <Flatpickr
        disabled={disabled}
        value={field.value}
        className="form-control"
        onChange={(value) => {
          field.onChange(value[0])
        }}
        placeholder={t('select-date')}
        options={{
          dateFormat: 'd M, Y',
        }}
      />

      {error?.message && (
        <span style={{ color: 'red', fontSize: 12 }}>{error?.message}</span>
      )}
    </>
  )
}
