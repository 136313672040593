import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import {
  createExampleOtherTwo,
  updateExampleOtherTwo,
} from 'services/examples-tables/examples-others-twos/examples-others-twos-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectExampleOtherTwo,
} from 'slices/examples-tables/examples-others-twos-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-others-twos-name')),
})

export type FormData = z.infer<typeof schema>

const exampleOtherTwoModalFormSelector = (state: StateRoot) => {
  return state.examplesOthersTwosReducer
}

export const selectorResult = createSelector(
  exampleOtherTwoModalFormSelector,
  (exampleOtherTwoReducer) => {
    return exampleOtherTwoReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, exampleOtherTwoSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('examples_others_twos')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectExampleOtherTwo(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createExampleOtherTwoFn, isPending } = useMutation({
    mutationFn: createExampleOtherTwo,
  })

  const { mutateAsync: updateExampleOtherTwoFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateExampleOtherTwo,
    })

  const cleanFields: FormData = {
    name: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!exampleOtherTwoSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: exampleOtherTwoSelected.name,
    }

    reset(exampleOtherTwoSelected ? fields : cleanFields)
  }, [exampleOtherTwoSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        name: data?.name,
      }

      if (exampleOtherTwoSelected && exampleOtherTwoSelected?.id) {
        const { updateExampleOtherTwoDashboard } =
          await updateExampleOtherTwoFn({
            ...formatedData,
            exampleOtherTwoId: exampleOtherTwoSelected?.id,
          })

        dispatch(editUniqueData(updateExampleOtherTwoDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createExampleOtherTwoFn(formatedData)

      dispatch(pushData(responseData?.createExampleOtherTwoDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    exampleOtherTwoSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
