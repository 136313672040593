import { gql } from 'graphql-request'

export const createTypePestDashboardGQL = gql`
  mutation CREATE_TYPE_PEST_DASHBOARD(
    $name: String!
    $commonName: String!
    $scientificName: String
    $description: String!
  ) {
    createTypePestDashboard(
      name: $name
      commonName: $commonName
      scientificName: $scientificName
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        commonName
        scientificName
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateTypePestDashboardGQL = gql`
  mutation UPDATE_TYPE_PEST_DASHBOARD(
    $typePestId: Int!
    $name: String
    $commonName: String
    $scientificName: String
    $description: String
  ) {
    updateTypePestDashboard(
      typePestId: $typePestId
      name: $name
      commonName: $commonName
      scientificName: $scientificName
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        commonName
        scientificName
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteTypePestDashboardGQL = gql`
  mutation DELETE_TYPE_PEST_DASHBOARD($typePestId: Int!, $deleted: Boolean!) {
    deleteTypePestDashboard(typePestId: $typePestId, deleted: $deleted) {
      error
      status
      data {
        id
        companyId
        name
        commonName
        scientificName
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeTypePestDashboardGQL = gql`
  mutation ACTIVE_TYPE_PEST_DASHBOARD($typePestId: Int!, $active: Boolean!) {
    activeTypePestDashboard(typePestId: $typePestId, active: $active) {
      error
      status
      data {
        id
        companyId
        name
        commonName
        scientificName
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverTypePestDashboardGQL = gql`
  mutation DELETE_FOREVER_TYPE_PEST_DASHBOARD($typePestId: Int!) {
    deleteForeverTypePestDashboard(typePestId: $typePestId)
  }
`

export const getTypesPestsDashboardGQL = gql`
  query GET_TYPES_PESTS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $commonName: String
    $scientificName: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getTypesPestsDashboard(
      take: $take
      skip: $skip
      name: $name
      commonName: $commonName
      scientificName: $scientificName
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        commonName
        scientificName
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesPestsContainsDashboardGQL = gql`
  query GET_TYPES_PESTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $commonName: String
    $scientificName: String
    $description: String
  ) {
    getTypesPestsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      commonName: $commonName
      scientificName: $scientificName
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        commonName
        scientificName
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
