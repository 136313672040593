import { createSlice } from '@reduxjs/toolkit'
import { ExampleTable } from 'types/examples-tables/examples-tables-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  exampleTableSelected: null | ExampleTable
  deleteModalOpen: boolean
  exampleTableIdToDelete: null | string
  data: ExampleTable[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  exampleTableSelected: null,
  exampleTableIdToDelete: null,
  data: [],
  total: 0,
}

const exampleTableSlice = createSlice({
  initialState,
  name: 'examples-tables-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openExport(state) {
      state.exportOpen = true
    },
    closeExport(state) {
      state.exportOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectExampleTable(state, { payload }: { payload: ExampleTable | null }) {
      state.exampleTableSelected = payload
    },
    setData(state, { payload }: { payload: ExampleTable[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: ExampleTable }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: ExampleTable }) {
      state.data = state.data.map((exampleTable) => {
        return exampleTable.id === payload.id
          ? { ...exampleTable, ...payload }
          : exampleTable
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (exampleTable) => +exampleTable.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.exampleTableIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.exampleTableIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openExport,
  closeExport,
  openForm,
  closeForm,
  selectExampleTable,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = exampleTableSlice.actions
export default exampleTableSlice.reducer
