import { Marker } from '@react-google-maps/api'
import { memo } from 'react'
import { Form } from 'slices/map/map-reducer'

interface Props {
  area: Form
  position: google.maps.LatLng
}

export const AreaName = memo(({ area, position }: Props) => {
  return (
    <Marker
      position={position}
      icon={{ scale: 0, path: google.maps.SymbolPath.CIRCLE }}
      label={{
        fontFamily: 'Poppins',
        fontSize: '12px',
        color: '#fff',
        text: `${area.name} (${area.hectare})`,
      }}
    />
  )
})

AreaName.displayName = 'AreaName'
