import { graphClient } from 'services/graph-client'
import {
  activeTypeCoffeeTreatmentDashboardGQL,
  deleteTypeCoffeeTreatmentDashboardGQL,
  deleteForeverTypeCoffeeTreatmentDashboardGQL,
  createTypeCoffeeTreatmentDashboardGQL,
  updateTypeCoffeeTreatmentDashboardGQL,
} from './queries'
import { TypeCoffeeTreatment } from 'types/types-coffees/types-coffees-treatments-types'

interface ResponseCreate {
  createTypeCoffeeTreatmentDashboard: {
    data: TypeCoffeeTreatment
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeCoffeeTreatmentDashboard: {
    data: TypeCoffeeTreatment
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeCoffeeTreatmentDashboard: {
    data: TypeCoffeeTreatment
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeCoffeeTreatmentDashboard: {
    data: TypeCoffeeTreatment
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeCoffeeTreatmentDashboard: string
}

interface DataActive {
  typeCoffeeTreatmentId: number
  active: boolean
}

interface DataDeleted {
  typeCoffeeTreatmentId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeCoffeeTreatmentId: number
}

interface DataCreate {
  typeToolId: number
  name: string
  description?: string
}

interface DataUpdate {
  typeCoffeeTreatmentId: number
  typeToolId: number
  name: string
  description?: string
}

export const activeTypeCoffeeTreatment = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeTypeCoffeeTreatmentDashboardGQL,
    { ...data },
  )
}

export const deleteTypeCoffeeTreatment = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteTypeCoffeeTreatmentDashboardGQL,
    { ...data },
  )
}

export const deleteForeverTypeCoffeeTreatment = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeCoffeeTreatmentDashboardGQL,
    { ...data },
  )
}

export const createTypeCoffeeTreatment = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createTypeCoffeeTreatmentDashboardGQL,
    { ...data },
  )
}

export const updateTypeCoffeeTreatment = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateTypeCoffeeTreatmentDashboardGQL,
    { ...data },
  )
}
