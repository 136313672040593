import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  register: UseFormRegister<FormData>
}

export const FormControl = ({ control, errors, register }: Props) => {
  const permissions = useGetPermissions('apps_logs')
  const { appLogSelected } = useSelector(selectorResult)
  const disabled = !!(appLogSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="typeAppLogType"
            label={t('apps-logs-type-app-log-type')}
            data={[
              { id: 'active', name: 'active' },
              { id: 'delete', name: 'delete' },
              { id: 'deleteForever', name: 'deleteForever' },
              { id: 'insert', name: 'insert' },
              { id: 'update', name: 'update' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('apps-logs-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.table?.message}
            {...register('table')}
            label={t('apps-logs-table')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="tableIndex"
            label={t('apps-logs-table-index')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.oldJson?.message}
            {...register('oldJson')}
            label={t('apps-logs-old-json')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.newJson?.message}
            {...register('newJson')}
            label={t('apps-logs-new-json')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
