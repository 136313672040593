import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeTypeCoffeeVariety,
  deleteTypeCoffeeVariety,
} from 'services/types-coffees/types-coffees-varieties/types-coffees-varieties-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectTypeCoffeeVariety,
} from 'slices/types-coffees/types-coffees-varieties-reducer'
import { TypeCoffeeVariety } from 'types/types-coffees/types-coffees-varieties-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('types_coffees_varieties')
  const dispatch = useDispatch()
  const item = cell?.row?.original as TypeCoffeeVariety

  const handleOpenForm = () => {
    dispatch(selectTypeCoffeeVariety(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectTypeCoffeeVariety(itemWithoutId as TypeCoffeeVariety))
    dispatch(openForm())
  }

  const { mutateAsync: deleteTypeCoffeeVarietyDashboardFn } = useMutation({
    mutationFn: deleteTypeCoffeeVariety,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteTypeCoffeeVarietyDashboard } =
      await deleteTypeCoffeeVarietyDashboardFn({
        typeCoffeeVarietyId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteTypeCoffeeVarietyDashboard?.data))
  }

  const { mutateAsync: activeTypeCoffeeVarietyDashboardFn } = useMutation({
    mutationFn: activeTypeCoffeeVariety,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeTypeCoffeeVarietyDashboard } =
      await activeTypeCoffeeVarietyDashboardFn({
        typeCoffeeVarietyId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeTypeCoffeeVarietyDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
