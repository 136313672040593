export const formatDisplaySelectInput = (value: any) => {
  const currentValue = value

  const returnObject = {
    label: currentValue,
    value: currentValue,
  }

  return {
    display: typeof currentValue === 'string' ? returnObject : value,
  }
}
