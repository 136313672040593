import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeCoffeeMaturationDashboardGQL,
  deleteDefaultTypeCoffeeMaturationDashboardGQL,
  deleteForeverDefaultTypeCoffeeMaturationDashboardGQL,
  createDefaultTypeCoffeeMaturationDashboardGQL,
  updateDefaultTypeCoffeeMaturationDashboardGQL,
} from './queries'
import { DefaultTypeCoffeeMaturation } from 'types/defaults/defaults-types-coffees-maturations-types'

interface ResponseCreate {
  createDefaultTypeCoffeeMaturationDashboard: {
    data: DefaultTypeCoffeeMaturation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeCoffeeMaturationDashboard: {
    data: DefaultTypeCoffeeMaturation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeCoffeeMaturationDashboard: {
    data: DefaultTypeCoffeeMaturation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeCoffeeMaturationDashboard: {
    data: DefaultTypeCoffeeMaturation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeCoffeeMaturationDashboard: string
}

interface DataActive {
  defaultTypeCoffeeMaturationId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeCoffeeMaturationId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeCoffeeMaturationId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  defaultTypeCoffeeMaturationId: number
  name: string
  description?: string
}

export const activeDefaultTypeCoffeeMaturation = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeCoffeeMaturationDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeCoffeeMaturation = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeCoffeeMaturationDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeCoffeeMaturation = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeCoffeeMaturationDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeCoffeeMaturation = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeCoffeeMaturationDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeCoffeeMaturation = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeCoffeeMaturationDashboardGQL,
    { ...data },
  )
}
