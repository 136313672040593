import { createSelector } from 'reselect'
import { FarmField } from 'types/farms/farms-fields-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverFarmField } from 'services/farms/farms-fields/farms-fields-cruds'
import {
  getFarmsFieldsDashboard,
  GetParams,
  getFarmsFieldsContainsDashboard,
} from 'services/farms/farms-fields/farms-fields-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/farms/farms-fields-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const farmFieldListSelector = (state: StateRoot) => state.farmsFieldsReducer

const selectorResult = createSelector(
  farmFieldListSelector,
  (farmFieldListReducer) => {
    return farmFieldListReducer
  },
)

export const useFarmFieldList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('farms_fields')

  const {
    data: dataFarmField,
    deleteModalOpen,
    farmFieldIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'farmField', 'list', { params }],
    queryFn: () => getFarmsFieldsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverFarmField,
  })

  const handleStoreData = useCallback(
    (farmsFields: FarmField[], totalPages: number | null) => {
      if (!farmsFields) return

      dispatch(setData(farmsFields))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverFarmField = useCallback(async () => {
    if (!farmFieldIdToDelete) return

    const response = await mutateAsync({
      farmFieldId: +farmFieldIdToDelete,
    })

    if (response?.deleteForeverFarmFieldDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: farmFieldIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [farmFieldIdToDelete])

  useEffect(() => {
    const farmsFieldsResult = data?.getFarmsFieldsDashboard

    if (farmsFieldsResult) {
      handleStoreData(farmsFieldsResult?.data, farmsFieldsResult?.totalPages)
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getFarmsFieldsContainsDashboard(value)
      const farmsFieldsResult = response?.getFarmsFieldsContainsDashboard

      handleStoreData(farmsFieldsResult?.data, farmsFieldsResult?.totalPages)
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    farmFieldIdToDelete,
    farmField: data?.getFarmsFieldsDashboard?.data,
    isLoading,
    isFetching,
    dataFarmField,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverFarmField,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
