import { graphClient } from 'services/graph-client'
import { FarmFieldPeriod } from 'types/farms/farms-fields-periods-types'

import { getFarmsFieldsPeriodsDashboardGQL } from 'services/farms/farms-fields-periods/queries'

interface Response {
  getFarmsFieldsPeriodsDashboard: {
    data: FarmFieldPeriod[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsFieldsPeriodsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getFarmsFieldsPeriodsDashboardGQL,
    {
      take,
      skip,
    },
  )
}
