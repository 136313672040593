import { gql } from 'graphql-request'

export const createCompanyOwnerDashboardGQL = gql`
  mutation CREATE_COMPANY_OWNER_DASHBOARD(
    $name: String!
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $priorityContact: Boolean!
  ) {
    createCompanyOwnerDashboard(
      name: $name
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      priorityContact: $priorityContact
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        document
        documentTwo
        documentThree
        email
        phone
        priorityContact
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateCompanyOwnerDashboardGQL = gql`
  mutation UPDATE_COMPANY_OWNER_DASHBOARD(
    $companyOwnerId: Int!
    $name: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $priorityContact: Boolean
  ) {
    updateCompanyOwnerDashboard(
      companyOwnerId: $companyOwnerId
      name: $name
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      priorityContact: $priorityContact
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        document
        documentTwo
        documentThree
        email
        phone
        priorityContact
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteCompanyOwnerDashboardGQL = gql`
  mutation DELETE_COMPANY_OWNER_DASHBOARD(
    $companyOwnerId: Int!
    $deleted: Boolean!
  ) {
    deleteCompanyOwnerDashboard(
      companyOwnerId: $companyOwnerId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        name
        document
        documentTwo
        documentThree
        email
        phone
        priorityContact
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeCompanyOwnerDashboardGQL = gql`
  mutation ACTIVE_COMPANY_OWNER_DASHBOARD(
    $companyOwnerId: Int!
    $active: Boolean!
  ) {
    activeCompanyOwnerDashboard(
      companyOwnerId: $companyOwnerId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        name
        document
        documentTwo
        documentThree
        email
        phone
        priorityContact
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverCompanyOwnerDashboardGQL = gql`
  mutation DELETE_FOREVER_COMPANY_OWNER_DASHBOARD($companyOwnerId: Int!) {
    deleteForeverCompanyOwnerDashboard(companyOwnerId: $companyOwnerId)
  }
`

export const getCompaniesOwnersDashboardGQL = gql`
  query GET_COMPANIES_OWNERS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $priorityContact: Boolean
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getCompaniesOwnersDashboard(
      take: $take
      skip: $skip
      name: $name
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      priorityContact: $priorityContact
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        document
        documentTwo
        documentThree
        email
        phone
        priorityContact
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesOwnersContainsDashboardGQL = gql`
  query GET_COMPANIES_OWNERS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
  ) {
    getCompaniesOwnersContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        document
        documentTwo
        documentThree
        email
        phone
        priorityContact
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
