import { graphClient } from 'services/graph-client'
import {
  Company,
  AgriculturalInputType,
  AgriculturalInputSubType,
} from 'types/agriculturals-inputs/agriculturals-inputs-subs-types-types'

import {
  getCompaniesDashboardGQL,
  getAgriculturalsInputsTypesDashboardGQL,
  getAgriculturalsInputsSubsTypesDashboardGQL,
  getAgriculturalsInputsSubsTypesContainsDashboardGQL,
} from './queries'

interface Response {
  getAgriculturalsInputsSubsTypesDashboard: {
    data: AgriculturalInputSubType[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getAgriculturalsInputsSubsTypesContainsDashboard: {
    data: AgriculturalInputSubType[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  agriculturalInputTypeId?: number
  name?: string
  description?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getAgriculturalsInputsSubsTypesDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getAgriculturalsInputsSubsTypesDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getAgriculturalsInputsSubsTypesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getAgriculturalsInputsSubsTypesDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getAgriculturalsInputsSubsTypesContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getAgriculturalsInputsSubsTypesContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseAgriculturalInputType {
  getAgriculturalsInputsTypesDashboard: {
    data: AgriculturalInputType[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAgriculturalsInputsTypesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseAgriculturalInputType>(
    getAgriculturalsInputsTypesDashboardGQL,
    { take, skip },
  )
}
