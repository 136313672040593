import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'
import { formatSelectPhoneToFields } from 'helpers/form/format-select-phone-to-fields'
import { formatInputDateValue } from 'helpers/form/format-input-date-value'
import { formatDateToFields } from 'helpers/form/format-date-to-fields'
import { formatCurrencyToSend } from 'helpers/format/format-currency'
import {
  createEmployee,
  updateEmployee,
} from 'services/employees/employees/employees-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectEmployee,
} from 'slices/employees/employees-reducer'
import { z } from 'zod'
import { TypeEmployee } from 'types/employees/employees-types'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetEmployeesFunctionsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-employees-functions-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('employees-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  employeeFunctionId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('employees-employee-function-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeEmployee: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('employees-type-employee')),
    z.object({ label: z.string(), value: z.string() }),
  ]),

  name: z.string().min(1, t('inform-field') + ' ' + t('employees-name')),

  documentOne: z.string(),
  documentTwo: z.string(),
  photo: z.string(),
  monthSalaryCurrency: z.string(),
  monthBonusCurrency: z.string(),
  monthSalaryFamilyCurrency: z.string(),
  daySalaryCurrency: z.string(),
  birthday: z.union([z.date(), z.string().min(1)]),

  email: z.string().min(1, t('inform-field') + ' ' + t('employees-email')),

  phone: z
    .object({
      phone: z
        .string()
        .min(1, t('invalid-phone-field') + ' ' + t('employees-phone')),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('employees-phone'),
      path: ['phone'],
    }),
})

export type FormData = z.infer<typeof schema>

const employeeModalFormSelector = (state: StateRoot) => {
  return state.employeesReducer
}

export const selectorResult = createSelector(
  employeeModalFormSelector,
  (employeeReducer) => {
    return employeeReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, employeeSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('employees')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
    setValue,
  } = form

  const farmInfo = useGetFarmsDashboard()
  const employeeFunctionInfo = useGetEmployeesFunctionsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectEmployee(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createEmployeeFn, isPending } = useMutation({
    mutationFn: createEmployee,
  })

  const { mutateAsync: updateEmployeeFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateEmployee,
    })

  const cleanFields: FormData = {
    farmId: '',
    employeeFunctionId: '',
    typeEmployee: '',
    name: '',
    documentOne: '',
    documentTwo: '',
    photo: '',
    monthSalaryCurrency: '',
    monthBonusCurrency: '',
    monthSalaryFamilyCurrency: '',
    daySalaryCurrency: '',
    birthday: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!employeeSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      farmId: formatSelectValueToFields(employeeSelected.farm, ['id', 'name']),
      employeeFunctionId: formatSelectValueToFields(
        employeeSelected.employeeFunction,
        ['id', 'name'],
      ),
      typeEmployee: formatSelectValueToFields(employeeSelected, [
        'typeEmployee',
        'typeEmployee',
      ]),
      name: employeeSelected.name,
      documentOne: employeeSelected.documentOne,
      documentTwo: employeeSelected.documentTwo,
      photo: employeeSelected.photo,
      monthSalaryCurrency: `${employeeSelected.monthSalaryCurrency}`,
      monthBonusCurrency: `${employeeSelected.monthBonusCurrency}`,
      monthSalaryFamilyCurrency: `${employeeSelected.monthSalaryFamilyCurrency}`,
      daySalaryCurrency: `${employeeSelected.daySalaryCurrency}`,
      birthday: formatDateToFields(employeeSelected.birthday),
      email: employeeSelected.email,
      phone: formatSelectPhoneToFields(employeeSelected.phone),
    }

    reset(employeeSelected ? fields : cleanFields)
  }, [employeeSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedEmployeeFunction = +formatToSendData(
        data?.employeeFunctionId,
      )
      const selectedTypeEmployee = formatToSendData(
        data?.typeEmployee,
      ) as TypeEmployee

      const formatedData = {
        farmId: selectedFarm,
        employeeFunctionId: selectedEmployeeFunction,
        typeEmployee: selectedTypeEmployee,
        name: data?.name,
        documentOne: data?.documentOne,
        documentTwo: data?.documentTwo,
        photo: data?.photo,
        monthSalaryCurrency: formatCurrencyToSend(data?.monthSalaryCurrency),
        monthBonusCurrency: formatCurrencyToSend(data?.monthBonusCurrency),
        monthSalaryFamilyCurrency: formatCurrencyToSend(
          data?.monthSalaryFamilyCurrency,
        ),
        daySalaryCurrency: formatCurrencyToSend(data?.daySalaryCurrency),
        birthday: formatInputDateValue(data?.birthday),
        email: data?.email,
        phone: data?.phone.phone,
      }

      if (employeeSelected && employeeSelected?.id) {
        const { updateEmployeeDashboard } = await updateEmployeeFn({
          ...formatedData,
          employeeId: employeeSelected?.id,
        })

        dispatch(editUniqueData(updateEmployeeDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createEmployeeFn(formatedData)

      dispatch(pushData(responseData?.createEmployeeDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    employeeSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    handleSelectCountry,
    resetFields,
    farmInfo,
    employeeFunctionInfo,
  }
}
