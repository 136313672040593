import { Label } from 'reactstrap'
import { forwardRef } from 'react'
import { InputComponent } from './input'
import { CurrencyInput } from 'react-currency-mask'

interface Props {
  name: string
  label?: string
  error?: string
  locale?: string
  currency?: string
  disabled?: boolean
  onChange: (e: any) => void
}

export const InputCurrencyComponent = forwardRef<HTMLInputElement, Props>(
  ({ error, label, locale, currency, disabled, onChange, ...props }, ref) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Label htmlFor={props.name} className="form-label">
          {label}
        </Label>

        <CurrencyInput
          locale={locale}
          currency={currency}
          InputElement={<input disabled={disabled} className="form-control" />}
          ref={ref}
          {...props}
          onChangeValue={onChange}
        />

        {error && <span style={{ color: 'red', fontSize: 12 }}>{error}</span>}
      </div>
    )
  },
)

InputComponent.displayName = 'InputComponent'
