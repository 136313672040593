import { ModalFormTemplate } from 'components/common/modal/form-template'
import { useDefaultAgriculturalInputTypeList } from '../../use-defaults-agriculturals-inputs-types'
import { t } from 'i18next'
import { SendFile } from 'components/common/send-file-component'

export const Import = () => {
  const { importOpen, handleCloseImport } =
    useDefaultAgriculturalInputTypeList()

  const handleConfirm = () => {
    handleCloseImport()
  }

  return (
    <ModalFormTemplate
      isOpen={importOpen}
      full={false}
      hideOptions
      title={t('defaults-agriculturals-inputs-types-title-import')}
      handleConfirm={handleConfirm}
      handleClose={handleCloseImport}
    >
      <form className="mt-4 w-100">
        <SendFile
          label="document"
          shouldLeave={!importOpen}
          onChange={() => {}}
        />
      </form>
    </ModalFormTemplate>
  )
}
