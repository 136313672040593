import { gql } from 'graphql-request'

export const createDefaultTypeCultivationDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_CULTIVATION_DASHBOARD(
    $name: String!
    $description: String!
  ) {
    createDefaultTypeCultivationDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypeCultivationDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_CULTIVATION_DASHBOARD(
    $defaultTypeCultivationId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultTypeCultivationDashboard(
      defaultTypeCultivationId: $defaultTypeCultivationId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypeCultivationDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_CULTIVATION_DASHBOARD(
    $defaultTypeCultivationId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypeCultivationDashboard(
      defaultTypeCultivationId: $defaultTypeCultivationId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypeCultivationDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_CULTIVATION_DASHBOARD(
    $defaultTypeCultivationId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypeCultivationDashboard(
      defaultTypeCultivationId: $defaultTypeCultivationId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypeCultivationDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_CULTIVATION_DASHBOARD(
    $defaultTypeCultivationId: Int!
  ) {
    deleteForeverDefaultTypeCultivationDashboard(
      defaultTypeCultivationId: $defaultTypeCultivationId
    )
  }
`

export const getDefaultsTypesCultivationsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_CULTIVATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesCultivationsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesCultivationsContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_CULTIVATIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsTypesCultivationsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
