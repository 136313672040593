import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormDataFilter } from '../modal/filter/use-filter-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetHarvestsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-harvests-dashboard'

interface Props {
  control: Control<FormDataFilter>
  errors: FieldErrors<FormDataFilter>
  farmFieldInfo: ReturnType<typeof useGetFarmsFieldsDashboard>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  harvestInfo: ReturnType<typeof useGetHarvestsDashboard>
  register: UseFormRegister<FormDataFilter>
}

export const FormFilterControl = ({
  control,
  errors,
  farmFieldInfo,
  farmInfo,
  harvestInfo,
  register,
}: Props) => {
  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmFieldInfo?.farmsFieldsDashboard}
            info={farmFieldInfo}
            label={t('harvests-costs-agriculturals-inputs-farm-field-id')}
            name="farmFieldId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('harvests-costs-agriculturals-inputs-farm-id')}
            name="farmId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={harvestInfo?.harvestsDashboard}
            info={harvestInfo}
            label={t('harvests-costs-agriculturals-inputs-harvest-id')}
            name="harvestId"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="active"
            label={t('harvests-costs-agriculturals-inputs-active')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="deleted"
            label={t('harvests-costs-agriculturals-inputs-deleted')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('harvests-costs-agriculturals-inputs-name')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('harvests-costs-agriculturals-inputs-description')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="expectedNumberService"
            label={t(
              'harvests-costs-agriculturals-inputs-expected-number-service',
            )}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="numberService"
            label={t('harvests-costs-agriculturals-inputs-number-service')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtInitial"
            label={t('harvests-costs-agriculturals-inputs-created-at-initial')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtFinal"
            label={t('harvests-costs-agriculturals-inputs-created-at-final')}
          />
        </Col>
      </Row>
    </Container>
  )
}
