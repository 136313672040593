import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  register: UseFormRegister<FormData>
}

export const FormControl = ({ control, errors, register }: Props) => {
  const permissions = useGetPermissions('defaults_agriculturals_inputs')
  const { defaultAgriculturalInputSelected } = useSelector(selectorResult)
  const disabled = !!(defaultAgriculturalInputSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isPesticide"
            label={t('defaults-agriculturals-inputs-is-pesticide')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isOrganic"
            label={t('defaults-agriculturals-inputs-is-organic')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isFertilizer"
            label={t('defaults-agriculturals-inputs-is-fertilizer')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('defaults-agriculturals-inputs-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('defaults-agriculturals-inputs-description')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="agriculturalInputSubTypeId"
            label={t(
              'defaults-agriculturals-inputs-agricultural-input-sub-type-id',
            )}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="agriculturalInputModeActionId"
            label={t(
              'defaults-agriculturals-inputs-agricultural-input-mode-action-id',
            )}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.tradeMark?.message}
            {...register('tradeMark')}
            label={t('defaults-agriculturals-inputs-trade-mark')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.formulation?.message}
            {...register('formulation')}
            label={t('defaults-agriculturals-inputs-formulation')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.activeIngredient?.message}
            {...register('activeIngredient')}
            label={t('defaults-agriculturals-inputs-active-ingredient')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="typePestId"
            label={t('defaults-agriculturals-inputs-type-pest-id')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.toxicologicalClass?.message}
            {...register('toxicologicalClass')}
            label={t('defaults-agriculturals-inputs-toxicological-class')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.environmentalClass?.message}
            {...register('environmentalClass')}
            label={t('defaults-agriculturals-inputs-environmental-class')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
