import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatCurrencyToSend } from 'helpers/format/format-currency'
import {
  createOrderServiceAgriculturalInput,
  updateOrderServiceAgriculturalInput,
} from 'services/orders/orders-services-agriculturals-inputs/orders-services-agriculturals-inputs-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectOrderServiceAgriculturalInput,
} from 'slices/orders/orders-services-agriculturals-inputs-reducer'
import { z } from 'zod'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetOrdersServicesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-orders-services-dashboard'
import { useGetAgriculturalsInputsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('orders-services-agriculturals-inputs-farm-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('orders-services-agriculturals-inputs-farm-field-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  orderServiceId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('orders-services-agriculturals-inputs-order-service-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  agriculturalInputId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('orders-services-agriculturals-inputs-agricultural-input-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('orders-services-agriculturals-inputs-name'),
    ),

  valueAgriculturalInput: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('orders-services-agriculturals-inputs-value-agricultural-input'),
    ),

  quantityPerPlant: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('orders-services-agriculturals-inputs-quantity-per-plant'),
    ),

  expectedTotalQuantity: z.string(),
  realTotalQuantity: z.string(),
})

export type FormData = z.infer<typeof schema>

const orderServiceAgriculturalInputModalFormSelector = (state: StateRoot) => {
  return state.ordersServicesAgriculturalsInputsReducer
}

export const selectorResult = createSelector(
  orderServiceAgriculturalInputModalFormSelector,
  (orderServiceAgriculturalInputReducer) => {
    return orderServiceAgriculturalInputReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, orderServiceAgriculturalInputSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('orders_services_agriculturals_inputs')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const farmFieldInfo = useGetFarmsFieldsDashboard()
  const farmInfo = useGetFarmsDashboard()
  const orderServiceInfo = useGetOrdersServicesDashboard()
  const agriculturalInputInfo = useGetAgriculturalsInputsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectOrderServiceAgriculturalInput(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createOrderServiceAgriculturalInputFn, isPending } =
    useMutation({
      mutationFn: createOrderServiceAgriculturalInput,
    })

  const {
    mutateAsync: updateOrderServiceAgriculturalInputFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateOrderServiceAgriculturalInput,
  })

  const cleanFields: FormData = {
    farmId: '',
    farmFieldId: '',
    orderServiceId: '',
    agriculturalInputId: '',
    name: '',
    valueAgriculturalInput: '',
    quantityPerPlant: '',
    expectedTotalQuantity: '',
    realTotalQuantity: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!orderServiceAgriculturalInputSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      farmFieldId: formatSelectValueToFields(
        orderServiceAgriculturalInputSelected.farmField,
        ['id', 'name'],
      ),
      farmId: formatSelectValueToFields(
        orderServiceAgriculturalInputSelected.farm,
        ['id', 'name'],
      ),
      orderServiceId: formatSelectValueToFields(
        orderServiceAgriculturalInputSelected.orderService,
        ['id', 'name'],
      ),
      agriculturalInputId: formatSelectValueToFields(
        orderServiceAgriculturalInputSelected.agriculturalInput,
        ['id', 'name'],
      ),
      name: orderServiceAgriculturalInputSelected.name,
      valueAgriculturalInput: `${orderServiceAgriculturalInputSelected.valueAgriculturalInput}`,
      quantityPerPlant: `${orderServiceAgriculturalInputSelected.quantityPerPlant}`,
      expectedTotalQuantity: `${orderServiceAgriculturalInputSelected.expectedTotalQuantity}`,
      realTotalQuantity: `${orderServiceAgriculturalInputSelected.realTotalQuantity}`,
    }

    reset(orderServiceAgriculturalInputSelected ? fields : cleanFields)
  }, [orderServiceAgriculturalInputSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedFarmField = +formatToSendData(data?.farmFieldId)
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedOrderService = +formatToSendData(data?.orderServiceId)
      const selectedAgriculturalInput = +formatToSendData(
        data?.agriculturalInputId,
      )

      const formatedData = {
        farmFieldId: selectedFarmField,
        farmId: selectedFarm,
        orderServiceId: selectedOrderService,
        agriculturalInputId: selectedAgriculturalInput,
        name: data?.name,
        valueAgriculturalInput: formatCurrencyToSend(
          data?.valueAgriculturalInput,
        ),
        quantityPerPlant: formatCurrencyToSend(data?.quantityPerPlant),
        expectedTotalQuantity: formatCurrencyToSend(
          data?.expectedTotalQuantity,
        ),
        realTotalQuantity: formatCurrencyToSend(data?.realTotalQuantity),
      }

      if (
        orderServiceAgriculturalInputSelected &&
        orderServiceAgriculturalInputSelected?.id
      ) {
        const { updateOrderServiceAgriculturalInputDashboard } =
          await updateOrderServiceAgriculturalInputFn({
            ...formatedData,
            orderServiceAgriculturalInputId:
              orderServiceAgriculturalInputSelected?.id,
          })

        dispatch(
          editUniqueData(updateOrderServiceAgriculturalInputDashboard?.data),
        )
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData =
        await createOrderServiceAgriculturalInputFn(formatedData)

      dispatch(
        pushData(
          responseData?.createOrderServiceAgriculturalInputDashboard?.data,
        ),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    orderServiceAgriculturalInputSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    farmFieldInfo,
    farmInfo,
    orderServiceInfo,
    agriculturalInputInfo,
  }
}
