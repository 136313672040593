import { gql } from 'graphql-request'

export const createTypeExpenseDashboardGQL = gql`
  mutation CREATE_TYPE_EXPENSE_DASHBOARD($name: String!, $description: String) {
    createTypeExpenseDashboard(name: $name, description: $description) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateTypeExpenseDashboardGQL = gql`
  mutation UPDATE_TYPE_EXPENSE_DASHBOARD(
    $typeExpenseId: Int!
    $name: String
    $description: String
  ) {
    updateTypeExpenseDashboard(
      typeExpenseId: $typeExpenseId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteTypeExpenseDashboardGQL = gql`
  mutation DELETE_TYPE_EXPENSE_DASHBOARD(
    $typeExpenseId: Int!
    $deleted: Boolean!
  ) {
    deleteTypeExpenseDashboard(
      typeExpenseId: $typeExpenseId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeTypeExpenseDashboardGQL = gql`
  mutation ACTIVE_TYPE_EXPENSE_DASHBOARD(
    $typeExpenseId: Int!
    $active: Boolean!
  ) {
    activeTypeExpenseDashboard(typeExpenseId: $typeExpenseId, active: $active) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverTypeExpenseDashboardGQL = gql`
  mutation DELETE_FOREVER_TYPE_EXPENSE_DASHBOARD($typeExpenseId: Int!) {
    deleteForeverTypeExpenseDashboard(typeExpenseId: $typeExpenseId)
  }
`

export const getTypesExpensesDashboardGQL = gql`
  query GET_TYPES_EXPENSES_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getTypesExpensesDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesExpensesContainsDashboardGQL = gql`
  query GET_TYPES_EXPENSES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getTypesExpensesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
