import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'
import { ExampleTable } from 'types/examples-tables/examples-tables-types'
import {
  activeExampleTable,
  deleteExampleTable,
} from 'services/examples-tables/examples-tables/examples-tables-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectExampleTable,
} from 'slices/examples-tables/examples-tables-reducer'

const Status = (cell: any) => {
  const permissions = useGetPermissions('examples_tables')
  const dispatch = useDispatch()
  const item = cell?.row?.original as ExampleTable

  const handleOpenForm = () => {
    dispatch(selectExampleTable(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectExampleTable(itemWithoutId as ExampleTable))
    dispatch(openForm())
  }

  const { mutateAsync: deleteExampleTableDashboardFn } = useMutation({
    mutationFn: deleteExampleTable,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteExampleTableDashboard } = await deleteExampleTableDashboardFn(
      {
        exampleTableId: +item?.id,
        deleted: !item?.deleted,
      },
    )

    dispatch(editUniqueData(deleteExampleTableDashboard?.data))
  }

  const { mutateAsync: activeExampleTableDashboardFn } = useMutation({
    mutationFn: activeExampleTable,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeExampleTableDashboard } = await activeExampleTableDashboardFn(
      {
        exampleTableId: +item?.id,
        active: !item?.active,
      },
    )

    dispatch(editUniqueData(activeExampleTableDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
