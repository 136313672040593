import { graphClient } from 'services/graph-client'
import {
  Company,
  Farm,
  EmployeeFunction,
} from 'types/employees/employees-functions-types'

import {
  getCompaniesDashboardGQL,
  getFarmsDashboardGQL,
  getEmployeesFunctionsDashboardGQL,
  getEmployeesFunctionsContainsDashboardGQL,
} from './queries'

interface Response {
  getEmployeesFunctionsDashboard: {
    data: EmployeeFunction[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getEmployeesFunctionsContainsDashboard: {
    data: EmployeeFunction[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  farmId?: number
  name?: string
  description?: string
  baseSalaryCurrency?: number
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getEmployeesFunctionsDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getEmployeesFunctionsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getEmployeesFunctionsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getEmployeesFunctionsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getEmployeesFunctionsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getEmployeesFunctionsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseFarm {
  getFarmsDashboard: {
    data: Farm[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarm>(getFarmsDashboardGQL, {
    take,
    skip,
  })
}
