import React, { useEffect, useRef, useState } from 'react'
import { Container } from 'reactstrap'
import SimpleBar from 'simplebar-react'

import VerticalLayout from './vertical-layouts'
import TwoColumnLayout from './two-column-layout'
import HorizontalLayout from './horizontal-layout'

const Sidebar = ({ layoutType }: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const sidebarRef = useRef(null)

  useEffect(() => {
    if (!sidebarRef?.current) return

    const resizeObserver = new ResizeObserver((e) => {
      const width = e[0]?.target.clientWidth

      setSidebarOpen(width < 70 ? false : true)
    })

    resizeObserver.observe(sidebarRef?.current)

    return () => {
      resizeObserver.disconnect()
    }
  })

  return (
    <React.Fragment>
      <div ref={sidebarRef} id="sidebar-left" className="app-menu navbar-menu">
        <div
          style={{ marginTop: 20, marginBottom: 20 }}
          className="navbar-brand-box"
        >
          <span
            style={{
              fontSize: 18,
              cursor: 'pointer',
              color: '#fff',
            }}
          >
            {sidebarOpen ? 'DIDDER' : 'D'}
          </span>
        </div>
        {layoutType === 'horizontal' ? (
          <div id="scrollbar">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <HorizontalLayout />
              </ul>
            </Container>
          </div>
        ) : layoutType === 'twocolumn' ? (
          <React.Fragment>
            <TwoColumnLayout layoutType={layoutType} />
            <div className="sidebar-background"></div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SimpleBar id="scrollbar" className="h-100">
              <Container fluid>
                <div id="two-column-menu"></div>
                <ul className="navbar-nav" id="navbar-nav">
                  <VerticalLayout layoutType={layoutType} />
                </ul>
              </Container>
            </SimpleBar>
            <div className="sidebar-background"></div>
          </React.Fragment>
        )}
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  )
}

export default Sidebar
