import { GraphQLClient } from 'graphql-request'
import { GraphQLClientRequestHeaders } from 'graphql-request/build/esm/types'
import { env } from 'lib/constants/env'

export const graphClient = new GraphQLClient(env.apiUrl, {
  responseMiddleware: (response) => {
    if (response instanceof Error) {
      if (response.message.includes('Invalid User Token')) {
        localStorage.clear()
        window.location.href = '/login'
      }
    }

    return response
  },
  headers: () => {
    const user = localStorage.getItem('@user')
    const accessToken = user && JSON.parse(user)?.token

    const headers: GraphQLClientRequestHeaders = {}

    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`
    }

    return headers
  },
})
