import { graphClient } from 'services/graph-client'
import {
  Area,
  CoffeePruning,
  Company,
  FarmFieldPeriod,
  Farm,
  TypeCoffeeColor,
  TypeCoffeeHarvest,
  TypeCoffeeIrrigation,
  TypeCoffeeManagement,
  TypeCoffeeMaturation,
  TypeCoffeePruning,
  TypeCoffeeSize,
  TypeCoffeeVariety,
  TypeCoffeeRenovation,
  FarmField,
} from 'types/farms/farms-fields-types'

import {
  getAreasDashboardGQL,
  getCoffeesPruningsDashboardGQL,
  getCompaniesDashboardGQL,
  getFarmsFieldsPeriodsDashboardGQL,
  getFarmsDashboardGQL,
  getTypesCoffeesColorsDashboardGQL,
  getTypesCoffeesHarvestsDashboardGQL,
  getTypesCoffeesIrrigationsDashboardGQL,
  getTypesCoffeesManagementsDashboardGQL,
  getTypesCoffeesMaturationsDashboardGQL,
  getTypesCoffeesPruningsDashboardGQL,
  getTypesCoffeesSizesDashboardGQL,
  getTypesCoffeesVarietiesDashboardGQL,
  getTypesCoffeesRenovationsDashboardGQL,
  getFarmsFieldsDashboardGQL,
  getFarmsFieldsContainsDashboardGQL,
} from './queries'

interface Response {
  getFarmsFieldsDashboard: {
    data: FarmField[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getFarmsFieldsContainsDashboard: {
    data: FarmField[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  monthPlanting?: string
  geographicLocation?: string
}

export interface GetParams {
  page?: number
  farmId?: number
  areaId?: number
  farmFieldPeriodId?: number
  typeCoffeeColorId?: number
  typeCoffeeSizeId?: number
  typeCoffeeMaturationId?: number
  typeCoffeeIrrigationId?: number
  typeCoffeeVarietyId?: number
  coffeePruningId?: number
  typeCoffeePruningId?: number
  typeCoffeeManagementId?: number
  typeCoffeeHarvestId?: number
  typeCoffeeRenovationId?: number
  name?: string
  yearPlanting?: number
  monthPlanting?: string
  lineSpacing?: number
  plantSpacing?: number
  plantFailure?: number
  linearQuantity?: number
  plantingQuantity?: number
  geographicLocation?: string
  hectareQuantity?: number
  usefullHectareQuantity?: number
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getFarmsFieldsDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getFarmsFieldsDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getFarmsFieldsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getFarmsFieldsDashboardGQL, {
    take,
    skip,
  })
}

export const getFarmsFieldsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getFarmsFieldsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseArea {
  getAreasDashboard: {
    data: Area[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAreasDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseArea>(getAreasDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseCoffeePruning {
  getCoffeesPruningsDashboard: {
    data: CoffeePruning[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCoffeesPruningsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCoffeePruning>(
    getCoffeesPruningsDashboardGQL,
    { take, skip },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseFarmFieldPeriod {
  getFarmsFieldsPeriodsDashboard: {
    data: FarmFieldPeriod[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsFieldsPeriodsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarmFieldPeriod>(
    getFarmsFieldsPeriodsDashboardGQL,
    { take, skip },
  )
}

interface ResponseFarm {
  getFarmsDashboard: {
    data: Farm[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarm>(getFarmsDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseTypeCoffeeColor {
  getTypesCoffeesColorsDashboard: {
    data: TypeCoffeeColor[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesColorsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeCoffeeColor>(
    getTypesCoffeesColorsDashboardGQL,
    { take, skip },
  )
}

interface ResponseTypeCoffeeHarvest {
  getTypesCoffeesHarvestsDashboard: {
    data: TypeCoffeeHarvest[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesHarvestsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeCoffeeHarvest>(
    getTypesCoffeesHarvestsDashboardGQL,
    { take, skip },
  )
}

interface ResponseTypeCoffeeIrrigation {
  getTypesCoffeesIrrigationsDashboard: {
    data: TypeCoffeeIrrigation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesIrrigationsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeCoffeeIrrigation>(
    getTypesCoffeesIrrigationsDashboardGQL,
    { take, skip },
  )
}

interface ResponseTypeCoffeeManagement {
  getTypesCoffeesManagementsDashboard: {
    data: TypeCoffeeManagement[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesManagementsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeCoffeeManagement>(
    getTypesCoffeesManagementsDashboardGQL,
    { take, skip },
  )
}

interface ResponseTypeCoffeeMaturation {
  getTypesCoffeesMaturationsDashboard: {
    data: TypeCoffeeMaturation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesMaturationsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeCoffeeMaturation>(
    getTypesCoffeesMaturationsDashboardGQL,
    { take, skip },
  )
}

interface ResponseTypeCoffeePruning {
  getTypesCoffeesPruningsDashboard: {
    data: TypeCoffeePruning[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesPruningsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeCoffeePruning>(
    getTypesCoffeesPruningsDashboardGQL,
    { take, skip },
  )
}

interface ResponseTypeCoffeeSize {
  getTypesCoffeesSizesDashboard: {
    data: TypeCoffeeSize[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesSizesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeCoffeeSize>(
    getTypesCoffeesSizesDashboardGQL,
    { take, skip },
  )
}

interface ResponseTypeCoffeeVariety {
  getTypesCoffeesVarietiesDashboard: {
    data: TypeCoffeeVariety[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesVarietiesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeCoffeeVariety>(
    getTypesCoffeesVarietiesDashboardGQL,
    { take, skip },
  )
}

interface ResponseTypeCoffeeRenovation {
  getTypesCoffeesRenovationsDashboard: {
    data: TypeCoffeeRenovation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesRenovationsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeCoffeeRenovation>(
    getTypesCoffeesRenovationsDashboardGQL,
    { take, skip },
  )
}
