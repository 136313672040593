import { graphClient } from 'services/graph-client'
import {
  activeAgriculturalInputPestDashboardGQL,
  deleteAgriculturalInputPestDashboardGQL,
  deleteForeverAgriculturalInputPestDashboardGQL,
  createAgriculturalInputPestDashboardGQL,
  updateAgriculturalInputPestDashboardGQL,
} from './queries'
import { AgriculturalInputPest } from 'types/agriculturals-inputs/agriculturals-inputs-pests-types'

interface ResponseCreate {
  createAgriculturalInputPestDashboard: {
    data: AgriculturalInputPest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateAgriculturalInputPestDashboard: {
    data: AgriculturalInputPest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeAgriculturalInputPestDashboard: {
    data: AgriculturalInputPest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteAgriculturalInputPestDashboard: {
    data: AgriculturalInputPest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverAgriculturalInputPestDashboard: string
}

interface DataActive {
  agriculturalInputPestId: number
  active: boolean
}

interface DataDeleted {
  agriculturalInputPestId: number
  deleted: boolean
}

interface DataDeleteForever {
  agriculturalInputPestId: number
}

interface DataCreate {
  agriculturalInputId: number
  typePestId: number
  agriculturalInputSubTypeId: number
  agriculturalInputModeActionId: number
  name: string
  description: string
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  toxicologicalClass?: string
  environmentalClass?: string
  isOrganic: boolean
}

interface DataUpdate {
  agriculturalInputPestId: number
  agriculturalInputId: number
  typePestId: number
  agriculturalInputSubTypeId: number
  agriculturalInputModeActionId: number
  name: string
  description: string
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  toxicologicalClass?: string
  environmentalClass?: string
  isOrganic: boolean
}

export const activeAgriculturalInputPest = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeAgriculturalInputPestDashboardGQL,
    { ...data },
  )
}

export const deleteAgriculturalInputPest = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteAgriculturalInputPestDashboardGQL,
    { ...data },
  )
}

export const deleteForeverAgriculturalInputPest = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverAgriculturalInputPestDashboardGQL,
    { ...data },
  )
}

export const createAgriculturalInputPest = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createAgriculturalInputPestDashboardGQL,
    { ...data },
  )
}

export const updateAgriculturalInputPest = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateAgriculturalInputPestDashboardGQL,
    { ...data },
  )
}
