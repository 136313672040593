import { gql } from 'graphql-request'

export const createDefaultTypeCoffeeIrrigationDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_COFFEE_IRRIGATION_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createDefaultTypeCoffeeIrrigationDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypeCoffeeIrrigationDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_COFFEE_IRRIGATION_DASHBOARD(
    $defaultTypeCoffeeIrrigationId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultTypeCoffeeIrrigationDashboard(
      defaultTypeCoffeeIrrigationId: $defaultTypeCoffeeIrrigationId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypeCoffeeIrrigationDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_COFFEE_IRRIGATION_DASHBOARD(
    $defaultTypeCoffeeIrrigationId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypeCoffeeIrrigationDashboard(
      defaultTypeCoffeeIrrigationId: $defaultTypeCoffeeIrrigationId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypeCoffeeIrrigationDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_COFFEE_IRRIGATION_DASHBOARD(
    $defaultTypeCoffeeIrrigationId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypeCoffeeIrrigationDashboard(
      defaultTypeCoffeeIrrigationId: $defaultTypeCoffeeIrrigationId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypeCoffeeIrrigationDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_COFFEE_IRRIGATION_DASHBOARD(
    $defaultTypeCoffeeIrrigationId: Int!
  ) {
    deleteForeverDefaultTypeCoffeeIrrigationDashboard(
      defaultTypeCoffeeIrrigationId: $defaultTypeCoffeeIrrigationId
    )
  }
`

export const getDefaultsTypesCoffeesIrrigationsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_IRRIGATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesCoffeesIrrigationsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesCoffeesIrrigationsContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_IRRIGATIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsTypesCoffeesIrrigationsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
