import { createSlice } from '@reduxjs/toolkit'
import { AgriculturalInputCultivation } from 'types/agriculturals-inputs/agriculturals-inputs-cultivations-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  agriculturalInputCultivationSelected: null | AgriculturalInputCultivation
  deleteModalOpen: boolean
  agriculturalInputCultivationIdToDelete: null | string
  data: AgriculturalInputCultivation[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  agriculturalInputCultivationSelected: null,
  agriculturalInputCultivationIdToDelete: null,
  data: [],
  total: 0,
}

const agriculturalInputCultivationSlice = createSlice({
  initialState,
  name: 'agriculturals-inputs-cultivations-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectAgriculturalInputCultivation(
      state,
      { payload }: { payload: AgriculturalInputCultivation | null },
    ) {
      state.agriculturalInputCultivationSelected = payload
    },
    setData(state, { payload }: { payload: AgriculturalInputCultivation[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: AgriculturalInputCultivation }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(
      state,
      { payload }: { payload: AgriculturalInputCultivation },
    ) {
      state.data = state.data.map((agriculturalInputCultivation) => {
        return agriculturalInputCultivation.id === payload.id
          ? { ...agriculturalInputCultivation, ...payload }
          : agriculturalInputCultivation
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (agriculturalInputCultivation) =>
          +agriculturalInputCultivation.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.agriculturalInputCultivationIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.agriculturalInputCultivationIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectAgriculturalInputCultivation,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = agriculturalInputCultivationSlice.actions
export default agriculturalInputCultivationSlice.reducer
