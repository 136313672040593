import { graphClient } from 'services/graph-client'
import { DefaultTypeCoffeePruning } from 'types/defaults/defaults-types-coffees-prunings-types'

import {
  getDefaultsTypesCoffeesPruningsDashboardGQL,
  getDefaultsTypesCoffeesPruningsContainsDashboardGQL,
} from './queries'

interface Response {
  getDefaultsTypesCoffeesPruningsDashboard: {
    data: DefaultTypeCoffeePruning[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getDefaultsTypesCoffeesPruningsContainsDashboard: {
    data: DefaultTypeCoffeePruning[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  name?: string
  description?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getDefaultsTypesCoffeesPruningsDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getDefaultsTypesCoffeesPruningsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getDefaultsTypesCoffeesPruningsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getDefaultsTypesCoffeesPruningsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getDefaultsTypesCoffeesPruningsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getDefaultsTypesCoffeesPruningsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}
