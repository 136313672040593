import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import {
  createDefaultTypeCoffeeIrrigation,
  updateDefaultTypeCoffeeIrrigation,
} from 'services/defaults/defaults-types-coffees-irrigations/defaults-types-coffees-irrigations-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectDefaultTypeCoffeeIrrigation,
} from 'slices/defaults/defaults-types-coffees-irrigations-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('defaults-types-coffees-irrigations-name'),
    ),

  description: z.string(),
})

export type FormData = z.infer<typeof schema>

const defaultTypeCoffeeIrrigationModalFormSelector = (state: StateRoot) => {
  return state.defaultsTypesCoffeesIrrigationsReducer
}

export const selectorResult = createSelector(
  defaultTypeCoffeeIrrigationModalFormSelector,
  (defaultTypeCoffeeIrrigationReducer) => {
    return defaultTypeCoffeeIrrigationReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, defaultTypeCoffeeIrrigationSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('defaults_types_coffees_irrigations')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectDefaultTypeCoffeeIrrigation(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createDefaultTypeCoffeeIrrigationFn, isPending } =
    useMutation({
      mutationFn: createDefaultTypeCoffeeIrrigation,
    })

  const {
    mutateAsync: updateDefaultTypeCoffeeIrrigationFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateDefaultTypeCoffeeIrrigation,
  })

  const cleanFields: FormData = {
    name: '',
    description: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!defaultTypeCoffeeIrrigationSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: defaultTypeCoffeeIrrigationSelected.name,
      description: defaultTypeCoffeeIrrigationSelected.description,
    }

    reset(defaultTypeCoffeeIrrigationSelected ? fields : cleanFields)
  }, [defaultTypeCoffeeIrrigationSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        name: data?.name,
        description: data?.description,
      }

      if (
        defaultTypeCoffeeIrrigationSelected &&
        defaultTypeCoffeeIrrigationSelected?.id
      ) {
        const { updateDefaultTypeCoffeeIrrigationDashboard } =
          await updateDefaultTypeCoffeeIrrigationFn({
            ...formatedData,
            defaultTypeCoffeeIrrigationId:
              defaultTypeCoffeeIrrigationSelected?.id,
          })

        dispatch(
          editUniqueData(updateDefaultTypeCoffeeIrrigationDashboard?.data),
        )
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData =
        await createDefaultTypeCoffeeIrrigationFn(formatedData)

      dispatch(
        pushData(
          responseData?.createDefaultTypeCoffeeIrrigationDashboard?.data,
        ),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    defaultTypeCoffeeIrrigationSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
