import { gql } from 'graphql-request'

export const createAgriculturalInputPestDashboardGQL = gql`
  mutation CREATE_AGRICULTURAL_INPUT_PEST_DASHBOARD(
    $agriculturalInputId: Int!
    $typePestId: Int!
    $agriculturalInputSubTypeId: Int!
    $agriculturalInputModeActionId: Int!
    $name: String!
    $description: String!
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $toxicologicalClass: String
    $environmentalClass: String
    $isOrganic: Boolean!
  ) {
    createAgriculturalInputPestDashboard(
      agriculturalInputId: $agriculturalInputId
      typePestId: $typePestId
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      name: $name
      description: $description
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
      isOrganic: $isOrganic
    ) {
      error
      status
      data {
        id
        agriculturalInputId
        agriculturalInput {
          id
          name
        }
        agriculturalInputSubTypeId
        agriculturalInputSubType {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        typePestId
        typePest {
          id
          name
        }
        agriculturalInputModeActionId
        agriculturalInputModeAction {
          id
          name
        }
        name
        description
        tradeMark
        formulation
        activeIngredient
        toxicologicalClass
        environmentalClass
        isOrganic
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateAgriculturalInputPestDashboardGQL = gql`
  mutation UPDATE_AGRICULTURAL_INPUT_PEST_DASHBOARD(
    $agriculturalInputPestId: Int!
    $agriculturalInputId: Int
    $typePestId: Int
    $agriculturalInputSubTypeId: Int
    $agriculturalInputModeActionId: Int
    $name: String
    $description: String
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $toxicologicalClass: String
    $environmentalClass: String
    $isOrganic: Boolean
  ) {
    updateAgriculturalInputPestDashboard(
      agriculturalInputPestId: $agriculturalInputPestId
      agriculturalInputId: $agriculturalInputId
      typePestId: $typePestId
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      name: $name
      description: $description
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
      isOrganic: $isOrganic
    ) {
      error
      status
      data {
        id
        agriculturalInputId
        agriculturalInput {
          id
          name
        }
        agriculturalInputSubTypeId
        agriculturalInputSubType {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        typePestId
        typePest {
          id
          name
        }
        agriculturalInputModeActionId
        agriculturalInputModeAction {
          id
          name
        }
        name
        description
        tradeMark
        formulation
        activeIngredient
        toxicologicalClass
        environmentalClass
        isOrganic
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteAgriculturalInputPestDashboardGQL = gql`
  mutation DELETE_AGRICULTURAL_INPUT_PEST_DASHBOARD(
    $agriculturalInputPestId: Int!
    $deleted: Boolean!
  ) {
    deleteAgriculturalInputPestDashboard(
      agriculturalInputPestId: $agriculturalInputPestId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        agriculturalInputId
        typePestId
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        name
        description
        tradeMark
        formulation
        activeIngredient
        toxicologicalClass
        environmentalClass
        isOrganic
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeAgriculturalInputPestDashboardGQL = gql`
  mutation ACTIVE_AGRICULTURAL_INPUT_PEST_DASHBOARD(
    $agriculturalInputPestId: Int!
    $active: Boolean!
  ) {
    activeAgriculturalInputPestDashboard(
      agriculturalInputPestId: $agriculturalInputPestId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        agriculturalInputId
        typePestId
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        name
        description
        tradeMark
        formulation
        activeIngredient
        toxicologicalClass
        environmentalClass
        isOrganic
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverAgriculturalInputPestDashboardGQL = gql`
  mutation DELETE_FOREVER_AGRICULTURAL_INPUT_PEST_DASHBOARD(
    $agriculturalInputPestId: Int!
  ) {
    deleteForeverAgriculturalInputPestDashboard(
      agriculturalInputPestId: $agriculturalInputPestId
    )
  }
`

export const getAgriculturalsInputsPestsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_PESTS_DASHBOARD(
    $take: Int
    $skip: Int
    $agriculturalInputId: Int
    $typePestId: Int
    $agriculturalInputSubTypeId: Int
    $agriculturalInputModeActionId: Int
    $name: String
    $description: String
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $toxicologicalClass: String
    $environmentalClass: String
    $isOrganic: Boolean
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getAgriculturalsInputsPestsDashboard(
      take: $take
      skip: $skip
      agriculturalInputId: $agriculturalInputId
      typePestId: $typePestId
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      name: $name
      description: $description
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
      isOrganic: $isOrganic
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        agriculturalInputId
        agriculturalInput {
          id
          name
        }
        agriculturalInputSubTypeId
        agriculturalInputSubType {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        typePestId
        typePest {
          id
          name
        }
        agriculturalInputModeActionId
        agriculturalInputModeAction {
          id
          name
        }
        name
        description
        tradeMark
        formulation
        activeIngredient
        toxicologicalClass
        environmentalClass
        isOrganic
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalInputDashboardIdGQL = gql`
  query GET_AGRICULTURAL_INPUT_DASHBOARD_ID($agriculturalInputId: Int!) {
    getAgriculturalInputDashboard(agriculturalInputId: $agriculturalInputId) {
      error
      status
      data {
        id
        companyId
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        name
        description
        tradeMark
        formulation
        activeIngredient
        isPesticide
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getAgriculturalsInputsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        name
        description
        tradeMark
        formulation
        activeIngredient
        isPesticide
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalInputSubTypeDashboardIdGQL = gql`
  query GET_AGRICULTURAL_INPUT_SUB_TYPE_DASHBOARD_ID(
    $agriculturalInputSubTypeId: Int!
  ) {
    getAgriculturalInputSubTypeDashboard(
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
    ) {
      error
      status
      data {
        id
        companyId
        agriculturalInputTypeId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsSubsTypesDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_SUBS_TYPES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getAgriculturalsInputsSubsTypesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        agriculturalInputTypeId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypePestDashboardIdGQL = gql`
  query GET_TYPE_PEST_DASHBOARD_ID($typePestId: Int!) {
    getTypePestDashboard(typePestId: $typePestId) {
      error
      status
      data {
        id
        companyId
        name
        commonName
        scientificName
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesPestsDashboardGQL = gql`
  query GET_TYPES_PESTS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesPestsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        commonName
        scientificName
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalInputModeActionDashboardIdGQL = gql`
  query GET_AGRICULTURAL_INPUT_MODE_ACTION_DASHBOARD_ID(
    $agriculturalInputModeActionId: Int!
  ) {
    getAgriculturalInputModeActionDashboard(
      agriculturalInputModeActionId: $agriculturalInputModeActionId
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsModesActionsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_MODES_ACTIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getAgriculturalsInputsModesActionsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsPestsContainsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_PESTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $toxicologicalClass: String
    $environmentalClass: String
  ) {
    getAgriculturalsInputsPestsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
    ) {
      error
      status
      totalPages
      data {
        id
        agriculturalInputId
        agriculturalInput {
          id
          name
        }
        agriculturalInputSubTypeId
        agriculturalInputSubType {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        typePestId
        typePest {
          id
          name
        }
        agriculturalInputModeActionId
        agriculturalInputModeAction {
          id
          name
        }
        name
        description
        tradeMark
        formulation
        activeIngredient
        toxicologicalClass
        environmentalClass
        isOrganic
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
