import { graphClient } from 'services/graph-client'
import { TypeCoffeeIrrigation } from 'types/types-coffees/types-coffees-irrigations-types'

import { getTypesCoffeesIrrigationsDashboardGQL } from 'services/types-coffees/types-coffees-irrigations/queries'

interface Response {
  getTypesCoffeesIrrigationsDashboard: {
    data: TypeCoffeeIrrigation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesIrrigationsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getTypesCoffeesIrrigationsDashboardGQL,
    {
      take,
      skip,
    },
  )
}
