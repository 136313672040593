import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeCoffeeIrrigationDashboardGQL,
  deleteDefaultTypeCoffeeIrrigationDashboardGQL,
  deleteForeverDefaultTypeCoffeeIrrigationDashboardGQL,
  createDefaultTypeCoffeeIrrigationDashboardGQL,
  updateDefaultTypeCoffeeIrrigationDashboardGQL,
} from './queries'
import { DefaultTypeCoffeeIrrigation } from 'types/defaults/defaults-types-coffees-irrigations-types'

interface ResponseCreate {
  createDefaultTypeCoffeeIrrigationDashboard: {
    data: DefaultTypeCoffeeIrrigation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeCoffeeIrrigationDashboard: {
    data: DefaultTypeCoffeeIrrigation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeCoffeeIrrigationDashboard: {
    data: DefaultTypeCoffeeIrrigation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeCoffeeIrrigationDashboard: {
    data: DefaultTypeCoffeeIrrigation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeCoffeeIrrigationDashboard: string
}

interface DataActive {
  defaultTypeCoffeeIrrigationId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeCoffeeIrrigationId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeCoffeeIrrigationId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  defaultTypeCoffeeIrrigationId: number
  name: string
  description?: string
}

export const activeDefaultTypeCoffeeIrrigation = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeCoffeeIrrigationDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeCoffeeIrrigation = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeCoffeeIrrigationDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeCoffeeIrrigation = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeCoffeeIrrigationDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeCoffeeIrrigation = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeCoffeeIrrigationDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeCoffeeIrrigation = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeCoffeeIrrigationDashboardGQL,
    { ...data },
  )
}
