import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatCurrencyToSend } from 'helpers/format/format-currency'
import {
  createHarvestForecast,
  updateHarvestForecast,
} from 'services/harvests/harvests-forecasts/harvests-forecasts-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectHarvestForecast,
} from 'slices/harvests/harvests-forecasts-reducer'
import { z } from 'zod'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetHarvestsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-harvests-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('harvests-forecasts-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('harvests-forecasts-farm-field-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  harvestId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('harvests-forecasts-harvest-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  forecastPlant: z
    .string()
    .min(1, t('inform-field') + ' ' + t('harvests-forecasts-forecast-plant')),

  litersSpentPerBag: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('harvests-forecasts-liters-spent-per-bag'),
    ),

  bagPerHectare: z
    .string()
    .min(1, t('inform-field') + ' ' + t('harvests-forecasts-bag-per-hectare')),

  totalBag: z
    .string()
    .min(1, t('inform-field') + ' ' + t('harvests-forecasts-total-bag')),

  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('harvests-forecasts-name')),
})

export type FormData = z.infer<typeof schema>

const harvestForecastModalFormSelector = (state: StateRoot) => {
  return state.harvestsForecastsReducer
}

export const selectorResult = createSelector(
  harvestForecastModalFormSelector,
  (harvestForecastReducer) => {
    return harvestForecastReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, harvestForecastSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('harvests_forecasts')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const farmFieldInfo = useGetFarmsFieldsDashboard()
  const farmInfo = useGetFarmsDashboard()
  const harvestInfo = useGetHarvestsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectHarvestForecast(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createHarvestForecastFn, isPending } = useMutation({
    mutationFn: createHarvestForecast,
  })

  const { mutateAsync: updateHarvestForecastFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateHarvestForecast,
    })

  const cleanFields: FormData = {
    farmId: '',
    farmFieldId: '',
    harvestId: '',
    forecastPlant: '',
    litersSpentPerBag: '',
    bagPerHectare: '',
    totalBag: '',
    name: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!harvestForecastSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      farmFieldId: formatSelectValueToFields(
        harvestForecastSelected.farmField,
        ['id', 'name'],
      ),
      farmId: formatSelectValueToFields(harvestForecastSelected.farm, [
        'id',
        'name',
      ]),
      harvestId: formatSelectValueToFields(harvestForecastSelected.harvest, [
        'id',
        'name',
      ]),
      forecastPlant: `${harvestForecastSelected.forecastPlant}`,
      litersSpentPerBag: `${harvestForecastSelected.litersSpentPerBag}`,
      bagPerHectare: `${harvestForecastSelected.bagPerHectare}`,
      totalBag: `${harvestForecastSelected.totalBag}`,
      name: harvestForecastSelected.name,
    }

    reset(harvestForecastSelected ? fields : cleanFields)
  }, [harvestForecastSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedFarmField = +formatToSendData(data?.farmFieldId)
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedHarvest = +formatToSendData(data?.harvestId)

      const formatedData = {
        farmFieldId: selectedFarmField,
        farmId: selectedFarm,
        harvestId: selectedHarvest,
        forecastPlant: formatCurrencyToSend(data?.forecastPlant),
        litersSpentPerBag: formatCurrencyToSend(data?.litersSpentPerBag),
        bagPerHectare: formatCurrencyToSend(data?.bagPerHectare),
        totalBag: formatCurrencyToSend(data?.totalBag),
        name: data?.name,
      }

      if (harvestForecastSelected && harvestForecastSelected?.id) {
        const { updateHarvestForecastDashboard } =
          await updateHarvestForecastFn({
            ...formatedData,
            harvestForecastId: harvestForecastSelected?.id,
          })

        dispatch(editUniqueData(updateHarvestForecastDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createHarvestForecastFn(formatedData)

      dispatch(pushData(responseData?.createHarvestForecastDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    harvestForecastSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    farmFieldInfo,
    farmInfo,
    harvestInfo,
  }
}
