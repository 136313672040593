import { graphClient } from 'services/graph-client'
import { AgriculturalInputSubType } from 'types/agriculturals-inputs/agriculturals-inputs-subs-types-types'

import { getAgriculturalsInputsSubsTypesDashboardGQL } from 'services/agriculturals-inputs/agriculturals-inputs-subs-types/queries'

interface Response {
  getAgriculturalsInputsSubsTypesDashboard: {
    data: AgriculturalInputSubType[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAgriculturalsInputsSubsTypesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getAgriculturalsInputsSubsTypesDashboardGQL,
    {
      take,
      skip,
    },
  )
}
