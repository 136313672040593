import { Navigate } from 'react-router-dom'
import DashboardAnalytics from '../pages/dashboards/dashboard-analytics'
// import DashboardCrm from '../pages/dashboards/dashboard-crm'
// import DashboardEcommerce from '../pages/dashboards/dashboard-ecommerce'
import Alt404 from '../pages/authentication/errors/alt-404'
import Login from 'pages/authentication/login'
import Map from 'pages/map'

//GENERATE
import ExamplesTables from 'pages/examples-tables/examples-tables'
import ExamplesOthersOnes from 'pages/examples-tables/examples-others-ones'
import ExamplesOthersTwos from 'pages/examples-tables/examples-others-twos'
import Employees from 'pages/employees/employees'
import UsersDevicesInformations from 'pages/users/users-devices-informations'
import AppsLogs from 'pages/settings/apps-logs'
import Users from 'pages/users/users'
import AgriculturalsInputs from 'pages/agriculturals-inputs/agriculturals-inputs'
import AgriculturalsInputsCultivations from 'pages/agriculturals-inputs/agriculturals-inputs-cultivations'
import AgriculturalsInputsModesActions from 'pages/agriculturals-inputs/agriculturals-inputs-modes-actions'
import AgriculturalsInputsPests from 'pages/agriculturals-inputs/agriculturals-inputs-pests'
import AgriculturalsInputsSubsTypes from 'pages/agriculturals-inputs/agriculturals-inputs-subs-types'
import AgriculturalsInputsTypes from 'pages/agriculturals-inputs/agriculturals-inputs-types'
import CoffeesPrunings from 'pages/farms/coffees-prunings'
import DefaultsAgriculturalsInputs from 'pages/defaults/defaults-agriculturals-inputs'
import DefaultsAgriculturalsInputsCultivations from 'pages/defaults/defaults-agriculturals-inputs-cultivations'
import DefaultsAgriculturalsInputsModesActions from 'pages/defaults/defaults-agriculturals-inputs-modes-actions'
import DefaultsAgriculturalsInputsPests from 'pages/defaults/defaults-agriculturals-inputs-pests'
import DefaultsAgriculturalsInputsSubsTypes from 'pages/defaults/defaults-agriculturals-inputs-subs-types'
import DefaultsAgriculturalsInputsTypes from 'pages/defaults/defaults-agriculturals-inputs-types'
import DefaultsCoffeesPrunings from 'pages/defaults/defaults-coffees-prunings'
import DefaultsFarmsFieldsPeriods from 'pages/defaults/defaults-farms-fields-periods'
import DefaultsTypesCoffeesColors from 'pages/defaults/defaults-types-coffees-colors'
import DefaultsTypesCoffeesHarvests from 'pages/defaults/defaults-types-coffees-harvests'
import DefaultsTypesCoffeesIrrigations from 'pages/defaults/defaults-types-coffees-irrigations'
import DefaultsTypesCoffeesManagements from 'pages/defaults/defaults-types-coffees-managements'
import DefaultsTypesCoffeesMaturations from 'pages/defaults/defaults-types-coffees-maturations'
import DefaultsTypesCoffeesPrunings from 'pages/defaults/defaults-types-coffees-prunings'
import DefaultsTypesCoffeesRenovations from 'pages/defaults/defaults-types-coffees-renovations'
import DefaultsTypesCoffeesSizes from 'pages/defaults/defaults-types-coffees-sizes'
import DefaultsTypesCoffeesTreatments from 'pages/defaults/defaults-types-coffees-treatments'
import DefaultsTypesCoffeesVarieties from 'pages/defaults/defaults-types-coffees-varieties'
import DefaultsTypesCultivations from 'pages/defaults/defaults-types-cultivations'
import DefaultsTypesExpenses from 'pages/defaults/defaults-types-expenses'
import DefaultsTypesMeasurements from 'pages/defaults/defaults-types-measurements'
import DefaultsTypesPests from 'pages/defaults/defaults-types-pests'
import DefaultsTypesTools from 'pages/defaults/defaults-types-tools'
import EmployeesFunctions from 'pages/employees/employees-functions'
import Farms from 'pages/farms/farms'
import Areas from 'pages/farms/areas'
import FarmsAreas from 'pages/farms/farms-areas'
import FarmsCultivations from 'pages/farms/farms-cultivations'
import FarmsFields from 'pages/farms/farms-fields'
import FarmsFieldsPeriods from 'pages/farms/farms-fields-periods'
import Companies from 'pages/settings/companies'
import CompaniesOwners from 'pages/settings/companies-owners'
import FarmsInventories from 'pages/farms/farms-inventories'
import FarmsOwners from 'pages/farms/farms-owners'
import FarmsUsers from 'pages/farms/farms-users'
import Harvests from 'pages/harvests/harvests'
import HarvestsCostsAgriculturalsInputs from 'pages/harvests/harvests-costs-agriculturals-inputs'
import HarvestsCostsServices from 'pages/harvests/harvests-costs-services'
import HarvestsForecasts from 'pages/harvests/harvests-forecasts'
import HarvestsReals from 'pages/harvests/harvests-reals'
import OrdersServices from 'pages/orders/orders-services'
import OrdersServicesAgriculturalsInputs from 'pages/orders/orders-services-agriculturals-inputs'
import OrdersServicesEmployees from 'pages/orders/orders-services-employees'
import TypesCoffeesColors from 'pages/types-coffees/types-coffees-colors'
import TypesCoffeesHarvests from 'pages/types-coffees/types-coffees-harvests'
import TypesCoffeesIrrigations from 'pages/types-coffees/types-coffees-irrigations'
import TypesCoffeesManagements from 'pages/types-coffees/types-coffees-managements'
import TypesCoffeesMaturations from 'pages/types-coffees/types-coffees-maturations'
import TypesCoffeesPrunings from 'pages/types-coffees/types-coffees-prunings'
import TypesCoffeesRenovations from 'pages/types-coffees/types-coffees-renovations'
import TypesCoffeesSizes from 'pages/types-coffees/types-coffees-sizes'
import TypesCoffeesTreatments from 'pages/types-coffees/types-coffees-treatments'
import TypesCoffeesVarieties from 'pages/types-coffees/types-coffees-varieties'
import TypesCultivations from 'pages/types-coffees/types-cultivations'
import TypesExpenses from 'pages/types-coffees/types-expenses'
import TypesMeasurements from 'pages/types-coffees/types-measurements'
import TypesPests from 'pages/types-coffees/types-pests'
import TypesTools from 'pages/types-coffees/types-tools'
import Roles from 'pages/users/roles'
import UsersRoles from 'pages/users/users-roles'
import UsersTypes from 'pages/users/users-types'
import HarvestsProductions from 'pages/harvests/harvests-productions'
import EmployeesDaysWorkeds from 'pages/employees/employees-days-workeds'
//END-GENERATE

const authProtectedRoutes = [
  { path: '/dashboard-analytics', component: <DashboardAnalytics /> },
  { path: '/index', component: <DashboardAnalytics /> },
  {
    path: '/',
    exact: true,
    component: <Navigate to="/dashboard-analytics" />,
  },
  { path: '*', component: <Navigate to="/dashboard-analytics" /> },
  { path: '/map', component: <Map /> },

  //GENERATE
  { path: '/examples-tables', component: <ExamplesTables /> },
  { path: '/examples-others-ones', component: <ExamplesOthersOnes /> },
  { path: '/examples-others-twos', component: <ExamplesOthersTwos /> },
  { path: '/employees', component: <Employees /> },
  {
    path: '/users-devices-informations',
    component: <UsersDevicesInformations />,
  },
  { path: '/apps-logs', component: <AppsLogs /> },
  { path: '/users', component: <Users /> },
  { path: '/agriculturals-inputs', component: <AgriculturalsInputs /> },
  {
    path: '/agriculturals-inputs-cultivations',
    component: <AgriculturalsInputsCultivations />,
  },
  {
    path: '/agriculturals-inputs-modes-actions',
    component: <AgriculturalsInputsModesActions />,
  },
  {
    path: '/agriculturals-inputs-pests',
    component: <AgriculturalsInputsPests />,
  },
  {
    path: '/agriculturals-inputs-subs-types',
    component: <AgriculturalsInputsSubsTypes />,
  },
  {
    path: '/agriculturals-inputs-types',
    component: <AgriculturalsInputsTypes />,
  },
  { path: '/coffees-prunings', component: <CoffeesPrunings /> },
  {
    path: '/defaults-agriculturals-inputs',
    component: <DefaultsAgriculturalsInputs />,
  },
  {
    path: '/defaults-agriculturals-inputs-cultivations',
    component: <DefaultsAgriculturalsInputsCultivations />,
  },
  {
    path: '/defaults-agriculturals-inputs-modes-actions',
    component: <DefaultsAgriculturalsInputsModesActions />,
  },
  {
    path: '/defaults-agriculturals-inputs-pests',
    component: <DefaultsAgriculturalsInputsPests />,
  },
  {
    path: '/defaults-agriculturals-inputs-subs-types',
    component: <DefaultsAgriculturalsInputsSubsTypes />,
  },
  {
    path: '/defaults-agriculturals-inputs-types',
    component: <DefaultsAgriculturalsInputsTypes />,
  },
  {
    path: '/defaults-coffees-prunings',
    component: <DefaultsCoffeesPrunings />,
  },
  {
    path: '/defaults-farms-fields-periods',
    component: <DefaultsFarmsFieldsPeriods />,
  },
  {
    path: '/defaults-types-coffees-colors',
    component: <DefaultsTypesCoffeesColors />,
  },
  {
    path: '/defaults-types-coffees-harvests',
    component: <DefaultsTypesCoffeesHarvests />,
  },
  {
    path: '/defaults-types-coffees-irrigations',
    component: <DefaultsTypesCoffeesIrrigations />,
  },
  {
    path: '/defaults-types-coffees-managements',
    component: <DefaultsTypesCoffeesManagements />,
  },
  {
    path: '/defaults-types-coffees-maturations',
    component: <DefaultsTypesCoffeesMaturations />,
  },
  {
    path: '/defaults-types-coffees-prunings',
    component: <DefaultsTypesCoffeesPrunings />,
  },
  {
    path: '/defaults-types-coffees-renovations',
    component: <DefaultsTypesCoffeesRenovations />,
  },
  {
    path: '/defaults-types-coffees-sizes',
    component: <DefaultsTypesCoffeesSizes />,
  },
  {
    path: '/defaults-types-coffees-treatments',
    component: <DefaultsTypesCoffeesTreatments />,
  },
  {
    path: '/defaults-types-coffees-varieties',
    component: <DefaultsTypesCoffeesVarieties />,
  },
  {
    path: '/defaults-types-cultivations',
    component: <DefaultsTypesCultivations />,
  },
  { path: '/defaults-types-expenses', component: <DefaultsTypesExpenses /> },
  {
    path: '/defaults-types-measurements',
    component: <DefaultsTypesMeasurements />,
  },
  { path: '/defaults-types-pests', component: <DefaultsTypesPests /> },
  { path: '/defaults-types-tools', component: <DefaultsTypesTools /> },
  { path: '/employees-functions', component: <EmployeesFunctions /> },
  { path: '/farms', component: <Farms /> },
  { path: '/areas', component: <Areas /> },
  { path: '/farms-areas', component: <FarmsAreas /> },
  { path: '/farms-cultivations', component: <FarmsCultivations /> },
  { path: '/farms-fields', component: <FarmsFields /> },
  { path: '/farms-fields-periods', component: <FarmsFieldsPeriods /> },
  { path: '/companies', component: <Companies /> },
  { path: '/companies-owners', component: <CompaniesOwners /> },
  { path: '/farms-inventories', component: <FarmsInventories /> },
  { path: '/farms-owners', component: <FarmsOwners /> },
  { path: '/farms-users', component: <FarmsUsers /> },
  { path: '/harvests', component: <Harvests /> },
  {
    path: '/harvests-costs-agriculturals-inputs',
    component: <HarvestsCostsAgriculturalsInputs />,
  },
  { path: '/harvests-costs-services', component: <HarvestsCostsServices /> },
  { path: '/harvests-forecasts', component: <HarvestsForecasts /> },
  { path: '/harvests-reals', component: <HarvestsReals /> },
  { path: '/orders-services', component: <OrdersServices /> },
  {
    path: '/orders-services-agriculturals-inputs',
    component: <OrdersServicesAgriculturalsInputs />,
  },
  {
    path: '/orders-services-employees',
    component: <OrdersServicesEmployees />,
  },
  { path: '/types-coffees-colors', component: <TypesCoffeesColors /> },
  { path: '/types-coffees-harvests', component: <TypesCoffeesHarvests /> },
  {
    path: '/types-coffees-irrigations',
    component: <TypesCoffeesIrrigations />,
  },
  {
    path: '/types-coffees-managements',
    component: <TypesCoffeesManagements />,
  },
  {
    path: '/types-coffees-maturations',
    component: <TypesCoffeesMaturations />,
  },
  { path: '/types-coffees-prunings', component: <TypesCoffeesPrunings /> },
  {
    path: '/types-coffees-renovations',
    component: <TypesCoffeesRenovations />,
  },
  { path: '/types-coffees-sizes', component: <TypesCoffeesSizes /> },
  { path: '/types-coffees-treatments', component: <TypesCoffeesTreatments /> },
  { path: '/types-coffees-varieties', component: <TypesCoffeesVarieties /> },
  { path: '/types-cultivations', component: <TypesCultivations /> },
  { path: '/types-expenses', component: <TypesExpenses /> },
  { path: '/types-measurements', component: <TypesMeasurements /> },
  { path: '/types-pests', component: <TypesPests /> },
  { path: '/types-tools', component: <TypesTools /> },
  { path: '/roles', component: <Roles /> },
  { path: '/users-roles', component: <UsersRoles /> },
  { path: '/users-types', component: <UsersTypes /> },
  { path: '/harvests-productions', component: <HarvestsProductions /> },
  { path: '/employees-days-workeds', component: <EmployeesDaysWorkeds /> },
  //END-GENERATE
]

const publicRoutes = [
  { path: '/login', component: <Login /> },
  { path: '/auth-404-alt', component: <Alt404 /> },
]

export { authProtectedRoutes, publicRoutes }
