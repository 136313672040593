import axios from 'axios'

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

httpClient.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('@user') ?? '')?.token

  if (token) {
    config.headers.set('Authorization', `Bearer ${token}`)
  }

  return config
})
