import { Libraries } from '@react-google-maps/api'
import { env } from 'lib/constants/env'

export const center = {
  lat: -25.440222,
  lng: -49.2889285,
}

export const options = {
  googleMapsApiKey: env.googleMapApi,
  libraries: ['drawing', 'places'] as Libraries,
}

export const mapOptions = {
  mapTypeId: 'hybrid',
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  rotateControl: false,
  zoom: 16,
}
