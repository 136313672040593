import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormDataFilter } from '../modal/filter/use-filter-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetAgriculturalsInputsSubsTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-subs-types-dashboard'
import { useGetAgriculturalsInputsModesActionsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-modes-actions-dashboard'

interface Props {
  control: Control<FormDataFilter>
  errors: FieldErrors<FormDataFilter>
  agriculturalInputSubTypeInfo: ReturnType<
    typeof useGetAgriculturalsInputsSubsTypesDashboard
  >
  agriculturalInputModeActionInfo: ReturnType<
    typeof useGetAgriculturalsInputsModesActionsDashboard
  >
  register: UseFormRegister<FormDataFilter>
}

export const FormFilterControl = ({
  control,
  errors,
  agriculturalInputSubTypeInfo,
  agriculturalInputModeActionInfo,
  register,
}: Props) => {
  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={
              agriculturalInputSubTypeInfo?.agriculturalsInputsSubsTypesDashboard
            }
            info={agriculturalInputSubTypeInfo}
            label={t('agriculturals-inputs-agricultural-input-sub-type-id')}
            name="agriculturalInputSubTypeId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={
              agriculturalInputModeActionInfo?.agriculturalsInputsModesActionsDashboard
            }
            info={agriculturalInputModeActionInfo}
            label={t('agriculturals-inputs-agricultural-input-mode-action-id')}
            name="agriculturalInputModeActionId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isPesticide"
            label={t('agriculturals-inputs-is-pesticide')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isOrganic"
            label={t('agriculturals-inputs-is-organic')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isFertilizer"
            label={t('agriculturals-inputs-is-fertilizer')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="active"
            label={t('agriculturals-inputs-active')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="deleted"
            label={t('agriculturals-inputs-deleted')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('agriculturals-inputs-name')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('agriculturals-inputs-description')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.tradeMark?.message}
            {...register('tradeMark')}
            label={t('agriculturals-inputs-trade-mark')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.formulation?.message}
            {...register('formulation')}
            label={t('agriculturals-inputs-formulation')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.activeIngredient?.message}
            {...register('activeIngredient')}
            label={t('agriculturals-inputs-active-ingredient')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.toxicologicalClass?.message}
            {...register('toxicologicalClass')}
            label={t('agriculturals-inputs-toxicological-class')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.environmentalClass?.message}
            {...register('environmentalClass')}
            label={t('agriculturals-inputs-environmental-class')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtInitial"
            label={t('agriculturals-inputs-created-at-initial')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtFinal"
            label={t('agriculturals-inputs-created-at-final')}
          />
        </Col>
      </Row>
    </Container>
  )
}
