import { graphClient } from 'services/graph-client'
import { TypeTool } from 'types/types-coffees/types-tools-types'

import { getTypesToolsDashboardGQL } from 'services/types-coffees/types-tools/queries'

interface Response {
  getTypesToolsDashboard: {
    data: TypeTool[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesToolsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getTypesToolsDashboardGQL, {
    take,
    skip,
  })
}
