import { gql } from 'graphql-request'

export const createDefaultTypeToolDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_TOOL_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createDefaultTypeToolDashboard(name: $name, description: $description) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypeToolDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_TOOL_DASHBOARD(
    $defaultTypeToolId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultTypeToolDashboard(
      defaultTypeToolId: $defaultTypeToolId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypeToolDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_TOOL_DASHBOARD(
    $defaultTypeToolId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypeToolDashboard(
      defaultTypeToolId: $defaultTypeToolId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypeToolDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_TOOL_DASHBOARD(
    $defaultTypeToolId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypeToolDashboard(
      defaultTypeToolId: $defaultTypeToolId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypeToolDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_TOOL_DASHBOARD(
    $defaultTypeToolId: Int!
  ) {
    deleteForeverDefaultTypeToolDashboard(defaultTypeToolId: $defaultTypeToolId)
  }
`

export const getDefaultsTypesToolsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_TOOLS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesToolsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesToolsContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_TOOLS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsTypesToolsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
