import { createSlice } from '@reduxjs/toolkit'
import { DefaultTypeCoffeeSize } from 'types/defaults/defaults-types-coffees-sizes-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  defaultTypeCoffeeSizeSelected: null | DefaultTypeCoffeeSize
  deleteModalOpen: boolean
  defaultTypeCoffeeSizeIdToDelete: null | string
  data: DefaultTypeCoffeeSize[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  defaultTypeCoffeeSizeSelected: null,
  defaultTypeCoffeeSizeIdToDelete: null,
  data: [],
  total: 0,
}

const defaultTypeCoffeeSizeSlice = createSlice({
  initialState,
  name: 'defaults-types-coffees-sizes-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectDefaultTypeCoffeeSize(
      state,
      { payload }: { payload: DefaultTypeCoffeeSize | null },
    ) {
      state.defaultTypeCoffeeSizeSelected = payload
    },
    setData(state, { payload }: { payload: DefaultTypeCoffeeSize[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: DefaultTypeCoffeeSize }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: DefaultTypeCoffeeSize }) {
      state.data = state.data.map((defaultTypeCoffeeSize) => {
        return defaultTypeCoffeeSize.id === payload.id
          ? { ...defaultTypeCoffeeSize, ...payload }
          : defaultTypeCoffeeSize
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (defaultTypeCoffeeSize) => +defaultTypeCoffeeSize.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.defaultTypeCoffeeSizeIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.defaultTypeCoffeeSizeIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectDefaultTypeCoffeeSize,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = defaultTypeCoffeeSizeSlice.actions
export default defaultTypeCoffeeSizeSlice.reducer
