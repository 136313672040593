import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import {
  createAgriculturalInputPest,
  updateAgriculturalInputPest,
} from 'services/agriculturals-inputs/agriculturals-inputs-pests/agriculturals-inputs-pests-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectAgriculturalInputPest,
} from 'slices/agriculturals-inputs/agriculturals-inputs-pests-reducer'
import { z } from 'zod'
import { useGetAgriculturalsInputsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-dashboard'
import { useGetAgriculturalsInputsSubsTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-subs-types-dashboard'
import { useGetTypesPestsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-pests-dashboard'
import { useGetAgriculturalsInputsModesActionsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-modes-actions-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  agriculturalInputId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-pests-agricultural-input-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typePestId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('agriculturals-inputs-pests-type-pest-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  agriculturalInputSubTypeId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-pests-agricultural-input-sub-type-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  agriculturalInputModeActionId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-pests-agricultural-input-mode-action-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-pests-name')),

  description: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('agriculturals-inputs-pests-description'),
    ),

  tradeMark: z.string(),
  formulation: z.string(),
  activeIngredient: z.string(),
  toxicologicalClass: z.string(),
  environmentalClass: z.string(),
  isOrganic: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('agriculturals-inputs-pests-is-organic'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),
})

export type FormData = z.infer<typeof schema>

const agriculturalInputPestModalFormSelector = (state: StateRoot) => {
  return state.agriculturalsInputsPestsReducer
}

export const selectorResult = createSelector(
  agriculturalInputPestModalFormSelector,
  (agriculturalInputPestReducer) => {
    return agriculturalInputPestReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, agriculturalInputPestSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('agriculturals_inputs_pests')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const agriculturalInputInfo = useGetAgriculturalsInputsDashboard()
  const agriculturalInputSubTypeInfo =
    useGetAgriculturalsInputsSubsTypesDashboard()
  const typePestInfo = useGetTypesPestsDashboard()
  const agriculturalInputModeActionInfo =
    useGetAgriculturalsInputsModesActionsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectAgriculturalInputPest(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createAgriculturalInputPestFn, isPending } = useMutation(
    {
      mutationFn: createAgriculturalInputPest,
    },
  )

  const {
    mutateAsync: updateAgriculturalInputPestFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateAgriculturalInputPest,
  })

  const cleanFields: FormData = {
    agriculturalInputId: '',
    typePestId: '',
    agriculturalInputSubTypeId: '',
    agriculturalInputModeActionId: '',
    name: '',
    description: '',
    tradeMark: '',
    formulation: '',
    activeIngredient: '',
    toxicologicalClass: '',
    environmentalClass: '',
    isOrganic: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!agriculturalInputPestSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      agriculturalInputId: formatSelectValueToFields(
        agriculturalInputPestSelected.agriculturalInput,
        ['id', 'name'],
      ),
      agriculturalInputSubTypeId: formatSelectValueToFields(
        agriculturalInputPestSelected.agriculturalInputSubType,
        ['id', 'name'],
      ),
      typePestId: formatSelectValueToFields(
        agriculturalInputPestSelected.typePest,
        ['id', 'name'],
      ),
      agriculturalInputModeActionId: formatSelectValueToFields(
        agriculturalInputPestSelected.agriculturalInputModeAction,
        ['id', 'name'],
      ),
      name: agriculturalInputPestSelected.name,
      description: agriculturalInputPestSelected.description,
      tradeMark: agriculturalInputPestSelected.tradeMark,
      formulation: agriculturalInputPestSelected.formulation,
      activeIngredient: agriculturalInputPestSelected.activeIngredient,
      toxicologicalClass: agriculturalInputPestSelected.toxicologicalClass,
      environmentalClass: agriculturalInputPestSelected.environmentalClass,
      isOrganic: `${agriculturalInputPestSelected.isOrganic}`,
    }

    reset(agriculturalInputPestSelected ? fields : cleanFields)
  }, [agriculturalInputPestSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedAgriculturalInput = +formatToSendData(
        data?.agriculturalInputId,
      )
      const selectedAgriculturalInputSubType = +formatToSendData(
        data?.agriculturalInputSubTypeId,
      )
      const selectedTypePest = +formatToSendData(data?.typePestId)
      const selectedAgriculturalInputModeAction = +formatToSendData(
        data?.agriculturalInputModeActionId,
      )
      const selectedIsOrganic = formatToSendData(data?.isOrganic)

      const formatedData = {
        agriculturalInputId: selectedAgriculturalInput,
        agriculturalInputSubTypeId: selectedAgriculturalInputSubType,
        typePestId: selectedTypePest,
        agriculturalInputModeActionId: selectedAgriculturalInputModeAction,
        name: data?.name,
        description: data?.description,
        tradeMark: data?.tradeMark,
        formulation: data?.formulation,
        activeIngredient: data?.activeIngredient,
        toxicologicalClass: data?.toxicologicalClass,
        environmentalClass: data?.environmentalClass,
        isOrganic: formatInputBooleanValue(selectedIsOrganic),
      }

      if (agriculturalInputPestSelected && agriculturalInputPestSelected?.id) {
        const { updateAgriculturalInputPestDashboard } =
          await updateAgriculturalInputPestFn({
            ...formatedData,
            agriculturalInputPestId: agriculturalInputPestSelected?.id,
          })

        dispatch(editUniqueData(updateAgriculturalInputPestDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createAgriculturalInputPestFn(formatedData)

      dispatch(
        pushData(responseData?.createAgriculturalInputPestDashboard?.data),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    agriculturalInputPestSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    agriculturalInputInfo,
    agriculturalInputSubTypeInfo,
    typePestInfo,
    agriculturalInputModeActionInfo,
  }
}
