import { gql } from 'graphql-request'

export const createDefaultAgriculturalInputSubTypeDashboardGQL = gql`
  mutation CREATE_DEFAULT_AGRICULTURAL_INPUT_SUB_TYPE_DASHBOARD(
    $agriculturalInputTypeId: Int!
    $name: String!
    $description: String!
  ) {
    createDefaultAgriculturalInputSubTypeDashboard(
      agriculturalInputTypeId: $agriculturalInputTypeId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        agriculturalInputTypeId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultAgriculturalInputSubTypeDashboardGQL = gql`
  mutation UPDATE_DEFAULT_AGRICULTURAL_INPUT_SUB_TYPE_DASHBOARD(
    $defaultAgriculturalInputSubTypeId: Int!
    $agriculturalInputTypeId: Int
    $name: String
    $description: String
  ) {
    updateDefaultAgriculturalInputSubTypeDashboard(
      defaultAgriculturalInputSubTypeId: $defaultAgriculturalInputSubTypeId
      agriculturalInputTypeId: $agriculturalInputTypeId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        agriculturalInputTypeId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultAgriculturalInputSubTypeDashboardGQL = gql`
  mutation DELETE_DEFAULT_AGRICULTURAL_INPUT_SUB_TYPE_DASHBOARD(
    $defaultAgriculturalInputSubTypeId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultAgriculturalInputSubTypeDashboard(
      defaultAgriculturalInputSubTypeId: $defaultAgriculturalInputSubTypeId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        agriculturalInputTypeId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultAgriculturalInputSubTypeDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_AGRICULTURAL_INPUT_SUB_TYPE_DASHBOARD(
    $defaultAgriculturalInputSubTypeId: Int!
    $active: Boolean!
  ) {
    activeDefaultAgriculturalInputSubTypeDashboard(
      defaultAgriculturalInputSubTypeId: $defaultAgriculturalInputSubTypeId
      active: $active
    ) {
      error
      status
      data {
        id
        agriculturalInputTypeId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultAgriculturalInputSubTypeDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_AGRICULTURAL_INPUT_SUB_TYPE_DASHBOARD(
    $defaultAgriculturalInputSubTypeId: Int!
  ) {
    deleteForeverDefaultAgriculturalInputSubTypeDashboard(
      defaultAgriculturalInputSubTypeId: $defaultAgriculturalInputSubTypeId
    )
  }
`

export const getDefaultsAgriculturalsInputsSubsTypesDashboardGQL = gql`
  query GET_DEFAULTS_AGRICULTURALS_INPUTS_SUBS_TYPES_DASHBOARD(
    $take: Int
    $skip: Int
    $agriculturalInputTypeId: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsAgriculturalsInputsSubsTypesDashboard(
      take: $take
      skip: $skip
      agriculturalInputTypeId: $agriculturalInputTypeId
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        agriculturalInputTypeId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsAgriculturalsInputsSubsTypesContainsDashboardGQL = gql`
  query GET_DEFAULTS_AGRICULTURALS_INPUTS_SUBS_TYPES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsAgriculturalsInputsSubsTypesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        agriculturalInputTypeId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
