import { graphClient } from 'services/graph-client'
import {
  activeHarvestCostServiceDashboardGQL,
  deleteHarvestCostServiceDashboardGQL,
  deleteForeverHarvestCostServiceDashboardGQL,
  createHarvestCostServiceDashboardGQL,
  updateHarvestCostServiceDashboardGQL,
} from './queries'
import { HarvestCostService } from 'types/harvests/harvests-costs-services-types'

interface ResponseCreate {
  createHarvestCostServiceDashboard: {
    data: HarvestCostService
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateHarvestCostServiceDashboard: {
    data: HarvestCostService
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeHarvestCostServiceDashboard: {
    data: HarvestCostService
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteHarvestCostServiceDashboard: {
    data: HarvestCostService
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverHarvestCostServiceDashboard: string
}

interface DataActive {
  harvestCostServiceId: number
  active: boolean
}

interface DataDeleted {
  harvestCostServiceId: number
  deleted: boolean
}

interface DataDeleteForever {
  harvestCostServiceId: number
}

interface DataCreate {
  farmId?: number
  farmFieldId?: number
  harvestId: number
  orderServiceId: number
  name: string
  description?: string
  expectedNumberService?: number
  numberService?: number
  serviceValueApportionment?: number
  valueAgriculturalInput?: number
}

interface DataUpdate {
  harvestCostServiceId: number
  farmId?: number
  farmFieldId?: number
  harvestId: number
  orderServiceId: number
  name: string
  description?: string
  expectedNumberService?: number
  numberService?: number
  serviceValueApportionment?: number
  valueAgriculturalInput?: number
}

export const activeHarvestCostService = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeHarvestCostServiceDashboardGQL,
    { ...data },
  )
}

export const deleteHarvestCostService = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteHarvestCostServiceDashboardGQL,
    { ...data },
  )
}

export const deleteForeverHarvestCostService = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverHarvestCostServiceDashboardGQL,
    { ...data },
  )
}

export const createHarvestCostService = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createHarvestCostServiceDashboardGQL,
    { ...data },
  )
}

export const updateHarvestCostService = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateHarvestCostServiceDashboardGQL,
    { ...data },
  )
}
