import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormDataFilter } from '../modal/filter/use-filter-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { ControlledInputPhone } from 'components/controlled-components/controlled-input-phone'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetUsersTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-users-types-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'

interface Props {
  control: Control<FormDataFilter>
  errors: FieldErrors<FormDataFilter>
  userTypeInfo: ReturnType<typeof useGetUsersTypesDashboard>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  register: UseFormRegister<FormDataFilter>
  handleSelectCountry: (country: string) => void
}

export const FormFilterControl = ({
  control,
  errors,
  userTypeInfo,
  farmInfo,
  register,
  handleSelectCountry,
}: Props) => {
  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={userTypeInfo?.usersTypesDashboard}
            info={userTypeInfo}
            label={t('users-user-type-id')}
            name="userTypeId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('users-farm-id')}
            name="farmId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="active"
            label={t('users-active')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="deleted"
            label={t('users-deleted')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.firstName?.message}
            {...register('firstName')}
            label={t('users-first-name')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.lastName?.message}
            {...register('lastName')}
            label={t('users-last-name')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('users-name')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            type="email"
            error={errors?.email?.message}
            {...register('email')}
            label={t('users-email')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputPhone
            control={control}
            name="phone.phone"
            label={t('users-phone')}
            handleSelectCountry={handleSelectCountry}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.country?.message}
            {...register('country')}
            label={t('users-country')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.state?.message}
            {...register('state')}
            label={t('users-state')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.utc?.message}
            {...register('utc')}
            label={t('users-utc')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.language?.message}
            {...register('language')}
            label={t('users-language')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.gender?.message}
            {...register('gender')}
            label={t('users-gender')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="birthday"
            label={t('users-birthday')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtInitial"
            label={t('users-created-at-initial')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtFinal"
            label={t('users-created-at-final')}
          />
        </Col>
      </Row>
    </Container>
  )
}
