import { gql } from 'graphql-request'

export const createHarvestProductionDashboardGQL = gql`
  mutation CREATE_HARVEST_PRODUCTION_DASHBOARD(
    $farmId: Int
    $farmFieldId: Int
    $employeeId: Int!
    $name: String!
    $dateHarvest: String!
    $measurements: Int!
    $liters: Int!
  ) {
    createHarvestProductionDashboard(
      farmId: $farmId
      farmFieldId: $farmFieldId
      employeeId: $employeeId
      name: $name
      dateHarvest: $dateHarvest
      measurements: $measurements
      liters: $liters
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        employeeId
        employee {
          id
          name
        }
        name
        dateHarvest
        measurements
        liters
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateHarvestProductionDashboardGQL = gql`
  mutation UPDATE_HARVEST_PRODUCTION_DASHBOARD(
    $harvestProductionId: Int!
    $farmId: Int
    $farmFieldId: Int
    $employeeId: Int
    $name: String
    $dateHarvest: String
    $measurements: Int
    $liters: Int
  ) {
    updateHarvestProductionDashboard(
      harvestProductionId: $harvestProductionId
      farmId: $farmId
      farmFieldId: $farmFieldId
      employeeId: $employeeId
      name: $name
      dateHarvest: $dateHarvest
      measurements: $measurements
      liters: $liters
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        employeeId
        employee {
          id
          name
        }
        name
        dateHarvest
        measurements
        liters
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteHarvestProductionDashboardGQL = gql`
  mutation DELETE_HARVEST_PRODUCTION_DASHBOARD(
    $harvestProductionId: Int!
    $deleted: Boolean!
  ) {
    deleteHarvestProductionDashboard(
      harvestProductionId: $harvestProductionId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        employeeId
        name
        dateHarvest
        measurements
        liters
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeHarvestProductionDashboardGQL = gql`
  mutation ACTIVE_HARVEST_PRODUCTION_DASHBOARD(
    $harvestProductionId: Int!
    $active: Boolean!
  ) {
    activeHarvestProductionDashboard(
      harvestProductionId: $harvestProductionId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        employeeId
        name
        dateHarvest
        measurements
        liters
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverHarvestProductionDashboardGQL = gql`
  mutation DELETE_FOREVER_HARVEST_PRODUCTION_DASHBOARD(
    $harvestProductionId: Int!
  ) {
    deleteForeverHarvestProductionDashboard(
      harvestProductionId: $harvestProductionId
    )
  }
`

export const getHarvestsProductionsDashboardGQL = gql`
  query GET_HARVESTS_PRODUCTIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $farmFieldId: Int
    $employeeId: Int
    $name: String
    $dateHarvest: String
    $measurements: Int
    $liters: Int
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getHarvestsProductionsDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      farmFieldId: $farmFieldId
      employeeId: $employeeId
      name: $name
      dateHarvest: $dateHarvest
      measurements: $measurements
      liters: $liters
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        employeeId
        employee {
          id
          name
        }
        name
        dateHarvest
        measurements
        liters
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmFieldDashboardIdGQL = gql`
  query GET_FARM_FIELD_DASHBOARD_ID($farmFieldId: Int!) {
    getFarmFieldDashboard(farmFieldId: $farmFieldId) {
      error
      status
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsFieldsDashboardGQL = gql`
  query GET_FARMS_FIELDS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsFieldsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getEmployeeDashboardIdGQL = gql`
  query GET_EMPLOYEE_DASHBOARD_ID($employeeId: Int!) {
    getEmployeeDashboard(employeeId: $employeeId) {
      error
      status
      data {
        id
        companyId
        farmId
        employeeFunctionId
        typeEmployee
        name
        documentOne
        documentTwo
        photo
        monthSalaryCurrency
        monthBonusCurrency
        monthSalaryFamilyCurrency
        daySalaryCurrency
        birthday
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getEmployeesDashboardGQL = gql`
  query GET_EMPLOYEES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getEmployeesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        employeeFunctionId
        typeEmployee
        name
        documentOne
        documentTwo
        photo
        monthSalaryCurrency
        monthBonusCurrency
        monthSalaryFamilyCurrency
        daySalaryCurrency
        birthday
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getHarvestsProductionsContainsDashboardGQL = gql`
  query GET_HARVESTS_PRODUCTIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
  ) {
    getHarvestsProductionsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        employeeId
        employee {
          id
          name
        }
        name
        dateHarvest
        measurements
        liters
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
