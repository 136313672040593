import { createSelector } from 'reselect'
import { OrderService } from 'types/orders/orders-services-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverOrderService } from 'services/orders/orders-services/orders-services-cruds'
import {
  getOrdersServicesDashboard,
  GetParams,
  getOrdersServicesContainsDashboard,
} from 'services/orders/orders-services/orders-services-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/orders/orders-services-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const orderServiceListSelector = (state: StateRoot) =>
  state.ordersServicesReducer

const selectorResult = createSelector(
  orderServiceListSelector,
  (orderServiceListReducer) => {
    return orderServiceListReducer
  },
)

export const useOrderServiceList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('orders_services')

  const {
    data: dataOrderService,
    deleteModalOpen,
    orderServiceIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'orderService', 'list', { params }],
    queryFn: () => getOrdersServicesDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverOrderService,
  })

  const handleStoreData = useCallback(
    (ordersServices: OrderService[], totalPages: number | null) => {
      if (!ordersServices) return

      dispatch(setData(ordersServices))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverOrderService = useCallback(async () => {
    if (!orderServiceIdToDelete) return

    const response = await mutateAsync({
      orderServiceId: +orderServiceIdToDelete,
    })

    if (response?.deleteForeverOrderServiceDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: orderServiceIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [orderServiceIdToDelete])

  useEffect(() => {
    const ordersServicesResult = data?.getOrdersServicesDashboard

    if (ordersServicesResult) {
      handleStoreData(
        ordersServicesResult?.data,
        ordersServicesResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getOrdersServicesContainsDashboard(value)
      const ordersServicesResult = response?.getOrdersServicesContainsDashboard

      handleStoreData(
        ordersServicesResult?.data,
        ordersServicesResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    orderServiceIdToDelete,
    orderService: data?.getOrdersServicesDashboard?.data,
    isLoading,
    isFetching,
    dataOrderService,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverOrderService,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
