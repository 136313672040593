import { PhoneNumberUtil } from 'google-libphonenumber'

export const phoneUtil = new PhoneNumberUtil()

export const formatDate = (date: string | Date) => {
  const newDate = new Date(+date)

  const day = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()

  return `${day}/${month}/${year}`
}

export const formatDateDB = (date: string | Date) => {
  const newDate = new Date(+date)

  const day = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()

  return `${year}-${month}-${day}`
}

export const formatDateUSA = (date: string) => {
  const [day, month, year] = date.split('/')

  return `${year}-${month}-${day}`
}
