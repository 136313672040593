import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { InputDecimalComponent } from 'components/common/inputs/input-decimal'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetTypesCoffeesTreatmentsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-treatments-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  farmFieldInfo: ReturnType<typeof useGetFarmsFieldsDashboard>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  typeCoffeeTreatmentInfo: ReturnType<
    typeof useGetTypesCoffeesTreatmentsDashboard
  >
  register: UseFormRegister<FormData>
}

export const FormControl = ({
  control,
  errors,
  farmFieldInfo,
  farmInfo,
  typeCoffeeTreatmentInfo,
  register,
}: Props) => {
  const permissions = useGetPermissions('orders_services')
  const { orderServiceSelected } = useSelector(selectorResult)
  const disabled = !!(orderServiceSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmFieldInfo?.farmsFieldsDashboard}
            info={farmFieldInfo}
            label={t('orders-services-farm-field-id')}
            name="farmFieldId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('orders-services-farm-id')}
            name="farmId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeCoffeeTreatmentInfo?.typesCoffeesTreatmentsDashboard}
            info={typeCoffeeTreatmentInfo}
            label={t('orders-services-type-coffee-treatment-id')}
            name="typeCoffeeTreatmentId"
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isBrush"
            label={t('orders-services-is-brush')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isPulverization"
            label={t('orders-services-is-pulverization')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isFertilizing"
            label={t('orders-services-is-fertilizing')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('orders-services-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="dateService"
            label={t('orders-services-date-service')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('orders-services-description')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.optionalDescription?.message}
            {...register('optionalDescription')}
            label={t('orders-services-optional-description')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.quantityHectare?.message}
            {...register('quantityHectare')}
            label={t('orders-services-quantity-hectare')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
