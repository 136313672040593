import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeEmployeeFunction,
  deleteEmployeeFunction,
} from 'services/employees/employees-functions/employees-functions-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectEmployeeFunction,
} from 'slices/employees/employees-functions-reducer'
import { EmployeeFunction } from 'types/employees/employees-functions-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('employees_functions')
  const dispatch = useDispatch()
  const item = cell?.row?.original as EmployeeFunction

  const handleOpenForm = () => {
    dispatch(selectEmployeeFunction(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectEmployeeFunction(itemWithoutId as EmployeeFunction))
    dispatch(openForm())
  }

  const { mutateAsync: deleteEmployeeFunctionDashboardFn } = useMutation({
    mutationFn: deleteEmployeeFunction,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteEmployeeFunctionDashboard } =
      await deleteEmployeeFunctionDashboardFn({
        employeeFunctionId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteEmployeeFunctionDashboard?.data))
  }

  const { mutateAsync: activeEmployeeFunctionDashboardFn } = useMutation({
    mutationFn: activeEmployeeFunction,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeEmployeeFunctionDashboard } =
      await activeEmployeeFunctionDashboardFn({
        employeeFunctionId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeEmployeeFunctionDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
