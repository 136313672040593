import { createSelector } from 'reselect'
import { AgriculturalInputCultivation } from 'types/agriculturals-inputs/agriculturals-inputs-cultivations-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverAgriculturalInputCultivation } from 'services/agriculturals-inputs/agriculturals-inputs-cultivations/agriculturals-inputs-cultivations-cruds'
import {
  getAgriculturalsInputsCultivationsDashboard,
  GetParams,
  getAgriculturalsInputsCultivationsContainsDashboard,
} from 'services/agriculturals-inputs/agriculturals-inputs-cultivations/agriculturals-inputs-cultivations-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/agriculturals-inputs/agriculturals-inputs-cultivations-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const agriculturalInputCultivationListSelector = (state: StateRoot) =>
  state.agriculturalsInputsCultivationsReducer

const selectorResult = createSelector(
  agriculturalInputCultivationListSelector,
  (agriculturalInputCultivationListReducer) => {
    return agriculturalInputCultivationListReducer
  },
)

export const useAgriculturalInputCultivationList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('agriculturals_inputs_cultivations')

  const {
    data: dataAgriculturalInputCultivation,
    deleteModalOpen,
    agriculturalInputCultivationIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'agriculturalInputCultivation', 'list', { params }],
    queryFn: () => getAgriculturalsInputsCultivationsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverAgriculturalInputCultivation,
  })

  const handleStoreData = useCallback(
    (
      agriculturalsInputsCultivations: AgriculturalInputCultivation[],
      totalPages: number | null,
    ) => {
      if (!agriculturalsInputsCultivations) return

      dispatch(setData(agriculturalsInputsCultivations))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverAgriculturalInputCultivation =
    useCallback(async () => {
      if (!agriculturalInputCultivationIdToDelete) return

      const response = await mutateAsync({
        agriculturalInputCultivationId: +agriculturalInputCultivationIdToDelete,
      })

      if (
        response?.deleteForeverAgriculturalInputCultivationDashboard ===
        'deleted_forever'
      ) {
        dispatch(
          deleteUniqueData({ id: agriculturalInputCultivationIdToDelete }),
        )
      }

      dispatch(closeDeleteModal())
    }, [agriculturalInputCultivationIdToDelete])

  useEffect(() => {
    const agriculturalsInputsCultivationsResult =
      data?.getAgriculturalsInputsCultivationsDashboard

    if (agriculturalsInputsCultivationsResult) {
      handleStoreData(
        agriculturalsInputsCultivationsResult?.data,
        agriculturalsInputsCultivationsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response =
        await getAgriculturalsInputsCultivationsContainsDashboard(value)
      const agriculturalsInputsCultivationsResult =
        response?.getAgriculturalsInputsCultivationsContainsDashboard

      handleStoreData(
        agriculturalsInputsCultivationsResult?.data,
        agriculturalsInputsCultivationsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    agriculturalInputCultivationIdToDelete,
    agriculturalInputCultivation:
      data?.getAgriculturalsInputsCultivationsDashboard?.data,
    isLoading,
    isFetching,
    dataAgriculturalInputCultivation,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverAgriculturalInputCultivation,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
