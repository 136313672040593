import { gql } from 'graphql-request'

export const createFarmUserDashboardGQL = gql`
  mutation CREATE_FARM_USER_DASHBOARD($name: String!, $farmId: Int!) {
    createFarmUserDashboard(name: $name, farmId: $farmId) {
      error
      status
      data {
        id
        name
        companyId
        company {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        farmId
        farm {
          id
          name
        }
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateFarmUserDashboardGQL = gql`
  mutation UPDATE_FARM_USER_DASHBOARD(
    $farmUserId: Int!
    $name: String
    $farmId: Int
  ) {
    updateFarmUserDashboard(
      farmUserId: $farmUserId
      name: $name
      farmId: $farmId
    ) {
      error
      status
      data {
        id
        name
        companyId
        company {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        farmId
        farm {
          id
          name
        }
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteFarmUserDashboardGQL = gql`
  mutation DELETE_FARM_USER_DASHBOARD($farmUserId: Int!, $deleted: Boolean!) {
    deleteFarmUserDashboard(farmUserId: $farmUserId, deleted: $deleted) {
      error
      status
      data {
        id
        name
        companyId
        userId
        farmId
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeFarmUserDashboardGQL = gql`
  mutation ACTIVE_FARM_USER_DASHBOARD($farmUserId: Int!, $active: Boolean!) {
    activeFarmUserDashboard(farmUserId: $farmUserId, active: $active) {
      error
      status
      data {
        id
        name
        companyId
        userId
        farmId
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverFarmUserDashboardGQL = gql`
  mutation DELETE_FOREVER_FARM_USER_DASHBOARD($farmUserId: Int!) {
    deleteForeverFarmUserDashboard(farmUserId: $farmUserId)
  }
`

export const getFarmsUsersDashboardGQL = gql`
  query GET_FARMS_USERS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $userId: Int
    $farmId: Int
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getFarmsUsersDashboard(
      take: $take
      skip: $skip
      name: $name
      userId: $userId
      farmId: $farmId
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        companyId
        company {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        farmId
        farm {
          id
          name
        }
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUserDashboardIdGQL = gql`
  query GET_USER_DASHBOARD_ID($userId: Int!) {
    getUserDashboard(userId: $userId) {
      error
      status
      data {
        id
        userTypeId
        companyId
        farmId
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUsersDashboardGQL = gql`
  query GET_USERS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getUsersDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        userTypeId
        companyId
        farmId
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsUsersContainsDashboardGQL = gql`
  query GET_FARMS_USERS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
  ) {
    getFarmsUsersContainsDashboard(take: $take, skip: $skip, name: $name) {
      error
      status
      totalPages
      data {
        id
        name
        companyId
        company {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        farmId
        farm {
          id
          name
        }
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
