import { graphClient } from 'services/graph-client'
import { Farm, Area, FarmArea } from 'types/farms/farms-areas-types'

import {
  getFarmsDashboardGQL,
  getAreasDashboardGQL,
  getFarmsAreasDashboardGQL,
  getFarmsAreasContainsDashboardGQL,
} from './queries'

interface Response {
  getFarmsAreasDashboard: {
    data: FarmArea[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getFarmsAreasContainsDashboard: {
    data: FarmArea[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  name?: string
  description?: string
  farmId?: number
  areaId?: number
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getFarmsAreasDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getFarmsAreasDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getFarmsAreasDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getFarmsAreasDashboardGQL, {
    take,
    skip,
  })
}

export const getFarmsAreasContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getFarmsAreasContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseFarm {
  getFarmsDashboard: {
    data: Farm[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarm>(getFarmsDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseArea {
  getAreasDashboard: {
    data: Area[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAreasDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseArea>(getAreasDashboardGQL, {
    take,
    skip,
  })
}
