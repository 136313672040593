import { useInfiniteQuery } from '@tanstack/react-query'
import { getAgriculturalsInputsModesActionsDashboardHook } from 'services/_hooks/agriculturals-inputs-modes-actions-gets'

export const useGetAgriculturalsInputsModesActionsDashboard = (take = 4) => {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: [
        'get',
        'agriculturalsInputsModesActionsDashboard',
        'list',
        'total',
      ],
      initialPageParam: 0,
      queryFn: ({ pageParam }) => {
        return getAgriculturalsInputsModesActionsDashboardHook(pageParam, take)
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const total =
          lastPage?.getAgriculturalsInputsModesActionsDashboard?.totalPages ?? 4
        const isLastPage = allPages?.length >= total

        if (isLastPage) {
          return null
        }

        return lastPageParam + 1
      },
    })

  const agriculturalsInputsModesActionsDashboard = data?.pages?.flatMap(
    (page) => {
      return page?.getAgriculturalsInputsModesActionsDashboard?.data
    },
  )

  return {
    agriculturalsInputsModesActionsDashboard:
      agriculturalsInputsModesActionsDashboard ?? [],
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  }
}
