import { Button } from 'reactstrap'

interface Props {
  handleSaveEdit: () => void
  handleCancelEdit: () => void
}

export const ActionsButtons = ({ handleSaveEdit, handleCancelEdit }: Props) => {
  return (
    <div
      className="position-absolute d-flex flex-row"
      style={{ top: 70, right: 20, gap: 8 }}
    >
      <Button className="btn-success" onClick={handleSaveEdit}>
        Salvar Edição
      </Button>
      <Button onClick={handleCancelEdit} className="btn-danger">
        Cancelar Edição
      </Button>
    </div>
  )
}
