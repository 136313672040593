import { graphClient } from 'services/graph-client'
import {
  activeFarmUserDashboardGQL,
  deleteFarmUserDashboardGQL,
  deleteForeverFarmUserDashboardGQL,
  createFarmUserDashboardGQL,
  updateFarmUserDashboardGQL,
} from './queries'
import { FarmUser } from 'types/farms/farms-users-types'

interface ResponseCreate {
  createFarmUserDashboard: {
    data: FarmUser
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateFarmUserDashboard: {
    data: FarmUser
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeFarmUserDashboard: {
    data: FarmUser
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteFarmUserDashboard: {
    data: FarmUser
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverFarmUserDashboard: string
}

interface DataActive {
  farmUserId: number
  active: boolean
}

interface DataDeleted {
  farmUserId: number
  deleted: boolean
}

interface DataDeleteForever {
  farmUserId: number
}

interface DataCreate {
  name: string
  farmId: number
}

interface DataUpdate {
  farmUserId: number
  name: string
  farmId: number
}

export const activeFarmUser = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeFarmUserDashboardGQL, {
    ...data,
  })
}

export const deleteFarmUser = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteFarmUserDashboardGQL, {
    ...data,
  })
}

export const deleteForeverFarmUser = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverFarmUserDashboardGQL,
    { ...data },
  )
}

export const createFarmUser = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(createFarmUserDashboardGQL, {
    ...data,
  })
}

export const updateFarmUser = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateFarmUserDashboardGQL, {
    ...data,
  })
}
