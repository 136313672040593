import { graphClient } from 'services/graph-client'
import { TypeCoffeeManagement } from 'types/types-coffees/types-coffees-managements-types'

import { getTypesCoffeesManagementsDashboardGQL } from 'services/types-coffees/types-coffees-managements/queries'

interface Response {
  getTypesCoffeesManagementsDashboard: {
    data: TypeCoffeeManagement[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesManagementsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getTypesCoffeesManagementsDashboardGQL,
    {
      take,
      skip,
    },
  )
}
