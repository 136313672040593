import { ModalFormTemplate } from 'components/common/modal/form-template'
import { t } from 'i18next'
import { useUserList } from '../../use-users'
import { useModalFormFilter } from './use-filter-form'
import { FormProvider } from 'react-hook-form'
import { FormFilterControl } from '../../components/form-filter'
import { formatDateDB } from 'helpers/format/format-date'

export const Filter = () => {
  const { filterOpen, handleCloseFilter, handleSetFilter } = useUserList()

  const {
    control,
    errors,
    form,
    userTypeInfo,
    farmInfo,
    handleSelectCountry,
    register,
    resetFields,
    getValues,
  } = useModalFormFilter()

  const handleConfirm = () => {
    const values: Record<string, any> = {}
    const formValues = getValues()

    if (typeof formValues.userTypeId != 'undefined') {
      if (typeof formValues.userTypeId === 'object') {
        values['userTypeId'] = formValues.userTypeId.value
      }
    }

    if (typeof formValues.farmId != 'undefined') {
      if (typeof formValues.farmId === 'object') {
        values['farmId'] = formValues.farmId.value
      }
    }

    if (
      typeof formValues.firstName != 'undefined' &&
      formValues.firstName != ''
    ) {
      values['firstName'] = formValues.firstName
    }

    if (
      typeof formValues.lastName != 'undefined' &&
      formValues.lastName != ''
    ) {
      values['lastName'] = formValues.lastName
    }

    if (typeof formValues.name != 'undefined' && formValues.name != '') {
      values['name'] = formValues.name
    }

    if (typeof formValues.country != 'undefined' && formValues.country != '') {
      values['country'] = formValues.country
    }

    if (typeof formValues.state != 'undefined' && formValues.state != '') {
      values['state'] = formValues.state
    }

    if (typeof formValues.utc != 'undefined' && formValues.utc != '') {
      values['utc'] = formValues.utc
    }

    if (
      typeof formValues.language != 'undefined' &&
      formValues.language != ''
    ) {
      values['language'] = formValues.language
    }

    if (typeof formValues.gender != 'undefined' && formValues.gender != '') {
      values['gender'] = formValues.gender
    }

    if (
      typeof formValues.phone != 'undefined' &&
      formValues.phone.phone != ''
    ) {
      values['phone'] = formValues.phone['phone']
    }

    if (typeof formValues.active != 'undefined') {
      if (typeof formValues.active === 'object') {
        if (formValues.active.value === 'true') {
          values['active'] = true
        }
        if (formValues.active.value === 'false') {
          values['active'] = false
        }
      }
    }

    if (typeof formValues.deleted != 'undefined') {
      if (typeof formValues.deleted === 'object') {
        if (formValues.deleted.value === 'true') {
          values['deleted'] = true
        }
        if (formValues.deleted.value === 'false') {
          values['deleted'] = false
        }
      }
    }

    if (
      typeof formValues.birthday != 'undefined' &&
      formValues.birthday != ''
    ) {
      values['birthday'] = formatDateDB(formValues.birthday)
    }

    if (
      typeof formValues.createdAtInitial != 'undefined' &&
      formValues.createdAtInitial != '' &&
      typeof formValues.createdAtFinal != 'undefined' &&
      formValues.createdAtFinal != ''
    ) {
      values['createdAtInitial'] = formatDateDB(formValues.createdAtInitial)
      values['createdAtFinal'] = formatDateDB(formValues.createdAtFinal)
    }

    handleSetFilter(values)
    handleCloseFilter()
  }

  const handleClear = () => {
    resetFields()
  }

  return (
    <ModalFormTemplate
      isOpen={filterOpen}
      //full={false}
      //middle
      full={true}
      title={t('filters')}
      cancelLabel={t('cancel-filter')}
      handleConfirm={handleConfirm}
      handleClose={handleCloseFilter}
      handleCancel={handleClear}
    >
      <form
        style={{ paddingLeft: 4 }}
        className="mt-4 pe-1 d-flex flex-wrap gap-3"
      >
        <FormProvider {...form}>
          <FormFilterControl
            control={control}
            errors={errors}
            userTypeInfo={userTypeInfo}
            farmInfo={farmInfo}
            handleSelectCountry={handleSelectCountry}
            register={register}
          />
        </FormProvider>
      </form>
    </ModalFormTemplate>
  )
}
