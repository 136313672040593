import { gql } from 'graphql-request'

export const createAreaDashboardGQL = gql`
  mutation CREATE_AREA_DASHBOARD(
    $name: String!
    $description: String!
    $geographicLocation: String
    $hectareQuantity: Float!
    $usefullHectareQuantity: Float!
  ) {
    createAreaDashboard(
      name: $name
      description: $description
      geographicLocation: $geographicLocation
      hectareQuantity: $hectareQuantity
      usefullHectareQuantity: $usefullHectareQuantity
    ) {
      error
      status
      data {
        id
        name
        description
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateAreaDashboardGQL = gql`
  mutation UPDATE_AREA_DASHBOARD(
    $areaId: Int!
    $name: String
    $description: String
    $geographicLocation: String
    $hectareQuantity: Float
    $usefullHectareQuantity: Float
  ) {
    updateAreaDashboard(
      areaId: $areaId
      name: $name
      description: $description
      geographicLocation: $geographicLocation
      hectareQuantity: $hectareQuantity
      usefullHectareQuantity: $usefullHectareQuantity
    ) {
      error
      status
      data {
        id
        name
        description
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteAreaDashboardGQL = gql`
  mutation DELETE_AREA_DASHBOARD($areaId: Int!, $deleted: Boolean!) {
    deleteAreaDashboard(areaId: $areaId, deleted: $deleted) {
      error
      status
      data {
        id
        name
        description
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeAreaDashboardGQL = gql`
  mutation ACTIVE_AREA_DASHBOARD($areaId: Int!, $active: Boolean!) {
    activeAreaDashboard(areaId: $areaId, active: $active) {
      error
      status
      data {
        id
        name
        description
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverAreaDashboardGQL = gql`
  mutation DELETE_FOREVER_AREA_DASHBOARD($areaId: Int!) {
    deleteForeverAreaDashboard(areaId: $areaId)
  }
`

export const getAreasDashboardGQL = gql`
  query GET_AREAS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $geographicLocation: String
    $hectareQuantity: Float
    $usefullHectareQuantity: Float
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getAreasDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      geographicLocation: $geographicLocation
      hectareQuantity: $hectareQuantity
      usefullHectareQuantity: $usefullHectareQuantity
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAreasContainsDashboardGQL = gql`
  query GET_AREAS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $geographicLocation: String
  ) {
    getAreasContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      geographicLocation: $geographicLocation
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
