import { Status } from './list-columns'
import { t } from 'i18next'

export const columns = [
  {
    Header: t('id'),
    accessor: 'id',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span className="fw-medium link-primary">{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-roles-company-id'),
    accessor: 'company.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-roles-user-id'),
    accessor: 'user.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-roles-role-id'),
    accessor: 'role.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-roles-name'),
    accessor: 'name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-roles-name-index'),
    accessor: 'nameIndex',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-roles-can-menu'),
    accessor: 'canMenu',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('users-roles-can-add'),
    accessor: 'canAdd',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('users-roles-can-duplicate'),
    accessor: 'canDuplicate',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('users-roles-can-edit'),
    accessor: 'canEdit',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('users-roles-can-delete-forever'),
    accessor: 'canDeleteForever',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('users-roles-can-export'),
    accessor: 'canExport',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('users-roles-can-import'),
    accessor: 'canImport',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('users-roles-can-active'),
    accessor: 'canActive',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('users-roles-can-deleted'),
    accessor: 'canDeleted',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('active'),
    accessor: 'actions',
    filterable: false,
    Cell: (props: any, cellProps: any) => {
      return <Status {...cellProps} {...props} showActive={true} />
    },
  },
]
