import { gql } from 'graphql-request'

export const createRoleDashboardGQL = gql`
  mutation CREATE_ROLE_DASHBOARD(
    $userTypeId: Int!
    $name: String!
    $description: String!
    $nameIndex: String!
    $canMenu: Boolean!
    $canAdd: Boolean!
    $canDuplicate: Boolean!
    $canEdit: Boolean!
    $canDeleteForever: Boolean!
    $canExport: Boolean!
    $canImport: Boolean!
    $canActive: Boolean!
    $canDeleted: Boolean!
  ) {
    createRoleDashboard(
      userTypeId: $userTypeId
      name: $name
      description: $description
      nameIndex: $nameIndex
      canMenu: $canMenu
      canAdd: $canAdd
      canDuplicate: $canDuplicate
      canEdit: $canEdit
      canDeleteForever: $canDeleteForever
      canExport: $canExport
      canImport: $canImport
      canActive: $canActive
      canDeleted: $canDeleted
    ) {
      error
      status
      data {
        id
        userTypeId
        name
        description
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateRoleDashboardGQL = gql`
  mutation UPDATE_ROLE_DASHBOARD(
    $roleId: Int!
    $userTypeId: Int
    $name: String
    $description: String
    $nameIndex: String
    $canMenu: Boolean
    $canAdd: Boolean
    $canDuplicate: Boolean
    $canEdit: Boolean
    $canDeleteForever: Boolean
    $canExport: Boolean
    $canImport: Boolean
    $canActive: Boolean
    $canDeleted: Boolean
  ) {
    updateRoleDashboard(
      roleId: $roleId
      userTypeId: $userTypeId
      name: $name
      description: $description
      nameIndex: $nameIndex
      canMenu: $canMenu
      canAdd: $canAdd
      canDuplicate: $canDuplicate
      canEdit: $canEdit
      canDeleteForever: $canDeleteForever
      canExport: $canExport
      canImport: $canImport
      canActive: $canActive
      canDeleted: $canDeleted
    ) {
      error
      status
      data {
        id
        userTypeId
        name
        description
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteRoleDashboardGQL = gql`
  mutation DELETE_ROLE_DASHBOARD($roleId: Int!, $deleted: Boolean!) {
    deleteRoleDashboard(roleId: $roleId, deleted: $deleted) {
      error
      status
      data {
        id
        userTypeId
        name
        description
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeRoleDashboardGQL = gql`
  mutation ACTIVE_ROLE_DASHBOARD($roleId: Int!, $active: Boolean!) {
    activeRoleDashboard(roleId: $roleId, active: $active) {
      error
      status
      data {
        id
        userTypeId
        name
        description
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverRoleDashboardGQL = gql`
  mutation DELETE_FOREVER_ROLE_DASHBOARD($roleId: Int!) {
    deleteForeverRoleDashboard(roleId: $roleId)
  }
`

export const getRolesDashboardGQL = gql`
  query GET_ROLES_DASHBOARD(
    $take: Int
    $skip: Int
    $userTypeId: Int
    $name: String
    $description: String
    $nameIndex: String
    $canMenu: Boolean
    $canAdd: Boolean
    $canDuplicate: Boolean
    $canEdit: Boolean
    $canDeleteForever: Boolean
    $canExport: Boolean
    $canImport: Boolean
    $canActive: Boolean
    $canDeleted: Boolean
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getRolesDashboard(
      take: $take
      skip: $skip
      userTypeId: $userTypeId
      name: $name
      description: $description
      nameIndex: $nameIndex
      canMenu: $canMenu
      canAdd: $canAdd
      canDuplicate: $canDuplicate
      canEdit: $canEdit
      canDeleteForever: $canDeleteForever
      canExport: $canExport
      canImport: $canImport
      canActive: $canActive
      canDeleted: $canDeleted
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        userTypeId
        name
        description
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getRolesContainsDashboardGQL = gql`
  query GET_ROLES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $nameIndex: String
  ) {
    getRolesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      nameIndex: $nameIndex
    ) {
      error
      status
      totalPages
      data {
        id
        userTypeId
        name
        description
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
