import { Status } from './list-columns'
import { formatDate } from 'helpers/format/format-date'
import { t } from 'i18next'

export const columns = [
  {
    Header: t('id'),
    accessor: 'id',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span className="fw-medium link-primary">{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-company-id'),
    accessor: 'company.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-farm-field-id'),
    accessor: 'farmField.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-farm-id'),
    accessor: 'farm.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-type-coffee-treatment-id'),
    accessor: 'typeCoffeeTreatment.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-name'),
    accessor: 'name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-description'),
    accessor: 'description',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-optional-description'),
    accessor: 'optionalDescription',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-quantity-hectare'),
    accessor: 'quantityHectare',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-is-brush'),
    accessor: 'isBrush',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('orders-services-is-pulverization'),
    accessor: 'isPulverization',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('orders-services-is-fertilizing'),
    accessor: 'isFertilizing',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('orders-services-date-service'),
    accessor: 'dateService',
    filterable: false,
    Cell: (cellProps: any) => {
      const date = cellProps?.value

      return <span>{formatDate(date)}</span>
    },
  },
  {
    Header: t('active'),
    accessor: 'actions',
    filterable: false,
    Cell: (props: any, cellProps: any) => {
      return <Status {...cellProps} {...props} showActive={true} />
    },
  },
]
