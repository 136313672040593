import { gql } from 'graphql-request'

export const createFarmFieldDashboardGQL = gql`
  mutation CREATE_FARM_FIELD_DASHBOARD(
    $farmId: Int
    $areaId: Int
    $farmFieldPeriodId: Int!
    $typeCoffeeColorId: Int!
    $typeCoffeeSizeId: Int!
    $typeCoffeeMaturationId: Int!
    $typeCoffeeIrrigationId: Int!
    $typeCoffeeVarietyId: Int!
    $coffeePruningId: Int!
    $typeCoffeePruningId: Int!
    $typeCoffeeManagementId: Int!
    $typeCoffeeHarvestId: Int!
    $typeCoffeeRenovationId: Int!
    $name: String!
    $yearPlanting: Int!
    $monthPlanting: String!
    $lineSpacing: Float!
    $plantSpacing: Float!
    $plantFailure: Int!
    $linearQuantity: Float!
    $plantingQuantity: Int!
    $geographicLocation: String
    $hectareQuantity: Float!
    $usefullHectareQuantity: Float!
  ) {
    createFarmFieldDashboard(
      farmId: $farmId
      areaId: $areaId
      farmFieldPeriodId: $farmFieldPeriodId
      typeCoffeeColorId: $typeCoffeeColorId
      typeCoffeeSizeId: $typeCoffeeSizeId
      typeCoffeeMaturationId: $typeCoffeeMaturationId
      typeCoffeeIrrigationId: $typeCoffeeIrrigationId
      typeCoffeeVarietyId: $typeCoffeeVarietyId
      coffeePruningId: $coffeePruningId
      typeCoffeePruningId: $typeCoffeePruningId
      typeCoffeeManagementId: $typeCoffeeManagementId
      typeCoffeeHarvestId: $typeCoffeeHarvestId
      typeCoffeeRenovationId: $typeCoffeeRenovationId
      name: $name
      yearPlanting: $yearPlanting
      monthPlanting: $monthPlanting
      lineSpacing: $lineSpacing
      plantSpacing: $plantSpacing
      plantFailure: $plantFailure
      linearQuantity: $linearQuantity
      plantingQuantity: $plantingQuantity
      geographicLocation: $geographicLocation
      hectareQuantity: $hectareQuantity
      usefullHectareQuantity: $usefullHectareQuantity
    ) {
      error
      status
      data {
        id
        areaId
        area {
          id
          name
        }
        coffeePruningId
        coffeePruning {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        farmFieldPeriodId
        farmFieldPeriod {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCoffeeColorId
        typeCoffeeColor {
          id
          name
        }
        typeCoffeeHarvestId
        typeCoffeeHarvest {
          id
          name
        }
        typeCoffeeIrrigationId
        typeCoffeeIrrigation {
          id
          name
        }
        typeCoffeeManagementId
        typeCoffeeManagement {
          id
          name
        }
        typeCoffeeMaturationId
        typeCoffeeMaturation {
          id
          name
        }
        typeCoffeePruningId
        typeCoffeePruning {
          id
          name
        }
        typeCoffeeSizeId
        typeCoffeeSize {
          id
          name
        }
        typeCoffeeVarietyId
        typeCoffeeVariety {
          id
          name
        }
        typeCoffeeRenovationId
        typeCoffeeRenovation {
          id
          name
        }
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateFarmFieldDashboardGQL = gql`
  mutation UPDATE_FARM_FIELD_DASHBOARD(
    $farmFieldId: Int!
    $farmId: Int
    $areaId: Int
    $farmFieldPeriodId: Int
    $typeCoffeeColorId: Int
    $typeCoffeeSizeId: Int
    $typeCoffeeMaturationId: Int
    $typeCoffeeIrrigationId: Int
    $typeCoffeeVarietyId: Int
    $coffeePruningId: Int
    $typeCoffeePruningId: Int
    $typeCoffeeManagementId: Int
    $typeCoffeeHarvestId: Int
    $typeCoffeeRenovationId: Int
    $name: String
    $yearPlanting: Int
    $monthPlanting: String
    $lineSpacing: Float
    $plantSpacing: Float
    $plantFailure: Int
    $linearQuantity: Float
    $plantingQuantity: Int
    $geographicLocation: String
    $hectareQuantity: Float
    $usefullHectareQuantity: Float
  ) {
    updateFarmFieldDashboard(
      farmFieldId: $farmFieldId
      farmId: $farmId
      areaId: $areaId
      farmFieldPeriodId: $farmFieldPeriodId
      typeCoffeeColorId: $typeCoffeeColorId
      typeCoffeeSizeId: $typeCoffeeSizeId
      typeCoffeeMaturationId: $typeCoffeeMaturationId
      typeCoffeeIrrigationId: $typeCoffeeIrrigationId
      typeCoffeeVarietyId: $typeCoffeeVarietyId
      coffeePruningId: $coffeePruningId
      typeCoffeePruningId: $typeCoffeePruningId
      typeCoffeeManagementId: $typeCoffeeManagementId
      typeCoffeeHarvestId: $typeCoffeeHarvestId
      typeCoffeeRenovationId: $typeCoffeeRenovationId
      name: $name
      yearPlanting: $yearPlanting
      monthPlanting: $monthPlanting
      lineSpacing: $lineSpacing
      plantSpacing: $plantSpacing
      plantFailure: $plantFailure
      linearQuantity: $linearQuantity
      plantingQuantity: $plantingQuantity
      geographicLocation: $geographicLocation
      hectareQuantity: $hectareQuantity
      usefullHectareQuantity: $usefullHectareQuantity
    ) {
      error
      status
      data {
        id
        areaId
        area {
          id
          name
        }
        coffeePruningId
        coffeePruning {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        farmFieldPeriodId
        farmFieldPeriod {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCoffeeColorId
        typeCoffeeColor {
          id
          name
        }
        typeCoffeeHarvestId
        typeCoffeeHarvest {
          id
          name
        }
        typeCoffeeIrrigationId
        typeCoffeeIrrigation {
          id
          name
        }
        typeCoffeeManagementId
        typeCoffeeManagement {
          id
          name
        }
        typeCoffeeMaturationId
        typeCoffeeMaturation {
          id
          name
        }
        typeCoffeePruningId
        typeCoffeePruning {
          id
          name
        }
        typeCoffeeSizeId
        typeCoffeeSize {
          id
          name
        }
        typeCoffeeVarietyId
        typeCoffeeVariety {
          id
          name
        }
        typeCoffeeRenovationId
        typeCoffeeRenovation {
          id
          name
        }
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteFarmFieldDashboardGQL = gql`
  mutation DELETE_FARM_FIELD_DASHBOARD($farmFieldId: Int!, $deleted: Boolean!) {
    deleteFarmFieldDashboard(farmFieldId: $farmFieldId, deleted: $deleted) {
      error
      status
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeFarmFieldDashboardGQL = gql`
  mutation ACTIVE_FARM_FIELD_DASHBOARD($farmFieldId: Int!, $active: Boolean!) {
    activeFarmFieldDashboard(farmFieldId: $farmFieldId, active: $active) {
      error
      status
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverFarmFieldDashboardGQL = gql`
  mutation DELETE_FOREVER_FARM_FIELD_DASHBOARD($farmFieldId: Int!) {
    deleteForeverFarmFieldDashboard(farmFieldId: $farmFieldId)
  }
`

export const getFarmsFieldsDashboardGQL = gql`
  query GET_FARMS_FIELDS_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $areaId: Int
    $farmFieldPeriodId: Int
    $typeCoffeeColorId: Int
    $typeCoffeeSizeId: Int
    $typeCoffeeMaturationId: Int
    $typeCoffeeIrrigationId: Int
    $typeCoffeeVarietyId: Int
    $coffeePruningId: Int
    $typeCoffeePruningId: Int
    $typeCoffeeManagementId: Int
    $typeCoffeeHarvestId: Int
    $typeCoffeeRenovationId: Int
    $name: String
    $yearPlanting: Int
    $monthPlanting: String
    $lineSpacing: Float
    $plantSpacing: Float
    $plantFailure: Int
    $linearQuantity: Float
    $plantingQuantity: Int
    $geographicLocation: String
    $hectareQuantity: Float
    $usefullHectareQuantity: Float
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getFarmsFieldsDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      areaId: $areaId
      farmFieldPeriodId: $farmFieldPeriodId
      typeCoffeeColorId: $typeCoffeeColorId
      typeCoffeeSizeId: $typeCoffeeSizeId
      typeCoffeeMaturationId: $typeCoffeeMaturationId
      typeCoffeeIrrigationId: $typeCoffeeIrrigationId
      typeCoffeeVarietyId: $typeCoffeeVarietyId
      coffeePruningId: $coffeePruningId
      typeCoffeePruningId: $typeCoffeePruningId
      typeCoffeeManagementId: $typeCoffeeManagementId
      typeCoffeeHarvestId: $typeCoffeeHarvestId
      typeCoffeeRenovationId: $typeCoffeeRenovationId
      name: $name
      yearPlanting: $yearPlanting
      monthPlanting: $monthPlanting
      lineSpacing: $lineSpacing
      plantSpacing: $plantSpacing
      plantFailure: $plantFailure
      linearQuantity: $linearQuantity
      plantingQuantity: $plantingQuantity
      geographicLocation: $geographicLocation
      hectareQuantity: $hectareQuantity
      usefullHectareQuantity: $usefullHectareQuantity
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        areaId
        area {
          id
          name
        }
        coffeePruningId
        coffeePruning {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        farmFieldPeriodId
        farmFieldPeriod {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCoffeeColorId
        typeCoffeeColor {
          id
          name
        }
        typeCoffeeHarvestId
        typeCoffeeHarvest {
          id
          name
        }
        typeCoffeeIrrigationId
        typeCoffeeIrrigation {
          id
          name
        }
        typeCoffeeManagementId
        typeCoffeeManagement {
          id
          name
        }
        typeCoffeeMaturationId
        typeCoffeeMaturation {
          id
          name
        }
        typeCoffeePruningId
        typeCoffeePruning {
          id
          name
        }
        typeCoffeeSizeId
        typeCoffeeSize {
          id
          name
        }
        typeCoffeeVarietyId
        typeCoffeeVariety {
          id
          name
        }
        typeCoffeeRenovationId
        typeCoffeeRenovation {
          id
          name
        }
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAreaDashboardIdGQL = gql`
  query GET_AREA_DASHBOARD_ID($areaId: Int!) {
    getAreaDashboard(areaId: $areaId) {
      error
      status
      data {
        id
        name
        description
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAreasDashboardGQL = gql`
  query GET_AREAS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getAreasDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCoffeePruningDashboardIdGQL = gql`
  query GET_COFFEE_PRUNING_DASHBOARD_ID($coffeePruningId: Int!) {
    getCoffeePruningDashboard(coffeePruningId: $coffeePruningId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCoffeesPruningsDashboardGQL = gql`
  query GET_COFFEES_PRUNINGS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCoffeesPruningsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmFieldPeriodDashboardIdGQL = gql`
  query GET_FARM_FIELD_PERIOD_DASHBOARD_ID($farmFieldPeriodId: Int!) {
    getFarmFieldPeriodDashboard(farmFieldPeriodId: $farmFieldPeriodId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsFieldsPeriodsDashboardGQL = gql`
  query GET_FARMS_FIELDS_PERIODS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsFieldsPeriodsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCoffeeColorDashboardIdGQL = gql`
  query GET_TYPE_COFFEE_COLOR_DASHBOARD_ID($typeCoffeeColorId: Int!) {
    getTypeCoffeeColorDashboard(typeCoffeeColorId: $typeCoffeeColorId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesColorsDashboardGQL = gql`
  query GET_TYPES_COFFEES_COLORS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCoffeesColorsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCoffeeHarvestDashboardIdGQL = gql`
  query GET_TYPE_COFFEE_HARVEST_DASHBOARD_ID($typeCoffeeHarvestId: Int!) {
    getTypeCoffeeHarvestDashboard(typeCoffeeHarvestId: $typeCoffeeHarvestId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesHarvestsDashboardGQL = gql`
  query GET_TYPES_COFFEES_HARVESTS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCoffeesHarvestsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCoffeeIrrigationDashboardIdGQL = gql`
  query GET_TYPE_COFFEE_IRRIGATION_DASHBOARD_ID($typeCoffeeIrrigationId: Int!) {
    getTypeCoffeeIrrigationDashboard(
      typeCoffeeIrrigationId: $typeCoffeeIrrigationId
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesIrrigationsDashboardGQL = gql`
  query GET_TYPES_COFFEES_IRRIGATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCoffeesIrrigationsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCoffeeManagementDashboardIdGQL = gql`
  query GET_TYPE_COFFEE_MANAGEMENT_DASHBOARD_ID($typeCoffeeManagementId: Int!) {
    getTypeCoffeeManagementDashboard(
      typeCoffeeManagementId: $typeCoffeeManagementId
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesManagementsDashboardGQL = gql`
  query GET_TYPES_COFFEES_MANAGEMENTS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCoffeesManagementsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCoffeeMaturationDashboardIdGQL = gql`
  query GET_TYPE_COFFEE_MATURATION_DASHBOARD_ID($typeCoffeeMaturationId: Int!) {
    getTypeCoffeeMaturationDashboard(
      typeCoffeeMaturationId: $typeCoffeeMaturationId
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesMaturationsDashboardGQL = gql`
  query GET_TYPES_COFFEES_MATURATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCoffeesMaturationsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCoffeePruningDashboardIdGQL = gql`
  query GET_TYPE_COFFEE_PRUNING_DASHBOARD_ID($typeCoffeePruningId: Int!) {
    getTypeCoffeePruningDashboard(typeCoffeePruningId: $typeCoffeePruningId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesPruningsDashboardGQL = gql`
  query GET_TYPES_COFFEES_PRUNINGS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCoffeesPruningsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCoffeeSizeDashboardIdGQL = gql`
  query GET_TYPE_COFFEE_SIZE_DASHBOARD_ID($typeCoffeeSizeId: Int!) {
    getTypeCoffeeSizeDashboard(typeCoffeeSizeId: $typeCoffeeSizeId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesSizesDashboardGQL = gql`
  query GET_TYPES_COFFEES_SIZES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCoffeesSizesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCoffeeVarietyDashboardIdGQL = gql`
  query GET_TYPE_COFFEE_VARIETY_DASHBOARD_ID($typeCoffeeVarietyId: Int!) {
    getTypeCoffeeVarietyDashboard(typeCoffeeVarietyId: $typeCoffeeVarietyId) {
      error
      status
      data {
        id
        companyId
        name
        description
        numbering
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesVarietiesDashboardGQL = gql`
  query GET_TYPES_COFFEES_VARIETIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCoffeesVarietiesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        numbering
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCoffeeRenovationDashboardIdGQL = gql`
  query GET_TYPE_COFFEE_RENOVATION_DASHBOARD_ID($typeCoffeeRenovationId: Int!) {
    getTypeCoffeeRenovationDashboard(
      typeCoffeeRenovationId: $typeCoffeeRenovationId
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesRenovationsDashboardGQL = gql`
  query GET_TYPES_COFFEES_RENOVATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCoffeesRenovationsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsFieldsContainsDashboardGQL = gql`
  query GET_FARMS_FIELDS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $monthPlanting: String
    $geographicLocation: String
  ) {
    getFarmsFieldsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      monthPlanting: $monthPlanting
      geographicLocation: $geographicLocation
    ) {
      error
      status
      totalPages
      data {
        id
        areaId
        area {
          id
          name
        }
        coffeePruningId
        coffeePruning {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        farmFieldPeriodId
        farmFieldPeriod {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCoffeeColorId
        typeCoffeeColor {
          id
          name
        }
        typeCoffeeHarvestId
        typeCoffeeHarvest {
          id
          name
        }
        typeCoffeeIrrigationId
        typeCoffeeIrrigation {
          id
          name
        }
        typeCoffeeManagementId
        typeCoffeeManagement {
          id
          name
        }
        typeCoffeeMaturationId
        typeCoffeeMaturation {
          id
          name
        }
        typeCoffeePruningId
        typeCoffeePruning {
          id
          name
        }
        typeCoffeeSizeId
        typeCoffeeSize {
          id
          name
        }
        typeCoffeeVarietyId
        typeCoffeeVariety {
          id
          name
        }
        typeCoffeeRenovationId
        typeCoffeeRenovation {
          id
          name
        }
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
