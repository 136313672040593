import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatCurrencyToSend } from 'helpers/format/format-currency'
import {
  createHarvestCostAgriculturalInput,
  updateHarvestCostAgriculturalInput,
} from 'services/harvests/harvests-costs-agriculturals-inputs/harvests-costs-agriculturals-inputs-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectHarvestCostAgriculturalInput,
} from 'slices/harvests/harvests-costs-agriculturals-inputs-reducer'
import { z } from 'zod'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetHarvestsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-harvests-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('harvests-costs-agriculturals-inputs-farm-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('harvests-costs-agriculturals-inputs-farm-field-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  harvestId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('harvests-costs-agriculturals-inputs-harvest-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('harvests-costs-agriculturals-inputs-name'),
    ),

  description: z.string(),
  expectedNumberService: z.string(),
  numberService: z.string(),
  serviceValueApportionment: z.string(),
  valueAgriculturalInput: z.string(),
})

export type FormData = z.infer<typeof schema>

const harvestCostAgriculturalInputModalFormSelector = (state: StateRoot) => {
  return state.harvestsCostsAgriculturalsInputsReducer
}

export const selectorResult = createSelector(
  harvestCostAgriculturalInputModalFormSelector,
  (harvestCostAgriculturalInputReducer) => {
    return harvestCostAgriculturalInputReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, harvestCostAgriculturalInputSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('harvests_costs_agriculturals_inputs')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const farmFieldInfo = useGetFarmsFieldsDashboard()
  const farmInfo = useGetFarmsDashboard()
  const harvestInfo = useGetHarvestsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectHarvestCostAgriculturalInput(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createHarvestCostAgriculturalInputFn, isPending } =
    useMutation({
      mutationFn: createHarvestCostAgriculturalInput,
    })

  const {
    mutateAsync: updateHarvestCostAgriculturalInputFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateHarvestCostAgriculturalInput,
  })

  const cleanFields: FormData = {
    farmId: '',
    farmFieldId: '',
    harvestId: '',
    name: '',
    description: '',
    expectedNumberService: '',
    numberService: '',
    serviceValueApportionment: '',
    valueAgriculturalInput: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!harvestCostAgriculturalInputSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      farmFieldId: formatSelectValueToFields(
        harvestCostAgriculturalInputSelected.farmField,
        ['id', 'name'],
      ),
      farmId: formatSelectValueToFields(
        harvestCostAgriculturalInputSelected.farm,
        ['id', 'name'],
      ),
      harvestId: formatSelectValueToFields(
        harvestCostAgriculturalInputSelected.harvest,
        ['id', 'name'],
      ),
      name: harvestCostAgriculturalInputSelected.name,
      description: harvestCostAgriculturalInputSelected.description,
      expectedNumberService: `${harvestCostAgriculturalInputSelected.expectedNumberService}`,
      numberService: `${harvestCostAgriculturalInputSelected.numberService}`,
      serviceValueApportionment: `${harvestCostAgriculturalInputSelected.serviceValueApportionment}`,
      valueAgriculturalInput: `${harvestCostAgriculturalInputSelected.valueAgriculturalInput}`,
    }

    reset(harvestCostAgriculturalInputSelected ? fields : cleanFields)
  }, [harvestCostAgriculturalInputSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedFarmField = +formatToSendData(data?.farmFieldId)
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedHarvest = +formatToSendData(data?.harvestId)

      const formatedData = {
        farmFieldId: selectedFarmField,
        farmId: selectedFarm,
        harvestId: selectedHarvest,
        name: data?.name,
        description: data?.description,
        expectedNumberService: +data?.expectedNumberService,
        numberService: +data?.numberService,
        serviceValueApportionment: formatCurrencyToSend(
          data?.serviceValueApportionment,
        ),
        valueAgriculturalInput: formatCurrencyToSend(
          data?.valueAgriculturalInput,
        ),
      }

      if (
        harvestCostAgriculturalInputSelected &&
        harvestCostAgriculturalInputSelected?.id
      ) {
        const { updateHarvestCostAgriculturalInputDashboard } =
          await updateHarvestCostAgriculturalInputFn({
            ...formatedData,
            harvestCostAgriculturalInputId:
              harvestCostAgriculturalInputSelected?.id,
          })

        dispatch(
          editUniqueData(updateHarvestCostAgriculturalInputDashboard?.data),
        )
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData =
        await createHarvestCostAgriculturalInputFn(formatedData)

      dispatch(
        pushData(
          responseData?.createHarvestCostAgriculturalInputDashboard?.data,
        ),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    harvestCostAgriculturalInputSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    farmFieldInfo,
    farmInfo,
    harvestInfo,
  }
}
