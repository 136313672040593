import { graphClient } from 'services/graph-client'
import {
  activeAgriculturalInputSubTypeDashboardGQL,
  deleteAgriculturalInputSubTypeDashboardGQL,
  deleteForeverAgriculturalInputSubTypeDashboardGQL,
  createAgriculturalInputSubTypeDashboardGQL,
  updateAgriculturalInputSubTypeDashboardGQL,
} from './queries'
import { AgriculturalInputSubType } from 'types/agriculturals-inputs/agriculturals-inputs-subs-types-types'

interface ResponseCreate {
  createAgriculturalInputSubTypeDashboard: {
    data: AgriculturalInputSubType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateAgriculturalInputSubTypeDashboard: {
    data: AgriculturalInputSubType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeAgriculturalInputSubTypeDashboard: {
    data: AgriculturalInputSubType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteAgriculturalInputSubTypeDashboard: {
    data: AgriculturalInputSubType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverAgriculturalInputSubTypeDashboard: string
}

interface DataActive {
  agriculturalInputSubTypeId: number
  active: boolean
}

interface DataDeleted {
  agriculturalInputSubTypeId: number
  deleted: boolean
}

interface DataDeleteForever {
  agriculturalInputSubTypeId: number
}

interface DataCreate {
  agriculturalInputTypeId: number
  name: string
  description: string
}

interface DataUpdate {
  agriculturalInputSubTypeId: number
  agriculturalInputTypeId: number
  name: string
  description: string
}

export const activeAgriculturalInputSubType = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeAgriculturalInputSubTypeDashboardGQL,
    { ...data },
  )
}

export const deleteAgriculturalInputSubType = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteAgriculturalInputSubTypeDashboardGQL,
    { ...data },
  )
}

export const deleteForeverAgriculturalInputSubType = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverAgriculturalInputSubTypeDashboardGQL,
    { ...data },
  )
}

export const createAgriculturalInputSubType = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createAgriculturalInputSubTypeDashboardGQL,
    { ...data },
  )
}

export const updateAgriculturalInputSubType = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateAgriculturalInputSubTypeDashboardGQL,
    { ...data },
  )
}
