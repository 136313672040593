import { graphClient } from 'services/graph-client'
import { Company, User, Role, UserRole } from 'types/users/users-roles-types'

import {
  getCompaniesDashboardGQL,
  getUsersDashboardGQL,
  getRolesDashboardGQL,
  getUsersRolesDashboardGQL,
  getUsersRolesContainsDashboardGQL,
} from './queries'

interface Response {
  getUsersRolesDashboard: {
    data: UserRole[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getUsersRolesContainsDashboard: {
    data: UserRole[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  nameIndex?: string
}

export interface GetParams {
  page?: number
  userId?: number
  roleId?: number
  name?: string
  nameIndex?: string
  canMenu?: boolean
  canAdd?: boolean
  canDuplicate?: boolean
  canEdit?: boolean
  canDeleteForever?: boolean
  canExport?: boolean
  canImport?: boolean
  canActive?: boolean
  canDeleted?: boolean
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getUsersRolesDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getUsersRolesDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getUsersRolesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getUsersRolesDashboardGQL, {
    take,
    skip,
  })
}

export const getUsersRolesContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getUsersRolesContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseUser {
  getUsersDashboard: {
    data: User[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getUsersDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseUser>(getUsersDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseRole {
  getRolesDashboard: {
    data: Role[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getRolesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseRole>(getRolesDashboardGQL, {
    take,
    skip,
  })
}
