import { graphClient } from 'services/graph-client'
import {
  activeEmployeeDayWorkedDashboardGQL,
  deleteEmployeeDayWorkedDashboardGQL,
  deleteForeverEmployeeDayWorkedDashboardGQL,
  createEmployeeDayWorkedDashboardGQL,
  updateEmployeeDayWorkedDashboardGQL,
} from './queries'
import { EmployeeDayWorked } from 'types/employees/employees-days-workeds-types'

interface ResponseCreate {
  createEmployeeDayWorkedDashboard: {
    data: EmployeeDayWorked
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateEmployeeDayWorkedDashboard: {
    data: EmployeeDayWorked
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeEmployeeDayWorkedDashboard: {
    data: EmployeeDayWorked
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteEmployeeDayWorkedDashboard: {
    data: EmployeeDayWorked
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverEmployeeDayWorkedDashboard: string
}

interface DataActive {
  employeeDayWorkedId: number
  active: boolean
}

interface DataDeleted {
  employeeDayWorkedId: number
  deleted: boolean
}

interface DataDeleteForever {
  employeeDayWorkedId: number
}

interface DataCreate {
  farmId?: number
  farmFieldId?: number
  employeeId: number
  name: string
  dateWorked: string
  holiday?: boolean
  worked?: boolean
  workedSociety?: boolean
  workedOtherSociety?: boolean
  workedOtherSocietyEmployeeId?: number
  absence?: boolean
  reasonAbsence?: string
}

interface DataUpdate {
  employeeDayWorkedId: number
  farmId?: number
  farmFieldId?: number
  employeeId: number
  name: string
  dateWorked: string
  holiday?: boolean
  worked?: boolean
  workedSociety?: boolean
  workedOtherSociety?: boolean
  workedOtherSocietyEmployeeId?: number
  absence?: boolean
  reasonAbsence?: string
}

export const activeEmployeeDayWorked = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeEmployeeDayWorkedDashboardGQL,
    { ...data },
  )
}

export const deleteEmployeeDayWorked = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteEmployeeDayWorkedDashboardGQL,
    { ...data },
  )
}

export const deleteForeverEmployeeDayWorked = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverEmployeeDayWorkedDashboardGQL,
    { ...data },
  )
}

export const createEmployeeDayWorked = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createEmployeeDayWorkedDashboardGQL,
    { ...data },
  )
}

export const updateEmployeeDayWorked = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateEmployeeDayWorkedDashboardGQL,
    { ...data },
  )
}
