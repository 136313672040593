import { graphClient } from 'services/graph-client'
import {
  Company,
  ExampleOtherOne,
  User,
  ExampleOtherTwo,
  ExampleTable,
  TypeEnum,
} from 'types/examples-tables/examples-tables-types'

import {
  getCompaniesDashboardGQL,
  getExamplesOthersOnesDashboardGQL,
  getUsersDashboardGQL,
  getExamplesOthersTwosDashboardGQL,
  getExamplesTablesDashboardGQL,
  getExamplesTablesContainsDashboardGQL,
  exportExcelExamplesTablesGQL,
} from './queries'

interface Response {
  getExamplesTablesDashboard: {
    data: ExampleTable[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getExamplesTablesContainsDashboard: {
    data: ExampleTable[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  typeText?: string
  email?: string
  phone?: string
}

export interface GetParams {
  page?: number
  userId?: number
  name?: string
  typeText?: string
  typeBoolean?: boolean
  typeInt?: number
  typeDatetime?: string
  typeEnum?: TypeEnum
  exampleOtherOneId?: number
  exampleOtherTwoId?: number
  typeDecimal?: number
  typeCurrency?: number
  email?: string
  phone?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getExamplesTablesDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getExamplesTablesDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getExamplesTablesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getExamplesTablesDashboardGQL, {
    take,
    skip,
  })
}

export const getExamplesTablesContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getExamplesTablesContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseExampleOtherOne {
  getExamplesOthersOnesDashboard: {
    data: ExampleOtherOne[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getExamplesOthersOnesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseExampleOtherOne>(
    getExamplesOthersOnesDashboardGQL,
    { take, skip },
  )
}

interface ResponseUser {
  getUsersDashboard: {
    data: User[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getUsersDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseUser>(getUsersDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseExampleOtherTwo {
  getExamplesOthersTwosDashboard: {
    data: ExampleOtherTwo[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getExamplesOthersTwosDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseExampleOtherTwo>(
    getExamplesOthersTwosDashboardGQL,
    { take, skip },
  )
}

interface ExportParams {
  name?: string
  typeText?: string
  typeBoolean?: boolean
  typeInt?: number
  typeDatetimeInitial?: string
  typeDatetimeFinal?: string
  typeEnum?: TypeEnum
  exampleOtherOneId?: number
  exampleOtherTwoId?: number
  typeDecimalInitial?: number
  typeDecimalFinal?: number
  typeCurrencyInitial?: number
  typeCurrencyFinal?: number
  email?: string
  phone?: string
  active?: boolean
  deleted?: boolean
  userId?: number
  createdAtInitial?: string
  createdAtFinal?: string
}

interface ResponseExport {
  exportExcelExamplesTablesDashboard: string
}

export const exportExcelExamplesTables = async (body: ExportParams) => {
  return await graphClient.request<ResponseExport>(
    exportExcelExamplesTablesGQL,
    { ...body },
  )
}
