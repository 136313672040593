import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatCurrencyToSend } from 'helpers/format/format-currency'
import {
  createEmployeeFunction,
  updateEmployeeFunction,
} from 'services/employees/employees-functions/employees-functions-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectEmployeeFunction,
} from 'slices/employees/employees-functions-reducer'
import { z } from 'zod'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('employees-functions-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('employees-functions-name')),

  description: z.string(),
  baseSalaryCurrency: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('employees-functions-base-salary-currency'),
    ),
})

export type FormData = z.infer<typeof schema>

const employeeFunctionModalFormSelector = (state: StateRoot) => {
  return state.employeesFunctionsReducer
}

export const selectorResult = createSelector(
  employeeFunctionModalFormSelector,
  (employeeFunctionReducer) => {
    return employeeFunctionReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, employeeFunctionSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('employees_functions')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const farmInfo = useGetFarmsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectEmployeeFunction(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createEmployeeFunctionFn, isPending } = useMutation({
    mutationFn: createEmployeeFunction,
  })

  const { mutateAsync: updateEmployeeFunctionFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateEmployeeFunction,
    })

  const cleanFields: FormData = {
    farmId: '',
    name: '',
    description: '',
    baseSalaryCurrency: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!employeeFunctionSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      farmId: formatSelectValueToFields(employeeFunctionSelected.farm, [
        'id',
        'name',
      ]),
      name: employeeFunctionSelected.name,
      description: employeeFunctionSelected.description,
      baseSalaryCurrency: `${employeeFunctionSelected.baseSalaryCurrency}`,
    }

    reset(employeeFunctionSelected ? fields : cleanFields)
  }, [employeeFunctionSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedFarm = +formatToSendData(data?.farmId)

      const formatedData = {
        farmId: selectedFarm,
        name: data?.name,
        description: data?.description,
        baseSalaryCurrency: formatCurrencyToSend(data?.baseSalaryCurrency),
      }

      if (employeeFunctionSelected && employeeFunctionSelected?.id) {
        const { updateEmployeeFunctionDashboard } =
          await updateEmployeeFunctionFn({
            ...formatedData,
            employeeFunctionId: employeeFunctionSelected?.id,
          })

        dispatch(editUniqueData(updateEmployeeFunctionDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createEmployeeFunctionFn(formatedData)

      dispatch(pushData(responseData?.createEmployeeFunctionDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    employeeFunctionSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    farmInfo,
  }
}
