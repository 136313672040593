import { gql } from 'graphql-request'

export const createFarmAreaDashboardGQL = gql`
  mutation CREATE_FARM_AREA_DASHBOARD(
    $name: String!
    $description: String!
    $farmId: Int!
    $areaId: Int!
  ) {
    createFarmAreaDashboard(
      name: $name
      description: $description
      farmId: $farmId
      areaId: $areaId
    ) {
      error
      status
      data {
        id
        name
        description
        farmId
        farm {
          id
          name
        }
        areaId
        area {
          id
          name
        }
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateFarmAreaDashboardGQL = gql`
  mutation UPDATE_FARM_AREA_DASHBOARD(
    $farmAreaId: Int!
    $name: String
    $description: String
    $farmId: Int
    $areaId: Int
  ) {
    updateFarmAreaDashboard(
      farmAreaId: $farmAreaId
      name: $name
      description: $description
      farmId: $farmId
      areaId: $areaId
    ) {
      error
      status
      data {
        id
        name
        description
        farmId
        farm {
          id
          name
        }
        areaId
        area {
          id
          name
        }
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteFarmAreaDashboardGQL = gql`
  mutation DELETE_FARM_AREA_DASHBOARD($farmAreaId: Int!, $deleted: Boolean!) {
    deleteFarmAreaDashboard(farmAreaId: $farmAreaId, deleted: $deleted) {
      error
      status
      data {
        id
        name
        description
        farmId
        areaId
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeFarmAreaDashboardGQL = gql`
  mutation ACTIVE_FARM_AREA_DASHBOARD($farmAreaId: Int!, $active: Boolean!) {
    activeFarmAreaDashboard(farmAreaId: $farmAreaId, active: $active) {
      error
      status
      data {
        id
        name
        description
        farmId
        areaId
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverFarmAreaDashboardGQL = gql`
  mutation DELETE_FOREVER_FARM_AREA_DASHBOARD($farmAreaId: Int!) {
    deleteForeverFarmAreaDashboard(farmAreaId: $farmAreaId)
  }
`

export const getFarmsAreasDashboardGQL = gql`
  query GET_FARMS_AREAS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $farmId: Int
    $areaId: Int
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getFarmsAreasDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      farmId: $farmId
      areaId: $areaId
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        farmId
        farm {
          id
          name
        }
        areaId
        area {
          id
          name
        }
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAreaDashboardIdGQL = gql`
  query GET_AREA_DASHBOARD_ID($areaId: Int!) {
    getAreaDashboard(areaId: $areaId) {
      error
      status
      data {
        id
        name
        description
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAreasDashboardGQL = gql`
  query GET_AREAS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getAreasDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsAreasContainsDashboardGQL = gql`
  query GET_FARMS_AREAS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getFarmsAreasContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        farmId
        farm {
          id
          name
        }
        areaId
        area {
          id
          name
        }
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
