import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { ControlledInputPhone } from 'components/controlled-components/controlled-input-phone'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  register: UseFormRegister<FormData>
  handleSelectCountry: (country: string) => void
}

export const FormControl = ({
  control,
  errors,
  farmInfo,
  register,
  handleSelectCountry,
}: Props) => {
  const permissions = useGetPermissions('farms_owners')
  const { farmOwnerSelected } = useSelector(selectorResult)
  const disabled = !!(farmOwnerSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('farms-owners-farm-id')}
            name="farmId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="priorityContact"
            label={t('farms-owners-priority-contact')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('farms-owners-name')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.document?.message}
            {...register('document')}
            label={t('farms-owners-document')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.documentTwo?.message}
            {...register('documentTwo')}
            label={t('farms-owners-document-two')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.documentThree?.message}
            {...register('documentThree')}
            label={t('farms-owners-document-three')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            type="email"
            error={errors?.email?.message}
            {...register('email')}
            label={t('farms-owners-email')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputPhone
            control={control}
            name="phone.phone"
            label={t('farms-owners-phone')}
            handleSelectCountry={handleSelectCountry}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
