import { createSlice } from '@reduxjs/toolkit'
import { TypeCoffeeRenovation } from 'types/types-coffees/types-coffees-renovations-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  typeCoffeeRenovationSelected: null | TypeCoffeeRenovation
  deleteModalOpen: boolean
  typeCoffeeRenovationIdToDelete: null | string
  data: TypeCoffeeRenovation[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  typeCoffeeRenovationSelected: null,
  typeCoffeeRenovationIdToDelete: null,
  data: [],
  total: 0,
}

const typeCoffeeRenovationSlice = createSlice({
  initialState,
  name: 'types-coffees-renovations-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectTypeCoffeeRenovation(
      state,
      { payload }: { payload: TypeCoffeeRenovation | null },
    ) {
      state.typeCoffeeRenovationSelected = payload
    },
    setData(state, { payload }: { payload: TypeCoffeeRenovation[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: TypeCoffeeRenovation }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: TypeCoffeeRenovation }) {
      state.data = state.data.map((typeCoffeeRenovation) => {
        return typeCoffeeRenovation.id === payload.id
          ? { ...typeCoffeeRenovation, ...payload }
          : typeCoffeeRenovation
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (typeCoffeeRenovation) => +typeCoffeeRenovation.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.typeCoffeeRenovationIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.typeCoffeeRenovationIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectTypeCoffeeRenovation,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = typeCoffeeRenovationSlice.actions
export default typeCoffeeRenovationSlice.reducer
