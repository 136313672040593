import { useMemo } from 'react'
import { useUserDetails } from './use-user-details'

export const useGetPermissions = (table: string) => {
  const { roles } = useUserDetails()

  const permissions = useMemo(
    () => ({
      canMenu: roles?.canMenu[table],
      canAdd: roles?.canAdd[table],
      canEdit: roles?.canEdit[table],
      canDuplicate: roles?.canDuplicate[table],
      canActive: roles?.canActive[table],
      canDeleted: roles?.canDeleted[table],
      canDeleteForever: roles?.canDeleteForever[table],
      canImport: roles?.canImport[table],
      canExport: roles?.canExport[table],
    }),
    [roles],
  )

  return permissions
}
