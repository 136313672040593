import { gql } from 'graphql-request'

export const createTypeCoffeeManagementDashboardGQL = gql`
  mutation CREATE_TYPE_COFFEE_MANAGEMENT_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createTypeCoffeeManagementDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateTypeCoffeeManagementDashboardGQL = gql`
  mutation UPDATE_TYPE_COFFEE_MANAGEMENT_DASHBOARD(
    $typeCoffeeManagementId: Int!
    $name: String
    $description: String
  ) {
    updateTypeCoffeeManagementDashboard(
      typeCoffeeManagementId: $typeCoffeeManagementId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteTypeCoffeeManagementDashboardGQL = gql`
  mutation DELETE_TYPE_COFFEE_MANAGEMENT_DASHBOARD(
    $typeCoffeeManagementId: Int!
    $deleted: Boolean!
  ) {
    deleteTypeCoffeeManagementDashboard(
      typeCoffeeManagementId: $typeCoffeeManagementId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeTypeCoffeeManagementDashboardGQL = gql`
  mutation ACTIVE_TYPE_COFFEE_MANAGEMENT_DASHBOARD(
    $typeCoffeeManagementId: Int!
    $active: Boolean!
  ) {
    activeTypeCoffeeManagementDashboard(
      typeCoffeeManagementId: $typeCoffeeManagementId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverTypeCoffeeManagementDashboardGQL = gql`
  mutation DELETE_FOREVER_TYPE_COFFEE_MANAGEMENT_DASHBOARD(
    $typeCoffeeManagementId: Int!
  ) {
    deleteForeverTypeCoffeeManagementDashboard(
      typeCoffeeManagementId: $typeCoffeeManagementId
    )
  }
`

export const getTypesCoffeesManagementsDashboardGQL = gql`
  query GET_TYPES_COFFEES_MANAGEMENTS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getTypesCoffeesManagementsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesManagementsContainsDashboardGQL = gql`
  query GET_TYPES_COFFEES_MANAGEMENTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getTypesCoffeesManagementsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
