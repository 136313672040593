import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import {
  createDefaultAgriculturalInput,
  updateDefaultAgriculturalInput,
} from 'services/defaults/defaults-agriculturals-inputs/defaults-agriculturals-inputs-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectDefaultAgriculturalInput,
} from 'slices/defaults/defaults-agriculturals-inputs-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('defaults-agriculturals-inputs-name')),

  description: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('defaults-agriculturals-inputs-description'),
    ),

  agriculturalInputSubTypeId: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('defaults-agriculturals-inputs-agricultural-input-sub-type-id'),
    ),

  agriculturalInputModeActionId: z.string(),
  tradeMark: z.string(),
  formulation: z.string(),
  activeIngredient: z.string(),
  isPesticide: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('defaults-agriculturals-inputs-is-pesticide'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typePestId: z.string(),
  toxicologicalClass: z.string(),
  environmentalClass: z.string(),
  isOrganic: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('defaults-agriculturals-inputs-is-organic'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  isFertilizer: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('defaults-agriculturals-inputs-is-fertilizer'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),
})

export type FormData = z.infer<typeof schema>

const defaultAgriculturalInputModalFormSelector = (state: StateRoot) => {
  return state.defaultsAgriculturalsInputsReducer
}

export const selectorResult = createSelector(
  defaultAgriculturalInputModalFormSelector,
  (defaultAgriculturalInputReducer) => {
    return defaultAgriculturalInputReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, defaultAgriculturalInputSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('defaults_agriculturals_inputs')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectDefaultAgriculturalInput(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createDefaultAgriculturalInputFn, isPending } =
    useMutation({
      mutationFn: createDefaultAgriculturalInput,
    })

  const {
    mutateAsync: updateDefaultAgriculturalInputFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateDefaultAgriculturalInput,
  })

  const cleanFields: FormData = {
    name: '',
    description: '',
    agriculturalInputSubTypeId: '',
    agriculturalInputModeActionId: '',
    tradeMark: '',
    formulation: '',
    activeIngredient: '',
    isPesticide: '',
    typePestId: '',
    toxicologicalClass: '',
    environmentalClass: '',
    isOrganic: '',
    isFertilizer: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!defaultAgriculturalInputSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: defaultAgriculturalInputSelected.name,
      description: defaultAgriculturalInputSelected.description,
      agriculturalInputSubTypeId: `${defaultAgriculturalInputSelected.agriculturalInputSubTypeId}`,
      agriculturalInputModeActionId: `${defaultAgriculturalInputSelected.agriculturalInputModeActionId}`,
      tradeMark: defaultAgriculturalInputSelected.tradeMark,
      formulation: defaultAgriculturalInputSelected.formulation,
      activeIngredient: defaultAgriculturalInputSelected.activeIngredient,
      isPesticide: `${defaultAgriculturalInputSelected.isPesticide}`,
      typePestId: `${defaultAgriculturalInputSelected.typePestId}`,
      toxicologicalClass: defaultAgriculturalInputSelected.toxicologicalClass,
      environmentalClass: defaultAgriculturalInputSelected.environmentalClass,
      isOrganic: `${defaultAgriculturalInputSelected.isOrganic}`,
      isFertilizer: `${defaultAgriculturalInputSelected.isFertilizer}`,
    }

    reset(defaultAgriculturalInputSelected ? fields : cleanFields)
  }, [defaultAgriculturalInputSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedIsPesticide = formatToSendData(data?.isPesticide)
      const selectedIsOrganic = formatToSendData(data?.isOrganic)
      const selectedIsFertilizer = formatToSendData(data?.isFertilizer)

      const formatedData = {
        name: data?.name,
        description: data?.description,
        agriculturalInputSubTypeId: +data?.agriculturalInputSubTypeId,
        agriculturalInputModeActionId: +data?.agriculturalInputModeActionId,
        tradeMark: data?.tradeMark,
        formulation: data?.formulation,
        activeIngredient: data?.activeIngredient,
        isPesticide: formatInputBooleanValue(selectedIsPesticide),
        typePestId: +data?.typePestId,
        toxicologicalClass: data?.toxicologicalClass,
        environmentalClass: data?.environmentalClass,
        isOrganic: formatInputBooleanValue(selectedIsOrganic),
        isFertilizer: formatInputBooleanValue(selectedIsFertilizer),
      }

      if (
        defaultAgriculturalInputSelected &&
        defaultAgriculturalInputSelected?.id
      ) {
        const { updateDefaultAgriculturalInputDashboard } =
          await updateDefaultAgriculturalInputFn({
            ...formatedData,
            defaultAgriculturalInputId: defaultAgriculturalInputSelected?.id,
          })

        dispatch(editUniqueData(updateDefaultAgriculturalInputDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createDefaultAgriculturalInputFn(formatedData)

      dispatch(
        pushData(responseData?.createDefaultAgriculturalInputDashboard?.data),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    defaultAgriculturalInputSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
