import { Link } from 'react-router-dom'
import { Avatar } from '../avatar'
import { useProfileDropdown } from './use-profile-dropdown'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

const ProfileDropdown = () => {
  const { isProfileDropdownVisible, user, logout, toggleProfileDropdown } =
    useProfileDropdown()

  return (
    <>
      <Dropdown
        isOpen={isProfileDropdownVisible}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <Avatar />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {user?.firstName}!</h6>
          <DropdownItem className="p-0">
            <Link to="/profile" className="dropdown-item">
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Profile</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="/apps-chat" className="dropdown-item">
              <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">Messages</span>
            </Link>
          </DropdownItem>

          <DropdownItem className="p-0">
            <Link to="/pages-faqs" className="dropdown-item">
              <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">Help</span>
            </Link>
          </DropdownItem>
          <div className="dropdown-divider"></div>

          <DropdownItem className="p-0">
            <Link to="/pages-profile-settings" className="dropdown-item">
              <span className="badge bg-success-subtle text-success mt-1 float-end">
                New
              </span>
              <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">Settings</span>
            </Link>
          </DropdownItem>

          <DropdownItem className="p-0" onClick={logout}>
            <div style={{ padding: '5.6px 19.2px' }}>
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle" data-key="t-logout">
                Logout
              </span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default ProfileDropdown
