import { graphClient } from 'services/graph-client'
import {
  activeUserTypeDashboardGQL,
  deleteUserTypeDashboardGQL,
  deleteForeverUserTypeDashboardGQL,
  createUserTypeDashboardGQL,
  updateUserTypeDashboardGQL,
} from './queries'
import { UserType } from 'types/users/users-types-types'

interface ResponseCreate {
  createUserTypeDashboard: {
    data: UserType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateUserTypeDashboard: {
    data: UserType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeUserTypeDashboard: {
    data: UserType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteUserTypeDashboard: {
    data: UserType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverUserTypeDashboard: string
}

interface DataActive {
  userTypeId: number
  active: boolean
}

interface DataDeleted {
  userTypeId: number
  deleted: boolean
}

interface DataDeleteForever {
  userTypeId: number
}

interface DataCreate {
  name: string
  description: string
}

interface DataUpdate {
  userTypeId: number
  name: string
  description: string
}

export const activeUserType = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeUserTypeDashboardGQL, {
    ...data,
  })
}

export const deleteUserType = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteUserTypeDashboardGQL, {
    ...data,
  })
}

export const deleteForeverUserType = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverUserTypeDashboardGQL,
    { ...data },
  )
}

export const createUserType = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(createUserTypeDashboardGQL, {
    ...data,
  })
}

export const updateUserType = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateUserTypeDashboardGQL, {
    ...data,
  })
}
