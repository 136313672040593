import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeCoffeePruningDashboardGQL,
  deleteDefaultTypeCoffeePruningDashboardGQL,
  deleteForeverDefaultTypeCoffeePruningDashboardGQL,
  createDefaultTypeCoffeePruningDashboardGQL,
  updateDefaultTypeCoffeePruningDashboardGQL,
} from './queries'
import { DefaultTypeCoffeePruning } from 'types/defaults/defaults-types-coffees-prunings-types'

interface ResponseCreate {
  createDefaultTypeCoffeePruningDashboard: {
    data: DefaultTypeCoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeCoffeePruningDashboard: {
    data: DefaultTypeCoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeCoffeePruningDashboard: {
    data: DefaultTypeCoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeCoffeePruningDashboard: {
    data: DefaultTypeCoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeCoffeePruningDashboard: string
}

interface DataActive {
  defaultTypeCoffeePruningId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeCoffeePruningId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeCoffeePruningId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  defaultTypeCoffeePruningId: number
  name: string
  description?: string
}

export const activeDefaultTypeCoffeePruning = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeCoffeePruning = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeCoffeePruning = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeCoffeePruning = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeCoffeePruning = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeCoffeePruningDashboardGQL,
    { ...data },
  )
}
