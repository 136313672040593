import { useInfiniteQuery } from '@tanstack/react-query'
import { getTypesCoffeesPruningsDashboardHook } from 'services/_hooks/types-coffees-prunings-gets'

export const useGetTypesCoffeesPruningsDashboard = (take = 4) => {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['get', 'typesCoffeesPruningsDashboard', 'list', 'total'],
      initialPageParam: 0,
      queryFn: ({ pageParam }) => {
        return getTypesCoffeesPruningsDashboardHook(pageParam, take)
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const total =
          lastPage?.getTypesCoffeesPruningsDashboard?.totalPages ?? 4
        const isLastPage = allPages?.length >= total

        if (isLastPage) {
          return null
        }

        return lastPageParam + 1
      },
    })

  const typesCoffeesPruningsDashboard = data?.pages?.flatMap((page) => {
    return page?.getTypesCoffeesPruningsDashboard?.data
  })

  return {
    typesCoffeesPruningsDashboard: typesCoffeesPruningsDashboard ?? [],
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  }
}
