import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetAgriculturalsInputsTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-types-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  agriculturalInputTypeInfo: ReturnType<
    typeof useGetAgriculturalsInputsTypesDashboard
  >
  register: UseFormRegister<FormData>
}

export const FormControl = ({
  control,
  errors,
  agriculturalInputTypeInfo,
  register,
}: Props) => {
  const permissions = useGetPermissions('agriculturals_inputs_subs_types')
  const { agriculturalInputSubTypeSelected } = useSelector(selectorResult)
  const disabled = !!(agriculturalInputSubTypeSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={agriculturalInputTypeInfo?.agriculturalsInputsTypesDashboard}
            info={agriculturalInputTypeInfo}
            label={t(
              'agriculturals-inputs-subs-types-agricultural-input-type-id',
            )}
            name="agriculturalInputTypeId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('agriculturals-inputs-subs-types-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('agriculturals-inputs-subs-types-description')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
