import { User } from 'types/auth/login-types'

import { graphClient } from 'services/graph-client'
import { loginPhoneGQL } from './queries'

interface Body {
  phone: string
  password: string
  country: string
}

interface Response {
  loginPhone: {
    data: User
    error: string | null
    success: string | null
  }
}

export const login = async ({ phone, password, country }: Body) => {
  return await graphClient.request<Response>(loginPhoneGQL, {
    phone,
    password,
    country,
  })
}
