import { graphClient } from 'services/graph-client'
import {
  activeTypeExpenseDashboardGQL,
  deleteTypeExpenseDashboardGQL,
  deleteForeverTypeExpenseDashboardGQL,
  createTypeExpenseDashboardGQL,
  updateTypeExpenseDashboardGQL,
} from './queries'
import { TypeExpense } from 'types/types-coffees/types-expenses-types'

interface ResponseCreate {
  createTypeExpenseDashboard: {
    data: TypeExpense
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeExpenseDashboard: {
    data: TypeExpense
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeExpenseDashboard: {
    data: TypeExpense
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeExpenseDashboard: {
    data: TypeExpense
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeExpenseDashboard: string
}

interface DataActive {
  typeExpenseId: number
  active: boolean
}

interface DataDeleted {
  typeExpenseId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeExpenseId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  typeExpenseId: number
  name: string
  description?: string
}

export const activeTypeExpense = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeTypeExpenseDashboardGQL,
    { ...data },
  )
}

export const deleteTypeExpense = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteTypeExpenseDashboardGQL,
    { ...data },
  )
}

export const deleteForeverTypeExpense = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeExpenseDashboardGQL,
    { ...data },
  )
}

export const createTypeExpense = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createTypeExpenseDashboardGQL,
    { ...data },
  )
}

export const updateTypeExpense = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateTypeExpenseDashboardGQL,
    { ...data },
  )
}
