import { Status } from './list-columns'
import { t } from 'i18next'

export const columns = [
  {
    Header: t('id'),
    accessor: 'id',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span className="fw-medium link-primary">{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-agriculturals-inputs-company-id'),
    accessor: 'company.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-agriculturals-inputs-farm-field-id'),
    accessor: 'farmField.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-agriculturals-inputs-farm-id'),
    accessor: 'farm.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-agriculturals-inputs-order-service-id'),
    accessor: 'orderService.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-agriculturals-inputs-agricultural-input-id'),
    accessor: 'agriculturalInput.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-agriculturals-inputs-name'),
    accessor: 'name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-agriculturals-inputs-value-agricultural-input'),
    accessor: 'valueAgriculturalInput',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-agriculturals-inputs-quantity-per-plant'),
    accessor: 'quantityPerPlant',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-agriculturals-inputs-expected-total-quantity'),
    accessor: 'expectedTotalQuantity',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('orders-services-agriculturals-inputs-real-total-quantity'),
    accessor: 'realTotalQuantity',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('active'),
    accessor: 'actions',
    filterable: false,
    Cell: (props: any, cellProps: any) => {
      return <Status {...cellProps} {...props} showActive={true} />
    },
  },
]
