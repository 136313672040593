import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'
import { useGetExamplesOthersOnesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-examples-others-ones-dashboard'
import { useGetExamplesOthersTwosDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-examples-others-twos-dashboard'
import { formatDateDB } from 'helpers/format/format-date'

const schemaFilter = z.object({
  name: z.string().min(1, t('inform-field') + ' ' + t('examples-tables-name')),

  typeText: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-tables-type-text')),

  typeBoolean: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('examples-tables-type-boolean')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeInt: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-tables-type-int')),

  typeDatetime: z.union([z.date(), z.string().min(1)]),

  typeEnum: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('examples-tables-type-enum')),
    z.object({ label: z.string(), value: z.string() }),
  ]),

  exampleOtherOneId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('examples-tables-example-other-one-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  exampleOtherTwoId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('examples-tables-example-other-two-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  email: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-tables-email')),

  phone: z
    .object({
      phone: z.string(),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('examples-tables-phone'),
      path: ['phone'],
    }),
  active: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('examples-tables-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('examples-tables-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    name: '',
    typeText: '',
    typeBoolean: '',
    typeInt: '',
    typeDatetime: '',
    typeEnum: '',
    exampleOtherOneId: '',
    exampleOtherTwoId: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = form

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  const exampleOtherOneInfo = useGetExamplesOthersOnesDashboard()
  const exampleOtherTwoInfo = useGetExamplesOthersTwosDashboard()

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  const handleFormValues = (formValues: FormDataFilter) => {
    const values: Record<string, any> = {}

    if (typeof formValues.exampleOtherOneId != 'undefined') {
      if (typeof formValues.exampleOtherOneId === 'object') {
        values['exampleOtherOneId'] = formValues.exampleOtherOneId.value
      }
    }

    if (typeof formValues.exampleOtherTwoId != 'undefined') {
      if (typeof formValues.exampleOtherTwoId === 'object') {
        values['exampleOtherTwoId'] = formValues.exampleOtherTwoId.value
      }
    }

    if (typeof formValues.name != 'undefined' && formValues.name != '') {
      values['name'] = formValues.name
    }

    if (typeof formValues.email != 'undefined' && formValues.email != '') {
      values['email'] = formValues.email
    }

    if (
      typeof formValues.typeText != 'undefined' &&
      formValues.typeText != ''
    ) {
      values['typeText'] = formValues.typeText
    }

    if (
      typeof formValues.phone != 'undefined' &&
      formValues.phone.phone != ''
    ) {
      values['phone'] = formValues.phone['phone']
    }

    if (typeof formValues.typeInt != 'undefined' && formValues.typeInt != '') {
      values['typeInt'] = +formValues.typeInt
    }

    if (typeof formValues.typeBoolean != 'undefined') {
      if (typeof formValues.typeBoolean === 'object') {
        if (formValues.typeBoolean.value === 'true') {
          values['typeBoolean'] = true
        }
        if (formValues.typeBoolean.value === 'false') {
          values['typeBoolean'] = false
        }
      }
    }

    if (typeof formValues.active != 'undefined') {
      if (typeof formValues.active === 'object') {
        if (formValues.active.value === 'true') {
          values['active'] = true
        }
        if (formValues.active.value === 'false') {
          values['active'] = false
        }
      }
    }

    if (typeof formValues.deleted != 'undefined') {
      if (typeof formValues.deleted === 'object') {
        if (formValues.deleted.value === 'true') {
          values['deleted'] = true
        }
        if (formValues.deleted.value === 'false') {
          values['deleted'] = false
        }
      }
    }

    if (typeof formValues.typeEnum != 'undefined') {
      if (typeof formValues.typeEnum === 'object') {
        values['typeEnum'] = formValues.typeEnum.value
      }
    }

    if (
      typeof formValues.typeDatetime != 'undefined' &&
      formValues.typeDatetime != ''
    ) {
      values['typeDatetime'] = formatDateDB(formValues.typeDatetime)
    }

    if (
      typeof formValues.createdAtInitial != 'undefined' &&
      formValues.createdAtInitial != '' &&
      typeof formValues.createdAtFinal != 'undefined' &&
      formValues.createdAtFinal != ''
    ) {
      values['createdAtInitial'] = formatDateDB(formValues.createdAtInitial)
      values['createdAtFinal'] = formatDateDB(formValues.createdAtFinal)
    }

    return values
  }

  return {
    errors,
    control,
    form,
    exampleOtherOneInfo,
    exampleOtherTwoInfo,
    handleFormValues,
    register,
    handleSelectCountry,
    resetFields,
    getValues,
  }
}
