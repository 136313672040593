import { CSSProperties } from 'react'

export const wrapperStyle: CSSProperties = {
  padding: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  height: '100vh',
  position: 'relative',
}

export const mapStyle: CSSProperties = {
  position: 'absolute',
  height: 'calc(100% - 70px)',
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
}
