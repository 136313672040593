import { createSelector } from 'reselect'
import { Company } from 'types/settings/companies-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverCompany } from 'services/settings/companies/companies-cruds'
import {
  getCompaniesDashboard,
  GetParams,
  getCompaniesContainsDashboard,
} from 'services/settings/companies/companies-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/settings/companies-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const companyListSelector = (state: StateRoot) => state.companiesReducer

const selectorResult = createSelector(
  companyListSelector,
  (companyListReducer) => {
    return companyListReducer
  },
)

export const useCompanyList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('companies')

  const {
    data: dataCompany,
    deleteModalOpen,
    companyIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'company', 'list', { params }],
    queryFn: () => getCompaniesDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverCompany,
  })

  const handleStoreData = useCallback(
    (companies: Company[], totalPages: number | null) => {
      if (!companies) return

      dispatch(setData(companies))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverCompany = useCallback(async () => {
    if (!companyIdToDelete) return

    const response = await mutateAsync({
      companyId: +companyIdToDelete,
    })

    if (response?.deleteForeverCompanyDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: companyIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [companyIdToDelete])

  useEffect(() => {
    const companiesResult = data?.getCompaniesDashboard

    if (companiesResult) {
      handleStoreData(companiesResult?.data, companiesResult?.totalPages)
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getCompaniesContainsDashboard(value)
      const companiesResult = response?.getCompaniesContainsDashboard

      handleStoreData(companiesResult?.data, companiesResult?.totalPages)
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    companyIdToDelete,
    company: data?.getCompaniesDashboard?.data,
    isLoading,
    isFetching,
    dataCompany,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverCompany,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
