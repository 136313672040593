import { graphClient } from 'services/graph-client'
import {
  activeDefaultAgriculturalInputSubTypeDashboardGQL,
  deleteDefaultAgriculturalInputSubTypeDashboardGQL,
  deleteForeverDefaultAgriculturalInputSubTypeDashboardGQL,
  createDefaultAgriculturalInputSubTypeDashboardGQL,
  updateDefaultAgriculturalInputSubTypeDashboardGQL,
} from './queries'
import { DefaultAgriculturalInputSubType } from 'types/defaults/defaults-agriculturals-inputs-subs-types-types'

interface ResponseCreate {
  createDefaultAgriculturalInputSubTypeDashboard: {
    data: DefaultAgriculturalInputSubType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultAgriculturalInputSubTypeDashboard: {
    data: DefaultAgriculturalInputSubType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultAgriculturalInputSubTypeDashboard: {
    data: DefaultAgriculturalInputSubType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultAgriculturalInputSubTypeDashboard: {
    data: DefaultAgriculturalInputSubType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultAgriculturalInputSubTypeDashboard: string
}

interface DataActive {
  defaultAgriculturalInputSubTypeId: number
  active: boolean
}

interface DataDeleted {
  defaultAgriculturalInputSubTypeId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultAgriculturalInputSubTypeId: number
}

interface DataCreate {
  agriculturalInputTypeId: number
  name: string
  description: string
}

interface DataUpdate {
  defaultAgriculturalInputSubTypeId: number
  agriculturalInputTypeId: number
  name: string
  description: string
}

export const activeDefaultAgriculturalInputSubType = async (
  data: DataActive,
) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultAgriculturalInputSubTypeDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultAgriculturalInputSubType = async (
  data: DataDeleted,
) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultAgriculturalInputSubTypeDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultAgriculturalInputSubType = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultAgriculturalInputSubTypeDashboardGQL,
    { ...data },
  )
}

export const createDefaultAgriculturalInputSubType = async (
  data: DataCreate,
) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultAgriculturalInputSubTypeDashboardGQL,
    { ...data },
  )
}

export const updateDefaultAgriculturalInputSubType = async (
  data: DataUpdate,
) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultAgriculturalInputSubTypeDashboardGQL,
    { ...data },
  )
}
