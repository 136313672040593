import { graphClient } from 'services/graph-client'
import {
  activeTypeCoffeeHarvestDashboardGQL,
  deleteTypeCoffeeHarvestDashboardGQL,
  deleteForeverTypeCoffeeHarvestDashboardGQL,
  createTypeCoffeeHarvestDashboardGQL,
  updateTypeCoffeeHarvestDashboardGQL,
} from './queries'
import { TypeCoffeeHarvest } from 'types/types-coffees/types-coffees-harvests-types'

interface ResponseCreate {
  createTypeCoffeeHarvestDashboard: {
    data: TypeCoffeeHarvest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeCoffeeHarvestDashboard: {
    data: TypeCoffeeHarvest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeCoffeeHarvestDashboard: {
    data: TypeCoffeeHarvest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeCoffeeHarvestDashboard: {
    data: TypeCoffeeHarvest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeCoffeeHarvestDashboard: string
}

interface DataActive {
  typeCoffeeHarvestId: number
  active: boolean
}

interface DataDeleted {
  typeCoffeeHarvestId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeCoffeeHarvestId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  typeCoffeeHarvestId: number
  name: string
  description?: string
}

export const activeTypeCoffeeHarvest = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeTypeCoffeeHarvestDashboardGQL,
    { ...data },
  )
}

export const deleteTypeCoffeeHarvest = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteTypeCoffeeHarvestDashboardGQL,
    { ...data },
  )
}

export const deleteForeverTypeCoffeeHarvest = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeCoffeeHarvestDashboardGQL,
    { ...data },
  )
}

export const createTypeCoffeeHarvest = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createTypeCoffeeHarvestDashboardGQL,
    { ...data },
  )
}

export const updateTypeCoffeeHarvest = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateTypeCoffeeHarvestDashboardGQL,
    { ...data },
  )
}
