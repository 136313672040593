import { t } from 'i18next'
import { useUserDetails } from 'lib/hooks/use-user-details'
import React, { useState } from 'react'

const Navdata = () => {
  const [currentPage, setCurrentPage] = useState({
    isDashboard: false,
    isMaps: false,
    //GENERATE
    isExampleTableMainMenu: false,
    isEmployeeMainMenu: false,
    isUserMainMenu: false,
    isSettingMainMenu: false,
    isAgriculturalInputMainMenu: false,
    isFarmMainMenu: false,
    isDefaultMainMenu: false,
    isHarvestMainMenu: false,
    isOrderMainMenu: false,
    isTypeCoffeeMainMenu: false,
    //END-GENERATE
  })

  const [isDashboard, setIsDashboard] = useState<boolean>(false)

  //GENERATE
  const [isExampleTableMainMenu, setIsExampleTableMainMenu] =
    useState<boolean>(false)
  const [isEmployeeMainMenu, setIsEmployeeMainMenu] = useState<boolean>(false)
  const [isUserMainMenu, setIsUserMainMenu] = useState<boolean>(false)
  const [isSettingMainMenu, setIsSettingMainMenu] = useState<boolean>(false)
  const [isAgriculturalInputMainMenu, setIsAgriculturalInputMainMenu] =
    useState<boolean>(false)
  const [isFarmMainMenu, setIsFarmMainMenu] = useState<boolean>(false)
  const [isDefaultMainMenu, setIsDefaultMainMenu] = useState<boolean>(false)
  const [isHarvestMainMenu, setIsHarvestMainMenu] = useState<boolean>(false)
  const [isOrderMainMenu, setIsOrderMainMenu] = useState<boolean>(false)
  const [isTypeCoffeeMainMenu, setIsTypeCoffeeMainMenu] =
    useState<boolean>(false)
  //END-GENERATE

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute('sub-items')) {
      const ul: any = document.getElementById('two-column-menu')
      const iconItems: any = ul.querySelectorAll('.nav-icon.active')
      const activeIconItems = [...iconItems]
      activeIconItems.forEach((item) => {
        item.classList.remove('active')
        const id = item.getAttribute('sub-items')
        const getID = document.getElementById(id) as HTMLElement
        if (getID) getID.classList.remove('show')
      })
    }
  }

  const cleanFields = () => {
    document.body.classList.remove('twocolumn-panel')

    for (const k in currentPage) {
      setCurrentPage((prevState) => {
        return { ...prevState, [k]: false }
      })
    }
  }

  const onClickItem = ({
    e,
    item,
  }: {
    e: Event
    item: keyof typeof currentPage
  }) => {
    e.preventDefault()
    cleanFields()

    setCurrentPage((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }))

    updateIconSidebar(e)
  }

  const {
    roles: { canMenu },
  } = useUserDetails()

  const menuItems: any = [
    {
      label: 'Menu',
      isHeader: true,
    },
    {
      id: 'dashboard',
      label: t('dashboard-menu'),
      icon: 'ri-dashboard-2-line',
      link: '/#',
      stateVariables: isDashboard,
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(!isDashboard)
        setIsExampleTableMainMenu(false)
        setIsEmployeeMainMenu(false)
        setIsUserMainMenu(false)
        setIsSettingMainMenu(false)
        setIsAgriculturalInputMainMenu(false)
        setIsFarmMainMenu(false)
        setIsDefaultMainMenu(false)
        setIsHarvestMainMenu(false)
        setIsOrderMainMenu(false)
        setIsTypeCoffeeMainMenu(false)
      },
      subItems: [
        {
          id: 'analytics',
          label: 'Analytics',
          link: '/dashboard-analytics',
          parentId: 'dashboard',
        },
      ],
    },

    //GENERATE
    {
      id: 'examples-tables-main-menu',
      label: t('examples-tables-main-menu'),
      icon: 'ri-book-line',
      link: '/#',
      stateVariables: isExampleTableMainMenu,
      visible:
        canMenu['examples_tables'] ||
        canMenu['examples_others_ones'] ||
        canMenu['examples_others_twos'],
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(false)
        setIsExampleTableMainMenu(!isExampleTableMainMenu)
        setIsEmployeeMainMenu(false)
        setIsUserMainMenu(false)
        setIsSettingMainMenu(false)
        setIsAgriculturalInputMainMenu(false)
        setIsFarmMainMenu(false)
        setIsDefaultMainMenu(false)
        setIsHarvestMainMenu(false)
        setIsOrderMainMenu(false)
        setIsTypeCoffeeMainMenu(false)
      },
      subItems: [
        {
          id: 'examples-tables-title',
          label: t('examples-tables-title'),
          link: '/examples-tables',
          parentId: 'examples-tables-main-menu',
          visible: canMenu['examples_tables'],
        },
        {
          id: 'examples-others-ones-title',
          label: t('examples-others-ones-title'),
          link: '/examples-others-ones',
          parentId: 'examples-tables-main-menu',
          visible: canMenu['examples_others_ones'],
        },
        {
          id: 'examples-others-twos-title',
          label: t('examples-others-twos-title'),
          link: '/examples-others-twos',
          parentId: 'examples-tables-main-menu',
          visible: canMenu['examples_others_twos'],
        },
      ],
    },
    {
      id: 'employees-main-menu',
      label: t('employees-main-menu'),
      icon: 'ri-book-line',
      link: '/#',
      stateVariables: isEmployeeMainMenu,
      visible:
        canMenu['employees'] ||
        canMenu['employees_functions'] ||
        canMenu['employees_days_workeds'],
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(false)
        setIsExampleTableMainMenu(false)
        setIsEmployeeMainMenu(!isEmployeeMainMenu)
        setIsUserMainMenu(false)
        setIsSettingMainMenu(false)
        setIsAgriculturalInputMainMenu(false)
        setIsFarmMainMenu(false)
        setIsDefaultMainMenu(false)
        setIsHarvestMainMenu(false)
        setIsOrderMainMenu(false)
        setIsTypeCoffeeMainMenu(false)
      },
      subItems: [
        {
          id: 'employees-title',
          label: t('employees-title'),
          link: '/employees',
          parentId: 'employees-main-menu',
          visible: canMenu['employees'],
        },
        {
          id: 'employees-functions-title',
          label: t('employees-functions-title'),
          link: '/employees-functions',
          parentId: 'employees-main-menu',
          visible: canMenu['employees_functions'],
        },
        {
          id: 'employees-days-workeds-title',
          label: t('employees-days-workeds-title'),
          link: '/employees-days-workeds',
          parentId: 'employees-main-menu',
          visible: canMenu['employees_days_workeds'],
        },
      ],
    },
    {
      id: 'users-main-menu',
      label: t('users-main-menu'),
      icon: 'ri-book-line',
      link: '/#',
      stateVariables: isUserMainMenu,
      visible:
        canMenu['users_devices_informations'] ||
        canMenu['users'] ||
        canMenu['roles'] ||
        canMenu['users_roles'] ||
        canMenu['users_types'],
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(false)
        setIsExampleTableMainMenu(false)
        setIsEmployeeMainMenu(false)
        setIsUserMainMenu(!isUserMainMenu)
        setIsSettingMainMenu(false)
        setIsAgriculturalInputMainMenu(false)
        setIsFarmMainMenu(false)
        setIsDefaultMainMenu(false)
        setIsHarvestMainMenu(false)
        setIsOrderMainMenu(false)
        setIsTypeCoffeeMainMenu(false)
      },
      subItems: [
        {
          id: 'users-devices-informations-title',
          label: t('users-devices-informations-title'),
          link: '/users-devices-informations',
          parentId: 'users-main-menu',
          visible: canMenu['users_devices_informations'],
        },
        {
          id: 'users-title',
          label: t('users-title'),
          link: '/users',
          parentId: 'users-main-menu',
          visible: canMenu['users'],
        },
        {
          id: 'roles-title',
          label: t('roles-title'),
          link: '/roles',
          parentId: 'users-main-menu',
          visible: canMenu['roles'],
        },
        {
          id: 'users-roles-title',
          label: t('users-roles-title'),
          link: '/users-roles',
          parentId: 'users-main-menu',
          visible: canMenu['users_roles'],
        },
        {
          id: 'users-types-title',
          label: t('users-types-title'),
          link: '/users-types',
          parentId: 'users-main-menu',
          visible: canMenu['users_types'],
        },
      ],
    },
    {
      id: 'settings-main-menu',
      label: t('settings-main-menu'),
      icon: 'ri-book-line',
      link: '/#',
      stateVariables: isSettingMainMenu,
      visible:
        canMenu['apps_logs'] ||
        canMenu['companies'] ||
        canMenu['companies_owners'],
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(false)
        setIsExampleTableMainMenu(false)
        setIsEmployeeMainMenu(false)
        setIsUserMainMenu(false)
        setIsSettingMainMenu(!isSettingMainMenu)
        setIsAgriculturalInputMainMenu(false)
        setIsFarmMainMenu(false)
        setIsDefaultMainMenu(false)
        setIsHarvestMainMenu(false)
        setIsOrderMainMenu(false)
        setIsTypeCoffeeMainMenu(false)
      },
      subItems: [
        {
          id: 'apps-logs-title',
          label: t('apps-logs-title'),
          link: '/apps-logs',
          parentId: 'settings-main-menu',
          visible: canMenu['apps_logs'],
        },
        {
          id: 'companies-title',
          label: t('companies-title'),
          link: '/companies',
          parentId: 'settings-main-menu',
          visible: canMenu['companies'],
        },
        {
          id: 'companies-owners-title',
          label: t('companies-owners-title'),
          link: '/companies-owners',
          parentId: 'settings-main-menu',
          visible: canMenu['companies_owners'],
        },
      ],
    },
    {
      id: 'agriculturals-inputs-main-menu',
      label: t('agriculturals-inputs-main-menu'),
      icon: 'ri-book-line',
      link: '/#',
      stateVariables: isAgriculturalInputMainMenu,
      visible:
        canMenu['agriculturals_inputs'] ||
        canMenu['agriculturals_inputs_cultivations'] ||
        canMenu['agriculturals_inputs_modes_actions'] ||
        canMenu['agriculturals_inputs_pests'] ||
        canMenu['agriculturals_inputs_subs_types'] ||
        canMenu['agriculturals_inputs_types'],
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(false)
        setIsExampleTableMainMenu(false)
        setIsEmployeeMainMenu(false)
        setIsUserMainMenu(false)
        setIsSettingMainMenu(false)
        setIsAgriculturalInputMainMenu(!isAgriculturalInputMainMenu)
        setIsFarmMainMenu(false)
        setIsDefaultMainMenu(false)
        setIsHarvestMainMenu(false)
        setIsOrderMainMenu(false)
        setIsTypeCoffeeMainMenu(false)
      },
      subItems: [
        {
          id: 'agriculturals-inputs-title',
          label: t('agriculturals-inputs-title'),
          link: '/agriculturals-inputs',
          parentId: 'agriculturals-inputs-main-menu',
          visible: canMenu['agriculturals_inputs'],
        },
        {
          id: 'agriculturals-inputs-cultivations-title',
          label: t('agriculturals-inputs-cultivations-title'),
          link: '/agriculturals-inputs-cultivations',
          parentId: 'agriculturals-inputs-main-menu',
          visible: canMenu['agriculturals_inputs_cultivations'],
        },
        {
          id: 'agriculturals-inputs-modes-actions-title',
          label: t('agriculturals-inputs-modes-actions-title'),
          link: '/agriculturals-inputs-modes-actions',
          parentId: 'agriculturals-inputs-main-menu',
          visible: canMenu['agriculturals_inputs_modes_actions'],
        },
        {
          id: 'agriculturals-inputs-pests-title',
          label: t('agriculturals-inputs-pests-title'),
          link: '/agriculturals-inputs-pests',
          parentId: 'agriculturals-inputs-main-menu',
          visible: canMenu['agriculturals_inputs_pests'],
        },
        {
          id: 'agriculturals-inputs-subs-types-title',
          label: t('agriculturals-inputs-subs-types-title'),
          link: '/agriculturals-inputs-subs-types',
          parentId: 'agriculturals-inputs-main-menu',
          visible: canMenu['agriculturals_inputs_subs_types'],
        },
        {
          id: 'agriculturals-inputs-types-title',
          label: t('agriculturals-inputs-types-title'),
          link: '/agriculturals-inputs-types',
          parentId: 'agriculturals-inputs-main-menu',
          visible: canMenu['agriculturals_inputs_types'],
        },
      ],
    },
    {
      id: 'farms-main-menu',
      label: t('farms-main-menu'),
      icon: 'ri-book-line',
      link: '/#',
      stateVariables: isFarmMainMenu,
      visible:
        canMenu['coffees_prunings'] ||
        canMenu['farms'] ||
        canMenu['areas'] ||
        canMenu['farms_areas'] ||
        canMenu['farms_cultivations'] ||
        canMenu['farms_fields'] ||
        canMenu['farms_fields_periods'] ||
        canMenu['farms_inventories'] ||
        canMenu['farms_owners'] ||
        canMenu['farms_users'],
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(false)
        setIsExampleTableMainMenu(false)
        setIsEmployeeMainMenu(false)
        setIsUserMainMenu(false)
        setIsSettingMainMenu(false)
        setIsAgriculturalInputMainMenu(false)
        setIsFarmMainMenu(!isFarmMainMenu)
        setIsDefaultMainMenu(false)
        setIsHarvestMainMenu(false)
        setIsOrderMainMenu(false)
        setIsTypeCoffeeMainMenu(false)
      },
      subItems: [
        {
          id: 'coffees-prunings-title',
          label: t('coffees-prunings-title'),
          link: '/coffees-prunings',
          parentId: 'farms-main-menu',
          visible: canMenu['coffees_prunings'],
        },
        {
          id: 'farms-title',
          label: t('farms-title'),
          link: '/farms',
          parentId: 'farms-main-menu',
          visible: canMenu['farms'],
        },
        {
          id: 'areas-title',
          label: t('areas-title'),
          link: '/areas',
          parentId: 'farms-main-menu',
          visible: canMenu['areas'],
        },
        {
          id: 'farms-areas-title',
          label: t('farms-areas-title'),
          link: '/farms-areas',
          parentId: 'farms-main-menu',
          visible: canMenu['farms_areas'],
        },
        {
          id: 'farms-cultivations-title',
          label: t('farms-cultivations-title'),
          link: '/farms-cultivations',
          parentId: 'farms-main-menu',
          visible: canMenu['farms_cultivations'],
        },
        {
          id: 'farms-fields-title',
          label: t('farms-fields-title'),
          link: '/farms-fields',
          parentId: 'farms-main-menu',
          visible: canMenu['farms_fields'],
        },
        {
          id: 'farms-fields-periods-title',
          label: t('farms-fields-periods-title'),
          link: '/farms-fields-periods',
          parentId: 'farms-main-menu',
          visible: canMenu['farms_fields_periods'],
        },
        {
          id: 'farms-inventories-title',
          label: t('farms-inventories-title'),
          link: '/farms-inventories',
          parentId: 'farms-main-menu',
          visible: canMenu['farms_inventories'],
        },
        {
          id: 'farms-owners-title',
          label: t('farms-owners-title'),
          link: '/farms-owners',
          parentId: 'farms-main-menu',
          visible: canMenu['farms_owners'],
        },
        {
          id: 'farms-users-title',
          label: t('farms-users-title'),
          link: '/farms-users',
          parentId: 'farms-main-menu',
          visible: canMenu['farms_users'],
        },
      ],
    },
    {
      id: 'defaults-main-menu',
      label: t('defaults-main-menu'),
      icon: 'ri-book-line',
      link: '/#',
      stateVariables: isDefaultMainMenu,
      visible:
        canMenu['defaults_agriculturals_inputs'] ||
        canMenu['defaults_agriculturals_inputs_cultivations'] ||
        canMenu['defaults_agriculturals_inputs_modes_actions'] ||
        canMenu['defaults_agriculturals_inputs_pests'] ||
        canMenu['defaults_agriculturals_inputs_subs_types'] ||
        canMenu['defaults_agriculturals_inputs_types'] ||
        canMenu['defaults_coffees_prunings'] ||
        canMenu['defaults_farms_fields_periods'] ||
        canMenu['defaults_types_coffees_colors'] ||
        canMenu['defaults_types_coffees_harvests'] ||
        canMenu['defaults_types_coffees_irrigations'] ||
        canMenu['defaults_types_coffees_managements'] ||
        canMenu['defaults_types_coffees_maturations'] ||
        canMenu['defaults_types_coffees_prunings'] ||
        canMenu['defaults_types_coffees_renovations'] ||
        canMenu['defaults_types_coffees_sizes'] ||
        canMenu['defaults_types_coffees_treatments'] ||
        canMenu['defaults_types_coffees_varieties'] ||
        canMenu['defaults_types_cultivations'] ||
        canMenu['defaults_types_expenses'] ||
        canMenu['defaults_types_measurements'] ||
        canMenu['defaults_types_pests'] ||
        canMenu['defaults_types_tools'],
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(false)
        setIsExampleTableMainMenu(false)
        setIsEmployeeMainMenu(false)
        setIsUserMainMenu(false)
        setIsSettingMainMenu(false)
        setIsAgriculturalInputMainMenu(false)
        setIsFarmMainMenu(false)
        setIsDefaultMainMenu(!isDefaultMainMenu)
        setIsHarvestMainMenu(false)
        setIsOrderMainMenu(false)
        setIsTypeCoffeeMainMenu(false)
      },
      subItems: [
        {
          id: 'defaults-agriculturals-inputs-title',
          label: t('defaults-agriculturals-inputs-title'),
          link: '/defaults-agriculturals-inputs',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_agriculturals_inputs'],
        },
        {
          id: 'defaults-agriculturals-inputs-cultivations-title',
          label: t('defaults-agriculturals-inputs-cultivations-title'),
          link: '/defaults-agriculturals-inputs-cultivations',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_agriculturals_inputs_cultivations'],
        },
        {
          id: 'defaults-agriculturals-inputs-modes-actions-title',
          label: t('defaults-agriculturals-inputs-modes-actions-title'),
          link: '/defaults-agriculturals-inputs-modes-actions',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_agriculturals_inputs_modes_actions'],
        },
        {
          id: 'defaults-agriculturals-inputs-pests-title',
          label: t('defaults-agriculturals-inputs-pests-title'),
          link: '/defaults-agriculturals-inputs-pests',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_agriculturals_inputs_pests'],
        },
        {
          id: 'defaults-agriculturals-inputs-subs-types-title',
          label: t('defaults-agriculturals-inputs-subs-types-title'),
          link: '/defaults-agriculturals-inputs-subs-types',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_agriculturals_inputs_subs_types'],
        },
        {
          id: 'defaults-agriculturals-inputs-types-title',
          label: t('defaults-agriculturals-inputs-types-title'),
          link: '/defaults-agriculturals-inputs-types',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_agriculturals_inputs_types'],
        },
        {
          id: 'defaults-coffees-prunings-title',
          label: t('defaults-coffees-prunings-title'),
          link: '/defaults-coffees-prunings',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_coffees_prunings'],
        },
        {
          id: 'defaults-farms-fields-periods-title',
          label: t('defaults-farms-fields-periods-title'),
          link: '/defaults-farms-fields-periods',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_farms_fields_periods'],
        },
        {
          id: 'defaults-types-coffees-colors-title',
          label: t('defaults-types-coffees-colors-title'),
          link: '/defaults-types-coffees-colors',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_coffees_colors'],
        },
        {
          id: 'defaults-types-coffees-harvests-title',
          label: t('defaults-types-coffees-harvests-title'),
          link: '/defaults-types-coffees-harvests',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_coffees_harvests'],
        },
        {
          id: 'defaults-types-coffees-irrigations-title',
          label: t('defaults-types-coffees-irrigations-title'),
          link: '/defaults-types-coffees-irrigations',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_coffees_irrigations'],
        },
        {
          id: 'defaults-types-coffees-managements-title',
          label: t('defaults-types-coffees-managements-title'),
          link: '/defaults-types-coffees-managements',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_coffees_managements'],
        },
        {
          id: 'defaults-types-coffees-maturations-title',
          label: t('defaults-types-coffees-maturations-title'),
          link: '/defaults-types-coffees-maturations',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_coffees_maturations'],
        },
        {
          id: 'defaults-types-coffees-prunings-title',
          label: t('defaults-types-coffees-prunings-title'),
          link: '/defaults-types-coffees-prunings',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_coffees_prunings'],
        },
        {
          id: 'defaults-types-coffees-renovations-title',
          label: t('defaults-types-coffees-renovations-title'),
          link: '/defaults-types-coffees-renovations',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_coffees_renovations'],
        },
        {
          id: 'defaults-types-coffees-sizes-title',
          label: t('defaults-types-coffees-sizes-title'),
          link: '/defaults-types-coffees-sizes',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_coffees_sizes'],
        },
        {
          id: 'defaults-types-coffees-treatments-title',
          label: t('defaults-types-coffees-treatments-title'),
          link: '/defaults-types-coffees-treatments',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_coffees_treatments'],
        },
        {
          id: 'defaults-types-coffees-varieties-title',
          label: t('defaults-types-coffees-varieties-title'),
          link: '/defaults-types-coffees-varieties',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_coffees_varieties'],
        },
        {
          id: 'defaults-types-cultivations-title',
          label: t('defaults-types-cultivations-title'),
          link: '/defaults-types-cultivations',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_cultivations'],
        },
        {
          id: 'defaults-types-expenses-title',
          label: t('defaults-types-expenses-title'),
          link: '/defaults-types-expenses',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_expenses'],
        },
        {
          id: 'defaults-types-measurements-title',
          label: t('defaults-types-measurements-title'),
          link: '/defaults-types-measurements',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_measurements'],
        },
        {
          id: 'defaults-types-pests-title',
          label: t('defaults-types-pests-title'),
          link: '/defaults-types-pests',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_pests'],
        },
        {
          id: 'defaults-types-tools-title',
          label: t('defaults-types-tools-title'),
          link: '/defaults-types-tools',
          parentId: 'defaults-main-menu',
          visible: canMenu['defaults_types_tools'],
        },
      ],
    },
    {
      id: 'harvests-main-menu',
      label: t('harvests-main-menu'),
      icon: 'ri-book-line',
      link: '/#',
      stateVariables: isHarvestMainMenu,
      visible:
        canMenu['harvests'] ||
        canMenu['harvests_costs_agriculturals_inputs'] ||
        canMenu['harvests_costs_services'] ||
        canMenu['harvests_forecasts'] ||
        canMenu['harvests_reals'] ||
        canMenu['harvests_productions'],
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(false)
        setIsExampleTableMainMenu(false)
        setIsEmployeeMainMenu(false)
        setIsUserMainMenu(false)
        setIsSettingMainMenu(false)
        setIsAgriculturalInputMainMenu(false)
        setIsFarmMainMenu(false)
        setIsDefaultMainMenu(false)
        setIsHarvestMainMenu(!isHarvestMainMenu)
        setIsOrderMainMenu(false)
        setIsTypeCoffeeMainMenu(false)
      },
      subItems: [
        {
          id: 'harvests-title',
          label: t('harvests-title'),
          link: '/harvests',
          parentId: 'harvests-main-menu',
          visible: canMenu['harvests'],
        },
        {
          id: 'harvests-costs-agriculturals-inputs-title',
          label: t('harvests-costs-agriculturals-inputs-title'),
          link: '/harvests-costs-agriculturals-inputs',
          parentId: 'harvests-main-menu',
          visible: canMenu['harvests_costs_agriculturals_inputs'],
        },
        {
          id: 'harvests-costs-services-title',
          label: t('harvests-costs-services-title'),
          link: '/harvests-costs-services',
          parentId: 'harvests-main-menu',
          visible: canMenu['harvests_costs_services'],
        },
        {
          id: 'harvests-forecasts-title',
          label: t('harvests-forecasts-title'),
          link: '/harvests-forecasts',
          parentId: 'harvests-main-menu',
          visible: canMenu['harvests_forecasts'],
        },
        {
          id: 'harvests-reals-title',
          label: t('harvests-reals-title'),
          link: '/harvests-reals',
          parentId: 'harvests-main-menu',
          visible: canMenu['harvests_reals'],
        },
        {
          id: 'harvests-productions-title',
          label: t('harvests-productions-title'),
          link: '/harvests-productions',
          parentId: 'harvests-main-menu',
          visible: canMenu['harvests_productions'],
        },
      ],
    },
    {
      id: 'orders-main-menu',
      label: t('orders-main-menu'),
      icon: 'ri-book-line',
      link: '/#',
      stateVariables: isOrderMainMenu,
      visible:
        canMenu['orders_services'] ||
        canMenu['orders_services_agriculturals_inputs'] ||
        canMenu['orders_services_employees'],
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(false)
        setIsExampleTableMainMenu(false)
        setIsEmployeeMainMenu(false)
        setIsUserMainMenu(false)
        setIsSettingMainMenu(false)
        setIsAgriculturalInputMainMenu(false)
        setIsFarmMainMenu(false)
        setIsDefaultMainMenu(false)
        setIsHarvestMainMenu(false)
        setIsOrderMainMenu(!isOrderMainMenu)
        setIsTypeCoffeeMainMenu(false)
      },
      subItems: [
        {
          id: 'orders-services-title',
          label: t('orders-services-title'),
          link: '/orders-services',
          parentId: 'orders-main-menu',
          visible: canMenu['orders_services'],
        },
        {
          id: 'orders-services-agriculturals-inputs-title',
          label: t('orders-services-agriculturals-inputs-title'),
          link: '/orders-services-agriculturals-inputs',
          parentId: 'orders-main-menu',
          visible: canMenu['orders_services_agriculturals_inputs'],
        },
        {
          id: 'orders-services-employees-title',
          label: t('orders-services-employees-title'),
          link: '/orders-services-employees',
          parentId: 'orders-main-menu',
          visible: canMenu['orders_services_employees'],
        },
      ],
    },
    {
      id: 'types-coffees-main-menu',
      label: t('types-coffees-main-menu'),
      icon: 'ri-book-line',
      link: '/#',
      stateVariables: isTypeCoffeeMainMenu,
      visible:
        canMenu['types_coffees_colors'] ||
        canMenu['types_coffees_harvests'] ||
        canMenu['types_coffees_irrigations'] ||
        canMenu['types_coffees_managements'] ||
        canMenu['types_coffees_maturations'] ||
        canMenu['types_coffees_prunings'] ||
        canMenu['types_coffees_renovations'] ||
        canMenu['types_coffees_sizes'] ||
        canMenu['types_coffees_treatments'] ||
        canMenu['types_coffees_varieties'] ||
        canMenu['types_cultivations'] ||
        canMenu['types_expenses'] ||
        canMenu['types_measurements'] ||
        canMenu['types_pests'] ||
        canMenu['types_tools'],
      click: function (e: any) {
        e.preventDefault()
        setIsDashboard(false)
        setIsExampleTableMainMenu(false)
        setIsEmployeeMainMenu(false)
        setIsUserMainMenu(false)
        setIsSettingMainMenu(false)
        setIsAgriculturalInputMainMenu(false)
        setIsFarmMainMenu(false)
        setIsDefaultMainMenu(false)
        setIsHarvestMainMenu(false)
        setIsOrderMainMenu(false)
        setIsTypeCoffeeMainMenu(!isTypeCoffeeMainMenu)
      },
      subItems: [
        {
          id: 'types-coffees-colors-title',
          label: t('types-coffees-colors-title'),
          link: '/types-coffees-colors',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_coffees_colors'],
        },
        {
          id: 'types-coffees-harvests-title',
          label: t('types-coffees-harvests-title'),
          link: '/types-coffees-harvests',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_coffees_harvests'],
        },
        {
          id: 'types-coffees-irrigations-title',
          label: t('types-coffees-irrigations-title'),
          link: '/types-coffees-irrigations',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_coffees_irrigations'],
        },
        {
          id: 'types-coffees-managements-title',
          label: t('types-coffees-managements-title'),
          link: '/types-coffees-managements',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_coffees_managements'],
        },
        {
          id: 'types-coffees-maturations-title',
          label: t('types-coffees-maturations-title'),
          link: '/types-coffees-maturations',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_coffees_maturations'],
        },
        {
          id: 'types-coffees-prunings-title',
          label: t('types-coffees-prunings-title'),
          link: '/types-coffees-prunings',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_coffees_prunings'],
        },
        {
          id: 'types-coffees-renovations-title',
          label: t('types-coffees-renovations-title'),
          link: '/types-coffees-renovations',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_coffees_renovations'],
        },
        {
          id: 'types-coffees-sizes-title',
          label: t('types-coffees-sizes-title'),
          link: '/types-coffees-sizes',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_coffees_sizes'],
        },
        {
          id: 'types-coffees-treatments-title',
          label: t('types-coffees-treatments-title'),
          link: '/types-coffees-treatments',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_coffees_treatments'],
        },
        {
          id: 'types-coffees-varieties-title',
          label: t('types-coffees-varieties-title'),
          link: '/types-coffees-varieties',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_coffees_varieties'],
        },
        {
          id: 'types-cultivations-title',
          label: t('types-cultivations-title'),
          link: '/types-cultivations',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_cultivations'],
        },
        {
          id: 'types-expenses-title',
          label: t('types-expenses-title'),
          link: '/types-expenses',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_expenses'],
        },
        {
          id: 'types-measurements-title',
          label: t('types-measurements-title'),
          link: '/types-measurements',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_measurements'],
        },
        {
          id: 'types-pests-title',
          label: t('types-pests-title'),
          link: '/types-pests',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_pests'],
        },
        {
          id: 'types-tools-title',
          label: t('types-tools-title'),
          link: '/types-tools',
          parentId: 'types-coffees-main-menu',
          visible: canMenu['types_tools'],
        },
      ],
    },
    //END-GENERATE
  ]
  return <React.Fragment>{menuItems}</React.Fragment>
}
export default Navdata
