import { InputPhoneComponent } from 'components/common/inputs/input-phone'
import { t } from 'i18next'
import { useMemo } from 'react'
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form'

interface ControlledProps<T extends FieldValues> {
  control: Control<T>
  name: FieldPath<T>
  label: string
  disabled?: boolean
  handleSelectCountry: (country: string) => void
}

export function ControlledInputPhone<T extends FieldValues>({
  control,
  name,
  label,
  disabled,
  handleSelectCountry,
}: ControlledProps<T>) {
  const {
    field,
    formState: { defaultValues },
    fieldState: { error },
  } = useController({ control, name })

  const mountedValue = useMemo(
    () => defaultValues?.['phone']?.['phone'],
    [defaultValues],
  )

  return (
    <InputPhoneComponent
      name={name}
      label={t(label)}
      disabled={disabled}
      key={mountedValue}
      value={field.value}
      onChange={field.onChange}
      error={error?.message}
      handleSelectCountry={handleSelectCountry}
    />
  )
}
