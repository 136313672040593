import { t } from 'i18next'
import { ModalFormTemplate } from 'components/common/modal/form-template'
import { useExampleTableList } from '../../use-examples-tables'
import { useModalFormExport } from './use-export-form'
import { FormProvider } from 'react-hook-form'
import { FormFilterControl } from '../../components/form-filter'
import { useModalFormFilter } from '../filter/use-filter-form'

export const Export = () => {
  const { exportOpen, handleCloseExport } = useExampleTableList()

  const {
    control,
    errors,
    form,
    exampleOtherOneInfo,
    exampleOtherTwoInfo,
    handleSelectCountry,
    register,
    resetFields,
    getValues,
    exportExamplesTables,
  } = useModalFormExport()

  const { handleFormValues } = useModalFormFilter()

  const handleConfirm = async () => {
    const formValues = getValues()
    const params = handleFormValues(formValues)

    const response = await exportExamplesTables(params)
    const exportExcelResponse = response?.exportExcelExamplesTablesDashboard

    if (exportExcelResponse) {
      window.open(exportExcelResponse, '_blank')
      handleClear()
      handleCloseExport()
    }
  }

  const handleClear = () => {
    resetFields()
  }

  return (
    <ModalFormTemplate
      isOpen={exportOpen}
      full={true}
      title={t('export')}
      cancelLabel={t('cancel-export')}
      handleConfirm={handleConfirm}
      handleClose={handleCloseExport}
      handleCancel={handleClear}
    >
      <form
        style={{ paddingLeft: 4 }}
        className="mt-4 pe-1 d-flex flex-wrap gap-3"
      >
        <FormProvider {...form}>
          <FormFilterControl
            control={control}
            errors={errors}
            exampleOtherOneInfo={exampleOtherOneInfo}
            exampleOtherTwoInfo={exampleOtherTwoInfo}
            handleSelectCountry={handleSelectCountry}
            register={register}
          />
        </FormProvider>
      </form>
    </ModalFormTemplate>
  )
}
