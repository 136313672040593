import { graphClient } from 'services/graph-client'
import {
  Company,
  Farm,
  TypeTool,
  FarmInventory,
} from 'types/farms/farms-inventories-types'

import {
  getCompaniesDashboardGQL,
  getFarmsDashboardGQL,
  getTypesToolsDashboardGQL,
  getFarmsInventoriesDashboardGQL,
  getFarmsInventoriesContainsDashboardGQL,
} from './queries'

interface Response {
  getFarmsInventoriesDashboard: {
    data: FarmInventory[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getFarmsInventoriesContainsDashboard: {
    data: FarmInventory[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  serialNumber?: string
  photoInvoice?: string
  yearPurchase?: string
}

export interface GetParams {
  page?: number
  farmId?: number
  name?: string
  serialNumber?: string
  purchasCost?: number
  photoInvoice?: string
  datePurchase?: string
  yearPurchase?: string
  typeToolId?: number
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getFarmsInventoriesDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getFarmsInventoriesDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getFarmsInventoriesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getFarmsInventoriesDashboardGQL, {
    take,
    skip,
  })
}

export const getFarmsInventoriesContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getFarmsInventoriesContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseFarm {
  getFarmsDashboard: {
    data: Farm[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarm>(getFarmsDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseTypeTool {
  getTypesToolsDashboard: {
    data: TypeTool[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesToolsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeTool>(
    getTypesToolsDashboardGQL,
    { take, skip },
  )
}
