import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import {
  createDefaultTypeCoffeeSize,
  updateDefaultTypeCoffeeSize,
} from 'services/defaults/defaults-types-coffees-sizes/defaults-types-coffees-sizes-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectDefaultTypeCoffeeSize,
} from 'slices/defaults/defaults-types-coffees-sizes-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('defaults-types-coffees-sizes-name')),

  description: z.string(),
})

export type FormData = z.infer<typeof schema>

const defaultTypeCoffeeSizeModalFormSelector = (state: StateRoot) => {
  return state.defaultsTypesCoffeesSizesReducer
}

export const selectorResult = createSelector(
  defaultTypeCoffeeSizeModalFormSelector,
  (defaultTypeCoffeeSizeReducer) => {
    return defaultTypeCoffeeSizeReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, defaultTypeCoffeeSizeSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('defaults_types_coffees_sizes')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectDefaultTypeCoffeeSize(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createDefaultTypeCoffeeSizeFn, isPending } = useMutation(
    {
      mutationFn: createDefaultTypeCoffeeSize,
    },
  )

  const {
    mutateAsync: updateDefaultTypeCoffeeSizeFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateDefaultTypeCoffeeSize,
  })

  const cleanFields: FormData = {
    name: '',
    description: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!defaultTypeCoffeeSizeSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: defaultTypeCoffeeSizeSelected.name,
      description: defaultTypeCoffeeSizeSelected.description,
    }

    reset(defaultTypeCoffeeSizeSelected ? fields : cleanFields)
  }, [defaultTypeCoffeeSizeSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        name: data?.name,
        description: data?.description,
      }

      if (defaultTypeCoffeeSizeSelected && defaultTypeCoffeeSizeSelected?.id) {
        const { updateDefaultTypeCoffeeSizeDashboard } =
          await updateDefaultTypeCoffeeSizeFn({
            ...formatedData,
            defaultTypeCoffeeSizeId: defaultTypeCoffeeSizeSelected?.id,
          })

        dispatch(editUniqueData(updateDefaultTypeCoffeeSizeDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createDefaultTypeCoffeeSizeFn(formatedData)

      dispatch(
        pushData(responseData?.createDefaultTypeCoffeeSizeDashboard?.data),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    defaultTypeCoffeeSizeSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
