import { createSelector } from 'reselect'
import { DefaultAgriculturalInputType } from 'types/defaults/defaults-agriculturals-inputs-types-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverDefaultAgriculturalInputType } from 'services/defaults/defaults-agriculturals-inputs-types/defaults-agriculturals-inputs-types-cruds'
import {
  getDefaultsAgriculturalsInputsTypesDashboard,
  GetParams,
  getDefaultsAgriculturalsInputsTypesContainsDashboard,
} from 'services/defaults/defaults-agriculturals-inputs-types/defaults-agriculturals-inputs-types-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/defaults/defaults-agriculturals-inputs-types-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const defaultAgriculturalInputTypeListSelector = (state: StateRoot) =>
  state.defaultsAgriculturalsInputsTypesReducer

const selectorResult = createSelector(
  defaultAgriculturalInputTypeListSelector,
  (defaultAgriculturalInputTypeListReducer) => {
    return defaultAgriculturalInputTypeListReducer
  },
)

export const useDefaultAgriculturalInputTypeList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('defaults_agriculturals_inputs_types')

  const {
    data: dataDefaultAgriculturalInputType,
    deleteModalOpen,
    defaultAgriculturalInputTypeIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'defaultAgriculturalInputType', 'list', { params }],
    queryFn: () => getDefaultsAgriculturalsInputsTypesDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverDefaultAgriculturalInputType,
  })

  const handleStoreData = useCallback(
    (
      defaultsAgriculturalsInputsTypes: DefaultAgriculturalInputType[],
      totalPages: number | null,
    ) => {
      if (!defaultsAgriculturalsInputsTypes) return

      dispatch(setData(defaultsAgriculturalsInputsTypes))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverDefaultAgriculturalInputType =
    useCallback(async () => {
      if (!defaultAgriculturalInputTypeIdToDelete) return

      const response = await mutateAsync({
        defaultAgriculturalInputTypeId: +defaultAgriculturalInputTypeIdToDelete,
      })

      if (
        response?.deleteForeverDefaultAgriculturalInputTypeDashboard ===
        'deleted_forever'
      ) {
        dispatch(
          deleteUniqueData({ id: defaultAgriculturalInputTypeIdToDelete }),
        )
      }

      dispatch(closeDeleteModal())
    }, [defaultAgriculturalInputTypeIdToDelete])

  useEffect(() => {
    const defaultsAgriculturalsInputsTypesResult =
      data?.getDefaultsAgriculturalsInputsTypesDashboard

    if (defaultsAgriculturalsInputsTypesResult) {
      handleStoreData(
        defaultsAgriculturalsInputsTypesResult?.data,
        defaultsAgriculturalsInputsTypesResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response =
        await getDefaultsAgriculturalsInputsTypesContainsDashboard(value)
      const defaultsAgriculturalsInputsTypesResult =
        response?.getDefaultsAgriculturalsInputsTypesContainsDashboard

      handleStoreData(
        defaultsAgriculturalsInputsTypesResult?.data,
        defaultsAgriculturalsInputsTypesResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    defaultAgriculturalInputTypeIdToDelete,
    defaultAgriculturalInputType:
      data?.getDefaultsAgriculturalsInputsTypesDashboard?.data,
    isLoading,
    isFetching,
    dataDefaultAgriculturalInputType,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverDefaultAgriculturalInputType,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
