import { graphClient } from 'services/graph-client'
import { UserType } from 'types/users/users-types-types'

import { getUsersTypesDashboardGQL } from 'services/users/users-types/queries'

interface Response {
  getUsersTypesDashboard: {
    data: UserType[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getUsersTypesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getUsersTypesDashboardGQL, {
    take,
    skip,
  })
}
