import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app'
import rootReducer from './slices'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { env } from 'lib/constants/env'
import './assets/base/base.css'

const store = configureStore({ reducer: rootReducer, devTools: true })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter basename={env.publicUrl}>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>,
)
