import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'

const schemaFilter = z.object({
  name: z.string().min(1, t('inform-field') + ' ' + t('farms-users-name')),

  farmId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-users-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  active: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-users-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-users-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    name: '',
    farmId: '',
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    getValues,
  } = form

  const farmInfo = useGetFarmsDashboard()

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    farmInfo,
    register,
    resetFields,
    getValues,
  }
}
