import { graphClient } from 'services/graph-client'
import {
  activeFarmAreaDashboardGQL,
  deleteFarmAreaDashboardGQL,
  deleteForeverFarmAreaDashboardGQL,
  createFarmAreaDashboardGQL,
  updateFarmAreaDashboardGQL,
} from './queries'
import { FarmArea } from 'types/farms/farms-areas-types'

interface ResponseCreate {
  createFarmAreaDashboard: {
    data: FarmArea
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateFarmAreaDashboard: {
    data: FarmArea
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeFarmAreaDashboard: {
    data: FarmArea
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteFarmAreaDashboard: {
    data: FarmArea
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverFarmAreaDashboard: string
}

interface DataActive {
  farmAreaId: number
  active: boolean
}

interface DataDeleted {
  farmAreaId: number
  deleted: boolean
}

interface DataDeleteForever {
  farmAreaId: number
}

interface DataCreate {
  name: string
  description: string
  farmId: number
  areaId: number
}

interface DataUpdate {
  farmAreaId: number
  name: string
  description: string
  farmId: number
  areaId: number
}

export const activeFarmArea = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeFarmAreaDashboardGQL, {
    ...data,
  })
}

export const deleteFarmArea = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteFarmAreaDashboardGQL, {
    ...data,
  })
}

export const deleteForeverFarmArea = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverFarmAreaDashboardGQL,
    { ...data },
  )
}

export const createFarmArea = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(createFarmAreaDashboardGQL, {
    ...data,
  })
}

export const updateFarmArea = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateFarmAreaDashboardGQL, {
    ...data,
  })
}
