import { createSelector } from 'reselect'
import { TypeCoffeePruning } from 'types/types-coffees/types-coffees-prunings-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverTypeCoffeePruning } from 'services/types-coffees/types-coffees-prunings/types-coffees-prunings-cruds'
import {
  getTypesCoffeesPruningsDashboard,
  GetParams,
  getTypesCoffeesPruningsContainsDashboard,
} from 'services/types-coffees/types-coffees-prunings/types-coffees-prunings-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/types-coffees/types-coffees-prunings-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const typeCoffeePruningListSelector = (state: StateRoot) =>
  state.typesCoffeesPruningsReducer

const selectorResult = createSelector(
  typeCoffeePruningListSelector,
  (typeCoffeePruningListReducer) => {
    return typeCoffeePruningListReducer
  },
)

export const useTypeCoffeePruningList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('types_coffees_prunings')

  const {
    data: dataTypeCoffeePruning,
    deleteModalOpen,
    typeCoffeePruningIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'typeCoffeePruning', 'list', { params }],
    queryFn: () => getTypesCoffeesPruningsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverTypeCoffeePruning,
  })

  const handleStoreData = useCallback(
    (typesCoffeesPrunings: TypeCoffeePruning[], totalPages: number | null) => {
      if (!typesCoffeesPrunings) return

      dispatch(setData(typesCoffeesPrunings))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverTypeCoffeePruning = useCallback(async () => {
    if (!typeCoffeePruningIdToDelete) return

    const response = await mutateAsync({
      typeCoffeePruningId: +typeCoffeePruningIdToDelete,
    })

    if (
      response?.deleteForeverTypeCoffeePruningDashboard === 'deleted_forever'
    ) {
      dispatch(deleteUniqueData({ id: typeCoffeePruningIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [typeCoffeePruningIdToDelete])

  useEffect(() => {
    const typesCoffeesPruningsResult = data?.getTypesCoffeesPruningsDashboard

    if (typesCoffeesPruningsResult) {
      handleStoreData(
        typesCoffeesPruningsResult?.data,
        typesCoffeesPruningsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getTypesCoffeesPruningsContainsDashboard(value)
      const typesCoffeesPruningsResult =
        response?.getTypesCoffeesPruningsContainsDashboard

      handleStoreData(
        typesCoffeesPruningsResult?.data,
        typesCoffeesPruningsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    typeCoffeePruningIdToDelete,
    typeCoffeePruning: data?.getTypesCoffeesPruningsDashboard?.data,
    isLoading,
    isFetching,
    dataTypeCoffeePruning,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverTypeCoffeePruning,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
