import { gql } from 'graphql-request'

export const createDefaultTypeCoffeeSizeDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_COFFEE_SIZE_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createDefaultTypeCoffeeSizeDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypeCoffeeSizeDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_COFFEE_SIZE_DASHBOARD(
    $defaultTypeCoffeeSizeId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultTypeCoffeeSizeDashboard(
      defaultTypeCoffeeSizeId: $defaultTypeCoffeeSizeId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypeCoffeeSizeDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_COFFEE_SIZE_DASHBOARD(
    $defaultTypeCoffeeSizeId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypeCoffeeSizeDashboard(
      defaultTypeCoffeeSizeId: $defaultTypeCoffeeSizeId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypeCoffeeSizeDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_COFFEE_SIZE_DASHBOARD(
    $defaultTypeCoffeeSizeId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypeCoffeeSizeDashboard(
      defaultTypeCoffeeSizeId: $defaultTypeCoffeeSizeId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypeCoffeeSizeDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_COFFEE_SIZE_DASHBOARD(
    $defaultTypeCoffeeSizeId: Int!
  ) {
    deleteForeverDefaultTypeCoffeeSizeDashboard(
      defaultTypeCoffeeSizeId: $defaultTypeCoffeeSizeId
    )
  }
`

export const getDefaultsTypesCoffeesSizesDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_SIZES_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesCoffeesSizesDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesCoffeesSizesContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_SIZES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsTypesCoffeesSizesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
