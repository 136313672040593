import { Status } from './list-columns'
import { formatDate } from 'helpers/format/format-date'
import { t } from 'i18next'

export const columns = [
  {
    Header: t('id'),
    accessor: 'id',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span className="fw-medium link-primary">{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-farm-field-id'),
    accessor: 'farmField.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-farm-id'),
    accessor: 'farm.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-type-cultivation-id'),
    accessor: 'typeCultivation.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-type-measurement-id'),
    accessor: 'typeMeasurement.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-name'),
    accessor: 'name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-description'),
    accessor: 'description',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-automatic-cost'),
    accessor: 'automaticCost',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('harvests-date-harvest-initial'),
    accessor: 'dateHarvestInitial',
    filterable: false,
    Cell: (cellProps: any) => {
      const date = cellProps?.value

      return <span>{formatDate(date)}</span>
    },
  },
  {
    Header: t('harvests-date-harvest-final'),
    accessor: 'dateHarvestFinal',
    filterable: false,
    Cell: (cellProps: any) => {
      const date = cellProps?.value

      return <span>{formatDate(date)}</span>
    },
  },
  {
    Header: t('active'),
    accessor: 'actions',
    filterable: false,
    Cell: (props: any, cellProps: any) => {
      return <Status {...cellProps} {...props} showActive={true} />
    },
  },
]
