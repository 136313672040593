import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { InputDecimalComponent } from 'components/common/inputs/input-decimal'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetHarvestsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-harvests-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  farmFieldInfo: ReturnType<typeof useGetFarmsFieldsDashboard>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  harvestInfo: ReturnType<typeof useGetHarvestsDashboard>
  register: UseFormRegister<FormData>
}

export const FormControl = ({
  control,
  errors,
  farmFieldInfo,
  farmInfo,
  harvestInfo,
  register,
}: Props) => {
  const permissions = useGetPermissions('harvests_reals')
  const { harvestRealSelected } = useSelector(selectorResult)
  const disabled = !!(harvestRealSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmFieldInfo?.farmsFieldsDashboard}
            info={farmFieldInfo}
            label={t('harvests-reals-farm-field-id')}
            name="farmFieldId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('harvests-reals-farm-id')}
            name="farmId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={harvestInfo?.harvestsDashboard}
            info={harvestInfo}
            label={t('harvests-reals-harvest-id')}
            name="harvestId"
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.forecastPlant?.message}
            {...register('forecastPlant')}
            label={t('harvests-reals-forecast-plant')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.litersSpentPerBag?.message}
            {...register('litersSpentPerBag')}
            label={t('harvests-reals-liters-spent-per-bag')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.bagPerHectare?.message}
            {...register('bagPerHectare')}
            label={t('harvests-reals-bag-per-hectare')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.totalBag?.message}
            {...register('totalBag')}
            label={t('harvests-reals-total-bag')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('harvests-reals-name')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
