import { gql } from 'graphql-request'

export const createDefaultTypePestDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_PEST_DASHBOARD(
    $commonName: String!
    $scientificName: String
    $name: String!
    $description: String!
  ) {
    createDefaultTypePestDashboard(
      commonName: $commonName
      scientificName: $scientificName
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        commonName
        scientificName
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypePestDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_PEST_DASHBOARD(
    $defaultTypePestId: Int!
    $commonName: String
    $scientificName: String
    $name: String
    $description: String
  ) {
    updateDefaultTypePestDashboard(
      defaultTypePestId: $defaultTypePestId
      commonName: $commonName
      scientificName: $scientificName
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        commonName
        scientificName
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypePestDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_PEST_DASHBOARD(
    $defaultTypePestId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypePestDashboard(
      defaultTypePestId: $defaultTypePestId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        commonName
        scientificName
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypePestDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_PEST_DASHBOARD(
    $defaultTypePestId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypePestDashboard(
      defaultTypePestId: $defaultTypePestId
      active: $active
    ) {
      error
      status
      data {
        id
        commonName
        scientificName
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypePestDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_PEST_DASHBOARD(
    $defaultTypePestId: Int!
  ) {
    deleteForeverDefaultTypePestDashboard(defaultTypePestId: $defaultTypePestId)
  }
`

export const getDefaultsTypesPestsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_PESTS_DASHBOARD(
    $take: Int
    $skip: Int
    $commonName: String
    $scientificName: String
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesPestsDashboard(
      take: $take
      skip: $skip
      commonName: $commonName
      scientificName: $scientificName
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        commonName
        scientificName
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesPestsContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_PESTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $commonName: String
    $scientificName: String
    $name: String
    $description: String
  ) {
    getDefaultsTypesPestsContainsDashboard(
      take: $take
      skip: $skip
      commonName: $commonName
      scientificName: $scientificName
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        commonName
        scientificName
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
