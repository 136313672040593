import flagus from '../assets/images/flags/us.svg'
import flagspain from '../assets/images/flags/spain.svg'
import flagportuguesebr from '../assets/images/flags/br.svg'

const languages = {
  sp: {
    label: 'Española',
    flag: flagspain,
  },
  en: {
    label: 'English',
    flag: flagus,
  },
  ptbr: {
    label: 'Portuguese',
    flag: flagportuguesebr,
  },
}

export default languages
