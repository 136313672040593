import { ModalFormTemplate } from 'components/common/modal/form-template'
import { t } from 'i18next'
import { useAgriculturalInputList } from '../../use-agriculturals-inputs'
import { useModalFormFilter } from './use-filter-form'
import { FormProvider } from 'react-hook-form'
import { FormFilterControl } from '../../components/form-filter'
import { formatDateDB } from 'helpers/format/format-date'

export const Filter = () => {
  const { filterOpen, handleCloseFilter, handleSetFilter } =
    useAgriculturalInputList()

  const {
    control,
    errors,
    form,
    agriculturalInputSubTypeInfo,
    agriculturalInputModeActionInfo,
    register,
    resetFields,
    getValues,
  } = useModalFormFilter()

  const handleConfirm = () => {
    const values: Record<string, any> = {}
    const formValues = getValues()

    if (typeof formValues.agriculturalInputSubTypeId != 'undefined') {
      if (typeof formValues.agriculturalInputSubTypeId === 'object') {
        values['agriculturalInputSubTypeId'] =
          formValues.agriculturalInputSubTypeId.value
      }
    }

    if (typeof formValues.agriculturalInputModeActionId != 'undefined') {
      if (typeof formValues.agriculturalInputModeActionId === 'object') {
        values['agriculturalInputModeActionId'] =
          formValues.agriculturalInputModeActionId.value
      }
    }

    if (typeof formValues.name != 'undefined' && formValues.name != '') {
      values['name'] = formValues.name
    }

    if (
      typeof formValues.description != 'undefined' &&
      formValues.description != ''
    ) {
      values['description'] = formValues.description
    }

    if (
      typeof formValues.tradeMark != 'undefined' &&
      formValues.tradeMark != ''
    ) {
      values['tradeMark'] = formValues.tradeMark
    }

    if (
      typeof formValues.formulation != 'undefined' &&
      formValues.formulation != ''
    ) {
      values['formulation'] = formValues.formulation
    }

    if (
      typeof formValues.activeIngredient != 'undefined' &&
      formValues.activeIngredient != ''
    ) {
      values['activeIngredient'] = formValues.activeIngredient
    }

    if (
      typeof formValues.toxicologicalClass != 'undefined' &&
      formValues.toxicologicalClass != ''
    ) {
      values['toxicologicalClass'] = formValues.toxicologicalClass
    }

    if (
      typeof formValues.environmentalClass != 'undefined' &&
      formValues.environmentalClass != ''
    ) {
      values['environmentalClass'] = formValues.environmentalClass
    }

    if (typeof formValues.isPesticide != 'undefined') {
      if (typeof formValues.isPesticide === 'object') {
        if (formValues.isPesticide.value === 'true') {
          values['isPesticide'] = true
        }
        if (formValues.isPesticide.value === 'false') {
          values['isPesticide'] = false
        }
      }
    }

    if (typeof formValues.isOrganic != 'undefined') {
      if (typeof formValues.isOrganic === 'object') {
        if (formValues.isOrganic.value === 'true') {
          values['isOrganic'] = true
        }
        if (formValues.isOrganic.value === 'false') {
          values['isOrganic'] = false
        }
      }
    }

    if (typeof formValues.isFertilizer != 'undefined') {
      if (typeof formValues.isFertilizer === 'object') {
        if (formValues.isFertilizer.value === 'true') {
          values['isFertilizer'] = true
        }
        if (formValues.isFertilizer.value === 'false') {
          values['isFertilizer'] = false
        }
      }
    }

    if (typeof formValues.active != 'undefined') {
      if (typeof formValues.active === 'object') {
        if (formValues.active.value === 'true') {
          values['active'] = true
        }
        if (formValues.active.value === 'false') {
          values['active'] = false
        }
      }
    }

    if (typeof formValues.deleted != 'undefined') {
      if (typeof formValues.deleted === 'object') {
        if (formValues.deleted.value === 'true') {
          values['deleted'] = true
        }
        if (formValues.deleted.value === 'false') {
          values['deleted'] = false
        }
      }
    }

    if (
      typeof formValues.createdAtInitial != 'undefined' &&
      formValues.createdAtInitial != '' &&
      typeof formValues.createdAtFinal != 'undefined' &&
      formValues.createdAtFinal != ''
    ) {
      values['createdAtInitial'] = formatDateDB(formValues.createdAtInitial)
      values['createdAtFinal'] = formatDateDB(formValues.createdAtFinal)
    }

    handleSetFilter(values)
    handleCloseFilter()
  }

  const handleClear = () => {
    resetFields()
  }

  return (
    <ModalFormTemplate
      isOpen={filterOpen}
      //full={false}
      //middle
      full={true}
      title={t('filters')}
      cancelLabel={t('cancel-filter')}
      handleConfirm={handleConfirm}
      handleClose={handleCloseFilter}
      handleCancel={handleClear}
    >
      <form
        style={{ paddingLeft: 4 }}
        className="mt-4 pe-1 d-flex flex-wrap gap-3"
      >
        <FormProvider {...form}>
          <FormFilterControl
            control={control}
            errors={errors}
            agriculturalInputSubTypeInfo={agriculturalInputSubTypeInfo}
            agriculturalInputModeActionInfo={agriculturalInputModeActionInfo}
            register={register}
          />
        </FormProvider>
      </form>
    </ModalFormTemplate>
  )
}
