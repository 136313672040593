import { createSelector } from 'reselect'
import { User } from 'types/users/users-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverUser } from 'services/users/users/users-cruds'
import {
  getUsersDashboard,
  GetParams,
  getUsersContainsDashboard,
} from 'services/users/users/users-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/users/users-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const userListSelector = (state: StateRoot) => state.usersReducer

const selectorResult = createSelector(userListSelector, (userListReducer) => {
  return userListReducer
})

export const useUserList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('users')

  const {
    data: dataUser,
    deleteModalOpen,
    userIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'user', 'list', { params }],
    queryFn: () => getUsersDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverUser,
  })

  const handleStoreData = useCallback(
    (users: User[], totalPages: number | null) => {
      if (!users) return

      dispatch(setData(users))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverUser = useCallback(async () => {
    if (!userIdToDelete) return

    const response = await mutateAsync({
      userId: +userIdToDelete,
    })

    if (response?.deleteForeverUserDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: userIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [userIdToDelete])

  useEffect(() => {
    const usersResult = data?.getUsersDashboard

    if (usersResult) {
      handleStoreData(usersResult?.data, usersResult?.totalPages)
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getUsersContainsDashboard(value)
      const usersResult = response?.getUsersContainsDashboard

      handleStoreData(usersResult?.data, usersResult?.totalPages)
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    userIdToDelete,
    user: data?.getUsersDashboard?.data,
    isLoading,
    isFetching,
    dataUser,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverUser,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
