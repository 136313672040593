import { createSlice } from '@reduxjs/toolkit'
import { TypeMeasurement } from 'types/types-coffees/types-measurements-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  typeMeasurementSelected: null | TypeMeasurement
  deleteModalOpen: boolean
  typeMeasurementIdToDelete: null | string
  data: TypeMeasurement[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  typeMeasurementSelected: null,
  typeMeasurementIdToDelete: null,
  data: [],
  total: 0,
}

const typeMeasurementSlice = createSlice({
  initialState,
  name: 'types-measurements-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectTypeMeasurement(
      state,
      { payload }: { payload: TypeMeasurement | null },
    ) {
      state.typeMeasurementSelected = payload
    },
    setData(state, { payload }: { payload: TypeMeasurement[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: TypeMeasurement }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: TypeMeasurement }) {
      state.data = state.data.map((typeMeasurement) => {
        return typeMeasurement.id === payload.id
          ? { ...typeMeasurement, ...payload }
          : typeMeasurement
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (typeMeasurement) => +typeMeasurement.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.typeMeasurementIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.typeMeasurementIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectTypeMeasurement,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = typeMeasurementSlice.actions
export default typeMeasurementSlice.reducer
