import { graphClient } from 'services/graph-client'
import { ExampleOtherTwo } from 'types/examples-tables/examples-others-twos-types'

import { getExamplesOthersTwosDashboardGQL } from 'services/examples-tables/examples-others-twos/queries'

interface Response {
  getExamplesOthersTwosDashboard: {
    data: ExampleOtherTwo[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getExamplesOthersTwosDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getExamplesOthersTwosDashboardGQL,
    {
      take,
      skip,
    },
  )
}
