import { graphClient } from 'services/graph-client'
import {
  activeDefaultCoffeePruningDashboardGQL,
  deleteDefaultCoffeePruningDashboardGQL,
  deleteForeverDefaultCoffeePruningDashboardGQL,
  createDefaultCoffeePruningDashboardGQL,
  updateDefaultCoffeePruningDashboardGQL,
} from './queries'
import { DefaultCoffeePruning } from 'types/defaults/defaults-coffees-prunings-types'

interface ResponseCreate {
  createDefaultCoffeePruningDashboard: {
    data: DefaultCoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultCoffeePruningDashboard: {
    data: DefaultCoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultCoffeePruningDashboard: {
    data: DefaultCoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultCoffeePruningDashboard: {
    data: DefaultCoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultCoffeePruningDashboard: string
}

interface DataActive {
  defaultCoffeePruningId: number
  active: boolean
}

interface DataDeleted {
  defaultCoffeePruningId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultCoffeePruningId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  defaultCoffeePruningId: number
  name: string
  description?: string
}

export const activeDefaultCoffeePruning = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultCoffeePruning = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultCoffeePruning = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const createDefaultCoffeePruning = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const updateDefaultCoffeePruning = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultCoffeePruningDashboardGQL,
    { ...data },
  )
}
