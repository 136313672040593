import { gql } from 'graphql-request'

export const createAgriculturalInputModeActionDashboardGQL = gql`
  mutation CREATE_AGRICULTURAL_INPUT_MODE_ACTION_DASHBOARD(
    $name: String!
    $description: String!
  ) {
    createAgriculturalInputModeActionDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateAgriculturalInputModeActionDashboardGQL = gql`
  mutation UPDATE_AGRICULTURAL_INPUT_MODE_ACTION_DASHBOARD(
    $agriculturalInputModeActionId: Int!
    $name: String
    $description: String
  ) {
    updateAgriculturalInputModeActionDashboard(
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteAgriculturalInputModeActionDashboardGQL = gql`
  mutation DELETE_AGRICULTURAL_INPUT_MODE_ACTION_DASHBOARD(
    $agriculturalInputModeActionId: Int!
    $deleted: Boolean!
  ) {
    deleteAgriculturalInputModeActionDashboard(
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeAgriculturalInputModeActionDashboardGQL = gql`
  mutation ACTIVE_AGRICULTURAL_INPUT_MODE_ACTION_DASHBOARD(
    $agriculturalInputModeActionId: Int!
    $active: Boolean!
  ) {
    activeAgriculturalInputModeActionDashboard(
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverAgriculturalInputModeActionDashboardGQL = gql`
  mutation DELETE_FOREVER_AGRICULTURAL_INPUT_MODE_ACTION_DASHBOARD(
    $agriculturalInputModeActionId: Int!
  ) {
    deleteForeverAgriculturalInputModeActionDashboard(
      agriculturalInputModeActionId: $agriculturalInputModeActionId
    )
  }
`

export const getAgriculturalsInputsModesActionsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_MODES_ACTIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getAgriculturalsInputsModesActionsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsModesActionsContainsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_MODES_ACTIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getAgriculturalsInputsModesActionsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
