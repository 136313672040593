import { graphClient } from 'services/graph-client'
import {
  activeFarmCultivationDashboardGQL,
  deleteFarmCultivationDashboardGQL,
  deleteForeverFarmCultivationDashboardGQL,
  createFarmCultivationDashboardGQL,
  updateFarmCultivationDashboardGQL,
} from './queries'
import { FarmCultivation } from 'types/farms/farms-cultivations-types'

interface ResponseCreate {
  createFarmCultivationDashboard: {
    data: FarmCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateFarmCultivationDashboard: {
    data: FarmCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeFarmCultivationDashboard: {
    data: FarmCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteFarmCultivationDashboard: {
    data: FarmCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverFarmCultivationDashboard: string
}

interface DataActive {
  farmCultivationId: number
  active: boolean
}

interface DataDeleted {
  farmCultivationId: number
  deleted: boolean
}

interface DataDeleteForever {
  farmCultivationId: number
}

interface DataCreate {
  farmId?: number
  typeCultivationId: number
  name: string
}

interface DataUpdate {
  farmCultivationId: number
  farmId?: number
  typeCultivationId: number
  name: string
}

export const activeFarmCultivation = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeFarmCultivationDashboardGQL,
    { ...data },
  )
}

export const deleteFarmCultivation = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteFarmCultivationDashboardGQL,
    { ...data },
  )
}

export const deleteForeverFarmCultivation = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverFarmCultivationDashboardGQL,
    { ...data },
  )
}

export const createFarmCultivation = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createFarmCultivationDashboardGQL,
    { ...data },
  )
}

export const updateFarmCultivation = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateFarmCultivationDashboardGQL,
    { ...data },
  )
}
