import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from 'react-table'
import { Table, Row, Col, Button, CardBody } from 'reactstrap'
import { DefaultColumnFilter } from './filters'
import {
  ProductsGlobalFilter,
  CustomersGlobalFilter,
  OrderGlobalFilter,
  ContactsGlobalFilter,
  CompaniesGlobalFilter,
  LeadsGlobalFilter,
  CryptoOrdersGlobalFilter,
  InvoiceListGlobalSearch,
  TicketsListGlobalFilter,
  NFTRankingGlobalFilter,
  TaskListGlobalFilter,
} from './global-search-filter'
import SimpleBar from 'simplebar-react'
import { useIsMobile } from 'lib/hooks/use-is-mobile'

interface GlobaFilerPropes {
  preGlobalFilteredRows?: any
  globalFilter?: any
  setGlobalFilter?: any
  isCustomerFilter?: any
  isOrderFilter?: any
  isContactsFilter?: any
  isCompaniesFilter?: any
  isCryptoOrdersFilter?: any
  isInvoiceListFilter?: any
  isTicketsListFilter?: any
  isNFTRankingFilter?: any
  isTaskListFilter?: any
  isProductsFilter?: any
  isLeadsFilter?: any
  SearchPlaceholder?: any
}

// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  isProductsFilter,
  isLeadsFilter,
  SearchPlaceholder,
}: GlobaFilerPropes) {
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <CardBody className="border border-dashed border-end-0 border-start-0">
        <form>
          <Row>
            <Col sm={5}>
              <div
                className={
                  isProductsFilter ||
                  isContactsFilter ||
                  isCompaniesFilter ||
                  isNFTRankingFilter
                    ? 'search-box me-2 mb-2 d-inline-block'
                    : 'search-box me-2 mb-2 d-inline-block col-12'
                }
              >
                <input
                  onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control"
                  placeholder={SearchPlaceholder}
                  value={value || ''}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
            {isProductsFilter && <ProductsGlobalFilter />}
            {isCustomerFilter && <CustomersGlobalFilter />}
            {isOrderFilter && <OrderGlobalFilter />}
            {isContactsFilter && <ContactsGlobalFilter />}
            {isCompaniesFilter && <CompaniesGlobalFilter />}
            {isLeadsFilter && <LeadsGlobalFilter />}
            {isCryptoOrdersFilter && <CryptoOrdersGlobalFilter />}
            {isInvoiceListFilter && <InvoiceListGlobalSearch />}
            {isTicketsListFilter && <TicketsListGlobalFilter />}
            {isNFTRankingFilter && <NFTRankingGlobalFilter />}
            {isTaskListFilter && <TaskListGlobalFilter />}
          </Row>
        </form>
      </CardBody>
    </React.Fragment>
  )
}

interface TableContainerProps {
  className?: any
  columns?: any
  data?: any
  isGlobalSearch?: any
  isGlobalFilter?: any
  isProductsFilter?: any
  isCustomerFilter?: any
  isOrderFilter?: any
  isContactsFilter?: any
  isCompaniesFilter?: any
  isLeadsFilter?: any
  isCryptoOrdersFilter?: any
  isInvoiceListFilter?: any
  isTicketsListFilter?: any
  isNFTRankingFilter?: any
  isTaskListFilter?: any
  isAddOptions?: any
  isAddUserList?: any
  handleOrderClicks?: any
  handleUserClick?: any
  handleCustomerClick?: any
  handleTaskClick?: any
  isAddCustList?: any
  customPageSize?: any
  tableClass?: any
  theadClass?: any
  trClass?: any
  thClass?: any
  divClass?: any
  SearchPlaceholder?: any
  handleLeadClick?: any
  handleCompanyClick?: any
  handleContactClick?: any
  handleTicketClick?: any
  noScroll?: boolean
}

const TableContainer = ({
  columns,
  data,
  isGlobalSearch,
  isGlobalFilter,
  isProductsFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isLeadsFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  SearchPlaceholder,
  noScroll,
}: TableContainerProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        selectedRowIds: 0,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  )

  const onChangeInSelect = (event: any) => {
    setPageSize(Number(event.target.value))
  }

  const generateSortingIndicator = (column: any) => {
    return column.isSorted ? (column.isSortedDesc ? ' ' : '') : ''
  }

  const isMobile = useIsMobile()

  return (
    <Fragment>
      <Row className="mb-3">
        {isGlobalSearch && (
          <Col md={1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isProductsFilter={isProductsFilter}
            isCustomerFilter={isCustomerFilter}
            isOrderFilter={isOrderFilter}
            isContactsFilter={isContactsFilter}
            isCompaniesFilter={isCompaniesFilter}
            isLeadsFilter={isLeadsFilter}
            isCryptoOrdersFilter={isCryptoOrdersFilter}
            isInvoiceListFilter={isInvoiceListFilter}
            isTicketsListFilter={isTicketsListFilter}
            isNFTRankingFilter={isNFTRankingFilter}
            isTaskListFilter={isTaskListFilter}
            SearchPlaceholder={SearchPlaceholder}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="rounded-pill  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="rounded-pill mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div
        style={
          noScroll && !isMobile
            ? { height: 'calc(100vh - 320px)', overflowY: 'hidden' }
            : { height: `${window.innerHeight / 2.5}px` }
        }
        className={divClass}
      >
        <SimpleBar style={{ height: '100%' }}>
          <Table hover {...getTableProps()} className={tableClass}>
            <thead
              style={{ position: 'sticky', top: 0 }}
              className={theadClass}
            >
              {headerGroups.map((headerGroup: any) => {
                const headerGroups = headerGroup.getHeaderGroupProps()
                const role = headerGroups?.role
                const key = headerGroups?.key

                return (
                  <tr className={trClass} role={role} key={key}>
                    {headerGroup.headers.map((column: any) => {
                      if ('visible' in column && !column?.visible) {
                        return null
                      }

                      return (
                        <th
                          key={column.id}
                          className={thClass}
                          {...column.getSortByToggleProps()}
                        >
                          {column.render('Header')}
                          {generateSortingIndicator(column)}
                        </th>
                      )
                    })}
                  </tr>
                )
              })}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row: any) => {
                prepareRow(row)

                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            className="tr-table-items"
                            key={cell.id}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          </Table>
        </SimpleBar>
      </div>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
