import { graphClient } from 'services/graph-client'
import { FarmField } from 'types/farms/farms-fields-types'

import { getFarmsFieldsDashboardGQL } from 'services/farms/farms-fields/queries'

interface Response {
  getFarmsFieldsDashboard: {
    data: FarmField[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsFieldsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getFarmsFieldsDashboardGQL, {
    take,
    skip,
  })
}
