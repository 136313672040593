import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import {
  createDefaultAgriculturalInputSubType,
  updateDefaultAgriculturalInputSubType,
} from 'services/defaults/defaults-agriculturals-inputs-subs-types/defaults-agriculturals-inputs-subs-types-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectDefaultAgriculturalInputSubType,
} from 'slices/defaults/defaults-agriculturals-inputs-subs-types-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  agriculturalInputTypeId: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t(
          'defaults-agriculturals-inputs-subs-types-agricultural-input-type-id',
        ),
    ),

  name: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('defaults-agriculturals-inputs-subs-types-name'),
    ),

  description: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('defaults-agriculturals-inputs-subs-types-description'),
    ),
})

export type FormData = z.infer<typeof schema>

const defaultAgriculturalInputSubTypeModalFormSelector = (state: StateRoot) => {
  return state.defaultsAgriculturalsInputsSubsTypesReducer
}

export const selectorResult = createSelector(
  defaultAgriculturalInputSubTypeModalFormSelector,
  (defaultAgriculturalInputSubTypeReducer) => {
    return defaultAgriculturalInputSubTypeReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, defaultAgriculturalInputSubTypeSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions(
    'defaults_agriculturals_inputs_subs_types',
  )

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectDefaultAgriculturalInputSubType(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createDefaultAgriculturalInputSubTypeFn, isPending } =
    useMutation({
      mutationFn: createDefaultAgriculturalInputSubType,
    })

  const {
    mutateAsync: updateDefaultAgriculturalInputSubTypeFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateDefaultAgriculturalInputSubType,
  })

  const cleanFields: FormData = {
    agriculturalInputTypeId: '',
    name: '',
    description: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!defaultAgriculturalInputSubTypeSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      agriculturalInputTypeId: `${defaultAgriculturalInputSubTypeSelected.agriculturalInputTypeId}`,
      name: defaultAgriculturalInputSubTypeSelected.name,
      description: defaultAgriculturalInputSubTypeSelected.description,
    }

    reset(defaultAgriculturalInputSubTypeSelected ? fields : cleanFields)
  }, [defaultAgriculturalInputSubTypeSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        agriculturalInputTypeId: +data?.agriculturalInputTypeId,
        name: data?.name,
        description: data?.description,
      }

      if (
        defaultAgriculturalInputSubTypeSelected &&
        defaultAgriculturalInputSubTypeSelected?.id
      ) {
        const { updateDefaultAgriculturalInputSubTypeDashboard } =
          await updateDefaultAgriculturalInputSubTypeFn({
            ...formatedData,
            defaultAgriculturalInputSubTypeId:
              defaultAgriculturalInputSubTypeSelected?.id,
          })

        dispatch(
          editUniqueData(updateDefaultAgriculturalInputSubTypeDashboard?.data),
        )
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData =
        await createDefaultAgriculturalInputSubTypeFn(formatedData)

      dispatch(
        pushData(
          responseData?.createDefaultAgriculturalInputSubTypeDashboard?.data,
        ),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    defaultAgriculturalInputSubTypeSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
