import { graphClient } from 'services/graph-client'
import { DefaultAgriculturalInputPest } from 'types/defaults/defaults-agriculturals-inputs-pests-types'

import {
  getDefaultsAgriculturalsInputsPestsDashboardGQL,
  getDefaultsAgriculturalsInputsPestsContainsDashboardGQL,
} from './queries'

interface Response {
  getDefaultsAgriculturalsInputsPestsDashboard: {
    data: DefaultAgriculturalInputPest[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getDefaultsAgriculturalsInputsPestsContainsDashboard: {
    data: DefaultAgriculturalInputPest[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  toxicologicalClass?: string
  environmentalClass?: string
}

export interface GetParams {
  page?: number
  agriculturalInputId?: number
  typePestId?: number
  name?: string
  description?: string
  agriculturalInputSubTypeId?: number
  agriculturalInputModeActionId?: number
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  toxicologicalClass?: string
  environmentalClass?: string
  isOrganic?: boolean
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getDefaultsAgriculturalsInputsPestsDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getDefaultsAgriculturalsInputsPestsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getDefaultsAgriculturalsInputsPestsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getDefaultsAgriculturalsInputsPestsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getDefaultsAgriculturalsInputsPestsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getDefaultsAgriculturalsInputsPestsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}
