import { graphClient } from 'services/graph-client'
import {
  activeDefaultAgriculturalInputPestDashboardGQL,
  deleteDefaultAgriculturalInputPestDashboardGQL,
  deleteForeverDefaultAgriculturalInputPestDashboardGQL,
  createDefaultAgriculturalInputPestDashboardGQL,
  updateDefaultAgriculturalInputPestDashboardGQL,
} from './queries'
import { DefaultAgriculturalInputPest } from 'types/defaults/defaults-agriculturals-inputs-pests-types'

interface ResponseCreate {
  createDefaultAgriculturalInputPestDashboard: {
    data: DefaultAgriculturalInputPest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultAgriculturalInputPestDashboard: {
    data: DefaultAgriculturalInputPest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultAgriculturalInputPestDashboard: {
    data: DefaultAgriculturalInputPest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultAgriculturalInputPestDashboard: {
    data: DefaultAgriculturalInputPest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultAgriculturalInputPestDashboard: string
}

interface DataActive {
  defaultAgriculturalInputPestId: number
  active: boolean
}

interface DataDeleted {
  defaultAgriculturalInputPestId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultAgriculturalInputPestId: number
}

interface DataCreate {
  agriculturalInputId: number
  typePestId: number
  name: string
  description: string
  agriculturalInputSubTypeId: number
  agriculturalInputModeActionId?: number
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  toxicologicalClass?: string
  environmentalClass?: string
  isOrganic: boolean
}

interface DataUpdate {
  defaultAgriculturalInputPestId: number
  agriculturalInputId: number
  typePestId: number
  name: string
  description: string
  agriculturalInputSubTypeId: number
  agriculturalInputModeActionId?: number
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  toxicologicalClass?: string
  environmentalClass?: string
  isOrganic: boolean
}

export const activeDefaultAgriculturalInputPest = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultAgriculturalInputPestDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultAgriculturalInputPest = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultAgriculturalInputPestDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultAgriculturalInputPest = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultAgriculturalInputPestDashboardGQL,
    { ...data },
  )
}

export const createDefaultAgriculturalInputPest = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultAgriculturalInputPestDashboardGQL,
    { ...data },
  )
}

export const updateDefaultAgriculturalInputPest = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultAgriculturalInputPestDashboardGQL,
    { ...data },
  )
}
