import { graphClient } from 'services/graph-client'
import {
  AgriculturalInputSubType,
  Company,
  AgriculturalInputModeAction,
  AgriculturalInput,
} from 'types/agriculturals-inputs/agriculturals-inputs-types'

import {
  getAgriculturalsInputsSubsTypesDashboardGQL,
  getCompaniesDashboardGQL,
  getAgriculturalsInputsModesActionsDashboardGQL,
  getAgriculturalsInputsDashboardGQL,
  getAgriculturalsInputsContainsDashboardGQL,
} from './queries'

interface Response {
  getAgriculturalsInputsDashboard: {
    data: AgriculturalInput[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getAgriculturalsInputsContainsDashboard: {
    data: AgriculturalInput[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  toxicologicalClass?: string
  environmentalClass?: string
}

export interface GetParams {
  page?: number
  agriculturalInputSubTypeId?: number
  agriculturalInputModeActionId?: number
  name?: string
  description?: string
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  isPesticide?: boolean
  toxicologicalClass?: string
  environmentalClass?: string
  isOrganic?: boolean
  isFertilizer?: boolean
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getAgriculturalsInputsDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getAgriculturalsInputsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getAgriculturalsInputsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getAgriculturalsInputsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getAgriculturalsInputsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getAgriculturalsInputsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseAgriculturalInputSubType {
  getAgriculturalsInputsSubsTypesDashboard: {
    data: AgriculturalInputSubType[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAgriculturalsInputsSubsTypesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseAgriculturalInputSubType>(
    getAgriculturalsInputsSubsTypesDashboardGQL,
    { take, skip },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseAgriculturalInputModeAction {
  getAgriculturalsInputsModesActionsDashboard: {
    data: AgriculturalInputModeAction[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAgriculturalsInputsModesActionsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseAgriculturalInputModeAction>(
    getAgriculturalsInputsModesActionsDashboardGQL,
    { take, skip },
  )
}
