import { Status } from './list-columns'
import { t } from 'i18next'

export const columns = [
  {
    Header: t('id'),
    accessor: 'id',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span className="fw-medium link-primary">{cellProps?.value}</span>
    },
  },
  {
    Header: t('companies-code-uid'),
    accessor: 'codeUid',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('companies-name'),
    accessor: 'name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('companies-description'),
    accessor: 'description',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('companies-document'),
    accessor: 'document',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('companies-document-two'),
    accessor: 'documentTwo',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('companies-document-three'),
    accessor: 'documentThree',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('companies-email'),
    accessor: 'email',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('companies-phone'),
    accessor: 'phone',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('companies-country'),
    accessor: 'country',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('companies-hectare-quantity'),
    accessor: 'hectareQuantity',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('companies-usefull-hectare-quantity'),
    accessor: 'usefullHectareQuantity',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('active'),
    accessor: 'actions',
    filterable: false,
    Cell: (props: any, cellProps: any) => {
      return <Status {...cellProps} {...props} showActive={true} />
    },
  },
]
