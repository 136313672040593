import toast from 'react-hot-toast'
import SimpleBar from 'simplebar-react'
import { useCallback, useEffect } from 'react'
import { Col, Button } from 'reactstrap'
import { AreaForm } from './form'
import { useSelector } from 'react-redux'
import { mapReducer } from '../google-map/map'
import { useDispatch } from 'react-redux'
import {
  Form,
  handleAddForm,
  handleClearForm,
  handleClearFormsInputs,
  handleEditArea,
  handleIsMap,
  handleSetArea,
  handleSetItemForm,
} from 'slices/map/map-reducer'

export const AreaControl = () => {
  const dispatch = useDispatch()

  const {
    isAreaControlOpen,
    isEditing,
    forms,
    formAreaSelected,

    //
  } = useSelector(mapReducer)

  useEffect(() => {
    if (!forms.length && !isEditing) {
      dispatch(handleSetItemForm({ id: Math.random(), name: '' }))
    }
  }, [forms, isEditing])

  const handleStoreLocalStorage = useCallback(
    (form: Form) => {
      const areas = localStorage.getItem('@areas')
      const lastAreas: Form[] = areas ? JSON.parse(areas) : []

      if (isEditing) {
        const newAreas = lastAreas.map((area) => {
          return area.id === form.id ? form : area
        })

        return localStorage.setItem('@areas', JSON.stringify(newAreas))
      }

      localStorage.setItem('@areas', JSON.stringify([...lastAreas, form]))
    },
    [isEditing],
  )

  const handleReset = useCallback(() => {
    dispatch(handleClearForm())
    dispatch(handleClearFormsInputs())
  }, [isEditing])

  const handleSaveArea = () => {
    const handleOperation = (form: Form) => {
      const idSelector = `form-${form.id}`
      const inputName = document.getElementById(idSelector) as HTMLInputElement
      const name = inputName.value

      const formWithName = {
        ...form,
        name,
      }

      if (!formWithName.name) return null
      handleStoreLocalStorage(formWithName)

      if (isEditing) {
        dispatch(handleEditArea(formWithName))
        return true
      }

      dispatch(handleSetArea(formWithName))
      return true
    }

    if (isEditing && formAreaSelected) {
      if (!formAreaSelected.name) {
        return toast.error('Coloque nome no campo!')
      }

      handleOperation(formAreaSelected)
    }

    const check = forms.map(handleOperation)

    if (check.some((form) => !form)) {
      return toast.error('Coloque nome nos campos!')
    }

    if (isEditing) {
      toast.success('Editado com sucesso!')
    }

    toast.success('Criado com sucesso!')
    dispatch(handleIsMap(false))
    handleReset()
  }

  const handleCancel = useCallback(() => {
    handleReset()
    dispatch(handleIsMap(false))
  }, [])

  return (
    <Col
      xs={11}
      lg={5}
      className="flex-column position-absolute rounded bg-white m-3"
      style={{
        display: isAreaControlOpen ? 'flex' : 'none',
        height: '95%',
        top: 0,
        left: 0,
      }}
    >
      <SimpleBar style={{ height: '90%' }}>
        <div className="d-flex flex-column p-4 gap-2">
          {forms.map((form) => (
            <AreaForm key={form.id} form={form} />
          ))}
        </div>

        {!isEditing && (
          <Col className="text-center mb-4">
            <Button
              className="bg-success border-0"
              onClick={() => dispatch(handleAddForm())}
            >
              ADICIONAR MAIS ÁREAS
            </Button>
          </Col>
        )}
      </SimpleBar>

      <div
        style={{ flex: 1, borderTopColor: '#eee', borderTopStyle: 'dashed' }}
        className="d-flex w-100 gap-2 justify-content-end align-items-end p-2"
      >
        <Button className="btn-danger" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button className="btn-success" onClick={handleSaveArea}>
          Salvar
        </Button>
      </div>
    </Col>
  )
}
