import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'
import { formatSelectPhoneToFields } from 'helpers/form/format-select-phone-to-fields'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import {
  createFarmOwner,
  updateFarmOwner,
} from 'services/farms/farms-owners/farms-owners-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectFarmOwner,
} from 'slices/farms/farms-owners-reducer'
import { z } from 'zod'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-owners-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z.string().min(1, t('inform-field') + ' ' + t('farms-owners-name')),

  document: z.string(),
  documentTwo: z.string(),
  documentThree: z.string(),
  email: z.string().min(1, t('inform-field') + ' ' + t('farms-owners-email')),

  phone: z
    .object({
      phone: z
        .string()
        .min(1, t('invalid-phone-field') + ' ' + t('farms-owners-phone')),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('farms-owners-phone'),
      path: ['phone'],
    }),
  priorityContact: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('farms-owners-priority-contact')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),
})

export type FormData = z.infer<typeof schema>

const farmOwnerModalFormSelector = (state: StateRoot) => {
  return state.farmsOwnersReducer
}

export const selectorResult = createSelector(
  farmOwnerModalFormSelector,
  (farmOwnerReducer) => {
    return farmOwnerReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, farmOwnerSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('farms_owners')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
    setValue,
  } = form

  const farmInfo = useGetFarmsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectFarmOwner(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createFarmOwnerFn, isPending } = useMutation({
    mutationFn: createFarmOwner,
  })

  const { mutateAsync: updateFarmOwnerFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateFarmOwner,
    })

  const cleanFields: FormData = {
    farmId: '',
    name: '',
    document: '',
    documentTwo: '',
    documentThree: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
    priorityContact: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!farmOwnerSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      farmId: formatSelectValueToFields(farmOwnerSelected.farm, ['id', 'name']),
      name: farmOwnerSelected.name,
      document: farmOwnerSelected.document,
      documentTwo: farmOwnerSelected.documentTwo,
      documentThree: farmOwnerSelected.documentThree,
      email: farmOwnerSelected.email,
      phone: formatSelectPhoneToFields(farmOwnerSelected.phone),
      priorityContact: `${farmOwnerSelected.priorityContact}`,
    }

    reset(farmOwnerSelected ? fields : cleanFields)
  }, [farmOwnerSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedPriorityContact = formatToSendData(data?.priorityContact)

      const formatedData = {
        farmId: selectedFarm,
        name: data?.name,
        document: data?.document,
        documentTwo: data?.documentTwo,
        documentThree: data?.documentThree,
        email: data?.email,
        phone: data?.phone.phone,
        priorityContact: formatInputBooleanValue(selectedPriorityContact),
      }

      if (farmOwnerSelected && farmOwnerSelected?.id) {
        const { updateFarmOwnerDashboard } = await updateFarmOwnerFn({
          ...formatedData,
          farmOwnerId: farmOwnerSelected?.id,
        })

        dispatch(editUniqueData(updateFarmOwnerDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createFarmOwnerFn(formatedData)

      dispatch(pushData(responseData?.createFarmOwnerDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    farmOwnerSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    handleSelectCountry,
    resetFields,
    farmInfo,
  }
}
