import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeExampleOtherTwo,
  deleteExampleOtherTwo,
} from 'services/examples-tables/examples-others-twos/examples-others-twos-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectExampleOtherTwo,
} from 'slices/examples-tables/examples-others-twos-reducer'
import { ExampleOtherTwo } from 'types/examples-tables/examples-others-twos-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('examples_others_twos')
  const dispatch = useDispatch()
  const item = cell?.row?.original as ExampleOtherTwo

  const handleOpenForm = () => {
    dispatch(selectExampleOtherTwo(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectExampleOtherTwo(itemWithoutId as ExampleOtherTwo))
    dispatch(openForm())
  }

  const { mutateAsync: deleteExampleOtherTwoDashboardFn } = useMutation({
    mutationFn: deleteExampleOtherTwo,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteExampleOtherTwoDashboard } =
      await deleteExampleOtherTwoDashboardFn({
        exampleOtherTwoId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteExampleOtherTwoDashboard?.data))
  }

  const { mutateAsync: activeExampleOtherTwoDashboardFn } = useMutation({
    mutationFn: activeExampleOtherTwo,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeExampleOtherTwoDashboard } =
      await activeExampleOtherTwoDashboardFn({
        exampleOtherTwoId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeExampleOtherTwoDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
