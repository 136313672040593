import { createSlice } from '@reduxjs/toolkit'
import { DefaultAgriculturalInputPest } from 'types/defaults/defaults-agriculturals-inputs-pests-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  defaultAgriculturalInputPestSelected: null | DefaultAgriculturalInputPest
  deleteModalOpen: boolean
  defaultAgriculturalInputPestIdToDelete: null | string
  data: DefaultAgriculturalInputPest[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  defaultAgriculturalInputPestSelected: null,
  defaultAgriculturalInputPestIdToDelete: null,
  data: [],
  total: 0,
}

const defaultAgriculturalInputPestSlice = createSlice({
  initialState,
  name: 'defaults-agriculturals-inputs-pests-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectDefaultAgriculturalInputPest(
      state,
      { payload }: { payload: DefaultAgriculturalInputPest | null },
    ) {
      state.defaultAgriculturalInputPestSelected = payload
    },
    setData(state, { payload }: { payload: DefaultAgriculturalInputPest[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: DefaultAgriculturalInputPest }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(
      state,
      { payload }: { payload: DefaultAgriculturalInputPest },
    ) {
      state.data = state.data.map((defaultAgriculturalInputPest) => {
        return defaultAgriculturalInputPest.id === payload.id
          ? { ...defaultAgriculturalInputPest, ...payload }
          : defaultAgriculturalInputPest
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (defaultAgriculturalInputPest) =>
          +defaultAgriculturalInputPest.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.defaultAgriculturalInputPestIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.defaultAgriculturalInputPestIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectDefaultAgriculturalInputPest,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = defaultAgriculturalInputPestSlice.actions
export default defaultAgriculturalInputPestSlice.reducer
