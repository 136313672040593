import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeFarmInventory,
  deleteFarmInventory,
} from 'services/farms/farms-inventories/farms-inventories-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectFarmInventory,
} from 'slices/farms/farms-inventories-reducer'
import { FarmInventory } from 'types/farms/farms-inventories-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('farms_inventories')
  const dispatch = useDispatch()
  const item = cell?.row?.original as FarmInventory

  const handleOpenForm = () => {
    dispatch(selectFarmInventory(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectFarmInventory(itemWithoutId as FarmInventory))
    dispatch(openForm())
  }

  const { mutateAsync: deleteFarmInventoryDashboardFn } = useMutation({
    mutationFn: deleteFarmInventory,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteFarmInventoryDashboard } =
      await deleteFarmInventoryDashboardFn({
        farmInventoryId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteFarmInventoryDashboard?.data))
  }

  const { mutateAsync: activeFarmInventoryDashboardFn } = useMutation({
    mutationFn: activeFarmInventory,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeFarmInventoryDashboard } =
      await activeFarmInventoryDashboardFn({
        farmInventoryId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeFarmInventoryDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
