import { graphClient } from 'services/graph-client'
import {
  activeTypeCoffeeRenovationDashboardGQL,
  deleteTypeCoffeeRenovationDashboardGQL,
  deleteForeverTypeCoffeeRenovationDashboardGQL,
  createTypeCoffeeRenovationDashboardGQL,
  updateTypeCoffeeRenovationDashboardGQL,
} from './queries'
import { TypeCoffeeRenovation } from 'types/types-coffees/types-coffees-renovations-types'

interface ResponseCreate {
  createTypeCoffeeRenovationDashboard: {
    data: TypeCoffeeRenovation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeCoffeeRenovationDashboard: {
    data: TypeCoffeeRenovation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeCoffeeRenovationDashboard: {
    data: TypeCoffeeRenovation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeCoffeeRenovationDashboard: {
    data: TypeCoffeeRenovation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeCoffeeRenovationDashboard: string
}

interface DataActive {
  typeCoffeeRenovationId: number
  active: boolean
}

interface DataDeleted {
  typeCoffeeRenovationId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeCoffeeRenovationId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  typeCoffeeRenovationId: number
  name: string
  description?: string
}

export const activeTypeCoffeeRenovation = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeTypeCoffeeRenovationDashboardGQL,
    { ...data },
  )
}

export const deleteTypeCoffeeRenovation = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteTypeCoffeeRenovationDashboardGQL,
    { ...data },
  )
}

export const deleteForeverTypeCoffeeRenovation = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeCoffeeRenovationDashboardGQL,
    { ...data },
  )
}

export const createTypeCoffeeRenovation = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createTypeCoffeeRenovationDashboardGQL,
    { ...data },
  )
}

export const updateTypeCoffeeRenovation = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateTypeCoffeeRenovationDashboardGQL,
    { ...data },
  )
}
