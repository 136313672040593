import { graphClient } from 'services/graph-client'
import { CoffeePruning } from 'types/farms/coffees-prunings-types'

import { getCoffeesPruningsDashboardGQL } from 'services/farms/coffees-prunings/queries'

interface Response {
  getCoffeesPruningsDashboard: {
    data: CoffeePruning[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCoffeesPruningsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getCoffeesPruningsDashboardGQL, {
    take,
    skip,
  })
}
