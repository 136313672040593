import { combineReducers } from 'redux'
import LayoutReducer from './layouts/reducer'
import mapSliceReducer from './map/map-reducer'
//GENERATE
import examplesTablesReducer from './examples-tables/examples-tables-reducer'
import examplesOthersOnesReducer from './examples-tables/examples-others-ones-reducer'
import examplesOthersTwosReducer from './examples-tables/examples-others-twos-reducer'
import employeesReducer from './employees/employees-reducer'
import usersDevicesInformationsReducer from './users/users-devices-informations-reducer'
import appsLogsReducer from './settings/apps-logs-reducer'
import usersReducer from './users/users-reducer'
import agriculturalsInputsReducer from './agriculturals-inputs/agriculturals-inputs-reducer'
import agriculturalsInputsCultivationsReducer from './agriculturals-inputs/agriculturals-inputs-cultivations-reducer'
import agriculturalsInputsModesActionsReducer from './agriculturals-inputs/agriculturals-inputs-modes-actions-reducer'
import agriculturalsInputsPestsReducer from './agriculturals-inputs/agriculturals-inputs-pests-reducer'
import agriculturalsInputsSubsTypesReducer from './agriculturals-inputs/agriculturals-inputs-subs-types-reducer'
import agriculturalsInputsTypesReducer from './agriculturals-inputs/agriculturals-inputs-types-reducer'
import coffeesPruningsReducer from './farms/coffees-prunings-reducer'
import defaultsAgriculturalsInputsReducer from './defaults/defaults-agriculturals-inputs-reducer'
import defaultsAgriculturalsInputsCultivationsReducer from './defaults/defaults-agriculturals-inputs-cultivations-reducer'
import defaultsAgriculturalsInputsModesActionsReducer from './defaults/defaults-agriculturals-inputs-modes-actions-reducer'
import defaultsAgriculturalsInputsPestsReducer from './defaults/defaults-agriculturals-inputs-pests-reducer'
import defaultsAgriculturalsInputsSubsTypesReducer from './defaults/defaults-agriculturals-inputs-subs-types-reducer'
import defaultsAgriculturalsInputsTypesReducer from './defaults/defaults-agriculturals-inputs-types-reducer'
import defaultsCoffeesPruningsReducer from './defaults/defaults-coffees-prunings-reducer'
import defaultsFarmsFieldsPeriodsReducer from './defaults/defaults-farms-fields-periods-reducer'
import defaultsTypesCoffeesColorsReducer from './defaults/defaults-types-coffees-colors-reducer'
import defaultsTypesCoffeesHarvestsReducer from './defaults/defaults-types-coffees-harvests-reducer'
import defaultsTypesCoffeesIrrigationsReducer from './defaults/defaults-types-coffees-irrigations-reducer'
import defaultsTypesCoffeesManagementsReducer from './defaults/defaults-types-coffees-managements-reducer'
import defaultsTypesCoffeesMaturationsReducer from './defaults/defaults-types-coffees-maturations-reducer'
import defaultsTypesCoffeesPruningsReducer from './defaults/defaults-types-coffees-prunings-reducer'
import defaultsTypesCoffeesRenovationsReducer from './defaults/defaults-types-coffees-renovations-reducer'
import defaultsTypesCoffeesSizesReducer from './defaults/defaults-types-coffees-sizes-reducer'
import defaultsTypesCoffeesTreatmentsReducer from './defaults/defaults-types-coffees-treatments-reducer'
import defaultsTypesCoffeesVarietiesReducer from './defaults/defaults-types-coffees-varieties-reducer'
import defaultsTypesCultivationsReducer from './defaults/defaults-types-cultivations-reducer'
import defaultsTypesExpensesReducer from './defaults/defaults-types-expenses-reducer'
import defaultsTypesMeasurementsReducer from './defaults/defaults-types-measurements-reducer'
import defaultsTypesPestsReducer from './defaults/defaults-types-pests-reducer'
import defaultsTypesToolsReducer from './defaults/defaults-types-tools-reducer'
import employeesFunctionsReducer from './employees/employees-functions-reducer'
import farmsReducer from './farms/farms-reducer'
import areasReducer from './farms/areas-reducer'
import farmsAreasReducer from './farms/farms-areas-reducer'
import farmsCultivationsReducer from './farms/farms-cultivations-reducer'
import farmsFieldsReducer from './farms/farms-fields-reducer'
import farmsFieldsPeriodsReducer from './farms/farms-fields-periods-reducer'
import companiesReducer from './settings/companies-reducer'
import companiesOwnersReducer from './settings/companies-owners-reducer'
import farmsInventoriesReducer from './farms/farms-inventories-reducer'
import farmsOwnersReducer from './farms/farms-owners-reducer'
import farmsUsersReducer from './farms/farms-users-reducer'
import harvestsReducer from './harvests/harvests-reducer'
import harvestsCostsAgriculturalsInputsReducer from './harvests/harvests-costs-agriculturals-inputs-reducer'
import harvestsCostsServicesReducer from './harvests/harvests-costs-services-reducer'
import harvestsForecastsReducer from './harvests/harvests-forecasts-reducer'
import harvestsRealsReducer from './harvests/harvests-reals-reducer'
import ordersServicesReducer from './orders/orders-services-reducer'
import ordersServicesAgriculturalsInputsReducer from './orders/orders-services-agriculturals-inputs-reducer'
import ordersServicesEmployeesReducer from './orders/orders-services-employees-reducer'
import typesCoffeesColorsReducer from './types-coffees/types-coffees-colors-reducer'
import typesCoffeesHarvestsReducer from './types-coffees/types-coffees-harvests-reducer'
import typesCoffeesIrrigationsReducer from './types-coffees/types-coffees-irrigations-reducer'
import typesCoffeesManagementsReducer from './types-coffees/types-coffees-managements-reducer'
import typesCoffeesMaturationsReducer from './types-coffees/types-coffees-maturations-reducer'
import typesCoffeesPruningsReducer from './types-coffees/types-coffees-prunings-reducer'
import typesCoffeesRenovationsReducer from './types-coffees/types-coffees-renovations-reducer'
import typesCoffeesSizesReducer from './types-coffees/types-coffees-sizes-reducer'
import typesCoffeesTreatmentsReducer from './types-coffees/types-coffees-treatments-reducer'
import typesCoffeesVarietiesReducer from './types-coffees/types-coffees-varieties-reducer'
import typesCultivationsReducer from './types-coffees/types-cultivations-reducer'
import typesExpensesReducer from './types-coffees/types-expenses-reducer'
import typesMeasurementsReducer from './types-coffees/types-measurements-reducer'
import typesPestsReducer from './types-coffees/types-pests-reducer'
import typesToolsReducer from './types-coffees/types-tools-reducer'
import rolesReducer from './users/roles-reducer'
import usersRolesReducer from './users/users-roles-reducer'
import usersTypesReducer from './users/users-types-reducer'
import harvestsProductionsReducer from './harvests/harvests-productions-reducer'
import employeesDaysWorkedsReducer from './employees/employees-days-workeds-reducer'
//END-GENERATE

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  mapSliceReducer,
  //GENERATE
  examplesTablesReducer,
  examplesOthersOnesReducer,
  examplesOthersTwosReducer,
  employeesReducer,
  usersDevicesInformationsReducer,
  appsLogsReducer,
  usersReducer,
  agriculturalsInputsReducer,
  agriculturalsInputsCultivationsReducer,
  agriculturalsInputsModesActionsReducer,
  agriculturalsInputsPestsReducer,
  agriculturalsInputsSubsTypesReducer,
  agriculturalsInputsTypesReducer,
  coffeesPruningsReducer,
  defaultsAgriculturalsInputsReducer,
  defaultsAgriculturalsInputsCultivationsReducer,
  defaultsAgriculturalsInputsModesActionsReducer,
  defaultsAgriculturalsInputsPestsReducer,
  defaultsAgriculturalsInputsSubsTypesReducer,
  defaultsAgriculturalsInputsTypesReducer,
  defaultsCoffeesPruningsReducer,
  defaultsFarmsFieldsPeriodsReducer,
  defaultsTypesCoffeesColorsReducer,
  defaultsTypesCoffeesHarvestsReducer,
  defaultsTypesCoffeesIrrigationsReducer,
  defaultsTypesCoffeesManagementsReducer,
  defaultsTypesCoffeesMaturationsReducer,
  defaultsTypesCoffeesPruningsReducer,
  defaultsTypesCoffeesRenovationsReducer,
  defaultsTypesCoffeesSizesReducer,
  defaultsTypesCoffeesTreatmentsReducer,
  defaultsTypesCoffeesVarietiesReducer,
  defaultsTypesCultivationsReducer,
  defaultsTypesExpensesReducer,
  defaultsTypesMeasurementsReducer,
  defaultsTypesPestsReducer,
  defaultsTypesToolsReducer,
  employeesFunctionsReducer,
  farmsReducer,
  areasReducer,
  farmsAreasReducer,
  farmsCultivationsReducer,
  farmsFieldsReducer,
  farmsFieldsPeriodsReducer,
  companiesReducer,
  companiesOwnersReducer,
  farmsInventoriesReducer,
  farmsOwnersReducer,
  farmsUsersReducer,
  harvestsReducer,
  harvestsCostsAgriculturalsInputsReducer,
  harvestsCostsServicesReducer,
  harvestsForecastsReducer,
  harvestsRealsReducer,
  ordersServicesReducer,
  ordersServicesAgriculturalsInputsReducer,
  ordersServicesEmployeesReducer,
  typesCoffeesColorsReducer,
  typesCoffeesHarvestsReducer,
  typesCoffeesIrrigationsReducer,
  typesCoffeesManagementsReducer,
  typesCoffeesMaturationsReducer,
  typesCoffeesPruningsReducer,
  typesCoffeesRenovationsReducer,
  typesCoffeesSizesReducer,
  typesCoffeesTreatmentsReducer,
  typesCoffeesVarietiesReducer,
  typesCultivationsReducer,
  typesExpensesReducer,
  typesMeasurementsReducer,
  typesPestsReducer,
  typesToolsReducer,
  rolesReducer,
  usersRolesReducer,
  usersTypesReducer,
  harvestsProductionsReducer,
  employeesDaysWorkedsReducer,
  //END-GENERATE
})

export default rootReducer

export type StateRoot = ReturnType<typeof rootReducer>
