import { graphClient } from 'services/graph-client'
import {
  activeCoffeePruningDashboardGQL,
  deleteCoffeePruningDashboardGQL,
  deleteForeverCoffeePruningDashboardGQL,
  createCoffeePruningDashboardGQL,
  updateCoffeePruningDashboardGQL,
} from './queries'
import { CoffeePruning } from 'types/farms/coffees-prunings-types'

interface ResponseCreate {
  createCoffeePruningDashboard: {
    data: CoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateCoffeePruningDashboard: {
    data: CoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeCoffeePruningDashboard: {
    data: CoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteCoffeePruningDashboard: {
    data: CoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverCoffeePruningDashboard: string
}

interface DataActive {
  coffeePruningId: number
  active: boolean
}

interface DataDeleted {
  coffeePruningId: number
  deleted: boolean
}

interface DataDeleteForever {
  coffeePruningId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  coffeePruningId: number
  name: string
  description?: string
}

export const activeCoffeePruning = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const deleteCoffeePruning = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const deleteForeverCoffeePruning = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const createCoffeePruning = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const updateCoffeePruning = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateCoffeePruningDashboardGQL,
    { ...data },
  )
}
