import { graphClient } from 'services/graph-client'
import {
  activeUserDeviceInformationDashboardGQL,
  deleteUserDeviceInformationDashboardGQL,
  deleteForeverUserDeviceInformationDashboardGQL,
  updateUserDeviceInformationDashboardGQL,
} from './queries'
import { UserDeviceInformation } from 'types/users/users-devices-informations-types'

interface ResponseUpdate {
  updateUserDeviceInformationDashboard: {
    data: UserDeviceInformation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeUserDeviceInformationDashboard: {
    data: UserDeviceInformation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteUserDeviceInformationDashboard: {
    data: UserDeviceInformation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverUserDeviceInformationDashboard: string
}

interface DataActive {
  userDeviceInformationId: number
  active: boolean
}

interface DataDeleted {
  userDeviceInformationId: number
  deleted: boolean
}

interface DataDeleteForever {
  userDeviceInformationId: number
}

interface DataUpdate {
  userDeviceInformationId: number
  name: string
  installationDevice: boolean
  manufacturer: string
  mac: string
  baseOs?: string
  bootLoader?: string
  brand: string
  buildNumber: string
  isCameraPresent?: string
  networkOperator: string
  deviceId: string
  deviceName: string
  ipAddress: string
  systemVersion: string
  uniqueId: string
  userAgent: string
}

export const activeUserDeviceInformation = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeUserDeviceInformationDashboardGQL,
    { ...data },
  )
}

export const deleteUserDeviceInformation = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteUserDeviceInformationDashboardGQL,
    { ...data },
  )
}

export const deleteForeverUserDeviceInformation = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverUserDeviceInformationDashboardGQL,
    { ...data },
  )
}

export const updateUserDeviceInformation = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateUserDeviceInformationDashboardGQL,
    { ...data },
  )
}
