import { graphClient } from 'services/graph-client'
import {
  activeAgriculturalInputTypeDashboardGQL,
  deleteAgriculturalInputTypeDashboardGQL,
  deleteForeverAgriculturalInputTypeDashboardGQL,
  createAgriculturalInputTypeDashboardGQL,
  updateAgriculturalInputTypeDashboardGQL,
} from './queries'
import { AgriculturalInputType } from 'types/agriculturals-inputs/agriculturals-inputs-types-types'

interface ResponseCreate {
  createAgriculturalInputTypeDashboard: {
    data: AgriculturalInputType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateAgriculturalInputTypeDashboard: {
    data: AgriculturalInputType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeAgriculturalInputTypeDashboard: {
    data: AgriculturalInputType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteAgriculturalInputTypeDashboard: {
    data: AgriculturalInputType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverAgriculturalInputTypeDashboard: string
}

interface DataActive {
  agriculturalInputTypeId: number
  active: boolean
}

interface DataDeleted {
  agriculturalInputTypeId: number
  deleted: boolean
}

interface DataDeleteForever {
  agriculturalInputTypeId: number
}

interface DataCreate {
  name: string
  description: string
}

interface DataUpdate {
  agriculturalInputTypeId: number
  name: string
  description: string
}

export const activeAgriculturalInputType = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeAgriculturalInputTypeDashboardGQL,
    { ...data },
  )
}

export const deleteAgriculturalInputType = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteAgriculturalInputTypeDashboardGQL,
    { ...data },
  )
}

export const deleteForeverAgriculturalInputType = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverAgriculturalInputTypeDashboardGQL,
    { ...data },
  )
}

export const createAgriculturalInputType = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createAgriculturalInputTypeDashboardGQL,
    { ...data },
  )
}

export const updateAgriculturalInputType = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateAgriculturalInputTypeDashboardGQL,
    { ...data },
  )
}
