import { StandaloneSearchBox } from '@react-google-maps/api'

interface Props {
  handleLoadSearch: (input: google.maps.places.SearchBox) => void
  handlePlacesChanged: () => void
}

export const SearchPlace = ({
  handleLoadSearch,
  handlePlacesChanged,
}: Props) => {
  return (
    <StandaloneSearchBox
      onLoad={handleLoadSearch}
      onPlacesChanged={handlePlacesChanged}
    >
      <input
        placeholder="Procure uma localização"
        type="text"
        className="w-25 position-absolute border-0 rounded-1 py-0 px-2 "
        style={{
          height: 40,
          right: 20,
          top: 20,
        }}
      />
    </StandaloneSearchBox>
  )
}
