import { gql } from 'graphql-request'

export const createTypeToolDashboardGQL = gql`
  mutation CREATE_TYPE_TOOL_DASHBOARD($name: String!, $description: String) {
    createTypeToolDashboard(name: $name, description: $description) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateTypeToolDashboardGQL = gql`
  mutation UPDATE_TYPE_TOOL_DASHBOARD(
    $typeToolId: Int!
    $name: String
    $description: String
  ) {
    updateTypeToolDashboard(
      typeToolId: $typeToolId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteTypeToolDashboardGQL = gql`
  mutation DELETE_TYPE_TOOL_DASHBOARD($typeToolId: Int!, $deleted: Boolean!) {
    deleteTypeToolDashboard(typeToolId: $typeToolId, deleted: $deleted) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeTypeToolDashboardGQL = gql`
  mutation ACTIVE_TYPE_TOOL_DASHBOARD($typeToolId: Int!, $active: Boolean!) {
    activeTypeToolDashboard(typeToolId: $typeToolId, active: $active) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverTypeToolDashboardGQL = gql`
  mutation DELETE_FOREVER_TYPE_TOOL_DASHBOARD($typeToolId: Int!) {
    deleteForeverTypeToolDashboard(typeToolId: $typeToolId)
  }
`

export const getTypesToolsDashboardGQL = gql`
  query GET_TYPES_TOOLS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getTypesToolsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesToolsContainsDashboardGQL = gql`
  query GET_TYPES_TOOLS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getTypesToolsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
