import { graphClient } from 'services/graph-client'
import {
  Company,
  TypeCoffeeIrrigation,
} from 'types/types-coffees/types-coffees-irrigations-types'

import {
  getCompaniesDashboardGQL,
  getTypesCoffeesIrrigationsDashboardGQL,
  getTypesCoffeesIrrigationsContainsDashboardGQL,
} from './queries'

interface Response {
  getTypesCoffeesIrrigationsDashboard: {
    data: TypeCoffeeIrrigation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getTypesCoffeesIrrigationsContainsDashboard: {
    data: TypeCoffeeIrrigation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  name?: string
  description?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getTypesCoffeesIrrigationsDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getTypesCoffeesIrrigationsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getTypesCoffeesIrrigationsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getTypesCoffeesIrrigationsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getTypesCoffeesIrrigationsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getTypesCoffeesIrrigationsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}
