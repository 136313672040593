import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeTypePest,
  deleteTypePest,
} from 'services/types-coffees/types-pests/types-pests-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectTypePest,
} from 'slices/types-coffees/types-pests-reducer'
import { TypePest } from 'types/types-coffees/types-pests-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('types_pests')
  const dispatch = useDispatch()
  const item = cell?.row?.original as TypePest

  const handleOpenForm = () => {
    dispatch(selectTypePest(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectTypePest(itemWithoutId as TypePest))
    dispatch(openForm())
  }

  const { mutateAsync: deleteTypePestDashboardFn } = useMutation({
    mutationFn: deleteTypePest,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteTypePestDashboard } = await deleteTypePestDashboardFn({
      typePestId: +item?.id,
      deleted: !item?.deleted,
    })

    dispatch(editUniqueData(deleteTypePestDashboard?.data))
  }

  const { mutateAsync: activeTypePestDashboardFn } = useMutation({
    mutationFn: activeTypePest,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeTypePestDashboard } = await activeTypePestDashboardFn({
      typePestId: +item?.id,
      active: !item?.active,
    })

    dispatch(editUniqueData(activeTypePestDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
