import { Status } from './list-columns'
import { formatDate } from 'helpers/format/format-date'
import { t } from 'i18next'

export const columns = [
  {
    Header: t('id'),
    accessor: 'id',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span className="fw-medium link-primary">{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-company-id'),
    accessor: 'company.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-user-type-id'),
    accessor: 'userType.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-farm-id'),
    accessor: 'farm.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-first-name'),
    accessor: 'firstName',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-last-name'),
    accessor: 'lastName',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-name'),
    accessor: 'name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-email'),
    accessor: 'email',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-phone'),
    accessor: 'phone',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-password'),
    accessor: 'password',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-photo'),
    accessor: 'photo',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-email-verified'),
    accessor: 'emailVerified',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('users-phone-verified'),
    accessor: 'phoneVerified',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('users-two-factor-enabled'),
    accessor: 'twoFactorEnabled',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('users-sms-code'),
    accessor: 'smsCode',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-email-code'),
    accessor: 'emailCode',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-google-id'),
    accessor: 'googleId',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-apple-id'),
    accessor: 'appleId',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-apple-token'),
    accessor: 'appleToken',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-country'),
    accessor: 'country',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-state'),
    accessor: 'state',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-utc'),
    accessor: 'utc',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-language'),
    accessor: 'language',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-gender'),
    accessor: 'gender',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-birthday'),
    accessor: 'birthday',
    filterable: false,
    Cell: (cellProps: any) => {
      const date = cellProps?.value

      return <span>{formatDate(date)}</span>
    },
  },
  {
    Header: t('active'),
    accessor: 'actions',
    filterable: false,
    Cell: (props: any, cellProps: any) => {
      return <Status {...cellProps} {...props} showActive={true} />
    },
  },
]
