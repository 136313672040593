import { graphClient } from 'services/graph-client'
import { DefaultAgriculturalInputModeAction } from 'types/defaults/defaults-agriculturals-inputs-modes-actions-types'

import {
  getDefaultsAgriculturalsInputsModesActionsDashboardGQL,
  getDefaultsAgriculturalsInputsModesActionsContainsDashboardGQL,
} from './queries'

interface Response {
  getDefaultsAgriculturalsInputsModesActionsDashboard: {
    data: DefaultAgriculturalInputModeAction[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getDefaultsAgriculturalsInputsModesActionsContainsDashboard: {
    data: DefaultAgriculturalInputModeAction[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  name?: string
  description?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getDefaultsAgriculturalsInputsModesActionsDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getDefaultsAgriculturalsInputsModesActionsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getDefaultsAgriculturalsInputsModesActionsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getDefaultsAgriculturalsInputsModesActionsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getDefaultsAgriculturalsInputsModesActionsContainsDashboard =
  async (params: GetContainsParams) => {
    const take = 10
    const skip = (params?.page ?? 0) * take

    return await graphClient.request<ContainsResponse>(
      getDefaultsAgriculturalsInputsModesActionsContainsDashboardGQL,
      {
        ...params,
        take,
        skip,
      },
    )
  }
