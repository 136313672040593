import { graphClient } from 'services/graph-client'
import { AgriculturalInputType } from 'types/agriculturals-inputs/agriculturals-inputs-types-types'

import { getAgriculturalsInputsTypesDashboardGQL } from 'services/agriculturals-inputs/agriculturals-inputs-types/queries'

interface Response {
  getAgriculturalsInputsTypesDashboard: {
    data: AgriculturalInputType[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAgriculturalsInputsTypesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getAgriculturalsInputsTypesDashboardGQL,
    {
      take,
      skip,
    },
  )
}
