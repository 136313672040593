import { graphClient } from 'services/graph-client'
import { OrderService } from 'types/orders/orders-services-types'

import { getOrdersServicesDashboardGQL } from 'services/orders/orders-services/queries'

interface Response {
  getOrdersServicesDashboard: {
    data: OrderService[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getOrdersServicesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getOrdersServicesDashboardGQL, {
    take,
    skip,
  })
}
