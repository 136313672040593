import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeUserRole,
  deleteUserRole,
} from 'services/users/users-roles/users-roles-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectUserRole,
} from 'slices/users/users-roles-reducer'
import { UserRole } from 'types/users/users-roles-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('users_roles')
  const dispatch = useDispatch()
  const item = cell?.row?.original as UserRole

  const handleOpenForm = () => {
    dispatch(selectUserRole(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectUserRole(itemWithoutId as UserRole))
    dispatch(openForm())
  }

  const { mutateAsync: deleteUserRoleDashboardFn } = useMutation({
    mutationFn: deleteUserRole,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteUserRoleDashboard } = await deleteUserRoleDashboardFn({
      userRoleId: +item?.id,
      deleted: !item?.deleted,
    })

    dispatch(editUniqueData(deleteUserRoleDashboard?.data))
  }

  const { mutateAsync: activeUserRoleDashboardFn } = useMutation({
    mutationFn: activeUserRole,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeUserRoleDashboard } = await activeUserRoleDashboardFn({
      userRoleId: +item?.id,
      active: !item?.active,
    })

    dispatch(editUniqueData(activeUserRoleDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
