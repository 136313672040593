import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatInputDateValue } from 'helpers/form/format-input-date-value'
import { formatDateToFields } from 'helpers/form/format-date-to-fields'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import {
  createHarvest,
  updateHarvest,
} from 'services/harvests/harvests/harvests-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectHarvest,
} from 'slices/harvests/harvests-reducer'
import { z } from 'zod'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetTypesCultivationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-cultivations-dashboard'
import { useGetTypesMeasurementsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-measurements-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('harvests-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('harvests-farm-field-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCultivationId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('harvests-type-cultivation-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeMeasurementId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('harvests-type-measurement-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z.string().min(1, t('inform-field') + ' ' + t('harvests-name')),

  description: z.string(),
  automaticCost: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('harvests-automatic-cost')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  dateHarvestInitial: z.union([z.date(), z.string().min(1)]),

  dateHarvestFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormData = z.infer<typeof schema>

const harvestModalFormSelector = (state: StateRoot) => {
  return state.harvestsReducer
}

export const selectorResult = createSelector(
  harvestModalFormSelector,
  (harvestReducer) => {
    return harvestReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, harvestSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('harvests')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const farmFieldInfo = useGetFarmsFieldsDashboard()
  const farmInfo = useGetFarmsDashboard()
  const typeCultivationInfo = useGetTypesCultivationsDashboard()
  const typeMeasurementInfo = useGetTypesMeasurementsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectHarvest(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createHarvestFn, isPending } = useMutation({
    mutationFn: createHarvest,
  })

  const { mutateAsync: updateHarvestFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateHarvest,
    })

  const cleanFields: FormData = {
    farmId: '',
    farmFieldId: '',
    typeCultivationId: '',
    typeMeasurementId: '',
    name: '',
    description: '',
    automaticCost: '',
    dateHarvestInitial: '',
    dateHarvestFinal: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!harvestSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      farmFieldId: formatSelectValueToFields(harvestSelected.farmField, [
        'id',
        'name',
      ]),
      farmId: formatSelectValueToFields(harvestSelected.farm, ['id', 'name']),
      typeCultivationId: formatSelectValueToFields(
        harvestSelected.typeCultivation,
        ['id', 'name'],
      ),
      typeMeasurementId: formatSelectValueToFields(
        harvestSelected.typeMeasurement,
        ['id', 'name'],
      ),
      name: harvestSelected.name,
      description: harvestSelected.description,
      automaticCost: `${harvestSelected.automaticCost}`,
      dateHarvestInitial: formatDateToFields(
        harvestSelected.dateHarvestInitial,
      ),
      dateHarvestFinal: formatDateToFields(harvestSelected.dateHarvestFinal),
    }

    reset(harvestSelected ? fields : cleanFields)
  }, [harvestSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedFarmField = +formatToSendData(data?.farmFieldId)
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedTypeCultivation = +formatToSendData(data?.typeCultivationId)
      const selectedTypeMeasurement = +formatToSendData(data?.typeMeasurementId)
      const selectedAutomaticCost = formatToSendData(data?.automaticCost)

      const formatedData = {
        farmFieldId: selectedFarmField,
        farmId: selectedFarm,
        typeCultivationId: selectedTypeCultivation,
        typeMeasurementId: selectedTypeMeasurement,
        name: data?.name,
        description: data?.description,
        automaticCost: formatInputBooleanValue(selectedAutomaticCost),
        dateHarvestInitial: formatInputDateValue(data?.dateHarvestInitial),
        dateHarvestFinal: formatInputDateValue(data?.dateHarvestFinal),
      }

      if (harvestSelected && harvestSelected?.id) {
        const { updateHarvestDashboard } = await updateHarvestFn({
          ...formatedData,
          harvestId: harvestSelected?.id,
        })

        dispatch(editUniqueData(updateHarvestDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createHarvestFn(formatedData)

      dispatch(pushData(responseData?.createHarvestDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    harvestSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    farmFieldInfo,
    farmInfo,
    typeCultivationInfo,
    typeMeasurementInfo,
  }
}
