import { Status } from './list-columns'
import { t } from 'i18next'

export const columns = [
  {
    Header: t('id'),
    accessor: 'id',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span className="fw-medium link-primary">{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-costs-agriculturals-inputs-company-id'),
    accessor: 'company.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-costs-agriculturals-inputs-farm-field-id'),
    accessor: 'farmField.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-costs-agriculturals-inputs-farm-id'),
    accessor: 'farm.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-costs-agriculturals-inputs-harvest-id'),
    accessor: 'harvest.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-costs-agriculturals-inputs-name'),
    accessor: 'name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-costs-agriculturals-inputs-description'),
    accessor: 'description',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-costs-agriculturals-inputs-expected-number-service'),
    accessor: 'expectedNumberService',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-costs-agriculturals-inputs-number-service'),
    accessor: 'numberService',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t(
      'harvests-costs-agriculturals-inputs-service-value-apportionment',
    ),
    accessor: 'serviceValueApportionment',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('harvests-costs-agriculturals-inputs-value-agricultural-input'),
    accessor: 'valueAgriculturalInput',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('active'),
    accessor: 'actions',
    filterable: false,
    Cell: (props: any, cellProps: any) => {
      return <Status {...cellProps} {...props} showActive={true} />
    },
  },
]
