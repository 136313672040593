import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import withRouter from '../components/common/with-router'
import Header from './header'
import Sidebar from './sidebar'
import RightSidebar from '../components/common/right-sidebar'

import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarImageType,
  changeSidebarVisibility,
} from '../slices/thunks'

import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

const Layout = (props: any) => {
  const [headerClass, setHeaderClass] = useState('')
  const dispatch: any = useDispatch()

  const selectLayoutState = (state: any) => state.Layout
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutType: layout.layoutType,
      leftSidebarType: layout.leftSidebarType,
      layoutModeType: layout.layoutModeType,
      layoutWidthType: layout.layoutWidthType,
      layoutPositionType: layout.layoutPositionType,
      topbarThemeType: layout.topbarThemeType,
      leftsidbarSizeType: layout.leftsidbarSizeType,
      leftSidebarViewType: layout.leftSidebarViewType,
      leftSidebarImageType: layout.leftSidebarImageType,
      preloader: layout.preloader,
      sidebarVisibilitytype: layout.sidebarVisibilitytype,
    }),
  )
  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilitytype,
  } = useSelector(selectLayoutProperties)

  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType ||
      sidebarVisibilitytype
    ) {
      window.dispatchEvent(new Event('resize'))
      dispatch(changeLeftsidebarViewType(leftSidebarViewType))
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType))
      dispatch(changeSidebarTheme(leftSidebarType))
      dispatch(changeLayoutMode(layoutModeType))
      dispatch(changeLayoutWidth(layoutWidthType))
      dispatch(changeLayoutPosition(layoutPositionType))
      dispatch(changeTopbarTheme(topbarThemeType))
      dispatch(changeLayout(layoutType))
      dispatch(changeSidebarImageType(leftSidebarImageType))
      dispatch(changeSidebarVisibility(sidebarVisibilitytype))
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilitytype,
    dispatch,
  ])

  const onChangeLayoutMode = (value: any) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value))
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true)
  })

  function scrollNavigation() {
    const scrollup = document.documentElement.scrollTop
    if (scrollup > 50) {
      setHeaderClass('topbar-shadow')
    } else {
      setHeaderClass('')
    }
  }

  useEffect(() => {
    const humberIcon = document.querySelector('.hamburger-icon') as HTMLElement
    if (
      sidebarVisibilitytype === 'show' ||
      layoutType === 'vertical' ||
      layoutType === 'twocolumn'
    ) {
      humberIcon.classList.remove('open')
    } else {
      humberIcon && humberIcon.classList.add('open')
    }
  }, [sidebarVisibilitytype, layoutType])

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType}
          onChangeLayoutMode={onChangeLayoutMode}
        />
        <Sidebar layoutType={layoutType} />
        <div className="main-content">{props.children}</div>
      </div>
      <RightSidebar />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.object,
}

export default withRouter(Layout)
