import { graphClient } from 'services/graph-client'
import {
  activeTypeCoffeeManagementDashboardGQL,
  deleteTypeCoffeeManagementDashboardGQL,
  deleteForeverTypeCoffeeManagementDashboardGQL,
  createTypeCoffeeManagementDashboardGQL,
  updateTypeCoffeeManagementDashboardGQL,
} from './queries'
import { TypeCoffeeManagement } from 'types/types-coffees/types-coffees-managements-types'

interface ResponseCreate {
  createTypeCoffeeManagementDashboard: {
    data: TypeCoffeeManagement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeCoffeeManagementDashboard: {
    data: TypeCoffeeManagement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeCoffeeManagementDashboard: {
    data: TypeCoffeeManagement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeCoffeeManagementDashboard: {
    data: TypeCoffeeManagement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeCoffeeManagementDashboard: string
}

interface DataActive {
  typeCoffeeManagementId: number
  active: boolean
}

interface DataDeleted {
  typeCoffeeManagementId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeCoffeeManagementId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  typeCoffeeManagementId: number
  name: string
  description?: string
}

export const activeTypeCoffeeManagement = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeTypeCoffeeManagementDashboardGQL,
    { ...data },
  )
}

export const deleteTypeCoffeeManagement = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteTypeCoffeeManagementDashboardGQL,
    { ...data },
  )
}

export const deleteForeverTypeCoffeeManagement = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeCoffeeManagementDashboardGQL,
    { ...data },
  )
}

export const createTypeCoffeeManagement = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createTypeCoffeeManagementDashboardGQL,
    { ...data },
  )
}

export const updateTypeCoffeeManagement = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateTypeCoffeeManagementDashboardGQL,
    { ...data },
  )
}
