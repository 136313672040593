import { createSlice } from '@reduxjs/toolkit'
import { DefaultTypeTool } from 'types/defaults/defaults-types-tools-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  defaultTypeToolSelected: null | DefaultTypeTool
  deleteModalOpen: boolean
  defaultTypeToolIdToDelete: null | string
  data: DefaultTypeTool[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  defaultTypeToolSelected: null,
  defaultTypeToolIdToDelete: null,
  data: [],
  total: 0,
}

const defaultTypeToolSlice = createSlice({
  initialState,
  name: 'defaults-types-tools-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectDefaultTypeTool(
      state,
      { payload }: { payload: DefaultTypeTool | null },
    ) {
      state.defaultTypeToolSelected = payload
    },
    setData(state, { payload }: { payload: DefaultTypeTool[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: DefaultTypeTool }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: DefaultTypeTool }) {
      state.data = state.data.map((defaultTypeTool) => {
        return defaultTypeTool.id === payload.id
          ? { ...defaultTypeTool, ...payload }
          : defaultTypeTool
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (defaultTypeTool) => +defaultTypeTool.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.defaultTypeToolIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.defaultTypeToolIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectDefaultTypeTool,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = defaultTypeToolSlice.actions
export default defaultTypeToolSlice.reducer
