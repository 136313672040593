import { graphClient } from 'services/graph-client'
import {
  activeUserDashboardGQL,
  deleteUserDashboardGQL,
  deleteForeverUserDashboardGQL,
  updateUserDashboardGQL,
} from './queries'
import { User } from 'types/users/users-types'

interface ResponseUpdate {
  updateUserDashboard: {
    data: User
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeUserDashboard: {
    data: User
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteUserDashboard: {
    data: User
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverUserDashboard: string
}

interface DataActive {
  userId: number
  active: boolean
}

interface DataDeleted {
  userId: number
  deleted: boolean
}

interface DataDeleteForever {
  userId: number
}

interface DataUpdate {
  userId: number
  userTypeId: number
  farmId?: number
  firstName: string
  lastName?: string
  name?: string
  email?: string
  phone?: string
  password: string
  photo: string
  emailVerified: boolean
  phoneVerified: boolean
  twoFactorEnabled: boolean
  smsCode?: string
  emailCode: string
  googleId?: string
  appleId?: string
  appleToken?: string
  country: string
  state: string
  utc?: string
  language: string
  gender: string
  birthday: string
}

export const activeUser = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeUserDashboardGQL, {
    ...data,
  })
}

export const deleteUser = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteUserDashboardGQL, {
    ...data,
  })
}

export const deleteForeverUser = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverUserDashboardGQL,
    { ...data },
  )
}

export const updateUser = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateUserDashboardGQL, {
    ...data,
  })
}
