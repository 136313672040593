import { Label } from 'reactstrap'
import { forwardRef } from 'react'
import { InputComponent } from './input'
import { NumericFormat } from 'react-number-format'

interface Props {
  name: string
  label?: string
  error?: string
  disabled?: boolean
}

export const InputDecimalComponent = forwardRef<HTMLInputElement, Props>(
  ({ error, label, disabled, ...props }, ref) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Label htmlFor={props.name} className="form-label">
          {label}
        </Label>

        <NumericFormat
          decimalSeparator="."
          className="form-control"
          {...props}
          getInputRef={ref}
          disabled={disabled}
        />

        {error && <span style={{ color: 'red', fontSize: 12 }}>{error}</span>}
      </div>
    )
  },
)

InputComponent.displayName = 'InputComponent'
