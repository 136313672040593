import { graphClient } from 'services/graph-client'
import {
  FarmField,
  Farm,
  TypeCultivation,
  TypeMeasurement,
  Harvest,
} from 'types/harvests/harvests-types'

import {
  getFarmsFieldsDashboardGQL,
  getFarmsDashboardGQL,
  getTypesCultivationsDashboardGQL,
  getTypesMeasurementsDashboardGQL,
  getHarvestsDashboardGQL,
  getHarvestsContainsDashboardGQL,
} from './queries'

interface Response {
  getHarvestsDashboard: {
    data: Harvest[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getHarvestsContainsDashboard: {
    data: Harvest[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  farmId?: number
  farmFieldId?: number
  typeCultivationId?: number
  typeMeasurementId?: number
  name?: string
  description?: string
  automaticCost?: boolean
  active?: boolean
  deleted?: boolean
  dateHarvestInitial?: string
  dateHarvestFinal?: string
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getHarvestsDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getHarvestsDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getHarvestsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getHarvestsDashboardGQL, {
    take,
    skip,
  })
}

export const getHarvestsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getHarvestsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseFarmField {
  getFarmsFieldsDashboard: {
    data: FarmField[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsFieldsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarmField>(
    getFarmsFieldsDashboardGQL,
    { take, skip },
  )
}

interface ResponseFarm {
  getFarmsDashboard: {
    data: Farm[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarm>(getFarmsDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseTypeCultivation {
  getTypesCultivationsDashboard: {
    data: TypeCultivation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCultivationsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeCultivation>(
    getTypesCultivationsDashboardGQL,
    { take, skip },
  )
}

interface ResponseTypeMeasurement {
  getTypesMeasurementsDashboard: {
    data: TypeMeasurement[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesMeasurementsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeMeasurement>(
    getTypesMeasurementsDashboardGQL,
    { take, skip },
  )
}
