import { gql } from 'graphql-request'

export const createTypeCultivationDashboardGQL = gql`
  mutation CREATE_TYPE_CULTIVATION_DASHBOARD(
    $name: String!
    $description: String!
  ) {
    createTypeCultivationDashboard(name: $name, description: $description) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateTypeCultivationDashboardGQL = gql`
  mutation UPDATE_TYPE_CULTIVATION_DASHBOARD(
    $typeCultivationId: Int!
    $name: String
    $description: String
  ) {
    updateTypeCultivationDashboard(
      typeCultivationId: $typeCultivationId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteTypeCultivationDashboardGQL = gql`
  mutation DELETE_TYPE_CULTIVATION_DASHBOARD(
    $typeCultivationId: Int!
    $deleted: Boolean!
  ) {
    deleteTypeCultivationDashboard(
      typeCultivationId: $typeCultivationId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeTypeCultivationDashboardGQL = gql`
  mutation ACTIVE_TYPE_CULTIVATION_DASHBOARD(
    $typeCultivationId: Int!
    $active: Boolean!
  ) {
    activeTypeCultivationDashboard(
      typeCultivationId: $typeCultivationId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverTypeCultivationDashboardGQL = gql`
  mutation DELETE_FOREVER_TYPE_CULTIVATION_DASHBOARD($typeCultivationId: Int!) {
    deleteForeverTypeCultivationDashboard(typeCultivationId: $typeCultivationId)
  }
`

export const getTypesCultivationsDashboardGQL = gql`
  query GET_TYPES_CULTIVATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getTypesCultivationsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCultivationsContainsDashboardGQL = gql`
  query GET_TYPES_CULTIVATIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getTypesCultivationsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
