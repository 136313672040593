import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import {
  createAgriculturalInput,
  updateAgriculturalInput,
} from 'services/agriculturals-inputs/agriculturals-inputs/agriculturals-inputs-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectAgriculturalInput,
} from 'slices/agriculturals-inputs/agriculturals-inputs-reducer'
import { z } from 'zod'
import { useGetAgriculturalsInputsSubsTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-subs-types-dashboard'
import { useGetAgriculturalsInputsModesActionsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-modes-actions-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  agriculturalInputSubTypeId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-agricultural-input-sub-type-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  agriculturalInputModeActionId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-agricultural-input-mode-action-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-name')),

  description: z
    .string()
    .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-description')),

  tradeMark: z.string(),
  formulation: z.string(),
  activeIngredient: z.string(),
  isPesticide: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-is-pesticide')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  toxicologicalClass: z.string(),
  environmentalClass: z.string(),
  isOrganic: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-is-organic')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  isFertilizer: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('agriculturals-inputs-is-fertilizer'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),
})

export type FormData = z.infer<typeof schema>

const agriculturalInputModalFormSelector = (state: StateRoot) => {
  return state.agriculturalsInputsReducer
}

export const selectorResult = createSelector(
  agriculturalInputModalFormSelector,
  (agriculturalInputReducer) => {
    return agriculturalInputReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, agriculturalInputSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('agriculturals_inputs')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const agriculturalInputSubTypeInfo =
    useGetAgriculturalsInputsSubsTypesDashboard()
  const agriculturalInputModeActionInfo =
    useGetAgriculturalsInputsModesActionsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectAgriculturalInput(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createAgriculturalInputFn, isPending } = useMutation({
    mutationFn: createAgriculturalInput,
  })

  const { mutateAsync: updateAgriculturalInputFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateAgriculturalInput,
    })

  const cleanFields: FormData = {
    agriculturalInputSubTypeId: '',
    agriculturalInputModeActionId: '',
    name: '',
    description: '',
    tradeMark: '',
    formulation: '',
    activeIngredient: '',
    isPesticide: '',
    toxicologicalClass: '',
    environmentalClass: '',
    isOrganic: '',
    isFertilizer: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!agriculturalInputSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      agriculturalInputSubTypeId: formatSelectValueToFields(
        agriculturalInputSelected.agriculturalInputSubType,
        ['id', 'name'],
      ),
      agriculturalInputModeActionId: formatSelectValueToFields(
        agriculturalInputSelected.agriculturalInputModeAction,
        ['id', 'name'],
      ),
      name: agriculturalInputSelected.name,
      description: agriculturalInputSelected.description,
      tradeMark: agriculturalInputSelected.tradeMark,
      formulation: agriculturalInputSelected.formulation,
      activeIngredient: agriculturalInputSelected.activeIngredient,
      isPesticide: `${agriculturalInputSelected.isPesticide}`,
      toxicologicalClass: agriculturalInputSelected.toxicologicalClass,
      environmentalClass: agriculturalInputSelected.environmentalClass,
      isOrganic: `${agriculturalInputSelected.isOrganic}`,
      isFertilizer: `${agriculturalInputSelected.isFertilizer}`,
    }

    reset(agriculturalInputSelected ? fields : cleanFields)
  }, [agriculturalInputSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedAgriculturalInputSubType = +formatToSendData(
        data?.agriculturalInputSubTypeId,
      )
      const selectedAgriculturalInputModeAction = +formatToSendData(
        data?.agriculturalInputModeActionId,
      )
      const selectedIsPesticide = formatToSendData(data?.isPesticide)
      const selectedIsOrganic = formatToSendData(data?.isOrganic)
      const selectedIsFertilizer = formatToSendData(data?.isFertilizer)

      const formatedData = {
        agriculturalInputSubTypeId: selectedAgriculturalInputSubType,
        agriculturalInputModeActionId: selectedAgriculturalInputModeAction,
        name: data?.name,
        description: data?.description,
        tradeMark: data?.tradeMark,
        formulation: data?.formulation,
        activeIngredient: data?.activeIngredient,
        isPesticide: formatInputBooleanValue(selectedIsPesticide),
        toxicologicalClass: data?.toxicologicalClass,
        environmentalClass: data?.environmentalClass,
        isOrganic: formatInputBooleanValue(selectedIsOrganic),
        isFertilizer: formatInputBooleanValue(selectedIsFertilizer),
      }

      if (agriculturalInputSelected && agriculturalInputSelected?.id) {
        const { updateAgriculturalInputDashboard } =
          await updateAgriculturalInputFn({
            ...formatedData,
            agriculturalInputId: agriculturalInputSelected?.id,
          })

        dispatch(editUniqueData(updateAgriculturalInputDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createAgriculturalInputFn(formatedData)

      dispatch(pushData(responseData?.createAgriculturalInputDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    agriculturalInputSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    agriculturalInputSubTypeInfo,
    agriculturalInputModeActionInfo,
  }
}
