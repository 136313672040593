import { graphClient } from 'services/graph-client'
import {
  activeTypeCoffeeColorDashboardGQL,
  deleteTypeCoffeeColorDashboardGQL,
  deleteForeverTypeCoffeeColorDashboardGQL,
  createTypeCoffeeColorDashboardGQL,
  updateTypeCoffeeColorDashboardGQL,
} from './queries'
import { TypeCoffeeColor } from 'types/types-coffees/types-coffees-colors-types'

interface ResponseCreate {
  createTypeCoffeeColorDashboard: {
    data: TypeCoffeeColor
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeCoffeeColorDashboard: {
    data: TypeCoffeeColor
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeCoffeeColorDashboard: {
    data: TypeCoffeeColor
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeCoffeeColorDashboard: {
    data: TypeCoffeeColor
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeCoffeeColorDashboard: string
}

interface DataActive {
  typeCoffeeColorId: number
  active: boolean
}

interface DataDeleted {
  typeCoffeeColorId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeCoffeeColorId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  typeCoffeeColorId: number
  name: string
  description?: string
}

export const activeTypeCoffeeColor = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeTypeCoffeeColorDashboardGQL,
    { ...data },
  )
}

export const deleteTypeCoffeeColor = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteTypeCoffeeColorDashboardGQL,
    { ...data },
  )
}

export const deleteForeverTypeCoffeeColor = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeCoffeeColorDashboardGQL,
    { ...data },
  )
}

export const createTypeCoffeeColor = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createTypeCoffeeColorDashboardGQL,
    { ...data },
  )
}

export const updateTypeCoffeeColor = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateTypeCoffeeColorDashboardGQL,
    { ...data },
  )
}
