import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'

const schemaFilter = z.object({
  name: z.string().min(1, t('inform-field') + ' ' + t('farms-name')),

  description: z.string(),
  document: z.string(),
  documentTwo: z.string(),
  documentThree: z.string(),
  email: z.string().min(1, t('inform-field') + ' ' + t('farms-email')),

  phone: z
    .object({
      phone: z.string(),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('farms-phone'),
      path: ['phone'],
    }),
  country: z.string(),
  state: z.string(),
  city: z.string(),

  geographicLocation: z.string(),
  active: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    name: '',
    description: '',
    document: '',
    documentTwo: '',
    documentThree: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
    country: '',
    state: '',
    city: '',
    geographicLocation: '',
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = form

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    register,
    handleSelectCountry,
    resetFields,
    getValues,
  }
}
