import { createSelector } from 'reselect'
import { TypeCoffeeColor } from 'types/types-coffees/types-coffees-colors-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverTypeCoffeeColor } from 'services/types-coffees/types-coffees-colors/types-coffees-colors-cruds'
import {
  getTypesCoffeesColorsDashboard,
  GetParams,
  getTypesCoffeesColorsContainsDashboard,
} from 'services/types-coffees/types-coffees-colors/types-coffees-colors-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/types-coffees/types-coffees-colors-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const typeCoffeeColorListSelector = (state: StateRoot) =>
  state.typesCoffeesColorsReducer

const selectorResult = createSelector(
  typeCoffeeColorListSelector,
  (typeCoffeeColorListReducer) => {
    return typeCoffeeColorListReducer
  },
)

export const useTypeCoffeeColorList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('types_coffees_colors')

  const {
    data: dataTypeCoffeeColor,
    deleteModalOpen,
    typeCoffeeColorIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'typeCoffeeColor', 'list', { params }],
    queryFn: () => getTypesCoffeesColorsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverTypeCoffeeColor,
  })

  const handleStoreData = useCallback(
    (typesCoffeesColors: TypeCoffeeColor[], totalPages: number | null) => {
      if (!typesCoffeesColors) return

      dispatch(setData(typesCoffeesColors))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverTypeCoffeeColor = useCallback(async () => {
    if (!typeCoffeeColorIdToDelete) return

    const response = await mutateAsync({
      typeCoffeeColorId: +typeCoffeeColorIdToDelete,
    })

    if (response?.deleteForeverTypeCoffeeColorDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: typeCoffeeColorIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [typeCoffeeColorIdToDelete])

  useEffect(() => {
    const typesCoffeesColorsResult = data?.getTypesCoffeesColorsDashboard

    if (typesCoffeesColorsResult) {
      handleStoreData(
        typesCoffeesColorsResult?.data,
        typesCoffeesColorsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getTypesCoffeesColorsContainsDashboard(value)
      const typesCoffeesColorsResult =
        response?.getTypesCoffeesColorsContainsDashboard

      handleStoreData(
        typesCoffeesColorsResult?.data,
        typesCoffeesColorsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    typeCoffeeColorIdToDelete,
    typeCoffeeColor: data?.getTypesCoffeesColorsDashboard?.data,
    isLoading,
    isFetching,
    dataTypeCoffeeColor,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverTypeCoffeeColor,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
