import { graphClient } from 'services/graph-client'
import { TypePest } from 'types/types-coffees/types-pests-types'

import { getTypesPestsDashboardGQL } from 'services/types-coffees/types-pests/queries'

interface Response {
  getTypesPestsDashboard: {
    data: TypePest[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesPestsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getTypesPestsDashboardGQL, {
    take,
    skip,
  })
}
