import { ComponentPropsWithRef, forwardRef, useState } from 'react'
import { Label } from 'reactstrap'

interface Props extends ComponentPropsWithRef<'input'> {
  label?: string
  error?: string
}

export const InputPasswordComponent = forwardRef<HTMLInputElement, Props>(
  ({ error, label, ...props }, ref) => {
    const [passwordShow, setPasswordShow] = useState(false)

    return (
      <>
        <Label htmlFor={props.name} className="form-label">
          {label}
        </Label>
        <div className="position-relative auth-pass-inputgroup mb-3">
          <input
            {...props}
            type={passwordShow ? 'text' : 'password'}
            className="form-control"
            ref={ref}
          />

          {error && <span style={{ color: 'red' }}>{error}</span>}

          <button
            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
            type="button"
            id="password-addon"
            onClick={() => setPasswordShow((prevState) => !prevState)}
          >
            <i className="ri-eye-fill align-middle"></i>
          </button>
        </div>
      </>
    )
  },
)

InputPasswordComponent.displayName = 'InputPasswordComponent'
