import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeDefaultAgriculturalInput,
  deleteDefaultAgriculturalInput,
} from 'services/defaults/defaults-agriculturals-inputs/defaults-agriculturals-inputs-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectDefaultAgriculturalInput,
} from 'slices/defaults/defaults-agriculturals-inputs-reducer'
import { DefaultAgriculturalInput } from 'types/defaults/defaults-agriculturals-inputs-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('defaults_agriculturals_inputs')
  const dispatch = useDispatch()
  const item = cell?.row?.original as DefaultAgriculturalInput

  const handleOpenForm = () => {
    dispatch(selectDefaultAgriculturalInput(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(
      selectDefaultAgriculturalInput(itemWithoutId as DefaultAgriculturalInput),
    )
    dispatch(openForm())
  }

  const { mutateAsync: deleteDefaultAgriculturalInputDashboardFn } =
    useMutation({
      mutationFn: deleteDefaultAgriculturalInput,
    })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteDefaultAgriculturalInputDashboard } =
      await deleteDefaultAgriculturalInputDashboardFn({
        defaultAgriculturalInputId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteDefaultAgriculturalInputDashboard?.data))
  }

  const { mutateAsync: activeDefaultAgriculturalInputDashboardFn } =
    useMutation({
      mutationFn: activeDefaultAgriculturalInput,
    })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeDefaultAgriculturalInputDashboard } =
      await activeDefaultAgriculturalInputDashboardFn({
        defaultAgriculturalInputId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeDefaultAgriculturalInputDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
