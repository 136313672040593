import { createSlice } from '@reduxjs/toolkit'
import { CompanyOwner } from 'types/settings/companies-owners-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  companyOwnerSelected: null | CompanyOwner
  deleteModalOpen: boolean
  companyOwnerIdToDelete: null | string
  data: CompanyOwner[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  companyOwnerSelected: null,
  companyOwnerIdToDelete: null,
  data: [],
  total: 0,
}

const companyOwnerSlice = createSlice({
  initialState,
  name: 'companies-owners-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectCompanyOwner(state, { payload }: { payload: CompanyOwner | null }) {
      state.companyOwnerSelected = payload
    },
    setData(state, { payload }: { payload: CompanyOwner[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: CompanyOwner }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: CompanyOwner }) {
      state.data = state.data.map((companyOwner) => {
        return companyOwner.id === payload.id
          ? { ...companyOwner, ...payload }
          : companyOwner
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (companyOwner) => +companyOwner.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.companyOwnerIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.companyOwnerIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectCompanyOwner,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = companyOwnerSlice.actions
export default companyOwnerSlice.reducer
