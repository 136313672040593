import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useGetAgriculturalsInputsSubsTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-subs-types-dashboard'
import { useGetAgriculturalsInputsModesActionsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-modes-actions-dashboard'

const schemaFilter = z.object({
  agriculturalInputSubTypeId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-agricultural-input-sub-type-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  agriculturalInputModeActionId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-agricultural-input-mode-action-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-name')),

  description: z
    .string()
    .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-description')),

  tradeMark: z.string(),
  formulation: z.string(),
  activeIngredient: z.string(),
  isPesticide: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-is-pesticide')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  toxicologicalClass: z.string(),
  environmentalClass: z.string(),
  isOrganic: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-is-organic')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  isFertilizer: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('agriculturals-inputs-is-fertilizer'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  active: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    agriculturalInputSubTypeId: '',
    agriculturalInputModeActionId: '',
    name: '',
    description: '',
    tradeMark: '',
    formulation: '',
    activeIngredient: '',
    isPesticide: '',
    toxicologicalClass: '',
    environmentalClass: '',
    isOrganic: '',
    isFertilizer: '',
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    getValues,
  } = form

  const agriculturalInputSubTypeInfo =
    useGetAgriculturalsInputsSubsTypesDashboard()
  const agriculturalInputModeActionInfo =
    useGetAgriculturalsInputsModesActionsDashboard()

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    agriculturalInputSubTypeInfo,
    agriculturalInputModeActionInfo,
    register,
    resetFields,
    getValues,
  }
}
