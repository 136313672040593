export const formatOptionsSelect = (data?: { id: string; name: string }[]) => {
  return [
    {
      options:
        data?.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? [],
    },
  ]
}

export const formatOptionsSelectDescription = (
  data?: { id: string; description: string }[],
) => {
  return [
    {
      options:
        data?.map((item) => ({
          label: item.description,
          value: item.id,
        })) ?? [],
    },
  ]
}
