import { graphClient } from 'services/graph-client'
import {
  activeFarmOwnerDashboardGQL,
  deleteFarmOwnerDashboardGQL,
  deleteForeverFarmOwnerDashboardGQL,
  createFarmOwnerDashboardGQL,
  updateFarmOwnerDashboardGQL,
} from './queries'
import { FarmOwner } from 'types/farms/farms-owners-types'

interface ResponseCreate {
  createFarmOwnerDashboard: {
    data: FarmOwner
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateFarmOwnerDashboard: {
    data: FarmOwner
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeFarmOwnerDashboard: {
    data: FarmOwner
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteFarmOwnerDashboard: {
    data: FarmOwner
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverFarmOwnerDashboard: string
}

interface DataActive {
  farmOwnerId: number
  active: boolean
}

interface DataDeleted {
  farmOwnerId: number
  deleted: boolean
}

interface DataDeleteForever {
  farmOwnerId: number
}

interface DataCreate {
  farmId: number
  name: string
  document?: string
  documentTwo?: string
  documentThree?: string
  email?: string
  phone?: string
  priorityContact: boolean
}

interface DataUpdate {
  farmOwnerId: number
  farmId: number
  name: string
  document?: string
  documentTwo?: string
  documentThree?: string
  email?: string
  phone?: string
  priorityContact: boolean
}

export const activeFarmOwner = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeFarmOwnerDashboardGQL,
    { ...data },
  )
}

export const deleteFarmOwner = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteFarmOwnerDashboardGQL,
    { ...data },
  )
}

export const deleteForeverFarmOwner = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverFarmOwnerDashboardGQL,
    { ...data },
  )
}

export const createFarmOwner = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createFarmOwnerDashboardGQL,
    { ...data },
  )
}

export const updateFarmOwner = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateFarmOwnerDashboardGQL,
    { ...data },
  )
}
