import { createSelector } from 'reselect'
import { TypeCoffeeIrrigation } from 'types/types-coffees/types-coffees-irrigations-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverTypeCoffeeIrrigation } from 'services/types-coffees/types-coffees-irrigations/types-coffees-irrigations-cruds'
import {
  getTypesCoffeesIrrigationsDashboard,
  GetParams,
  getTypesCoffeesIrrigationsContainsDashboard,
} from 'services/types-coffees/types-coffees-irrigations/types-coffees-irrigations-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/types-coffees/types-coffees-irrigations-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const typeCoffeeIrrigationListSelector = (state: StateRoot) =>
  state.typesCoffeesIrrigationsReducer

const selectorResult = createSelector(
  typeCoffeeIrrigationListSelector,
  (typeCoffeeIrrigationListReducer) => {
    return typeCoffeeIrrigationListReducer
  },
)

export const useTypeCoffeeIrrigationList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('types_coffees_irrigations')

  const {
    data: dataTypeCoffeeIrrigation,
    deleteModalOpen,
    typeCoffeeIrrigationIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'typeCoffeeIrrigation', 'list', { params }],
    queryFn: () => getTypesCoffeesIrrigationsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverTypeCoffeeIrrigation,
  })

  const handleStoreData = useCallback(
    (
      typesCoffeesIrrigations: TypeCoffeeIrrigation[],
      totalPages: number | null,
    ) => {
      if (!typesCoffeesIrrigations) return

      dispatch(setData(typesCoffeesIrrigations))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverTypeCoffeeIrrigation = useCallback(async () => {
    if (!typeCoffeeIrrigationIdToDelete) return

    const response = await mutateAsync({
      typeCoffeeIrrigationId: +typeCoffeeIrrigationIdToDelete,
    })

    if (
      response?.deleteForeverTypeCoffeeIrrigationDashboard === 'deleted_forever'
    ) {
      dispatch(deleteUniqueData({ id: typeCoffeeIrrigationIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [typeCoffeeIrrigationIdToDelete])

  useEffect(() => {
    const typesCoffeesIrrigationsResult =
      data?.getTypesCoffeesIrrigationsDashboard

    if (typesCoffeesIrrigationsResult) {
      handleStoreData(
        typesCoffeesIrrigationsResult?.data,
        typesCoffeesIrrigationsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getTypesCoffeesIrrigationsContainsDashboard(value)
      const typesCoffeesIrrigationsResult =
        response?.getTypesCoffeesIrrigationsContainsDashboard

      handleStoreData(
        typesCoffeesIrrigationsResult?.data,
        typesCoffeesIrrigationsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    typeCoffeeIrrigationIdToDelete,
    typeCoffeeIrrigation: data?.getTypesCoffeesIrrigationsDashboard?.data,
    isLoading,
    isFetching,
    dataTypeCoffeeIrrigation,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverTypeCoffeeIrrigation,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
