import { useState } from 'react'
import { User } from 'types/auth/login-types'

export const useUserDetails = () => {
  const [user] = useState<User>(() => {
    const storageUser = localStorage.getItem('@user')

    return storageUser ? JSON.parse(storageUser) : {}
  })

  return {
    user,
    roles: user?.roles,
  }
}
