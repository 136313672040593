import { graphClient } from 'services/graph-client'
import { Role } from 'types/users/roles-types'

import { getRolesDashboardGQL, getRolesContainsDashboardGQL } from './queries'

interface Response {
  getRolesDashboard: {
    data: Role[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getRolesContainsDashboard: {
    data: Role[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
  nameIndex?: string
}

export interface GetParams {
  page?: number
  userTypeId?: number
  name?: string
  description?: string
  nameIndex?: string
  canMenu?: boolean
  canAdd?: boolean
  canDuplicate?: boolean
  canEdit?: boolean
  canDeleteForever?: boolean
  canExport?: boolean
  canImport?: boolean
  canActive?: boolean
  canDeleted?: boolean
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getRolesDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getRolesDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getRolesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getRolesDashboardGQL, {
    take,
    skip,
  })
}

export const getRolesContainsDashboard = async (params: GetContainsParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getRolesContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}
