import { graphClient } from 'services/graph-client'
import {
  Company,
  TypeCoffeeMaturation,
} from 'types/types-coffees/types-coffees-maturations-types'

import {
  getCompaniesDashboardGQL,
  getTypesCoffeesMaturationsDashboardGQL,
  getTypesCoffeesMaturationsContainsDashboardGQL,
} from './queries'

interface Response {
  getTypesCoffeesMaturationsDashboard: {
    data: TypeCoffeeMaturation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getTypesCoffeesMaturationsContainsDashboard: {
    data: TypeCoffeeMaturation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  name?: string
  description?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getTypesCoffeesMaturationsDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getTypesCoffeesMaturationsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getTypesCoffeesMaturationsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getTypesCoffeesMaturationsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getTypesCoffeesMaturationsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getTypesCoffeesMaturationsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}
