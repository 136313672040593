import { gql } from 'graphql-request'

export const createHarvestForecastDashboardGQL = gql`
  mutation CREATE_HARVEST_FORECAST_DASHBOARD(
    $farmId: Int
    $farmFieldId: Int
    $harvestId: Int!
    $forecastPlant: Float!
    $litersSpentPerBag: Float!
    $bagPerHectare: Float!
    $totalBag: Float!
    $name: String!
  ) {
    createHarvestForecastDashboard(
      farmId: $farmId
      farmFieldId: $farmFieldId
      harvestId: $harvestId
      forecastPlant: $forecastPlant
      litersSpentPerBag: $litersSpentPerBag
      bagPerHectare: $bagPerHectare
      totalBag: $totalBag
      name: $name
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        harvestId
        harvest {
          id
          name
        }
        forecastPlant
        litersSpentPerBag
        bagPerHectare
        totalBag
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateHarvestForecastDashboardGQL = gql`
  mutation UPDATE_HARVEST_FORECAST_DASHBOARD(
    $harvestForecastId: Int!
    $farmId: Int
    $farmFieldId: Int
    $harvestId: Int
    $forecastPlant: Float
    $litersSpentPerBag: Float
    $bagPerHectare: Float
    $totalBag: Float
    $name: String
  ) {
    updateHarvestForecastDashboard(
      harvestForecastId: $harvestForecastId
      farmId: $farmId
      farmFieldId: $farmFieldId
      harvestId: $harvestId
      forecastPlant: $forecastPlant
      litersSpentPerBag: $litersSpentPerBag
      bagPerHectare: $bagPerHectare
      totalBag: $totalBag
      name: $name
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        harvestId
        harvest {
          id
          name
        }
        forecastPlant
        litersSpentPerBag
        bagPerHectare
        totalBag
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteHarvestForecastDashboardGQL = gql`
  mutation DELETE_HARVEST_FORECAST_DASHBOARD(
    $harvestForecastId: Int!
    $deleted: Boolean!
  ) {
    deleteHarvestForecastDashboard(
      harvestForecastId: $harvestForecastId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        harvestId
        forecastPlant
        litersSpentPerBag
        bagPerHectare
        totalBag
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeHarvestForecastDashboardGQL = gql`
  mutation ACTIVE_HARVEST_FORECAST_DASHBOARD(
    $harvestForecastId: Int!
    $active: Boolean!
  ) {
    activeHarvestForecastDashboard(
      harvestForecastId: $harvestForecastId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        harvestId
        forecastPlant
        litersSpentPerBag
        bagPerHectare
        totalBag
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverHarvestForecastDashboardGQL = gql`
  mutation DELETE_FOREVER_HARVEST_FORECAST_DASHBOARD($harvestForecastId: Int!) {
    deleteForeverHarvestForecastDashboard(harvestForecastId: $harvestForecastId)
  }
`

export const getHarvestsForecastsDashboardGQL = gql`
  query GET_HARVESTS_FORECASTS_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $farmFieldId: Int
    $harvestId: Int
    $forecastPlant: Float
    $litersSpentPerBag: Float
    $bagPerHectare: Float
    $totalBag: Float
    $name: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getHarvestsForecastsDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      farmFieldId: $farmFieldId
      harvestId: $harvestId
      forecastPlant: $forecastPlant
      litersSpentPerBag: $litersSpentPerBag
      bagPerHectare: $bagPerHectare
      totalBag: $totalBag
      name: $name
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        harvestId
        harvest {
          id
          name
        }
        forecastPlant
        litersSpentPerBag
        bagPerHectare
        totalBag
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmFieldDashboardIdGQL = gql`
  query GET_FARM_FIELD_DASHBOARD_ID($farmFieldId: Int!) {
    getFarmFieldDashboard(farmFieldId: $farmFieldId) {
      error
      status
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsFieldsDashboardGQL = gql`
  query GET_FARMS_FIELDS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsFieldsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getHarvestDashboardIdGQL = gql`
  query GET_HARVEST_DASHBOARD_ID($harvestId: Int!) {
    getHarvestDashboard(harvestId: $harvestId) {
      error
      status
      data {
        id
        farmId
        farmFieldId
        typeCultivationId
        typeMeasurementId
        name
        description
        automaticCost
        active
        deleted
        dateHarvestInitial
        dateHarvestFinal
        createdAt
        updatedAt
      }
    }
  }
`

export const getHarvestsDashboardGQL = gql`
  query GET_HARVESTS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getHarvestsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        farmId
        farmFieldId
        typeCultivationId
        typeMeasurementId
        name
        description
        automaticCost
        active
        deleted
        dateHarvestInitial
        dateHarvestFinal
        createdAt
        updatedAt
      }
    }
  }
`

export const getHarvestsForecastsContainsDashboardGQL = gql`
  query GET_HARVESTS_FORECASTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
  ) {
    getHarvestsForecastsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        harvestId
        harvest {
          id
          name
        }
        forecastPlant
        litersSpentPerBag
        bagPerHectare
        totalBag
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
