import { gql } from 'graphql-request'

export const createEmployeeFunctionDashboardGQL = gql`
  mutation CREATE_EMPLOYEE_FUNCTION_DASHBOARD(
    $farmId: Int
    $name: String!
    $description: String
    $baseSalaryCurrency: Float!
  ) {
    createEmployeeFunctionDashboard(
      farmId: $farmId
      name: $name
      description: $description
      baseSalaryCurrency: $baseSalaryCurrency
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        name
        description
        baseSalaryCurrency
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateEmployeeFunctionDashboardGQL = gql`
  mutation UPDATE_EMPLOYEE_FUNCTION_DASHBOARD(
    $employeeFunctionId: Int!
    $farmId: Int
    $name: String
    $description: String
    $baseSalaryCurrency: Float
  ) {
    updateEmployeeFunctionDashboard(
      employeeFunctionId: $employeeFunctionId
      farmId: $farmId
      name: $name
      description: $description
      baseSalaryCurrency: $baseSalaryCurrency
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        name
        description
        baseSalaryCurrency
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteEmployeeFunctionDashboardGQL = gql`
  mutation DELETE_EMPLOYEE_FUNCTION_DASHBOARD(
    $employeeFunctionId: Int!
    $deleted: Boolean!
  ) {
    deleteEmployeeFunctionDashboard(
      employeeFunctionId: $employeeFunctionId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        name
        description
        baseSalaryCurrency
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeEmployeeFunctionDashboardGQL = gql`
  mutation ACTIVE_EMPLOYEE_FUNCTION_DASHBOARD(
    $employeeFunctionId: Int!
    $active: Boolean!
  ) {
    activeEmployeeFunctionDashboard(
      employeeFunctionId: $employeeFunctionId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        name
        description
        baseSalaryCurrency
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverEmployeeFunctionDashboardGQL = gql`
  mutation DELETE_FOREVER_EMPLOYEE_FUNCTION_DASHBOARD(
    $employeeFunctionId: Int!
  ) {
    deleteForeverEmployeeFunctionDashboard(
      employeeFunctionId: $employeeFunctionId
    )
  }
`

export const getEmployeesFunctionsDashboardGQL = gql`
  query GET_EMPLOYEES_FUNCTIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $name: String
    $description: String
    $baseSalaryCurrency: Float
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getEmployeesFunctionsDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      name: $name
      description: $description
      baseSalaryCurrency: $baseSalaryCurrency
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        name
        description
        baseSalaryCurrency
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getEmployeesFunctionsContainsDashboardGQL = gql`
  query GET_EMPLOYEES_FUNCTIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getEmployeesFunctionsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        name
        description
        baseSalaryCurrency
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
