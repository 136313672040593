import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeDefaultTypeCoffeeManagement,
  deleteDefaultTypeCoffeeManagement,
} from 'services/defaults/defaults-types-coffees-managements/defaults-types-coffees-managements-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectDefaultTypeCoffeeManagement,
} from 'slices/defaults/defaults-types-coffees-managements-reducer'
import { DefaultTypeCoffeeManagement } from 'types/defaults/defaults-types-coffees-managements-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('defaults_types_coffees_managements')
  const dispatch = useDispatch()
  const item = cell?.row?.original as DefaultTypeCoffeeManagement

  const handleOpenForm = () => {
    dispatch(selectDefaultTypeCoffeeManagement(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(
      selectDefaultTypeCoffeeManagement(
        itemWithoutId as DefaultTypeCoffeeManagement,
      ),
    )
    dispatch(openForm())
  }

  const { mutateAsync: deleteDefaultTypeCoffeeManagementDashboardFn } =
    useMutation({
      mutationFn: deleteDefaultTypeCoffeeManagement,
    })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteDefaultTypeCoffeeManagementDashboard } =
      await deleteDefaultTypeCoffeeManagementDashboardFn({
        defaultTypeCoffeeManagementId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteDefaultTypeCoffeeManagementDashboard?.data))
  }

  const { mutateAsync: activeDefaultTypeCoffeeManagementDashboardFn } =
    useMutation({
      mutationFn: activeDefaultTypeCoffeeManagement,
    })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeDefaultTypeCoffeeManagementDashboard } =
      await activeDefaultTypeCoffeeManagementDashboardFn({
        defaultTypeCoffeeManagementId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeDefaultTypeCoffeeManagementDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
