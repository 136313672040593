import { gql } from 'graphql-request'

export const createUserRoleDashboardGQL = gql`
  mutation CREATE_USER_ROLE_DASHBOARD(
    $roleId: Int!
    $name: String!
    $nameIndex: String!
    $canMenu: Boolean!
    $canAdd: Boolean!
    $canDuplicate: Boolean!
    $canEdit: Boolean!
    $canDeleteForever: Boolean!
    $canExport: Boolean!
    $canImport: Boolean!
    $canActive: Boolean!
    $canDeleted: Boolean!
  ) {
    createUserRoleDashboard(
      roleId: $roleId
      name: $name
      nameIndex: $nameIndex
      canMenu: $canMenu
      canAdd: $canAdd
      canDuplicate: $canDuplicate
      canEdit: $canEdit
      canDeleteForever: $canDeleteForever
      canExport: $canExport
      canImport: $canImport
      canActive: $canActive
      canDeleted: $canDeleted
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        roleId
        role {
          id
          name
        }
        name
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateUserRoleDashboardGQL = gql`
  mutation UPDATE_USER_ROLE_DASHBOARD(
    $userRoleId: Int!
    $roleId: Int
    $name: String
    $nameIndex: String
    $canMenu: Boolean
    $canAdd: Boolean
    $canDuplicate: Boolean
    $canEdit: Boolean
    $canDeleteForever: Boolean
    $canExport: Boolean
    $canImport: Boolean
    $canActive: Boolean
    $canDeleted: Boolean
  ) {
    updateUserRoleDashboard(
      userRoleId: $userRoleId
      roleId: $roleId
      name: $name
      nameIndex: $nameIndex
      canMenu: $canMenu
      canAdd: $canAdd
      canDuplicate: $canDuplicate
      canEdit: $canEdit
      canDeleteForever: $canDeleteForever
      canExport: $canExport
      canImport: $canImport
      canActive: $canActive
      canDeleted: $canDeleted
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        roleId
        role {
          id
          name
        }
        name
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteUserRoleDashboardGQL = gql`
  mutation DELETE_USER_ROLE_DASHBOARD($userRoleId: Int!, $deleted: Boolean!) {
    deleteUserRoleDashboard(userRoleId: $userRoleId, deleted: $deleted) {
      error
      status
      data {
        id
        userId
        companyId
        roleId
        name
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeUserRoleDashboardGQL = gql`
  mutation ACTIVE_USER_ROLE_DASHBOARD($userRoleId: Int!, $active: Boolean!) {
    activeUserRoleDashboard(userRoleId: $userRoleId, active: $active) {
      error
      status
      data {
        id
        userId
        companyId
        roleId
        name
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverUserRoleDashboardGQL = gql`
  mutation DELETE_FOREVER_USER_ROLE_DASHBOARD($userRoleId: Int!) {
    deleteForeverUserRoleDashboard(userRoleId: $userRoleId)
  }
`

export const getUsersRolesDashboardGQL = gql`
  query GET_USERS_ROLES_DASHBOARD(
    $take: Int
    $skip: Int
    $userId: Int
    $roleId: Int
    $name: String
    $nameIndex: String
    $canMenu: Boolean
    $canAdd: Boolean
    $canDuplicate: Boolean
    $canEdit: Boolean
    $canDeleteForever: Boolean
    $canExport: Boolean
    $canImport: Boolean
    $canActive: Boolean
    $canDeleted: Boolean
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getUsersRolesDashboard(
      take: $take
      skip: $skip
      userId: $userId
      roleId: $roleId
      name: $name
      nameIndex: $nameIndex
      canMenu: $canMenu
      canAdd: $canAdd
      canDuplicate: $canDuplicate
      canEdit: $canEdit
      canDeleteForever: $canDeleteForever
      canExport: $canExport
      canImport: $canImport
      canActive: $canActive
      canDeleted: $canDeleted
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        roleId
        role {
          id
          name
        }
        name
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUserDashboardIdGQL = gql`
  query GET_USER_DASHBOARD_ID($userId: Int!) {
    getUserDashboard(userId: $userId) {
      error
      status
      data {
        id
        userTypeId
        companyId
        farmId
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUsersDashboardGQL = gql`
  query GET_USERS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getUsersDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        userTypeId
        companyId
        farmId
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getRoleDashboardIdGQL = gql`
  query GET_ROLE_DASHBOARD_ID($roleId: Int!) {
    getRoleDashboard(roleId: $roleId) {
      error
      status
      data {
        id
        userTypeId
        name
        description
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getRolesDashboardGQL = gql`
  query GET_ROLES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getRolesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        userTypeId
        name
        description
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUsersRolesContainsDashboardGQL = gql`
  query GET_USERS_ROLES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $nameIndex: String
  ) {
    getUsersRolesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      nameIndex: $nameIndex
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        roleId
        role {
          id
          name
        }
        name
        nameIndex
        canMenu
        canAdd
        canDuplicate
        canEdit
        canDeleteForever
        canExport
        canImport
        canActive
        canDeleted
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
