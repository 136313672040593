export const loginPhoneGQL = `
    mutation LOGIN_PHONE(
      $phone: String!
      $password: String!
      $country: String!
    ) {
      loginPhone(phone: $phone, password: $password, country: $country) {
        error
        status
        error
        data {
          id
          firstName
          lastName
          phone
          token
          country
          roles
        }
      }
    }
`
