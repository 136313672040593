import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeToolDashboardGQL,
  deleteDefaultTypeToolDashboardGQL,
  deleteForeverDefaultTypeToolDashboardGQL,
  createDefaultTypeToolDashboardGQL,
  updateDefaultTypeToolDashboardGQL,
} from './queries'
import { DefaultTypeTool } from 'types/defaults/defaults-types-tools-types'

interface ResponseCreate {
  createDefaultTypeToolDashboard: {
    data: DefaultTypeTool
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeToolDashboard: {
    data: DefaultTypeTool
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeToolDashboard: {
    data: DefaultTypeTool
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeToolDashboard: {
    data: DefaultTypeTool
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeToolDashboard: string
}

interface DataActive {
  defaultTypeToolId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeToolId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeToolId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  defaultTypeToolId: number
  name: string
  description?: string
}

export const activeDefaultTypeTool = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeToolDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeTool = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeToolDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeTool = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeToolDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeTool = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeToolDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeTool = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeToolDashboardGQL,
    { ...data },
  )
}
