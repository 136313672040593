import { createSlice } from '@reduxjs/toolkit'
import { DefaultTypeCoffeeManagement } from 'types/defaults/defaults-types-coffees-managements-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  defaultTypeCoffeeManagementSelected: null | DefaultTypeCoffeeManagement
  deleteModalOpen: boolean
  defaultTypeCoffeeManagementIdToDelete: null | string
  data: DefaultTypeCoffeeManagement[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  defaultTypeCoffeeManagementSelected: null,
  defaultTypeCoffeeManagementIdToDelete: null,
  data: [],
  total: 0,
}

const defaultTypeCoffeeManagementSlice = createSlice({
  initialState,
  name: 'defaults-types-coffees-managements-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectDefaultTypeCoffeeManagement(
      state,
      { payload }: { payload: DefaultTypeCoffeeManagement | null },
    ) {
      state.defaultTypeCoffeeManagementSelected = payload
    },
    setData(state, { payload }: { payload: DefaultTypeCoffeeManagement[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: DefaultTypeCoffeeManagement }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(
      state,
      { payload }: { payload: DefaultTypeCoffeeManagement },
    ) {
      state.data = state.data.map((defaultTypeCoffeeManagement) => {
        return defaultTypeCoffeeManagement.id === payload.id
          ? { ...defaultTypeCoffeeManagement, ...payload }
          : defaultTypeCoffeeManagement
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (defaultTypeCoffeeManagement) =>
          +defaultTypeCoffeeManagement.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.defaultTypeCoffeeManagementIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.defaultTypeCoffeeManagementIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectDefaultTypeCoffeeManagement,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = defaultTypeCoffeeManagementSlice.actions
export default defaultTypeCoffeeManagementSlice.reducer
