import { ModalFormTemplate } from 'components/common/modal/form-template'
import { t } from 'i18next'
import { useUserDeviceInformationList } from '../../use-users-devices-informations'
import { useModalFormFilter } from './use-filter-form'
import { FormProvider } from 'react-hook-form'
import { FormFilterControl } from '../../components/form-filter'
import { formatDateDB } from 'helpers/format/format-date'

export const Filter = () => {
  const { filterOpen, handleCloseFilter, handleSetFilter } =
    useUserDeviceInformationList()

  const { control, errors, form, register, resetFields, getValues } =
    useModalFormFilter()

  const handleConfirm = () => {
    const values: Record<string, any> = {}
    const formValues = getValues()

    if (typeof formValues.name != 'undefined' && formValues.name != '') {
      values['name'] = formValues.name
    }

    if (
      typeof formValues.manufacturer != 'undefined' &&
      formValues.manufacturer != ''
    ) {
      values['manufacturer'] = formValues.manufacturer
    }

    if (typeof formValues.mac != 'undefined' && formValues.mac != '') {
      values['mac'] = formValues.mac
    }

    if (typeof formValues.baseOs != 'undefined' && formValues.baseOs != '') {
      values['baseOs'] = formValues.baseOs
    }

    if (
      typeof formValues.bootLoader != 'undefined' &&
      formValues.bootLoader != ''
    ) {
      values['bootLoader'] = formValues.bootLoader
    }

    if (typeof formValues.brand != 'undefined' && formValues.brand != '') {
      values['brand'] = formValues.brand
    }

    if (
      typeof formValues.buildNumber != 'undefined' &&
      formValues.buildNumber != ''
    ) {
      values['buildNumber'] = formValues.buildNumber
    }

    if (
      typeof formValues.isCameraPresent != 'undefined' &&
      formValues.isCameraPresent != ''
    ) {
      values['isCameraPresent'] = formValues.isCameraPresent
    }

    if (
      typeof formValues.networkOperator != 'undefined' &&
      formValues.networkOperator != ''
    ) {
      values['networkOperator'] = formValues.networkOperator
    }

    if (
      typeof formValues.deviceId != 'undefined' &&
      formValues.deviceId != ''
    ) {
      values['deviceId'] = formValues.deviceId
    }

    if (
      typeof formValues.deviceName != 'undefined' &&
      formValues.deviceName != ''
    ) {
      values['deviceName'] = formValues.deviceName
    }

    if (
      typeof formValues.ipAddress != 'undefined' &&
      formValues.ipAddress != ''
    ) {
      values['ipAddress'] = formValues.ipAddress
    }

    if (
      typeof formValues.systemVersion != 'undefined' &&
      formValues.systemVersion != ''
    ) {
      values['systemVersion'] = formValues.systemVersion
    }

    if (
      typeof formValues.uniqueId != 'undefined' &&
      formValues.uniqueId != ''
    ) {
      values['uniqueId'] = formValues.uniqueId
    }

    if (
      typeof formValues.userAgent != 'undefined' &&
      formValues.userAgent != ''
    ) {
      values['userAgent'] = formValues.userAgent
    }

    if (typeof formValues.installationDevice != 'undefined') {
      if (typeof formValues.installationDevice === 'object') {
        if (formValues.installationDevice.value === 'true') {
          values['installationDevice'] = true
        }
        if (formValues.installationDevice.value === 'false') {
          values['installationDevice'] = false
        }
      }
    }

    if (typeof formValues.active != 'undefined') {
      if (typeof formValues.active === 'object') {
        if (formValues.active.value === 'true') {
          values['active'] = true
        }
        if (formValues.active.value === 'false') {
          values['active'] = false
        }
      }
    }

    if (typeof formValues.deleted != 'undefined') {
      if (typeof formValues.deleted === 'object') {
        if (formValues.deleted.value === 'true') {
          values['deleted'] = true
        }
        if (formValues.deleted.value === 'false') {
          values['deleted'] = false
        }
      }
    }

    if (
      typeof formValues.createdAtInitial != 'undefined' &&
      formValues.createdAtInitial != '' &&
      typeof formValues.createdAtFinal != 'undefined' &&
      formValues.createdAtFinal != ''
    ) {
      values['createdAtInitial'] = formatDateDB(formValues.createdAtInitial)
      values['createdAtFinal'] = formatDateDB(formValues.createdAtFinal)
    }

    handleSetFilter(values)
    handleCloseFilter()
  }

  const handleClear = () => {
    resetFields()
  }

  return (
    <ModalFormTemplate
      isOpen={filterOpen}
      //full={false}
      //middle
      full={true}
      title={t('filters')}
      cancelLabel={t('cancel-filter')}
      handleConfirm={handleConfirm}
      handleClose={handleCloseFilter}
      handleCancel={handleClear}
    >
      <form
        style={{ paddingLeft: 4 }}
        className="mt-4 pe-1 d-flex flex-wrap gap-3"
      >
        <FormProvider {...form}>
          <FormFilterControl
            control={control}
            errors={errors}
            register={register}
          />
        </FormProvider>
      </form>
    </ModalFormTemplate>
  )
}
