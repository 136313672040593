import { ModalFormTemplate } from 'components/common/modal/form-template'
import { t } from 'i18next'
import { useModalForm } from './use-form'
import { FormControl } from '../components/form'
import { FormProvider } from 'react-hook-form'

export const Form = () => {
  const {
    formOpen,
    farmCultivationSelected,
    errors,
    control,
    form,
    farmInfo,
    typeCultivationInfo,
    isLoading,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
  } = useModalForm()

  const canEdit = farmCultivationSelected
    ? farmCultivationSelected && permissions.canEdit
    : true

  return (
    <ModalFormTemplate
      isOpen={formOpen}
      title={t('farms-cultivations-title')}
      subtitle={
        farmCultivationSelected && farmCultivationSelected?.id
          ? t('editing')
          : t('creating')
      }
      isLoading={isLoading}
      permissions={{ canEdit: !!canEdit }}
      handleConfirm={handleSubmit}
      handleClose={handleCloseModal}
    >
      <form className="mt-4 d-flex flex-wrap gap-3">
        <FormProvider {...form}>
          <FormControl
            control={control}
            errors={errors}
            farmInfo={farmInfo}
            typeCultivationInfo={typeCultivationInfo}
            register={register}
          />
        </FormProvider>
      </form>
    </ModalFormTemplate>
  )
}
