import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useGetAreasDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-areas-dashboard'
import { useGetCoffeesPruningsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-coffees-prunings-dashboard'
import { useGetFarmsFieldsPeriodsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-periods-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetTypesCoffeesColorsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-colors-dashboard'
import { useGetTypesCoffeesHarvestsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-harvests-dashboard'
import { useGetTypesCoffeesIrrigationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-irrigations-dashboard'
import { useGetTypesCoffeesManagementsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-managements-dashboard'
import { useGetTypesCoffeesMaturationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-maturations-dashboard'
import { useGetTypesCoffeesPruningsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-prunings-dashboard'
import { useGetTypesCoffeesSizesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-sizes-dashboard'
import { useGetTypesCoffeesVarietiesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-varieties-dashboard'
import { useGetTypesCoffeesRenovationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-renovations-dashboard'

const schemaFilter = z.object({
  farmId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-fields-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  areaId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-fields-area-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldPeriodId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('farms-fields-farm-field-period-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeColorId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('farms-fields-type-coffee-color-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeSizeId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('farms-fields-type-coffee-size-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeMaturationId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-maturation-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeIrrigationId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-irrigation-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeVarietyId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-variety-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  coffeePruningId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('farms-fields-coffee-pruning-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeePruningId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-pruning-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeManagementId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-management-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeHarvestId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-harvest-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeRenovationId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('farms-fields-type-coffee-renovation-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z.string().min(1, t('inform-field') + ' ' + t('farms-fields-name')),

  yearPlanting: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-fields-year-planting')),

  monthPlanting: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-fields-month-planting')),

  plantFailure: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-fields-plant-failure')),

  plantingQuantity: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-fields-planting-quantity')),

  geographicLocation: z.string(),
  active: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-fields-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-fields-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    farmId: '',
    areaId: '',
    farmFieldPeriodId: '',
    typeCoffeeColorId: '',
    typeCoffeeSizeId: '',
    typeCoffeeMaturationId: '',
    typeCoffeeIrrigationId: '',
    typeCoffeeVarietyId: '',
    coffeePruningId: '',
    typeCoffeePruningId: '',
    typeCoffeeManagementId: '',
    typeCoffeeHarvestId: '',
    typeCoffeeRenovationId: '',
    name: '',
    yearPlanting: '',
    monthPlanting: '',
    plantFailure: '',
    plantingQuantity: '',
    geographicLocation: '',
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    getValues,
  } = form

  const areaInfo = useGetAreasDashboard()
  const coffeePruningInfo = useGetCoffeesPruningsDashboard()
  const farmFieldPeriodInfo = useGetFarmsFieldsPeriodsDashboard()
  const farmInfo = useGetFarmsDashboard()
  const typeCoffeeColorInfo = useGetTypesCoffeesColorsDashboard()
  const typeCoffeeHarvestInfo = useGetTypesCoffeesHarvestsDashboard()
  const typeCoffeeIrrigationInfo = useGetTypesCoffeesIrrigationsDashboard()
  const typeCoffeeManagementInfo = useGetTypesCoffeesManagementsDashboard()
  const typeCoffeeMaturationInfo = useGetTypesCoffeesMaturationsDashboard()
  const typeCoffeePruningInfo = useGetTypesCoffeesPruningsDashboard()
  const typeCoffeeSizeInfo = useGetTypesCoffeesSizesDashboard()
  const typeCoffeeVarietyInfo = useGetTypesCoffeesVarietiesDashboard()
  const typeCoffeeRenovationInfo = useGetTypesCoffeesRenovationsDashboard()

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    areaInfo,
    coffeePruningInfo,
    farmFieldPeriodInfo,
    farmInfo,
    typeCoffeeColorInfo,
    typeCoffeeHarvestInfo,
    typeCoffeeIrrigationInfo,
    typeCoffeeManagementInfo,
    typeCoffeeMaturationInfo,
    typeCoffeePruningInfo,
    typeCoffeeSizeInfo,
    typeCoffeeVarietyInfo,
    typeCoffeeRenovationInfo,
    register,
    resetFields,
    getValues,
  }
}
