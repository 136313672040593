import { gql } from 'graphql-request'

export const updateUserDeviceInformationDashboardGQL = gql`
  mutation UPDATE_USER_DEVICE_INFORMATION_DASHBOARD(
    $userDeviceInformationId: Int!
    $name: String
    $installationDevice: Boolean
    $manufacturer: String
    $mac: String
    $baseOs: String
    $bootLoader: String
    $brand: String
    $buildNumber: String
    $isCameraPresent: String
    $networkOperator: String
    $deviceId: String
    $deviceName: String
    $ipAddress: String
    $systemVersion: String
    $uniqueId: String
    $userAgent: String
  ) {
    updateUserDeviceInformationDashboard(
      userDeviceInformationId: $userDeviceInformationId
      name: $name
      installationDevice: $installationDevice
      manufacturer: $manufacturer
      mac: $mac
      baseOs: $baseOs
      bootLoader: $bootLoader
      brand: $brand
      buildNumber: $buildNumber
      isCameraPresent: $isCameraPresent
      networkOperator: $networkOperator
      deviceId: $deviceId
      deviceName: $deviceName
      ipAddress: $ipAddress
      systemVersion: $systemVersion
      uniqueId: $uniqueId
      userAgent: $userAgent
    ) {
      error
      status
      data {
        id
        userId
        user {
          id
          firstName
        }
        name
        installationDevice
        manufacturer
        mac
        baseOs
        bootLoader
        brand
        buildNumber
        isCameraPresent
        networkOperator
        deviceId
        deviceName
        ipAddress
        systemVersion
        uniqueId
        userAgent
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteUserDeviceInformationDashboardGQL = gql`
  mutation DELETE_USER_DEVICE_INFORMATION_DASHBOARD(
    $userDeviceInformationId: Int!
    $deleted: Boolean!
  ) {
    deleteUserDeviceInformationDashboard(
      userDeviceInformationId: $userDeviceInformationId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        userId
        name
        installationDevice
        manufacturer
        mac
        baseOs
        bootLoader
        brand
        buildNumber
        isCameraPresent
        networkOperator
        deviceId
        deviceName
        ipAddress
        systemVersion
        uniqueId
        userAgent
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeUserDeviceInformationDashboardGQL = gql`
  mutation ACTIVE_USER_DEVICE_INFORMATION_DASHBOARD(
    $userDeviceInformationId: Int!
    $active: Boolean!
  ) {
    activeUserDeviceInformationDashboard(
      userDeviceInformationId: $userDeviceInformationId
      active: $active
    ) {
      error
      status
      data {
        id
        userId
        name
        installationDevice
        manufacturer
        mac
        baseOs
        bootLoader
        brand
        buildNumber
        isCameraPresent
        networkOperator
        deviceId
        deviceName
        ipAddress
        systemVersion
        uniqueId
        userAgent
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverUserDeviceInformationDashboardGQL = gql`
  mutation DELETE_FOREVER_USER_DEVICE_INFORMATION_DASHBOARD(
    $userDeviceInformationId: Int!
  ) {
    deleteForeverUserDeviceInformationDashboard(
      userDeviceInformationId: $userDeviceInformationId
    )
  }
`

export const getUsersDevicesInformationsDashboardGQL = gql`
  query GET_USERS_DEVICES_INFORMATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $userId: Int
    $name: String
    $installationDevice: Boolean
    $manufacturer: String
    $mac: String
    $baseOs: String
    $bootLoader: String
    $brand: String
    $buildNumber: String
    $isCameraPresent: String
    $networkOperator: String
    $deviceId: String
    $deviceName: String
    $ipAddress: String
    $systemVersion: String
    $uniqueId: String
    $userAgent: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getUsersDevicesInformationsDashboard(
      take: $take
      skip: $skip
      userId: $userId
      name: $name
      installationDevice: $installationDevice
      manufacturer: $manufacturer
      mac: $mac
      baseOs: $baseOs
      bootLoader: $bootLoader
      brand: $brand
      buildNumber: $buildNumber
      isCameraPresent: $isCameraPresent
      networkOperator: $networkOperator
      deviceId: $deviceId
      deviceName: $deviceName
      ipAddress: $ipAddress
      systemVersion: $systemVersion
      uniqueId: $uniqueId
      userAgent: $userAgent
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        userId
        user {
          id
          firstName
        }
        name
        installationDevice
        manufacturer
        mac
        baseOs
        bootLoader
        brand
        buildNumber
        isCameraPresent
        networkOperator
        deviceId
        deviceName
        ipAddress
        systemVersion
        uniqueId
        userAgent
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUserDashboardIdGQL = gql`
  query GET_USER_DASHBOARD_ID($userId: Int!) {
    getUserDashboard(userId: $userId) {
      error
      status
      data {
        id
        userTypeId
        companyId
        farmId
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUsersDashboardGQL = gql`
  query GET_USERS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getUsersDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        userTypeId
        companyId
        farmId
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUsersDevicesInformationsContainsDashboardGQL = gql`
  query GET_USERS_DEVICES_INFORMATIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $manufacturer: String
    $mac: String
    $baseOs: String
    $bootLoader: String
    $brand: String
    $buildNumber: String
    $isCameraPresent: String
    $networkOperator: String
    $deviceId: String
    $deviceName: String
    $ipAddress: String
    $systemVersion: String
    $uniqueId: String
    $userAgent: String
  ) {
    getUsersDevicesInformationsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      manufacturer: $manufacturer
      mac: $mac
      baseOs: $baseOs
      bootLoader: $bootLoader
      brand: $brand
      buildNumber: $buildNumber
      isCameraPresent: $isCameraPresent
      networkOperator: $networkOperator
      deviceId: $deviceId
      deviceName: $deviceName
      ipAddress: $ipAddress
      systemVersion: $systemVersion
      uniqueId: $uniqueId
      userAgent: $userAgent
    ) {
      error
      status
      totalPages
      data {
        id
        userId
        user {
          id
          firstName
        }
        name
        installationDevice
        manufacturer
        mac
        baseOs
        bootLoader
        brand
        buildNumber
        isCameraPresent
        networkOperator
        deviceId
        deviceName
        ipAddress
        systemVersion
        uniqueId
        userAgent
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
