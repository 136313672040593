import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import { updateUserDeviceInformation } from 'services/users/users-devices-informations/users-devices-informations-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectUserDeviceInformation,
} from 'slices/users/users-devices-informations-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('users-devices-informations-name')),

  installationDevice: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('users-devices-informations-installation-device'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  manufacturer: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-manufacturer'),
    ),

  mac: z
    .string()
    .min(1, t('inform-field') + ' ' + t('users-devices-informations-mac')),

  baseOs: z.string(),
  bootLoader: z.string(),
  brand: z
    .string()
    .min(1, t('inform-field') + ' ' + t('users-devices-informations-brand')),

  buildNumber: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-build-number'),
    ),

  isCameraPresent: z.string(),
  networkOperator: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('users-devices-informations-network-operator'),
    ),

  deviceId: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-device-id'),
    ),

  deviceName: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-device-name'),
    ),

  ipAddress: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-ip-address'),
    ),

  systemVersion: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-system-version'),
    ),

  uniqueId: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-unique-id'),
    ),

  userAgent: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-user-agent'),
    ),
})

export type FormData = z.infer<typeof schema>

const userDeviceInformationModalFormSelector = (state: StateRoot) => {
  return state.usersDevicesInformationsReducer
}

export const selectorResult = createSelector(
  userDeviceInformationModalFormSelector,
  (userDeviceInformationReducer) => {
    return userDeviceInformationReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, userDeviceInformationSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('users_devices_informations')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectUserDeviceInformation(null))
    dispatch(closeForm())
  }

  const {
    mutateAsync: updateUserDeviceInformationFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateUserDeviceInformation,
  })

  const cleanFields: FormData = {
    name: '',
    installationDevice: '',
    manufacturer: '',
    mac: '',
    baseOs: '',
    bootLoader: '',
    brand: '',
    buildNumber: '',
    isCameraPresent: '',
    networkOperator: '',
    deviceId: '',
    deviceName: '',
    ipAddress: '',
    systemVersion: '',
    uniqueId: '',
    userAgent: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!userDeviceInformationSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: userDeviceInformationSelected.name,
      installationDevice: `${userDeviceInformationSelected.installationDevice}`,
      manufacturer: userDeviceInformationSelected.manufacturer,
      mac: userDeviceInformationSelected.mac,
      baseOs: userDeviceInformationSelected.baseOs,
      bootLoader: userDeviceInformationSelected.bootLoader,
      brand: userDeviceInformationSelected.brand,
      buildNumber: userDeviceInformationSelected.buildNumber,
      isCameraPresent: userDeviceInformationSelected.isCameraPresent,
      networkOperator: userDeviceInformationSelected.networkOperator,
      deviceId: userDeviceInformationSelected.deviceId,
      deviceName: userDeviceInformationSelected.deviceName,
      ipAddress: userDeviceInformationSelected.ipAddress,
      systemVersion: userDeviceInformationSelected.systemVersion,
      uniqueId: userDeviceInformationSelected.uniqueId,
      userAgent: userDeviceInformationSelected.userAgent,
    }

    reset(userDeviceInformationSelected ? fields : cleanFields)
  }, [userDeviceInformationSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedInstallationDevice = formatToSendData(
        data?.installationDevice,
      )

      const formatedData = {
        name: data?.name,
        installationDevice: formatInputBooleanValue(selectedInstallationDevice),
        manufacturer: data?.manufacturer,
        mac: data?.mac,
        baseOs: data?.baseOs,
        bootLoader: data?.bootLoader,
        brand: data?.brand,
        buildNumber: data?.buildNumber,
        isCameraPresent: data?.isCameraPresent,
        networkOperator: data?.networkOperator,
        deviceId: data?.deviceId,
        deviceName: data?.deviceName,
        ipAddress: data?.ipAddress,
        systemVersion: data?.systemVersion,
        uniqueId: data?.uniqueId,
        userAgent: data?.userAgent,
      }

      if (userDeviceInformationSelected && userDeviceInformationSelected?.id) {
        const { updateUserDeviceInformationDashboard } =
          await updateUserDeviceInformationFn({
            ...formatedData,
            userDeviceInformationId: userDeviceInformationSelected?.id,
          })

        dispatch(editUniqueData(updateUserDeviceInformationDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isLoadingUpdate,
    formOpen,
    userDeviceInformationSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
