import { createSlice } from '@reduxjs/toolkit'
import { AgriculturalInputModeAction } from 'types/agriculturals-inputs/agriculturals-inputs-modes-actions-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  agriculturalInputModeActionSelected: null | AgriculturalInputModeAction
  deleteModalOpen: boolean
  agriculturalInputModeActionIdToDelete: null | string
  data: AgriculturalInputModeAction[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  agriculturalInputModeActionSelected: null,
  agriculturalInputModeActionIdToDelete: null,
  data: [],
  total: 0,
}

const agriculturalInputModeActionSlice = createSlice({
  initialState,
  name: 'agriculturals-inputs-modes-actions-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectAgriculturalInputModeAction(
      state,
      { payload }: { payload: AgriculturalInputModeAction | null },
    ) {
      state.agriculturalInputModeActionSelected = payload
    },
    setData(state, { payload }: { payload: AgriculturalInputModeAction[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: AgriculturalInputModeAction }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(
      state,
      { payload }: { payload: AgriculturalInputModeAction },
    ) {
      state.data = state.data.map((agriculturalInputModeAction) => {
        return agriculturalInputModeAction.id === payload.id
          ? { ...agriculturalInputModeAction, ...payload }
          : agriculturalInputModeAction
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (agriculturalInputModeAction) =>
          +agriculturalInputModeAction.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.agriculturalInputModeActionIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.agriculturalInputModeActionIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectAgriculturalInputModeAction,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = agriculturalInputModeActionSlice.actions
export default agriculturalInputModeActionSlice.reducer
