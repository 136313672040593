import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeCoffeeHarvestDashboardGQL,
  deleteDefaultTypeCoffeeHarvestDashboardGQL,
  deleteForeverDefaultTypeCoffeeHarvestDashboardGQL,
  createDefaultTypeCoffeeHarvestDashboardGQL,
  updateDefaultTypeCoffeeHarvestDashboardGQL,
} from './queries'
import { DefaultTypeCoffeeHarvest } from 'types/defaults/defaults-types-coffees-harvests-types'

interface ResponseCreate {
  createDefaultTypeCoffeeHarvestDashboard: {
    data: DefaultTypeCoffeeHarvest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeCoffeeHarvestDashboard: {
    data: DefaultTypeCoffeeHarvest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeCoffeeHarvestDashboard: {
    data: DefaultTypeCoffeeHarvest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeCoffeeHarvestDashboard: {
    data: DefaultTypeCoffeeHarvest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeCoffeeHarvestDashboard: string
}

interface DataActive {
  defaultTypeCoffeeHarvestId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeCoffeeHarvestId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeCoffeeHarvestId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  defaultTypeCoffeeHarvestId: number
  name: string
  description?: string
}

export const activeDefaultTypeCoffeeHarvest = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeCoffeeHarvestDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeCoffeeHarvest = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeCoffeeHarvestDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeCoffeeHarvest = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeCoffeeHarvestDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeCoffeeHarvest = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeCoffeeHarvestDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeCoffeeHarvest = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeCoffeeHarvestDashboardGQL,
    { ...data },
  )
}
