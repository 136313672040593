import { createSelector } from 'reselect'
import { TypeCoffeeMaturation } from 'types/types-coffees/types-coffees-maturations-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverTypeCoffeeMaturation } from 'services/types-coffees/types-coffees-maturations/types-coffees-maturations-cruds'
import {
  getTypesCoffeesMaturationsDashboard,
  GetParams,
  getTypesCoffeesMaturationsContainsDashboard,
} from 'services/types-coffees/types-coffees-maturations/types-coffees-maturations-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/types-coffees/types-coffees-maturations-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const typeCoffeeMaturationListSelector = (state: StateRoot) =>
  state.typesCoffeesMaturationsReducer

const selectorResult = createSelector(
  typeCoffeeMaturationListSelector,
  (typeCoffeeMaturationListReducer) => {
    return typeCoffeeMaturationListReducer
  },
)

export const useTypeCoffeeMaturationList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('types_coffees_maturations')

  const {
    data: dataTypeCoffeeMaturation,
    deleteModalOpen,
    typeCoffeeMaturationIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'typeCoffeeMaturation', 'list', { params }],
    queryFn: () => getTypesCoffeesMaturationsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverTypeCoffeeMaturation,
  })

  const handleStoreData = useCallback(
    (
      typesCoffeesMaturations: TypeCoffeeMaturation[],
      totalPages: number | null,
    ) => {
      if (!typesCoffeesMaturations) return

      dispatch(setData(typesCoffeesMaturations))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverTypeCoffeeMaturation = useCallback(async () => {
    if (!typeCoffeeMaturationIdToDelete) return

    const response = await mutateAsync({
      typeCoffeeMaturationId: +typeCoffeeMaturationIdToDelete,
    })

    if (
      response?.deleteForeverTypeCoffeeMaturationDashboard === 'deleted_forever'
    ) {
      dispatch(deleteUniqueData({ id: typeCoffeeMaturationIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [typeCoffeeMaturationIdToDelete])

  useEffect(() => {
    const typesCoffeesMaturationsResult =
      data?.getTypesCoffeesMaturationsDashboard

    if (typesCoffeesMaturationsResult) {
      handleStoreData(
        typesCoffeesMaturationsResult?.data,
        typesCoffeesMaturationsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getTypesCoffeesMaturationsContainsDashboard(value)
      const typesCoffeesMaturationsResult =
        response?.getTypesCoffeesMaturationsContainsDashboard

      handleStoreData(
        typesCoffeesMaturationsResult?.data,
        typesCoffeesMaturationsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    typeCoffeeMaturationIdToDelete,
    typeCoffeeMaturation: data?.getTypesCoffeesMaturationsDashboard?.data,
    isLoading,
    isFetching,
    dataTypeCoffeeMaturation,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverTypeCoffeeMaturation,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
