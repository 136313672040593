import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeHarvestForecast,
  deleteHarvestForecast,
} from 'services/harvests/harvests-forecasts/harvests-forecasts-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectHarvestForecast,
} from 'slices/harvests/harvests-forecasts-reducer'
import { HarvestForecast } from 'types/harvests/harvests-forecasts-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('harvests_forecasts')
  const dispatch = useDispatch()
  const item = cell?.row?.original as HarvestForecast

  const handleOpenForm = () => {
    dispatch(selectHarvestForecast(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectHarvestForecast(itemWithoutId as HarvestForecast))
    dispatch(openForm())
  }

  const { mutateAsync: deleteHarvestForecastDashboardFn } = useMutation({
    mutationFn: deleteHarvestForecast,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteHarvestForecastDashboard } =
      await deleteHarvestForecastDashboardFn({
        harvestForecastId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteHarvestForecastDashboard?.data))
  }

  const { mutateAsync: activeHarvestForecastDashboardFn } = useMutation({
    mutationFn: activeHarvestForecast,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeHarvestForecastDashboard } =
      await activeHarvestForecastDashboardFn({
        harvestForecastId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeHarvestForecastDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
