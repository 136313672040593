import { graphClient } from 'services/graph-client'
import {
  User,
  UserDeviceInformation,
} from 'types/users/users-devices-informations-types'

import {
  getUsersDashboardGQL,
  getUsersDevicesInformationsDashboardGQL,
  getUsersDevicesInformationsContainsDashboardGQL,
} from './queries'

interface Response {
  getUsersDevicesInformationsDashboard: {
    data: UserDeviceInformation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getUsersDevicesInformationsContainsDashboard: {
    data: UserDeviceInformation[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  manufacturer?: string
  mac?: string
  baseOs?: string
  bootLoader?: string
  brand?: string
  buildNumber?: string
  isCameraPresent?: string
  networkOperator?: string
  deviceId?: string
  deviceName?: string
  ipAddress?: string
  systemVersion?: string
  uniqueId?: string
  userAgent?: string
}

export interface GetParams {
  page?: number
  userId?: number
  name?: string
  installationDevice?: boolean
  manufacturer?: string
  mac?: string
  baseOs?: string
  bootLoader?: string
  brand?: string
  buildNumber?: string
  isCameraPresent?: string
  networkOperator?: string
  deviceId?: string
  deviceName?: string
  ipAddress?: string
  systemVersion?: string
  uniqueId?: string
  userAgent?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getUsersDevicesInformationsDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getUsersDevicesInformationsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getUsersDevicesInformationsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getUsersDevicesInformationsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getUsersDevicesInformationsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getUsersDevicesInformationsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseUser {
  getUsersDashboard: {
    data: User[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getUsersDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseUser>(getUsersDashboardGQL, {
    take,
    skip,
  })
}
