import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import {
  createTypeCoffeeSize,
  updateTypeCoffeeSize,
} from 'services/types-coffees/types-coffees-sizes/types-coffees-sizes-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectTypeCoffeeSize,
} from 'slices/types-coffees/types-coffees-sizes-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('types-coffees-sizes-name')),

  description: z.string(),
})

export type FormData = z.infer<typeof schema>

const typeCoffeeSizeModalFormSelector = (state: StateRoot) => {
  return state.typesCoffeesSizesReducer
}

export const selectorResult = createSelector(
  typeCoffeeSizeModalFormSelector,
  (typeCoffeeSizeReducer) => {
    return typeCoffeeSizeReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, typeCoffeeSizeSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('types_coffees_sizes')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectTypeCoffeeSize(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createTypeCoffeeSizeFn, isPending } = useMutation({
    mutationFn: createTypeCoffeeSize,
  })

  const { mutateAsync: updateTypeCoffeeSizeFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateTypeCoffeeSize,
    })

  const cleanFields: FormData = {
    name: '',
    description: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!typeCoffeeSizeSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: typeCoffeeSizeSelected.name,
      description: typeCoffeeSizeSelected.description,
    }

    reset(typeCoffeeSizeSelected ? fields : cleanFields)
  }, [typeCoffeeSizeSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        name: data?.name,
        description: data?.description,
      }

      if (typeCoffeeSizeSelected && typeCoffeeSizeSelected?.id) {
        const { updateTypeCoffeeSizeDashboard } = await updateTypeCoffeeSizeFn({
          ...formatedData,
          typeCoffeeSizeId: typeCoffeeSizeSelected?.id,
        })

        dispatch(editUniqueData(updateTypeCoffeeSizeDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createTypeCoffeeSizeFn(formatedData)

      dispatch(pushData(responseData?.createTypeCoffeeSizeDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    typeCoffeeSizeSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
