import { graphClient } from 'services/graph-client'
import {
  activeDefaultAgriculturalInputTypeDashboardGQL,
  deleteDefaultAgriculturalInputTypeDashboardGQL,
  deleteForeverDefaultAgriculturalInputTypeDashboardGQL,
  createDefaultAgriculturalInputTypeDashboardGQL,
  updateDefaultAgriculturalInputTypeDashboardGQL,
} from './queries'
import { DefaultAgriculturalInputType } from 'types/defaults/defaults-agriculturals-inputs-types-types'

interface ResponseCreate {
  createDefaultAgriculturalInputTypeDashboard: {
    data: DefaultAgriculturalInputType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultAgriculturalInputTypeDashboard: {
    data: DefaultAgriculturalInputType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultAgriculturalInputTypeDashboard: {
    data: DefaultAgriculturalInputType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultAgriculturalInputTypeDashboard: {
    data: DefaultAgriculturalInputType
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultAgriculturalInputTypeDashboard: string
}

interface DataActive {
  defaultAgriculturalInputTypeId: number
  active: boolean
}

interface DataDeleted {
  defaultAgriculturalInputTypeId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultAgriculturalInputTypeId: number
}

interface DataCreate {
  name: string
  description: string
}

interface DataUpdate {
  defaultAgriculturalInputTypeId: number
  name: string
  description: string
}

export const activeDefaultAgriculturalInputType = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultAgriculturalInputTypeDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultAgriculturalInputType = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultAgriculturalInputTypeDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultAgriculturalInputType = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultAgriculturalInputTypeDashboardGQL,
    { ...data },
  )
}

export const createDefaultAgriculturalInputType = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultAgriculturalInputTypeDashboardGQL,
    { ...data },
  )
}

export const updateDefaultAgriculturalInputType = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultAgriculturalInputTypeDashboardGQL,
    { ...data },
  )
}
