import { gql } from 'graphql-request'

export const createEmployeeDayWorkedDashboardGQL = gql`
  mutation CREATE_EMPLOYEE_DAY_WORKED_DASHBOARD(
    $farmId: Int
    $farmFieldId: Int
    $employeeId: Int!
    $name: String!
    $dateWorked: String!
    $holiday: Boolean
    $worked: Boolean
    $workedSociety: Boolean
    $workedOtherSociety: Boolean
    $workedOtherSocietyEmployeeId: Int
    $absence: Boolean
    $reasonAbsence: String
  ) {
    createEmployeeDayWorkedDashboard(
      farmId: $farmId
      farmFieldId: $farmFieldId
      employeeId: $employeeId
      name: $name
      dateWorked: $dateWorked
      holiday: $holiday
      worked: $worked
      workedSociety: $workedSociety
      workedOtherSociety: $workedOtherSociety
      workedOtherSocietyEmployeeId: $workedOtherSocietyEmployeeId
      absence: $absence
      reasonAbsence: $reasonAbsence
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        employeeId
        employee {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        name
        dateWorked
        holiday
        worked
        workedSociety
        workedOtherSociety
        absence
        reasonAbsence
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateEmployeeDayWorkedDashboardGQL = gql`
  mutation UPDATE_EMPLOYEE_DAY_WORKED_DASHBOARD(
    $employeeDayWorkedId: Int!
    $farmId: Int
    $farmFieldId: Int
    $employeeId: Int
    $name: String
    $dateWorked: String
    $holiday: Boolean
    $worked: Boolean
    $workedSociety: Boolean
    $workedOtherSociety: Boolean
    $workedOtherSocietyEmployeeId: Int
    $absence: Boolean
    $reasonAbsence: String
  ) {
    updateEmployeeDayWorkedDashboard(
      employeeDayWorkedId: $employeeDayWorkedId
      farmId: $farmId
      farmFieldId: $farmFieldId
      employeeId: $employeeId
      name: $name
      dateWorked: $dateWorked
      holiday: $holiday
      worked: $worked
      workedSociety: $workedSociety
      workedOtherSociety: $workedOtherSociety
      workedOtherSocietyEmployeeId: $workedOtherSocietyEmployeeId
      absence: $absence
      reasonAbsence: $reasonAbsence
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        employeeId
        employee {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        name
        dateWorked
        holiday
        worked
        workedSociety
        workedOtherSociety
        absence
        reasonAbsence
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteEmployeeDayWorkedDashboardGQL = gql`
  mutation DELETE_EMPLOYEE_DAY_WORKED_DASHBOARD(
    $employeeDayWorkedId: Int!
    $deleted: Boolean!
  ) {
    deleteEmployeeDayWorkedDashboard(
      employeeDayWorkedId: $employeeDayWorkedId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        employeeId
        name
        dateWorked
        holiday
        worked
        workedSociety
        workedOtherSociety
        workedOtherSocietyEmployeeId
        absence
        reasonAbsence
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeEmployeeDayWorkedDashboardGQL = gql`
  mutation ACTIVE_EMPLOYEE_DAY_WORKED_DASHBOARD(
    $employeeDayWorkedId: Int!
    $active: Boolean!
  ) {
    activeEmployeeDayWorkedDashboard(
      employeeDayWorkedId: $employeeDayWorkedId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        employeeId
        name
        dateWorked
        holiday
        worked
        workedSociety
        workedOtherSociety
        workedOtherSocietyEmployeeId
        absence
        reasonAbsence
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverEmployeeDayWorkedDashboardGQL = gql`
  mutation DELETE_FOREVER_EMPLOYEE_DAY_WORKED_DASHBOARD(
    $employeeDayWorkedId: Int!
  ) {
    deleteForeverEmployeeDayWorkedDashboard(
      employeeDayWorkedId: $employeeDayWorkedId
    )
  }
`

export const getEmployeesDaysWorkedsDashboardGQL = gql`
  query GET_EMPLOYEES_DAYS_WORKEDS_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $farmFieldId: Int
    $employeeId: Int
    $name: String
    $dateWorked: String
    $holiday: Boolean
    $worked: Boolean
    $workedSociety: Boolean
    $workedOtherSociety: Boolean
    $workedOtherSocietyEmployeeId: Int
    $absence: Boolean
    $reasonAbsence: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getEmployeesDaysWorkedsDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      farmFieldId: $farmFieldId
      employeeId: $employeeId
      name: $name
      dateWorked: $dateWorked
      holiday: $holiday
      worked: $worked
      workedSociety: $workedSociety
      workedOtherSociety: $workedOtherSociety
      workedOtherSocietyEmployeeId: $workedOtherSocietyEmployeeId
      absence: $absence
      reasonAbsence: $reasonAbsence
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        employeeId
        employee {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        name
        dateWorked
        holiday
        worked
        workedSociety
        workedOtherSociety
        absence
        reasonAbsence
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getEmployeeDashboardIdGQL = gql`
  query GET_EMPLOYEE_DASHBOARD_ID($employeeId: Int!) {
    getEmployeeDashboard(employeeId: $employeeId) {
      error
      status
      data {
        id
        companyId
        farmId
        employeeFunctionId
        typeEmployee
        name
        documentOne
        documentTwo
        photo
        monthSalaryCurrency
        monthBonusCurrency
        monthSalaryFamilyCurrency
        daySalaryCurrency
        birthday
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getEmployeesDashboardGQL = gql`
  query GET_EMPLOYEES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getEmployeesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        employeeFunctionId
        typeEmployee
        name
        documentOne
        documentTwo
        photo
        monthSalaryCurrency
        monthBonusCurrency
        monthSalaryFamilyCurrency
        daySalaryCurrency
        birthday
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmFieldDashboardIdGQL = gql`
  query GET_FARM_FIELD_DASHBOARD_ID($farmFieldId: Int!) {
    getFarmFieldDashboard(farmFieldId: $farmFieldId) {
      error
      status
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsFieldsDashboardGQL = gql`
  query GET_FARMS_FIELDS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsFieldsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getEmployeesDaysWorkedsContainsDashboardGQL = gql`
  query GET_EMPLOYEES_DAYS_WORKEDS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $reasonAbsence: String
  ) {
    getEmployeesDaysWorkedsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      reasonAbsence: $reasonAbsence
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        employeeId
        employee {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        name
        dateWorked
        holiday
        worked
        workedSociety
        workedOtherSociety
        absence
        reasonAbsence
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
