import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { InputDecimalComponent } from 'components/common/inputs/input-decimal'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetHarvestsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-harvests-dashboard'
import { useGetOrdersServicesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-orders-services-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  farmFieldInfo: ReturnType<typeof useGetFarmsFieldsDashboard>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  harvestInfo: ReturnType<typeof useGetHarvestsDashboard>
  orderServiceInfo: ReturnType<typeof useGetOrdersServicesDashboard>
  register: UseFormRegister<FormData>
}

export const FormControl = ({
  control,
  errors,
  farmFieldInfo,
  farmInfo,
  harvestInfo,
  orderServiceInfo,
  register,
}: Props) => {
  const permissions = useGetPermissions('harvests_costs_services')
  const { harvestCostServiceSelected } = useSelector(selectorResult)
  const disabled = !!(harvestCostServiceSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmFieldInfo?.farmsFieldsDashboard}
            info={farmFieldInfo}
            label={t('harvests-costs-services-farm-field-id')}
            name="farmFieldId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('harvests-costs-services-farm-id')}
            name="farmId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={harvestInfo?.harvestsDashboard}
            info={harvestInfo}
            label={t('harvests-costs-services-harvest-id')}
            name="harvestId"
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={orderServiceInfo?.ordersServicesDashboard}
            info={orderServiceInfo}
            label={t('harvests-costs-services-order-service-id')}
            name="orderServiceId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('harvests-costs-services-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('harvests-costs-services-description')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="expectedNumberService"
            label={t('harvests-costs-services-expected-number-service')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="numberService"
            label={t('harvests-costs-services-number-service')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.serviceValueApportionment?.message}
            {...register('serviceValueApportionment')}
            label={t('harvests-costs-services-service-value-apportionment')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.valueAgriculturalInput?.message}
            {...register('valueAgriculturalInput')}
            label={t('harvests-costs-services-value-agricultural-input')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
