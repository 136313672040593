import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormDataFilter } from '../modal/filter/use-filter-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'

interface Props {
  control: Control<FormDataFilter>
  errors: FieldErrors<FormDataFilter>
  register: UseFormRegister<FormDataFilter>
}

export const FormFilterControl = ({ control, errors, register }: Props) => {
  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="active"
            label={t('agriculturals-inputs-types-active')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="deleted"
            label={t('agriculturals-inputs-types-deleted')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('agriculturals-inputs-types-name')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('agriculturals-inputs-types-description')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtInitial"
            label={t('agriculturals-inputs-types-created-at-initial')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtFinal"
            label={t('agriculturals-inputs-types-created-at-final')}
          />
        </Col>
      </Row>
    </Container>
  )
}
