import { graphClient } from 'services/graph-client'
import {
  Company,
  TypeTool,
  TypeCoffeeTreatment,
} from 'types/types-coffees/types-coffees-treatments-types'

import {
  getCompaniesDashboardGQL,
  getTypesToolsDashboardGQL,
  getTypesCoffeesTreatmentsDashboardGQL,
  getTypesCoffeesTreatmentsContainsDashboardGQL,
} from './queries'

interface Response {
  getTypesCoffeesTreatmentsDashboard: {
    data: TypeCoffeeTreatment[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getTypesCoffeesTreatmentsContainsDashboard: {
    data: TypeCoffeeTreatment[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  typeToolId?: number
  name?: string
  description?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getTypesCoffeesTreatmentsDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getTypesCoffeesTreatmentsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getTypesCoffeesTreatmentsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getTypesCoffeesTreatmentsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getTypesCoffeesTreatmentsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getTypesCoffeesTreatmentsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseTypeTool {
  getTypesToolsDashboard: {
    data: TypeTool[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesToolsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypeTool>(
    getTypesToolsDashboardGQL,
    { take, skip },
  )
}
