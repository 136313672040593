import { Label } from 'reactstrap'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface Props {
  name: string
  label?: string
  error?: string
  value: string
  disabled?: boolean
  onChange(value: string): void
  handleSelectCountry(country: string): void
}

export const InputPhoneComponent = ({
  error,
  label,
  value,
  disabled,
  handleSelectCountry,
  onChange,
  ...props
}: Props) => {
  const onSelectCountry = (countryCode: string) => {
    handleSelectCountry(countryCode?.toUpperCase())
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Label htmlFor={props.name} className="form-label">
        {label}
      </Label>

      <PhoneInput
        value={value}
        disabled={disabled}
        inputStyle={{
          width: '100%',
          height: '100%',
        }}
        onMount={(value, data: CountryData) => {
          onSelectCountry(data?.countryCode)
        }}
        onChange={(value, data: CountryData) => {
          onSelectCountry(data?.countryCode)
          return onChange(value)
        }}
      />

      {error && <span style={{ color: 'red', fontSize: 12 }}>{error}</span>}
    </div>
  )
}
