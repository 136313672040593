import { graphClient } from 'services/graph-client'
import { DefaultTypeCoffeeVariety } from 'types/defaults/defaults-types-coffees-varieties-types'

import {
  getDefaultsTypesCoffeesVarietiesDashboardGQL,
  getDefaultsTypesCoffeesVarietiesContainsDashboardGQL,
} from './queries'

interface Response {
  getDefaultsTypesCoffeesVarietiesDashboard: {
    data: DefaultTypeCoffeeVariety[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getDefaultsTypesCoffeesVarietiesContainsDashboard: {
    data: DefaultTypeCoffeeVariety[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
  numbering?: string
}

export interface GetParams {
  page?: number
  name?: string
  description?: string
  numbering?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getDefaultsTypesCoffeesVarietiesDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getDefaultsTypesCoffeesVarietiesDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getDefaultsTypesCoffeesVarietiesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getDefaultsTypesCoffeesVarietiesDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getDefaultsTypesCoffeesVarietiesContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getDefaultsTypesCoffeesVarietiesContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}
