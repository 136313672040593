import { createSelector } from 'reselect'
import { HarvestCostAgriculturalInput } from 'types/harvests/harvests-costs-agriculturals-inputs-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverHarvestCostAgriculturalInput } from 'services/harvests/harvests-costs-agriculturals-inputs/harvests-costs-agriculturals-inputs-cruds'
import {
  getHarvestsCostsAgriculturalsInputsDashboard,
  GetParams,
  getHarvestsCostsAgriculturalsInputsContainsDashboard,
} from 'services/harvests/harvests-costs-agriculturals-inputs/harvests-costs-agriculturals-inputs-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/harvests/harvests-costs-agriculturals-inputs-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const harvestCostAgriculturalInputListSelector = (state: StateRoot) =>
  state.harvestsCostsAgriculturalsInputsReducer

const selectorResult = createSelector(
  harvestCostAgriculturalInputListSelector,
  (harvestCostAgriculturalInputListReducer) => {
    return harvestCostAgriculturalInputListReducer
  },
)

export const useHarvestCostAgriculturalInputList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('harvests_costs_agriculturals_inputs')

  const {
    data: dataHarvestCostAgriculturalInput,
    deleteModalOpen,
    harvestCostAgriculturalInputIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'harvestCostAgriculturalInput', 'list', { params }],
    queryFn: () => getHarvestsCostsAgriculturalsInputsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverHarvestCostAgriculturalInput,
  })

  const handleStoreData = useCallback(
    (
      harvestsCostsAgriculturalsInputs: HarvestCostAgriculturalInput[],
      totalPages: number | null,
    ) => {
      if (!harvestsCostsAgriculturalsInputs) return

      dispatch(setData(harvestsCostsAgriculturalsInputs))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverHarvestCostAgriculturalInput =
    useCallback(async () => {
      if (!harvestCostAgriculturalInputIdToDelete) return

      const response = await mutateAsync({
        harvestCostAgriculturalInputId: +harvestCostAgriculturalInputIdToDelete,
      })

      if (
        response?.deleteForeverHarvestCostAgriculturalInputDashboard ===
        'deleted_forever'
      ) {
        dispatch(
          deleteUniqueData({ id: harvestCostAgriculturalInputIdToDelete }),
        )
      }

      dispatch(closeDeleteModal())
    }, [harvestCostAgriculturalInputIdToDelete])

  useEffect(() => {
    const harvestsCostsAgriculturalsInputsResult =
      data?.getHarvestsCostsAgriculturalsInputsDashboard

    if (harvestsCostsAgriculturalsInputsResult) {
      handleStoreData(
        harvestsCostsAgriculturalsInputsResult?.data,
        harvestsCostsAgriculturalsInputsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response =
        await getHarvestsCostsAgriculturalsInputsContainsDashboard(value)
      const harvestsCostsAgriculturalsInputsResult =
        response?.getHarvestsCostsAgriculturalsInputsContainsDashboard

      handleStoreData(
        harvestsCostsAgriculturalsInputsResult?.data,
        harvestsCostsAgriculturalsInputsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    harvestCostAgriculturalInputIdToDelete,
    harvestCostAgriculturalInput:
      data?.getHarvestsCostsAgriculturalsInputsDashboard?.data,
    isLoading,
    isFetching,
    dataHarvestCostAgriculturalInput,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverHarvestCostAgriculturalInput,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
