import { createSlice } from '@reduxjs/toolkit'
import { TypeCoffeeMaturation } from 'types/types-coffees/types-coffees-maturations-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  typeCoffeeMaturationSelected: null | TypeCoffeeMaturation
  deleteModalOpen: boolean
  typeCoffeeMaturationIdToDelete: null | string
  data: TypeCoffeeMaturation[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  typeCoffeeMaturationSelected: null,
  typeCoffeeMaturationIdToDelete: null,
  data: [],
  total: 0,
}

const typeCoffeeMaturationSlice = createSlice({
  initialState,
  name: 'types-coffees-maturations-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectTypeCoffeeMaturation(
      state,
      { payload }: { payload: TypeCoffeeMaturation | null },
    ) {
      state.typeCoffeeMaturationSelected = payload
    },
    setData(state, { payload }: { payload: TypeCoffeeMaturation[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: TypeCoffeeMaturation }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: TypeCoffeeMaturation }) {
      state.data = state.data.map((typeCoffeeMaturation) => {
        return typeCoffeeMaturation.id === payload.id
          ? { ...typeCoffeeMaturation, ...payload }
          : typeCoffeeMaturation
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (typeCoffeeMaturation) => +typeCoffeeMaturation.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.typeCoffeeMaturationIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.typeCoffeeMaturationIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectTypeCoffeeMaturation,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = typeCoffeeMaturationSlice.actions
export default typeCoffeeMaturationSlice.reducer
