import { createSlice } from '@reduxjs/toolkit'
import { DefaultCoffeePruning } from 'types/defaults/defaults-coffees-prunings-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  defaultCoffeePruningSelected: null | DefaultCoffeePruning
  deleteModalOpen: boolean
  defaultCoffeePruningIdToDelete: null | string
  data: DefaultCoffeePruning[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  defaultCoffeePruningSelected: null,
  defaultCoffeePruningIdToDelete: null,
  data: [],
  total: 0,
}

const defaultCoffeePruningSlice = createSlice({
  initialState,
  name: 'defaults-coffees-prunings-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectDefaultCoffeePruning(
      state,
      { payload }: { payload: DefaultCoffeePruning | null },
    ) {
      state.defaultCoffeePruningSelected = payload
    },
    setData(state, { payload }: { payload: DefaultCoffeePruning[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: DefaultCoffeePruning }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: DefaultCoffeePruning }) {
      state.data = state.data.map((defaultCoffeePruning) => {
        return defaultCoffeePruning.id === payload.id
          ? { ...defaultCoffeePruning, ...payload }
          : defaultCoffeePruning
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (defaultCoffeePruning) => +defaultCoffeePruning.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.defaultCoffeePruningIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.defaultCoffeePruningIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectDefaultCoffeePruning,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = defaultCoffeePruningSlice.actions
export default defaultCoffeePruningSlice.reducer
