import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormDataFilter } from '../modal/filter/use-filter-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { ControlledInputPhone } from 'components/controlled-components/controlled-input-phone'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetEmployeesFunctionsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-employees-functions-dashboard'

interface Props {
  control: Control<FormDataFilter>
  errors: FieldErrors<FormDataFilter>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  employeeFunctionInfo: ReturnType<typeof useGetEmployeesFunctionsDashboard>
  register: UseFormRegister<FormDataFilter>
  handleSelectCountry: (country: string) => void
}

export const FormFilterControl = ({
  control,
  errors,
  farmInfo,
  employeeFunctionInfo,
  register,
  handleSelectCountry,
}: Props) => {
  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('employees-farm-id')}
            name="farmId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={employeeFunctionInfo?.employeesFunctionsDashboard}
            info={employeeFunctionInfo}
            label={t('employees-employee-function-id')}
            name="employeeFunctionId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="typeEmployee"
            label={t('employees-type-employee')}
            data={[
              { id: 'registered', name: 'registered' },
              { id: 'contracted', name: 'contracted' },
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="active"
            label={t('employees-active')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="deleted"
            label={t('employees-deleted')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('employees-name')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.documentOne?.message}
            {...register('documentOne')}
            label={t('employees-document-one')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.documentTwo?.message}
            {...register('documentTwo')}
            label={t('employees-document-two')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="birthday"
            label={t('employees-birthday')}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            type="email"
            error={errors?.email?.message}
            {...register('email')}
            label={t('employees-email')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputPhone
            control={control}
            name="phone.phone"
            label={t('employees-phone')}
            handleSelectCountry={handleSelectCountry}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtInitial"
            label={t('employees-created-at-initial')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtFinal"
            label={t('employees-created-at-final')}
          />
        </Col>
      </Row>
    </Container>
  )
}
