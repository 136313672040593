import { gql } from 'graphql-request'

export const createHarvestCostAgriculturalInputDashboardGQL = gql`
  mutation CREATE_HARVEST_COST_AGRICULTURAL_INPUT_DASHBOARD(
    $farmId: Int
    $farmFieldId: Int
    $harvestId: Int!
    $name: String!
    $description: String
    $expectedNumberService: Int
    $numberService: Int
    $serviceValueApportionment: Float
    $valueAgriculturalInput: Float
  ) {
    createHarvestCostAgriculturalInputDashboard(
      farmId: $farmId
      farmFieldId: $farmFieldId
      harvestId: $harvestId
      name: $name
      description: $description
      expectedNumberService: $expectedNumberService
      numberService: $numberService
      serviceValueApportionment: $serviceValueApportionment
      valueAgriculturalInput: $valueAgriculturalInput
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        harvestId
        harvest {
          id
          name
        }
        name
        description
        expectedNumberService
        numberService
        serviceValueApportionment
        valueAgriculturalInput
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateHarvestCostAgriculturalInputDashboardGQL = gql`
  mutation UPDATE_HARVEST_COST_AGRICULTURAL_INPUT_DASHBOARD(
    $harvestCostAgriculturalInputId: Int!
    $farmId: Int
    $farmFieldId: Int
    $harvestId: Int
    $name: String
    $description: String
    $expectedNumberService: Int
    $numberService: Int
    $serviceValueApportionment: Float
    $valueAgriculturalInput: Float
  ) {
    updateHarvestCostAgriculturalInputDashboard(
      harvestCostAgriculturalInputId: $harvestCostAgriculturalInputId
      farmId: $farmId
      farmFieldId: $farmFieldId
      harvestId: $harvestId
      name: $name
      description: $description
      expectedNumberService: $expectedNumberService
      numberService: $numberService
      serviceValueApportionment: $serviceValueApportionment
      valueAgriculturalInput: $valueAgriculturalInput
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        harvestId
        harvest {
          id
          name
        }
        name
        description
        expectedNumberService
        numberService
        serviceValueApportionment
        valueAgriculturalInput
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteHarvestCostAgriculturalInputDashboardGQL = gql`
  mutation DELETE_HARVEST_COST_AGRICULTURAL_INPUT_DASHBOARD(
    $harvestCostAgriculturalInputId: Int!
    $deleted: Boolean!
  ) {
    deleteHarvestCostAgriculturalInputDashboard(
      harvestCostAgriculturalInputId: $harvestCostAgriculturalInputId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        harvestId
        name
        description
        expectedNumberService
        numberService
        serviceValueApportionment
        valueAgriculturalInput
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeHarvestCostAgriculturalInputDashboardGQL = gql`
  mutation ACTIVE_HARVEST_COST_AGRICULTURAL_INPUT_DASHBOARD(
    $harvestCostAgriculturalInputId: Int!
    $active: Boolean!
  ) {
    activeHarvestCostAgriculturalInputDashboard(
      harvestCostAgriculturalInputId: $harvestCostAgriculturalInputId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        harvestId
        name
        description
        expectedNumberService
        numberService
        serviceValueApportionment
        valueAgriculturalInput
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverHarvestCostAgriculturalInputDashboardGQL = gql`
  mutation DELETE_FOREVER_HARVEST_COST_AGRICULTURAL_INPUT_DASHBOARD(
    $harvestCostAgriculturalInputId: Int!
  ) {
    deleteForeverHarvestCostAgriculturalInputDashboard(
      harvestCostAgriculturalInputId: $harvestCostAgriculturalInputId
    )
  }
`

export const getHarvestsCostsAgriculturalsInputsDashboardGQL = gql`
  query GET_HARVESTS_COSTS_AGRICULTURALS_INPUTS_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $farmFieldId: Int
    $harvestId: Int
    $name: String
    $description: String
    $expectedNumberService: Int
    $numberService: Int
    $serviceValueApportionment: Float
    $valueAgriculturalInput: Float
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getHarvestsCostsAgriculturalsInputsDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      farmFieldId: $farmFieldId
      harvestId: $harvestId
      name: $name
      description: $description
      expectedNumberService: $expectedNumberService
      numberService: $numberService
      serviceValueApportionment: $serviceValueApportionment
      valueAgriculturalInput: $valueAgriculturalInput
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        harvestId
        harvest {
          id
          name
        }
        name
        description
        expectedNumberService
        numberService
        serviceValueApportionment
        valueAgriculturalInput
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmFieldDashboardIdGQL = gql`
  query GET_FARM_FIELD_DASHBOARD_ID($farmFieldId: Int!) {
    getFarmFieldDashboard(farmFieldId: $farmFieldId) {
      error
      status
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsFieldsDashboardGQL = gql`
  query GET_FARMS_FIELDS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsFieldsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getHarvestDashboardIdGQL = gql`
  query GET_HARVEST_DASHBOARD_ID($harvestId: Int!) {
    getHarvestDashboard(harvestId: $harvestId) {
      error
      status
      data {
        id
        farmId
        farmFieldId
        typeCultivationId
        typeMeasurementId
        name
        description
        automaticCost
        active
        deleted
        dateHarvestInitial
        dateHarvestFinal
        createdAt
        updatedAt
      }
    }
  }
`

export const getHarvestsDashboardGQL = gql`
  query GET_HARVESTS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getHarvestsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        farmId
        farmFieldId
        typeCultivationId
        typeMeasurementId
        name
        description
        automaticCost
        active
        deleted
        dateHarvestInitial
        dateHarvestFinal
        createdAt
        updatedAt
      }
    }
  }
`

export const getHarvestsCostsAgriculturalsInputsContainsDashboardGQL = gql`
  query GET_HARVESTS_COSTS_AGRICULTURALS_INPUTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getHarvestsCostsAgriculturalsInputsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        harvestId
        harvest {
          id
          name
        }
        name
        description
        expectedNumberService
        numberService
        serviceValueApportionment
        valueAgriculturalInput
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
