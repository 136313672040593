import { graphClient } from 'services/graph-client'
import {
  activeHarvestForecastDashboardGQL,
  deleteHarvestForecastDashboardGQL,
  deleteForeverHarvestForecastDashboardGQL,
  createHarvestForecastDashboardGQL,
  updateHarvestForecastDashboardGQL,
} from './queries'
import { HarvestForecast } from 'types/harvests/harvests-forecasts-types'

interface ResponseCreate {
  createHarvestForecastDashboard: {
    data: HarvestForecast
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateHarvestForecastDashboard: {
    data: HarvestForecast
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeHarvestForecastDashboard: {
    data: HarvestForecast
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteHarvestForecastDashboard: {
    data: HarvestForecast
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverHarvestForecastDashboard: string
}

interface DataActive {
  harvestForecastId: number
  active: boolean
}

interface DataDeleted {
  harvestForecastId: number
  deleted: boolean
}

interface DataDeleteForever {
  harvestForecastId: number
}

interface DataCreate {
  farmId?: number
  farmFieldId?: number
  harvestId: number
  forecastPlant: number
  litersSpentPerBag: number
  bagPerHectare: number
  totalBag: number
  name: string
}

interface DataUpdate {
  harvestForecastId: number
  farmId?: number
  farmFieldId?: number
  harvestId: number
  forecastPlant: number
  litersSpentPerBag: number
  bagPerHectare: number
  totalBag: number
  name: string
}

export const activeHarvestForecast = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeHarvestForecastDashboardGQL,
    { ...data },
  )
}

export const deleteHarvestForecast = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteHarvestForecastDashboardGQL,
    { ...data },
  )
}

export const deleteForeverHarvestForecast = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverHarvestForecastDashboardGQL,
    { ...data },
  )
}

export const createHarvestForecast = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createHarvestForecastDashboardGQL,
    { ...data },
  )
}

export const updateHarvestForecast = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateHarvestForecastDashboardGQL,
    { ...data },
  )
}
