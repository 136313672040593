import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetAreasDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-areas-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  areaInfo: ReturnType<typeof useGetAreasDashboard>
  register: UseFormRegister<FormData>
}

export const FormControl = ({
  control,
  errors,
  farmInfo,
  areaInfo,
  register,
}: Props) => {
  const permissions = useGetPermissions('farms_areas')
  const { farmAreaSelected } = useSelector(selectorResult)
  const disabled = !!(farmAreaSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('farms-areas-farm-id')}
            name="farmId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={areaInfo?.areasDashboard}
            info={areaInfo}
            label={t('farms-areas-area-id')}
            name="areaId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('farms-areas-name')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('farms-areas-description')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
