import { graphClient } from 'services/graph-client'
import { Company, User, Farm, FarmUser } from 'types/farms/farms-users-types'

import {
  getCompaniesDashboardGQL,
  getUsersDashboardGQL,
  getFarmsDashboardGQL,
  getFarmsUsersDashboardGQL,
  getFarmsUsersContainsDashboardGQL,
} from './queries'

interface Response {
  getFarmsUsersDashboard: {
    data: FarmUser[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getFarmsUsersContainsDashboard: {
    data: FarmUser[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
}

export interface GetParams {
  page?: number
  name?: string
  userId?: number
  farmId?: number
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getFarmsUsersDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getFarmsUsersDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getFarmsUsersDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getFarmsUsersDashboardGQL, {
    take,
    skip,
  })
}

export const getFarmsUsersContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getFarmsUsersContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseUser {
  getUsersDashboard: {
    data: User[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getUsersDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseUser>(getUsersDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseFarm {
  getFarmsDashboard: {
    data: Farm[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarm>(getFarmsDashboardGQL, {
    take,
    skip,
  })
}
