import React from 'react'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import NonAuthLayout from '../layouts/non-auth-layout'
import VerticalLayout from '../layouts/index'
import { authProtectedRoutes, publicRoutes } from './all-routes'
import { useAuth } from 'context/use-auth'

export function AuthGuard({ isPrivate }: { isPrivate: boolean }) {
  const { signedIn } = useAuth()

  if (!signedIn && isPrivate) {
    return <Navigate to="/login" replace />
  }

  if (signedIn && !isPrivate) {
    return <Navigate to="/" replace />
  }

  return <Outlet />
}

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route element={<AuthGuard isPrivate={false} />}>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
            />
          ))}
        </Route>

        <Route element={<AuthGuard isPrivate={true} />}>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<VerticalLayout>{route.component}</VerticalLayout>}
              key={idx}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  )
}

export default Index
