import { env } from 'lib/constants/env'
import { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Col } from 'reactstrap'
import {
  Form,
  handleIsEditing,
  handleIsMap,
  handleSelectForm,
  handleSetItemForm,
  handleShowResultPolygon,
} from 'slices/map/map-reducer'

export const Card = memo(({ area }: { area: Form }) => {
  const dispatch = useDispatch()

  const handleGetImagePath = useCallback(() => {
    const paths = area?.paths
      ?.map((path) => `${path.lat},${path.lng}`)
      .join('|')
    const endpoint =
      'https://maps.googleapis.com/maps/api/staticmap?&path=fillcolor:0xffff0033|color:0xffff00|weight:2|'

    return `${endpoint}${paths}&maptype=satellite&size=800x800&key=${env.googleMapApi}`
  }, [area])

  const handleClickArea = () => {
    dispatch(handleSelectForm(area))
    dispatch(handleSetItemForm(area))
    dispatch(handleIsEditing(true))
    dispatch(handleIsMap(true))
    dispatch(handleShowResultPolygon(true))
  }

  return (
    <Col
      onClick={handleClickArea}
      lg={2}
      className="d-flex flex-column"
      style={{ height: 244, cursor: 'pointer' }}
    >
      <div
        className="w-100 h-100 d-flex align-items-end gap-2 text-white rounded-2"
        style={{
          background: `url('${handleGetImagePath()}') center`,
          backgroundSize: 'cover',
        }}
      >
        <div
          className="w-100 px-2 py-1"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
          <span className="d-block">{area?.name}</span>
          <span className="d-block">({area?.hectare} ha)</span>
        </div>
      </div>
    </Col>
  )
})

Card.displayName = 'Card'
