import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import {
  createDefaultAgriculturalInputType,
  updateDefaultAgriculturalInputType,
} from 'services/defaults/defaults-agriculturals-inputs-types/defaults-agriculturals-inputs-types-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectDefaultAgriculturalInputType,
} from 'slices/defaults/defaults-agriculturals-inputs-types-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('defaults-agriculturals-inputs-types-name'),
    ),

  description: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('defaults-agriculturals-inputs-types-description'),
    ),
})

export type FormData = z.infer<typeof schema>

const defaultAgriculturalInputTypeModalFormSelector = (state: StateRoot) => {
  return state.defaultsAgriculturalsInputsTypesReducer
}

export const selectorResult = createSelector(
  defaultAgriculturalInputTypeModalFormSelector,
  (defaultAgriculturalInputTypeReducer) => {
    return defaultAgriculturalInputTypeReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, defaultAgriculturalInputTypeSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('defaults_agriculturals_inputs_types')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectDefaultAgriculturalInputType(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createDefaultAgriculturalInputTypeFn, isPending } =
    useMutation({
      mutationFn: createDefaultAgriculturalInputType,
    })

  const {
    mutateAsync: updateDefaultAgriculturalInputTypeFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateDefaultAgriculturalInputType,
  })

  const cleanFields: FormData = {
    name: '',
    description: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!defaultAgriculturalInputTypeSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: defaultAgriculturalInputTypeSelected.name,
      description: defaultAgriculturalInputTypeSelected.description,
    }

    reset(defaultAgriculturalInputTypeSelected ? fields : cleanFields)
  }, [defaultAgriculturalInputTypeSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        name: data?.name,
        description: data?.description,
      }

      if (
        defaultAgriculturalInputTypeSelected &&
        defaultAgriculturalInputTypeSelected?.id
      ) {
        const { updateDefaultAgriculturalInputTypeDashboard } =
          await updateDefaultAgriculturalInputTypeFn({
            ...formatedData,
            defaultAgriculturalInputTypeId:
              defaultAgriculturalInputTypeSelected?.id,
          })

        dispatch(
          editUniqueData(updateDefaultAgriculturalInputTypeDashboard?.data),
        )
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData =
        await createDefaultAgriculturalInputTypeFn(formatedData)

      dispatch(
        pushData(
          responseData?.createDefaultAgriculturalInputTypeDashboard?.data,
        ),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    defaultAgriculturalInputTypeSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
