import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeEmployeeDayWorked,
  deleteEmployeeDayWorked,
} from 'services/employees/employees-days-workeds/employees-days-workeds-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectEmployeeDayWorked,
} from 'slices/employees/employees-days-workeds-reducer'
import { EmployeeDayWorked } from 'types/employees/employees-days-workeds-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('employees_days_workeds')
  const dispatch = useDispatch()
  const item = cell?.row?.original as EmployeeDayWorked

  const handleOpenForm = () => {
    dispatch(selectEmployeeDayWorked(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectEmployeeDayWorked(itemWithoutId as EmployeeDayWorked))
    dispatch(openForm())
  }

  const { mutateAsync: deleteEmployeeDayWorkedDashboardFn } = useMutation({
    mutationFn: deleteEmployeeDayWorked,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteEmployeeDayWorkedDashboard } =
      await deleteEmployeeDayWorkedDashboardFn({
        employeeDayWorkedId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteEmployeeDayWorkedDashboard?.data))
  }

  const { mutateAsync: activeEmployeeDayWorkedDashboardFn } = useMutation({
    mutationFn: activeEmployeeDayWorked,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeEmployeeDayWorkedDashboard } =
      await activeEmployeeDayWorkedDashboardFn({
        employeeDayWorkedId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeEmployeeDayWorkedDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
