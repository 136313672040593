import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useGetRolesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-roles-dashboard'

const schemaFilter = z.object({
  roleId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-roles-role-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z.string().min(1, t('inform-field') + ' ' + t('users-roles-name')),

  nameIndex: z
    .string()
    .min(1, t('inform-field') + ' ' + t('users-roles-name-index')),

  canMenu: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-roles-can-menu')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canAdd: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-roles-can-add')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canDuplicate: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-roles-can-duplicate')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canEdit: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-roles-can-edit')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canDeleteForever: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('users-roles-can-delete-forever')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canExport: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-roles-can-export')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canImport: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-roles-can-import')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canActive: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-roles-can-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  canDeleted: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-roles-can-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  active: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-roles-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-roles-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    roleId: '',
    name: '',
    nameIndex: '',
    canMenu: '',
    canAdd: '',
    canDuplicate: '',
    canEdit: '',
    canDeleteForever: '',
    canExport: '',
    canImport: '',
    canActive: '',
    canDeleted: '',
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    getValues,
  } = form

  const roleInfo = useGetRolesDashboard()

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    roleInfo,
    register,
    resetFields,
    getValues,
  }
}
