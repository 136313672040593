import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeDefaultTypeCoffeeSize,
  deleteDefaultTypeCoffeeSize,
} from 'services/defaults/defaults-types-coffees-sizes/defaults-types-coffees-sizes-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectDefaultTypeCoffeeSize,
} from 'slices/defaults/defaults-types-coffees-sizes-reducer'
import { DefaultTypeCoffeeSize } from 'types/defaults/defaults-types-coffees-sizes-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('defaults_types_coffees_sizes')
  const dispatch = useDispatch()
  const item = cell?.row?.original as DefaultTypeCoffeeSize

  const handleOpenForm = () => {
    dispatch(selectDefaultTypeCoffeeSize(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(
      selectDefaultTypeCoffeeSize(itemWithoutId as DefaultTypeCoffeeSize),
    )
    dispatch(openForm())
  }

  const { mutateAsync: deleteDefaultTypeCoffeeSizeDashboardFn } = useMutation({
    mutationFn: deleteDefaultTypeCoffeeSize,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteDefaultTypeCoffeeSizeDashboard } =
      await deleteDefaultTypeCoffeeSizeDashboardFn({
        defaultTypeCoffeeSizeId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteDefaultTypeCoffeeSizeDashboard?.data))
  }

  const { mutateAsync: activeDefaultTypeCoffeeSizeDashboardFn } = useMutation({
    mutationFn: activeDefaultTypeCoffeeSize,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeDefaultTypeCoffeeSizeDashboard } =
      await activeDefaultTypeCoffeeSizeDashboardFn({
        defaultTypeCoffeeSizeId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeDefaultTypeCoffeeSizeDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
