import { useInfiniteQuery } from '@tanstack/react-query'
import { getTypesCoffeesManagementsDashboardHook } from 'services/_hooks/types-coffees-managements-gets'

export const useGetTypesCoffeesManagementsDashboard = (take = 4) => {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['get', 'typesCoffeesManagementsDashboard', 'list', 'total'],
      initialPageParam: 0,
      queryFn: ({ pageParam }) => {
        return getTypesCoffeesManagementsDashboardHook(pageParam, take)
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const total =
          lastPage?.getTypesCoffeesManagementsDashboard?.totalPages ?? 4
        const isLastPage = allPages?.length >= total

        if (isLastPage) {
          return null
        }

        return lastPageParam + 1
      },
    })

  const typesCoffeesManagementsDashboard = data?.pages?.flatMap((page) => {
    return page?.getTypesCoffeesManagementsDashboard?.data
  })

  return {
    typesCoffeesManagementsDashboard: typesCoffeesManagementsDashboard ?? [],
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  }
}
