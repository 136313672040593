import { graphClient } from 'services/graph-client'
import {
  activeDefaultAgriculturalInputCultivationDashboardGQL,
  deleteDefaultAgriculturalInputCultivationDashboardGQL,
  deleteForeverDefaultAgriculturalInputCultivationDashboardGQL,
  createDefaultAgriculturalInputCultivationDashboardGQL,
  updateDefaultAgriculturalInputCultivationDashboardGQL,
} from './queries'
import { DefaultAgriculturalInputCultivation } from 'types/defaults/defaults-agriculturals-inputs-cultivations-types'

interface ResponseCreate {
  createDefaultAgriculturalInputCultivationDashboard: {
    data: DefaultAgriculturalInputCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultAgriculturalInputCultivationDashboard: {
    data: DefaultAgriculturalInputCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultAgriculturalInputCultivationDashboard: {
    data: DefaultAgriculturalInputCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultAgriculturalInputCultivationDashboard: {
    data: DefaultAgriculturalInputCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultAgriculturalInputCultivationDashboard: string
}

interface DataActive {
  defaultAgriculturalInputCultivationId: number
  active: boolean
}

interface DataDeleted {
  defaultAgriculturalInputCultivationId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultAgriculturalInputCultivationId: number
}

interface DataCreate {
  agriculturalInputId: number
  typeCultivationId: number
  name: string
}

interface DataUpdate {
  defaultAgriculturalInputCultivationId: number
  agriculturalInputId: number
  typeCultivationId: number
  name: string
}

export const activeDefaultAgriculturalInputCultivation = async (
  data: DataActive,
) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultAgriculturalInputCultivationDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultAgriculturalInputCultivation = async (
  data: DataDeleted,
) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultAgriculturalInputCultivationDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultAgriculturalInputCultivation = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultAgriculturalInputCultivationDashboardGQL,
    { ...data },
  )
}

export const createDefaultAgriculturalInputCultivation = async (
  data: DataCreate,
) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultAgriculturalInputCultivationDashboardGQL,
    { ...data },
  )
}

export const updateDefaultAgriculturalInputCultivation = async (
  data: DataUpdate,
) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultAgriculturalInputCultivationDashboardGQL,
    { ...data },
  )
}
