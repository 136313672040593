import { ComponentProps } from 'react'
import didderLogo from '../../assets/images/didder-logo.svg'

interface Props extends ComponentProps<'div'> {}

export const DidderLogo = ({ style }: Props) => {
  // <img src={didderLogo} style={style} />
  return (
    <div
      style={{
        width: 200,
        height: 200,
        ...style,
        backgroundImage: `url(${didderLogo})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    ></div>
  )
}
