import { gql } from 'graphql-request'

export const createTypeCoffeeSizeDashboardGQL = gql`
  mutation CREATE_TYPE_COFFEE_SIZE_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createTypeCoffeeSizeDashboard(name: $name, description: $description) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateTypeCoffeeSizeDashboardGQL = gql`
  mutation UPDATE_TYPE_COFFEE_SIZE_DASHBOARD(
    $typeCoffeeSizeId: Int!
    $name: String
    $description: String
  ) {
    updateTypeCoffeeSizeDashboard(
      typeCoffeeSizeId: $typeCoffeeSizeId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteTypeCoffeeSizeDashboardGQL = gql`
  mutation DELETE_TYPE_COFFEE_SIZE_DASHBOARD(
    $typeCoffeeSizeId: Int!
    $deleted: Boolean!
  ) {
    deleteTypeCoffeeSizeDashboard(
      typeCoffeeSizeId: $typeCoffeeSizeId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeTypeCoffeeSizeDashboardGQL = gql`
  mutation ACTIVE_TYPE_COFFEE_SIZE_DASHBOARD(
    $typeCoffeeSizeId: Int!
    $active: Boolean!
  ) {
    activeTypeCoffeeSizeDashboard(
      typeCoffeeSizeId: $typeCoffeeSizeId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverTypeCoffeeSizeDashboardGQL = gql`
  mutation DELETE_FOREVER_TYPE_COFFEE_SIZE_DASHBOARD($typeCoffeeSizeId: Int!) {
    deleteForeverTypeCoffeeSizeDashboard(typeCoffeeSizeId: $typeCoffeeSizeId)
  }
`

export const getTypesCoffeesSizesDashboardGQL = gql`
  query GET_TYPES_COFFEES_SIZES_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getTypesCoffeesSizesDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesSizesContainsDashboardGQL = gql`
  query GET_TYPES_COFFEES_SIZES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getTypesCoffeesSizesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
