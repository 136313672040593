import { graphClient } from 'services/graph-client'
import {
  activeAgriculturalInputDashboardGQL,
  deleteAgriculturalInputDashboardGQL,
  deleteForeverAgriculturalInputDashboardGQL,
  createAgriculturalInputDashboardGQL,
  updateAgriculturalInputDashboardGQL,
} from './queries'
import { AgriculturalInput } from 'types/agriculturals-inputs/agriculturals-inputs-types'

interface ResponseCreate {
  createAgriculturalInputDashboard: {
    data: AgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateAgriculturalInputDashboard: {
    data: AgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeAgriculturalInputDashboard: {
    data: AgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteAgriculturalInputDashboard: {
    data: AgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverAgriculturalInputDashboard: string
}

interface DataActive {
  agriculturalInputId: number
  active: boolean
}

interface DataDeleted {
  agriculturalInputId: number
  deleted: boolean
}

interface DataDeleteForever {
  agriculturalInputId: number
}

interface DataCreate {
  agriculturalInputSubTypeId: number
  agriculturalInputModeActionId: number
  name: string
  description: string
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  isPesticide: boolean
  toxicologicalClass?: string
  environmentalClass?: string
  isOrganic: boolean
  isFertilizer: boolean
}

interface DataUpdate {
  agriculturalInputId: number
  agriculturalInputSubTypeId: number
  agriculturalInputModeActionId: number
  name: string
  description: string
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  isPesticide: boolean
  toxicologicalClass?: string
  environmentalClass?: string
  isOrganic: boolean
  isFertilizer: boolean
}

export const activeAgriculturalInput = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const deleteAgriculturalInput = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const deleteForeverAgriculturalInput = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const createAgriculturalInput = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const updateAgriculturalInput = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateAgriculturalInputDashboardGQL,
    { ...data },
  )
}
