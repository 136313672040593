import { gql } from 'graphql-request'

export const createFarmOwnerDashboardGQL = gql`
  mutation CREATE_FARM_OWNER_DASHBOARD(
    $farmId: Int!
    $name: String!
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $priorityContact: Boolean!
  ) {
    createFarmOwnerDashboard(
      farmId: $farmId
      name: $name
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      priorityContact: $priorityContact
    ) {
      error
      status
      data {
        id
        farmId
        farm {
          id
          name
        }
        name
        document
        documentTwo
        documentThree
        email
        phone
        priorityContact
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateFarmOwnerDashboardGQL = gql`
  mutation UPDATE_FARM_OWNER_DASHBOARD(
    $farmOwnerId: Int!
    $farmId: Int
    $name: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $priorityContact: Boolean
  ) {
    updateFarmOwnerDashboard(
      farmOwnerId: $farmOwnerId
      farmId: $farmId
      name: $name
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      priorityContact: $priorityContact
    ) {
      error
      status
      data {
        id
        farmId
        farm {
          id
          name
        }
        name
        document
        documentTwo
        documentThree
        email
        phone
        priorityContact
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteFarmOwnerDashboardGQL = gql`
  mutation DELETE_FARM_OWNER_DASHBOARD($farmOwnerId: Int!, $deleted: Boolean!) {
    deleteFarmOwnerDashboard(farmOwnerId: $farmOwnerId, deleted: $deleted) {
      error
      status
      data {
        id
        farmId
        name
        document
        documentTwo
        documentThree
        email
        phone
        priorityContact
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeFarmOwnerDashboardGQL = gql`
  mutation ACTIVE_FARM_OWNER_DASHBOARD($farmOwnerId: Int!, $active: Boolean!) {
    activeFarmOwnerDashboard(farmOwnerId: $farmOwnerId, active: $active) {
      error
      status
      data {
        id
        farmId
        name
        document
        documentTwo
        documentThree
        email
        phone
        priorityContact
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverFarmOwnerDashboardGQL = gql`
  mutation DELETE_FOREVER_FARM_OWNER_DASHBOARD($farmOwnerId: Int!) {
    deleteForeverFarmOwnerDashboard(farmOwnerId: $farmOwnerId)
  }
`

export const getFarmsOwnersDashboardGQL = gql`
  query GET_FARMS_OWNERS_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $name: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $priorityContact: Boolean
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getFarmsOwnersDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      name: $name
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      priorityContact: $priorityContact
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        farmId
        farm {
          id
          name
        }
        name
        document
        documentTwo
        documentThree
        email
        phone
        priorityContact
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsOwnersContainsDashboardGQL = gql`
  query GET_FARMS_OWNERS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
  ) {
    getFarmsOwnersContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
    ) {
      error
      status
      totalPages
      data {
        id
        farmId
        farm {
          id
          name
        }
        name
        document
        documentTwo
        documentThree
        email
        phone
        priorityContact
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
