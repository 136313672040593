import { useInfiniteQuery } from '@tanstack/react-query'
import { getUsersTypesDashboardHook } from 'services/_hooks/users-types-gets'

export const useGetUsersTypesDashboard = (take = 4) => {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['get', 'usersTypesDashboard', 'list', 'total'],
      initialPageParam: 0,
      queryFn: ({ pageParam }) => {
        return getUsersTypesDashboardHook(pageParam, take)
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const total = lastPage?.getUsersTypesDashboard?.totalPages ?? 4
        const isLastPage = allPages?.length >= total

        if (isLastPage) {
          return null
        }

        return lastPageParam + 1
      },
    })

  const usersTypesDashboard = data?.pages?.flatMap((page) => {
    return page?.getUsersTypesDashboard?.data
  })

  return {
    usersTypesDashboard: usersTypesDashboard ?? [],
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  }
}
