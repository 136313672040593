import { createSelector } from 'reselect'
import { FarmInventory } from 'types/farms/farms-inventories-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverFarmInventory } from 'services/farms/farms-inventories/farms-inventories-cruds'
import {
  getFarmsInventoriesDashboard,
  GetParams,
  getFarmsInventoriesContainsDashboard,
} from 'services/farms/farms-inventories/farms-inventories-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/farms/farms-inventories-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const farmInventoryListSelector = (state: StateRoot) =>
  state.farmsInventoriesReducer

const selectorResult = createSelector(
  farmInventoryListSelector,
  (farmInventoryListReducer) => {
    return farmInventoryListReducer
  },
)

export const useFarmInventoryList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('farms_inventories')

  const {
    data: dataFarmInventory,
    deleteModalOpen,
    farmInventoryIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'farmInventory', 'list', { params }],
    queryFn: () => getFarmsInventoriesDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverFarmInventory,
  })

  const handleStoreData = useCallback(
    (farmsInventories: FarmInventory[], totalPages: number | null) => {
      if (!farmsInventories) return

      dispatch(setData(farmsInventories))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverFarmInventory = useCallback(async () => {
    if (!farmInventoryIdToDelete) return

    const response = await mutateAsync({
      farmInventoryId: +farmInventoryIdToDelete,
    })

    if (response?.deleteForeverFarmInventoryDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: farmInventoryIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [farmInventoryIdToDelete])

  useEffect(() => {
    const farmsInventoriesResult = data?.getFarmsInventoriesDashboard

    if (farmsInventoriesResult) {
      handleStoreData(
        farmsInventoriesResult?.data,
        farmsInventoriesResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getFarmsInventoriesContainsDashboard(value)
      const farmsInventoriesResult =
        response?.getFarmsInventoriesContainsDashboard

      handleStoreData(
        farmsInventoriesResult?.data,
        farmsInventoriesResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    farmInventoryIdToDelete,
    farmInventory: data?.getFarmsInventoriesDashboard?.data,
    isLoading,
    isFetching,
    dataFarmInventory,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverFarmInventory,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
