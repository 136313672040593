import { gql } from 'graphql-request'

export const createDefaultTypeCoffeeRenovationDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_COFFEE_RENOVATION_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createDefaultTypeCoffeeRenovationDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypeCoffeeRenovationDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_COFFEE_RENOVATION_DASHBOARD(
    $defaultTypeCoffeeRenovationId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultTypeCoffeeRenovationDashboard(
      defaultTypeCoffeeRenovationId: $defaultTypeCoffeeRenovationId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypeCoffeeRenovationDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_COFFEE_RENOVATION_DASHBOARD(
    $defaultTypeCoffeeRenovationId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypeCoffeeRenovationDashboard(
      defaultTypeCoffeeRenovationId: $defaultTypeCoffeeRenovationId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypeCoffeeRenovationDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_COFFEE_RENOVATION_DASHBOARD(
    $defaultTypeCoffeeRenovationId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypeCoffeeRenovationDashboard(
      defaultTypeCoffeeRenovationId: $defaultTypeCoffeeRenovationId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypeCoffeeRenovationDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_COFFEE_RENOVATION_DASHBOARD(
    $defaultTypeCoffeeRenovationId: Int!
  ) {
    deleteForeverDefaultTypeCoffeeRenovationDashboard(
      defaultTypeCoffeeRenovationId: $defaultTypeCoffeeRenovationId
    )
  }
`

export const getDefaultsTypesCoffeesRenovationsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_RENOVATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesCoffeesRenovationsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesCoffeesRenovationsContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_RENOVATIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsTypesCoffeesRenovationsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
