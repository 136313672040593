import { graphClient } from 'services/graph-client'
import {
  AgriculturalInput,
  AgriculturalInputSubType,
  Company,
  TypePest,
  AgriculturalInputModeAction,
  AgriculturalInputPest,
} from 'types/agriculturals-inputs/agriculturals-inputs-pests-types'

import {
  getAgriculturalsInputsDashboardGQL,
  getAgriculturalsInputsSubsTypesDashboardGQL,
  getCompaniesDashboardGQL,
  getTypesPestsDashboardGQL,
  getAgriculturalsInputsModesActionsDashboardGQL,
  getAgriculturalsInputsPestsDashboardGQL,
  getAgriculturalsInputsPestsContainsDashboardGQL,
} from './queries'

interface Response {
  getAgriculturalsInputsPestsDashboard: {
    data: AgriculturalInputPest[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getAgriculturalsInputsPestsContainsDashboard: {
    data: AgriculturalInputPest[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  toxicologicalClass?: string
  environmentalClass?: string
}

export interface GetParams {
  page?: number
  agriculturalInputId?: number
  typePestId?: number
  agriculturalInputSubTypeId?: number
  agriculturalInputModeActionId?: number
  name?: string
  description?: string
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  toxicologicalClass?: string
  environmentalClass?: string
  isOrganic?: boolean
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getAgriculturalsInputsPestsDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getAgriculturalsInputsPestsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getAgriculturalsInputsPestsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getAgriculturalsInputsPestsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getAgriculturalsInputsPestsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getAgriculturalsInputsPestsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseAgriculturalInput {
  getAgriculturalsInputsDashboard: {
    data: AgriculturalInput[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAgriculturalsInputsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseAgriculturalInput>(
    getAgriculturalsInputsDashboardGQL,
    { take, skip },
  )
}

interface ResponseAgriculturalInputSubType {
  getAgriculturalsInputsSubsTypesDashboard: {
    data: AgriculturalInputSubType[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAgriculturalsInputsSubsTypesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseAgriculturalInputSubType>(
    getAgriculturalsInputsSubsTypesDashboardGQL,
    { take, skip },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseTypePest {
  getTypesPestsDashboard: {
    data: TypePest[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesPestsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseTypePest>(
    getTypesPestsDashboardGQL,
    { take, skip },
  )
}

interface ResponseAgriculturalInputModeAction {
  getAgriculturalsInputsModesActionsDashboard: {
    data: AgriculturalInputModeAction[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAgriculturalsInputsModesActionsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseAgriculturalInputModeAction>(
    getAgriculturalsInputsModesActionsDashboardGQL,
    { take, skip },
  )
}
