import { useInfiniteQuery } from '@tanstack/react-query'
import { getHarvestsDashboardHook } from 'services/_hooks/harvests-gets'

export const useGetHarvestsDashboard = (take = 4) => {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['get', 'harvestsDashboard', 'list', 'total'],
      initialPageParam: 0,
      queryFn: ({ pageParam }) => {
        return getHarvestsDashboardHook(pageParam, take)
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const total = lastPage?.getHarvestsDashboard?.totalPages ?? 4
        const isLastPage = allPages?.length >= total

        if (isLastPage) {
          return null
        }

        return lastPageParam + 1
      },
    })

  const harvestsDashboard = data?.pages?.flatMap((page) => {
    return page?.getHarvestsDashboard?.data
  })

  return {
    harvestsDashboard: harvestsDashboard ?? [],
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  }
}
