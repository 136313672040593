import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { handleIsEditing, handleIsMap } from 'slices/map/map-reducer'

export const ButtonAddNew = () => {
  const dispatch = useDispatch()

  return (
    <div style={{ bottom: 40, right: 40 }} className="position-absolute">
      <Button
        onClick={() => {
          dispatch(handleIsEditing(false))
          dispatch(handleIsMap(true))
        }}
        style={{
          width: 56,
          height: 56,
          fontSize: 24,
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        }}
        className="rounded-circle"
      >
        +
      </Button>
    </div>
  )
}
