import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import {
  createDefaultAgriculturalInputCultivation,
  updateDefaultAgriculturalInputCultivation,
} from 'services/defaults/defaults-agriculturals-inputs-cultivations/defaults-agriculturals-inputs-cultivations-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectDefaultAgriculturalInputCultivation,
} from 'slices/defaults/defaults-agriculturals-inputs-cultivations-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  agriculturalInputId: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('defaults-agriculturals-inputs-cultivations-agricultural-input-id'),
    ),

  typeCultivationId: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('defaults-agriculturals-inputs-cultivations-type-cultivation-id'),
    ),

  name: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('defaults-agriculturals-inputs-cultivations-name'),
    ),
})

export type FormData = z.infer<typeof schema>

const defaultAgriculturalInputCultivationModalFormSelector = (
  state: StateRoot,
) => {
  return state.defaultsAgriculturalsInputsCultivationsReducer
}

export const selectorResult = createSelector(
  defaultAgriculturalInputCultivationModalFormSelector,
  (defaultAgriculturalInputCultivationReducer) => {
    return defaultAgriculturalInputCultivationReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, defaultAgriculturalInputCultivationSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions(
    'defaults_agriculturals_inputs_cultivations',
  )

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectDefaultAgriculturalInputCultivation(null))
    dispatch(closeForm())
  }

  const {
    mutateAsync: createDefaultAgriculturalInputCultivationFn,
    isPending,
  } = useMutation({
    mutationFn: createDefaultAgriculturalInputCultivation,
  })

  const {
    mutateAsync: updateDefaultAgriculturalInputCultivationFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateDefaultAgriculturalInputCultivation,
  })

  const cleanFields: FormData = {
    agriculturalInputId: '',
    typeCultivationId: '',
    name: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!defaultAgriculturalInputCultivationSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      agriculturalInputId: `${defaultAgriculturalInputCultivationSelected.agriculturalInputId}`,
      typeCultivationId: `${defaultAgriculturalInputCultivationSelected.typeCultivationId}`,
      name: defaultAgriculturalInputCultivationSelected.name,
    }

    reset(defaultAgriculturalInputCultivationSelected ? fields : cleanFields)
  }, [defaultAgriculturalInputCultivationSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        agriculturalInputId: +data?.agriculturalInputId,
        typeCultivationId: +data?.typeCultivationId,
        name: data?.name,
      }

      if (
        defaultAgriculturalInputCultivationSelected &&
        defaultAgriculturalInputCultivationSelected?.id
      ) {
        const { updateDefaultAgriculturalInputCultivationDashboard } =
          await updateDefaultAgriculturalInputCultivationFn({
            ...formatedData,
            defaultAgriculturalInputCultivationId:
              defaultAgriculturalInputCultivationSelected?.id,
          })

        dispatch(
          editUniqueData(
            updateDefaultAgriculturalInputCultivationDashboard?.data,
          ),
        )
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData =
        await createDefaultAgriculturalInputCultivationFn(formatedData)

      dispatch(
        pushData(
          responseData?.createDefaultAgriculturalInputCultivationDashboard
            ?.data,
        ),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    defaultAgriculturalInputCultivationSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
