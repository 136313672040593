import { graphClient } from 'services/graph-client'
import {
  activeOrderServiceDashboardGQL,
  deleteOrderServiceDashboardGQL,
  deleteForeverOrderServiceDashboardGQL,
  createOrderServiceDashboardGQL,
  updateOrderServiceDashboardGQL,
} from './queries'
import { OrderService } from 'types/orders/orders-services-types'

interface ResponseCreate {
  createOrderServiceDashboard: {
    data: OrderService
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateOrderServiceDashboard: {
    data: OrderService
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeOrderServiceDashboard: {
    data: OrderService
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteOrderServiceDashboard: {
    data: OrderService
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverOrderServiceDashboard: string
}

interface DataActive {
  orderServiceId: number
  active: boolean
}

interface DataDeleted {
  orderServiceId: number
  deleted: boolean
}

interface DataDeleteForever {
  orderServiceId: number
}

interface DataCreate {
  farmId?: number
  farmFieldId?: number
  typeCoffeeTreatmentId?: number
  name: string
  dateService: string
  description: string
  optionalDescription?: string
  quantityHectare?: number
  isBrush?: boolean
  isPulverization?: boolean
  isFertilizing?: boolean
}

interface DataUpdate {
  orderServiceId: number
  farmId?: number
  farmFieldId?: number
  typeCoffeeTreatmentId?: number
  name: string
  dateService: string
  description: string
  optionalDescription?: string
  quantityHectare?: number
  isBrush?: boolean
  isPulverization?: boolean
  isFertilizing?: boolean
}

export const activeOrderService = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeOrderServiceDashboardGQL,
    { ...data },
  )
}

export const deleteOrderService = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteOrderServiceDashboardGQL,
    { ...data },
  )
}

export const deleteForeverOrderService = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverOrderServiceDashboardGQL,
    { ...data },
  )
}

export const createOrderService = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createOrderServiceDashboardGQL,
    { ...data },
  )
}

export const updateOrderService = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateOrderServiceDashboardGQL,
    { ...data },
  )
}
