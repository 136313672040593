import { gql } from 'graphql-request'

export const createDefaultFarmFieldPeriodDashboardGQL = gql`
  mutation CREATE_DEFAULT_FARM_FIELD_PERIOD_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createDefaultFarmFieldPeriodDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultFarmFieldPeriodDashboardGQL = gql`
  mutation UPDATE_DEFAULT_FARM_FIELD_PERIOD_DASHBOARD(
    $defaultFarmFieldPeriodId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultFarmFieldPeriodDashboard(
      defaultFarmFieldPeriodId: $defaultFarmFieldPeriodId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultFarmFieldPeriodDashboardGQL = gql`
  mutation DELETE_DEFAULT_FARM_FIELD_PERIOD_DASHBOARD(
    $defaultFarmFieldPeriodId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultFarmFieldPeriodDashboard(
      defaultFarmFieldPeriodId: $defaultFarmFieldPeriodId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultFarmFieldPeriodDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_FARM_FIELD_PERIOD_DASHBOARD(
    $defaultFarmFieldPeriodId: Int!
    $active: Boolean!
  ) {
    activeDefaultFarmFieldPeriodDashboard(
      defaultFarmFieldPeriodId: $defaultFarmFieldPeriodId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultFarmFieldPeriodDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_FARM_FIELD_PERIOD_DASHBOARD(
    $defaultFarmFieldPeriodId: Int!
  ) {
    deleteForeverDefaultFarmFieldPeriodDashboard(
      defaultFarmFieldPeriodId: $defaultFarmFieldPeriodId
    )
  }
`

export const getDefaultsFarmsFieldsPeriodsDashboardGQL = gql`
  query GET_DEFAULTS_FARMS_FIELDS_PERIODS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsFarmsFieldsPeriodsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsFarmsFieldsPeriodsContainsDashboardGQL = gql`
  query GET_DEFAULTS_FARMS_FIELDS_PERIODS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsFarmsFieldsPeriodsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
