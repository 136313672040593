import { Label } from 'reactstrap'
import { ComponentPropsWithRef, forwardRef } from 'react'

interface Props extends ComponentPropsWithRef<'input'> {
  label?: string
  error?: string
}

export const InputComponent = forwardRef<HTMLInputElement, Props>(
  ({ error, label, ...props }, ref) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Label htmlFor={props.name} className="form-label">
          {label}
        </Label>

        <input className="form-control" {...props} ref={ref} />

        {error && <span style={{ color: 'red', fontSize: 12 }}>{error}</span>}
      </div>
    )
  },
)

InputComponent.displayName = 'InputComponent'
