import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useGetAgriculturalsInputsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-dashboard'
import { useGetAgriculturalsInputsSubsTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-subs-types-dashboard'
import { useGetTypesPestsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-pests-dashboard'
import { useGetAgriculturalsInputsModesActionsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-modes-actions-dashboard'

const schemaFilter = z.object({
  agriculturalInputId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-pests-agricultural-input-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typePestId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('agriculturals-inputs-pests-type-pest-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  agriculturalInputSubTypeId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-pests-agricultural-input-sub-type-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  agriculturalInputModeActionId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-pests-agricultural-input-mode-action-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-pests-name')),

  description: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('agriculturals-inputs-pests-description'),
    ),

  tradeMark: z.string(),
  formulation: z.string(),
  activeIngredient: z.string(),
  toxicologicalClass: z.string(),
  environmentalClass: z.string(),
  isOrganic: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('agriculturals-inputs-pests-is-organic'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  active: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('agriculturals-inputs-pests-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('agriculturals-inputs-pests-deleted'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    agriculturalInputId: '',
    typePestId: '',
    agriculturalInputSubTypeId: '',
    agriculturalInputModeActionId: '',
    name: '',
    description: '',
    tradeMark: '',
    formulation: '',
    activeIngredient: '',
    toxicologicalClass: '',
    environmentalClass: '',
    isOrganic: '',
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    getValues,
  } = form

  const agriculturalInputInfo = useGetAgriculturalsInputsDashboard()
  const agriculturalInputSubTypeInfo =
    useGetAgriculturalsInputsSubsTypesDashboard()
  const typePestInfo = useGetTypesPestsDashboard()
  const agriculturalInputModeActionInfo =
    useGetAgriculturalsInputsModesActionsDashboard()

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    agriculturalInputInfo,
    agriculturalInputSubTypeInfo,
    typePestInfo,
    agriculturalInputModeActionInfo,
    register,
    resetFields,
    getValues,
  }
}
