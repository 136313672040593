import { gql } from 'graphql-request'

export const createCompanyDashboardGQL = gql`
  mutation CREATE_COMPANY_DASHBOARD(
    $codeUid: String!
    $name: String!
    $description: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $country: String!
    $hectareQuantity: Float!
    $usefullHectareQuantity: Float!
  ) {
    createCompanyDashboard(
      codeUid: $codeUid
      name: $name
      description: $description
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      country: $country
      hectareQuantity: $hectareQuantity
      usefullHectareQuantity: $usefullHectareQuantity
    ) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateCompanyDashboardGQL = gql`
  mutation UPDATE_COMPANY_DASHBOARD(
    $companyId: Int!
    $codeUid: String
    $name: String
    $description: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $country: String
    $hectareQuantity: Float
    $usefullHectareQuantity: Float
  ) {
    updateCompanyDashboard(
      companyId: $companyId
      codeUid: $codeUid
      name: $name
      description: $description
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      country: $country
      hectareQuantity: $hectareQuantity
      usefullHectareQuantity: $usefullHectareQuantity
    ) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteCompanyDashboardGQL = gql`
  mutation DELETE_COMPANY_DASHBOARD($companyId: Int!, $deleted: Boolean!) {
    deleteCompanyDashboard(companyId: $companyId, deleted: $deleted) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeCompanyDashboardGQL = gql`
  mutation ACTIVE_COMPANY_DASHBOARD($companyId: Int!, $active: Boolean!) {
    activeCompanyDashboard(companyId: $companyId, active: $active) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverCompanyDashboardGQL = gql`
  mutation DELETE_FOREVER_COMPANY_DASHBOARD($companyId: Int!) {
    deleteForeverCompanyDashboard(companyId: $companyId)
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $codeUid: String
    $name: String
    $description: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $country: String
    $hectareQuantity: Float
    $usefullHectareQuantity: Float
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      codeUid: $codeUid
      name: $name
      description: $description
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      country: $country
      hectareQuantity: $hectareQuantity
      usefullHectareQuantity: $usefullHectareQuantity
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesContainsDashboardGQL = gql`
  query GET_COMPANIES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $codeUid: String
    $name: String
    $description: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $country: String
  ) {
    getCompaniesContainsDashboard(
      take: $take
      skip: $skip
      codeUid: $codeUid
      name: $name
      description: $description
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      country: $country
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
