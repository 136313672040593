import { graphClient } from 'services/graph-client'
import { EmployeeFunction } from 'types/employees/employees-functions-types'

import { getEmployeesFunctionsDashboardGQL } from 'services/employees/employees-functions/queries'

interface Response {
  getEmployeesFunctionsDashboard: {
    data: EmployeeFunction[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getEmployeesFunctionsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getEmployeesFunctionsDashboardGQL,
    {
      take,
      skip,
    },
  )
}
