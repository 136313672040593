import { gql } from 'graphql-request'

export const createFarmFieldPeriodDashboardGQL = gql`
  mutation CREATE_FARM_FIELD_PERIOD_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createFarmFieldPeriodDashboard(name: $name, description: $description) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateFarmFieldPeriodDashboardGQL = gql`
  mutation UPDATE_FARM_FIELD_PERIOD_DASHBOARD(
    $farmFieldPeriodId: Int!
    $name: String
    $description: String
  ) {
    updateFarmFieldPeriodDashboard(
      farmFieldPeriodId: $farmFieldPeriodId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteFarmFieldPeriodDashboardGQL = gql`
  mutation DELETE_FARM_FIELD_PERIOD_DASHBOARD(
    $farmFieldPeriodId: Int!
    $deleted: Boolean!
  ) {
    deleteFarmFieldPeriodDashboard(
      farmFieldPeriodId: $farmFieldPeriodId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeFarmFieldPeriodDashboardGQL = gql`
  mutation ACTIVE_FARM_FIELD_PERIOD_DASHBOARD(
    $farmFieldPeriodId: Int!
    $active: Boolean!
  ) {
    activeFarmFieldPeriodDashboard(
      farmFieldPeriodId: $farmFieldPeriodId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverFarmFieldPeriodDashboardGQL = gql`
  mutation DELETE_FOREVER_FARM_FIELD_PERIOD_DASHBOARD(
    $farmFieldPeriodId: Int!
  ) {
    deleteForeverFarmFieldPeriodDashboard(farmFieldPeriodId: $farmFieldPeriodId)
  }
`

export const getFarmsFieldsPeriodsDashboardGQL = gql`
  query GET_FARMS_FIELDS_PERIODS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getFarmsFieldsPeriodsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsFieldsPeriodsContainsDashboardGQL = gql`
  query GET_FARMS_FIELDS_PERIODS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getFarmsFieldsPeriodsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
