import { createSlice } from '@reduxjs/toolkit'
import { TypeTool } from 'types/types-coffees/types-tools-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  typeToolSelected: null | TypeTool
  deleteModalOpen: boolean
  typeToolIdToDelete: null | string
  data: TypeTool[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  typeToolSelected: null,
  typeToolIdToDelete: null,
  data: [],
  total: 0,
}

const typeToolSlice = createSlice({
  initialState,
  name: 'types-tools-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectTypeTool(state, { payload }: { payload: TypeTool | null }) {
      state.typeToolSelected = payload
    },
    setData(state, { payload }: { payload: TypeTool[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: TypeTool }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: TypeTool }) {
      state.data = state.data.map((typeTool) => {
        return typeTool.id === payload.id
          ? { ...typeTool, ...payload }
          : typeTool
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter((typeTool) => +typeTool.id !== +payload.id)
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.typeToolIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.typeToolIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectTypeTool,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = typeToolSlice.actions
export default typeToolSlice.reducer
