import { graphClient } from 'services/graph-client'
import {
  activeRoleDashboardGQL,
  deleteRoleDashboardGQL,
  deleteForeverRoleDashboardGQL,
  createRoleDashboardGQL,
  updateRoleDashboardGQL,
} from './queries'
import { Role } from 'types/users/roles-types'

interface ResponseCreate {
  createRoleDashboard: {
    data: Role
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateRoleDashboard: {
    data: Role
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeRoleDashboard: {
    data: Role
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteRoleDashboard: {
    data: Role
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverRoleDashboard: string
}

interface DataActive {
  roleId: number
  active: boolean
}

interface DataDeleted {
  roleId: number
  deleted: boolean
}

interface DataDeleteForever {
  roleId: number
}

interface DataCreate {
  userTypeId: number
  name: string
  description: string
  nameIndex: string
  canMenu: boolean
  canAdd: boolean
  canDuplicate: boolean
  canEdit: boolean
  canDeleteForever: boolean
  canExport: boolean
  canImport: boolean
  canActive: boolean
  canDeleted: boolean
}

interface DataUpdate {
  roleId: number
  userTypeId: number
  name: string
  description: string
  nameIndex: string
  canMenu: boolean
  canAdd: boolean
  canDuplicate: boolean
  canEdit: boolean
  canDeleteForever: boolean
  canExport: boolean
  canImport: boolean
  canActive: boolean
  canDeleted: boolean
}

export const activeRole = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeRoleDashboardGQL, {
    ...data,
  })
}

export const deleteRole = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteRoleDashboardGQL, {
    ...data,
  })
}

export const deleteForeverRole = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverRoleDashboardGQL,
    { ...data },
  )
}

export const createRole = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(createRoleDashboardGQL, {
    ...data,
  })
}

export const updateRole = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateRoleDashboardGQL, {
    ...data,
  })
}
