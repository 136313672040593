import { gql } from 'graphql-request'

export const createFarmCultivationDashboardGQL = gql`
  mutation CREATE_FARM_CULTIVATION_DASHBOARD(
    $farmId: Int
    $typeCultivationId: Int!
    $name: String!
  ) {
    createFarmCultivationDashboard(
      farmId: $farmId
      typeCultivationId: $typeCultivationId
      name: $name
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCultivationId
        typeCultivation {
          id
          name
        }
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateFarmCultivationDashboardGQL = gql`
  mutation UPDATE_FARM_CULTIVATION_DASHBOARD(
    $farmCultivationId: Int!
    $farmId: Int
    $typeCultivationId: Int
    $name: String
  ) {
    updateFarmCultivationDashboard(
      farmCultivationId: $farmCultivationId
      farmId: $farmId
      typeCultivationId: $typeCultivationId
      name: $name
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCultivationId
        typeCultivation {
          id
          name
        }
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteFarmCultivationDashboardGQL = gql`
  mutation DELETE_FARM_CULTIVATION_DASHBOARD(
    $farmCultivationId: Int!
    $deleted: Boolean!
  ) {
    deleteFarmCultivationDashboard(
      farmCultivationId: $farmCultivationId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        typeCultivationId
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeFarmCultivationDashboardGQL = gql`
  mutation ACTIVE_FARM_CULTIVATION_DASHBOARD(
    $farmCultivationId: Int!
    $active: Boolean!
  ) {
    activeFarmCultivationDashboard(
      farmCultivationId: $farmCultivationId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        typeCultivationId
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverFarmCultivationDashboardGQL = gql`
  mutation DELETE_FOREVER_FARM_CULTIVATION_DASHBOARD($farmCultivationId: Int!) {
    deleteForeverFarmCultivationDashboard(farmCultivationId: $farmCultivationId)
  }
`

export const getFarmsCultivationsDashboardGQL = gql`
  query GET_FARMS_CULTIVATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $typeCultivationId: Int
    $name: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getFarmsCultivationsDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      typeCultivationId: $typeCultivationId
      name: $name
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCultivationId
        typeCultivation {
          id
          name
        }
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCultivationDashboardIdGQL = gql`
  query GET_TYPE_CULTIVATION_DASHBOARD_ID($typeCultivationId: Int!) {
    getTypeCultivationDashboard(typeCultivationId: $typeCultivationId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCultivationsDashboardGQL = gql`
  query GET_TYPES_CULTIVATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCultivationsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsCultivationsContainsDashboardGQL = gql`
  query GET_FARMS_CULTIVATIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
  ) {
    getFarmsCultivationsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCultivationId
        typeCultivation {
          id
          name
        }
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
