import { gql } from 'graphql-request'

export const createDefaultTypeExpenseDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_EXPENSE_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createDefaultTypeExpenseDashboard(name: $name, description: $description) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypeExpenseDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_EXPENSE_DASHBOARD(
    $defaultTypeExpenseId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultTypeExpenseDashboard(
      defaultTypeExpenseId: $defaultTypeExpenseId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypeExpenseDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_EXPENSE_DASHBOARD(
    $defaultTypeExpenseId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypeExpenseDashboard(
      defaultTypeExpenseId: $defaultTypeExpenseId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypeExpenseDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_EXPENSE_DASHBOARD(
    $defaultTypeExpenseId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypeExpenseDashboard(
      defaultTypeExpenseId: $defaultTypeExpenseId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypeExpenseDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_EXPENSE_DASHBOARD(
    $defaultTypeExpenseId: Int!
  ) {
    deleteForeverDefaultTypeExpenseDashboard(
      defaultTypeExpenseId: $defaultTypeExpenseId
    )
  }
`

export const getDefaultsTypesExpensesDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_EXPENSES_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesExpensesDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesExpensesContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_EXPENSES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsTypesExpensesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
