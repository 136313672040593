import { createSlice } from '@reduxjs/toolkit'
import { TypeCoffeeVariety } from 'types/types-coffees/types-coffees-varieties-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  typeCoffeeVarietySelected: null | TypeCoffeeVariety
  deleteModalOpen: boolean
  typeCoffeeVarietyIdToDelete: null | string
  data: TypeCoffeeVariety[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  typeCoffeeVarietySelected: null,
  typeCoffeeVarietyIdToDelete: null,
  data: [],
  total: 0,
}

const typeCoffeeVarietySlice = createSlice({
  initialState,
  name: 'types-coffees-varieties-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectTypeCoffeeVariety(
      state,
      { payload }: { payload: TypeCoffeeVariety | null },
    ) {
      state.typeCoffeeVarietySelected = payload
    },
    setData(state, { payload }: { payload: TypeCoffeeVariety[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: TypeCoffeeVariety }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: TypeCoffeeVariety }) {
      state.data = state.data.map((typeCoffeeVariety) => {
        return typeCoffeeVariety.id === payload.id
          ? { ...typeCoffeeVariety, ...payload }
          : typeCoffeeVariety
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (typeCoffeeVariety) => +typeCoffeeVariety.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.typeCoffeeVarietyIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.typeCoffeeVarietyIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectTypeCoffeeVariety,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = typeCoffeeVarietySlice.actions
export default typeCoffeeVarietySlice.reducer
