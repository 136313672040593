import { graphClient } from 'services/graph-client'
import { DefaultAgriculturalInputType } from 'types/defaults/defaults-agriculturals-inputs-types-types'

import {
  getDefaultsAgriculturalsInputsTypesDashboardGQL,
  getDefaultsAgriculturalsInputsTypesContainsDashboardGQL,
} from './queries'

interface Response {
  getDefaultsAgriculturalsInputsTypesDashboard: {
    data: DefaultAgriculturalInputType[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getDefaultsAgriculturalsInputsTypesContainsDashboard: {
    data: DefaultAgriculturalInputType[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  name?: string
  description?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getDefaultsAgriculturalsInputsTypesDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getDefaultsAgriculturalsInputsTypesDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getDefaultsAgriculturalsInputsTypesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getDefaultsAgriculturalsInputsTypesDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getDefaultsAgriculturalsInputsTypesContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getDefaultsAgriculturalsInputsTypesContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}
