import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeCoffeeColorDashboardGQL,
  deleteDefaultTypeCoffeeColorDashboardGQL,
  deleteForeverDefaultTypeCoffeeColorDashboardGQL,
  createDefaultTypeCoffeeColorDashboardGQL,
  updateDefaultTypeCoffeeColorDashboardGQL,
} from './queries'
import { DefaultTypeCoffeeColor } from 'types/defaults/defaults-types-coffees-colors-types'

interface ResponseCreate {
  createDefaultTypeCoffeeColorDashboard: {
    data: DefaultTypeCoffeeColor
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeCoffeeColorDashboard: {
    data: DefaultTypeCoffeeColor
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeCoffeeColorDashboard: {
    data: DefaultTypeCoffeeColor
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeCoffeeColorDashboard: {
    data: DefaultTypeCoffeeColor
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeCoffeeColorDashboard: string
}

interface DataActive {
  defaultTypeCoffeeColorId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeCoffeeColorId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeCoffeeColorId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  defaultTypeCoffeeColorId: number
  name: string
  description?: string
}

export const activeDefaultTypeCoffeeColor = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeCoffeeColorDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeCoffeeColor = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeCoffeeColorDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeCoffeeColor = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeCoffeeColorDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeCoffeeColor = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeCoffeeColorDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeCoffeeColor = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeCoffeeColorDashboardGQL,
    { ...data },
  )
}
