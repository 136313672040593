import { createSelector } from 'reselect'
import { DefaultTypeCoffeePruning } from 'types/defaults/defaults-types-coffees-prunings-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverDefaultTypeCoffeePruning } from 'services/defaults/defaults-types-coffees-prunings/defaults-types-coffees-prunings-cruds'
import {
  getDefaultsTypesCoffeesPruningsDashboard,
  GetParams,
  getDefaultsTypesCoffeesPruningsContainsDashboard,
} from 'services/defaults/defaults-types-coffees-prunings/defaults-types-coffees-prunings-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/defaults/defaults-types-coffees-prunings-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const defaultTypeCoffeePruningListSelector = (state: StateRoot) =>
  state.defaultsTypesCoffeesPruningsReducer

const selectorResult = createSelector(
  defaultTypeCoffeePruningListSelector,
  (defaultTypeCoffeePruningListReducer) => {
    return defaultTypeCoffeePruningListReducer
  },
)

export const useDefaultTypeCoffeePruningList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('defaults_types_coffees_prunings')

  const {
    data: dataDefaultTypeCoffeePruning,
    deleteModalOpen,
    defaultTypeCoffeePruningIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'defaultTypeCoffeePruning', 'list', { params }],
    queryFn: () => getDefaultsTypesCoffeesPruningsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverDefaultTypeCoffeePruning,
  })

  const handleStoreData = useCallback(
    (
      defaultsTypesCoffeesPrunings: DefaultTypeCoffeePruning[],
      totalPages: number | null,
    ) => {
      if (!defaultsTypesCoffeesPrunings) return

      dispatch(setData(defaultsTypesCoffeesPrunings))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverDefaultTypeCoffeePruning = useCallback(async () => {
    if (!defaultTypeCoffeePruningIdToDelete) return

    const response = await mutateAsync({
      defaultTypeCoffeePruningId: +defaultTypeCoffeePruningIdToDelete,
    })

    if (
      response?.deleteForeverDefaultTypeCoffeePruningDashboard ===
      'deleted_forever'
    ) {
      dispatch(deleteUniqueData({ id: defaultTypeCoffeePruningIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [defaultTypeCoffeePruningIdToDelete])

  useEffect(() => {
    const defaultsTypesCoffeesPruningsResult =
      data?.getDefaultsTypesCoffeesPruningsDashboard

    if (defaultsTypesCoffeesPruningsResult) {
      handleStoreData(
        defaultsTypesCoffeesPruningsResult?.data,
        defaultsTypesCoffeesPruningsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response =
        await getDefaultsTypesCoffeesPruningsContainsDashboard(value)
      const defaultsTypesCoffeesPruningsResult =
        response?.getDefaultsTypesCoffeesPruningsContainsDashboard

      handleStoreData(
        defaultsTypesCoffeesPruningsResult?.data,
        defaultsTypesCoffeesPruningsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    defaultTypeCoffeePruningIdToDelete,
    defaultTypeCoffeePruning:
      data?.getDefaultsTypesCoffeesPruningsDashboard?.data,
    isLoading,
    isFetching,
    dataDefaultTypeCoffeePruning,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverDefaultTypeCoffeePruning,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
