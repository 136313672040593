import { t } from 'i18next'
import React from 'react'
import ReactPaginate from 'react-paginate'

interface Props {
  total: number
  className?: string
  handleFetchMore(page: number): void
}

const Component = ({ className, total, handleFetchMore }: Props) => {
  return (
    <ReactPaginate
      pageCount={total}
      breakLabel="..."
      nextLabel={t('next')}
      previousLabel={t('previous')}
      className={`pagination pagination-alternative ${className}`}
      onPageChange={(event) => {
        const page = event.selected
        handleFetchMore(page)
      }}
      activeClassName="pagination-active"
      disabledClassName="pagination-disabled"
      renderOnZeroPageCount={null}
    />
  )
}

export const PaginanteComponent = React.memo(Component)
