import { createSlice } from '@reduxjs/toolkit'

const areas = localStorage.getItem('@areas')

export interface Form {
  id: number
  name: string
  hectare?: string
  paths?: { lat: number; lng: number }[]
}

interface InitialState {
  areas: Form[]
  forms: Form[]
  isMap: boolean
  formAreaSelected: Form | null
  showResultPolygon: boolean
  isAreaControlOpen: boolean
  isEditing: boolean
  isCreating: boolean
}

const initialState: InitialState = {
  forms: [],
  areas: areas ? JSON.parse(areas ?? '') : [],
  isMap: false,
  formAreaSelected: null,
  showResultPolygon: false,
  isAreaControlOpen: true,
  isEditing: false,
  isCreating: false,
}

const mapSlice = createSlice({
  name: 'map-slice',
  initialState,
  reducers: {
    handleSetItemForm: (state, { payload }: { payload: Form }) => {
      state.forms = [payload]
    },
    handleClearFormsInputs: (state) => {
      state.forms = [{ id: Math.random(), name: '' }]
    },
    handleAddForm: (state) => {
      state.forms = state.forms.concat([{ id: Math.random(), name: '' }])
    },
    handleRemoveForm: (state, { payload }: { payload: number }) => {
      state.forms = state.forms.filter((form) => form.id !== payload)
    },
    handleSetArea: (state, { payload }: { payload: Form }) => {
      const areas = state.areas
      const checkIndex = areas.findIndex((area) => area.id === payload.id)

      if (checkIndex !== -1) {
        areas.splice(checkIndex, 1, payload)
        state.areas = areas
      } else {
        state.areas = areas.concat(payload)
      }
    },
    handleEditArea: (state, { payload }: { payload: Form }) => {
      state.areas = state.areas.map((area) => {
        return area.id === payload.id ? payload : area
      })
    },
    handleRemoveArea: (state, { payload }: { payload: Form }) => {
      state.areas = state.areas.filter((area) => area.id !== payload.id)
    },
    handleIsMap: (state, { payload }: { payload: boolean }) => {
      state.isMap = payload
    },
    handleSelectForm: (state, { payload }: { payload: Form }) => {
      state.formAreaSelected = payload
    },
    handleClearForm: (state) => {
      state.formAreaSelected = null
    },
    handleAreaControlOpen: (state, { payload }: { payload: boolean }) => {
      state.isAreaControlOpen = payload
    },
    handleShowResultPolygon: (state, { payload }: { payload: boolean }) => {
      state.showResultPolygon = payload
    },
    handleIsEditing: (state, { payload }: { payload: boolean }) => {
      state.isEditing = payload
    },
    handleIsCreating: (state, { payload }: { payload: boolean }) => {
      state.isCreating = payload
    },
    handleSetAreaInfo: (
      state,
      {
        payload: { paths, hectare },
      }: { payload: { paths: google.maps.LatLng[]; hectare: string } },
    ) => {
      if (state.formAreaSelected) {
        const obj = {
          ...state.formAreaSelected,
          paths: paths.map((path) => ({ lat: path.lat(), lng: path.lng() })),
          hectare,
        }

        state.formAreaSelected = { ...state.formAreaSelected, ...obj }
        state.forms = state.forms.map((form) => {
          return form.id === obj.id ? { ...form, ...obj } : form
        })
      }
    },
  },
})

export const {
  handleSelectForm,
  handleSetAreaInfo,
  handleAreaControlOpen,
  handleShowResultPolygon,
  handleIsEditing,
  handleIsCreating,
  handleClearForm,
  handleIsMap,
  handleSetArea,
  handleEditArea,
  handleRemoveArea,
  handleAddForm,
  handleRemoveForm,
  handleSetItemForm,
  handleClearFormsInputs,
} = mapSlice.actions

export default mapSlice.reducer
