import cn from 'classnames'
import { t } from 'i18next'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { ReactNode } from 'react'
import { Col, Offcanvas, OffcanvasBody, Row } from 'reactstrap'
import SimpleBar from 'simplebar-react'

interface Props {
  isOpen: boolean
  children: ReactNode
  title: string
  subtitle?: string
  full?: boolean
  middle?: boolean
  cancelLabel?: string
  isLoading?: boolean
  hideOptions?: boolean
  permissions?: {
    canEdit: boolean
  }
  handleConfirm: () => void
  handleClose: () => void
  handleCancel?: () => void
}

export const ModalFormTemplate = ({
  isOpen,
  children,
  title,
  subtitle,
  full = true,
  middle = false,
  cancelLabel = 'form-cancel',
  hideOptions = false,
  permissions,
  handleConfirm,
  handleClose,
  handleCancel,
}: Props) => {
  const sidebarWidth = document?.querySelector('#sidebar-left')?.clientWidth
  const isMobile = useIsMobile()

  return (
    <Offcanvas
      isOpen={true}
      backdrop={false}
      fade
      direction="end"
      id="form-modal"
      className={cn('offcanvas-end border-0 d-flex overflow-hidden ', {
        'transition-end': !isOpen,
      })}
      style={{
        width: full ? `calc(100% - ${sidebarWidth}px)` : middle ? '50%' : '24%',
      }}
      toggle={handleClose}
    >
      <OffcanvasBody
        style={{ width: '100%' }}
        className="offcanvas-body profile-offcanvas overflow-hidden "
      >
        <div className="mt-4 pb-4 border-bottom border-bottom-dashed">
          <Row>
            <Col className="col">
              <div className="">
                <button
                  type="button"
                  className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"
                  onClick={handleClose}
                >
                  <i className="ri-close-fill align-bottom"></i>
                </button>

                <span className="ms-4" style={{ fontSize: 16 }}>
                  {title}
                  {subtitle && ` - ${subtitle}`}
                </span>
              </div>
            </Col>

            {full && (
              <Col
                className={cn('col-auto d-none d-lg-flex ', {
                  'me-5': full,
                  'me-1': !full,
                })}
              >
                <div className="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link"></div>
              </Col>
            )}
          </Row>
        </div>

        <SimpleBar className="p-2" style={{ height: '100%' }}>
          {children}
        </SimpleBar>

        {isMobile && !hideOptions && (
          <div
            className={cn(
              'd-flex justify-content-end w-50 gap-2 mt-2 position-absolute',
            )}
            style={{ bottom: '2%', right: '5%' }}
          >
            <button
              type="button"
              onClick={handleCancel ?? handleClose}
              style={{ width: '50%' }}
              className={cn('btn btn-outline-danger')}
            >
              {t(cancelLabel)}
            </button>

            {!permissions && (
              <button
                onClick={handleConfirm}
                type="submit"
                style={{ width: '50%' }}
                className={cn('btn btn-primary')}
              >
                {t('form-confirm')}
              </button>
            )}

            {permissions && !!permissions?.canEdit && (
              <button
                onClick={handleConfirm}
                type="submit"
                style={{ width: '50%' }}
                className={cn('btn btn-primary')}
              >
                {t('form-confirm')}
              </button>
            )}
          </div>
        )}

        {!isMobile && !hideOptions && (
          <div
            className={cn(
              'd-flex justify-content-end w-50 gap-2 mt-2 position-absolute',
              {
                'w-100 justify-content-center': !full,
              },
            )}
            style={{ bottom: '5%', right: full ? '2%' : '0' }}
          >
            <button
              type="button"
              onClick={handleCancel ?? handleClose}
              style={{ width: full ? '25%' : '40%' }}
              className={cn('btn btn-outline-danger')}
            >
              {t(cancelLabel)}
            </button>

            {!permissions && (
              <button
                onClick={handleConfirm}
                type="submit"
                style={{ width: full ? '25%' : '40%' }}
                className={cn('btn btn-primary')}
              >
                {t('form-confirm')}
              </button>
            )}

            {permissions && !!permissions?.canEdit && (
              <button
                onClick={handleConfirm}
                type="submit"
                style={{ width: full ? '25%' : '40%' }}
                className={cn('btn btn-primary')}
              >
                {t('form-confirm')}
              </button>
            )}
          </div>
        )}
      </OffcanvasBody>
    </Offcanvas>
  )
}
