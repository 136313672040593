import { ModalFormTemplate } from 'components/common/modal/form-template'
import { t } from 'i18next'
import { useModalForm } from './use-form'
import { FormControl } from '../components/form'
import { FormProvider } from 'react-hook-form'

export const Form = () => {
  const {
    formOpen,
    farmFieldSelected,
    errors,
    control,
    form,
    areaInfo,
    coffeePruningInfo,
    farmFieldPeriodInfo,
    farmInfo,
    typeCoffeeColorInfo,
    typeCoffeeHarvestInfo,
    typeCoffeeIrrigationInfo,
    typeCoffeeManagementInfo,
    typeCoffeeMaturationInfo,
    typeCoffeePruningInfo,
    typeCoffeeSizeInfo,
    typeCoffeeVarietyInfo,
    typeCoffeeRenovationInfo,
    isLoading,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
  } = useModalForm()

  const canEdit = farmFieldSelected
    ? farmFieldSelected && permissions.canEdit
    : true

  return (
    <ModalFormTemplate
      isOpen={formOpen}
      title={t('farms-fields-title')}
      subtitle={
        farmFieldSelected && farmFieldSelected?.id
          ? t('editing')
          : t('creating')
      }
      isLoading={isLoading}
      permissions={{ canEdit: !!canEdit }}
      handleConfirm={handleSubmit}
      handleClose={handleCloseModal}
    >
      <form className="mt-4 d-flex flex-wrap gap-3">
        <FormProvider {...form}>
          <FormControl
            control={control}
            errors={errors}
            areaInfo={areaInfo}
            coffeePruningInfo={coffeePruningInfo}
            farmFieldPeriodInfo={farmFieldPeriodInfo}
            farmInfo={farmInfo}
            typeCoffeeColorInfo={typeCoffeeColorInfo}
            typeCoffeeHarvestInfo={typeCoffeeHarvestInfo}
            typeCoffeeIrrigationInfo={typeCoffeeIrrigationInfo}
            typeCoffeeManagementInfo={typeCoffeeManagementInfo}
            typeCoffeeMaturationInfo={typeCoffeeMaturationInfo}
            typeCoffeePruningInfo={typeCoffeePruningInfo}
            typeCoffeeSizeInfo={typeCoffeeSizeInfo}
            typeCoffeeVarietyInfo={typeCoffeeVarietyInfo}
            typeCoffeeRenovationInfo={typeCoffeeRenovationInfo}
            register={register}
          />
        </FormProvider>
      </form>
    </ModalFormTemplate>
  )
}
