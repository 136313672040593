import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeOrderService,
  deleteOrderService,
} from 'services/orders/orders-services/orders-services-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectOrderService,
} from 'slices/orders/orders-services-reducer'
import { OrderService } from 'types/orders/orders-services-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('orders_services')
  const dispatch = useDispatch()
  const item = cell?.row?.original as OrderService

  const handleOpenForm = () => {
    dispatch(selectOrderService(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectOrderService(itemWithoutId as OrderService))
    dispatch(openForm())
  }

  const { mutateAsync: deleteOrderServiceDashboardFn } = useMutation({
    mutationFn: deleteOrderService,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteOrderServiceDashboard } = await deleteOrderServiceDashboardFn(
      {
        orderServiceId: +item?.id,
        deleted: !item?.deleted,
      },
    )

    dispatch(editUniqueData(deleteOrderServiceDashboard?.data))
  }

  const { mutateAsync: activeOrderServiceDashboardFn } = useMutation({
    mutationFn: activeOrderService,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeOrderServiceDashboard } = await activeOrderServiceDashboardFn(
      {
        orderServiceId: +item?.id,
        active: !item?.active,
      },
    )

    dispatch(editUniqueData(activeOrderServiceDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
