import { createSelector } from 'reselect'
import { FarmArea } from 'types/farms/farms-areas-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverFarmArea } from 'services/farms/farms-areas/farms-areas-cruds'
import {
  getFarmsAreasDashboard,
  GetParams,
  getFarmsAreasContainsDashboard,
} from 'services/farms/farms-areas/farms-areas-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/farms/farms-areas-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const farmAreaListSelector = (state: StateRoot) => state.farmsAreasReducer

const selectorResult = createSelector(
  farmAreaListSelector,
  (farmAreaListReducer) => {
    return farmAreaListReducer
  },
)

export const useFarmAreaList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('farms_areas')

  const {
    data: dataFarmArea,
    deleteModalOpen,
    farmAreaIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'farmArea', 'list', { params }],
    queryFn: () => getFarmsAreasDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverFarmArea,
  })

  const handleStoreData = useCallback(
    (farmsAreas: FarmArea[], totalPages: number | null) => {
      if (!farmsAreas) return

      dispatch(setData(farmsAreas))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverFarmArea = useCallback(async () => {
    if (!farmAreaIdToDelete) return

    const response = await mutateAsync({
      farmAreaId: +farmAreaIdToDelete,
    })

    if (response?.deleteForeverFarmAreaDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: farmAreaIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [farmAreaIdToDelete])

  useEffect(() => {
    const farmsAreasResult = data?.getFarmsAreasDashboard

    if (farmsAreasResult) {
      handleStoreData(farmsAreasResult?.data, farmsAreasResult?.totalPages)
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getFarmsAreasContainsDashboard(value)
      const farmsAreasResult = response?.getFarmsAreasContainsDashboard

      handleStoreData(farmsAreasResult?.data, farmsAreasResult?.totalPages)
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    farmAreaIdToDelete,
    farmArea: data?.getFarmsAreasDashboard?.data,
    isLoading,
    isFetching,
    dataFarmArea,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverFarmArea,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
