import { useInfiniteQuery } from '@tanstack/react-query'
import { getAgriculturalsInputsSubsTypesDashboardHook } from 'services/_hooks/agriculturals-inputs-subs-types-gets'

export const useGetAgriculturalsInputsSubsTypesDashboard = (take = 4) => {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: [
        'get',
        'agriculturalsInputsSubsTypesDashboard',
        'list',
        'total',
      ],
      initialPageParam: 0,
      queryFn: ({ pageParam }) => {
        return getAgriculturalsInputsSubsTypesDashboardHook(pageParam, take)
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const total =
          lastPage?.getAgriculturalsInputsSubsTypesDashboard?.totalPages ?? 4
        const isLastPage = allPages?.length >= total

        if (isLastPage) {
          return null
        }

        return lastPageParam + 1
      },
    })

  const agriculturalsInputsSubsTypesDashboard = data?.pages?.flatMap((page) => {
    return page?.getAgriculturalsInputsSubsTypesDashboard?.data
  })

  return {
    agriculturalsInputsSubsTypesDashboard:
      agriculturalsInputsSubsTypesDashboard ?? [],
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  }
}
