import { graphClient } from 'services/graph-client'
import { AgriculturalInput } from 'types/agriculturals-inputs/agriculturals-inputs-types'

import { getAgriculturalsInputsDashboardGQL } from 'services/agriculturals-inputs/agriculturals-inputs/queries'

interface Response {
  getAgriculturalsInputsDashboard: {
    data: AgriculturalInput[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAgriculturalsInputsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getAgriculturalsInputsDashboardGQL,
    {
      take,
      skip,
    },
  )
}
