import { gql } from 'graphql-request'

export const createTypeCoffeeTreatmentDashboardGQL = gql`
  mutation CREATE_TYPE_COFFEE_TREATMENT_DASHBOARD(
    $typeToolId: Int!
    $name: String!
    $description: String
  ) {
    createTypeCoffeeTreatmentDashboard(
      typeToolId: $typeToolId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        typeToolId
        typeTool {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateTypeCoffeeTreatmentDashboardGQL = gql`
  mutation UPDATE_TYPE_COFFEE_TREATMENT_DASHBOARD(
    $typeCoffeeTreatmentId: Int!
    $typeToolId: Int
    $name: String
    $description: String
  ) {
    updateTypeCoffeeTreatmentDashboard(
      typeCoffeeTreatmentId: $typeCoffeeTreatmentId
      typeToolId: $typeToolId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        typeToolId
        typeTool {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteTypeCoffeeTreatmentDashboardGQL = gql`
  mutation DELETE_TYPE_COFFEE_TREATMENT_DASHBOARD(
    $typeCoffeeTreatmentId: Int!
    $deleted: Boolean!
  ) {
    deleteTypeCoffeeTreatmentDashboard(
      typeCoffeeTreatmentId: $typeCoffeeTreatmentId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        typeToolId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeTypeCoffeeTreatmentDashboardGQL = gql`
  mutation ACTIVE_TYPE_COFFEE_TREATMENT_DASHBOARD(
    $typeCoffeeTreatmentId: Int!
    $active: Boolean!
  ) {
    activeTypeCoffeeTreatmentDashboard(
      typeCoffeeTreatmentId: $typeCoffeeTreatmentId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        typeToolId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverTypeCoffeeTreatmentDashboardGQL = gql`
  mutation DELETE_FOREVER_TYPE_COFFEE_TREATMENT_DASHBOARD(
    $typeCoffeeTreatmentId: Int!
  ) {
    deleteForeverTypeCoffeeTreatmentDashboard(
      typeCoffeeTreatmentId: $typeCoffeeTreatmentId
    )
  }
`

export const getTypesCoffeesTreatmentsDashboardGQL = gql`
  query GET_TYPES_COFFEES_TREATMENTS_DASHBOARD(
    $take: Int
    $skip: Int
    $typeToolId: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getTypesCoffeesTreatmentsDashboard(
      take: $take
      skip: $skip
      typeToolId: $typeToolId
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        typeToolId
        typeTool {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeToolDashboardIdGQL = gql`
  query GET_TYPE_TOOL_DASHBOARD_ID($typeToolId: Int!) {
    getTypeToolDashboard(typeToolId: $typeToolId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesToolsDashboardGQL = gql`
  query GET_TYPES_TOOLS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesToolsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesTreatmentsContainsDashboardGQL = gql`
  query GET_TYPES_COFFEES_TREATMENTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getTypesCoffeesTreatmentsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        typeToolId
        typeTool {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
