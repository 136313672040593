import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeTypeMeasurement,
  deleteTypeMeasurement,
} from 'services/types-coffees/types-measurements/types-measurements-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectTypeMeasurement,
} from 'slices/types-coffees/types-measurements-reducer'
import { TypeMeasurement } from 'types/types-coffees/types-measurements-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('types_measurements')
  const dispatch = useDispatch()
  const item = cell?.row?.original as TypeMeasurement

  const handleOpenForm = () => {
    dispatch(selectTypeMeasurement(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectTypeMeasurement(itemWithoutId as TypeMeasurement))
    dispatch(openForm())
  }

  const { mutateAsync: deleteTypeMeasurementDashboardFn } = useMutation({
    mutationFn: deleteTypeMeasurement,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteTypeMeasurementDashboard } =
      await deleteTypeMeasurementDashboardFn({
        typeMeasurementId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteTypeMeasurementDashboard?.data))
  }

  const { mutateAsync: activeTypeMeasurementDashboardFn } = useMutation({
    mutationFn: activeTypeMeasurement,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeTypeMeasurementDashboard } =
      await activeTypeMeasurementDashboardFn({
        typeMeasurementId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeTypeMeasurementDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
