import { graphClient } from 'services/graph-client'
import {
  Company,
  User,
  AppLog,
  TypeAppLogType,
} from 'types/settings/apps-logs-types'

import {
  getCompaniesDashboardGQL,
  getUsersDashboardGQL,
  getAppsLogsDashboardGQL,
  getAppsLogsContainsDashboardGQL,
} from './queries'

interface Response {
  getAppsLogsDashboard: {
    data: AppLog[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getAppsLogsContainsDashboard: {
    data: AppLog[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  table?: string
  oldJson?: string
  newJson?: string
}

export interface GetParams {
  page?: number
  name?: string
  userId?: number
  typeAppLogType?: TypeAppLogType
  table?: string
  tableIndex?: number
  oldJson?: string
  newJson?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getAppsLogsDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getAppsLogsDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getAppsLogsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getAppsLogsDashboardGQL, {
    take,
    skip,
  })
}

export const getAppsLogsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getAppsLogsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseUser {
  getUsersDashboard: {
    data: User[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getUsersDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseUser>(getUsersDashboardGQL, {
    take,
    skip,
  })
}
