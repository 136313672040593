import { createSlice } from '@reduxjs/toolkit'
import { Harvest } from 'types/harvests/harvests-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  harvestSelected: null | Harvest
  deleteModalOpen: boolean
  harvestIdToDelete: null | string
  data: Harvest[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  harvestSelected: null,
  harvestIdToDelete: null,
  data: [],
  total: 0,
}

const harvestSlice = createSlice({
  initialState,
  name: 'harvests-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectHarvest(state, { payload }: { payload: Harvest | null }) {
      state.harvestSelected = payload
    },
    setData(state, { payload }: { payload: Harvest[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: Harvest }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: Harvest }) {
      state.data = state.data.map((harvest) => {
        return harvest.id === payload.id ? { ...harvest, ...payload } : harvest
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter((harvest) => +harvest.id !== +payload.id)
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.harvestIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.harvestIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectHarvest,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = harvestSlice.actions
export default harvestSlice.reducer
