import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeDefaultAgriculturalInputPest,
  deleteDefaultAgriculturalInputPest,
} from 'services/defaults/defaults-agriculturals-inputs-pests/defaults-agriculturals-inputs-pests-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectDefaultAgriculturalInputPest,
} from 'slices/defaults/defaults-agriculturals-inputs-pests-reducer'
import { DefaultAgriculturalInputPest } from 'types/defaults/defaults-agriculturals-inputs-pests-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('defaults_agriculturals_inputs_pests')
  const dispatch = useDispatch()
  const item = cell?.row?.original as DefaultAgriculturalInputPest

  const handleOpenForm = () => {
    dispatch(selectDefaultAgriculturalInputPest(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(
      selectDefaultAgriculturalInputPest(
        itemWithoutId as DefaultAgriculturalInputPest,
      ),
    )
    dispatch(openForm())
  }

  const { mutateAsync: deleteDefaultAgriculturalInputPestDashboardFn } =
    useMutation({
      mutationFn: deleteDefaultAgriculturalInputPest,
    })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteDefaultAgriculturalInputPestDashboard } =
      await deleteDefaultAgriculturalInputPestDashboardFn({
        defaultAgriculturalInputPestId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteDefaultAgriculturalInputPestDashboard?.data))
  }

  const { mutateAsync: activeDefaultAgriculturalInputPestDashboardFn } =
    useMutation({
      mutationFn: activeDefaultAgriculturalInputPest,
    })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeDefaultAgriculturalInputPestDashboard } =
      await activeDefaultAgriculturalInputPestDashboardFn({
        defaultAgriculturalInputPestId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeDefaultAgriculturalInputPestDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
