import { gql } from 'graphql-request'

export const createDefaultTypeCoffeeHarvestDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_COFFEE_HARVEST_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createDefaultTypeCoffeeHarvestDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypeCoffeeHarvestDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_COFFEE_HARVEST_DASHBOARD(
    $defaultTypeCoffeeHarvestId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultTypeCoffeeHarvestDashboard(
      defaultTypeCoffeeHarvestId: $defaultTypeCoffeeHarvestId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypeCoffeeHarvestDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_COFFEE_HARVEST_DASHBOARD(
    $defaultTypeCoffeeHarvestId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypeCoffeeHarvestDashboard(
      defaultTypeCoffeeHarvestId: $defaultTypeCoffeeHarvestId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypeCoffeeHarvestDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_COFFEE_HARVEST_DASHBOARD(
    $defaultTypeCoffeeHarvestId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypeCoffeeHarvestDashboard(
      defaultTypeCoffeeHarvestId: $defaultTypeCoffeeHarvestId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypeCoffeeHarvestDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_COFFEE_HARVEST_DASHBOARD(
    $defaultTypeCoffeeHarvestId: Int!
  ) {
    deleteForeverDefaultTypeCoffeeHarvestDashboard(
      defaultTypeCoffeeHarvestId: $defaultTypeCoffeeHarvestId
    )
  }
`

export const getDefaultsTypesCoffeesHarvestsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_HARVESTS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesCoffeesHarvestsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesCoffeesHarvestsContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_HARVESTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsTypesCoffeesHarvestsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
