import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import {
  createFarmArea,
  updateFarmArea,
} from 'services/farms/farms-areas/farms-areas-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectFarmArea,
} from 'slices/farms/farms-areas-reducer'
import { z } from 'zod'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetAreasDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-areas-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z.string().min(1, t('inform-field') + ' ' + t('farms-areas-name')),

  description: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-areas-description')),

  farmId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-areas-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  areaId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('farms-areas-area-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),
})

export type FormData = z.infer<typeof schema>

const farmAreaModalFormSelector = (state: StateRoot) => {
  return state.farmsAreasReducer
}

export const selectorResult = createSelector(
  farmAreaModalFormSelector,
  (farmAreaReducer) => {
    return farmAreaReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, farmAreaSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('farms_areas')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const farmInfo = useGetFarmsDashboard()
  const areaInfo = useGetAreasDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectFarmArea(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createFarmAreaFn, isPending } = useMutation({
    mutationFn: createFarmArea,
  })

  const { mutateAsync: updateFarmAreaFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateFarmArea,
    })

  const cleanFields: FormData = {
    name: '',
    description: '',
    farmId: '',
    areaId: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!farmAreaSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: farmAreaSelected.name,
      description: farmAreaSelected.description,
      farmId: formatSelectValueToFields(farmAreaSelected.farm, ['id', 'name']),
      areaId: formatSelectValueToFields(farmAreaSelected.area, ['id', 'name']),
    }

    reset(farmAreaSelected ? fields : cleanFields)
  }, [farmAreaSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedArea = +formatToSendData(data?.areaId)

      const formatedData = {
        name: data?.name,
        description: data?.description,
        farmId: selectedFarm,
        areaId: selectedArea,
      }

      if (farmAreaSelected && farmAreaSelected?.id) {
        const { updateFarmAreaDashboard } = await updateFarmAreaFn({
          ...formatedData,
          farmAreaId: farmAreaSelected?.id,
        })

        dispatch(editUniqueData(updateFarmAreaDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createFarmAreaFn(formatedData)

      dispatch(pushData(responseData?.createFarmAreaDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    farmAreaSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    farmInfo,
    areaInfo,
  }
}
