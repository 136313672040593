import { Status } from './list-columns'
import { t } from 'i18next'

export const columns = [
  {
    Header: t('id'),
    accessor: 'id',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span className="fw-medium link-primary">{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-user-id'),
    accessor: 'user.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-name'),
    accessor: 'name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-installation-device'),
    accessor: 'installationDevice',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('users-devices-informations-manufacturer'),
    accessor: 'manufacturer',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-mac'),
    accessor: 'mac',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-base-os'),
    accessor: 'baseOs',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-boot-loader'),
    accessor: 'bootLoader',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-brand'),
    accessor: 'brand',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-build-number'),
    accessor: 'buildNumber',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-is-camera-present'),
    accessor: 'isCameraPresent',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-network-operator'),
    accessor: 'networkOperator',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-device-id'),
    accessor: 'deviceId',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-device-name'),
    accessor: 'deviceName',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-ip-address'),
    accessor: 'ipAddress',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-system-version'),
    accessor: 'systemVersion',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-unique-id'),
    accessor: 'uniqueId',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('users-devices-informations-user-agent'),
    accessor: 'userAgent',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('active'),
    accessor: 'actions',
    filterable: false,
    Cell: (props: any, cellProps: any) => {
      return <Status {...cellProps} {...props} showActive={true} />
    },
  },
]
