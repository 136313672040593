import { graphClient } from 'services/graph-client'
import { TypeCoffeeVariety } from 'types/types-coffees/types-coffees-varieties-types'

import { getTypesCoffeesVarietiesDashboardGQL } from 'services/types-coffees/types-coffees-varieties/queries'

interface Response {
  getTypesCoffeesVarietiesDashboard: {
    data: TypeCoffeeVariety[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesVarietiesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getTypesCoffeesVarietiesDashboardGQL,
    {
      take,
      skip,
    },
  )
}
