import { createSelector } from 'reselect'
import { ExampleOtherTwo } from 'types/examples-tables/examples-others-twos-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverExampleOtherTwo } from 'services/examples-tables/examples-others-twos/examples-others-twos-cruds'
import {
  getExamplesOthersTwosDashboard,
  GetParams,
  getExamplesOthersTwosContainsDashboard,
} from 'services/examples-tables/examples-others-twos/examples-others-twos-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/examples-tables/examples-others-twos-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const exampleOtherTwoListSelector = (state: StateRoot) =>
  state.examplesOthersTwosReducer

const selectorResult = createSelector(
  exampleOtherTwoListSelector,
  (exampleOtherTwoListReducer) => {
    return exampleOtherTwoListReducer
  },
)

export const useExampleOtherTwoList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('examples_others_twos')

  const {
    data: dataExampleOtherTwo,
    deleteModalOpen,
    exampleOtherTwoIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'exampleOtherTwo', 'list', { params }],
    queryFn: () => getExamplesOthersTwosDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverExampleOtherTwo,
  })

  const handleStoreData = useCallback(
    (examplesOthersTwos: ExampleOtherTwo[], totalPages: number | null) => {
      if (!examplesOthersTwos) return

      dispatch(setData(examplesOthersTwos))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverExampleOtherTwo = useCallback(async () => {
    if (!exampleOtherTwoIdToDelete) return

    const response = await mutateAsync({
      exampleOtherTwoId: +exampleOtherTwoIdToDelete,
    })

    if (response?.deleteForeverExampleOtherTwoDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: exampleOtherTwoIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [exampleOtherTwoIdToDelete])

  useEffect(() => {
    const examplesOthersTwosResult = data?.getExamplesOthersTwosDashboard

    if (examplesOthersTwosResult) {
      handleStoreData(
        examplesOthersTwosResult?.data,
        examplesOthersTwosResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getExamplesOthersTwosContainsDashboard(value)
      const examplesOthersTwosResult =
        response?.getExamplesOthersTwosContainsDashboard

      handleStoreData(
        examplesOthersTwosResult?.data,
        examplesOthersTwosResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    exampleOtherTwoIdToDelete,
    exampleOtherTwo: data?.getExamplesOthersTwosDashboard?.data,
    isLoading,
    isFetching,
    dataExampleOtherTwo,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverExampleOtherTwo,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
