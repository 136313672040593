import { graphClient } from 'services/graph-client'
import {
  activeFarmDashboardGQL,
  deleteFarmDashboardGQL,
  deleteForeverFarmDashboardGQL,
  createFarmDashboardGQL,
  updateFarmDashboardGQL,
} from './queries'
import { Farm } from 'types/farms/farms-types'

interface ResponseCreate {
  createFarmDashboard: {
    data: Farm
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateFarmDashboard: {
    data: Farm
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeFarmDashboard: {
    data: Farm
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteFarmDashboard: {
    data: Farm
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverFarmDashboard: string
}

interface DataActive {
  farmId: number
  active: boolean
}

interface DataDeleted {
  farmId: number
  deleted: boolean
}

interface DataDeleteForever {
  farmId: number
}

interface DataCreate {
  name: string
  description?: string
  document?: string
  documentTwo?: string
  documentThree?: string
  email?: string
  phone?: string
  country?: string
  state?: string
  city?: string
  geographicLocation?: string
  hectareQuantity: number
  usefullHectareQuantity: number
}

interface DataUpdate {
  farmId: number
  name: string
  description?: string
  document?: string
  documentTwo?: string
  documentThree?: string
  email?: string
  phone?: string
  country?: string
  state?: string
  city?: string
  geographicLocation?: string
  hectareQuantity: number
  usefullHectareQuantity: number
}

export const activeFarm = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeFarmDashboardGQL, {
    ...data,
  })
}

export const deleteFarm = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteFarmDashboardGQL, {
    ...data,
  })
}

export const deleteForeverFarm = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverFarmDashboardGQL,
    { ...data },
  )
}

export const createFarm = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(createFarmDashboardGQL, {
    ...data,
  })
}

export const updateFarm = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateFarmDashboardGQL, {
    ...data,
  })
}
