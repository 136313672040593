import { t } from 'i18next'

interface Props {
  permissions?: {
    canActive: boolean
    canDeleted: boolean
    canDeleteForever: boolean
    canDuplicate: boolean
  }
  cell: {
    row: {
      original: any
    }
    value: string
  }
  handleToggleActive(): void
  handleOpenForm(): void
  handleToggleArchive(): void
  handleOpenDuplicateForm(): void
  handleOpenDeleteModal(): void
}

function StatusComponent({
  cell,
  permissions,
  handleToggleActive,
  handleOpenForm,
  handleToggleArchive,
  handleOpenDuplicateForm,
  handleOpenDeleteModal,
}: Props) {
  const item = cell?.row?.original

  return (
    <div className="d-flex justify-content-start">
      {permissions?.canActive && (
        <>
          {item?.active ? (
            <span
              style={{ cursor: 'pointer' }}
              onClick={handleToggleActive}
              className="pt-2 pb-2 badge bg-success-subtle text-success text-uppercase"
            >
              {t('active')}
            </span>
          ) : (
            <span
              onClick={handleToggleActive}
              style={{ cursor: 'pointer', fontSize: 8 }}
              className="pt-2 pb-2 badge bg-warning-subtle text-warning text-uppercase"
            >
              {t('inactive')}
            </span>
          )}
        </>
      )}

      <div className="d-flex justify-content-end">
        <div className="flex-shrink-0 ms-4">
          <ul id="ul-list-actions" className="list-inline tasks-list-menu mb-0">
            <button
              type="button"
              onClick={handleOpenForm}
              className="list-inline-item cursor-pointer"
              title={t('edit')}
            >
              <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
            </button>

            {permissions?.canDeleted && (
              <>
                <button
                  type="button"
                  title={!item?.deleted ? t('archive') : t('desarchive')}
                  className="list-inline-item cursor-pointer"
                  onClick={handleToggleArchive}
                >
                  {!item?.deleted ? (
                    <i className="ri-folder-5-fill align-bottom me-2 text-muted"></i>
                  ) : (
                    <i className="ri-folder-fill align-bottom me-2 text-muted"></i>
                  )}
                </button>
              </>
            )}

            {permissions?.canDuplicate && (
              <>
                <button
                  title={t('duplicate')}
                  type="button"
                  className="list-inline-item cursor-pointer"
                  onClick={handleOpenDuplicateForm}
                >
                  <span className="align-bottom me-2 text-muted">x2</span>
                </button>
              </>
            )}

            {permissions?.canDeleteForever && (
              <>
                <button
                  title={t('delete')}
                  type="button"
                  className="list-inline-item cursor-pointer"
                  id="trash-icon"
                  onClick={handleOpenDeleteModal}
                >
                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                </button>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export { StatusComponent }
