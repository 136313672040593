import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import {
  createAppLog,
  updateAppLog,
} from 'services/settings/apps-logs/apps-logs-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectAppLog,
} from 'slices/settings/apps-logs-reducer'
import { z } from 'zod'
import { TypeAppLogType } from 'types/settings/apps-logs-types'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z.string().min(1, t('inform-field') + ' ' + t('apps-logs-name')),

  typeAppLogType: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('apps-logs-type-app-log-type')),
    z.object({ label: z.string(), value: z.string() }),
  ]),

  table: z.string().min(1, t('inform-field') + ' ' + t('apps-logs-table')),

  tableIndex: z
    .string()
    .min(1, t('inform-field') + ' ' + t('apps-logs-table-index')),

  oldJson: z.string(),
  newJson: z.string(),
})

export type FormData = z.infer<typeof schema>

const appLogModalFormSelector = (state: StateRoot) => {
  return state.appsLogsReducer
}

export const selectorResult = createSelector(
  appLogModalFormSelector,
  (appLogReducer) => {
    return appLogReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, appLogSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('apps_logs')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectAppLog(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createAppLogFn, isPending } = useMutation({
    mutationFn: createAppLog,
  })

  const { mutateAsync: updateAppLogFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateAppLog,
    })

  const cleanFields: FormData = {
    name: '',
    typeAppLogType: '',
    table: '',
    tableIndex: '',
    oldJson: '',
    newJson: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!appLogSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: appLogSelected.name,
      typeAppLogType: formatSelectValueToFields(appLogSelected, [
        'typeAppLogType',
        'typeAppLogType',
        'typeAppLogType',
        'typeAppLogType',
        'typeAppLogType',
      ]),
      table: appLogSelected.table,
      tableIndex: `${appLogSelected.tableIndex}`,
      oldJson: appLogSelected.oldJson,
      newJson: appLogSelected.newJson,
    }

    reset(appLogSelected ? fields : cleanFields)
  }, [appLogSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedTypeAppLogType = formatToSendData(
        data?.typeAppLogType,
      ) as TypeAppLogType

      const formatedData = {
        name: data?.name,
        typeAppLogType: selectedTypeAppLogType,
        table: data?.table,
        tableIndex: +data?.tableIndex,
        oldJson: data?.oldJson,
        newJson: data?.newJson,
      }

      if (appLogSelected && appLogSelected?.id) {
        const { updateAppLogDashboard } = await updateAppLogFn({
          ...formatedData,
          appLogId: appLogSelected?.id,
        })

        dispatch(editUniqueData(updateAppLogDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createAppLogFn(formatedData)

      dispatch(pushData(responseData?.createAppLogDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    appLogSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
