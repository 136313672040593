import { createSelector } from 'reselect'
import { TypeCoffeeTreatment } from 'types/types-coffees/types-coffees-treatments-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverTypeCoffeeTreatment } from 'services/types-coffees/types-coffees-treatments/types-coffees-treatments-cruds'
import {
  getTypesCoffeesTreatmentsDashboard,
  GetParams,
  getTypesCoffeesTreatmentsContainsDashboard,
} from 'services/types-coffees/types-coffees-treatments/types-coffees-treatments-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/types-coffees/types-coffees-treatments-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const typeCoffeeTreatmentListSelector = (state: StateRoot) =>
  state.typesCoffeesTreatmentsReducer

const selectorResult = createSelector(
  typeCoffeeTreatmentListSelector,
  (typeCoffeeTreatmentListReducer) => {
    return typeCoffeeTreatmentListReducer
  },
)

export const useTypeCoffeeTreatmentList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('types_coffees_treatments')

  const {
    data: dataTypeCoffeeTreatment,
    deleteModalOpen,
    typeCoffeeTreatmentIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'typeCoffeeTreatment', 'list', { params }],
    queryFn: () => getTypesCoffeesTreatmentsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverTypeCoffeeTreatment,
  })

  const handleStoreData = useCallback(
    (
      typesCoffeesTreatments: TypeCoffeeTreatment[],
      totalPages: number | null,
    ) => {
      if (!typesCoffeesTreatments) return

      dispatch(setData(typesCoffeesTreatments))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverTypeCoffeeTreatment = useCallback(async () => {
    if (!typeCoffeeTreatmentIdToDelete) return

    const response = await mutateAsync({
      typeCoffeeTreatmentId: +typeCoffeeTreatmentIdToDelete,
    })

    if (
      response?.deleteForeverTypeCoffeeTreatmentDashboard === 'deleted_forever'
    ) {
      dispatch(deleteUniqueData({ id: typeCoffeeTreatmentIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [typeCoffeeTreatmentIdToDelete])

  useEffect(() => {
    const typesCoffeesTreatmentsResult =
      data?.getTypesCoffeesTreatmentsDashboard

    if (typesCoffeesTreatmentsResult) {
      handleStoreData(
        typesCoffeesTreatmentsResult?.data,
        typesCoffeesTreatmentsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getTypesCoffeesTreatmentsContainsDashboard(value)
      const typesCoffeesTreatmentsResult =
        response?.getTypesCoffeesTreatmentsContainsDashboard

      handleStoreData(
        typesCoffeesTreatmentsResult?.data,
        typesCoffeesTreatmentsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    typeCoffeeTreatmentIdToDelete,
    typeCoffeeTreatment: data?.getTypesCoffeesTreatmentsDashboard?.data,
    isLoading,
    isFetching,
    dataTypeCoffeeTreatment,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverTypeCoffeeTreatment,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
