import { graphClient } from 'services/graph-client'
import {
  activeTypeToolDashboardGQL,
  deleteTypeToolDashboardGQL,
  deleteForeverTypeToolDashboardGQL,
  createTypeToolDashboardGQL,
  updateTypeToolDashboardGQL,
} from './queries'
import { TypeTool } from 'types/types-coffees/types-tools-types'

interface ResponseCreate {
  createTypeToolDashboard: {
    data: TypeTool
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeToolDashboard: {
    data: TypeTool
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeToolDashboard: {
    data: TypeTool
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeToolDashboard: {
    data: TypeTool
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeToolDashboard: string
}

interface DataActive {
  typeToolId: number
  active: boolean
}

interface DataDeleted {
  typeToolId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeToolId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  typeToolId: number
  name: string
  description?: string
}

export const activeTypeTool = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeTypeToolDashboardGQL, {
    ...data,
  })
}

export const deleteTypeTool = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteTypeToolDashboardGQL, {
    ...data,
  })
}

export const deleteForeverTypeTool = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeToolDashboardGQL,
    { ...data },
  )
}

export const createTypeTool = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(createTypeToolDashboardGQL, {
    ...data,
  })
}

export const updateTypeTool = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateTypeToolDashboardGQL, {
    ...data,
  })
}
