import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeCoffeeSizeDashboardGQL,
  deleteDefaultTypeCoffeeSizeDashboardGQL,
  deleteForeverDefaultTypeCoffeeSizeDashboardGQL,
  createDefaultTypeCoffeeSizeDashboardGQL,
  updateDefaultTypeCoffeeSizeDashboardGQL,
} from './queries'
import { DefaultTypeCoffeeSize } from 'types/defaults/defaults-types-coffees-sizes-types'

interface ResponseCreate {
  createDefaultTypeCoffeeSizeDashboard: {
    data: DefaultTypeCoffeeSize
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeCoffeeSizeDashboard: {
    data: DefaultTypeCoffeeSize
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeCoffeeSizeDashboard: {
    data: DefaultTypeCoffeeSize
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeCoffeeSizeDashboard: {
    data: DefaultTypeCoffeeSize
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeCoffeeSizeDashboard: string
}

interface DataActive {
  defaultTypeCoffeeSizeId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeCoffeeSizeId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeCoffeeSizeId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  defaultTypeCoffeeSizeId: number
  name: string
  description?: string
}

export const activeDefaultTypeCoffeeSize = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeCoffeeSizeDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeCoffeeSize = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeCoffeeSizeDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeCoffeeSize = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeCoffeeSizeDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeCoffeeSize = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeCoffeeSizeDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeCoffeeSize = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeCoffeeSizeDashboardGQL,
    { ...data },
  )
}
