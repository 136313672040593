import { gql } from 'graphql-request'

export const createExampleTableDashboardGQL = gql`
  mutation CREATE_EXAMPLE_TABLE_DASHBOARD(
    $name: String!
    $typeText: String!
    $typeBoolean: Boolean!
    $typeInt: Int!
    $typeDatetime: String!
    $typeEnum: TypeEnum!
    $exampleOtherOneId: Int!
    $exampleOtherTwoId: Int!
    $typeDecimal: Float!
    $typeCurrency: Float!
    $email: String
    $phone: String
  ) {
    createExampleTableDashboard(
      name: $name
      typeText: $typeText
      typeBoolean: $typeBoolean
      typeInt: $typeInt
      typeDatetime: $typeDatetime
      typeEnum: $typeEnum
      exampleOtherOneId: $exampleOtherOneId
      exampleOtherTwoId: $exampleOtherTwoId
      typeDecimal: $typeDecimal
      typeCurrency: $typeCurrency
      email: $email
      phone: $phone
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        exampleOtherOneId
        exampleOtherOne {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        exampleOtherTwoId
        exampleOtherTwo {
          id
          name
        }
        name
        typeText
        typeBoolean
        typeInt
        typeDatetime
        typeEnum
        typeDecimal
        typeCurrency
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateExampleTableDashboardGQL = gql`
  mutation UPDATE_EXAMPLE_TABLE_DASHBOARD(
    $exampleTableId: Int!
    $name: String
    $typeText: String
    $typeBoolean: Boolean
    $typeInt: Int
    $typeDatetime: String
    $typeEnum: TypeEnum
    $exampleOtherOneId: Int
    $exampleOtherTwoId: Int
    $typeDecimal: Float
    $typeCurrency: Float
    $email: String
    $phone: String
  ) {
    updateExampleTableDashboard(
      exampleTableId: $exampleTableId
      name: $name
      typeText: $typeText
      typeBoolean: $typeBoolean
      typeInt: $typeInt
      typeDatetime: $typeDatetime
      typeEnum: $typeEnum
      exampleOtherOneId: $exampleOtherOneId
      exampleOtherTwoId: $exampleOtherTwoId
      typeDecimal: $typeDecimal
      typeCurrency: $typeCurrency
      email: $email
      phone: $phone
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        exampleOtherOneId
        exampleOtherOne {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        exampleOtherTwoId
        exampleOtherTwo {
          id
          name
        }
        name
        typeText
        typeBoolean
        typeInt
        typeDatetime
        typeEnum
        typeDecimal
        typeCurrency
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteExampleTableDashboardGQL = gql`
  mutation DELETE_EXAMPLE_TABLE_DASHBOARD(
    $exampleTableId: Int!
    $deleted: Boolean!
  ) {
    deleteExampleTableDashboard(
      exampleTableId: $exampleTableId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        userId
        name
        typeText
        typeBoolean
        typeInt
        typeDatetime
        typeEnum
        exampleOtherOneId
        exampleOtherTwoId
        typeDecimal
        typeCurrency
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeExampleTableDashboardGQL = gql`
  mutation ACTIVE_EXAMPLE_TABLE_DASHBOARD(
    $exampleTableId: Int!
    $active: Boolean!
  ) {
    activeExampleTableDashboard(
      exampleTableId: $exampleTableId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        userId
        name
        typeText
        typeBoolean
        typeInt
        typeDatetime
        typeEnum
        exampleOtherOneId
        exampleOtherTwoId
        typeDecimal
        typeCurrency
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverExampleTableDashboardGQL = gql`
  mutation DELETE_FOREVER_EXAMPLE_TABLE_DASHBOARD($exampleTableId: Int!) {
    deleteForeverExampleTableDashboard(exampleTableId: $exampleTableId)
  }
`

export const getExamplesTablesDashboardGQL = gql`
  query GET_EXAMPLES_TABLES_DASHBOARD(
    $take: Int
    $skip: Int
    $userId: Int
    $name: String
    $typeText: String
    $typeBoolean: Boolean
    $typeInt: Int
    $typeDatetimeInitial: String
    $typeDatetimeFinal: String
    $typeEnum: TypeEnum
    $exampleOtherOneId: Int
    $exampleOtherTwoId: Int
    $typeDecimalInitial: Float
    $typeDecimalFinal: Float
    $typeCurrencyInitial: Float
    $typeCurrencyFinal: Float
    $email: String
    $phone: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getExamplesTablesDashboard(
      take: $take
      skip: $skip
      userId: $userId
      name: $name
      typeText: $typeText
      typeBoolean: $typeBoolean
      typeInt: $typeInt
      typeDatetimeInitial: $typeDatetimeInitial
      typeDatetimeFinal: $typeDatetimeFinal
      typeEnum: $typeEnum
      exampleOtherOneId: $exampleOtherOneId
      exampleOtherTwoId: $exampleOtherTwoId
      typeDecimalInitial: $typeDecimalInitial
      typeDecimalFinal: $typeDecimalFinal
      typeCurrencyInitial: $typeCurrencyInitial
      typeCurrencyFinal: $typeCurrencyFinal
      email: $email
      phone: $phone
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        exampleOtherOneId
        exampleOtherOne {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        exampleOtherTwoId
        exampleOtherTwo {
          id
          name
        }
        name
        typeText
        typeBoolean
        typeInt
        typeDatetime
        typeEnum
        typeDecimal
        typeCurrency
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getExampleOtherOneDashboardIdGQL = gql`
  query GET_EXAMPLE_OTHER_ONE_DASHBOARD_ID($exampleOtherOneId: Int!) {
    getExampleOtherOneDashboard(exampleOtherOneId: $exampleOtherOneId) {
      error
      status
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getExamplesOthersOnesDashboardGQL = gql`
  query GET_EXAMPLES_OTHERS_ONES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getExamplesOthersOnesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUserDashboardIdGQL = gql`
  query GET_USER_DASHBOARD_ID($userId: Int!) {
    getUserDashboard(userId: $userId) {
      error
      status
      data {
        id
        userTypeId
        companyId
        farmId
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUsersDashboardGQL = gql`
  query GET_USERS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getUsersDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        userTypeId
        companyId
        farmId
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getExampleOtherTwoDashboardIdGQL = gql`
  query GET_EXAMPLE_OTHER_TWO_DASHBOARD_ID($exampleOtherTwoId: Int!) {
    getExampleOtherTwoDashboard(exampleOtherTwoId: $exampleOtherTwoId) {
      error
      status
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getExamplesOthersTwosDashboardGQL = gql`
  query GET_EXAMPLES_OTHERS_TWOS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getExamplesOthersTwosDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getExamplesTablesContainsDashboardGQL = gql`
  query GET_EXAMPLES_TABLES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $typeText: String
    $email: String
    $phone: String
  ) {
    getExamplesTablesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      typeText: $typeText
      email: $email
      phone: $phone
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        exampleOtherOneId
        exampleOtherOne {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        exampleOtherTwoId
        exampleOtherTwo {
          id
          name
        }
        name
        typeText
        typeBoolean
        typeInt
        typeDatetime
        typeEnum
        typeDecimal
        typeCurrency
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const exportExcelExamplesTablesGQL = gql`
  query EXPORT_EXCEL_EXAMPLES_TABLES_DASHBOARD(
    $name: String
    $typeText: String
    $typeBoolean: Boolean
    $typeInt: Int
    $typeDatetimeInitial: String
    $typeDatetimeFinal: String
    $typeEnum: TypeEnum
    $exampleOtherOneId: Int
    $exampleOtherTwoId: Int
    $typeDecimalInitial: Float
    $typeDecimalFinal: Float
    $typeCurrencyInitial: Float
    $typeCurrencyFinal: Float
    $email: String
    $phone: String
    $active: Boolean
    $deleted: Boolean
    $userId: Int
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    exportExcelExamplesTablesDashboard(
      name: $name
      typeText: $typeText
      typeBoolean: $typeBoolean
      typeInt: $typeInt
      typeDatetimeInitial: $typeDatetimeInitial
      typeDatetimeFinal: $typeDatetimeFinal
      typeEnum: $typeEnum
      exampleOtherOneId: $exampleOtherOneId
      exampleOtherTwoId: $exampleOtherTwoId
      typeDecimalInitial: $typeDecimalInitial
      typeDecimalFinal: $typeDecimalFinal
      typeCurrencyInitial: $typeCurrencyInitial
      typeCurrencyFinal: $typeCurrencyFinal
      email: $email
      phone: $phone
      active: $active
      deleted: $deleted
      userId: $userId
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    )
  }
`
