import { graphClient } from 'services/graph-client'
import {
  activeHarvestDashboardGQL,
  deleteHarvestDashboardGQL,
  deleteForeverHarvestDashboardGQL,
  createHarvestDashboardGQL,
  updateHarvestDashboardGQL,
} from './queries'
import { Harvest } from 'types/harvests/harvests-types'

interface ResponseCreate {
  createHarvestDashboard: {
    data: Harvest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateHarvestDashboard: {
    data: Harvest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeHarvestDashboard: {
    data: Harvest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteHarvestDashboard: {
    data: Harvest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverHarvestDashboard: string
}

interface DataActive {
  harvestId: number
  active: boolean
}

interface DataDeleted {
  harvestId: number
  deleted: boolean
}

interface DataDeleteForever {
  harvestId: number
}

interface DataCreate {
  farmId?: number
  farmFieldId?: number
  typeCultivationId: number
  typeMeasurementId: number
  name: string
  description?: string
  automaticCost: boolean
  dateHarvestInitial: string
  dateHarvestFinal: string
}

interface DataUpdate {
  harvestId: number
  farmId?: number
  farmFieldId?: number
  typeCultivationId: number
  typeMeasurementId: number
  name: string
  description?: string
  automaticCost: boolean
  dateHarvestInitial: string
  dateHarvestFinal: string
}

export const activeHarvest = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeHarvestDashboardGQL, {
    ...data,
  })
}

export const deleteHarvest = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteHarvestDashboardGQL, {
    ...data,
  })
}

export const deleteForeverHarvest = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverHarvestDashboardGQL,
    { ...data },
  )
}

export const createHarvest = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(createHarvestDashboardGQL, {
    ...data,
  })
}

export const updateHarvest = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateHarvestDashboardGQL, {
    ...data,
  })
}
