import { formatInitials } from 'helpers/format/format-initials'
import { useUserDetails } from 'lib/hooks/use-user-details'

export const Avatar = () => {
  const { user } = useUserDetails()

  return (
    <div
      className="topbar-user"
      style={{
        borderRadius: '50%',
        width: 40,
        height: 40,
        border: '1px solid #414e81',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {formatInitials(user?.firstName)}
    </div>
  )
}
