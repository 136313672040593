import { graphClient } from 'services/graph-client'
import {
  activeCompanyDashboardGQL,
  deleteCompanyDashboardGQL,
  deleteForeverCompanyDashboardGQL,
  createCompanyDashboardGQL,
  updateCompanyDashboardGQL,
} from './queries'
import { Company } from 'types/settings/companies-types'

interface ResponseCreate {
  createCompanyDashboard: {
    data: Company
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateCompanyDashboard: {
    data: Company
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeCompanyDashboard: {
    data: Company
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteCompanyDashboard: {
    data: Company
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverCompanyDashboard: string
}

interface DataActive {
  companyId: number
  active: boolean
}

interface DataDeleted {
  companyId: number
  deleted: boolean
}

interface DataDeleteForever {
  companyId: number
}

interface DataCreate {
  codeUid: string
  name: string
  description?: string
  document?: string
  documentTwo?: string
  documentThree?: string
  email?: string
  phone?: string
  country: string
  hectareQuantity: number
  usefullHectareQuantity: number
}

interface DataUpdate {
  companyId: number
  codeUid: string
  name: string
  description?: string
  document?: string
  documentTwo?: string
  documentThree?: string
  email?: string
  phone?: string
  country: string
  hectareQuantity: number
  usefullHectareQuantity: number
}

export const activeCompany = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeCompanyDashboardGQL, {
    ...data,
  })
}

export const deleteCompany = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteCompanyDashboardGQL, {
    ...data,
  })
}

export const deleteForeverCompany = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverCompanyDashboardGQL,
    { ...data },
  )
}

export const createCompany = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(createCompanyDashboardGQL, {
    ...data,
  })
}

export const updateCompany = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateCompanyDashboardGQL, {
    ...data,
  })
}
