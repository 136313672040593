import { createSlice } from '@reduxjs/toolkit'
import { AgriculturalInputSubType } from 'types/agriculturals-inputs/agriculturals-inputs-subs-types-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  agriculturalInputSubTypeSelected: null | AgriculturalInputSubType
  deleteModalOpen: boolean
  agriculturalInputSubTypeIdToDelete: null | string
  data: AgriculturalInputSubType[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  agriculturalInputSubTypeSelected: null,
  agriculturalInputSubTypeIdToDelete: null,
  data: [],
  total: 0,
}

const agriculturalInputSubTypeSlice = createSlice({
  initialState,
  name: 'agriculturals-inputs-subs-types-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectAgriculturalInputSubType(
      state,
      { payload }: { payload: AgriculturalInputSubType | null },
    ) {
      state.agriculturalInputSubTypeSelected = payload
    },
    setData(state, { payload }: { payload: AgriculturalInputSubType[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: AgriculturalInputSubType }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: AgriculturalInputSubType }) {
      state.data = state.data.map((agriculturalInputSubType) => {
        return agriculturalInputSubType.id === payload.id
          ? { ...agriculturalInputSubType, ...payload }
          : agriculturalInputSubType
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (agriculturalInputSubType) =>
          +agriculturalInputSubType.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.agriculturalInputSubTypeIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.agriculturalInputSubTypeIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectAgriculturalInputSubType,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = agriculturalInputSubTypeSlice.actions
export default agriculturalInputSubTypeSlice.reducer
