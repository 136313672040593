import { graphClient } from 'services/graph-client'
import {
  activeTypeCoffeePruningDashboardGQL,
  deleteTypeCoffeePruningDashboardGQL,
  deleteForeverTypeCoffeePruningDashboardGQL,
  createTypeCoffeePruningDashboardGQL,
  updateTypeCoffeePruningDashboardGQL,
} from './queries'
import { TypeCoffeePruning } from 'types/types-coffees/types-coffees-prunings-types'

interface ResponseCreate {
  createTypeCoffeePruningDashboard: {
    data: TypeCoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeCoffeePruningDashboard: {
    data: TypeCoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeCoffeePruningDashboard: {
    data: TypeCoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeCoffeePruningDashboard: {
    data: TypeCoffeePruning
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeCoffeePruningDashboard: string
}

interface DataActive {
  typeCoffeePruningId: number
  active: boolean
}

interface DataDeleted {
  typeCoffeePruningId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeCoffeePruningId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  typeCoffeePruningId: number
  name: string
  description?: string
}

export const activeTypeCoffeePruning = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeTypeCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const deleteTypeCoffeePruning = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteTypeCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const deleteForeverTypeCoffeePruning = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const createTypeCoffeePruning = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createTypeCoffeePruningDashboardGQL,
    { ...data },
  )
}

export const updateTypeCoffeePruning = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateTypeCoffeePruningDashboardGQL,
    { ...data },
  )
}
