import { graphClient } from 'services/graph-client'
import {
  activeExampleTableDashboardGQL,
  deleteExampleTableDashboardGQL,
  deleteForeverExampleTableDashboardGQL,
  createExampleTableDashboardGQL,
  updateExampleTableDashboardGQL,
} from './queries'
import { ExampleTable } from 'types/examples-tables/examples-tables-types'

interface ResponseCreate {
  createExampleTableDashboard: {
    data: ExampleTable
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateExampleTableDashboard: {
    data: ExampleTable
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeExampleTableDashboard: {
    data: ExampleTable
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteExampleTableDashboard: {
    data: ExampleTable
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverExampleTableDashboard: string
}

interface DataActive {
  exampleTableId: number
  active: boolean
}

interface DataDeleted {
  exampleTableId: number
  deleted: boolean
}

interface DataDeleteForever {
  exampleTableId: number
}

interface DataCreate {
  name: string
  typeText: string
  typeBoolean: boolean
  typeInt: number
  typeDatetime: string
  typeEnum: 'exampleOne' | 'exampleTwo'
  exampleOtherOneId: number
  exampleOtherTwoId: number
  typeDecimal: number
  typeCurrency: number
  email?: string
  phone?: string
}

interface DataUpdate {
  exampleTableId: number
  name: string
  typeText: string
  typeBoolean: boolean
  typeInt: number
  typeDatetime: string
  typeEnum: 'exampleOne' | 'exampleTwo'
  exampleOtherOneId: number
  exampleOtherTwoId: number
  typeDecimal: number
  typeCurrency: number
  email?: string
  phone?: string
}

export const activeExampleTable = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeExampleTableDashboardGQL,
    { ...data },
  )
}

export const deleteExampleTable = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteExampleTableDashboardGQL,
    { ...data },
  )
}

export const deleteForeverExampleTable = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverExampleTableDashboardGQL,
    { ...data },
  )
}

export const createExampleTable = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createExampleTableDashboardGQL,
    { ...data },
  )
}

export const updateExampleTable = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateExampleTableDashboardGQL,
    { ...data },
  )
}
