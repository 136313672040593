import { useInfiniteQuery } from '@tanstack/react-query'
import { getTypesCoffeesHarvestsDashboardHook } from 'services/_hooks/types-coffees-harvests-gets'

export const useGetTypesCoffeesHarvestsDashboard = (take = 4) => {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['get', 'typesCoffeesHarvestsDashboard', 'list', 'total'],
      initialPageParam: 0,
      queryFn: ({ pageParam }) => {
        return getTypesCoffeesHarvestsDashboardHook(pageParam, take)
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const total =
          lastPage?.getTypesCoffeesHarvestsDashboard?.totalPages ?? 4
        const isLastPage = allPages?.length >= total

        if (isLastPage) {
          return null
        }

        return lastPageParam + 1
      },
    })

  const typesCoffeesHarvestsDashboard = data?.pages?.flatMap((page) => {
    return page?.getTypesCoffeesHarvestsDashboard?.data
  })

  return {
    typesCoffeesHarvestsDashboard: typesCoffeesHarvestsDashboard ?? [],
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  }
}
