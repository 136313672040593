import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap'
import withRouter from '../../../components/common/with-router'
import ParticlesAuth from '../errors/particles-auth'

import { DidderLogo } from 'components/common/didder-logo'
import { InputComponent } from 'components/common/inputs/input'
import { InputPasswordComponent } from 'components/common/inputs/input-password'
import { useLogin } from './use-login'

const Login = () => {
  const { errors, isPending, handleSubmit, register } = useLogin()

  return (
    <>
      <ParticlesAuth>
        <div
          style={{ paddingBottom: 0 }}
          className="auth-page-content mt-lg-5 "
        >
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div
                  style={{ right: 0, left: 0, top: -25 }}
                  className="position-absolute d-flex justify-content-center align-items-center opacity-25 "
                >
                  <DidderLogo />
                </div>
                <Card style={{ marginTop: '35%', zIndex: 10 }}>
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Dashboard Admin</h5>
                      <p className="text-muted">
                        Sign in to continue to Dashboard.
                      </p>
                    </div>

                    <div className="p-2 mt-4">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <InputComponent
                            label="Phone"
                            error={errors?.phone?.message}
                            {...register('phone')}
                            type="text"
                          />
                        </div>

                        <div className="mb-3">
                          <InputPasswordComponent
                            label="Password"
                            error={errors?.password?.message}
                            {...register('password')}
                          />
                        </div>

                        <div className="mt-4">
                          <Button
                            color="primary"
                            disabled={isPending}
                            type="submit"
                            className="w-100"
                          >
                            {isPending && (
                              <Spinner size="sm" className="me-2" />
                            )}
                            Sign In
                          </Button>
                        </div>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </>
  )
}

export default withRouter(Login)
