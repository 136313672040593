import { gql } from 'graphql-request'

export const createOrderServiceAgriculturalInputDashboardGQL = gql`
  mutation CREATE_ORDER_SERVICE_AGRICULTURAL_INPUT_DASHBOARD(
    $farmId: Int
    $farmFieldId: Int
    $orderServiceId: Int!
    $agriculturalInputId: Int!
    $name: String!
    $valueAgriculturalInput: Float!
    $quantityPerPlant: Float!
    $expectedTotalQuantity: Float
    $realTotalQuantity: Float
  ) {
    createOrderServiceAgriculturalInputDashboard(
      farmId: $farmId
      farmFieldId: $farmFieldId
      orderServiceId: $orderServiceId
      agriculturalInputId: $agriculturalInputId
      name: $name
      valueAgriculturalInput: $valueAgriculturalInput
      quantityPerPlant: $quantityPerPlant
      expectedTotalQuantity: $expectedTotalQuantity
      realTotalQuantity: $realTotalQuantity
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        orderServiceId
        orderService {
          id
          name
        }
        agriculturalInputId
        agriculturalInput {
          id
          name
        }
        name
        valueAgriculturalInput
        quantityPerPlant
        expectedTotalQuantity
        realTotalQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateOrderServiceAgriculturalInputDashboardGQL = gql`
  mutation UPDATE_ORDER_SERVICE_AGRICULTURAL_INPUT_DASHBOARD(
    $orderServiceAgriculturalInputId: Int!
    $farmId: Int
    $farmFieldId: Int
    $orderServiceId: Int
    $agriculturalInputId: Int
    $name: String
    $valueAgriculturalInput: Float
    $quantityPerPlant: Float
    $expectedTotalQuantity: Float
    $realTotalQuantity: Float
  ) {
    updateOrderServiceAgriculturalInputDashboard(
      orderServiceAgriculturalInputId: $orderServiceAgriculturalInputId
      farmId: $farmId
      farmFieldId: $farmFieldId
      orderServiceId: $orderServiceId
      agriculturalInputId: $agriculturalInputId
      name: $name
      valueAgriculturalInput: $valueAgriculturalInput
      quantityPerPlant: $quantityPerPlant
      expectedTotalQuantity: $expectedTotalQuantity
      realTotalQuantity: $realTotalQuantity
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        orderServiceId
        orderService {
          id
          name
        }
        agriculturalInputId
        agriculturalInput {
          id
          name
        }
        name
        valueAgriculturalInput
        quantityPerPlant
        expectedTotalQuantity
        realTotalQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteOrderServiceAgriculturalInputDashboardGQL = gql`
  mutation DELETE_ORDER_SERVICE_AGRICULTURAL_INPUT_DASHBOARD(
    $orderServiceAgriculturalInputId: Int!
    $deleted: Boolean!
  ) {
    deleteOrderServiceAgriculturalInputDashboard(
      orderServiceAgriculturalInputId: $orderServiceAgriculturalInputId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        orderServiceId
        agriculturalInputId
        name
        valueAgriculturalInput
        quantityPerPlant
        expectedTotalQuantity
        realTotalQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeOrderServiceAgriculturalInputDashboardGQL = gql`
  mutation ACTIVE_ORDER_SERVICE_AGRICULTURAL_INPUT_DASHBOARD(
    $orderServiceAgriculturalInputId: Int!
    $active: Boolean!
  ) {
    activeOrderServiceAgriculturalInputDashboard(
      orderServiceAgriculturalInputId: $orderServiceAgriculturalInputId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        orderServiceId
        agriculturalInputId
        name
        valueAgriculturalInput
        quantityPerPlant
        expectedTotalQuantity
        realTotalQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverOrderServiceAgriculturalInputDashboardGQL = gql`
  mutation DELETE_FOREVER_ORDER_SERVICE_AGRICULTURAL_INPUT_DASHBOARD(
    $orderServiceAgriculturalInputId: Int!
  ) {
    deleteForeverOrderServiceAgriculturalInputDashboard(
      orderServiceAgriculturalInputId: $orderServiceAgriculturalInputId
    )
  }
`

export const getOrdersServicesAgriculturalsInputsDashboardGQL = gql`
  query GET_ORDERS_SERVICES_AGRICULTURALS_INPUTS_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $farmFieldId: Int
    $orderServiceId: Int
    $agriculturalInputId: Int
    $name: String
    $valueAgriculturalInput: Float
    $quantityPerPlant: Float
    $expectedTotalQuantity: Float
    $realTotalQuantity: Float
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getOrdersServicesAgriculturalsInputsDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      farmFieldId: $farmFieldId
      orderServiceId: $orderServiceId
      agriculturalInputId: $agriculturalInputId
      name: $name
      valueAgriculturalInput: $valueAgriculturalInput
      quantityPerPlant: $quantityPerPlant
      expectedTotalQuantity: $expectedTotalQuantity
      realTotalQuantity: $realTotalQuantity
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        orderServiceId
        orderService {
          id
          name
        }
        agriculturalInputId
        agriculturalInput {
          id
          name
        }
        name
        valueAgriculturalInput
        quantityPerPlant
        expectedTotalQuantity
        realTotalQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmFieldDashboardIdGQL = gql`
  query GET_FARM_FIELD_DASHBOARD_ID($farmFieldId: Int!) {
    getFarmFieldDashboard(farmFieldId: $farmFieldId) {
      error
      status
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsFieldsDashboardGQL = gql`
  query GET_FARMS_FIELDS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsFieldsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getOrderServiceDashboardIdGQL = gql`
  query GET_ORDER_SERVICE_DASHBOARD_ID($orderServiceId: Int!) {
    getOrderServiceDashboard(orderServiceId: $orderServiceId) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        typeCoffeeTreatmentId
        name
        dateService
        description
        optionalDescription
        quantityHectare
        isBrush
        isPulverization
        isFertilizing
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getOrdersServicesDashboardGQL = gql`
  query GET_ORDERS_SERVICES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getOrdersServicesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        farmFieldId
        typeCoffeeTreatmentId
        name
        dateService
        description
        optionalDescription
        quantityHectare
        isBrush
        isPulverization
        isFertilizing
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalInputDashboardIdGQL = gql`
  query GET_AGRICULTURAL_INPUT_DASHBOARD_ID($agriculturalInputId: Int!) {
    getAgriculturalInputDashboard(agriculturalInputId: $agriculturalInputId) {
      error
      status
      data {
        id
        companyId
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        name
        description
        tradeMark
        formulation
        activeIngredient
        isPesticide
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getAgriculturalsInputsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        name
        description
        tradeMark
        formulation
        activeIngredient
        isPesticide
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getOrdersServicesAgriculturalsInputsContainsDashboardGQL = gql`
  query GET_ORDERS_SERVICES_AGRICULTURALS_INPUTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
  ) {
    getOrdersServicesAgriculturalsInputsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        orderServiceId
        orderService {
          id
          name
        }
        agriculturalInputId
        agriculturalInput {
          id
          name
        }
        name
        valueAgriculturalInput
        quantityPerPlant
        expectedTotalQuantity
        realTotalQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
