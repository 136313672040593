import { gql } from 'graphql-request'

export const createAgriculturalInputDashboardGQL = gql`
  mutation CREATE_AGRICULTURAL_INPUT_DASHBOARD(
    $agriculturalInputSubTypeId: Int!
    $agriculturalInputModeActionId: Int!
    $name: String!
    $description: String!
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $isPesticide: Boolean!
    $toxicologicalClass: String
    $environmentalClass: String
    $isOrganic: Boolean!
    $isFertilizer: Boolean!
  ) {
    createAgriculturalInputDashboard(
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      name: $name
      description: $description
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      isPesticide: $isPesticide
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
      isOrganic: $isOrganic
      isFertilizer: $isFertilizer
    ) {
      error
      status
      data {
        id
        agriculturalInputSubTypeId
        agriculturalInputSubType {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        agriculturalInputModeActionId
        agriculturalInputModeAction {
          id
          name
        }
        name
        description
        tradeMark
        formulation
        activeIngredient
        isPesticide
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateAgriculturalInputDashboardGQL = gql`
  mutation UPDATE_AGRICULTURAL_INPUT_DASHBOARD(
    $agriculturalInputId: Int!
    $agriculturalInputSubTypeId: Int
    $agriculturalInputModeActionId: Int
    $name: String
    $description: String
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $isPesticide: Boolean
    $toxicologicalClass: String
    $environmentalClass: String
    $isOrganic: Boolean
    $isFertilizer: Boolean
  ) {
    updateAgriculturalInputDashboard(
      agriculturalInputId: $agriculturalInputId
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      name: $name
      description: $description
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      isPesticide: $isPesticide
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
      isOrganic: $isOrganic
      isFertilizer: $isFertilizer
    ) {
      error
      status
      data {
        id
        agriculturalInputSubTypeId
        agriculturalInputSubType {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        agriculturalInputModeActionId
        agriculturalInputModeAction {
          id
          name
        }
        name
        description
        tradeMark
        formulation
        activeIngredient
        isPesticide
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteAgriculturalInputDashboardGQL = gql`
  mutation DELETE_AGRICULTURAL_INPUT_DASHBOARD(
    $agriculturalInputId: Int!
    $deleted: Boolean!
  ) {
    deleteAgriculturalInputDashboard(
      agriculturalInputId: $agriculturalInputId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        name
        description
        tradeMark
        formulation
        activeIngredient
        isPesticide
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeAgriculturalInputDashboardGQL = gql`
  mutation ACTIVE_AGRICULTURAL_INPUT_DASHBOARD(
    $agriculturalInputId: Int!
    $active: Boolean!
  ) {
    activeAgriculturalInputDashboard(
      agriculturalInputId: $agriculturalInputId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        name
        description
        tradeMark
        formulation
        activeIngredient
        isPesticide
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverAgriculturalInputDashboardGQL = gql`
  mutation DELETE_FOREVER_AGRICULTURAL_INPUT_DASHBOARD(
    $agriculturalInputId: Int!
  ) {
    deleteForeverAgriculturalInputDashboard(
      agriculturalInputId: $agriculturalInputId
    )
  }
`

export const getAgriculturalsInputsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_DASHBOARD(
    $take: Int
    $skip: Int
    $agriculturalInputSubTypeId: Int
    $agriculturalInputModeActionId: Int
    $name: String
    $description: String
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $isPesticide: Boolean
    $toxicologicalClass: String
    $environmentalClass: String
    $isOrganic: Boolean
    $isFertilizer: Boolean
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getAgriculturalsInputsDashboard(
      take: $take
      skip: $skip
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      name: $name
      description: $description
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      isPesticide: $isPesticide
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
      isOrganic: $isOrganic
      isFertilizer: $isFertilizer
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        agriculturalInputSubTypeId
        agriculturalInputSubType {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        agriculturalInputModeActionId
        agriculturalInputModeAction {
          id
          name
        }
        name
        description
        tradeMark
        formulation
        activeIngredient
        isPesticide
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalInputSubTypeDashboardIdGQL = gql`
  query GET_AGRICULTURAL_INPUT_SUB_TYPE_DASHBOARD_ID(
    $agriculturalInputSubTypeId: Int!
  ) {
    getAgriculturalInputSubTypeDashboard(
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
    ) {
      error
      status
      data {
        id
        companyId
        agriculturalInputTypeId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsSubsTypesDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_SUBS_TYPES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getAgriculturalsInputsSubsTypesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        agriculturalInputTypeId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalInputModeActionDashboardIdGQL = gql`
  query GET_AGRICULTURAL_INPUT_MODE_ACTION_DASHBOARD_ID(
    $agriculturalInputModeActionId: Int!
  ) {
    getAgriculturalInputModeActionDashboard(
      agriculturalInputModeActionId: $agriculturalInputModeActionId
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsModesActionsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_MODES_ACTIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getAgriculturalsInputsModesActionsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsContainsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $toxicologicalClass: String
    $environmentalClass: String
  ) {
    getAgriculturalsInputsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
    ) {
      error
      status
      totalPages
      data {
        id
        agriculturalInputSubTypeId
        agriculturalInputSubType {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        agriculturalInputModeActionId
        agriculturalInputModeAction {
          id
          name
        }
        name
        description
        tradeMark
        formulation
        activeIngredient
        isPesticide
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
