import { gql } from 'graphql-request'

export const createExampleOtherOneDashboardGQL = gql`
  mutation CREATE_EXAMPLE_OTHER_ONE_DASHBOARD($name: String!) {
    createExampleOtherOneDashboard(name: $name) {
      error
      status
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateExampleOtherOneDashboardGQL = gql`
  mutation UPDATE_EXAMPLE_OTHER_ONE_DASHBOARD(
    $exampleOtherOneId: Int!
    $name: String
  ) {
    updateExampleOtherOneDashboard(
      exampleOtherOneId: $exampleOtherOneId
      name: $name
    ) {
      error
      status
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteExampleOtherOneDashboardGQL = gql`
  mutation DELETE_EXAMPLE_OTHER_ONE_DASHBOARD(
    $exampleOtherOneId: Int!
    $deleted: Boolean!
  ) {
    deleteExampleOtherOneDashboard(
      exampleOtherOneId: $exampleOtherOneId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeExampleOtherOneDashboardGQL = gql`
  mutation ACTIVE_EXAMPLE_OTHER_ONE_DASHBOARD(
    $exampleOtherOneId: Int!
    $active: Boolean!
  ) {
    activeExampleOtherOneDashboard(
      exampleOtherOneId: $exampleOtherOneId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverExampleOtherOneDashboardGQL = gql`
  mutation DELETE_FOREVER_EXAMPLE_OTHER_ONE_DASHBOARD(
    $exampleOtherOneId: Int!
  ) {
    deleteForeverExampleOtherOneDashboard(exampleOtherOneId: $exampleOtherOneId)
  }
`

export const getExamplesOthersOnesDashboardGQL = gql`
  query GET_EXAMPLES_OTHERS_ONES_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getExamplesOthersOnesDashboard(
      take: $take
      skip: $skip
      name: $name
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getExamplesOthersOnesContainsDashboardGQL = gql`
  query GET_EXAMPLES_OTHERS_ONES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
  ) {
    getExamplesOthersOnesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
    ) {
      error
      status
      totalPages
      data {
        id
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
