import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import {
  createTypePest,
  updateTypePest,
} from 'services/types-coffees/types-pests/types-pests-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectTypePest,
} from 'slices/types-coffees/types-pests-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z.string().min(1, t('inform-field') + ' ' + t('types-pests-name')),

  commonName: z
    .string()
    .min(1, t('inform-field') + ' ' + t('types-pests-common-name')),

  scientificName: z.string(),
  description: z
    .string()
    .min(1, t('inform-field') + ' ' + t('types-pests-description')),
})

export type FormData = z.infer<typeof schema>

const typePestModalFormSelector = (state: StateRoot) => {
  return state.typesPestsReducer
}

export const selectorResult = createSelector(
  typePestModalFormSelector,
  (typePestReducer) => {
    return typePestReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, typePestSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('types_pests')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectTypePest(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createTypePestFn, isPending } = useMutation({
    mutationFn: createTypePest,
  })

  const { mutateAsync: updateTypePestFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateTypePest,
    })

  const cleanFields: FormData = {
    name: '',
    commonName: '',
    scientificName: '',
    description: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!typePestSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: typePestSelected.name,
      commonName: typePestSelected.commonName,
      scientificName: typePestSelected.scientificName,
      description: typePestSelected.description,
    }

    reset(typePestSelected ? fields : cleanFields)
  }, [typePestSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        name: data?.name,
        commonName: data?.commonName,
        scientificName: data?.scientificName,
        description: data?.description,
      }

      if (typePestSelected && typePestSelected?.id) {
        const { updateTypePestDashboard } = await updateTypePestFn({
          ...formatedData,
          typePestId: typePestSelected?.id,
        })

        dispatch(editUniqueData(updateTypePestDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createTypePestFn(formatedData)

      dispatch(pushData(responseData?.createTypePestDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    typePestSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
