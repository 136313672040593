import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeCultivationDashboardGQL,
  deleteDefaultTypeCultivationDashboardGQL,
  deleteForeverDefaultTypeCultivationDashboardGQL,
  createDefaultTypeCultivationDashboardGQL,
  updateDefaultTypeCultivationDashboardGQL,
} from './queries'
import { DefaultTypeCultivation } from 'types/defaults/defaults-types-cultivations-types'

interface ResponseCreate {
  createDefaultTypeCultivationDashboard: {
    data: DefaultTypeCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeCultivationDashboard: {
    data: DefaultTypeCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeCultivationDashboard: {
    data: DefaultTypeCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeCultivationDashboard: {
    data: DefaultTypeCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeCultivationDashboard: string
}

interface DataActive {
  defaultTypeCultivationId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeCultivationId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeCultivationId: number
}

interface DataCreate {
  name: string
  description: string
}

interface DataUpdate {
  defaultTypeCultivationId: number
  name: string
  description: string
}

export const activeDefaultTypeCultivation = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeCultivationDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeCultivation = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeCultivationDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeCultivation = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeCultivationDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeCultivation = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeCultivationDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeCultivation = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeCultivationDashboardGQL,
    { ...data },
  )
}
