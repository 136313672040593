import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetTypesCoffeesTreatmentsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-treatments-dashboard'

const schemaFilter = z.object({
  farmId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('orders-services-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('orders-services-farm-field-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCoffeeTreatmentId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('orders-services-type-coffee-treatment-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z.string().min(1, t('inform-field') + ' ' + t('orders-services-name')),

  dateService: z.union([z.date(), z.string().min(1)]),

  description: z
    .string()
    .min(1, t('inform-field') + ' ' + t('orders-services-description')),

  optionalDescription: z.string(),
  isBrush: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('orders-services-is-brush')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  isPulverization: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('orders-services-is-pulverization')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  isFertilizing: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('orders-services-is-fertilizing')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  active: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('orders-services-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('orders-services-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    farmId: '',
    farmFieldId: '',
    typeCoffeeTreatmentId: '',
    name: '',
    dateService: '',
    description: '',
    optionalDescription: '',
    isBrush: '',
    isPulverization: '',
    isFertilizing: '',
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    getValues,
  } = form

  const farmFieldInfo = useGetFarmsFieldsDashboard()
  const farmInfo = useGetFarmsDashboard()
  const typeCoffeeTreatmentInfo = useGetTypesCoffeesTreatmentsDashboard()

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    farmFieldInfo,
    farmInfo,
    typeCoffeeTreatmentInfo,
    register,
    resetFields,
    getValues,
  }
}
