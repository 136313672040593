import { gql } from 'graphql-request'

export const createDefaultTypeCoffeeMaturationDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_COFFEE_MATURATION_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createDefaultTypeCoffeeMaturationDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypeCoffeeMaturationDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_COFFEE_MATURATION_DASHBOARD(
    $defaultTypeCoffeeMaturationId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultTypeCoffeeMaturationDashboard(
      defaultTypeCoffeeMaturationId: $defaultTypeCoffeeMaturationId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypeCoffeeMaturationDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_COFFEE_MATURATION_DASHBOARD(
    $defaultTypeCoffeeMaturationId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypeCoffeeMaturationDashboard(
      defaultTypeCoffeeMaturationId: $defaultTypeCoffeeMaturationId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypeCoffeeMaturationDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_COFFEE_MATURATION_DASHBOARD(
    $defaultTypeCoffeeMaturationId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypeCoffeeMaturationDashboard(
      defaultTypeCoffeeMaturationId: $defaultTypeCoffeeMaturationId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypeCoffeeMaturationDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_COFFEE_MATURATION_DASHBOARD(
    $defaultTypeCoffeeMaturationId: Int!
  ) {
    deleteForeverDefaultTypeCoffeeMaturationDashboard(
      defaultTypeCoffeeMaturationId: $defaultTypeCoffeeMaturationId
    )
  }
`

export const getDefaultsTypesCoffeesMaturationsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_MATURATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesCoffeesMaturationsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesCoffeesMaturationsContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_MATURATIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsTypesCoffeesMaturationsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
