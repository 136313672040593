import { graphClient } from 'services/graph-client'
import {
  activeAppLogDashboardGQL,
  deleteAppLogDashboardGQL,
  deleteForeverAppLogDashboardGQL,
  createAppLogDashboardGQL,
  updateAppLogDashboardGQL,
} from './queries'
import { AppLog } from 'types/settings/apps-logs-types'

interface ResponseCreate {
  createAppLogDashboard: {
    data: AppLog
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateAppLogDashboard: {
    data: AppLog
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeAppLogDashboard: {
    data: AppLog
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteAppLogDashboard: {
    data: AppLog
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverAppLogDashboard: string
}

interface DataActive {
  appLogId: number
  active: boolean
}

interface DataDeleted {
  appLogId: number
  deleted: boolean
}

interface DataDeleteForever {
  appLogId: number
}

interface DataCreate {
  name: string
  typeAppLogType: 'active' | 'delete' | 'deleteForever' | 'insert' | 'update'
  table: string
  tableIndex: number
  oldJson?: string
  newJson?: string
}

interface DataUpdate {
  appLogId: number
  name: string
  typeAppLogType: 'active' | 'delete' | 'deleteForever' | 'insert' | 'update'
  table: string
  tableIndex: number
  oldJson?: string
  newJson?: string
}

export const activeAppLog = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeAppLogDashboardGQL, {
    ...data,
  })
}

export const deleteAppLog = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteAppLogDashboardGQL, {
    ...data,
  })
}

export const deleteForeverAppLog = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverAppLogDashboardGQL,
    { ...data },
  )
}

export const createAppLog = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(createAppLogDashboardGQL, {
    ...data,
  })
}

export const updateAppLog = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateAppLogDashboardGQL, {
    ...data,
  })
}
