import { graphClient } from 'services/graph-client'
import {
  Company,
  TypeMeasurement,
} from 'types/types-coffees/types-measurements-types'

import {
  getCompaniesDashboardGQL,
  getTypesMeasurementsDashboardGQL,
  getTypesMeasurementsContainsDashboardGQL,
} from './queries'

interface Response {
  getTypesMeasurementsDashboard: {
    data: TypeMeasurement[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getTypesMeasurementsContainsDashboard: {
    data: TypeMeasurement[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  name?: string
  description?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getTypesMeasurementsDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getTypesMeasurementsDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getTypesMeasurementsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getTypesMeasurementsDashboardGQL, {
    take,
    skip,
  })
}

export const getTypesMeasurementsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getTypesMeasurementsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}
