import { ModalFormTemplate } from 'components/common/modal/form-template'
import { t } from 'i18next'
import { useFarmFieldList } from '../../use-farms-fields'
import { useModalFormFilter } from './use-filter-form'
import { FormProvider } from 'react-hook-form'
import { FormFilterControl } from '../../components/form-filter'
import { formatDateDB } from 'helpers/format/format-date'

export const Filter = () => {
  const { filterOpen, handleCloseFilter, handleSetFilter } = useFarmFieldList()

  const {
    control,
    errors,
    form,
    areaInfo,
    coffeePruningInfo,
    farmFieldPeriodInfo,
    farmInfo,
    typeCoffeeColorInfo,
    typeCoffeeHarvestInfo,
    typeCoffeeIrrigationInfo,
    typeCoffeeManagementInfo,
    typeCoffeeMaturationInfo,
    typeCoffeePruningInfo,
    typeCoffeeSizeInfo,
    typeCoffeeVarietyInfo,
    typeCoffeeRenovationInfo,
    register,
    resetFields,
    getValues,
  } = useModalFormFilter()

  const handleConfirm = () => {
    const values: Record<string, any> = {}
    const formValues = getValues()

    if (typeof formValues.areaId != 'undefined') {
      if (typeof formValues.areaId === 'object') {
        values['areaId'] = formValues.areaId.value
      }
    }

    if (typeof formValues.coffeePruningId != 'undefined') {
      if (typeof formValues.coffeePruningId === 'object') {
        values['coffeePruningId'] = formValues.coffeePruningId.value
      }
    }

    if (typeof formValues.farmFieldPeriodId != 'undefined') {
      if (typeof formValues.farmFieldPeriodId === 'object') {
        values['farmFieldPeriodId'] = formValues.farmFieldPeriodId.value
      }
    }

    if (typeof formValues.farmId != 'undefined') {
      if (typeof formValues.farmId === 'object') {
        values['farmId'] = formValues.farmId.value
      }
    }

    if (typeof formValues.typeCoffeeColorId != 'undefined') {
      if (typeof formValues.typeCoffeeColorId === 'object') {
        values['typeCoffeeColorId'] = formValues.typeCoffeeColorId.value
      }
    }

    if (typeof formValues.typeCoffeeHarvestId != 'undefined') {
      if (typeof formValues.typeCoffeeHarvestId === 'object') {
        values['typeCoffeeHarvestId'] = formValues.typeCoffeeHarvestId.value
      }
    }

    if (typeof formValues.typeCoffeeIrrigationId != 'undefined') {
      if (typeof formValues.typeCoffeeIrrigationId === 'object') {
        values['typeCoffeeIrrigationId'] =
          formValues.typeCoffeeIrrigationId.value
      }
    }

    if (typeof formValues.typeCoffeeManagementId != 'undefined') {
      if (typeof formValues.typeCoffeeManagementId === 'object') {
        values['typeCoffeeManagementId'] =
          formValues.typeCoffeeManagementId.value
      }
    }

    if (typeof formValues.typeCoffeeMaturationId != 'undefined') {
      if (typeof formValues.typeCoffeeMaturationId === 'object') {
        values['typeCoffeeMaturationId'] =
          formValues.typeCoffeeMaturationId.value
      }
    }

    if (typeof formValues.typeCoffeePruningId != 'undefined') {
      if (typeof formValues.typeCoffeePruningId === 'object') {
        values['typeCoffeePruningId'] = formValues.typeCoffeePruningId.value
      }
    }

    if (typeof formValues.typeCoffeeSizeId != 'undefined') {
      if (typeof formValues.typeCoffeeSizeId === 'object') {
        values['typeCoffeeSizeId'] = formValues.typeCoffeeSizeId.value
      }
    }

    if (typeof formValues.typeCoffeeVarietyId != 'undefined') {
      if (typeof formValues.typeCoffeeVarietyId === 'object') {
        values['typeCoffeeVarietyId'] = formValues.typeCoffeeVarietyId.value
      }
    }

    if (typeof formValues.typeCoffeeRenovationId != 'undefined') {
      if (typeof formValues.typeCoffeeRenovationId === 'object') {
        values['typeCoffeeRenovationId'] =
          formValues.typeCoffeeRenovationId.value
      }
    }

    if (typeof formValues.name != 'undefined' && formValues.name != '') {
      values['name'] = formValues.name
    }

    if (
      typeof formValues.monthPlanting != 'undefined' &&
      formValues.monthPlanting != ''
    ) {
      values['monthPlanting'] = formValues.monthPlanting
    }

    if (
      typeof formValues.geographicLocation != 'undefined' &&
      formValues.geographicLocation != ''
    ) {
      values['geographicLocation'] = formValues.geographicLocation
    }

    if (
      typeof formValues.yearPlanting != 'undefined' &&
      formValues.yearPlanting != ''
    ) {
      values['yearPlanting'] = +formValues.yearPlanting
    }

    if (
      typeof formValues.plantFailure != 'undefined' &&
      formValues.plantFailure != ''
    ) {
      values['plantFailure'] = +formValues.plantFailure
    }

    if (
      typeof formValues.plantingQuantity != 'undefined' &&
      formValues.plantingQuantity != ''
    ) {
      values['plantingQuantity'] = +formValues.plantingQuantity
    }

    if (typeof formValues.active != 'undefined') {
      if (typeof formValues.active === 'object') {
        if (formValues.active.value === 'true') {
          values['active'] = true
        }
        if (formValues.active.value === 'false') {
          values['active'] = false
        }
      }
    }

    if (typeof formValues.deleted != 'undefined') {
      if (typeof formValues.deleted === 'object') {
        if (formValues.deleted.value === 'true') {
          values['deleted'] = true
        }
        if (formValues.deleted.value === 'false') {
          values['deleted'] = false
        }
      }
    }

    if (
      typeof formValues.createdAtInitial != 'undefined' &&
      formValues.createdAtInitial != '' &&
      typeof formValues.createdAtFinal != 'undefined' &&
      formValues.createdAtFinal != ''
    ) {
      values['createdAtInitial'] = formatDateDB(formValues.createdAtInitial)
      values['createdAtFinal'] = formatDateDB(formValues.createdAtFinal)
    }

    handleSetFilter(values)
    handleCloseFilter()
  }

  const handleClear = () => {
    resetFields()
  }

  return (
    <ModalFormTemplate
      isOpen={filterOpen}
      //full={false}
      //middle
      full={true}
      title={t('filters')}
      cancelLabel={t('cancel-filter')}
      handleConfirm={handleConfirm}
      handleClose={handleCloseFilter}
      handleCancel={handleClear}
    >
      <form
        style={{ paddingLeft: 4 }}
        className="mt-4 pe-1 d-flex flex-wrap gap-3"
      >
        <FormProvider {...form}>
          <FormFilterControl
            control={control}
            errors={errors}
            areaInfo={areaInfo}
            coffeePruningInfo={coffeePruningInfo}
            farmFieldPeriodInfo={farmFieldPeriodInfo}
            farmInfo={farmInfo}
            typeCoffeeColorInfo={typeCoffeeColorInfo}
            typeCoffeeHarvestInfo={typeCoffeeHarvestInfo}
            typeCoffeeIrrigationInfo={typeCoffeeIrrigationInfo}
            typeCoffeeManagementInfo={typeCoffeeManagementInfo}
            typeCoffeeMaturationInfo={typeCoffeeMaturationInfo}
            typeCoffeePruningInfo={typeCoffeePruningInfo}
            typeCoffeeSizeInfo={typeCoffeeSizeInfo}
            typeCoffeeVarietyInfo={typeCoffeeVarietyInfo}
            typeCoffeeRenovationInfo={typeCoffeeRenovationInfo}
            register={register}
          />
        </FormProvider>
      </form>
    </ModalFormTemplate>
  )
}
