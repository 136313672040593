import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeDefaultTypeCoffeeHarvest,
  deleteDefaultTypeCoffeeHarvest,
} from 'services/defaults/defaults-types-coffees-harvests/defaults-types-coffees-harvests-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectDefaultTypeCoffeeHarvest,
} from 'slices/defaults/defaults-types-coffees-harvests-reducer'
import { DefaultTypeCoffeeHarvest } from 'types/defaults/defaults-types-coffees-harvests-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('defaults_types_coffees_harvests')
  const dispatch = useDispatch()
  const item = cell?.row?.original as DefaultTypeCoffeeHarvest

  const handleOpenForm = () => {
    dispatch(selectDefaultTypeCoffeeHarvest(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(
      selectDefaultTypeCoffeeHarvest(itemWithoutId as DefaultTypeCoffeeHarvest),
    )
    dispatch(openForm())
  }

  const { mutateAsync: deleteDefaultTypeCoffeeHarvestDashboardFn } =
    useMutation({
      mutationFn: deleteDefaultTypeCoffeeHarvest,
    })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteDefaultTypeCoffeeHarvestDashboard } =
      await deleteDefaultTypeCoffeeHarvestDashboardFn({
        defaultTypeCoffeeHarvestId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteDefaultTypeCoffeeHarvestDashboard?.data))
  }

  const { mutateAsync: activeDefaultTypeCoffeeHarvestDashboardFn } =
    useMutation({
      mutationFn: activeDefaultTypeCoffeeHarvest,
    })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeDefaultTypeCoffeeHarvestDashboard } =
      await activeDefaultTypeCoffeeHarvestDashboardFn({
        defaultTypeCoffeeHarvestId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeDefaultTypeCoffeeHarvestDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
