import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeFarmFieldPeriod,
  deleteFarmFieldPeriod,
} from 'services/farms/farms-fields-periods/farms-fields-periods-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectFarmFieldPeriod,
} from 'slices/farms/farms-fields-periods-reducer'
import { FarmFieldPeriod } from 'types/farms/farms-fields-periods-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('farms_fields_periods')
  const dispatch = useDispatch()
  const item = cell?.row?.original as FarmFieldPeriod

  const handleOpenForm = () => {
    dispatch(selectFarmFieldPeriod(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectFarmFieldPeriod(itemWithoutId as FarmFieldPeriod))
    dispatch(openForm())
  }

  const { mutateAsync: deleteFarmFieldPeriodDashboardFn } = useMutation({
    mutationFn: deleteFarmFieldPeriod,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteFarmFieldPeriodDashboard } =
      await deleteFarmFieldPeriodDashboardFn({
        farmFieldPeriodId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteFarmFieldPeriodDashboard?.data))
  }

  const { mutateAsync: activeFarmFieldPeriodDashboardFn } = useMutation({
    mutationFn: activeFarmFieldPeriod,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeFarmFieldPeriodDashboard } =
      await activeFarmFieldPeriodDashboardFn({
        farmFieldPeriodId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeFarmFieldPeriodDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
