import { createSelector } from 'reselect'
import { DefaultTypeCoffeeSize } from 'types/defaults/defaults-types-coffees-sizes-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverDefaultTypeCoffeeSize } from 'services/defaults/defaults-types-coffees-sizes/defaults-types-coffees-sizes-cruds'
import {
  getDefaultsTypesCoffeesSizesDashboard,
  GetParams,
  getDefaultsTypesCoffeesSizesContainsDashboard,
} from 'services/defaults/defaults-types-coffees-sizes/defaults-types-coffees-sizes-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/defaults/defaults-types-coffees-sizes-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const defaultTypeCoffeeSizeListSelector = (state: StateRoot) =>
  state.defaultsTypesCoffeesSizesReducer

const selectorResult = createSelector(
  defaultTypeCoffeeSizeListSelector,
  (defaultTypeCoffeeSizeListReducer) => {
    return defaultTypeCoffeeSizeListReducer
  },
)

export const useDefaultTypeCoffeeSizeList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('defaults_types_coffees_sizes')

  const {
    data: dataDefaultTypeCoffeeSize,
    deleteModalOpen,
    defaultTypeCoffeeSizeIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'defaultTypeCoffeeSize', 'list', { params }],
    queryFn: () => getDefaultsTypesCoffeesSizesDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverDefaultTypeCoffeeSize,
  })

  const handleStoreData = useCallback(
    (
      defaultsTypesCoffeesSizes: DefaultTypeCoffeeSize[],
      totalPages: number | null,
    ) => {
      if (!defaultsTypesCoffeesSizes) return

      dispatch(setData(defaultsTypesCoffeesSizes))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverDefaultTypeCoffeeSize = useCallback(async () => {
    if (!defaultTypeCoffeeSizeIdToDelete) return

    const response = await mutateAsync({
      defaultTypeCoffeeSizeId: +defaultTypeCoffeeSizeIdToDelete,
    })

    if (
      response?.deleteForeverDefaultTypeCoffeeSizeDashboard ===
      'deleted_forever'
    ) {
      dispatch(deleteUniqueData({ id: defaultTypeCoffeeSizeIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [defaultTypeCoffeeSizeIdToDelete])

  useEffect(() => {
    const defaultsTypesCoffeesSizesResult =
      data?.getDefaultsTypesCoffeesSizesDashboard

    if (defaultsTypesCoffeesSizesResult) {
      handleStoreData(
        defaultsTypesCoffeesSizesResult?.data,
        defaultsTypesCoffeesSizesResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response =
        await getDefaultsTypesCoffeesSizesContainsDashboard(value)
      const defaultsTypesCoffeesSizesResult =
        response?.getDefaultsTypesCoffeesSizesContainsDashboard

      handleStoreData(
        defaultsTypesCoffeesSizesResult?.data,
        defaultsTypesCoffeesSizesResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    defaultTypeCoffeeSizeIdToDelete,
    defaultTypeCoffeeSize: data?.getDefaultsTypesCoffeesSizesDashboard?.data,
    isLoading,
    isFetching,
    dataDefaultTypeCoffeeSize,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverDefaultTypeCoffeeSize,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
