import { gql } from 'graphql-request'

export const createAgriculturalInputSubTypeDashboardGQL = gql`
  mutation CREATE_AGRICULTURAL_INPUT_SUB_TYPE_DASHBOARD(
    $agriculturalInputTypeId: Int!
    $name: String!
    $description: String!
  ) {
    createAgriculturalInputSubTypeDashboard(
      agriculturalInputTypeId: $agriculturalInputTypeId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        agriculturalInputTypeId
        agriculturalInputType {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateAgriculturalInputSubTypeDashboardGQL = gql`
  mutation UPDATE_AGRICULTURAL_INPUT_SUB_TYPE_DASHBOARD(
    $agriculturalInputSubTypeId: Int!
    $agriculturalInputTypeId: Int
    $name: String
    $description: String
  ) {
    updateAgriculturalInputSubTypeDashboard(
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputTypeId: $agriculturalInputTypeId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        agriculturalInputTypeId
        agriculturalInputType {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteAgriculturalInputSubTypeDashboardGQL = gql`
  mutation DELETE_AGRICULTURAL_INPUT_SUB_TYPE_DASHBOARD(
    $agriculturalInputSubTypeId: Int!
    $deleted: Boolean!
  ) {
    deleteAgriculturalInputSubTypeDashboard(
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        agriculturalInputTypeId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeAgriculturalInputSubTypeDashboardGQL = gql`
  mutation ACTIVE_AGRICULTURAL_INPUT_SUB_TYPE_DASHBOARD(
    $agriculturalInputSubTypeId: Int!
    $active: Boolean!
  ) {
    activeAgriculturalInputSubTypeDashboard(
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        agriculturalInputTypeId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverAgriculturalInputSubTypeDashboardGQL = gql`
  mutation DELETE_FOREVER_AGRICULTURAL_INPUT_SUB_TYPE_DASHBOARD(
    $agriculturalInputSubTypeId: Int!
  ) {
    deleteForeverAgriculturalInputSubTypeDashboard(
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
    )
  }
`

export const getAgriculturalsInputsSubsTypesDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_SUBS_TYPES_DASHBOARD(
    $take: Int
    $skip: Int
    $agriculturalInputTypeId: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getAgriculturalsInputsSubsTypesDashboard(
      take: $take
      skip: $skip
      agriculturalInputTypeId: $agriculturalInputTypeId
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        agriculturalInputTypeId
        agriculturalInputType {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalInputTypeDashboardIdGQL = gql`
  query GET_AGRICULTURAL_INPUT_TYPE_DASHBOARD_ID(
    $agriculturalInputTypeId: Int!
  ) {
    getAgriculturalInputTypeDashboard(
      agriculturalInputTypeId: $agriculturalInputTypeId
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsTypesDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_TYPES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getAgriculturalsInputsTypesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAgriculturalsInputsSubsTypesContainsDashboardGQL = gql`
  query GET_AGRICULTURALS_INPUTS_SUBS_TYPES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getAgriculturalsInputsSubsTypesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        agriculturalInputTypeId
        agriculturalInputType {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
