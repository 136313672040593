import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { InputCurrencyComponent } from 'components/common/inputs/input-currency'
import { InputDecimalComponent } from 'components/common/inputs/input-decimal'
import { ControlledInputPhone } from 'components/controlled-components/controlled-input-phone'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetExamplesOthersOnesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-examples-others-ones-dashboard'
import { useGetExamplesOthersTwosDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-examples-others-twos-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  exampleOtherOneInfo: ReturnType<typeof useGetExamplesOthersOnesDashboard>
  exampleOtherTwoInfo: ReturnType<typeof useGetExamplesOthersTwosDashboard>
  register: UseFormRegister<FormData>
  handleSelectCountry: (country: string) => void
}

export const FormControl = ({
  control,
  errors,
  exampleOtherOneInfo,
  exampleOtherTwoInfo,
  register,
  handleSelectCountry,
}: Props) => {
  const permissions = useGetPermissions('examples_tables')
  const { exampleTableSelected } = useSelector(selectorResult)
  const disabled = !!(exampleTableSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={exampleOtherOneInfo?.examplesOthersOnesDashboard}
            info={exampleOtherOneInfo}
            label={t('examples-tables-example-other-one-id')}
            name="exampleOtherOneId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={exampleOtherTwoInfo?.examplesOthersTwosDashboard}
            info={exampleOtherTwoInfo}
            label={t('examples-tables-example-other-two-id')}
            name="exampleOtherTwoId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="typeBoolean"
            label={t('examples-tables-type-boolean')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="typeEnum"
            label={t('examples-tables-type-enum')}
            data={[
              { id: 'exampleOne', name: 'exampleOne' },
              { id: 'exampleTwo', name: 'exampleTwo' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('examples-tables-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.typeText?.message}
            {...register('typeText')}
            label={t('examples-tables-type-text')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="typeInt"
            label={t('examples-tables-type-int')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="typeDatetime"
            label={t('examples-tables-type-datetime')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.typeDecimal?.message}
            {...register('typeDecimal')}
            label={t('examples-tables-type-decimal')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputCurrencyComponent
            locale="en-US" //pt-BR es-es etc..
            currency="USD" //BRL EUR etc..
            label={t('examples-tables-type-currency')}
            {...register('typeCurrency')}
            error={errors?.typeCurrency?.message}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            type="email"
            error={errors?.email?.message}
            {...register('email')}
            label={t('examples-tables-email')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputPhone
            control={control}
            name="phone.phone"
            label={t('examples-tables-phone')}
            handleSelectCountry={handleSelectCountry}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
