import { gql } from 'graphql-request'

export const createEmployeeDashboardGQL = gql`
  mutation CREATE_EMPLOYEE_DASHBOARD(
    $farmId: Int
    $employeeFunctionId: Int!
    $typeEmployee: TypeEmployee!
    $name: String!
    $documentOne: String
    $documentTwo: String
    $photo: String
    $monthSalaryCurrency: Float
    $monthBonusCurrency: Float
    $monthSalaryFamilyCurrency: Float
    $daySalaryCurrency: Float
    $birthday: String
    $email: String
    $phone: String
  ) {
    createEmployeeDashboard(
      farmId: $farmId
      employeeFunctionId: $employeeFunctionId
      typeEmployee: $typeEmployee
      name: $name
      documentOne: $documentOne
      documentTwo: $documentTwo
      photo: $photo
      monthSalaryCurrency: $monthSalaryCurrency
      monthBonusCurrency: $monthBonusCurrency
      monthSalaryFamilyCurrency: $monthSalaryFamilyCurrency
      daySalaryCurrency: $daySalaryCurrency
      birthday: $birthday
      email: $email
      phone: $phone
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        employeeFunctionId
        employeeFunction {
          id
          name
        }
        typeEmployee
        name
        documentOne
        documentTwo
        photo
        monthSalaryCurrency
        monthBonusCurrency
        monthSalaryFamilyCurrency
        daySalaryCurrency
        birthday
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateEmployeeDashboardGQL = gql`
  mutation UPDATE_EMPLOYEE_DASHBOARD(
    $employeeId: Int!
    $farmId: Int
    $employeeFunctionId: Int
    $typeEmployee: TypeEmployee
    $name: String
    $documentOne: String
    $documentTwo: String
    $photo: String
    $monthSalaryCurrency: Float
    $monthBonusCurrency: Float
    $monthSalaryFamilyCurrency: Float
    $daySalaryCurrency: Float
    $birthday: String
    $email: String
    $phone: String
  ) {
    updateEmployeeDashboard(
      employeeId: $employeeId
      farmId: $farmId
      employeeFunctionId: $employeeFunctionId
      typeEmployee: $typeEmployee
      name: $name
      documentOne: $documentOne
      documentTwo: $documentTwo
      photo: $photo
      monthSalaryCurrency: $monthSalaryCurrency
      monthBonusCurrency: $monthBonusCurrency
      monthSalaryFamilyCurrency: $monthSalaryFamilyCurrency
      daySalaryCurrency: $daySalaryCurrency
      birthday: $birthday
      email: $email
      phone: $phone
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        employeeFunctionId
        employeeFunction {
          id
          name
        }
        typeEmployee
        name
        documentOne
        documentTwo
        photo
        monthSalaryCurrency
        monthBonusCurrency
        monthSalaryFamilyCurrency
        daySalaryCurrency
        birthday
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteEmployeeDashboardGQL = gql`
  mutation DELETE_EMPLOYEE_DASHBOARD($employeeId: Int!, $deleted: Boolean!) {
    deleteEmployeeDashboard(employeeId: $employeeId, deleted: $deleted) {
      error
      status
      data {
        id
        companyId
        farmId
        employeeFunctionId
        typeEmployee
        name
        documentOne
        documentTwo
        photo
        monthSalaryCurrency
        monthBonusCurrency
        monthSalaryFamilyCurrency
        daySalaryCurrency
        birthday
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeEmployeeDashboardGQL = gql`
  mutation ACTIVE_EMPLOYEE_DASHBOARD($employeeId: Int!, $active: Boolean!) {
    activeEmployeeDashboard(employeeId: $employeeId, active: $active) {
      error
      status
      data {
        id
        companyId
        farmId
        employeeFunctionId
        typeEmployee
        name
        documentOne
        documentTwo
        photo
        monthSalaryCurrency
        monthBonusCurrency
        monthSalaryFamilyCurrency
        daySalaryCurrency
        birthday
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverEmployeeDashboardGQL = gql`
  mutation DELETE_FOREVER_EMPLOYEE_DASHBOARD($employeeId: Int!) {
    deleteForeverEmployeeDashboard(employeeId: $employeeId)
  }
`

export const getEmployeesDashboardGQL = gql`
  query GET_EMPLOYEES_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $employeeFunctionId: Int
    $typeEmployee: TypeEmployee
    $name: String
    $documentOne: String
    $documentTwo: String
    $monthSalaryCurrency: Float
    $monthBonusCurrency: Float
    $monthSalaryFamilyCurrency: Float
    $daySalaryCurrency: Float
    $birthday: String
    $email: String
    $phone: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getEmployeesDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      employeeFunctionId: $employeeFunctionId
      typeEmployee: $typeEmployee
      name: $name
      documentOne: $documentOne
      documentTwo: $documentTwo
      monthSalaryCurrency: $monthSalaryCurrency
      monthBonusCurrency: $monthBonusCurrency
      monthSalaryFamilyCurrency: $monthSalaryFamilyCurrency
      daySalaryCurrency: $daySalaryCurrency
      birthday: $birthday
      email: $email
      phone: $phone
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        employeeFunctionId
        employeeFunction {
          id
          name
        }
        typeEmployee
        name
        documentOne
        documentTwo
        photo
        monthSalaryCurrency
        monthBonusCurrency
        monthSalaryFamilyCurrency
        daySalaryCurrency
        birthday
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getEmployeeFunctionDashboardIdGQL = gql`
  query GET_EMPLOYEE_FUNCTION_DASHBOARD_ID($employeeFunctionId: Int!) {
    getEmployeeFunctionDashboard(employeeFunctionId: $employeeFunctionId) {
      error
      status
      data {
        id
        companyId
        farmId
        name
        description
        baseSalaryCurrency
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getEmployeesFunctionsDashboardGQL = gql`
  query GET_EMPLOYEES_FUNCTIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getEmployeesFunctionsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        name
        description
        baseSalaryCurrency
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getEmployeesContainsDashboardGQL = gql`
  query GET_EMPLOYEES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $documentOne: String
    $documentTwo: String
    $photo: String
    $email: String
    $phone: String
  ) {
    getEmployeesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      documentOne: $documentOne
      documentTwo: $documentTwo
      photo: $photo
      email: $email
      phone: $phone
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        employeeFunctionId
        employeeFunction {
          id
          name
        }
        typeEmployee
        name
        documentOne
        documentTwo
        photo
        monthSalaryCurrency
        monthBonusCurrency
        monthSalaryFamilyCurrency
        daySalaryCurrency
        birthday
        email
        phone
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
