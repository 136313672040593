import { graphClient } from 'services/graph-client'
import { Company } from 'types/settings/companies-types'

import {
  getCompaniesDashboardGQL,
  getCompaniesContainsDashboardGQL,
} from './queries'

interface Response {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getCompaniesContainsDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  codeUid?: string
  name?: string
  description?: string
  document?: string
  documentTwo?: string
  documentThree?: string
  email?: string
  phone?: string
  country?: string
}

export interface GetParams {
  page?: number
  codeUid?: string
  name?: string
  description?: string
  document?: string
  documentTwo?: string
  documentThree?: string
  email?: string
  phone?: string
  country?: string
  hectareQuantity?: number
  usefullHectareQuantity?: number
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getCompaniesDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getCompaniesDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getCompaniesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

export const getCompaniesContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getCompaniesContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}
