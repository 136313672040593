import { Status } from './list-columns'
import { t } from 'i18next'

export const columns = [
  {
    Header: t('id'),
    accessor: 'id',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span className="fw-medium link-primary">{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-area-id'),
    accessor: 'area.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-coffee-pruning-id'),
    accessor: 'coffeePruning.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-company-id'),
    accessor: 'company.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-farm-field-period-id'),
    accessor: 'farmFieldPeriod.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-farm-id'),
    accessor: 'farm.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-type-coffee-color-id'),
    accessor: 'typeCoffeeColor.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-type-coffee-harvest-id'),
    accessor: 'typeCoffeeHarvest.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-type-coffee-irrigation-id'),
    accessor: 'typeCoffeeIrrigation.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-type-coffee-management-id'),
    accessor: 'typeCoffeeManagement.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-type-coffee-maturation-id'),
    accessor: 'typeCoffeeMaturation.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-type-coffee-pruning-id'),
    accessor: 'typeCoffeePruning.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-type-coffee-size-id'),
    accessor: 'typeCoffeeSize.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-type-coffee-variety-id'),
    accessor: 'typeCoffeeVariety.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-type-coffee-renovation-id'),
    accessor: 'typeCoffeeRenovation.name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-name'),
    accessor: 'name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-year-planting'),
    accessor: 'yearPlanting',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-month-planting'),
    accessor: 'monthPlanting',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-line-spacing'),
    accessor: 'lineSpacing',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-plant-spacing'),
    accessor: 'plantSpacing',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-plant-failure'),
    accessor: 'plantFailure',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-linear-quantity'),
    accessor: 'linearQuantity',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-planting-quantity'),
    accessor: 'plantingQuantity',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-geographic-location'),
    accessor: 'geographicLocation',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-hectare-quantity'),
    accessor: 'hectareQuantity',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('farms-fields-usefull-hectare-quantity'),
    accessor: 'usefullHectareQuantity',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('active'),
    accessor: 'actions',
    filterable: false,
    Cell: (props: any, cellProps: any) => {
      return <Status {...cellProps} {...props} showActive={true} />
    },
  },
]
