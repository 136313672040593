import { createSelector } from 'reselect'
import { TypeCultivation } from 'types/types-coffees/types-cultivations-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverTypeCultivation } from 'services/types-coffees/types-cultivations/types-cultivations-cruds'
import {
  getTypesCultivationsDashboard,
  GetParams,
  getTypesCultivationsContainsDashboard,
} from 'services/types-coffees/types-cultivations/types-cultivations-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/types-coffees/types-cultivations-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const typeCultivationListSelector = (state: StateRoot) =>
  state.typesCultivationsReducer

const selectorResult = createSelector(
  typeCultivationListSelector,
  (typeCultivationListReducer) => {
    return typeCultivationListReducer
  },
)

export const useTypeCultivationList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('types_cultivations')

  const {
    data: dataTypeCultivation,
    deleteModalOpen,
    typeCultivationIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'typeCultivation', 'list', { params }],
    queryFn: () => getTypesCultivationsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverTypeCultivation,
  })

  const handleStoreData = useCallback(
    (typesCultivations: TypeCultivation[], totalPages: number | null) => {
      if (!typesCultivations) return

      dispatch(setData(typesCultivations))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverTypeCultivation = useCallback(async () => {
    if (!typeCultivationIdToDelete) return

    const response = await mutateAsync({
      typeCultivationId: +typeCultivationIdToDelete,
    })

    if (response?.deleteForeverTypeCultivationDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: typeCultivationIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [typeCultivationIdToDelete])

  useEffect(() => {
    const typesCultivationsResult = data?.getTypesCultivationsDashboard

    if (typesCultivationsResult) {
      handleStoreData(
        typesCultivationsResult?.data,
        typesCultivationsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getTypesCultivationsContainsDashboard(value)
      const typesCultivationsResult =
        response?.getTypesCultivationsContainsDashboard

      handleStoreData(
        typesCultivationsResult?.data,
        typesCultivationsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    typeCultivationIdToDelete,
    typeCultivation: data?.getTypesCultivationsDashboard?.data,
    isLoading,
    isFetching,
    dataTypeCultivation,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverTypeCultivation,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
