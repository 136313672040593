import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetEmployeesFunctionsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-employees-functions-dashboard'

const schemaFilter = z.object({
  farmId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('employees-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  employeeFunctionId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('employees-employee-function-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeEmployee: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('employees-type-employee')),
    z.object({ label: z.string(), value: z.string() }),
  ]),

  name: z.string().min(1, t('inform-field') + ' ' + t('employees-name')),

  documentOne: z.string(),
  documentTwo: z.string(),
  photo: z.string(),
  birthday: z.union([z.date(), z.string().min(1)]),

  email: z.string().min(1, t('inform-field') + ' ' + t('employees-email')),

  phone: z
    .object({
      phone: z.string(),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('employees-phone'),
      path: ['phone'],
    }),
  active: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('employees-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('employees-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    farmId: '',
    employeeFunctionId: '',
    typeEmployee: '',
    name: '',
    documentOne: '',
    documentTwo: '',
    photo: '',
    birthday: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = form

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  const farmInfo = useGetFarmsDashboard()
  const employeeFunctionInfo = useGetEmployeesFunctionsDashboard()

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    farmInfo,
    employeeFunctionInfo,
    register,
    handleSelectCountry,
    resetFields,
    getValues,
  }
}
