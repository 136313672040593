import { graphClient } from 'services/graph-client'
import {
  activeFarmFieldPeriodDashboardGQL,
  deleteFarmFieldPeriodDashboardGQL,
  deleteForeverFarmFieldPeriodDashboardGQL,
  createFarmFieldPeriodDashboardGQL,
  updateFarmFieldPeriodDashboardGQL,
} from './queries'
import { FarmFieldPeriod } from 'types/farms/farms-fields-periods-types'

interface ResponseCreate {
  createFarmFieldPeriodDashboard: {
    data: FarmFieldPeriod
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateFarmFieldPeriodDashboard: {
    data: FarmFieldPeriod
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeFarmFieldPeriodDashboard: {
    data: FarmFieldPeriod
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteFarmFieldPeriodDashboard: {
    data: FarmFieldPeriod
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverFarmFieldPeriodDashboard: string
}

interface DataActive {
  farmFieldPeriodId: number
  active: boolean
}

interface DataDeleted {
  farmFieldPeriodId: number
  deleted: boolean
}

interface DataDeleteForever {
  farmFieldPeriodId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  farmFieldPeriodId: number
  name: string
  description?: string
}

export const activeFarmFieldPeriod = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeFarmFieldPeriodDashboardGQL,
    { ...data },
  )
}

export const deleteFarmFieldPeriod = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteFarmFieldPeriodDashboardGQL,
    { ...data },
  )
}

export const deleteForeverFarmFieldPeriod = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverFarmFieldPeriodDashboardGQL,
    { ...data },
  )
}

export const createFarmFieldPeriod = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createFarmFieldPeriodDashboardGQL,
    { ...data },
  )
}

export const updateFarmFieldPeriod = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateFarmFieldPeriodDashboardGQL,
    { ...data },
  )
}
