import { createSelector } from 'reselect'
import { AgriculturalInput } from 'types/agriculturals-inputs/agriculturals-inputs-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverAgriculturalInput } from 'services/agriculturals-inputs/agriculturals-inputs/agriculturals-inputs-cruds'
import {
  getAgriculturalsInputsDashboard,
  GetParams,
  getAgriculturalsInputsContainsDashboard,
} from 'services/agriculturals-inputs/agriculturals-inputs/agriculturals-inputs-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/agriculturals-inputs/agriculturals-inputs-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const agriculturalInputListSelector = (state: StateRoot) =>
  state.agriculturalsInputsReducer

const selectorResult = createSelector(
  agriculturalInputListSelector,
  (agriculturalInputListReducer) => {
    return agriculturalInputListReducer
  },
)

export const useAgriculturalInputList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('agriculturals_inputs')

  const {
    data: dataAgriculturalInput,
    deleteModalOpen,
    agriculturalInputIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'agriculturalInput', 'list', { params }],
    queryFn: () => getAgriculturalsInputsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverAgriculturalInput,
  })

  const handleStoreData = useCallback(
    (agriculturalsInputs: AgriculturalInput[], totalPages: number | null) => {
      if (!agriculturalsInputs) return

      dispatch(setData(agriculturalsInputs))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverAgriculturalInput = useCallback(async () => {
    if (!agriculturalInputIdToDelete) return

    const response = await mutateAsync({
      agriculturalInputId: +agriculturalInputIdToDelete,
    })

    if (
      response?.deleteForeverAgriculturalInputDashboard === 'deleted_forever'
    ) {
      dispatch(deleteUniqueData({ id: agriculturalInputIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [agriculturalInputIdToDelete])

  useEffect(() => {
    const agriculturalsInputsResult = data?.getAgriculturalsInputsDashboard

    if (agriculturalsInputsResult) {
      handleStoreData(
        agriculturalsInputsResult?.data,
        agriculturalsInputsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getAgriculturalsInputsContainsDashboard(value)
      const agriculturalsInputsResult =
        response?.getAgriculturalsInputsContainsDashboard

      handleStoreData(
        agriculturalsInputsResult?.data,
        agriculturalsInputsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    agriculturalInputIdToDelete,
    agriculturalInput: data?.getAgriculturalsInputsDashboard?.data,
    isLoading,
    isFetching,
    dataAgriculturalInput,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverAgriculturalInput,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
