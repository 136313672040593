import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { InputDecimalComponent } from 'components/common/inputs/input-decimal'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetOrdersServicesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-orders-services-dashboard'
import { useGetAgriculturalsInputsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  farmFieldInfo: ReturnType<typeof useGetFarmsFieldsDashboard>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  orderServiceInfo: ReturnType<typeof useGetOrdersServicesDashboard>
  agriculturalInputInfo: ReturnType<typeof useGetAgriculturalsInputsDashboard>
  register: UseFormRegister<FormData>
}

export const FormControl = ({
  control,
  errors,
  farmFieldInfo,
  farmInfo,
  orderServiceInfo,
  agriculturalInputInfo,
  register,
}: Props) => {
  const permissions = useGetPermissions('orders_services_agriculturals_inputs')
  const { orderServiceAgriculturalInputSelected } = useSelector(selectorResult)
  const disabled = !!(
    orderServiceAgriculturalInputSelected && !permissions.canEdit
  )

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmFieldInfo?.farmsFieldsDashboard}
            info={farmFieldInfo}
            label={t('orders-services-agriculturals-inputs-farm-field-id')}
            name="farmFieldId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('orders-services-agriculturals-inputs-farm-id')}
            name="farmId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={orderServiceInfo?.ordersServicesDashboard}
            info={orderServiceInfo}
            label={t('orders-services-agriculturals-inputs-order-service-id')}
            name="orderServiceId"
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={agriculturalInputInfo?.agriculturalsInputsDashboard}
            info={agriculturalInputInfo}
            label={t(
              'orders-services-agriculturals-inputs-agricultural-input-id',
            )}
            name="agriculturalInputId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('orders-services-agriculturals-inputs-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.valueAgriculturalInput?.message}
            {...register('valueAgriculturalInput')}
            label={t(
              'orders-services-agriculturals-inputs-value-agricultural-input',
            )}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.quantityPerPlant?.message}
            {...register('quantityPerPlant')}
            label={t('orders-services-agriculturals-inputs-quantity-per-plant')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.expectedTotalQuantity?.message}
            {...register('expectedTotalQuantity')}
            label={t(
              'orders-services-agriculturals-inputs-expected-total-quantity',
            )}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.realTotalQuantity?.message}
            {...register('realTotalQuantity')}
            label={t(
              'orders-services-agriculturals-inputs-real-total-quantity',
            )}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
