import { createSlice } from '@reduxjs/toolkit'
import { AppLog } from 'types/settings/apps-logs-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  appLogSelected: null | AppLog
  deleteModalOpen: boolean
  appLogIdToDelete: null | string
  data: AppLog[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  appLogSelected: null,
  appLogIdToDelete: null,
  data: [],
  total: 0,
}

const appLogSlice = createSlice({
  initialState,
  name: 'apps-logs-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectAppLog(state, { payload }: { payload: AppLog | null }) {
      state.appLogSelected = payload
    },
    setData(state, { payload }: { payload: AppLog[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: AppLog }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: AppLog }) {
      state.data = state.data.map((appLog) => {
        return appLog.id === payload.id ? { ...appLog, ...payload } : appLog
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter((appLog) => +appLog.id !== +payload.id)
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.appLogIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.appLogIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectAppLog,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = appLogSlice.actions
export default appLogSlice.reducer
