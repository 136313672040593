import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import {
  createDefaultTypeCoffeeTreatment,
  updateDefaultTypeCoffeeTreatment,
} from 'services/defaults/defaults-types-coffees-treatments/defaults-types-coffees-treatments-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectDefaultTypeCoffeeTreatment,
} from 'slices/defaults/defaults-types-coffees-treatments-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('defaults-types-coffees-treatments-name'),
    ),

  description: z.string(),
  typeToolId: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('defaults-types-coffees-treatments-type-tool-id'),
    ),
})

export type FormData = z.infer<typeof schema>

const defaultTypeCoffeeTreatmentModalFormSelector = (state: StateRoot) => {
  return state.defaultsTypesCoffeesTreatmentsReducer
}

export const selectorResult = createSelector(
  defaultTypeCoffeeTreatmentModalFormSelector,
  (defaultTypeCoffeeTreatmentReducer) => {
    return defaultTypeCoffeeTreatmentReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, defaultTypeCoffeeTreatmentSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('defaults_types_coffees_treatments')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectDefaultTypeCoffeeTreatment(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createDefaultTypeCoffeeTreatmentFn, isPending } =
    useMutation({
      mutationFn: createDefaultTypeCoffeeTreatment,
    })

  const {
    mutateAsync: updateDefaultTypeCoffeeTreatmentFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateDefaultTypeCoffeeTreatment,
  })

  const cleanFields: FormData = {
    name: '',
    description: '',
    typeToolId: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!defaultTypeCoffeeTreatmentSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: defaultTypeCoffeeTreatmentSelected.name,
      description: defaultTypeCoffeeTreatmentSelected.description,
      typeToolId: `${defaultTypeCoffeeTreatmentSelected.typeToolId}`,
    }

    reset(defaultTypeCoffeeTreatmentSelected ? fields : cleanFields)
  }, [defaultTypeCoffeeTreatmentSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        name: data?.name,
        description: data?.description,
        typeToolId: +data?.typeToolId,
      }

      if (
        defaultTypeCoffeeTreatmentSelected &&
        defaultTypeCoffeeTreatmentSelected?.id
      ) {
        const { updateDefaultTypeCoffeeTreatmentDashboard } =
          await updateDefaultTypeCoffeeTreatmentFn({
            ...formatedData,
            defaultTypeCoffeeTreatmentId:
              defaultTypeCoffeeTreatmentSelected?.id,
          })

        dispatch(
          editUniqueData(updateDefaultTypeCoffeeTreatmentDashboard?.data),
        )
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData =
        await createDefaultTypeCoffeeTreatmentFn(formatedData)

      dispatch(
        pushData(responseData?.createDefaultTypeCoffeeTreatmentDashboard?.data),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    defaultTypeCoffeeTreatmentSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
