import { createSlice } from '@reduxjs/toolkit'
import { FarmOwner } from 'types/farms/farms-owners-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  farmOwnerSelected: null | FarmOwner
  deleteModalOpen: boolean
  farmOwnerIdToDelete: null | string
  data: FarmOwner[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  farmOwnerSelected: null,
  farmOwnerIdToDelete: null,
  data: [],
  total: 0,
}

const farmOwnerSlice = createSlice({
  initialState,
  name: 'farms-owners-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectFarmOwner(state, { payload }: { payload: FarmOwner | null }) {
      state.farmOwnerSelected = payload
    },
    setData(state, { payload }: { payload: FarmOwner[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: FarmOwner }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: FarmOwner }) {
      state.data = state.data.map((farmOwner) => {
        return farmOwner.id === payload.id
          ? { ...farmOwner, ...payload }
          : farmOwner
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (farmOwner) => +farmOwner.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.farmOwnerIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.farmOwnerIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectFarmOwner,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = farmOwnerSlice.actions
export default farmOwnerSlice.reducer
