import { gql } from 'graphql-request'

export const createDefaultAgriculturalInputCultivationDashboardGQL = gql`
  mutation CREATE_DEFAULT_AGRICULTURAL_INPUT_CULTIVATION_DASHBOARD(
    $agriculturalInputId: Int!
    $typeCultivationId: Int!
    $name: String!
  ) {
    createDefaultAgriculturalInputCultivationDashboard(
      agriculturalInputId: $agriculturalInputId
      typeCultivationId: $typeCultivationId
      name: $name
    ) {
      error
      status
      data {
        id
        agriculturalInputId
        typeCultivationId
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultAgriculturalInputCultivationDashboardGQL = gql`
  mutation UPDATE_DEFAULT_AGRICULTURAL_INPUT_CULTIVATION_DASHBOARD(
    $defaultAgriculturalInputCultivationId: Int!
    $agriculturalInputId: Int
    $typeCultivationId: Int
    $name: String
  ) {
    updateDefaultAgriculturalInputCultivationDashboard(
      defaultAgriculturalInputCultivationId: $defaultAgriculturalInputCultivationId
      agriculturalInputId: $agriculturalInputId
      typeCultivationId: $typeCultivationId
      name: $name
    ) {
      error
      status
      data {
        id
        agriculturalInputId
        typeCultivationId
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultAgriculturalInputCultivationDashboardGQL = gql`
  mutation DELETE_DEFAULT_AGRICULTURAL_INPUT_CULTIVATION_DASHBOARD(
    $defaultAgriculturalInputCultivationId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultAgriculturalInputCultivationDashboard(
      defaultAgriculturalInputCultivationId: $defaultAgriculturalInputCultivationId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        agriculturalInputId
        typeCultivationId
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultAgriculturalInputCultivationDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_AGRICULTURAL_INPUT_CULTIVATION_DASHBOARD(
    $defaultAgriculturalInputCultivationId: Int!
    $active: Boolean!
  ) {
    activeDefaultAgriculturalInputCultivationDashboard(
      defaultAgriculturalInputCultivationId: $defaultAgriculturalInputCultivationId
      active: $active
    ) {
      error
      status
      data {
        id
        agriculturalInputId
        typeCultivationId
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultAgriculturalInputCultivationDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_AGRICULTURAL_INPUT_CULTIVATION_DASHBOARD(
    $defaultAgriculturalInputCultivationId: Int!
  ) {
    deleteForeverDefaultAgriculturalInputCultivationDashboard(
      defaultAgriculturalInputCultivationId: $defaultAgriculturalInputCultivationId
    )
  }
`

export const getDefaultsAgriculturalsInputsCultivationsDashboardGQL = gql`
  query GET_DEFAULTS_AGRICULTURALS_INPUTS_CULTIVATIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $agriculturalInputId: Int
    $typeCultivationId: Int
    $name: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsAgriculturalsInputsCultivationsDashboard(
      take: $take
      skip: $skip
      agriculturalInputId: $agriculturalInputId
      typeCultivationId: $typeCultivationId
      name: $name
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        agriculturalInputId
        typeCultivationId
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsAgriculturalsInputsCultivationsContainsDashboardGQL = gql`
  query GET_DEFAULTS_AGRICULTURALS_INPUTS_CULTIVATIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
  ) {
    getDefaultsAgriculturalsInputsCultivationsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
    ) {
      error
      status
      totalPages
      data {
        id
        agriculturalInputId
        typeCultivationId
        name
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
