import { gql } from 'graphql-request'

export const createHarvestCostServiceDashboardGQL = gql`
  mutation CREATE_HARVEST_COST_SERVICE_DASHBOARD(
    $farmId: Int
    $farmFieldId: Int
    $harvestId: Int!
    $orderServiceId: Int!
    $name: String!
    $description: String
    $expectedNumberService: Int
    $numberService: Int
    $serviceValueApportionment: Float
    $valueAgriculturalInput: Float
  ) {
    createHarvestCostServiceDashboard(
      farmId: $farmId
      farmFieldId: $farmFieldId
      harvestId: $harvestId
      orderServiceId: $orderServiceId
      name: $name
      description: $description
      expectedNumberService: $expectedNumberService
      numberService: $numberService
      serviceValueApportionment: $serviceValueApportionment
      valueAgriculturalInput: $valueAgriculturalInput
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        harvestId
        harvest {
          id
          name
        }
        orderServiceId
        orderService {
          id
          name
        }
        name
        description
        expectedNumberService
        numberService
        serviceValueApportionment
        valueAgriculturalInput
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateHarvestCostServiceDashboardGQL = gql`
  mutation UPDATE_HARVEST_COST_SERVICE_DASHBOARD(
    $harvestCostServiceId: Int!
    $farmId: Int
    $farmFieldId: Int
    $harvestId: Int
    $orderServiceId: Int
    $name: String
    $description: String
    $expectedNumberService: Int
    $numberService: Int
    $serviceValueApportionment: Float
    $valueAgriculturalInput: Float
  ) {
    updateHarvestCostServiceDashboard(
      harvestCostServiceId: $harvestCostServiceId
      farmId: $farmId
      farmFieldId: $farmFieldId
      harvestId: $harvestId
      orderServiceId: $orderServiceId
      name: $name
      description: $description
      expectedNumberService: $expectedNumberService
      numberService: $numberService
      serviceValueApportionment: $serviceValueApportionment
      valueAgriculturalInput: $valueAgriculturalInput
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        harvestId
        harvest {
          id
          name
        }
        orderServiceId
        orderService {
          id
          name
        }
        name
        description
        expectedNumberService
        numberService
        serviceValueApportionment
        valueAgriculturalInput
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteHarvestCostServiceDashboardGQL = gql`
  mutation DELETE_HARVEST_COST_SERVICE_DASHBOARD(
    $harvestCostServiceId: Int!
    $deleted: Boolean!
  ) {
    deleteHarvestCostServiceDashboard(
      harvestCostServiceId: $harvestCostServiceId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        harvestId
        orderServiceId
        name
        description
        expectedNumberService
        numberService
        serviceValueApportionment
        valueAgriculturalInput
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeHarvestCostServiceDashboardGQL = gql`
  mutation ACTIVE_HARVEST_COST_SERVICE_DASHBOARD(
    $harvestCostServiceId: Int!
    $active: Boolean!
  ) {
    activeHarvestCostServiceDashboard(
      harvestCostServiceId: $harvestCostServiceId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        harvestId
        orderServiceId
        name
        description
        expectedNumberService
        numberService
        serviceValueApportionment
        valueAgriculturalInput
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverHarvestCostServiceDashboardGQL = gql`
  mutation DELETE_FOREVER_HARVEST_COST_SERVICE_DASHBOARD(
    $harvestCostServiceId: Int!
  ) {
    deleteForeverHarvestCostServiceDashboard(
      harvestCostServiceId: $harvestCostServiceId
    )
  }
`

export const getHarvestsCostsServicesDashboardGQL = gql`
  query GET_HARVESTS_COSTS_SERVICES_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $farmFieldId: Int
    $harvestId: Int
    $orderServiceId: Int
    $name: String
    $description: String
    $expectedNumberService: Int
    $numberService: Int
    $serviceValueApportionment: Float
    $valueAgriculturalInput: Float
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getHarvestsCostsServicesDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      farmFieldId: $farmFieldId
      harvestId: $harvestId
      orderServiceId: $orderServiceId
      name: $name
      description: $description
      expectedNumberService: $expectedNumberService
      numberService: $numberService
      serviceValueApportionment: $serviceValueApportionment
      valueAgriculturalInput: $valueAgriculturalInput
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        harvestId
        harvest {
          id
          name
        }
        orderServiceId
        orderService {
          id
          name
        }
        name
        description
        expectedNumberService
        numberService
        serviceValueApportionment
        valueAgriculturalInput
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmFieldDashboardIdGQL = gql`
  query GET_FARM_FIELD_DASHBOARD_ID($farmFieldId: Int!) {
    getFarmFieldDashboard(farmFieldId: $farmFieldId) {
      error
      status
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsFieldsDashboardGQL = gql`
  query GET_FARMS_FIELDS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsFieldsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getHarvestDashboardIdGQL = gql`
  query GET_HARVEST_DASHBOARD_ID($harvestId: Int!) {
    getHarvestDashboard(harvestId: $harvestId) {
      error
      status
      data {
        id
        farmId
        farmFieldId
        typeCultivationId
        typeMeasurementId
        name
        description
        automaticCost
        active
        deleted
        dateHarvestInitial
        dateHarvestFinal
        createdAt
        updatedAt
      }
    }
  }
`

export const getHarvestsDashboardGQL = gql`
  query GET_HARVESTS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getHarvestsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        farmId
        farmFieldId
        typeCultivationId
        typeMeasurementId
        name
        description
        automaticCost
        active
        deleted
        dateHarvestInitial
        dateHarvestFinal
        createdAt
        updatedAt
      }
    }
  }
`

export const getOrderServiceDashboardIdGQL = gql`
  query GET_ORDER_SERVICE_DASHBOARD_ID($orderServiceId: Int!) {
    getOrderServiceDashboard(orderServiceId: $orderServiceId) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        typeCoffeeTreatmentId
        name
        dateService
        description
        optionalDescription
        quantityHectare
        isBrush
        isPulverization
        isFertilizing
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getOrdersServicesDashboardGQL = gql`
  query GET_ORDERS_SERVICES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getOrdersServicesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        farmFieldId
        typeCoffeeTreatmentId
        name
        dateService
        description
        optionalDescription
        quantityHectare
        isBrush
        isPulverization
        isFertilizing
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getHarvestsCostsServicesContainsDashboardGQL = gql`
  query GET_HARVESTS_COSTS_SERVICES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getHarvestsCostsServicesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        harvestId
        harvest {
          id
          name
        }
        orderServiceId
        orderService {
          id
          name
        }
        name
        description
        expectedNumberService
        numberService
        serviceValueApportionment
        valueAgriculturalInput
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
