import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetAgriculturalsInputsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-dashboard'
import { useGetAgriculturalsInputsSubsTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-subs-types-dashboard'
import { useGetTypesPestsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-pests-dashboard'
import { useGetAgriculturalsInputsModesActionsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-modes-actions-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  agriculturalInputInfo: ReturnType<typeof useGetAgriculturalsInputsDashboard>
  agriculturalInputSubTypeInfo: ReturnType<
    typeof useGetAgriculturalsInputsSubsTypesDashboard
  >
  typePestInfo: ReturnType<typeof useGetTypesPestsDashboard>
  agriculturalInputModeActionInfo: ReturnType<
    typeof useGetAgriculturalsInputsModesActionsDashboard
  >
  register: UseFormRegister<FormData>
}

export const FormControl = ({
  control,
  errors,
  agriculturalInputInfo,
  agriculturalInputSubTypeInfo,
  typePestInfo,
  agriculturalInputModeActionInfo,
  register,
}: Props) => {
  const permissions = useGetPermissions('agriculturals_inputs_pests')
  const { agriculturalInputPestSelected } = useSelector(selectorResult)
  const disabled = !!(agriculturalInputPestSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={agriculturalInputInfo?.agriculturalsInputsDashboard}
            info={agriculturalInputInfo}
            label={t('agriculturals-inputs-pests-agricultural-input-id')}
            name="agriculturalInputId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={
              agriculturalInputSubTypeInfo?.agriculturalsInputsSubsTypesDashboard
            }
            info={agriculturalInputSubTypeInfo}
            label={t(
              'agriculturals-inputs-pests-agricultural-input-sub-type-id',
            )}
            name="agriculturalInputSubTypeId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typePestInfo?.typesPestsDashboard}
            info={typePestInfo}
            label={t('agriculturals-inputs-pests-type-pest-id')}
            name="typePestId"
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={
              agriculturalInputModeActionInfo?.agriculturalsInputsModesActionsDashboard
            }
            info={agriculturalInputModeActionInfo}
            label={t(
              'agriculturals-inputs-pests-agricultural-input-mode-action-id',
            )}
            name="agriculturalInputModeActionId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="isOrganic"
            label={t('agriculturals-inputs-pests-is-organic')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('agriculturals-inputs-pests-name')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('agriculturals-inputs-pests-description')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.tradeMark?.message}
            {...register('tradeMark')}
            label={t('agriculturals-inputs-pests-trade-mark')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.formulation?.message}
            {...register('formulation')}
            label={t('agriculturals-inputs-pests-formulation')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.activeIngredient?.message}
            {...register('activeIngredient')}
            label={t('agriculturals-inputs-pests-active-ingredient')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.toxicologicalClass?.message}
            {...register('toxicologicalClass')}
            label={t('agriculturals-inputs-pests-toxicological-class')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.environmentalClass?.message}
            {...register('environmentalClass')}
            label={t('agriculturals-inputs-pests-environmental-class')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
