import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormDataFilter } from '../modal/filter/use-filter-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetTypesCultivationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-cultivations-dashboard'
import { useGetAgriculturalsInputsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-dashboard'

interface Props {
  control: Control<FormDataFilter>
  errors: FieldErrors<FormDataFilter>
  typeCultivationInfo: ReturnType<typeof useGetTypesCultivationsDashboard>
  agriculturalInputInfo: ReturnType<typeof useGetAgriculturalsInputsDashboard>
  register: UseFormRegister<FormDataFilter>
}

export const FormFilterControl = ({
  control,
  errors,
  typeCultivationInfo,
  agriculturalInputInfo,
  register,
}: Props) => {
  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeCultivationInfo?.typesCultivationsDashboard}
            info={typeCultivationInfo}
            label={t('agriculturals-inputs-cultivations-type-cultivation-id')}
            name="typeCultivationId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={agriculturalInputInfo?.agriculturalsInputsDashboard}
            info={agriculturalInputInfo}
            label={t('agriculturals-inputs-cultivations-agricultural-input-id')}
            name="agriculturalInputId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="active"
            label={t('agriculturals-inputs-cultivations-active')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="deleted"
            label={t('agriculturals-inputs-cultivations-deleted')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('agriculturals-inputs-cultivations-name')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtInitial"
            label={t('agriculturals-inputs-cultivations-created-at-initial')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtFinal"
            label={t('agriculturals-inputs-cultivations-created-at-final')}
          />
        </Col>
      </Row>
    </Container>
  )
}
