import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatInputDateValue } from 'helpers/form/format-input-date-value'
import { formatDateToFields } from 'helpers/form/format-date-to-fields'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import {
  createEmployeeDayWorked,
  updateEmployeeDayWorked,
} from 'services/employees/employees-days-workeds/employees-days-workeds-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectEmployeeDayWorked,
} from 'slices/employees/employees-days-workeds-reducer'
import { z } from 'zod'
import { useGetEmployeesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-employees-dashboard'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('employees-days-workeds-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('employees-days-workeds-farm-field-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  employeeId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('employees-days-workeds-employee-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('employees-days-workeds-name')),

  dateWorked: z.union([z.date(), z.string().min(1)]),

  holiday: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('employees-days-workeds-holiday')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  worked: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('employees-days-workeds-worked')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  workedSociety: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('employees-days-workeds-worked-society'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  workedOtherSociety: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('employees-days-workeds-worked-other-society'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  workedOtherSocietyEmployeeId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('employees-days-workeds-worked-other-society-employee-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  absence: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('employees-days-workeds-absence')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  reasonAbsence: z.string(),
})

export type FormData = z.infer<typeof schema>

const employeeDayWorkedModalFormSelector = (state: StateRoot) => {
  return state.employeesDaysWorkedsReducer
}

export const selectorResult = createSelector(
  employeeDayWorkedModalFormSelector,
  (employeeDayWorkedReducer) => {
    return employeeDayWorkedReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, employeeDayWorkedSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('employees_days_workeds')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const employeeInfo = useGetEmployeesDashboard()
  const farmFieldInfo = useGetFarmsFieldsDashboard()
  const farmInfo = useGetFarmsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectEmployeeDayWorked(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createEmployeeDayWorkedFn, isPending } = useMutation({
    mutationFn: createEmployeeDayWorked,
  })

  const { mutateAsync: updateEmployeeDayWorkedFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateEmployeeDayWorked,
    })

  const cleanFields: FormData = {
    farmId: '',
    farmFieldId: '',
    employeeId: '',
    name: '',
    dateWorked: '',
    holiday: '',
    worked: '',
    workedSociety: '',
    workedOtherSociety: '',
    workedOtherSocietyEmployeeId: '',
    absence: '',
    reasonAbsence: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!employeeDayWorkedSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      employeeId: formatSelectValueToFields(
        employeeDayWorkedSelected.employee,
        ['id', 'name'],
      ),
      farmFieldId: formatSelectValueToFields(
        employeeDayWorkedSelected.farmField,
        ['id', 'name'],
      ),
      farmId: formatSelectValueToFields(employeeDayWorkedSelected.farm, [
        'id',
        'name',
      ]),
      workedOtherSocietyEmployeeId: formatSelectValueToFields(
        employeeDayWorkedSelected.employee,
        ['id', 'name'],
      ),
      name: employeeDayWorkedSelected.name,
      dateWorked: formatDateToFields(employeeDayWorkedSelected.dateWorked),
      holiday: `${employeeDayWorkedSelected.holiday}`,
      worked: `${employeeDayWorkedSelected.worked}`,
      workedSociety: `${employeeDayWorkedSelected.workedSociety}`,
      workedOtherSociety: `${employeeDayWorkedSelected.workedOtherSociety}`,
      absence: `${employeeDayWorkedSelected.absence}`,
      reasonAbsence: employeeDayWorkedSelected.reasonAbsence,
    }

    reset(employeeDayWorkedSelected ? fields : cleanFields)
  }, [employeeDayWorkedSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedEmployee = +formatToSendData(data?.employeeId)
      const selectedFarmField = +formatToSendData(data?.farmFieldId)
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedWorkedOtherSocietyEmployee = +formatToSendData(
        data?.workedOtherSocietyEmployeeId,
      )
      const selectedHoliday = formatToSendData(data?.holiday)
      const selectedWorked = formatToSendData(data?.worked)
      const selectedWorkedSociety = formatToSendData(data?.workedSociety)
      const selectedWorkedOtherSociety = formatToSendData(
        data?.workedOtherSociety,
      )
      const selectedAbsence = formatToSendData(data?.absence)

      const formatedData = {
        employeeId: selectedEmployee,
        farmFieldId: selectedFarmField,
        farmId: selectedFarm,
        workedOtherSocietyEmployeeId: selectedWorkedOtherSocietyEmployee,
        name: data?.name,
        dateWorked: formatInputDateValue(data?.dateWorked),
        holiday: formatInputBooleanValue(selectedHoliday),
        worked: formatInputBooleanValue(selectedWorked),
        workedSociety: formatInputBooleanValue(selectedWorkedSociety),
        workedOtherSociety: formatInputBooleanValue(selectedWorkedOtherSociety),
        absence: formatInputBooleanValue(selectedAbsence),
        reasonAbsence: data?.reasonAbsence,
      }

      if (employeeDayWorkedSelected && employeeDayWorkedSelected?.id) {
        const { updateEmployeeDayWorkedDashboard } =
          await updateEmployeeDayWorkedFn({
            ...formatedData,
            employeeDayWorkedId: employeeDayWorkedSelected?.id,
          })

        dispatch(editUniqueData(updateEmployeeDayWorkedDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createEmployeeDayWorkedFn(formatedData)

      dispatch(pushData(responseData?.createEmployeeDayWorkedDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    employeeDayWorkedSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    employeeInfo,
    farmFieldInfo,
    farmInfo,
  }
}
