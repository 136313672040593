import { graphClient } from 'services/graph-client'
import {
  activeUserRoleDashboardGQL,
  deleteUserRoleDashboardGQL,
  deleteForeverUserRoleDashboardGQL,
  createUserRoleDashboardGQL,
  updateUserRoleDashboardGQL,
} from './queries'
import { UserRole } from 'types/users/users-roles-types'

interface ResponseCreate {
  createUserRoleDashboard: {
    data: UserRole
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateUserRoleDashboard: {
    data: UserRole
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeUserRoleDashboard: {
    data: UserRole
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteUserRoleDashboard: {
    data: UserRole
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverUserRoleDashboard: string
}

interface DataActive {
  userRoleId: number
  active: boolean
}

interface DataDeleted {
  userRoleId: number
  deleted: boolean
}

interface DataDeleteForever {
  userRoleId: number
}

interface DataCreate {
  roleId: number
  name: string
  nameIndex: string
  canMenu: boolean
  canAdd: boolean
  canDuplicate: boolean
  canEdit: boolean
  canDeleteForever: boolean
  canExport: boolean
  canImport: boolean
  canActive: boolean
  canDeleted: boolean
}

interface DataUpdate {
  userRoleId: number
  roleId: number
  name: string
  nameIndex: string
  canMenu: boolean
  canAdd: boolean
  canDuplicate: boolean
  canEdit: boolean
  canDeleteForever: boolean
  canExport: boolean
  canImport: boolean
  canActive: boolean
  canDeleted: boolean
}

export const activeUserRole = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeUserRoleDashboardGQL, {
    ...data,
  })
}

export const deleteUserRole = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteUserRoleDashboardGQL, {
    ...data,
  })
}

export const deleteForeverUserRole = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverUserRoleDashboardGQL,
    { ...data },
  )
}

export const createUserRole = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(createUserRoleDashboardGQL, {
    ...data,
  })
}

export const updateUserRole = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateUserRoleDashboardGQL, {
    ...data,
  })
}
