import { createSelector } from 'reselect'
import { Employee } from 'types/employees/employees-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverEmployee } from 'services/employees/employees/employees-cruds'
import {
  getEmployeesDashboard,
  GetParams,
  getEmployeesContainsDashboard,
} from 'services/employees/employees/employees-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/employees/employees-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const employeeListSelector = (state: StateRoot) => state.employeesReducer

const selectorResult = createSelector(
  employeeListSelector,
  (employeeListReducer) => {
    return employeeListReducer
  },
)

export const useEmployeeList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('employees')

  const {
    data: dataEmployee,
    deleteModalOpen,
    employeeIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'employee', 'list', { params }],
    queryFn: () => getEmployeesDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverEmployee,
  })

  const handleStoreData = useCallback(
    (employees: Employee[], totalPages: number | null) => {
      if (!employees) return

      dispatch(setData(employees))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverEmployee = useCallback(async () => {
    if (!employeeIdToDelete) return

    const response = await mutateAsync({
      employeeId: +employeeIdToDelete,
    })

    if (response?.deleteForeverEmployeeDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: employeeIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [employeeIdToDelete])

  useEffect(() => {
    const employeesResult = data?.getEmployeesDashboard

    if (employeesResult) {
      handleStoreData(employeesResult?.data, employeesResult?.totalPages)
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getEmployeesContainsDashboard(value)
      const employeesResult = response?.getEmployeesContainsDashboard

      handleStoreData(employeesResult?.data, employeesResult?.totalPages)
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    employeeIdToDelete,
    employee: data?.getEmployeesDashboard?.data,
    isLoading,
    isFetching,
    dataEmployee,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverEmployee,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
