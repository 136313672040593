import { ActionsButtons } from './components/actions-buttons'
import { mapOptions } from './config'
import { mapStyle, wrapperStyle } from './styles'
import { Container } from 'reactstrap'
import { useMap } from './use-map'
import { AreaControl } from '../area-control'
import { createSelector } from 'reselect'
import { StateRoot } from 'slices'
import { Polygons } from '../polygons'
import { GoogleMap, DrawingManager } from '@react-google-maps/api'
import { SearchPlace } from './components/search-place'

export const mapReducer = createSelector(
  (state: StateRoot) => state.mapSliceReducer,
  (mapSliceReducer) => mapSliceReducer,
)

const GoogleMapScreen = () => {
  const {
    isLoaded,
    isAreaControlOpen,
    isCreating,
    handleGetCenterPolygon,
    onLoadMap,
    handlePlacesChanged,
    handleCancelEdit,
    handleSaveEdit,
    onLoadSearch,
    onPolygonComplete,
  } = useMap()

  return (
    <div className="h-100 w-100">
      <Container fluid style={wrapperStyle}>
        {isLoaded && (
          <GoogleMap
            onLoad={onLoadMap}
            mapContainerStyle={mapStyle}
            options={mapOptions}
          >
            {isCreating && (
              <DrawingManager
                onPolygonComplete={onPolygonComplete}
                options={{
                  drawingMode: google.maps.drawing.OverlayType.POLYGON,
                  drawingControl: false,
                  polygonOptions: {
                    fillColor: 'green',
                    editable: true,
                  },
                }}
              />
            )}

            <Polygons
              onPolygonComplete={onPolygonComplete}
              handleGetCenterPolygon={handleGetCenterPolygon}
            />

            <AreaControl />

            {!isAreaControlOpen && (
              <ActionsButtons
                handleSaveEdit={handleSaveEdit}
                handleCancelEdit={handleCancelEdit}
              />
            )}

            <SearchPlace
              handleLoadSearch={onLoadSearch}
              handlePlacesChanged={handlePlacesChanged}
            />
          </GoogleMap>
        )}
      </Container>
    </div>
  )
}

export default GoogleMapScreen
