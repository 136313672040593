import { gql } from 'graphql-request'

export const createAppLogDashboardGQL = gql`
  mutation CREATE_APP_LOG_DASHBOARD(
    $name: String!
    $typeAppLogType: TypeAppLogType!
    $table: String!
    $tableIndex: Int!
    $oldJson: String
    $newJson: String
  ) {
    createAppLogDashboard(
      name: $name
      typeAppLogType: $typeAppLogType
      table: $table
      tableIndex: $tableIndex
      oldJson: $oldJson
      newJson: $newJson
    ) {
      error
      status
      data {
        id
        name
        companyId
        company {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        typeAppLogType
        table
        tableIndex
        oldJson
        newJson
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateAppLogDashboardGQL = gql`
  mutation UPDATE_APP_LOG_DASHBOARD(
    $appLogId: Int!
    $name: String
    $typeAppLogType: TypeAppLogType
    $table: String
    $tableIndex: Int
    $oldJson: String
    $newJson: String
  ) {
    updateAppLogDashboard(
      appLogId: $appLogId
      name: $name
      typeAppLogType: $typeAppLogType
      table: $table
      tableIndex: $tableIndex
      oldJson: $oldJson
      newJson: $newJson
    ) {
      error
      status
      data {
        id
        name
        companyId
        company {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        typeAppLogType
        table
        tableIndex
        oldJson
        newJson
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteAppLogDashboardGQL = gql`
  mutation DELETE_APP_LOG_DASHBOARD($appLogId: Int!, $deleted: Boolean!) {
    deleteAppLogDashboard(appLogId: $appLogId, deleted: $deleted) {
      error
      status
      data {
        id
        name
        companyId
        userId
        typeAppLogType
        table
        tableIndex
        oldJson
        newJson
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeAppLogDashboardGQL = gql`
  mutation ACTIVE_APP_LOG_DASHBOARD($appLogId: Int!, $active: Boolean!) {
    activeAppLogDashboard(appLogId: $appLogId, active: $active) {
      error
      status
      data {
        id
        name
        companyId
        userId
        typeAppLogType
        table
        tableIndex
        oldJson
        newJson
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverAppLogDashboardGQL = gql`
  mutation DELETE_FOREVER_APP_LOG_DASHBOARD($appLogId: Int!) {
    deleteForeverAppLogDashboard(appLogId: $appLogId)
  }
`

export const getAppsLogsDashboardGQL = gql`
  query GET_APPS_LOGS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $userId: Int
    $typeAppLogType: TypeAppLogType
    $table: String
    $tableIndex: Int
    $oldJson: String
    $newJson: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getAppsLogsDashboard(
      take: $take
      skip: $skip
      name: $name
      userId: $userId
      typeAppLogType: $typeAppLogType
      table: $table
      tableIndex: $tableIndex
      oldJson: $oldJson
      newJson: $newJson
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        companyId
        company {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        typeAppLogType
        table
        tableIndex
        oldJson
        newJson
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUserDashboardIdGQL = gql`
  query GET_USER_DASHBOARD_ID($userId: Int!) {
    getUserDashboard(userId: $userId) {
      error
      status
      data {
        id
        userTypeId
        companyId
        farmId
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUsersDashboardGQL = gql`
  query GET_USERS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getUsersDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        userTypeId
        companyId
        farmId
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getAppsLogsContainsDashboardGQL = gql`
  query GET_APPS_LOGS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $table: String
    $oldJson: String
    $newJson: String
  ) {
    getAppsLogsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      table: $table
      oldJson: $oldJson
      newJson: $newJson
    ) {
      error
      status
      totalPages
      data {
        id
        name
        companyId
        company {
          id
          name
        }
        userId
        user {
          id
          firstName
        }
        typeAppLogType
        table
        tableIndex
        oldJson
        newJson
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
