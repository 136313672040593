import { graphClient } from 'services/graph-client'
import {
  activeAgriculturalInputModeActionDashboardGQL,
  deleteAgriculturalInputModeActionDashboardGQL,
  deleteForeverAgriculturalInputModeActionDashboardGQL,
  createAgriculturalInputModeActionDashboardGQL,
  updateAgriculturalInputModeActionDashboardGQL,
} from './queries'
import { AgriculturalInputModeAction } from 'types/agriculturals-inputs/agriculturals-inputs-modes-actions-types'

interface ResponseCreate {
  createAgriculturalInputModeActionDashboard: {
    data: AgriculturalInputModeAction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateAgriculturalInputModeActionDashboard: {
    data: AgriculturalInputModeAction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeAgriculturalInputModeActionDashboard: {
    data: AgriculturalInputModeAction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteAgriculturalInputModeActionDashboard: {
    data: AgriculturalInputModeAction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverAgriculturalInputModeActionDashboard: string
}

interface DataActive {
  agriculturalInputModeActionId: number
  active: boolean
}

interface DataDeleted {
  agriculturalInputModeActionId: number
  deleted: boolean
}

interface DataDeleteForever {
  agriculturalInputModeActionId: number
}

interface DataCreate {
  name: string
  description: string
}

interface DataUpdate {
  agriculturalInputModeActionId: number
  name: string
  description: string
}

export const activeAgriculturalInputModeAction = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeAgriculturalInputModeActionDashboardGQL,
    { ...data },
  )
}

export const deleteAgriculturalInputModeAction = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteAgriculturalInputModeActionDashboardGQL,
    { ...data },
  )
}

export const deleteForeverAgriculturalInputModeAction = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverAgriculturalInputModeActionDashboardGQL,
    { ...data },
  )
}

export const createAgriculturalInputModeAction = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createAgriculturalInputModeActionDashboardGQL,
    { ...data },
  )
}

export const updateAgriculturalInputModeAction = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateAgriculturalInputModeActionDashboardGQL,
    { ...data },
  )
}
