import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import {
  createExampleOtherOne,
  updateExampleOtherOne,
} from 'services/examples-tables/examples-others-ones/examples-others-ones-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectExampleOtherOne,
} from 'slices/examples-tables/examples-others-ones-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-others-ones-name')),
})

export type FormData = z.infer<typeof schema>

const exampleOtherOneModalFormSelector = (state: StateRoot) => {
  return state.examplesOthersOnesReducer
}

export const selectorResult = createSelector(
  exampleOtherOneModalFormSelector,
  (exampleOtherOneReducer) => {
    return exampleOtherOneReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, exampleOtherOneSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('examples_others_ones')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectExampleOtherOne(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createExampleOtherOneFn, isPending } = useMutation({
    mutationFn: createExampleOtherOne,
  })

  const { mutateAsync: updateExampleOtherOneFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateExampleOtherOne,
    })

  const cleanFields: FormData = {
    name: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!exampleOtherOneSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: exampleOtherOneSelected.name,
    }

    reset(exampleOtherOneSelected ? fields : cleanFields)
  }, [exampleOtherOneSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        name: data?.name,
      }

      if (exampleOtherOneSelected && exampleOtherOneSelected?.id) {
        const { updateExampleOtherOneDashboard } =
          await updateExampleOtherOneFn({
            ...formatedData,
            exampleOtherOneId: exampleOtherOneSelected?.id,
          })

        dispatch(editUniqueData(updateExampleOtherOneDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createExampleOtherOneFn(formatedData)

      dispatch(pushData(responseData?.createExampleOtherOneDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    exampleOtherOneSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
