import { gql } from 'graphql-request'

export const createDefaultAgriculturalInputPestDashboardGQL = gql`
  mutation CREATE_DEFAULT_AGRICULTURAL_INPUT_PEST_DASHBOARD(
    $agriculturalInputId: Int!
    $typePestId: Int!
    $name: String!
    $description: String!
    $agriculturalInputSubTypeId: Int!
    $agriculturalInputModeActionId: Int
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $toxicologicalClass: String
    $environmentalClass: String
    $isOrganic: Boolean!
  ) {
    createDefaultAgriculturalInputPestDashboard(
      agriculturalInputId: $agriculturalInputId
      typePestId: $typePestId
      name: $name
      description: $description
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
      isOrganic: $isOrganic
    ) {
      error
      status
      data {
        id
        agriculturalInputId
        typePestId
        name
        description
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        tradeMark
        formulation
        activeIngredient
        toxicologicalClass
        environmentalClass
        isOrganic
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultAgriculturalInputPestDashboardGQL = gql`
  mutation UPDATE_DEFAULT_AGRICULTURAL_INPUT_PEST_DASHBOARD(
    $defaultAgriculturalInputPestId: Int!
    $agriculturalInputId: Int
    $typePestId: Int
    $name: String
    $description: String
    $agriculturalInputSubTypeId: Int
    $agriculturalInputModeActionId: Int
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $toxicologicalClass: String
    $environmentalClass: String
    $isOrganic: Boolean
  ) {
    updateDefaultAgriculturalInputPestDashboard(
      defaultAgriculturalInputPestId: $defaultAgriculturalInputPestId
      agriculturalInputId: $agriculturalInputId
      typePestId: $typePestId
      name: $name
      description: $description
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
      isOrganic: $isOrganic
    ) {
      error
      status
      data {
        id
        agriculturalInputId
        typePestId
        name
        description
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        tradeMark
        formulation
        activeIngredient
        toxicologicalClass
        environmentalClass
        isOrganic
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultAgriculturalInputPestDashboardGQL = gql`
  mutation DELETE_DEFAULT_AGRICULTURAL_INPUT_PEST_DASHBOARD(
    $defaultAgriculturalInputPestId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultAgriculturalInputPestDashboard(
      defaultAgriculturalInputPestId: $defaultAgriculturalInputPestId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        agriculturalInputId
        typePestId
        name
        description
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        tradeMark
        formulation
        activeIngredient
        toxicologicalClass
        environmentalClass
        isOrganic
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultAgriculturalInputPestDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_AGRICULTURAL_INPUT_PEST_DASHBOARD(
    $defaultAgriculturalInputPestId: Int!
    $active: Boolean!
  ) {
    activeDefaultAgriculturalInputPestDashboard(
      defaultAgriculturalInputPestId: $defaultAgriculturalInputPestId
      active: $active
    ) {
      error
      status
      data {
        id
        agriculturalInputId
        typePestId
        name
        description
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        tradeMark
        formulation
        activeIngredient
        toxicologicalClass
        environmentalClass
        isOrganic
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultAgriculturalInputPestDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_AGRICULTURAL_INPUT_PEST_DASHBOARD(
    $defaultAgriculturalInputPestId: Int!
  ) {
    deleteForeverDefaultAgriculturalInputPestDashboard(
      defaultAgriculturalInputPestId: $defaultAgriculturalInputPestId
    )
  }
`

export const getDefaultsAgriculturalsInputsPestsDashboardGQL = gql`
  query GET_DEFAULTS_AGRICULTURALS_INPUTS_PESTS_DASHBOARD(
    $take: Int
    $skip: Int
    $agriculturalInputId: Int
    $typePestId: Int
    $name: String
    $description: String
    $agriculturalInputSubTypeId: Int
    $agriculturalInputModeActionId: Int
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $toxicologicalClass: String
    $environmentalClass: String
    $isOrganic: Boolean
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsAgriculturalsInputsPestsDashboard(
      take: $take
      skip: $skip
      agriculturalInputId: $agriculturalInputId
      typePestId: $typePestId
      name: $name
      description: $description
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
      isOrganic: $isOrganic
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        agriculturalInputId
        typePestId
        name
        description
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        tradeMark
        formulation
        activeIngredient
        toxicologicalClass
        environmentalClass
        isOrganic
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsAgriculturalsInputsPestsContainsDashboardGQL = gql`
  query GET_DEFAULTS_AGRICULTURALS_INPUTS_PESTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $toxicologicalClass: String
    $environmentalClass: String
  ) {
    getDefaultsAgriculturalsInputsPestsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
    ) {
      error
      status
      totalPages
      data {
        id
        agriculturalInputId
        typePestId
        name
        description
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        tradeMark
        formulation
        activeIngredient
        toxicologicalClass
        environmentalClass
        isOrganic
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
