import { createSelector } from 'reselect'
import { DefaultTypeCoffeeIrrigation } from 'types/defaults/defaults-types-coffees-irrigations-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverDefaultTypeCoffeeIrrigation } from 'services/defaults/defaults-types-coffees-irrigations/defaults-types-coffees-irrigations-cruds'
import {
  getDefaultsTypesCoffeesIrrigationsDashboard,
  GetParams,
  getDefaultsTypesCoffeesIrrigationsContainsDashboard,
} from 'services/defaults/defaults-types-coffees-irrigations/defaults-types-coffees-irrigations-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/defaults/defaults-types-coffees-irrigations-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const defaultTypeCoffeeIrrigationListSelector = (state: StateRoot) =>
  state.defaultsTypesCoffeesIrrigationsReducer

const selectorResult = createSelector(
  defaultTypeCoffeeIrrigationListSelector,
  (defaultTypeCoffeeIrrigationListReducer) => {
    return defaultTypeCoffeeIrrigationListReducer
  },
)

export const useDefaultTypeCoffeeIrrigationList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('defaults_types_coffees_irrigations')

  const {
    data: dataDefaultTypeCoffeeIrrigation,
    deleteModalOpen,
    defaultTypeCoffeeIrrigationIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'defaultTypeCoffeeIrrigation', 'list', { params }],
    queryFn: () => getDefaultsTypesCoffeesIrrigationsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverDefaultTypeCoffeeIrrigation,
  })

  const handleStoreData = useCallback(
    (
      defaultsTypesCoffeesIrrigations: DefaultTypeCoffeeIrrigation[],
      totalPages: number | null,
    ) => {
      if (!defaultsTypesCoffeesIrrigations) return

      dispatch(setData(defaultsTypesCoffeesIrrigations))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverDefaultTypeCoffeeIrrigation =
    useCallback(async () => {
      if (!defaultTypeCoffeeIrrigationIdToDelete) return

      const response = await mutateAsync({
        defaultTypeCoffeeIrrigationId: +defaultTypeCoffeeIrrigationIdToDelete,
      })

      if (
        response?.deleteForeverDefaultTypeCoffeeIrrigationDashboard ===
        'deleted_forever'
      ) {
        dispatch(
          deleteUniqueData({ id: defaultTypeCoffeeIrrigationIdToDelete }),
        )
      }

      dispatch(closeDeleteModal())
    }, [defaultTypeCoffeeIrrigationIdToDelete])

  useEffect(() => {
    const defaultsTypesCoffeesIrrigationsResult =
      data?.getDefaultsTypesCoffeesIrrigationsDashboard

    if (defaultsTypesCoffeesIrrigationsResult) {
      handleStoreData(
        defaultsTypesCoffeesIrrigationsResult?.data,
        defaultsTypesCoffeesIrrigationsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response =
        await getDefaultsTypesCoffeesIrrigationsContainsDashboard(value)
      const defaultsTypesCoffeesIrrigationsResult =
        response?.getDefaultsTypesCoffeesIrrigationsContainsDashboard

      handleStoreData(
        defaultsTypesCoffeesIrrigationsResult?.data,
        defaultsTypesCoffeesIrrigationsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    defaultTypeCoffeeIrrigationIdToDelete,
    defaultTypeCoffeeIrrigation:
      data?.getDefaultsTypesCoffeesIrrigationsDashboard?.data,
    isLoading,
    isFetching,
    dataDefaultTypeCoffeeIrrigation,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverDefaultTypeCoffeeIrrigation,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
