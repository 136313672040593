import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import {
  createDefaultAgriculturalInputPest,
  updateDefaultAgriculturalInputPest,
} from 'services/defaults/defaults-agriculturals-inputs-pests/defaults-agriculturals-inputs-pests-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectDefaultAgriculturalInputPest,
} from 'slices/defaults/defaults-agriculturals-inputs-pests-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  agriculturalInputId: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('defaults-agriculturals-inputs-pests-agricultural-input-id'),
    ),

  typePestId: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('defaults-agriculturals-inputs-pests-type-pest-id'),
    ),

  name: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('defaults-agriculturals-inputs-pests-name'),
    ),

  description: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('defaults-agriculturals-inputs-pests-description'),
    ),

  agriculturalInputSubTypeId: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('defaults-agriculturals-inputs-pests-agricultural-input-sub-type-id'),
    ),

  agriculturalInputModeActionId: z.string(),
  tradeMark: z.string(),
  formulation: z.string(),
  activeIngredient: z.string(),
  toxicologicalClass: z.string(),
  environmentalClass: z.string(),
  isOrganic: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('defaults-agriculturals-inputs-pests-is-organic'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),
})

export type FormData = z.infer<typeof schema>

const defaultAgriculturalInputPestModalFormSelector = (state: StateRoot) => {
  return state.defaultsAgriculturalsInputsPestsReducer
}

export const selectorResult = createSelector(
  defaultAgriculturalInputPestModalFormSelector,
  (defaultAgriculturalInputPestReducer) => {
    return defaultAgriculturalInputPestReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, defaultAgriculturalInputPestSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('defaults_agriculturals_inputs_pests')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectDefaultAgriculturalInputPest(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createDefaultAgriculturalInputPestFn, isPending } =
    useMutation({
      mutationFn: createDefaultAgriculturalInputPest,
    })

  const {
    mutateAsync: updateDefaultAgriculturalInputPestFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateDefaultAgriculturalInputPest,
  })

  const cleanFields: FormData = {
    agriculturalInputId: '',
    typePestId: '',
    name: '',
    description: '',
    agriculturalInputSubTypeId: '',
    agriculturalInputModeActionId: '',
    tradeMark: '',
    formulation: '',
    activeIngredient: '',
    toxicologicalClass: '',
    environmentalClass: '',
    isOrganic: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!defaultAgriculturalInputPestSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      agriculturalInputId: `${defaultAgriculturalInputPestSelected.agriculturalInputId}`,
      typePestId: `${defaultAgriculturalInputPestSelected.typePestId}`,
      name: defaultAgriculturalInputPestSelected.name,
      description: defaultAgriculturalInputPestSelected.description,
      agriculturalInputSubTypeId: `${defaultAgriculturalInputPestSelected.agriculturalInputSubTypeId}`,
      agriculturalInputModeActionId: `${defaultAgriculturalInputPestSelected.agriculturalInputModeActionId}`,
      tradeMark: defaultAgriculturalInputPestSelected.tradeMark,
      formulation: defaultAgriculturalInputPestSelected.formulation,
      activeIngredient: defaultAgriculturalInputPestSelected.activeIngredient,
      toxicologicalClass:
        defaultAgriculturalInputPestSelected.toxicologicalClass,
      environmentalClass:
        defaultAgriculturalInputPestSelected.environmentalClass,
      isOrganic: `${defaultAgriculturalInputPestSelected.isOrganic}`,
    }

    reset(defaultAgriculturalInputPestSelected ? fields : cleanFields)
  }, [defaultAgriculturalInputPestSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedIsOrganic = formatToSendData(data?.isOrganic)

      const formatedData = {
        agriculturalInputId: +data?.agriculturalInputId,
        typePestId: +data?.typePestId,
        name: data?.name,
        description: data?.description,
        agriculturalInputSubTypeId: +data?.agriculturalInputSubTypeId,
        agriculturalInputModeActionId: +data?.agriculturalInputModeActionId,
        tradeMark: data?.tradeMark,
        formulation: data?.formulation,
        activeIngredient: data?.activeIngredient,
        toxicologicalClass: data?.toxicologicalClass,
        environmentalClass: data?.environmentalClass,
        isOrganic: formatInputBooleanValue(selectedIsOrganic),
      }

      if (
        defaultAgriculturalInputPestSelected &&
        defaultAgriculturalInputPestSelected?.id
      ) {
        const { updateDefaultAgriculturalInputPestDashboard } =
          await updateDefaultAgriculturalInputPestFn({
            ...formatedData,
            defaultAgriculturalInputPestId:
              defaultAgriculturalInputPestSelected?.id,
          })

        dispatch(
          editUniqueData(updateDefaultAgriculturalInputPestDashboard?.data),
        )
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData =
        await createDefaultAgriculturalInputPestFn(formatedData)

      dispatch(
        pushData(
          responseData?.createDefaultAgriculturalInputPestDashboard?.data,
        ),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    defaultAgriculturalInputPestSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
