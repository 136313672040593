import { graphClient } from 'services/graph-client'
import {
  activeFarmInventoryDashboardGQL,
  deleteFarmInventoryDashboardGQL,
  deleteForeverFarmInventoryDashboardGQL,
  createFarmInventoryDashboardGQL,
  updateFarmInventoryDashboardGQL,
} from './queries'
import { FarmInventory } from 'types/farms/farms-inventories-types'

interface ResponseCreate {
  createFarmInventoryDashboard: {
    data: FarmInventory
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateFarmInventoryDashboard: {
    data: FarmInventory
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeFarmInventoryDashboard: {
    data: FarmInventory
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteFarmInventoryDashboard: {
    data: FarmInventory
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverFarmInventoryDashboard: string
}

interface DataActive {
  farmInventoryId: number
  active: boolean
}

interface DataDeleted {
  farmInventoryId: number
  deleted: boolean
}

interface DataDeleteForever {
  farmInventoryId: number
}

interface DataCreate {
  farmId?: number
  name: string
  serialNumber: string
  purchasCost: number
  photoInvoice: string
  datePurchase: string
  yearPurchase: string
  typeToolId: number
}

interface DataUpdate {
  farmInventoryId: number
  farmId?: number
  name: string
  serialNumber: string
  purchasCost: number
  photoInvoice: string
  datePurchase: string
  yearPurchase: string
  typeToolId: number
}

export const activeFarmInventory = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeFarmInventoryDashboardGQL,
    { ...data },
  )
}

export const deleteFarmInventory = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteFarmInventoryDashboardGQL,
    { ...data },
  )
}

export const deleteForeverFarmInventory = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverFarmInventoryDashboardGQL,
    { ...data },
  )
}

export const createFarmInventory = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createFarmInventoryDashboardGQL,
    { ...data },
  )
}

export const updateFarmInventory = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateFarmInventoryDashboardGQL,
    { ...data },
  )
}
