import { t } from 'i18next'
import { SelectComponent } from 'components/common/inputs/select'
import { formatDisplaySelectInput } from 'helpers/form/format-display-select-input'
import { formatOptionsSelect } from 'helpers/format/format-options-select'
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form'

export interface Info {
  hasNextPage: boolean
  isFetchingNextPage: boolean
  fetchNextPage: () => void
}

interface ControlledProps<T extends FieldValues> {
  label: string
  control: Control<T>
  name: FieldPath<T>
  data: { id: number | string; name: string }[]
  info?: Info
  disabled?: boolean
}

export function ControlledSelect<T extends FieldValues>({
  label,
  control,
  name,
  data = [],
  info,
  disabled,
}: ControlledProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control })

  const { display } = formatDisplaySelectInput(field.value)

  return (
    <SelectComponent
      label={t(label)}
      disabled={disabled}
      minWidth={270}
      value={display}
      error={error?.message}
      info={info}
      onChange={field.onChange}
      options={formatOptionsSelect(
        data?.map((item) => ({
          id: `${item?.id}`,
          name: item?.name,
        })),
      )}
    />
  )
}
