import { createSelector } from 'reselect'
import { HarvestReal } from 'types/harvests/harvests-reals-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverHarvestReal } from 'services/harvests/harvests-reals/harvests-reals-cruds'
import {
  getHarvestsRealsDashboard,
  GetParams,
  getHarvestsRealsContainsDashboard,
} from 'services/harvests/harvests-reals/harvests-reals-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/harvests/harvests-reals-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const harvestRealListSelector = (state: StateRoot) => state.harvestsRealsReducer

const selectorResult = createSelector(
  harvestRealListSelector,
  (harvestRealListReducer) => {
    return harvestRealListReducer
  },
)

export const useHarvestRealList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('harvests_reals')

  const {
    data: dataHarvestReal,
    deleteModalOpen,
    harvestRealIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'harvestReal', 'list', { params }],
    queryFn: () => getHarvestsRealsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverHarvestReal,
  })

  const handleStoreData = useCallback(
    (harvestsReals: HarvestReal[], totalPages: number | null) => {
      if (!harvestsReals) return

      dispatch(setData(harvestsReals))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverHarvestReal = useCallback(async () => {
    if (!harvestRealIdToDelete) return

    const response = await mutateAsync({
      harvestRealId: +harvestRealIdToDelete,
    })

    if (response?.deleteForeverHarvestRealDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: harvestRealIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [harvestRealIdToDelete])

  useEffect(() => {
    const harvestsRealsResult = data?.getHarvestsRealsDashboard

    if (harvestsRealsResult) {
      handleStoreData(
        harvestsRealsResult?.data,
        harvestsRealsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getHarvestsRealsContainsDashboard(value)
      const harvestsRealsResult = response?.getHarvestsRealsContainsDashboard

      handleStoreData(
        harvestsRealsResult?.data,
        harvestsRealsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    harvestRealIdToDelete,
    harvestReal: data?.getHarvestsRealsDashboard?.data,
    isLoading,
    isFetching,
    dataHarvestReal,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverHarvestReal,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
