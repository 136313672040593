import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'
import { formatSelectPhoneToFields } from 'helpers/form/format-select-phone-to-fields'
import { formatInputDateValue } from 'helpers/form/format-input-date-value'
import { formatDateToFields } from 'helpers/form/format-date-to-fields'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import { updateUser } from 'services/users/users/users-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  selectUser,
} from 'slices/users/users-reducer'
import { z } from 'zod'
import { useGetUsersTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-users-types-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  userTypeId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-user-type-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  firstName: z.string().min(1, t('inform-field') + ' ' + t('users-first-name')),

  lastName: z.string(),
  name: z.string(),

  email: z.string().min(1, t('inform-field') + ' ' + t('users-email')),

  phone: z
    .object({
      phone: z
        .string()
        .min(1, t('invalid-phone-field') + ' ' + t('users-phone')),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('users-phone'),
      path: ['phone'],
    }),
  password: z.string().min(1, t('inform-field') + ' ' + t('users-password')),

  photo: z.string().min(1, t('inform-field') + ' ' + t('users-photo')),

  emailVerified: z
    .string()
    .min(1, t('inform-field') + ' ' + t('users-email-verified')),

  phoneVerified: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-phone-verified')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  twoFactorEnabled: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-two-factor-enabled')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  smsCode: z.string(),
  emailCode: z.string().min(1, t('inform-field') + ' ' + t('users-email-code')),

  googleId: z.string(),
  appleId: z.string(),
  appleToken: z.string(),
  country: z.string().min(1, t('inform-field') + ' ' + t('users-country')),

  state: z.string().min(1, t('inform-field') + ' ' + t('users-state')),

  utc: z.string(),

  language: z.string().min(1, t('inform-field') + ' ' + t('users-language')),

  gender: z.string().min(1, t('inform-field') + ' ' + t('users-gender')),

  birthday: z.union([z.date(), z.string().min(1)]),
})

export type FormData = z.infer<typeof schema>

const userModalFormSelector = (state: StateRoot) => {
  return state.usersReducer
}

export const selectorResult = createSelector(
  userModalFormSelector,
  (userReducer) => {
    return userReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, userSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('users')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
    setValue,
  } = form

  const userTypeInfo = useGetUsersTypesDashboard()
  const farmInfo = useGetFarmsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectUser(null))
    dispatch(closeForm())
  }

  const { mutateAsync: updateUserFn, isPending: isLoadingUpdate } = useMutation(
    {
      mutationFn: updateUser,
    },
  )

  const cleanFields: FormData = {
    userTypeId: '',
    farmId: '',
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
    password: '',
    photo: '',
    emailVerified: '',
    phoneVerified: '',
    twoFactorEnabled: '',
    smsCode: '',
    emailCode: '',
    googleId: '',
    appleId: '',
    appleToken: '',
    country: '',
    state: '',
    utc: '',
    language: '',
    gender: '',
    birthday: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!userSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      userTypeId: formatSelectValueToFields(userSelected.userType, [
        'id',
        'name',
      ]),
      farmId: formatSelectValueToFields(userSelected.farm, ['id', 'name']),
      firstName: userSelected.firstName,
      lastName: userSelected.lastName,
      name: userSelected.name,
      email: userSelected.email,
      phone: formatSelectPhoneToFields(userSelected.phone),
      password: userSelected.password,
      photo: userSelected.photo,
      emailVerified: `${userSelected.emailVerified}`,
      phoneVerified: `${userSelected.phoneVerified}`,
      twoFactorEnabled: `${userSelected.twoFactorEnabled}`,
      smsCode: userSelected.smsCode,
      emailCode: userSelected.emailCode,
      googleId: userSelected.googleId,
      appleId: userSelected.appleId,
      appleToken: userSelected.appleToken,
      country: userSelected.country,
      state: userSelected.state,
      utc: userSelected.utc,
      language: userSelected.language,
      gender: userSelected.gender,
      birthday: formatDateToFields(userSelected.birthday),
    }

    reset(userSelected ? fields : cleanFields)
  }, [userSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedUserType = +formatToSendData(data?.userTypeId)
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedEmailVerified = formatToSendData(data?.emailVerified)
      const selectedPhoneVerified = formatToSendData(data?.phoneVerified)
      const selectedTwoFactorEnabled = formatToSendData(data?.twoFactorEnabled)

      const formatedData = {
        userTypeId: selectedUserType,
        farmId: selectedFarm,
        firstName: data?.firstName,
        lastName: data?.lastName,
        name: data?.name,
        email: data?.email,
        phone: data?.phone.phone,
        password: data?.password,
        photo: data?.photo,
        emailVerified: formatInputBooleanValue(selectedEmailVerified),
        phoneVerified: formatInputBooleanValue(selectedPhoneVerified),
        twoFactorEnabled: formatInputBooleanValue(selectedTwoFactorEnabled),
        smsCode: data?.smsCode,
        emailCode: data?.emailCode,
        googleId: data?.googleId,
        appleId: data?.appleId,
        appleToken: data?.appleToken,
        country: data?.country,
        state: data?.state,
        utc: data?.utc,
        language: data?.language,
        gender: data?.gender,
        birthday: formatInputDateValue(data?.birthday),
      }

      if (userSelected && userSelected?.id) {
        const { updateUserDashboard } = await updateUserFn({
          ...formatedData,
          userId: userSelected?.id,
        })

        dispatch(editUniqueData(updateUserDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  return {
    isLoading: isLoadingUpdate,
    formOpen,
    userSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    handleSelectCountry,
    resetFields,
    userTypeInfo,
    farmInfo,
  }
}
