import { graphClient } from 'services/graph-client'
import { TypeCoffeeTreatment } from 'types/types-coffees/types-coffees-treatments-types'

import { getTypesCoffeesTreatmentsDashboardGQL } from 'services/types-coffees/types-coffees-treatments/queries'

interface Response {
  getTypesCoffeesTreatmentsDashboard: {
    data: TypeCoffeeTreatment[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesTreatmentsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getTypesCoffeesTreatmentsDashboardGQL,
    {
      take,
      skip,
    },
  )
}
