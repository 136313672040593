import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  register: UseFormRegister<FormData>
}

export const FormControl = ({ control, errors, register }: Props) => {
  const permissions = useGetPermissions('users_devices_informations')
  const { userDeviceInformationSelected } = useSelector(selectorResult)
  const disabled = !!(userDeviceInformationSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="installationDevice"
            label={t('users-devices-informations-installation-device')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('users-devices-informations-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.manufacturer?.message}
            {...register('manufacturer')}
            label={t('users-devices-informations-manufacturer')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.mac?.message}
            {...register('mac')}
            label={t('users-devices-informations-mac')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.baseOs?.message}
            {...register('baseOs')}
            label={t('users-devices-informations-base-os')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.bootLoader?.message}
            {...register('bootLoader')}
            label={t('users-devices-informations-boot-loader')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.brand?.message}
            {...register('brand')}
            label={t('users-devices-informations-brand')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.buildNumber?.message}
            {...register('buildNumber')}
            label={t('users-devices-informations-build-number')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.isCameraPresent?.message}
            {...register('isCameraPresent')}
            label={t('users-devices-informations-is-camera-present')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.networkOperator?.message}
            {...register('networkOperator')}
            label={t('users-devices-informations-network-operator')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.deviceId?.message}
            {...register('deviceId')}
            label={t('users-devices-informations-device-id')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.deviceName?.message}
            {...register('deviceName')}
            label={t('users-devices-informations-device-name')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.ipAddress?.message}
            {...register('ipAddress')}
            label={t('users-devices-informations-ip-address')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.systemVersion?.message}
            {...register('systemVersion')}
            label={t('users-devices-informations-system-version')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.uniqueId?.message}
            {...register('uniqueId')}
            label={t('users-devices-informations-unique-id')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.userAgent?.message}
            {...register('userAgent')}
            label={t('users-devices-informations-user-agent')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
