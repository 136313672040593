import { graphClient } from 'services/graph-client'
import {
  activeAgriculturalInputCultivationDashboardGQL,
  deleteAgriculturalInputCultivationDashboardGQL,
  deleteForeverAgriculturalInputCultivationDashboardGQL,
  createAgriculturalInputCultivationDashboardGQL,
  updateAgriculturalInputCultivationDashboardGQL,
} from './queries'
import { AgriculturalInputCultivation } from 'types/agriculturals-inputs/agriculturals-inputs-cultivations-types'

interface ResponseCreate {
  createAgriculturalInputCultivationDashboard: {
    data: AgriculturalInputCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateAgriculturalInputCultivationDashboard: {
    data: AgriculturalInputCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeAgriculturalInputCultivationDashboard: {
    data: AgriculturalInputCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteAgriculturalInputCultivationDashboard: {
    data: AgriculturalInputCultivation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverAgriculturalInputCultivationDashboard: string
}

interface DataActive {
  agriculturalInputCultivationId: number
  active: boolean
}

interface DataDeleted {
  agriculturalInputCultivationId: number
  deleted: boolean
}

interface DataDeleteForever {
  agriculturalInputCultivationId: number
}

interface DataCreate {
  agriculturalInputId: number
  typeCultivationId: number
  name: string
}

interface DataUpdate {
  agriculturalInputCultivationId: number
  agriculturalInputId: number
  typeCultivationId: number
  name: string
}

export const activeAgriculturalInputCultivation = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeAgriculturalInputCultivationDashboardGQL,
    { ...data },
  )
}

export const deleteAgriculturalInputCultivation = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteAgriculturalInputCultivationDashboardGQL,
    { ...data },
  )
}

export const deleteForeverAgriculturalInputCultivation = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverAgriculturalInputCultivationDashboardGQL,
    { ...data },
  )
}

export const createAgriculturalInputCultivation = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createAgriculturalInputCultivationDashboardGQL,
    { ...data },
  )
}

export const updateAgriculturalInputCultivation = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateAgriculturalInputCultivationDashboardGQL,
    { ...data },
  )
}
