import { gql } from 'graphql-request'

export const createFarmDashboardGQL = gql`
  mutation CREATE_FARM_DASHBOARD(
    $name: String!
    $description: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $country: String
    $state: String
    $city: String
    $geographicLocation: String
    $hectareQuantity: Float!
    $usefullHectareQuantity: Float!
  ) {
    createFarmDashboard(
      name: $name
      description: $description
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      country: $country
      state: $state
      city: $city
      geographicLocation: $geographicLocation
      hectareQuantity: $hectareQuantity
      usefullHectareQuantity: $usefullHectareQuantity
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateFarmDashboardGQL = gql`
  mutation UPDATE_FARM_DASHBOARD(
    $farmId: Int!
    $name: String
    $description: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $country: String
    $state: String
    $city: String
    $geographicLocation: String
    $hectareQuantity: Float
    $usefullHectareQuantity: Float
  ) {
    updateFarmDashboard(
      farmId: $farmId
      name: $name
      description: $description
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      country: $country
      state: $state
      city: $city
      geographicLocation: $geographicLocation
      hectareQuantity: $hectareQuantity
      usefullHectareQuantity: $usefullHectareQuantity
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteFarmDashboardGQL = gql`
  mutation DELETE_FARM_DASHBOARD($farmId: Int!, $deleted: Boolean!) {
    deleteFarmDashboard(farmId: $farmId, deleted: $deleted) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeFarmDashboardGQL = gql`
  mutation ACTIVE_FARM_DASHBOARD($farmId: Int!, $active: Boolean!) {
    activeFarmDashboard(farmId: $farmId, active: $active) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverFarmDashboardGQL = gql`
  mutation DELETE_FOREVER_FARM_DASHBOARD($farmId: Int!) {
    deleteForeverFarmDashboard(farmId: $farmId)
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $country: String
    $state: String
    $city: String
    $geographicLocation: String
    $hectareQuantity: Float
    $usefullHectareQuantity: Float
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      country: $country
      state: $state
      city: $city
      geographicLocation: $geographicLocation
      hectareQuantity: $hectareQuantity
      usefullHectareQuantity: $usefullHectareQuantity
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsContainsDashboardGQL = gql`
  query GET_FARMS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $document: String
    $documentTwo: String
    $documentThree: String
    $email: String
    $phone: String
    $country: String
    $state: String
    $city: String
    $geographicLocation: String
  ) {
    getFarmsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      document: $document
      documentTwo: $documentTwo
      documentThree: $documentThree
      email: $email
      phone: $phone
      country: $country
      state: $state
      city: $city
      geographicLocation: $geographicLocation
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
