import { graphClient } from 'services/graph-client'
import {
  Company,
  FarmField,
  Farm,
  Harvest,
  OrderService,
  HarvestCostService,
} from 'types/harvests/harvests-costs-services-types'

import {
  getCompaniesDashboardGQL,
  getFarmsFieldsDashboardGQL,
  getFarmsDashboardGQL,
  getHarvestsDashboardGQL,
  getOrdersServicesDashboardGQL,
  getHarvestsCostsServicesDashboardGQL,
  getHarvestsCostsServicesContainsDashboardGQL,
} from './queries'

interface Response {
  getHarvestsCostsServicesDashboard: {
    data: HarvestCostService[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getHarvestsCostsServicesContainsDashboard: {
    data: HarvestCostService[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  farmId?: number
  farmFieldId?: number
  harvestId?: number
  orderServiceId?: number
  name?: string
  description?: string
  expectedNumberService?: number
  numberService?: number
  serviceValueApportionment?: number
  valueAgriculturalInput?: number
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getHarvestsCostsServicesDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getHarvestsCostsServicesDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getHarvestsCostsServicesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getHarvestsCostsServicesDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getHarvestsCostsServicesContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getHarvestsCostsServicesContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseFarmField {
  getFarmsFieldsDashboard: {
    data: FarmField[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsFieldsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarmField>(
    getFarmsFieldsDashboardGQL,
    { take, skip },
  )
}

interface ResponseFarm {
  getFarmsDashboard: {
    data: Farm[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarm>(getFarmsDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseHarvest {
  getHarvestsDashboard: {
    data: Harvest[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getHarvestsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseHarvest>(getHarvestsDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseOrderService {
  getOrdersServicesDashboard: {
    data: OrderService[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getOrdersServicesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseOrderService>(
    getOrdersServicesDashboardGQL,
    { take, skip },
  )
}
