import BreadCrumb from 'components/common/bread-crumb'
import { useHideScroll } from 'lib/hooks/use-hide-scroll'
import { withTranslation } from 'react-i18next'
import { Container } from 'reactstrap'
import List from './components/list'
import { Filter } from './modal/filter'
import { Form } from './modal/form'
import { Import } from './modal/import'

const EmployeeList = (props: { t: (value: string) => string }) => {
  useHideScroll()
  document.title = props.t('employees-page-title')

  return (
    <div className="page-content" style={{ paddingBottom: 0 }}>
      <Container fluid>
        <BreadCrumb
          data-key="t-menu"
          pageTitle={props.t('employees-page-title')}
          title={props.t('employees-title-list')}
        />

        <Form />
        <Filter />
        <Import />
        <List />
      </Container>
    </div>
  )
}

export default withTranslation()(EmployeeList)
