import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormDataFilter } from '../modal/filter/use-filter-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetAreasDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-areas-dashboard'
import { useGetCoffeesPruningsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-coffees-prunings-dashboard'
import { useGetFarmsFieldsPeriodsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-periods-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetTypesCoffeesColorsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-colors-dashboard'
import { useGetTypesCoffeesHarvestsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-harvests-dashboard'
import { useGetTypesCoffeesIrrigationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-irrigations-dashboard'
import { useGetTypesCoffeesManagementsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-managements-dashboard'
import { useGetTypesCoffeesMaturationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-maturations-dashboard'
import { useGetTypesCoffeesPruningsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-prunings-dashboard'
import { useGetTypesCoffeesSizesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-sizes-dashboard'
import { useGetTypesCoffeesVarietiesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-varieties-dashboard'
import { useGetTypesCoffeesRenovationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-coffees-renovations-dashboard'

interface Props {
  control: Control<FormDataFilter>
  errors: FieldErrors<FormDataFilter>
  areaInfo: ReturnType<typeof useGetAreasDashboard>
  coffeePruningInfo: ReturnType<typeof useGetCoffeesPruningsDashboard>
  farmFieldPeriodInfo: ReturnType<typeof useGetFarmsFieldsPeriodsDashboard>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  typeCoffeeColorInfo: ReturnType<typeof useGetTypesCoffeesColorsDashboard>
  typeCoffeeHarvestInfo: ReturnType<typeof useGetTypesCoffeesHarvestsDashboard>
  typeCoffeeIrrigationInfo: ReturnType<
    typeof useGetTypesCoffeesIrrigationsDashboard
  >
  typeCoffeeManagementInfo: ReturnType<
    typeof useGetTypesCoffeesManagementsDashboard
  >
  typeCoffeeMaturationInfo: ReturnType<
    typeof useGetTypesCoffeesMaturationsDashboard
  >
  typeCoffeePruningInfo: ReturnType<typeof useGetTypesCoffeesPruningsDashboard>
  typeCoffeeSizeInfo: ReturnType<typeof useGetTypesCoffeesSizesDashboard>
  typeCoffeeVarietyInfo: ReturnType<typeof useGetTypesCoffeesVarietiesDashboard>
  typeCoffeeRenovationInfo: ReturnType<
    typeof useGetTypesCoffeesRenovationsDashboard
  >
  register: UseFormRegister<FormDataFilter>
}

export const FormFilterControl = ({
  control,
  errors,
  areaInfo,
  coffeePruningInfo,
  farmFieldPeriodInfo,
  farmInfo,
  typeCoffeeColorInfo,
  typeCoffeeHarvestInfo,
  typeCoffeeIrrigationInfo,
  typeCoffeeManagementInfo,
  typeCoffeeMaturationInfo,
  typeCoffeePruningInfo,
  typeCoffeeSizeInfo,
  typeCoffeeVarietyInfo,
  typeCoffeeRenovationInfo,
  register,
}: Props) => {
  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={areaInfo?.areasDashboard}
            info={areaInfo}
            label={t('farms-fields-area-id')}
            name="areaId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={coffeePruningInfo?.coffeesPruningsDashboard}
            info={coffeePruningInfo}
            label={t('farms-fields-coffee-pruning-id')}
            name="coffeePruningId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmFieldPeriodInfo?.farmsFieldsPeriodsDashboard}
            info={farmFieldPeriodInfo}
            label={t('farms-fields-farm-field-period-id')}
            name="farmFieldPeriodId"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('farms-fields-farm-id')}
            name="farmId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeCoffeeColorInfo?.typesCoffeesColorsDashboard}
            info={typeCoffeeColorInfo}
            label={t('farms-fields-type-coffee-color-id')}
            name="typeCoffeeColorId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeCoffeeHarvestInfo?.typesCoffeesHarvestsDashboard}
            info={typeCoffeeHarvestInfo}
            label={t('farms-fields-type-coffee-harvest-id')}
            name="typeCoffeeHarvestId"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeCoffeeIrrigationInfo?.typesCoffeesIrrigationsDashboard}
            info={typeCoffeeIrrigationInfo}
            label={t('farms-fields-type-coffee-irrigation-id')}
            name="typeCoffeeIrrigationId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeCoffeeManagementInfo?.typesCoffeesManagementsDashboard}
            info={typeCoffeeManagementInfo}
            label={t('farms-fields-type-coffee-management-id')}
            name="typeCoffeeManagementId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeCoffeeMaturationInfo?.typesCoffeesMaturationsDashboard}
            info={typeCoffeeMaturationInfo}
            label={t('farms-fields-type-coffee-maturation-id')}
            name="typeCoffeeMaturationId"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeCoffeePruningInfo?.typesCoffeesPruningsDashboard}
            info={typeCoffeePruningInfo}
            label={t('farms-fields-type-coffee-pruning-id')}
            name="typeCoffeePruningId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeCoffeeSizeInfo?.typesCoffeesSizesDashboard}
            info={typeCoffeeSizeInfo}
            label={t('farms-fields-type-coffee-size-id')}
            name="typeCoffeeSizeId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeCoffeeVarietyInfo?.typesCoffeesVarietiesDashboard}
            info={typeCoffeeVarietyInfo}
            label={t('farms-fields-type-coffee-variety-id')}
            name="typeCoffeeVarietyId"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeCoffeeRenovationInfo?.typesCoffeesRenovationsDashboard}
            info={typeCoffeeRenovationInfo}
            label={t('farms-fields-type-coffee-renovation-id')}
            name="typeCoffeeRenovationId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="active"
            label={t('farms-fields-active')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="deleted"
            label={t('farms-fields-deleted')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('farms-fields-name')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="yearPlanting"
            label={t('farms-fields-year-planting')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.monthPlanting?.message}
            {...register('monthPlanting')}
            label={t('farms-fields-month-planting')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="plantFailure"
            label={t('farms-fields-plant-failure')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="plantingQuantity"
            label={t('farms-fields-planting-quantity')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.geographicLocation?.message}
            {...register('geographicLocation')}
            label={t('farms-fields-geographic-location')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtInitial"
            label={t('farms-fields-created-at-initial')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtFinal"
            label={t('farms-fields-created-at-final')}
          />
        </Col>
      </Row>
    </Container>
  )
}
