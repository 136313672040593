import { createSelector } from 'reselect'
import { TypePest } from 'types/types-coffees/types-pests-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverTypePest } from 'services/types-coffees/types-pests/types-pests-cruds'
import {
  getTypesPestsDashboard,
  GetParams,
  getTypesPestsContainsDashboard,
} from 'services/types-coffees/types-pests/types-pests-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/types-coffees/types-pests-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const typePestListSelector = (state: StateRoot) => state.typesPestsReducer

const selectorResult = createSelector(
  typePestListSelector,
  (typePestListReducer) => {
    return typePestListReducer
  },
)

export const useTypePestList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('types_pests')

  const {
    data: dataTypePest,
    deleteModalOpen,
    typePestIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'typePest', 'list', { params }],
    queryFn: () => getTypesPestsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverTypePest,
  })

  const handleStoreData = useCallback(
    (typesPests: TypePest[], totalPages: number | null) => {
      if (!typesPests) return

      dispatch(setData(typesPests))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverTypePest = useCallback(async () => {
    if (!typePestIdToDelete) return

    const response = await mutateAsync({
      typePestId: +typePestIdToDelete,
    })

    if (response?.deleteForeverTypePestDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: typePestIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [typePestIdToDelete])

  useEffect(() => {
    const typesPestsResult = data?.getTypesPestsDashboard

    if (typesPestsResult) {
      handleStoreData(typesPestsResult?.data, typesPestsResult?.totalPages)
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getTypesPestsContainsDashboard(value)
      const typesPestsResult = response?.getTypesPestsContainsDashboard

      handleStoreData(typesPestsResult?.data, typesPestsResult?.totalPages)
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    typePestIdToDelete,
    typePest: data?.getTypesPestsDashboard?.data,
    isLoading,
    isFetching,
    dataTypePest,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverTypePest,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
