import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeCoffeeVarietyDashboardGQL,
  deleteDefaultTypeCoffeeVarietyDashboardGQL,
  deleteForeverDefaultTypeCoffeeVarietyDashboardGQL,
  createDefaultTypeCoffeeVarietyDashboardGQL,
  updateDefaultTypeCoffeeVarietyDashboardGQL,
} from './queries'
import { DefaultTypeCoffeeVariety } from 'types/defaults/defaults-types-coffees-varieties-types'

interface ResponseCreate {
  createDefaultTypeCoffeeVarietyDashboard: {
    data: DefaultTypeCoffeeVariety
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeCoffeeVarietyDashboard: {
    data: DefaultTypeCoffeeVariety
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeCoffeeVarietyDashboard: {
    data: DefaultTypeCoffeeVariety
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeCoffeeVarietyDashboard: {
    data: DefaultTypeCoffeeVariety
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeCoffeeVarietyDashboard: string
}

interface DataActive {
  defaultTypeCoffeeVarietyId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeCoffeeVarietyId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeCoffeeVarietyId: number
}

interface DataCreate {
  name: string
  description?: string
  numbering?: string
}

interface DataUpdate {
  defaultTypeCoffeeVarietyId: number
  name: string
  description?: string
  numbering?: string
}

export const activeDefaultTypeCoffeeVariety = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeCoffeeVarietyDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeCoffeeVariety = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeCoffeeVarietyDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeCoffeeVariety = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeCoffeeVarietyDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeCoffeeVariety = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeCoffeeVarietyDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeCoffeeVariety = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeCoffeeVarietyDashboardGQL,
    { ...data },
  )
}
