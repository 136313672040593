import { graphClient } from 'services/graph-client'
import { TypeCoffeeColor } from 'types/types-coffees/types-coffees-colors-types'

import { getTypesCoffeesColorsDashboardGQL } from 'services/types-coffees/types-coffees-colors/queries'

interface Response {
  getTypesCoffeesColorsDashboard: {
    data: TypeCoffeeColor[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesColorsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getTypesCoffeesColorsDashboardGQL,
    {
      take,
      skip,
    },
  )
}
