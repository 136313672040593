import { graphClient } from 'services/graph-client'
import { ExampleOtherOne } from 'types/examples-tables/examples-others-ones-types'

import {
  getExamplesOthersOnesDashboardGQL,
  getExamplesOthersOnesContainsDashboardGQL,
} from './queries'

interface Response {
  getExamplesOthersOnesDashboard: {
    data: ExampleOtherOne[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getExamplesOthersOnesContainsDashboard: {
    data: ExampleOtherOne[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
}

export interface GetParams {
  page?: number
  name?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getExamplesOthersOnesDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getExamplesOthersOnesDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getExamplesOthersOnesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getExamplesOthersOnesDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getExamplesOthersOnesContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getExamplesOthersOnesContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}
