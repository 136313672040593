import { graphClient } from 'services/graph-client'
import {
  activeOrderServiceEmployeeDashboardGQL,
  deleteOrderServiceEmployeeDashboardGQL,
  deleteForeverOrderServiceEmployeeDashboardGQL,
  createOrderServiceEmployeeDashboardGQL,
  updateOrderServiceEmployeeDashboardGQL,
} from './queries'
import { OrderServiceEmployee } from 'types/orders/orders-services-employees-types'

interface ResponseCreate {
  createOrderServiceEmployeeDashboard: {
    data: OrderServiceEmployee
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateOrderServiceEmployeeDashboard: {
    data: OrderServiceEmployee
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeOrderServiceEmployeeDashboard: {
    data: OrderServiceEmployee
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteOrderServiceEmployeeDashboard: {
    data: OrderServiceEmployee
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverOrderServiceEmployeeDashboard: string
}

interface DataActive {
  orderServiceEmployeeId: number
  active: boolean
}

interface DataDeleted {
  orderServiceEmployeeId: number
  deleted: boolean
}

interface DataDeleteForever {
  orderServiceEmployeeId: number
}

interface DataCreate {
  farmId?: number
  farmFieldId?: number
  orderServiceId: number
  employeeId: number
  name: string
}

interface DataUpdate {
  orderServiceEmployeeId: number
  farmId?: number
  farmFieldId?: number
  orderServiceId: number
  employeeId: number
  name: string
}

export const activeOrderServiceEmployee = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeOrderServiceEmployeeDashboardGQL,
    { ...data },
  )
}

export const deleteOrderServiceEmployee = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteOrderServiceEmployeeDashboardGQL,
    { ...data },
  )
}

export const deleteForeverOrderServiceEmployee = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverOrderServiceEmployeeDashboardGQL,
    { ...data },
  )
}

export const createOrderServiceEmployee = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createOrderServiceEmployeeDashboardGQL,
    { ...data },
  )
}

export const updateOrderServiceEmployee = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateOrderServiceEmployeeDashboardGQL,
    { ...data },
  )
}
