import { graphClient } from 'services/graph-client'
import {
  activeTypeCoffeeMaturationDashboardGQL,
  deleteTypeCoffeeMaturationDashboardGQL,
  deleteForeverTypeCoffeeMaturationDashboardGQL,
  createTypeCoffeeMaturationDashboardGQL,
  updateTypeCoffeeMaturationDashboardGQL,
} from './queries'
import { TypeCoffeeMaturation } from 'types/types-coffees/types-coffees-maturations-types'

interface ResponseCreate {
  createTypeCoffeeMaturationDashboard: {
    data: TypeCoffeeMaturation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeCoffeeMaturationDashboard: {
    data: TypeCoffeeMaturation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeCoffeeMaturationDashboard: {
    data: TypeCoffeeMaturation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeCoffeeMaturationDashboard: {
    data: TypeCoffeeMaturation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeCoffeeMaturationDashboard: string
}

interface DataActive {
  typeCoffeeMaturationId: number
  active: boolean
}

interface DataDeleted {
  typeCoffeeMaturationId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeCoffeeMaturationId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  typeCoffeeMaturationId: number
  name: string
  description?: string
}

export const activeTypeCoffeeMaturation = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeTypeCoffeeMaturationDashboardGQL,
    { ...data },
  )
}

export const deleteTypeCoffeeMaturation = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteTypeCoffeeMaturationDashboardGQL,
    { ...data },
  )
}

export const deleteForeverTypeCoffeeMaturation = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeCoffeeMaturationDashboardGQL,
    { ...data },
  )
}

export const createTypeCoffeeMaturation = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createTypeCoffeeMaturationDashboardGQL,
    { ...data },
  )
}

export const updateTypeCoffeeMaturation = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateTypeCoffeeMaturationDashboardGQL,
    { ...data },
  )
}
