import { Status } from './list-columns'
import { formatDate } from 'helpers/format/format-date'
import { t } from 'i18next'

export const useColumns = () => {
  const columnsVisible = {
    name: true,
  }

  return [
    {
      Header: t('id'),
      accessor: 'id',
      filterable: false,
      visible: true,
      Cell: (cellProps: any) => {
        return (
          <span className="fw-medium link-primary">{cellProps?.value}</span>
        )
      },
    },
    {
      Header: t('examples-tables-company-id'),
      accessor: 'company.name',
      filterable: false,
      visible: columnsVisible['name'],
      Cell: (cellProps: any) => {
        return <span>{cellProps?.value}</span>
      },
    },
    {
      Header: t('examples-tables-example-other-one-id'),
      accessor: 'exampleOtherOne.name',
      filterable: false,
      Cell: (cellProps: any) => {
        return <span>{cellProps?.value}</span>
      },
    },
    {
      Header: t('examples-tables-user-id'),
      accessor: 'user.name',
      filterable: false,
      Cell: (cellProps: any) => {
        return <span>{cellProps?.value}</span>
      },
    },
    {
      Header: t('examples-tables-example-other-two-id'),
      accessor: 'exampleOtherTwo.name',
      filterable: false,
      Cell: (cellProps: any) => {
        return <span>{cellProps?.value}</span>
      },
    },
    {
      Header: t('examples-tables-type-enum'),
      accessor: 'typeEnum',
      filterable: false,
      Cell: (cellProps: any) => {
        return <span>{`${cellProps?.value}`}</span>
      },
    },
    {
      Header: t('examples-tables-name'),
      accessor: 'name',
      filterable: false,
      Cell: (cellProps: any) => {
        return <span>{cellProps?.value}</span>
      },
    },
    {
      Header: t('examples-tables-type-text'),
      accessor: 'typeText',
      filterable: false,
      Cell: (cellProps: any) => {
        return <span>{cellProps?.value}</span>
      },
    },
    {
      Header: t('examples-tables-type-boolean'),
      accessor: 'typeBoolean',
      filterable: false,
      Cell: (cellProps: any) => {
        return <span>{`${cellProps?.value}`}</span>
      },
    },
    {
      Header: t('examples-tables-type-int'),
      accessor: 'typeInt',
      filterable: false,
      Cell: (cellProps: any) => {
        return <span>{cellProps?.value}</span>
      },
    },
    {
      Header: t('examples-tables-type-decimal'),
      accessor: 'typeDecimal',
      filterable: false,
      Cell: (cellProps: any) => {
        return <span>{cellProps?.value}</span>
      },
    },
    {
      Header: t('examples-tables-type-currency'),
      accessor: 'typeCurrency',
      filterable: false,
      Cell: (cellProps: any) => {
        return <span>{cellProps?.value}</span>
      },
    },
    {
      Header: t('examples-tables-email'),
      accessor: 'email',
      filterable: false,
      Cell: (cellProps: any) => {
        return <span>{cellProps?.value}</span>
      },
    },
    {
      Header: t('examples-tables-phone'),
      accessor: 'phone',
      filterable: false,
      Cell: (cellProps: any) => {
        return <span>{cellProps?.value}</span>
      },
    },
    {
      Header: t('examples-tables-type-datetime'),
      accessor: 'typeDatetime',
      filterable: false,
      Cell: (cellProps: any) => {
        const date = cellProps?.value

        return <span>{formatDate(date)}</span>
      },
    },
    {
      Header: t('active'),
      accessor: 'actions',
      filterable: false,
      Cell: (props: any, cellProps: any) => {
        return <Status {...cellProps} {...props} showActive={true} />
      },
    },
  ]
}
