import { graphClient } from 'services/graph-client'
import {
  activeAreaDashboardGQL,
  deleteAreaDashboardGQL,
  deleteForeverAreaDashboardGQL,
  createAreaDashboardGQL,
  updateAreaDashboardGQL,
} from './queries'
import { Area } from 'types/farms/areas-types'

interface ResponseCreate {
  createAreaDashboard: {
    data: Area
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateAreaDashboard: {
    data: Area
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeAreaDashboard: {
    data: Area
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteAreaDashboard: {
    data: Area
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverAreaDashboard: string
}

interface DataActive {
  areaId: number
  active: boolean
}

interface DataDeleted {
  areaId: number
  deleted: boolean
}

interface DataDeleteForever {
  areaId: number
}

interface DataCreate {
  name: string
  description: string
  geographicLocation?: string
  hectareQuantity: number
  usefullHectareQuantity: number
}

interface DataUpdate {
  areaId: number
  name: string
  description: string
  geographicLocation?: string
  hectareQuantity: number
  usefullHectareQuantity: number
}

export const activeArea = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeAreaDashboardGQL, {
    ...data,
  })
}

export const deleteArea = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteAreaDashboardGQL, {
    ...data,
  })
}

export const deleteForeverArea = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverAreaDashboardGQL,
    { ...data },
  )
}

export const createArea = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(createAreaDashboardGQL, {
    ...data,
  })
}

export const updateArea = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateAreaDashboardGQL, {
    ...data,
  })
}
