import { createSlice } from '@reduxjs/toolkit'
import { DefaultTypeExpense } from 'types/defaults/defaults-types-expenses-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  defaultTypeExpenseSelected: null | DefaultTypeExpense
  deleteModalOpen: boolean
  defaultTypeExpenseIdToDelete: null | string
  data: DefaultTypeExpense[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  defaultTypeExpenseSelected: null,
  defaultTypeExpenseIdToDelete: null,
  data: [],
  total: 0,
}

const defaultTypeExpenseSlice = createSlice({
  initialState,
  name: 'defaults-types-expenses-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectDefaultTypeExpense(
      state,
      { payload }: { payload: DefaultTypeExpense | null },
    ) {
      state.defaultTypeExpenseSelected = payload
    },
    setData(state, { payload }: { payload: DefaultTypeExpense[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: DefaultTypeExpense }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: DefaultTypeExpense }) {
      state.data = state.data.map((defaultTypeExpense) => {
        return defaultTypeExpense.id === payload.id
          ? { ...defaultTypeExpense, ...payload }
          : defaultTypeExpense
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (defaultTypeExpense) => +defaultTypeExpense.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.defaultTypeExpenseIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.defaultTypeExpenseIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectDefaultTypeExpense,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = defaultTypeExpenseSlice.actions
export default defaultTypeExpenseSlice.reducer
