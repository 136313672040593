import { gql } from 'graphql-request'

export const createFarmInventoryDashboardGQL = gql`
  mutation CREATE_FARM_INVENTORY_DASHBOARD(
    $farmId: Int
    $name: String!
    $serialNumber: String!
    $purchasCost: Float!
    $photoInvoice: String!
    $datePurchase: String!
    $yearPurchase: String!
    $typeToolId: Int!
  ) {
    createFarmInventoryDashboard(
      farmId: $farmId
      name: $name
      serialNumber: $serialNumber
      purchasCost: $purchasCost
      photoInvoice: $photoInvoice
      datePurchase: $datePurchase
      yearPurchase: $yearPurchase
      typeToolId: $typeToolId
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeToolId
        typeTool {
          id
          name
        }
        name
        serialNumber
        purchasCost
        photoInvoice
        datePurchase
        yearPurchase
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateFarmInventoryDashboardGQL = gql`
  mutation UPDATE_FARM_INVENTORY_DASHBOARD(
    $farmInventoryId: Int!
    $farmId: Int
    $name: String
    $serialNumber: String
    $purchasCost: Float
    $photoInvoice: String
    $datePurchase: String
    $yearPurchase: String
    $typeToolId: Int
  ) {
    updateFarmInventoryDashboard(
      farmInventoryId: $farmInventoryId
      farmId: $farmId
      name: $name
      serialNumber: $serialNumber
      purchasCost: $purchasCost
      photoInvoice: $photoInvoice
      datePurchase: $datePurchase
      yearPurchase: $yearPurchase
      typeToolId: $typeToolId
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeToolId
        typeTool {
          id
          name
        }
        name
        serialNumber
        purchasCost
        photoInvoice
        datePurchase
        yearPurchase
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteFarmInventoryDashboardGQL = gql`
  mutation DELETE_FARM_INVENTORY_DASHBOARD(
    $farmInventoryId: Int!
    $deleted: Boolean!
  ) {
    deleteFarmInventoryDashboard(
      farmInventoryId: $farmInventoryId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        name
        serialNumber
        purchasCost
        photoInvoice
        datePurchase
        yearPurchase
        typeToolId
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeFarmInventoryDashboardGQL = gql`
  mutation ACTIVE_FARM_INVENTORY_DASHBOARD(
    $farmInventoryId: Int!
    $active: Boolean!
  ) {
    activeFarmInventoryDashboard(
      farmInventoryId: $farmInventoryId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        name
        serialNumber
        purchasCost
        photoInvoice
        datePurchase
        yearPurchase
        typeToolId
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverFarmInventoryDashboardGQL = gql`
  mutation DELETE_FOREVER_FARM_INVENTORY_DASHBOARD($farmInventoryId: Int!) {
    deleteForeverFarmInventoryDashboard(farmInventoryId: $farmInventoryId)
  }
`

export const getFarmsInventoriesDashboardGQL = gql`
  query GET_FARMS_INVENTORIES_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $name: String
    $serialNumber: String
    $purchasCost: Float
    $photoInvoice: String
    $datePurchase: String
    $yearPurchase: String
    $typeToolId: Int
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getFarmsInventoriesDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      name: $name
      serialNumber: $serialNumber
      purchasCost: $purchasCost
      photoInvoice: $photoInvoice
      datePurchase: $datePurchase
      yearPurchase: $yearPurchase
      typeToolId: $typeToolId
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeToolId
        typeTool {
          id
          name
        }
        name
        serialNumber
        purchasCost
        photoInvoice
        datePurchase
        yearPurchase
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeToolDashboardIdGQL = gql`
  query GET_TYPE_TOOL_DASHBOARD_ID($typeToolId: Int!) {
    getTypeToolDashboard(typeToolId: $typeToolId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesToolsDashboardGQL = gql`
  query GET_TYPES_TOOLS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesToolsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsInventoriesContainsDashboardGQL = gql`
  query GET_FARMS_INVENTORIES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $serialNumber: String
    $photoInvoice: String
    $yearPurchase: String
  ) {
    getFarmsInventoriesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      serialNumber: $serialNumber
      photoInvoice: $photoInvoice
      yearPurchase: $yearPurchase
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeToolId
        typeTool {
          id
          name
        }
        name
        serialNumber
        purchasCost
        photoInvoice
        datePurchase
        yearPurchase
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
