import { graphClient } from 'services/graph-client'
import {
  activeTypeCoffeeSizeDashboardGQL,
  deleteTypeCoffeeSizeDashboardGQL,
  deleteForeverTypeCoffeeSizeDashboardGQL,
  createTypeCoffeeSizeDashboardGQL,
  updateTypeCoffeeSizeDashboardGQL,
} from './queries'
import { TypeCoffeeSize } from 'types/types-coffees/types-coffees-sizes-types'

interface ResponseCreate {
  createTypeCoffeeSizeDashboard: {
    data: TypeCoffeeSize
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeCoffeeSizeDashboard: {
    data: TypeCoffeeSize
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeCoffeeSizeDashboard: {
    data: TypeCoffeeSize
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeCoffeeSizeDashboard: {
    data: TypeCoffeeSize
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeCoffeeSizeDashboard: string
}

interface DataActive {
  typeCoffeeSizeId: number
  active: boolean
}

interface DataDeleted {
  typeCoffeeSizeId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeCoffeeSizeId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  typeCoffeeSizeId: number
  name: string
  description?: string
}

export const activeTypeCoffeeSize = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeTypeCoffeeSizeDashboardGQL,
    { ...data },
  )
}

export const deleteTypeCoffeeSize = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteTypeCoffeeSizeDashboardGQL,
    { ...data },
  )
}

export const deleteForeverTypeCoffeeSize = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeCoffeeSizeDashboardGQL,
    { ...data },
  )
}

export const createTypeCoffeeSize = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createTypeCoffeeSizeDashboardGQL,
    { ...data },
  )
}

export const updateTypeCoffeeSize = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateTypeCoffeeSizeDashboardGQL,
    { ...data },
  )
}
