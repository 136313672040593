import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetTypesCultivationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-cultivations-dashboard'
import { useGetTypesMeasurementsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-measurements-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  farmFieldInfo: ReturnType<typeof useGetFarmsFieldsDashboard>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  typeCultivationInfo: ReturnType<typeof useGetTypesCultivationsDashboard>
  typeMeasurementInfo: ReturnType<typeof useGetTypesMeasurementsDashboard>
  register: UseFormRegister<FormData>
}

export const FormControl = ({
  control,
  errors,
  farmFieldInfo,
  farmInfo,
  typeCultivationInfo,
  typeMeasurementInfo,
  register,
}: Props) => {
  const permissions = useGetPermissions('harvests')
  const { harvestSelected } = useSelector(selectorResult)
  const disabled = !!(harvestSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmFieldInfo?.farmsFieldsDashboard}
            info={farmFieldInfo}
            label={t('harvests-farm-field-id')}
            name="farmFieldId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('harvests-farm-id')}
            name="farmId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeCultivationInfo?.typesCultivationsDashboard}
            info={typeCultivationInfo}
            label={t('harvests-type-cultivation-id')}
            name="typeCultivationId"
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={typeMeasurementInfo?.typesMeasurementsDashboard}
            info={typeMeasurementInfo}
            label={t('harvests-type-measurement-id')}
            name="typeMeasurementId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="automaticCost"
            label={t('harvests-automatic-cost')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('harvests-name')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('harvests-description')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="dateHarvestInitial"
            label={t('harvests-date-harvest-initial')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="dateHarvestFinal"
            label={t('harvests-date-harvest-final')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
