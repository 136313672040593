import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypePestDashboardGQL,
  deleteDefaultTypePestDashboardGQL,
  deleteForeverDefaultTypePestDashboardGQL,
  createDefaultTypePestDashboardGQL,
  updateDefaultTypePestDashboardGQL,
} from './queries'
import { DefaultTypePest } from 'types/defaults/defaults-types-pests-types'

interface ResponseCreate {
  createDefaultTypePestDashboard: {
    data: DefaultTypePest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypePestDashboard: {
    data: DefaultTypePest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypePestDashboard: {
    data: DefaultTypePest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypePestDashboard: {
    data: DefaultTypePest
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypePestDashboard: string
}

interface DataActive {
  defaultTypePestId: number
  active: boolean
}

interface DataDeleted {
  defaultTypePestId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypePestId: number
}

interface DataCreate {
  commonName: string
  scientificName?: string
  name: string
  description: string
}

interface DataUpdate {
  defaultTypePestId: number
  commonName: string
  scientificName?: string
  name: string
  description: string
}

export const activeDefaultTypePest = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypePestDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypePest = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypePestDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypePest = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypePestDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypePest = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypePestDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypePest = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypePestDashboardGQL,
    { ...data },
  )
}
