import GoogleMapScreen, { mapReducer } from './components/google-map/map'
import { t } from 'i18next'
import { Col, Container } from 'reactstrap'
import { useHideScroll } from 'lib/hooks/use-hide-scroll'
import { Card } from './components/card'
import { useSelector } from 'react-redux'
import { ButtonAddNew } from './components/button'
import SimpleBar from 'simplebar-react'

const Map = () => {
  useHideScroll()
  document.title = t('Map')

  const { isMap, areas } = useSelector(mapReducer)

  return (
    <div className="h-100 w-100 d-flex justify-content-center align-items-center">
      {!isMap && (
        <Container fluid className="page-content vh-100 position-relative">
          <Col className="mb-4">
            <span style={{ fontSize: 20 }}>Áreas</span>
          </Col>

          {!!areas?.length && (
            <SimpleBar style={{ height: '100%' }}>
              <div className="d-flex flex-wrap gap-2">
                {areas.map((area) => (
                  <Card key={area.id} area={area} />
                ))}
              </div>
            </SimpleBar>
          )}

          <ButtonAddNew />
        </Container>
      )}

      {isMap && <GoogleMapScreen />}
    </div>
  )
}

export default Map
