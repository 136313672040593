import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  register: UseFormRegister<FormData>
}

export const FormControl = ({ control, errors, register }: Props) => {
  const permissions = useGetPermissions('types_coffees_varieties')
  const { typeCoffeeVarietySelected } = useSelector(selectorResult)
  const disabled = !!(typeCoffeeVarietySelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('types-coffees-varieties-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('types-coffees-varieties-description')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.numbering?.message}
            {...register('numbering')}
            label={t('types-coffees-varieties-numbering')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
