import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

const schemaFilter = z.object({
  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('users-devices-informations-name')),

  installationDevice: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('users-devices-informations-installation-device'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  manufacturer: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-manufacturer'),
    ),

  mac: z
    .string()
    .min(1, t('inform-field') + ' ' + t('users-devices-informations-mac')),

  baseOs: z.string(),
  bootLoader: z.string(),
  brand: z
    .string()
    .min(1, t('inform-field') + ' ' + t('users-devices-informations-brand')),

  buildNumber: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-build-number'),
    ),

  isCameraPresent: z.string(),
  networkOperator: z
    .string()
    .min(
      1,
      t('inform-field') +
        ' ' +
        t('users-devices-informations-network-operator'),
    ),

  deviceId: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-device-id'),
    ),

  deviceName: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-device-name'),
    ),

  ipAddress: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-ip-address'),
    ),

  systemVersion: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-system-version'),
    ),

  uniqueId: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-unique-id'),
    ),

  userAgent: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('users-devices-informations-user-agent'),
    ),

  active: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('users-devices-informations-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('users-devices-informations-deleted'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    name: '',
    installationDevice: '',
    manufacturer: '',
    mac: '',
    baseOs: '',
    bootLoader: '',
    brand: '',
    buildNumber: '',
    isCameraPresent: '',
    networkOperator: '',
    deviceId: '',
    deviceName: '',
    ipAddress: '',
    systemVersion: '',
    uniqueId: '',
    userAgent: '',
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    getValues,
  } = form

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    register,
    resetFields,
    getValues,
  }
}
