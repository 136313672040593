import { createSelector } from 'reselect'
import { EmployeeDayWorked } from 'types/employees/employees-days-workeds-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverEmployeeDayWorked } from 'services/employees/employees-days-workeds/employees-days-workeds-cruds'
import {
  getEmployeesDaysWorkedsDashboard,
  GetParams,
  getEmployeesDaysWorkedsContainsDashboard,
} from 'services/employees/employees-days-workeds/employees-days-workeds-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/employees/employees-days-workeds-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const employeeDayWorkedListSelector = (state: StateRoot) =>
  state.employeesDaysWorkedsReducer

const selectorResult = createSelector(
  employeeDayWorkedListSelector,
  (employeeDayWorkedListReducer) => {
    return employeeDayWorkedListReducer
  },
)

export const useEmployeeDayWorkedList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('employees_days_workeds')

  const {
    data: dataEmployeeDayWorked,
    deleteModalOpen,
    employeeDayWorkedIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'employeeDayWorked', 'list', { params }],
    queryFn: () => getEmployeesDaysWorkedsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverEmployeeDayWorked,
  })

  const handleStoreData = useCallback(
    (employeesDaysWorkeds: EmployeeDayWorked[], totalPages: number | null) => {
      if (!employeesDaysWorkeds) return

      dispatch(setData(employeesDaysWorkeds))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverEmployeeDayWorked = useCallback(async () => {
    if (!employeeDayWorkedIdToDelete) return

    const response = await mutateAsync({
      employeeDayWorkedId: +employeeDayWorkedIdToDelete,
    })

    if (
      response?.deleteForeverEmployeeDayWorkedDashboard === 'deleted_forever'
    ) {
      dispatch(deleteUniqueData({ id: employeeDayWorkedIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [employeeDayWorkedIdToDelete])

  useEffect(() => {
    const employeesDaysWorkedsResult = data?.getEmployeesDaysWorkedsDashboard

    if (employeesDaysWorkedsResult) {
      handleStoreData(
        employeesDaysWorkedsResult?.data,
        employeesDaysWorkedsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getEmployeesDaysWorkedsContainsDashboard(value)
      const employeesDaysWorkedsResult =
        response?.getEmployeesDaysWorkedsContainsDashboard

      handleStoreData(
        employeesDaysWorkedsResult?.data,
        employeesDaysWorkedsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    employeeDayWorkedIdToDelete,
    employeeDayWorked: data?.getEmployeesDaysWorkedsDashboard?.data,
    isLoading,
    isFetching,
    dataEmployeeDayWorked,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverEmployeeDayWorked,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
