import { createSelector } from 'reselect'
import { TypeCoffeeHarvest } from 'types/types-coffees/types-coffees-harvests-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverTypeCoffeeHarvest } from 'services/types-coffees/types-coffees-harvests/types-coffees-harvests-cruds'
import {
  getTypesCoffeesHarvestsDashboard,
  GetParams,
  getTypesCoffeesHarvestsContainsDashboard,
} from 'services/types-coffees/types-coffees-harvests/types-coffees-harvests-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/types-coffees/types-coffees-harvests-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const typeCoffeeHarvestListSelector = (state: StateRoot) =>
  state.typesCoffeesHarvestsReducer

const selectorResult = createSelector(
  typeCoffeeHarvestListSelector,
  (typeCoffeeHarvestListReducer) => {
    return typeCoffeeHarvestListReducer
  },
)

export const useTypeCoffeeHarvestList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('types_coffees_harvests')

  const {
    data: dataTypeCoffeeHarvest,
    deleteModalOpen,
    typeCoffeeHarvestIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'typeCoffeeHarvest', 'list', { params }],
    queryFn: () => getTypesCoffeesHarvestsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverTypeCoffeeHarvest,
  })

  const handleStoreData = useCallback(
    (typesCoffeesHarvests: TypeCoffeeHarvest[], totalPages: number | null) => {
      if (!typesCoffeesHarvests) return

      dispatch(setData(typesCoffeesHarvests))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverTypeCoffeeHarvest = useCallback(async () => {
    if (!typeCoffeeHarvestIdToDelete) return

    const response = await mutateAsync({
      typeCoffeeHarvestId: +typeCoffeeHarvestIdToDelete,
    })

    if (
      response?.deleteForeverTypeCoffeeHarvestDashboard === 'deleted_forever'
    ) {
      dispatch(deleteUniqueData({ id: typeCoffeeHarvestIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [typeCoffeeHarvestIdToDelete])

  useEffect(() => {
    const typesCoffeesHarvestsResult = data?.getTypesCoffeesHarvestsDashboard

    if (typesCoffeesHarvestsResult) {
      handleStoreData(
        typesCoffeesHarvestsResult?.data,
        typesCoffeesHarvestsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getTypesCoffeesHarvestsContainsDashboard(value)
      const typesCoffeesHarvestsResult =
        response?.getTypesCoffeesHarvestsContainsDashboard

      handleStoreData(
        typesCoffeesHarvestsResult?.data,
        typesCoffeesHarvestsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    typeCoffeeHarvestIdToDelete,
    typeCoffeeHarvest: data?.getTypesCoffeesHarvestsDashboard?.data,
    isLoading,
    isFetching,
    dataTypeCoffeeHarvest,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverTypeCoffeeHarvest,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
