import { graphClient } from 'services/graph-client'
import {
  activeHarvestRealDashboardGQL,
  deleteHarvestRealDashboardGQL,
  deleteForeverHarvestRealDashboardGQL,
  createHarvestRealDashboardGQL,
  updateHarvestRealDashboardGQL,
} from './queries'
import { HarvestReal } from 'types/harvests/harvests-reals-types'

interface ResponseCreate {
  createHarvestRealDashboard: {
    data: HarvestReal
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateHarvestRealDashboard: {
    data: HarvestReal
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeHarvestRealDashboard: {
    data: HarvestReal
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteHarvestRealDashboard: {
    data: HarvestReal
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverHarvestRealDashboard: string
}

interface DataActive {
  harvestRealId: number
  active: boolean
}

interface DataDeleted {
  harvestRealId: number
  deleted: boolean
}

interface DataDeleteForever {
  harvestRealId: number
}

interface DataCreate {
  farmId?: number
  farmFieldId?: number
  harvestId: number
  forecastPlant: number
  litersSpentPerBag: number
  bagPerHectare: number
  totalBag: number
  name: string
}

interface DataUpdate {
  harvestRealId: number
  farmId?: number
  farmFieldId?: number
  harvestId: number
  forecastPlant: number
  litersSpentPerBag: number
  bagPerHectare: number
  totalBag: number
  name: string
}

export const activeHarvestReal = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeHarvestRealDashboardGQL,
    { ...data },
  )
}

export const deleteHarvestReal = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteHarvestRealDashboardGQL,
    { ...data },
  )
}

export const deleteForeverHarvestReal = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverHarvestRealDashboardGQL,
    { ...data },
  )
}

export const createHarvestReal = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createHarvestRealDashboardGQL,
    { ...data },
  )
}

export const updateHarvestReal = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateHarvestRealDashboardGQL,
    { ...data },
  )
}
