import { graphClient } from 'services/graph-client'
import {
  activeDefaultTypeMeasurementDashboardGQL,
  deleteDefaultTypeMeasurementDashboardGQL,
  deleteForeverDefaultTypeMeasurementDashboardGQL,
  createDefaultTypeMeasurementDashboardGQL,
  updateDefaultTypeMeasurementDashboardGQL,
} from './queries'
import { DefaultTypeMeasurement } from 'types/defaults/defaults-types-measurements-types'

interface ResponseCreate {
  createDefaultTypeMeasurementDashboard: {
    data: DefaultTypeMeasurement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultTypeMeasurementDashboard: {
    data: DefaultTypeMeasurement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultTypeMeasurementDashboard: {
    data: DefaultTypeMeasurement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultTypeMeasurementDashboard: {
    data: DefaultTypeMeasurement
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultTypeMeasurementDashboard: string
}

interface DataActive {
  defaultTypeMeasurementId: number
  active: boolean
}

interface DataDeleted {
  defaultTypeMeasurementId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultTypeMeasurementId: number
}

interface DataCreate {
  name: string
  description: string
}

interface DataUpdate {
  defaultTypeMeasurementId: number
  name: string
  description: string
}

export const activeDefaultTypeMeasurement = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultTypeMeasurementDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultTypeMeasurement = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultTypeMeasurementDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultTypeMeasurement = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultTypeMeasurementDashboardGQL,
    { ...data },
  )
}

export const createDefaultTypeMeasurement = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultTypeMeasurementDashboardGQL,
    { ...data },
  )
}

export const updateDefaultTypeMeasurement = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultTypeMeasurementDashboardGQL,
    { ...data },
  )
}
