import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import {
  createOrderServiceEmployee,
  updateOrderServiceEmployee,
} from 'services/orders/orders-services-employees/orders-services-employees-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectOrderServiceEmployee,
} from 'slices/orders/orders-services-employees-reducer'
import { z } from 'zod'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetOrdersServicesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-orders-services-dashboard'
import { useGetEmployeesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-employees-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('orders-services-employees-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('orders-services-employees-farm-field-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  orderServiceId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('orders-services-employees-order-service-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  employeeId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('orders-services-employees-employee-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('orders-services-employees-name')),
})

export type FormData = z.infer<typeof schema>

const orderServiceEmployeeModalFormSelector = (state: StateRoot) => {
  return state.ordersServicesEmployeesReducer
}

export const selectorResult = createSelector(
  orderServiceEmployeeModalFormSelector,
  (orderServiceEmployeeReducer) => {
    return orderServiceEmployeeReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, orderServiceEmployeeSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('orders_services_employees')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const farmFieldInfo = useGetFarmsFieldsDashboard()
  const farmInfo = useGetFarmsDashboard()
  const orderServiceInfo = useGetOrdersServicesDashboard()
  const employeeInfo = useGetEmployeesDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectOrderServiceEmployee(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createOrderServiceEmployeeFn, isPending } = useMutation({
    mutationFn: createOrderServiceEmployee,
  })

  const {
    mutateAsync: updateOrderServiceEmployeeFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateOrderServiceEmployee,
  })

  const cleanFields: FormData = {
    farmId: '',
    farmFieldId: '',
    orderServiceId: '',
    employeeId: '',
    name: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!orderServiceEmployeeSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      farmFieldId: formatSelectValueToFields(
        orderServiceEmployeeSelected.farmField,
        ['id', 'name'],
      ),
      farmId: formatSelectValueToFields(orderServiceEmployeeSelected.farm, [
        'id',
        'name',
      ]),
      orderServiceId: formatSelectValueToFields(
        orderServiceEmployeeSelected.orderService,
        ['id', 'name'],
      ),
      employeeId: formatSelectValueToFields(
        orderServiceEmployeeSelected.employee,
        ['id', 'name'],
      ),
      name: orderServiceEmployeeSelected.name,
    }

    reset(orderServiceEmployeeSelected ? fields : cleanFields)
  }, [orderServiceEmployeeSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedFarmField = +formatToSendData(data?.farmFieldId)
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedOrderService = +formatToSendData(data?.orderServiceId)
      const selectedEmployee = +formatToSendData(data?.employeeId)

      const formatedData = {
        farmFieldId: selectedFarmField,
        farmId: selectedFarm,
        orderServiceId: selectedOrderService,
        employeeId: selectedEmployee,
        name: data?.name,
      }

      if (orderServiceEmployeeSelected && orderServiceEmployeeSelected?.id) {
        const { updateOrderServiceEmployeeDashboard } =
          await updateOrderServiceEmployeeFn({
            ...formatedData,
            orderServiceEmployeeId: orderServiceEmployeeSelected?.id,
          })

        dispatch(editUniqueData(updateOrderServiceEmployeeDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createOrderServiceEmployeeFn(formatedData)

      dispatch(
        pushData(responseData?.createOrderServiceEmployeeDashboard?.data),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    orderServiceEmployeeSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    farmFieldInfo,
    farmInfo,
    orderServiceInfo,
    employeeInfo,
  }
}
