import { gql } from 'graphql-request'

export const createDefaultAgriculturalInputModeActionDashboardGQL = gql`
  mutation CREATE_DEFAULT_AGRICULTURAL_INPUT_MODE_ACTION_DASHBOARD(
    $name: String!
    $description: String!
  ) {
    createDefaultAgriculturalInputModeActionDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultAgriculturalInputModeActionDashboardGQL = gql`
  mutation UPDATE_DEFAULT_AGRICULTURAL_INPUT_MODE_ACTION_DASHBOARD(
    $defaultAgriculturalInputModeActionId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultAgriculturalInputModeActionDashboard(
      defaultAgriculturalInputModeActionId: $defaultAgriculturalInputModeActionId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultAgriculturalInputModeActionDashboardGQL = gql`
  mutation DELETE_DEFAULT_AGRICULTURAL_INPUT_MODE_ACTION_DASHBOARD(
    $defaultAgriculturalInputModeActionId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultAgriculturalInputModeActionDashboard(
      defaultAgriculturalInputModeActionId: $defaultAgriculturalInputModeActionId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultAgriculturalInputModeActionDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_AGRICULTURAL_INPUT_MODE_ACTION_DASHBOARD(
    $defaultAgriculturalInputModeActionId: Int!
    $active: Boolean!
  ) {
    activeDefaultAgriculturalInputModeActionDashboard(
      defaultAgriculturalInputModeActionId: $defaultAgriculturalInputModeActionId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultAgriculturalInputModeActionDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_AGRICULTURAL_INPUT_MODE_ACTION_DASHBOARD(
    $defaultAgriculturalInputModeActionId: Int!
  ) {
    deleteForeverDefaultAgriculturalInputModeActionDashboard(
      defaultAgriculturalInputModeActionId: $defaultAgriculturalInputModeActionId
    )
  }
`

export const getDefaultsAgriculturalsInputsModesActionsDashboardGQL = gql`
  query GET_DEFAULTS_AGRICULTURALS_INPUTS_MODES_ACTIONS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsAgriculturalsInputsModesActionsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsAgriculturalsInputsModesActionsContainsDashboardGQL = gql`
  query GET_DEFAULTS_AGRICULTURALS_INPUTS_MODES_ACTIONS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsAgriculturalsInputsModesActionsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
