import { graphClient } from 'services/graph-client'
import {
  activeTypeCoffeeIrrigationDashboardGQL,
  deleteTypeCoffeeIrrigationDashboardGQL,
  deleteForeverTypeCoffeeIrrigationDashboardGQL,
  createTypeCoffeeIrrigationDashboardGQL,
  updateTypeCoffeeIrrigationDashboardGQL,
} from './queries'
import { TypeCoffeeIrrigation } from 'types/types-coffees/types-coffees-irrigations-types'

interface ResponseCreate {
  createTypeCoffeeIrrigationDashboard: {
    data: TypeCoffeeIrrigation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateTypeCoffeeIrrigationDashboard: {
    data: TypeCoffeeIrrigation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeTypeCoffeeIrrigationDashboard: {
    data: TypeCoffeeIrrigation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteTypeCoffeeIrrigationDashboard: {
    data: TypeCoffeeIrrigation
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverTypeCoffeeIrrigationDashboard: string
}

interface DataActive {
  typeCoffeeIrrigationId: number
  active: boolean
}

interface DataDeleted {
  typeCoffeeIrrigationId: number
  deleted: boolean
}

interface DataDeleteForever {
  typeCoffeeIrrigationId: number
}

interface DataCreate {
  name: string
  description?: string
}

interface DataUpdate {
  typeCoffeeIrrigationId: number
  name: string
  description?: string
}

export const activeTypeCoffeeIrrigation = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeTypeCoffeeIrrigationDashboardGQL,
    { ...data },
  )
}

export const deleteTypeCoffeeIrrigation = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteTypeCoffeeIrrigationDashboardGQL,
    { ...data },
  )
}

export const deleteForeverTypeCoffeeIrrigation = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverTypeCoffeeIrrigationDashboardGQL,
    { ...data },
  )
}

export const createTypeCoffeeIrrigation = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createTypeCoffeeIrrigationDashboardGQL,
    { ...data },
  )
}

export const updateTypeCoffeeIrrigation = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateTypeCoffeeIrrigationDashboardGQL,
    { ...data },
  )
}
