import DeleteModal from 'components/common/delete-modal'
import { PaginanteComponent } from 'components/common/pagination-component'
import TableContainer from 'components/common/table-container'
import { TopList } from 'components/common/top-list'
import { withTranslation } from 'react-i18next'
import { Col, Spinner } from 'reactstrap'
import { closeDeleteModal } from 'slices/defaults/defaults-types-coffees-harvests-reducer'
import { useDefaultTypeCoffeeHarvestList } from '../use-defaults-types-coffees-harvests'
import { t } from 'i18next'
import { columns } from './columns'

const List = () => {
  const {
    isLoading,
    dataDefaultTypeCoffeeHarvest,
    deleteModalOpen,
    defaultTypeCoffeeHarvestIdToDelete,
    total,
    isLoadingDelete,
    isRefetching,
    permissions,
    handleOpenImport,
    handleSearch,
    handleOpenFilter,
    refetch,
    dispatch,
    handleOpenForm,
    handleFetchMore,
    handleDeleteForeverDefaultTypeCoffeeHarvest,
  } = useDefaultTypeCoffeeHarvestList()

  return (
    <>
      <DeleteModal
        show={deleteModalOpen}
        recordId={defaultTypeCoffeeHarvestIdToDelete ?? ''}
        isLoading={isLoadingDelete}
        onCloseClick={() => dispatch(closeDeleteModal())}
        onDeleteClick={handleDeleteForeverDefaultTypeCoffeeHarvest}
      />

      <div className="row">
        <Col lg={12}>
          <div className="card" id="tasksList">
            <TopList
              isRefetching={isRefetching}
              permissions={permissions}
              handleOpenForm={handleOpenForm}
              handleOpenFilter={handleOpenFilter}
              handleOpenImport={handleOpenImport}
              refetch={refetch}
              handleSearchTerm={(e) => {
                handleSearch({
                  name: e,
                  description: e,
                })
              }}
            />

            <div className="card-body pt-0">
              {isLoading && (
                <div
                  className="d-flex justify-content-center  align-items-center"
                  style={{ height: 'calc(100vh - 320px)' }}
                >
                  <Spinner />
                </div>
              )}

              {!isLoading && (
                <TableContainer
                  columns={columns}
                  data={dataDefaultTypeCoffeeHarvest}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  customPageSize={10}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-3"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass="table-light text-muted"
                  thClass="table-light text-muted"
                  isTaskListFilter={false}
                  SearchPlaceholder={t('search-placeholder')}
                  noScroll
                />
              )}

              <PaginanteComponent
                total={total}
                className="w-100 overflow-hidden overflow-x-auto mb-4 mt-4 align-items-center"
                handleFetchMore={handleFetchMore}
              />
            </div>
          </div>
        </Col>
      </div>
    </>
  )
}

export default withTranslation()(List)
