import { graphClient } from 'services/graph-client'
import {
  activeFarmFieldDashboardGQL,
  deleteFarmFieldDashboardGQL,
  deleteForeverFarmFieldDashboardGQL,
  createFarmFieldDashboardGQL,
  updateFarmFieldDashboardGQL,
} from './queries'
import { FarmField } from 'types/farms/farms-fields-types'

interface ResponseCreate {
  createFarmFieldDashboard: {
    data: FarmField
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateFarmFieldDashboard: {
    data: FarmField
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeFarmFieldDashboard: {
    data: FarmField
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteFarmFieldDashboard: {
    data: FarmField
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverFarmFieldDashboard: string
}

interface DataActive {
  farmFieldId: number
  active: boolean
}

interface DataDeleted {
  farmFieldId: number
  deleted: boolean
}

interface DataDeleteForever {
  farmFieldId: number
}

interface DataCreate {
  farmId?: number
  areaId?: number
  farmFieldPeriodId: number
  typeCoffeeColorId: number
  typeCoffeeSizeId: number
  typeCoffeeMaturationId: number
  typeCoffeeIrrigationId: number
  typeCoffeeVarietyId: number
  coffeePruningId: number
  typeCoffeePruningId: number
  typeCoffeeManagementId: number
  typeCoffeeHarvestId: number
  typeCoffeeRenovationId: number
  name: string
  yearPlanting: number
  monthPlanting: string
  lineSpacing: number
  plantSpacing: number
  plantFailure: number
  linearQuantity: number
  plantingQuantity: number
  geographicLocation?: string
  hectareQuantity: number
  usefullHectareQuantity: number
}

interface DataUpdate {
  farmFieldId: number
  farmId?: number
  areaId?: number
  farmFieldPeriodId: number
  typeCoffeeColorId: number
  typeCoffeeSizeId: number
  typeCoffeeMaturationId: number
  typeCoffeeIrrigationId: number
  typeCoffeeVarietyId: number
  coffeePruningId: number
  typeCoffeePruningId: number
  typeCoffeeManagementId: number
  typeCoffeeHarvestId: number
  typeCoffeeRenovationId: number
  name: string
  yearPlanting: number
  monthPlanting: string
  lineSpacing: number
  plantSpacing: number
  plantFailure: number
  linearQuantity: number
  plantingQuantity: number
  geographicLocation?: string
  hectareQuantity: number
  usefullHectareQuantity: number
}

export const activeFarmField = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeFarmFieldDashboardGQL,
    { ...data },
  )
}

export const deleteFarmField = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteFarmFieldDashboardGQL,
    { ...data },
  )
}

export const deleteForeverFarmField = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverFarmFieldDashboardGQL,
    { ...data },
  )
}

export const createFarmField = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createFarmFieldDashboardGQL,
    { ...data },
  )
}

export const updateFarmField = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateFarmFieldDashboardGQL,
    { ...data },
  )
}
