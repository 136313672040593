import { graphClient } from 'services/graph-client'
import { Company, TypeTool } from 'types/types-coffees/types-tools-types'

import {
  getCompaniesDashboardGQL,
  getTypesToolsDashboardGQL,
  getTypesToolsContainsDashboardGQL,
} from './queries'

interface Response {
  getTypesToolsDashboard: {
    data: TypeTool[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getTypesToolsContainsDashboard: {
    data: TypeTool[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  name?: string
  description?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getTypesToolsDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getTypesToolsDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getTypesToolsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getTypesToolsDashboardGQL, {
    take,
    skip,
  })
}

export const getTypesToolsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getTypesToolsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}
