import { ModalFormTemplate } from 'components/common/modal/form-template'
import { t } from 'i18next'
import { useUserRoleList } from '../../use-users-roles'
import { useModalFormFilter } from './use-filter-form'
import { FormProvider } from 'react-hook-form'
import { FormFilterControl } from '../../components/form-filter'
import { formatDateDB } from 'helpers/format/format-date'

export const Filter = () => {
  const { filterOpen, handleCloseFilter, handleSetFilter } = useUserRoleList()

  const { control, errors, form, roleInfo, register, resetFields, getValues } =
    useModalFormFilter()

  const handleConfirm = () => {
    const values: Record<string, any> = {}
    const formValues = getValues()

    if (typeof formValues.roleId != 'undefined') {
      if (typeof formValues.roleId === 'object') {
        values['roleId'] = formValues.roleId.value
      }
    }

    if (typeof formValues.name != 'undefined' && formValues.name != '') {
      values['name'] = formValues.name
    }

    if (
      typeof formValues.nameIndex != 'undefined' &&
      formValues.nameIndex != ''
    ) {
      values['nameIndex'] = formValues.nameIndex
    }

    if (typeof formValues.canMenu != 'undefined') {
      if (typeof formValues.canMenu === 'object') {
        if (formValues.canMenu.value === 'true') {
          values['canMenu'] = true
        }
        if (formValues.canMenu.value === 'false') {
          values['canMenu'] = false
        }
      }
    }

    if (typeof formValues.canAdd != 'undefined') {
      if (typeof formValues.canAdd === 'object') {
        if (formValues.canAdd.value === 'true') {
          values['canAdd'] = true
        }
        if (formValues.canAdd.value === 'false') {
          values['canAdd'] = false
        }
      }
    }

    if (typeof formValues.canDuplicate != 'undefined') {
      if (typeof formValues.canDuplicate === 'object') {
        if (formValues.canDuplicate.value === 'true') {
          values['canDuplicate'] = true
        }
        if (formValues.canDuplicate.value === 'false') {
          values['canDuplicate'] = false
        }
      }
    }

    if (typeof formValues.canEdit != 'undefined') {
      if (typeof formValues.canEdit === 'object') {
        if (formValues.canEdit.value === 'true') {
          values['canEdit'] = true
        }
        if (formValues.canEdit.value === 'false') {
          values['canEdit'] = false
        }
      }
    }

    if (typeof formValues.canDeleteForever != 'undefined') {
      if (typeof formValues.canDeleteForever === 'object') {
        if (formValues.canDeleteForever.value === 'true') {
          values['canDeleteForever'] = true
        }
        if (formValues.canDeleteForever.value === 'false') {
          values['canDeleteForever'] = false
        }
      }
    }

    if (typeof formValues.canExport != 'undefined') {
      if (typeof formValues.canExport === 'object') {
        if (formValues.canExport.value === 'true') {
          values['canExport'] = true
        }
        if (formValues.canExport.value === 'false') {
          values['canExport'] = false
        }
      }
    }

    if (typeof formValues.canImport != 'undefined') {
      if (typeof formValues.canImport === 'object') {
        if (formValues.canImport.value === 'true') {
          values['canImport'] = true
        }
        if (formValues.canImport.value === 'false') {
          values['canImport'] = false
        }
      }
    }

    if (typeof formValues.canActive != 'undefined') {
      if (typeof formValues.canActive === 'object') {
        if (formValues.canActive.value === 'true') {
          values['canActive'] = true
        }
        if (formValues.canActive.value === 'false') {
          values['canActive'] = false
        }
      }
    }

    if (typeof formValues.canDeleted != 'undefined') {
      if (typeof formValues.canDeleted === 'object') {
        if (formValues.canDeleted.value === 'true') {
          values['canDeleted'] = true
        }
        if (formValues.canDeleted.value === 'false') {
          values['canDeleted'] = false
        }
      }
    }

    if (typeof formValues.active != 'undefined') {
      if (typeof formValues.active === 'object') {
        if (formValues.active.value === 'true') {
          values['active'] = true
        }
        if (formValues.active.value === 'false') {
          values['active'] = false
        }
      }
    }

    if (typeof formValues.deleted != 'undefined') {
      if (typeof formValues.deleted === 'object') {
        if (formValues.deleted.value === 'true') {
          values['deleted'] = true
        }
        if (formValues.deleted.value === 'false') {
          values['deleted'] = false
        }
      }
    }

    if (
      typeof formValues.createdAtInitial != 'undefined' &&
      formValues.createdAtInitial != '' &&
      typeof formValues.createdAtFinal != 'undefined' &&
      formValues.createdAtFinal != ''
    ) {
      values['createdAtInitial'] = formatDateDB(formValues.createdAtInitial)
      values['createdAtFinal'] = formatDateDB(formValues.createdAtFinal)
    }

    handleSetFilter(values)
    handleCloseFilter()
  }

  const handleClear = () => {
    resetFields()
  }

  return (
    <ModalFormTemplate
      isOpen={filterOpen}
      //full={false}
      //middle
      full={true}
      title={t('filters')}
      cancelLabel={t('cancel-filter')}
      handleConfirm={handleConfirm}
      handleClose={handleCloseFilter}
      handleCancel={handleClear}
    >
      <form
        style={{ paddingLeft: 4 }}
        className="mt-4 pe-1 d-flex flex-wrap gap-3"
      >
        <FormProvider {...form}>
          <FormFilterControl
            control={control}
            errors={errors}
            roleInfo={roleInfo}
            register={register}
          />
        </FormProvider>
      </form>
    </ModalFormTemplate>
  )
}
