import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useAuth } from 'context/use-auth'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { login } from 'services/auth/login'
import { User } from 'types/auth/login-types'

import z from 'zod'

const schema = z.object({
  phone: z.string().min(1, 'Please provide your phone number'),
  password: z.string().min(4, 'Please provide your password'),
})

type FormData = z.infer<typeof schema>

export const useLogin = () => {
  const { handleSignedIn } = useAuth()

  const {
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (data: { phone: string; password: string }) => {
      return login({ ...data, country: 'br' })
    },
  })

  const history = useNavigate()

  const handleSubmit = hookFormSubmit(async (data) => {
    const { loginPhone } = await mutateAsync(data)

    if (loginPhone?.data?.token) {
      const { id, firstName, token, roles } = loginPhone.data

      toast.success('Logged with success!')
      handleSignedIn({ id, firstName, token, roles } as User)

      history('/dashboard')
    } else {
      toast.error(loginPhone?.error)
    }
  })

  return {
    errors,
    isPending,
    handleSubmit,
    register,
  }
}
