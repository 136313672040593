import React from 'react'
import { Col, Container, Row } from 'reactstrap'

//import Components
import UpgradeAccountNotise from './upgrade-account-notise'
import UsersByDevice from './users-by-device'
import Widget from './widget'
import BreadCrumb from '../../../components/common/bread-crumb'
import AudiencesMetrics from './audiences-metrics'
import AudiencesSessions from './audiences-sessions'
import LiveUsers from './live-users'
import TopReferrals from './top-referrals'
import TopPages from './top-pages'

const DashboardAnalytics = () => {
  document.title = 'Analytics | Velzon - React Admin & Dashboard Template'
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Analytics" pageTitle="Dashboards" />
          <Row>
            <Col xxl={5}>
              <UpgradeAccountNotise />
              <Widget />
            </Col>
            <LiveUsers />
          </Row>
          <Row>
            <AudiencesMetrics />
            <AudiencesSessions />
          </Row>
          <Row>
            <UsersByDevice />
            <TopReferrals />
            <TopPages />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardAnalytics
