import { graphClient } from 'services/graph-client'
import { Employee } from 'types/employees/employees-types'
import { getEmployeesDashboardGQL } from 'services/employees/employees/queries'

interface Response {
  getEmployeesDashboard: {
    data: Employee[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getEmployeesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getEmployeesDashboardGQL, {
    take,
    skip,
  })
}
