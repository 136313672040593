import { graphClient } from 'services/graph-client'
import { DefaultTypeCoffeeManagement } from 'types/defaults/defaults-types-coffees-managements-types'

import {
  getDefaultsTypesCoffeesManagementsDashboardGQL,
  getDefaultsTypesCoffeesManagementsContainsDashboardGQL,
} from './queries'

interface Response {
  getDefaultsTypesCoffeesManagementsDashboard: {
    data: DefaultTypeCoffeeManagement[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getDefaultsTypesCoffeesManagementsContainsDashboard: {
    data: DefaultTypeCoffeeManagement[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  name?: string
  description?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getDefaultsTypesCoffeesManagementsDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getDefaultsTypesCoffeesManagementsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getDefaultsTypesCoffeesManagementsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getDefaultsTypesCoffeesManagementsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getDefaultsTypesCoffeesManagementsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getDefaultsTypesCoffeesManagementsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}
