import { graphClient } from 'services/graph-client'
import { AgriculturalInputModeAction } from 'types/agriculturals-inputs/agriculturals-inputs-modes-actions-types'

import { getAgriculturalsInputsModesActionsDashboardGQL } from 'services/agriculturals-inputs/agriculturals-inputs-modes-actions/queries'

interface Response {
  getAgriculturalsInputsModesActionsDashboard: {
    data: AgriculturalInputModeAction[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAgriculturalsInputsModesActionsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getAgriculturalsInputsModesActionsDashboardGQL,
    {
      take,
      skip,
    },
  )
}
