import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatCurrencyToSend } from 'helpers/format/format-currency'
import {
  createHarvestCostService,
  updateHarvestCostService,
} from 'services/harvests/harvests-costs-services/harvests-costs-services-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectHarvestCostService,
} from 'slices/harvests/harvests-costs-services-reducer'
import { z } from 'zod'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetHarvestsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-harvests-dashboard'
import { useGetOrdersServicesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-orders-services-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('harvests-costs-services-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('harvests-costs-services-farm-field-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  harvestId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('harvests-costs-services-harvest-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  orderServiceId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('harvests-costs-services-order-service-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('harvests-costs-services-name')),

  description: z.string(),
  expectedNumberService: z.string(),
  numberService: z.string(),
  serviceValueApportionment: z.string(),
  valueAgriculturalInput: z.string(),
})

export type FormData = z.infer<typeof schema>

const harvestCostServiceModalFormSelector = (state: StateRoot) => {
  return state.harvestsCostsServicesReducer
}

export const selectorResult = createSelector(
  harvestCostServiceModalFormSelector,
  (harvestCostServiceReducer) => {
    return harvestCostServiceReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, harvestCostServiceSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('harvests_costs_services')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const farmFieldInfo = useGetFarmsFieldsDashboard()
  const farmInfo = useGetFarmsDashboard()
  const harvestInfo = useGetHarvestsDashboard()
  const orderServiceInfo = useGetOrdersServicesDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectHarvestCostService(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createHarvestCostServiceFn, isPending } = useMutation({
    mutationFn: createHarvestCostService,
  })

  const {
    mutateAsync: updateHarvestCostServiceFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateHarvestCostService,
  })

  const cleanFields: FormData = {
    farmId: '',
    farmFieldId: '',
    harvestId: '',
    orderServiceId: '',
    name: '',
    description: '',
    expectedNumberService: '',
    numberService: '',
    serviceValueApportionment: '',
    valueAgriculturalInput: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!harvestCostServiceSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      farmFieldId: formatSelectValueToFields(
        harvestCostServiceSelected.farmField,
        ['id', 'name'],
      ),
      farmId: formatSelectValueToFields(harvestCostServiceSelected.farm, [
        'id',
        'name',
      ]),
      harvestId: formatSelectValueToFields(harvestCostServiceSelected.harvest, [
        'id',
        'name',
      ]),
      orderServiceId: formatSelectValueToFields(
        harvestCostServiceSelected.orderService,
        ['id', 'name'],
      ),
      name: harvestCostServiceSelected.name,
      description: harvestCostServiceSelected.description,
      expectedNumberService: `${harvestCostServiceSelected.expectedNumberService}`,
      numberService: `${harvestCostServiceSelected.numberService}`,
      serviceValueApportionment: `${harvestCostServiceSelected.serviceValueApportionment}`,
      valueAgriculturalInput: `${harvestCostServiceSelected.valueAgriculturalInput}`,
    }

    reset(harvestCostServiceSelected ? fields : cleanFields)
  }, [harvestCostServiceSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedFarmField = +formatToSendData(data?.farmFieldId)
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedHarvest = +formatToSendData(data?.harvestId)
      const selectedOrderService = +formatToSendData(data?.orderServiceId)

      const formatedData = {
        farmFieldId: selectedFarmField,
        farmId: selectedFarm,
        harvestId: selectedHarvest,
        orderServiceId: selectedOrderService,
        name: data?.name,
        description: data?.description,
        expectedNumberService: +data?.expectedNumberService,
        numberService: +data?.numberService,
        serviceValueApportionment: formatCurrencyToSend(
          data?.serviceValueApportionment,
        ),
        valueAgriculturalInput: formatCurrencyToSend(
          data?.valueAgriculturalInput,
        ),
      }

      if (harvestCostServiceSelected && harvestCostServiceSelected?.id) {
        const { updateHarvestCostServiceDashboard } =
          await updateHarvestCostServiceFn({
            ...formatedData,
            harvestCostServiceId: harvestCostServiceSelected?.id,
          })

        dispatch(editUniqueData(updateHarvestCostServiceDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createHarvestCostServiceFn(formatedData)

      dispatch(pushData(responseData?.createHarvestCostServiceDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    harvestCostServiceSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    farmFieldInfo,
    farmInfo,
    harvestInfo,
    orderServiceInfo,
  }
}
