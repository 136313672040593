import { createSelector } from 'reselect'
import { FarmUser } from 'types/farms/farms-users-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverFarmUser } from 'services/farms/farms-users/farms-users-cruds'
import {
  getFarmsUsersDashboard,
  GetParams,
  getFarmsUsersContainsDashboard,
} from 'services/farms/farms-users/farms-users-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/farms/farms-users-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const farmUserListSelector = (state: StateRoot) => state.farmsUsersReducer

const selectorResult = createSelector(
  farmUserListSelector,
  (farmUserListReducer) => {
    return farmUserListReducer
  },
)

export const useFarmUserList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('farms_users')

  const {
    data: dataFarmUser,
    deleteModalOpen,
    farmUserIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'farmUser', 'list', { params }],
    queryFn: () => getFarmsUsersDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverFarmUser,
  })

  const handleStoreData = useCallback(
    (farmsUsers: FarmUser[], totalPages: number | null) => {
      if (!farmsUsers) return

      dispatch(setData(farmsUsers))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverFarmUser = useCallback(async () => {
    if (!farmUserIdToDelete) return

    const response = await mutateAsync({
      farmUserId: +farmUserIdToDelete,
    })

    if (response?.deleteForeverFarmUserDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: farmUserIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [farmUserIdToDelete])

  useEffect(() => {
    const farmsUsersResult = data?.getFarmsUsersDashboard

    if (farmsUsersResult) {
      handleStoreData(farmsUsersResult?.data, farmsUsersResult?.totalPages)
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getFarmsUsersContainsDashboard(value)
      const farmsUsersResult = response?.getFarmsUsersContainsDashboard

      handleStoreData(farmsUsersResult?.data, farmsUsersResult?.totalPages)
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    farmUserIdToDelete,
    farmUser: data?.getFarmsUsersDashboard?.data,
    isLoading,
    isFetching,
    dataFarmUser,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverFarmUser,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
