import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledInputDate } from 'components/controlled-components/controlled-input-date'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormDataFilter } from '../modal/filter/use-filter-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { ControlledInputPhone } from 'components/controlled-components/controlled-input-phone'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetExamplesOthersOnesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-examples-others-ones-dashboard'
import { useGetExamplesOthersTwosDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-examples-others-twos-dashboard'

interface Props {
  control: Control<FormDataFilter>
  errors: FieldErrors<FormDataFilter>
  exampleOtherOneInfo: ReturnType<typeof useGetExamplesOthersOnesDashboard>
  exampleOtherTwoInfo: ReturnType<typeof useGetExamplesOthersTwosDashboard>
  register: UseFormRegister<FormDataFilter>
  handleSelectCountry: (country: string) => void
}

export const FormFilterControl = ({
  control,
  errors,
  exampleOtherOneInfo,
  exampleOtherTwoInfo,
  register,
  handleSelectCountry,
}: Props) => {
  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={exampleOtherOneInfo?.examplesOthersOnesDashboard}
            info={exampleOtherOneInfo}
            label={t('examples-tables-example-other-one-id')}
            name="exampleOtherOneId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={exampleOtherTwoInfo?.examplesOthersTwosDashboard}
            info={exampleOtherTwoInfo}
            label={t('examples-tables-example-other-two-id')}
            name="exampleOtherTwoId"
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="typeBoolean"
            label={t('examples-tables-type-boolean')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="typeEnum"
            label={t('examples-tables-type-enum')}
            data={[
              { id: 'exampleOne', name: 'exampleOne' },
              { id: 'exampleTwo', name: 'exampleTwo' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="active"
            label={t('examples-tables-active')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>

        <Col sm={4}>
          <ControlledSelect
            control={control}
            name="deleted"
            label={t('examples-tables-deleted')}
            data={[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('examples-tables-name')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.typeText?.message}
            {...register('typeText')}
            label={t('examples-tables-type-text')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputNumber
            control={control}
            name="typeInt"
            label={t('examples-tables-type-int')}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="typeDatetime"
            label={t('examples-tables-type-datetime')}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            type="email"
            error={errors?.email?.message}
            {...register('email')}
            label={t('examples-tables-email')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputPhone
            control={control}
            name="phone.phone"
            label={t('examples-tables-phone')}
            handleSelectCountry={handleSelectCountry}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtInitial"
            label={t('examples-tables-created-at-initial')}
          />
        </Col>

        <Col sm={4}>
          <ControlledInputDate
            control={control}
            name="createdAtFinal"
            label={t('examples-tables-created-at-final')}
          />
        </Col>
      </Row>
    </Container>
  )
}
