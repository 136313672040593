import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'

interface Props {
  isRefetching: boolean
  permissions?: {
    canAdd: boolean
    canImport: boolean
    canExport: boolean
  }
  refetch(): void
  handleOpenForm(): void
  handleSearchTerm?(value: string): void
  handleOpenFilter(): void
  handleOpenImport?(): void
  handleOpenExport?(): void
}

// nao precisa de outro componente pro topList

export const TopList = ({
  isRefetching,
  permissions,
  refetch,
  handleOpenForm,
  handleSearchTerm,
  handleOpenFilter,
  handleOpenImport,
  handleOpenExport,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div className="row">
      <Col lg={12}>
        <div className="card" id="tasksList">
          <div className="card-header border-0 ">
            <div className="d-flex flex-wrap  bd-highlight gap-2 gap-lg-0">
              <div className="me-lg-2 flex-fill bd-highlight">
                {handleSearchTerm && (
                  <div className="search-box d-flex d-inline-block">
                    <input
                      id="search-bar-0"
                      type="text"
                      className="form-control w-100"
                      placeholder={t('search')}
                      onChange={(e) => {
                        setTimeout(() => {
                          handleSearchTerm?.(e.target.value)
                        }, 900)
                      }}
                    />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                )}
              </div>
              <div className="bd-highlight d-flex justify-content-center justify-content-lg-end col-12 col-lg-auto ">
                <button
                  className="btn btn-info add-btn me-1"
                  onClick={refetch}
                  title="Click to refetch"
                >
                  <div className={isRefetching ? 'spinner-loading-form' : ''}>
                    <i className="ri-restart-line align-bottom"></i>
                  </div>
                </button>

                <button
                  className="btn btn-primary add-btn me-1"
                  onClick={handleOpenFilter}
                >
                  <i className="ri-equalizer-fill align-bottom"></i>
                </button>

                {permissions?.canExport && (
                  <button
                    onClick={handleOpenExport}
                    className="btn btn-success add-btn  me-1"
                  >
                    <i className="ri-chat-download-line align-bottom "></i>
                  </button>
                )}

                {permissions?.canImport && (
                  <button
                    onClick={handleOpenImport}
                    className="btn btn-success add-btn  me-1"
                  >
                    <i className="ri-chat-upload-line align-bottom "></i>
                  </button>
                )}

                {permissions?.canAdd && (
                  <button
                    className="btn btn-primary add-btn me-1 "
                    onClick={handleOpenForm}
                  >
                    <i className="ri-add-line align-bottom "></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Col>
    </div>
  )
}
