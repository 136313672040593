import { gql } from 'graphql-request'

export const createDefaultTypeCoffeeTreatmentDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_COFFEE_TREATMENT_DASHBOARD(
    $name: String!
    $description: String
    $typeToolId: Int!
  ) {
    createDefaultTypeCoffeeTreatmentDashboard(
      name: $name
      description: $description
      typeToolId: $typeToolId
    ) {
      error
      status
      data {
        id
        name
        description
        typeToolId
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypeCoffeeTreatmentDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_COFFEE_TREATMENT_DASHBOARD(
    $defaultTypeCoffeeTreatmentId: Int!
    $name: String
    $description: String
    $typeToolId: Int
  ) {
    updateDefaultTypeCoffeeTreatmentDashboard(
      defaultTypeCoffeeTreatmentId: $defaultTypeCoffeeTreatmentId
      name: $name
      description: $description
      typeToolId: $typeToolId
    ) {
      error
      status
      data {
        id
        name
        description
        typeToolId
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypeCoffeeTreatmentDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_COFFEE_TREATMENT_DASHBOARD(
    $defaultTypeCoffeeTreatmentId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypeCoffeeTreatmentDashboard(
      defaultTypeCoffeeTreatmentId: $defaultTypeCoffeeTreatmentId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        typeToolId
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypeCoffeeTreatmentDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_COFFEE_TREATMENT_DASHBOARD(
    $defaultTypeCoffeeTreatmentId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypeCoffeeTreatmentDashboard(
      defaultTypeCoffeeTreatmentId: $defaultTypeCoffeeTreatmentId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        typeToolId
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypeCoffeeTreatmentDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_COFFEE_TREATMENT_DASHBOARD(
    $defaultTypeCoffeeTreatmentId: Int!
  ) {
    deleteForeverDefaultTypeCoffeeTreatmentDashboard(
      defaultTypeCoffeeTreatmentId: $defaultTypeCoffeeTreatmentId
    )
  }
`

export const getDefaultsTypesCoffeesTreatmentsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_TREATMENTS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $typeToolId: Int
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesCoffeesTreatmentsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      typeToolId: $typeToolId
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        typeToolId
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesCoffeesTreatmentsContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_COFFEES_TREATMENTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsTypesCoffeesTreatmentsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        typeToolId
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
