import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'
import { formatSelectPhoneToFields } from 'helpers/form/format-select-phone-to-fields'
import { formatInputDateValue } from 'helpers/form/format-input-date-value'
import { formatDateToFields } from 'helpers/form/format-date-to-fields'
import { formatCurrencyToSend } from 'helpers/format/format-currency'
import { formatInputBooleanValue } from 'helpers/form/format-input-boolean-value'
import {
  createExampleTable,
  updateExampleTable,
} from 'services/examples-tables/examples-tables/examples-tables-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectExampleTable,
} from 'slices/examples-tables/examples-tables-reducer'
import { z } from 'zod'
import { TypeEnum } from 'types/examples-tables/examples-tables-types'
import { useGetExamplesOthersOnesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-examples-others-ones-dashboard'
import { useGetExamplesOthersTwosDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-examples-others-twos-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z.string().min(1, t('inform-field') + ' ' + t('examples-tables-name')),

  typeText: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-tables-type-text')),

  typeBoolean: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('examples-tables-type-boolean')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeInt: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-tables-type-int')),

  typeDatetime: z.union([z.date(), z.string().min(1)]),

  typeEnum: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('examples-tables-type-enum')),
    z.object({ label: z.string(), value: z.string() }),
  ]),

  exampleOtherOneId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('examples-tables-example-other-one-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  exampleOtherTwoId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('examples-tables-example-other-two-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeDecimal: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-tables-type-decimal')),

  typeCurrency: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-tables-type-currency')),

  email: z
    .string()
    .min(1, t('inform-field') + ' ' + t('examples-tables-email')),

  phone: z
    .object({
      phone: z
        .string()
        .min(1, t('invalid-phone-field') + ' ' + t('examples-tables-phone')),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('examples-tables-phone'),
      path: ['phone'],
    }),
})

export type FormData = z.infer<typeof schema>

const exampleTableModalFormSelector = (state: StateRoot) => {
  return state.examplesTablesReducer
}

export const selectorResult = createSelector(
  exampleTableModalFormSelector,
  (exampleTableReducer) => {
    return exampleTableReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, exampleTableSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('examples_tables')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
    setValue,
  } = form

  const exampleOtherOneInfo = useGetExamplesOthersOnesDashboard()
  const exampleOtherTwoInfo = useGetExamplesOthersTwosDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectExampleTable(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createExampleTableFn, isPending } = useMutation({
    mutationFn: createExampleTable,
  })

  const { mutateAsync: updateExampleTableFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateExampleTable,
    })

  const cleanFields: FormData = {
    name: '',
    typeText: '',
    typeBoolean: '',
    typeInt: '',
    typeDatetime: '',
    typeEnum: '',
    exampleOtherOneId: '',
    exampleOtherTwoId: '',
    typeDecimal: '',
    typeCurrency: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!exampleTableSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: exampleTableSelected.name,
      typeText: exampleTableSelected.typeText,
      typeBoolean: `${exampleTableSelected.typeBoolean}`,
      typeInt: `${exampleTableSelected.typeInt}`,
      typeDatetime: formatDateToFields(exampleTableSelected.typeDatetime),
      typeEnum: formatSelectValueToFields(exampleTableSelected, [
        'typeEnum',
        'typeEnum',
      ]),
      exampleOtherOneId: formatSelectValueToFields(
        exampleTableSelected.exampleOtherOne,
        ['id', 'name'],
      ),
      exampleOtherTwoId: formatSelectValueToFields(
        exampleTableSelected.exampleOtherTwo,
        ['id', 'name'],
      ),
      typeDecimal: `${exampleTableSelected.typeDecimal}`,
      typeCurrency: `${exampleTableSelected.typeCurrency}`,
      email: exampleTableSelected.email,
      phone: formatSelectPhoneToFields(exampleTableSelected.phone),
    }

    reset(exampleTableSelected ? fields : cleanFields)
  }, [exampleTableSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedTypeBoolean = formatToSendData(data?.typeBoolean)
      const selectedTypeEnum = formatToSendData(data?.typeEnum) as TypeEnum
      const selectedExampleOtherOne = +formatToSendData(data?.exampleOtherOneId)
      const selectedExampleOtherTwo = +formatToSendData(data?.exampleOtherTwoId)

      const formatedData = {
        name: data?.name,
        typeText: data?.typeText,
        typeBoolean: formatInputBooleanValue(selectedTypeBoolean),
        typeInt: +data?.typeInt,
        typeDatetime: formatInputDateValue(data?.typeDatetime),
        typeEnum: selectedTypeEnum,
        exampleOtherOneId: selectedExampleOtherOne,
        exampleOtherTwoId: selectedExampleOtherTwo,
        typeDecimal: formatCurrencyToSend(data?.typeDecimal),
        typeCurrency: formatCurrencyToSend(data?.typeCurrency),
        email: data?.email,
        phone: data?.phone.phone,
      }

      if (exampleTableSelected && exampleTableSelected?.id) {
        const { updateExampleTableDashboard } = await updateExampleTableFn({
          ...formatedData,
          exampleTableId: exampleTableSelected?.id,
        })

        dispatch(editUniqueData(updateExampleTableDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createExampleTableFn(formatedData)

      dispatch(pushData(responseData?.createExampleTableDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    exampleTableSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    handleSelectCountry,
    resetFields,
    exampleOtherOneInfo,
    exampleOtherTwoInfo,
  }
}
