import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'
import { useGetUsersTypesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-users-types-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'

const schemaFilter = z.object({
  userTypeId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-user-type-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmId: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  firstName: z.string().min(1, t('inform-field') + ' ' + t('users-first-name')),

  lastName: z.string(),
  name: z.string(),

  email: z.string().min(1, t('inform-field') + ' ' + t('users-email')),

  phone: z
    .object({
      phone: z.string(),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('users-phone'),
      path: ['phone'],
    }),
  password: z.string().min(1, t('inform-field') + ' ' + t('users-password')),

  photo: z.string().min(1, t('inform-field') + ' ' + t('users-photo')),

  emailVerified: z
    .string()
    .min(1, t('inform-field') + ' ' + t('users-email-verified')),

  phoneVerified: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-phone-verified')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  twoFactorEnabled: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-two-factor-enabled')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  smsCode: z.string(),
  emailCode: z.string().min(1, t('inform-field') + ' ' + t('users-email-code')),

  googleId: z.string(),
  appleId: z.string(),
  appleToken: z.string(),
  country: z.string().min(1, t('inform-field') + ' ' + t('users-country')),

  state: z.string().min(1, t('inform-field') + ' ' + t('users-state')),

  utc: z.string(),

  language: z.string().min(1, t('inform-field') + ' ' + t('users-language')),

  gender: z.string().min(1, t('inform-field') + ' ' + t('users-gender')),

  birthday: z.union([z.date(), z.string().min(1)]),

  active: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('users-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    userTypeId: '',
    farmId: '',
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
    password: '',
    photo: '',
    emailVerified: '',
    phoneVerified: '',
    twoFactorEnabled: '',
    smsCode: '',
    emailCode: '',
    googleId: '',
    appleId: '',
    appleToken: '',
    country: '',
    state: '',
    utc: '',
    language: '',
    gender: '',
    birthday: '',
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = form

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  const userTypeInfo = useGetUsersTypesDashboard()
  const farmInfo = useGetFarmsDashboard()

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    userTypeInfo,
    farmInfo,
    register,
    handleSelectCountry,
    resetFields,
    getValues,
  }
}
