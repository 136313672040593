import { useInfiniteQuery } from '@tanstack/react-query'
import { getTypesCoffeesRenovationsDashboardHook } from 'services/_hooks/types-coffees-renovations-gets'

export const useGetTypesCoffeesRenovationsDashboard = (take = 4) => {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['get', 'typesCoffeesRenovationsDashboard', 'list', 'total'],
      initialPageParam: 0,
      queryFn: ({ pageParam }) => {
        return getTypesCoffeesRenovationsDashboardHook(pageParam, take)
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const total =
          lastPage?.getTypesCoffeesRenovationsDashboard?.totalPages ?? 4
        const isLastPage = allPages?.length >= total

        if (isLastPage) {
          return null
        }

        return lastPageParam + 1
      },
    })

  const typesCoffeesRenovationsDashboard = data?.pages?.flatMap((page) => {
    return page?.getTypesCoffeesRenovationsDashboard?.data
  })

  return {
    typesCoffeesRenovationsDashboard: typesCoffeesRenovationsDashboard ?? [],
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  }
}
