import { graphClient } from 'services/graph-client'
import { TypeCoffeePruning } from 'types/types-coffees/types-coffees-prunings-types'

import { getTypesCoffeesPruningsDashboardGQL } from 'services/types-coffees/types-coffees-prunings/queries'

interface Response {
  getTypesCoffeesPruningsDashboard: {
    data: TypeCoffeePruning[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getTypesCoffeesPruningsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getTypesCoffeesPruningsDashboardGQL,
    {
      take,
      skip,
    },
  )
}
