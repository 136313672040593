import { createSelector } from 'reselect'
import { EmployeeFunction } from 'types/employees/employees-functions-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverEmployeeFunction } from 'services/employees/employees-functions/employees-functions-cruds'
import {
  getEmployeesFunctionsDashboard,
  GetParams,
  getEmployeesFunctionsContainsDashboard,
} from 'services/employees/employees-functions/employees-functions-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/employees/employees-functions-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const employeeFunctionListSelector = (state: StateRoot) =>
  state.employeesFunctionsReducer

const selectorResult = createSelector(
  employeeFunctionListSelector,
  (employeeFunctionListReducer) => {
    return employeeFunctionListReducer
  },
)

export const useEmployeeFunctionList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('employees_functions')

  const {
    data: dataEmployeeFunction,
    deleteModalOpen,
    employeeFunctionIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'employeeFunction', 'list', { params }],
    queryFn: () => getEmployeesFunctionsDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverEmployeeFunction,
  })

  const handleStoreData = useCallback(
    (employeesFunctions: EmployeeFunction[], totalPages: number | null) => {
      if (!employeesFunctions) return

      dispatch(setData(employeesFunctions))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverEmployeeFunction = useCallback(async () => {
    if (!employeeFunctionIdToDelete) return

    const response = await mutateAsync({
      employeeFunctionId: +employeeFunctionIdToDelete,
    })

    if (
      response?.deleteForeverEmployeeFunctionDashboard === 'deleted_forever'
    ) {
      dispatch(deleteUniqueData({ id: employeeFunctionIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [employeeFunctionIdToDelete])

  useEffect(() => {
    const employeesFunctionsResult = data?.getEmployeesFunctionsDashboard

    if (employeesFunctionsResult) {
      handleStoreData(
        employeesFunctionsResult?.data,
        employeesFunctionsResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getEmployeesFunctionsContainsDashboard(value)
      const employeesFunctionsResult =
        response?.getEmployeesFunctionsContainsDashboard

      handleStoreData(
        employeesFunctionsResult?.data,
        employeesFunctionsResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    employeeFunctionIdToDelete,
    employeeFunction: data?.getEmployeesFunctionsDashboard?.data,
    isLoading,
    isFetching,
    dataEmployeeFunction,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverEmployeeFunction,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
