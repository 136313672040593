import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { InputCurrencyComponent } from 'components/common/inputs/input-currency'
import { InputDecimalComponent } from 'components/common/inputs/input-decimal'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  farmInfo: ReturnType<typeof useGetFarmsDashboard>
  register: UseFormRegister<FormData>
}

export const FormControl = ({ control, errors, farmInfo, register }: Props) => {
  const permissions = useGetPermissions('employees_functions')
  const { employeeFunctionSelected } = useSelector(selectorResult)
  const disabled = !!(employeeFunctionSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <ControlledSelect
            control={control}
            data={farmInfo?.farmsDashboard}
            info={farmInfo}
            label={t('employees-functions-farm-id')}
            name="farmId"
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('employees-functions-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('employees-functions-description')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputCurrencyComponent
            locale="en-US" //pt-BR es-es etc..
            currency="USD" //BRL EUR etc..
            label={t('employees-functions-base-salary-currency')}
            {...register('baseSalaryCurrency')}
            error={errors?.baseSalaryCurrency?.message}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
