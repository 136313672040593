import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import { formatInputDateValue } from 'helpers/form/format-input-date-value'
import { formatDateToFields } from 'helpers/form/format-date-to-fields'
import {
  createHarvestProduction,
  updateHarvestProduction,
} from 'services/harvests/harvests-productions/harvests-productions-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectHarvestProduction,
} from 'slices/harvests/harvests-productions-reducer'
import { z } from 'zod'
import { useGetFarmsFieldsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-fields-dashboard'
import { useGetFarmsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-farms-dashboard'
import { useGetEmployeesDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-employees-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  farmId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('harvests-productions-farm-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  farmFieldId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') + ' ' + t('harvests-productions-farm-field-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  employeeId: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('harvests-productions-employee-id')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(1, t('inform-field') + ' ' + t('harvests-productions-name')),

  dateHarvest: z.union([z.date(), z.string().min(1)]),

  measurements: z
    .string()
    .min(1, t('inform-field') + ' ' + t('harvests-productions-measurements')),

  liters: z
    .string()
    .min(1, t('inform-field') + ' ' + t('harvests-productions-liters')),
})

export type FormData = z.infer<typeof schema>

const harvestProductionModalFormSelector = (state: StateRoot) => {
  return state.harvestsProductionsReducer
}

export const selectorResult = createSelector(
  harvestProductionModalFormSelector,
  (harvestProductionReducer) => {
    return harvestProductionReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, harvestProductionSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('harvests_productions')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const farmFieldInfo = useGetFarmsFieldsDashboard()
  const farmInfo = useGetFarmsDashboard()
  const employeeInfo = useGetEmployeesDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectHarvestProduction(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createHarvestProductionFn, isPending } = useMutation({
    mutationFn: createHarvestProduction,
  })

  const { mutateAsync: updateHarvestProductionFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateHarvestProduction,
    })

  const cleanFields: FormData = {
    farmId: '',
    farmFieldId: '',
    employeeId: '',
    name: '',
    dateHarvest: '',
    measurements: '',
    liters: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!harvestProductionSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      farmFieldId: formatSelectValueToFields(
        harvestProductionSelected.farmField,
        ['id', 'name'],
      ),
      farmId: formatSelectValueToFields(harvestProductionSelected.farm, [
        'id',
        'name',
      ]),
      employeeId: formatSelectValueToFields(
        harvestProductionSelected.employee,
        ['id', 'name'],
      ),
      name: harvestProductionSelected.name,
      dateHarvest: formatDateToFields(harvestProductionSelected.dateHarvest),
      measurements: `${harvestProductionSelected.measurements}`,
      liters: `${harvestProductionSelected.liters}`,
    }

    reset(harvestProductionSelected ? fields : cleanFields)
  }, [harvestProductionSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedFarmField = +formatToSendData(data?.farmFieldId)
      const selectedFarm = +formatToSendData(data?.farmId)
      const selectedEmployee = +formatToSendData(data?.employeeId)

      const formatedData = {
        farmFieldId: selectedFarmField,
        farmId: selectedFarm,
        employeeId: selectedEmployee,
        name: data?.name,
        dateHarvest: formatInputDateValue(data?.dateHarvest),
        measurements: +data?.measurements,
        liters: +data?.liters,
      }

      if (harvestProductionSelected && harvestProductionSelected?.id) {
        const { updateHarvestProductionDashboard } =
          await updateHarvestProductionFn({
            ...formatedData,
            harvestProductionId: harvestProductionSelected?.id,
          })

        dispatch(editUniqueData(updateHarvestProductionDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createHarvestProductionFn(formatedData)

      dispatch(pushData(responseData?.createHarvestProductionDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    harvestProductionSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    farmFieldInfo,
    farmInfo,
    employeeInfo,
  }
}
