import { graphClient } from 'services/graph-client'
import {
  activeDefaultAgriculturalInputDashboardGQL,
  deleteDefaultAgriculturalInputDashboardGQL,
  deleteForeverDefaultAgriculturalInputDashboardGQL,
  createDefaultAgriculturalInputDashboardGQL,
  updateDefaultAgriculturalInputDashboardGQL,
} from './queries'
import { DefaultAgriculturalInput } from 'types/defaults/defaults-agriculturals-inputs-types'

interface ResponseCreate {
  createDefaultAgriculturalInputDashboard: {
    data: DefaultAgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateDefaultAgriculturalInputDashboard: {
    data: DefaultAgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeDefaultAgriculturalInputDashboard: {
    data: DefaultAgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteDefaultAgriculturalInputDashboard: {
    data: DefaultAgriculturalInput
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverDefaultAgriculturalInputDashboard: string
}

interface DataActive {
  defaultAgriculturalInputId: number
  active: boolean
}

interface DataDeleted {
  defaultAgriculturalInputId: number
  deleted: boolean
}

interface DataDeleteForever {
  defaultAgriculturalInputId: number
}

interface DataCreate {
  name: string
  description: string
  agriculturalInputSubTypeId: number
  agriculturalInputModeActionId?: number
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  isPesticide: boolean
  typePestId?: number
  toxicologicalClass?: string
  environmentalClass?: string
  isOrganic: boolean
  isFertilizer: boolean
}

interface DataUpdate {
  defaultAgriculturalInputId: number
  name: string
  description: string
  agriculturalInputSubTypeId: number
  agriculturalInputModeActionId?: number
  tradeMark?: string
  formulation?: string
  activeIngredient?: string
  isPesticide: boolean
  typePestId?: number
  toxicologicalClass?: string
  environmentalClass?: string
  isOrganic: boolean
  isFertilizer: boolean
}

export const activeDefaultAgriculturalInput = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeDefaultAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const deleteDefaultAgriculturalInput = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteDefaultAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const deleteForeverDefaultAgriculturalInput = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverDefaultAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const createDefaultAgriculturalInput = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createDefaultAgriculturalInputDashboardGQL,
    { ...data },
  )
}

export const updateDefaultAgriculturalInput = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateDefaultAgriculturalInputDashboardGQL,
    { ...data },
  )
}
