import { gql } from 'graphql-request'

export const updateUserDashboardGQL = gql`
  mutation UPDATE_USER_DASHBOARD(
    $userId: Int!
    $userTypeId: Int
    $farmId: Int
    $firstName: String
    $lastName: String
    $name: String
    $email: String
    $phone: String
    $password: String
    $photo: String
    $emailVerified: Boolean
    $phoneVerified: Boolean
    $twoFactorEnabled: Boolean
    $smsCode: String
    $emailCode: String
    $googleId: String
    $appleId: String
    $appleToken: String
    $country: String
    $state: String
    $utc: String
    $language: String
    $gender: String
    $birthday: String
  ) {
    updateUserDashboard(
      userId: $userId
      userTypeId: $userTypeId
      farmId: $farmId
      firstName: $firstName
      lastName: $lastName
      name: $name
      email: $email
      phone: $phone
      password: $password
      photo: $photo
      emailVerified: $emailVerified
      phoneVerified: $phoneVerified
      twoFactorEnabled: $twoFactorEnabled
      smsCode: $smsCode
      emailCode: $emailCode
      googleId: $googleId
      appleId: $appleId
      appleToken: $appleToken
      country: $country
      state: $state
      utc: $utc
      language: $language
      gender: $gender
      birthday: $birthday
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        userTypeId
        userType {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteUserDashboardGQL = gql`
  mutation DELETE_USER_DASHBOARD($userId: Int!, $deleted: Boolean!) {
    deleteUserDashboard(userId: $userId, deleted: $deleted) {
      error
      status
      data {
        id
        userTypeId
        companyId
        farmId
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeUserDashboardGQL = gql`
  mutation ACTIVE_USER_DASHBOARD($userId: Int!, $active: Boolean!) {
    activeUserDashboard(userId: $userId, active: $active) {
      error
      status
      data {
        id
        userTypeId
        companyId
        farmId
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverUserDashboardGQL = gql`
  mutation DELETE_FOREVER_USER_DASHBOARD($userId: Int!) {
    deleteForeverUserDashboard(userId: $userId)
  }
`

export const getUsersDashboardGQL = gql`
  query GET_USERS_DASHBOARD(
    $take: Int
    $skip: Int
    $userTypeId: Int
    $farmId: Int
    $firstName: String
    $lastName: String
    $name: String
    $email: String
    $phone: String
    $country: String
    $state: String
    $utc: String
    $language: String
    $gender: String
    $birthday: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getUsersDashboard(
      take: $take
      skip: $skip
      userTypeId: $userTypeId
      farmId: $farmId
      firstName: $firstName
      lastName: $lastName
      name: $name
      email: $email
      phone: $phone
      country: $country
      state: $state
      utc: $utc
      language: $language
      gender: $gender
      birthday: $birthday
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        userTypeId
        userType {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUserTypeDashboardIdGQL = gql`
  query GET_USER_TYPE_DASHBOARD_ID($userTypeId: Int!) {
    getUserTypeDashboard(userTypeId: $userTypeId) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUsersTypesDashboardGQL = gql`
  query GET_USERS_TYPES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getUsersTypesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getUsersContainsDashboardGQL = gql`
  query GET_USERS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $firstName: String
    $lastName: String
    $name: String
    $email: String
    $phone: String
    $password: String
    $photo: String
    $smsCode: String
    $emailCode: String
    $googleId: String
    $appleId: String
    $appleToken: String
    $country: String
    $state: String
    $utc: String
    $language: String
    $gender: String
  ) {
    getUsersContainsDashboard(
      take: $take
      skip: $skip
      firstName: $firstName
      lastName: $lastName
      name: $name
      email: $email
      phone: $phone
      password: $password
      photo: $photo
      smsCode: $smsCode
      emailCode: $emailCode
      googleId: $googleId
      appleId: $appleId
      appleToken: $appleToken
      country: $country
      state: $state
      utc: $utc
      language: $language
      gender: $gender
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        userTypeId
        userType {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        firstName
        lastName
        name
        email
        phone
        password
        photo
        emailVerified
        phoneVerified
        twoFactorEnabled
        smsCode
        emailCode
        googleId
        appleId
        appleToken
        country
        state
        utc
        language
        gender
        birthday
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
