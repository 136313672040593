import { Status } from './list-columns'
import { t } from 'i18next'

export const columns = [
  {
    Header: t('id'),
    accessor: 'id',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span className="fw-medium link-primary">{cellProps?.value}</span>
    },
  },
  {
    Header: t('defaults-agriculturals-inputs-pests-agricultural-input-id'),
    accessor: 'agriculturalInputId',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('defaults-agriculturals-inputs-pests-type-pest-id'),
    accessor: 'typePestId',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('defaults-agriculturals-inputs-pests-name'),
    accessor: 'name',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('defaults-agriculturals-inputs-pests-description'),
    accessor: 'description',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t(
      'defaults-agriculturals-inputs-pests-agricultural-input-sub-type-id',
    ),
    accessor: 'agriculturalInputSubTypeId',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t(
      'defaults-agriculturals-inputs-pests-agricultural-input-mode-action-id',
    ),
    accessor: 'agriculturalInputModeActionId',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('defaults-agriculturals-inputs-pests-trade-mark'),
    accessor: 'tradeMark',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('defaults-agriculturals-inputs-pests-formulation'),
    accessor: 'formulation',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('defaults-agriculturals-inputs-pests-active-ingredient'),
    accessor: 'activeIngredient',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('defaults-agriculturals-inputs-pests-toxicological-class'),
    accessor: 'toxicologicalClass',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('defaults-agriculturals-inputs-pests-environmental-class'),
    accessor: 'environmentalClass',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{cellProps?.value}</span>
    },
  },
  {
    Header: t('defaults-agriculturals-inputs-pests-is-organic'),
    accessor: 'isOrganic',
    filterable: false,
    Cell: (cellProps: any) => {
      return <span>{`${cellProps?.value}`}</span>
    },
  },
  {
    Header: t('active'),
    accessor: 'actions',
    filterable: false,
    Cell: (props: any, cellProps: any) => {
      return <Status {...cellProps} {...props} showActive={true} />
    },
  },
]
