import { graphClient } from 'services/graph-client'
import {
  activeCompanyOwnerDashboardGQL,
  deleteCompanyOwnerDashboardGQL,
  deleteForeverCompanyOwnerDashboardGQL,
  createCompanyOwnerDashboardGQL,
  updateCompanyOwnerDashboardGQL,
} from './queries'
import { CompanyOwner } from 'types/settings/companies-owners-types'

interface ResponseCreate {
  createCompanyOwnerDashboard: {
    data: CompanyOwner
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateCompanyOwnerDashboard: {
    data: CompanyOwner
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeCompanyOwnerDashboard: {
    data: CompanyOwner
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteCompanyOwnerDashboard: {
    data: CompanyOwner
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverCompanyOwnerDashboard: string
}

interface DataActive {
  companyOwnerId: number
  active: boolean
}

interface DataDeleted {
  companyOwnerId: number
  deleted: boolean
}

interface DataDeleteForever {
  companyOwnerId: number
}

interface DataCreate {
  name: string
  document?: string
  documentTwo?: string
  documentThree?: string
  email?: string
  phone?: string
  priorityContact: boolean
}

interface DataUpdate {
  companyOwnerId: number
  name: string
  document?: string
  documentTwo?: string
  documentThree?: string
  email?: string
  phone?: string
  priorityContact: boolean
}

export const activeCompanyOwner = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeCompanyOwnerDashboardGQL,
    { ...data },
  )
}

export const deleteCompanyOwner = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteCompanyOwnerDashboardGQL,
    { ...data },
  )
}

export const deleteForeverCompanyOwner = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverCompanyOwnerDashboardGQL,
    { ...data },
  )
}

export const createCompanyOwner = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createCompanyOwnerDashboardGQL,
    { ...data },
  )
}

export const updateCompanyOwner = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateCompanyOwnerDashboardGQL,
    { ...data },
  )
}
