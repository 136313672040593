import { createSlice } from '@reduxjs/toolkit'
import { FarmInventory } from 'types/farms/farms-inventories-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  farmInventorySelected: null | FarmInventory
  deleteModalOpen: boolean
  farmInventoryIdToDelete: null | string
  data: FarmInventory[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  farmInventorySelected: null,
  farmInventoryIdToDelete: null,
  data: [],
  total: 0,
}

const farmInventorySlice = createSlice({
  initialState,
  name: 'farms-inventories-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectFarmInventory(state, { payload }: { payload: FarmInventory | null }) {
      state.farmInventorySelected = payload
    },
    setData(state, { payload }: { payload: FarmInventory[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: FarmInventory }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: FarmInventory }) {
      state.data = state.data.map((farmInventory) => {
        return farmInventory.id === payload.id
          ? { ...farmInventory, ...payload }
          : farmInventory
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (farmInventory) => +farmInventory.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.farmInventoryIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.farmInventoryIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectFarmInventory,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = farmInventorySlice.actions
export default farmInventorySlice.reducer
