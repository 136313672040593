import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeDefaultTypeCoffeePruning,
  deleteDefaultTypeCoffeePruning,
} from 'services/defaults/defaults-types-coffees-prunings/defaults-types-coffees-prunings-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectDefaultTypeCoffeePruning,
} from 'slices/defaults/defaults-types-coffees-prunings-reducer'
import { DefaultTypeCoffeePruning } from 'types/defaults/defaults-types-coffees-prunings-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('defaults_types_coffees_prunings')
  const dispatch = useDispatch()
  const item = cell?.row?.original as DefaultTypeCoffeePruning

  const handleOpenForm = () => {
    dispatch(selectDefaultTypeCoffeePruning(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(
      selectDefaultTypeCoffeePruning(itemWithoutId as DefaultTypeCoffeePruning),
    )
    dispatch(openForm())
  }

  const { mutateAsync: deleteDefaultTypeCoffeePruningDashboardFn } =
    useMutation({
      mutationFn: deleteDefaultTypeCoffeePruning,
    })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteDefaultTypeCoffeePruningDashboard } =
      await deleteDefaultTypeCoffeePruningDashboardFn({
        defaultTypeCoffeePruningId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteDefaultTypeCoffeePruningDashboard?.data))
  }

  const { mutateAsync: activeDefaultTypeCoffeePruningDashboardFn } =
    useMutation({
      mutationFn: activeDefaultTypeCoffeePruning,
    })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeDefaultTypeCoffeePruningDashboard } =
      await activeDefaultTypeCoffeePruningDashboardFn({
        defaultTypeCoffeePruningId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeDefaultTypeCoffeePruningDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
