import { createSelector } from 'reselect'
import { TypeExpense } from 'types/types-coffees/types-expenses-types'
import { StateRoot } from 'slices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useIsMobile } from 'lib/hooks/use-is-mobile'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteForeverTypeExpense } from 'services/types-coffees/types-expenses/types-expenses-cruds'
import {
  getTypesExpensesDashboard,
  GetParams,
  getTypesExpensesContainsDashboard,
} from 'services/types-coffees/types-expenses/types-expenses-gets'
import {
  closeDeleteModal,
  deleteUniqueData,
  closeFilter,
  openFilter,
  openForm,
  setData,
  setTotal,
  openImport,
  closeImport,
} from 'slices/types-coffees/types-expenses-reducer'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const typeExpenseListSelector = (state: StateRoot) => state.typesExpensesReducer

const selectorResult = createSelector(
  typeExpenseListSelector,
  (typeExpenseListReducer) => {
    return typeExpenseListReducer
  },
)

export const useTypeExpenseList = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParams] = useState<GetParams>({
    page: 0,
  })

  const permissions = useGetPermissions('types_expenses')

  const {
    data: dataTypeExpense,
    deleteModalOpen,
    typeExpenseIdToDelete,
    filterOpen,
    exportOpen,
    importOpen,
    total,
  } = useSelector(selectorResult)

  const { data, isFetching, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get', 'typeExpense', 'list', { params }],
    queryFn: () => getTypesExpensesDashboard(params),
  })

  const { mutateAsync, isPending: isLoadingDelete } = useMutation({
    mutationFn: deleteForeverTypeExpense,
  })

  const handleStoreData = useCallback(
    (typesExpenses: TypeExpense[], totalPages: number | null) => {
      if (!typesExpenses) return

      dispatch(setData(typesExpenses))
      dispatch(setTotal(totalPages ?? 0))
    },
    [],
  )

  const handleDeleteForeverTypeExpense = useCallback(async () => {
    if (!typeExpenseIdToDelete) return

    const response = await mutateAsync({
      typeExpenseId: +typeExpenseIdToDelete,
    })

    if (response?.deleteForeverTypeExpenseDashboard === 'deleted_forever') {
      dispatch(deleteUniqueData({ id: typeExpenseIdToDelete }))
    }

    dispatch(closeDeleteModal())
  }, [typeExpenseIdToDelete])

  useEffect(() => {
    const typesExpensesResult = data?.getTypesExpensesDashboard

    if (typesExpensesResult) {
      handleStoreData(
        typesExpensesResult?.data,
        typesExpensesResult?.totalPages,
      )
    }
  }, [isFetching])

  const handleFetchMore = useCallback((pageNumber: number) => {
    setParams((prevState) => ({ ...prevState, page: pageNumber }))
  }, [])

  const handleSearch = useCallback(
    async (value: GetParams) => {
      const response = await getTypesExpensesContainsDashboard(value)
      const typesExpensesResult = response?.getTypesExpensesContainsDashboard

      handleStoreData(
        typesExpensesResult?.data,
        typesExpensesResult?.totalPages,
      )
    },
    [handleStoreData],
  )

  const handleSetFilter = useCallback((value: GetParams) => {
    setParams(value)
  }, [])

  const handleOpenForm = () => {
    if (!permissions.canAdd) return
    dispatch(openForm())
  }

  const handleCloseFilter = () => {
    dispatch(closeFilter())
  }

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  const handleOpenImport = () => {
    if (!permissions.canImport) return
    dispatch(openImport())
  }

  const handleCloseImport = () => {
    dispatch(closeImport())
  }

  return {
    isLoadingDelete,
    deleteModalOpen,
    typeExpenseIdToDelete,
    typeExpense: data?.getTypesExpensesDashboard?.data,
    isLoading,
    isFetching,
    dataTypeExpense,
    searchTerm,
    filterOpen,
    page: params.page,
    exportOpen,
    importOpen,
    isMobile,
    total,
    isRefetching,
    permissions,
    handleDeleteForeverTypeExpense,
    handleOpenImport,
    handleCloseImport,
    handleSetFilter,
    handleSearch,
    handleOpenFilter,
    refetch,
    setSearchTerm,
    dispatch,
    handleFetchMore,
    handleOpenForm,
    handleCloseFilter,
  }
}
