import { gql } from 'graphql-request'

export const createDefaultTypeMeasurementDashboardGQL = gql`
  mutation CREATE_DEFAULT_TYPE_MEASUREMENT_DASHBOARD(
    $name: String!
    $description: String!
  ) {
    createDefaultTypeMeasurementDashboard(
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultTypeMeasurementDashboardGQL = gql`
  mutation UPDATE_DEFAULT_TYPE_MEASUREMENT_DASHBOARD(
    $defaultTypeMeasurementId: Int!
    $name: String
    $description: String
  ) {
    updateDefaultTypeMeasurementDashboard(
      defaultTypeMeasurementId: $defaultTypeMeasurementId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultTypeMeasurementDashboardGQL = gql`
  mutation DELETE_DEFAULT_TYPE_MEASUREMENT_DASHBOARD(
    $defaultTypeMeasurementId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultTypeMeasurementDashboard(
      defaultTypeMeasurementId: $defaultTypeMeasurementId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultTypeMeasurementDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_TYPE_MEASUREMENT_DASHBOARD(
    $defaultTypeMeasurementId: Int!
    $active: Boolean!
  ) {
    activeDefaultTypeMeasurementDashboard(
      defaultTypeMeasurementId: $defaultTypeMeasurementId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultTypeMeasurementDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_TYPE_MEASUREMENT_DASHBOARD(
    $defaultTypeMeasurementId: Int!
  ) {
    deleteForeverDefaultTypeMeasurementDashboard(
      defaultTypeMeasurementId: $defaultTypeMeasurementId
    )
  }
`

export const getDefaultsTypesMeasurementsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_MEASUREMENTS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsTypesMeasurementsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsTypesMeasurementsContainsDashboardGQL = gql`
  query GET_DEFAULTS_TYPES_MEASUREMENTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getDefaultsTypesMeasurementsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
