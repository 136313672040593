import { gql } from 'graphql-request'

export const createDefaultAgriculturalInputDashboardGQL = gql`
  mutation CREATE_DEFAULT_AGRICULTURAL_INPUT_DASHBOARD(
    $name: String!
    $description: String!
    $agriculturalInputSubTypeId: Int!
    $agriculturalInputModeActionId: Int
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $isPesticide: Boolean!
    $typePestId: Int
    $toxicologicalClass: String
    $environmentalClass: String
    $isOrganic: Boolean!
    $isFertilizer: Boolean!
  ) {
    createDefaultAgriculturalInputDashboard(
      name: $name
      description: $description
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      isPesticide: $isPesticide
      typePestId: $typePestId
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
      isOrganic: $isOrganic
      isFertilizer: $isFertilizer
    ) {
      error
      status
      data {
        id
        name
        description
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        tradeMark
        formulation
        activeIngredient
        isPesticide
        typePestId
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateDefaultAgriculturalInputDashboardGQL = gql`
  mutation UPDATE_DEFAULT_AGRICULTURAL_INPUT_DASHBOARD(
    $defaultAgriculturalInputId: Int!
    $name: String
    $description: String
    $agriculturalInputSubTypeId: Int
    $agriculturalInputModeActionId: Int
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $isPesticide: Boolean
    $typePestId: Int
    $toxicologicalClass: String
    $environmentalClass: String
    $isOrganic: Boolean
    $isFertilizer: Boolean
  ) {
    updateDefaultAgriculturalInputDashboard(
      defaultAgriculturalInputId: $defaultAgriculturalInputId
      name: $name
      description: $description
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      isPesticide: $isPesticide
      typePestId: $typePestId
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
      isOrganic: $isOrganic
      isFertilizer: $isFertilizer
    ) {
      error
      status
      data {
        id
        name
        description
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        tradeMark
        formulation
        activeIngredient
        isPesticide
        typePestId
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteDefaultAgriculturalInputDashboardGQL = gql`
  mutation DELETE_DEFAULT_AGRICULTURAL_INPUT_DASHBOARD(
    $defaultAgriculturalInputId: Int!
    $deleted: Boolean!
  ) {
    deleteDefaultAgriculturalInputDashboard(
      defaultAgriculturalInputId: $defaultAgriculturalInputId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        name
        description
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        tradeMark
        formulation
        activeIngredient
        isPesticide
        typePestId
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeDefaultAgriculturalInputDashboardGQL = gql`
  mutation ACTIVE_DEFAULT_AGRICULTURAL_INPUT_DASHBOARD(
    $defaultAgriculturalInputId: Int!
    $active: Boolean!
  ) {
    activeDefaultAgriculturalInputDashboard(
      defaultAgriculturalInputId: $defaultAgriculturalInputId
      active: $active
    ) {
      error
      status
      data {
        id
        name
        description
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        tradeMark
        formulation
        activeIngredient
        isPesticide
        typePestId
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverDefaultAgriculturalInputDashboardGQL = gql`
  mutation DELETE_FOREVER_DEFAULT_AGRICULTURAL_INPUT_DASHBOARD(
    $defaultAgriculturalInputId: Int!
  ) {
    deleteForeverDefaultAgriculturalInputDashboard(
      defaultAgriculturalInputId: $defaultAgriculturalInputId
    )
  }
`

export const getDefaultsAgriculturalsInputsDashboardGQL = gql`
  query GET_DEFAULTS_AGRICULTURALS_INPUTS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $agriculturalInputSubTypeId: Int
    $agriculturalInputModeActionId: Int
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $isPesticide: Boolean
    $typePestId: Int
    $toxicologicalClass: String
    $environmentalClass: String
    $isOrganic: Boolean
    $isFertilizer: Boolean
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getDefaultsAgriculturalsInputsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      agriculturalInputSubTypeId: $agriculturalInputSubTypeId
      agriculturalInputModeActionId: $agriculturalInputModeActionId
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      isPesticide: $isPesticide
      typePestId: $typePestId
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
      isOrganic: $isOrganic
      isFertilizer: $isFertilizer
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        tradeMark
        formulation
        activeIngredient
        isPesticide
        typePestId
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getDefaultsAgriculturalsInputsContainsDashboardGQL = gql`
  query GET_DEFAULTS_AGRICULTURALS_INPUTS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $tradeMark: String
    $formulation: String
    $activeIngredient: String
    $toxicologicalClass: String
    $environmentalClass: String
  ) {
    getDefaultsAgriculturalsInputsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      tradeMark: $tradeMark
      formulation: $formulation
      activeIngredient: $activeIngredient
      toxicologicalClass: $toxicologicalClass
      environmentalClass: $environmentalClass
    ) {
      error
      status
      totalPages
      data {
        id
        name
        description
        agriculturalInputSubTypeId
        agriculturalInputModeActionId
        tradeMark
        formulation
        activeIngredient
        isPesticide
        typePestId
        toxicologicalClass
        environmentalClass
        isOrganic
        isFertilizer
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
