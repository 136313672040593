import { gql } from 'graphql-request'

export const createOrderServiceDashboardGQL = gql`
  mutation CREATE_ORDER_SERVICE_DASHBOARD(
    $farmId: Int
    $farmFieldId: Int
    $typeCoffeeTreatmentId: Int
    $name: String!
    $dateService: String!
    $description: String!
    $optionalDescription: String
    $quantityHectare: Float
    $isBrush: Boolean
    $isPulverization: Boolean
    $isFertilizing: Boolean
  ) {
    createOrderServiceDashboard(
      farmId: $farmId
      farmFieldId: $farmFieldId
      typeCoffeeTreatmentId: $typeCoffeeTreatmentId
      name: $name
      dateService: $dateService
      description: $description
      optionalDescription: $optionalDescription
      quantityHectare: $quantityHectare
      isBrush: $isBrush
      isPulverization: $isPulverization
      isFertilizing: $isFertilizing
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCoffeeTreatmentId
        typeCoffeeTreatment {
          id
          name
        }
        name
        dateService
        description
        optionalDescription
        quantityHectare
        isBrush
        isPulverization
        isFertilizing
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateOrderServiceDashboardGQL = gql`
  mutation UPDATE_ORDER_SERVICE_DASHBOARD(
    $orderServiceId: Int!
    $farmId: Int
    $farmFieldId: Int
    $typeCoffeeTreatmentId: Int
    $name: String
    $dateService: String
    $description: String
    $optionalDescription: String
    $quantityHectare: Float
    $isBrush: Boolean
    $isPulverization: Boolean
    $isFertilizing: Boolean
  ) {
    updateOrderServiceDashboard(
      orderServiceId: $orderServiceId
      farmId: $farmId
      farmFieldId: $farmFieldId
      typeCoffeeTreatmentId: $typeCoffeeTreatmentId
      name: $name
      dateService: $dateService
      description: $description
      optionalDescription: $optionalDescription
      quantityHectare: $quantityHectare
      isBrush: $isBrush
      isPulverization: $isPulverization
      isFertilizing: $isFertilizing
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCoffeeTreatmentId
        typeCoffeeTreatment {
          id
          name
        }
        name
        dateService
        description
        optionalDescription
        quantityHectare
        isBrush
        isPulverization
        isFertilizing
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteOrderServiceDashboardGQL = gql`
  mutation DELETE_ORDER_SERVICE_DASHBOARD(
    $orderServiceId: Int!
    $deleted: Boolean!
  ) {
    deleteOrderServiceDashboard(
      orderServiceId: $orderServiceId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        typeCoffeeTreatmentId
        name
        dateService
        description
        optionalDescription
        quantityHectare
        isBrush
        isPulverization
        isFertilizing
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeOrderServiceDashboardGQL = gql`
  mutation ACTIVE_ORDER_SERVICE_DASHBOARD(
    $orderServiceId: Int!
    $active: Boolean!
  ) {
    activeOrderServiceDashboard(
      orderServiceId: $orderServiceId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        farmId
        farmFieldId
        typeCoffeeTreatmentId
        name
        dateService
        description
        optionalDescription
        quantityHectare
        isBrush
        isPulverization
        isFertilizing
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverOrderServiceDashboardGQL = gql`
  mutation DELETE_FOREVER_ORDER_SERVICE_DASHBOARD($orderServiceId: Int!) {
    deleteForeverOrderServiceDashboard(orderServiceId: $orderServiceId)
  }
`

export const getOrdersServicesDashboardGQL = gql`
  query GET_ORDERS_SERVICES_DASHBOARD(
    $take: Int
    $skip: Int
    $farmId: Int
    $farmFieldId: Int
    $typeCoffeeTreatmentId: Int
    $name: String
    $dateService: String
    $description: String
    $optionalDescription: String
    $quantityHectare: Float
    $isBrush: Boolean
    $isPulverization: Boolean
    $isFertilizing: Boolean
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getOrdersServicesDashboard(
      take: $take
      skip: $skip
      farmId: $farmId
      farmFieldId: $farmFieldId
      typeCoffeeTreatmentId: $typeCoffeeTreatmentId
      name: $name
      dateService: $dateService
      description: $description
      optionalDescription: $optionalDescription
      quantityHectare: $quantityHectare
      isBrush: $isBrush
      isPulverization: $isPulverization
      isFertilizing: $isFertilizing
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCoffeeTreatmentId
        typeCoffeeTreatment {
          id
          name
        }
        name
        dateService
        description
        optionalDescription
        quantityHectare
        isBrush
        isPulverization
        isFertilizing
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmFieldDashboardIdGQL = gql`
  query GET_FARM_FIELD_DASHBOARD_ID($farmFieldId: Int!) {
    getFarmFieldDashboard(farmFieldId: $farmFieldId) {
      error
      status
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsFieldsDashboardGQL = gql`
  query GET_FARMS_FIELDS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsFieldsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        farmId
        areaId
        farmFieldPeriodId
        typeCoffeeColorId
        typeCoffeeSizeId
        typeCoffeeMaturationId
        typeCoffeeIrrigationId
        typeCoffeeVarietyId
        coffeePruningId
        typeCoffeePruningId
        typeCoffeeManagementId
        typeCoffeeHarvestId
        typeCoffeeRenovationId
        name
        yearPlanting
        monthPlanting
        lineSpacing
        plantSpacing
        plantFailure
        linearQuantity
        plantingQuantity
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmDashboardIdGQL = gql`
  query GET_FARM_DASHBOARD_ID($farmId: Int!) {
    getFarmDashboard(farmId: $farmId) {
      error
      status
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getFarmsDashboardGQL = gql`
  query GET_FARMS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getFarmsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        state
        city
        geographicLocation
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypeCoffeeTreatmentDashboardIdGQL = gql`
  query GET_TYPE_COFFEE_TREATMENT_DASHBOARD_ID($typeCoffeeTreatmentId: Int!) {
    getTypeCoffeeTreatmentDashboard(
      typeCoffeeTreatmentId: $typeCoffeeTreatmentId
    ) {
      error
      status
      data {
        id
        companyId
        typeToolId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesTreatmentsDashboardGQL = gql`
  query GET_TYPES_COFFEES_TREATMENTS_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getTypesCoffeesTreatmentsDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        typeToolId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getOrdersServicesContainsDashboardGQL = gql`
  query GET_ORDERS_SERVICES_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $optionalDescription: String
  ) {
    getOrdersServicesContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      optionalDescription: $optionalDescription
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        farmFieldId
        farmField {
          id
          name
        }
        farmId
        farm {
          id
          name
        }
        typeCoffeeTreatmentId
        typeCoffeeTreatment {
          id
          name
        }
        name
        dateService
        description
        optionalDescription
        quantityHectare
        isBrush
        isPulverization
        isFertilizing
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
