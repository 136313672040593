import { createSlice } from '@reduxjs/toolkit'
import { DefaultTypeCoffeeColor } from 'types/defaults/defaults-types-coffees-colors-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  defaultTypeCoffeeColorSelected: null | DefaultTypeCoffeeColor
  deleteModalOpen: boolean
  defaultTypeCoffeeColorIdToDelete: null | string
  data: DefaultTypeCoffeeColor[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  defaultTypeCoffeeColorSelected: null,
  defaultTypeCoffeeColorIdToDelete: null,
  data: [],
  total: 0,
}

const defaultTypeCoffeeColorSlice = createSlice({
  initialState,
  name: 'defaults-types-coffees-colors-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectDefaultTypeCoffeeColor(
      state,
      { payload }: { payload: DefaultTypeCoffeeColor | null },
    ) {
      state.defaultTypeCoffeeColorSelected = payload
    },
    setData(state, { payload }: { payload: DefaultTypeCoffeeColor[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: DefaultTypeCoffeeColor }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: DefaultTypeCoffeeColor }) {
      state.data = state.data.map((defaultTypeCoffeeColor) => {
        return defaultTypeCoffeeColor.id === payload.id
          ? { ...defaultTypeCoffeeColor, ...payload }
          : defaultTypeCoffeeColor
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (defaultTypeCoffeeColor) => +defaultTypeCoffeeColor.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.defaultTypeCoffeeColorIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.defaultTypeCoffeeColorIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectDefaultTypeCoffeeColor,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = defaultTypeCoffeeColorSlice.actions
export default defaultTypeCoffeeColorSlice.reducer
