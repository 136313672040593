import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'
import { formatSelectPhoneToFields } from 'helpers/form/format-select-phone-to-fields'
import { formatCurrencyToSend } from 'helpers/format/format-currency'
import { createFarm, updateFarm } from 'services/farms/farms/farms-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectFarm,
} from 'slices/farms/farms-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z.string().min(1, t('inform-field') + ' ' + t('farms-name')),

  description: z.string(),
  document: z.string(),
  documentTwo: z.string(),
  documentThree: z.string(),
  email: z.string().min(1, t('inform-field') + ' ' + t('farms-email')),

  phone: z
    .object({
      phone: z
        .string()
        .min(1, t('invalid-phone-field') + ' ' + t('farms-phone')),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('farms-phone'),
      path: ['phone'],
    }),
  country: z.string(),
  state: z.string(),
  city: z.string(),

  geographicLocation: z.string(),
  hectareQuantity: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-hectare-quantity')),

  usefullHectareQuantity: z
    .string()
    .min(1, t('inform-field') + ' ' + t('farms-usefull-hectare-quantity')),
})

export type FormData = z.infer<typeof schema>

const farmModalFormSelector = (state: StateRoot) => {
  return state.farmsReducer
}

export const selectorResult = createSelector(
  farmModalFormSelector,
  (farmReducer) => {
    return farmReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, farmSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('farms')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
    setValue,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectFarm(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createFarmFn, isPending } = useMutation({
    mutationFn: createFarm,
  })

  const { mutateAsync: updateFarmFn, isPending: isLoadingUpdate } = useMutation(
    {
      mutationFn: updateFarm,
    },
  )

  const cleanFields: FormData = {
    name: '',
    description: '',
    document: '',
    documentTwo: '',
    documentThree: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
    country: '',
    state: '',
    city: '',
    geographicLocation: '',
    hectareQuantity: '',
    usefullHectareQuantity: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!farmSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: farmSelected.name,
      description: farmSelected.description,
      document: farmSelected.document,
      documentTwo: farmSelected.documentTwo,
      documentThree: farmSelected.documentThree,
      email: farmSelected.email,
      phone: formatSelectPhoneToFields(farmSelected.phone),
      country: farmSelected.country,
      state: farmSelected.state,
      city: farmSelected.city,
      geographicLocation: farmSelected.geographicLocation,
      hectareQuantity: `${farmSelected.hectareQuantity}`,
      usefullHectareQuantity: `${farmSelected.usefullHectareQuantity}`,
    }

    reset(farmSelected ? fields : cleanFields)
  }, [farmSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        name: data?.name,
        description: data?.description,
        document: data?.document,
        documentTwo: data?.documentTwo,
        documentThree: data?.documentThree,
        email: data?.email,
        phone: data?.phone.phone,
        country: data?.country,
        state: data?.state,
        city: data?.city,
        geographicLocation: data?.geographicLocation,
        hectareQuantity: formatCurrencyToSend(data?.hectareQuantity),
        usefullHectareQuantity: formatCurrencyToSend(
          data?.usefullHectareQuantity,
        ),
      }

      if (farmSelected && farmSelected?.id) {
        const { updateFarmDashboard } = await updateFarmFn({
          ...formatedData,
          farmId: farmSelected?.id,
        })

        dispatch(editUniqueData(updateFarmDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createFarmFn(formatedData)

      dispatch(pushData(responseData?.createFarmDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    farmSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    handleSelectCountry,
    resetFields,
  }
}
