import { useMutation } from '@tanstack/react-query'
import { StatusComponent } from 'components/common/status'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useDispatch } from 'react-redux'

import {
  activeCoffeePruning,
  deleteCoffeePruning,
} from 'services/farms/coffees-prunings/coffees-prunings-cruds'

import {
  editUniqueData,
  openForm,
  openDeleteModal,
  openFilter,
  selectCoffeePruning,
} from 'slices/farms/coffees-prunings-reducer'
import { CoffeePruning } from 'types/farms/coffees-prunings-types'

const Status = (cell: any) => {
  const permissions = useGetPermissions('coffees_prunings')
  const dispatch = useDispatch()
  const item = cell?.row?.original as CoffeePruning

  const handleOpenForm = () => {
    dispatch(selectCoffeePruning(item))
    dispatch(openForm())
  }

  const handleOpenDuplicateForm = () => {
    if (!permissions.canDuplicate) return
    const { id, ...itemWithoutId } = item

    dispatch(selectCoffeePruning(itemWithoutId as CoffeePruning))
    dispatch(openForm())
  }

  const { mutateAsync: deleteCoffeePruningDashboardFn } = useMutation({
    mutationFn: deleteCoffeePruning,
  })

  const handleToggleArchive = async () => {
    if (!permissions.canDeleted) return

    const { deleteCoffeePruningDashboard } =
      await deleteCoffeePruningDashboardFn({
        coffeePruningId: +item?.id,
        deleted: !item?.deleted,
      })

    dispatch(editUniqueData(deleteCoffeePruningDashboard?.data))
  }

  const { mutateAsync: activeCoffeePruningDashboardFn } = useMutation({
    mutationFn: activeCoffeePruning,
  })

  const handleToggleActive = async () => {
    if (!permissions.canActive) return

    const { activeCoffeePruningDashboard } =
      await activeCoffeePruningDashboardFn({
        coffeePruningId: +item?.id,
        active: !item?.active,
      })

    dispatch(editUniqueData(activeCoffeePruningDashboard?.data))
  }

  const handleOpenDeleteModal = () => {
    if (!permissions.canDeleteForever) return

    dispatch(openDeleteModal({ id: `${item?.id}` }))
  }

  return (
    <StatusComponent
      cell={cell}
      permissions={permissions}
      handleToggleArchive={handleToggleArchive}
      handleToggleActive={handleToggleActive}
      handleOpenForm={handleOpenForm}
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenDuplicateForm={handleOpenDuplicateForm}
    />
  )
}

const Filters = () => {
  const dispatch = useDispatch()

  const handleOpenFilter = () => {
    dispatch(openFilter())
  }

  return (
    <button
      className="btn btn-primary add-btn me-1"
      style={{ width: '35%' }}
      onClick={handleOpenFilter}
    >
      <i className="ri-equalizer-fill align-bottom"></i>
    </button>
  )
}

export { Filters, Status }
