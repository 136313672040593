import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'
import { formatSelectPhoneToFields } from 'helpers/form/format-select-phone-to-fields'
import { formatCurrencyToSend } from 'helpers/format/format-currency'
import {
  createCompany,
  updateCompany,
} from 'services/settings/companies/companies-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectCompany,
} from 'slices/settings/companies-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  codeUid: z.string().min(1, t('inform-field') + ' ' + t('companies-code-uid')),

  name: z.string().min(1, t('inform-field') + ' ' + t('companies-name')),

  description: z.string(),
  document: z.string(),
  documentTwo: z.string(),
  documentThree: z.string(),
  email: z.string().min(1, t('inform-field') + ' ' + t('companies-email')),

  phone: z
    .object({
      phone: z
        .string()
        .min(1, t('invalid-phone-field') + ' ' + t('companies-phone')),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('companies-phone'),
      path: ['phone'],
    }),
  country: z.string().min(1, t('inform-field') + ' ' + t('companies-country')),

  hectareQuantity: z
    .string()
    .min(1, t('inform-field') + ' ' + t('companies-hectare-quantity')),

  usefullHectareQuantity: z
    .string()
    .min(1, t('inform-field') + ' ' + t('companies-usefull-hectare-quantity')),
})

export type FormData = z.infer<typeof schema>

const companyModalFormSelector = (state: StateRoot) => {
  return state.companiesReducer
}

export const selectorResult = createSelector(
  companyModalFormSelector,
  (companyReducer) => {
    return companyReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, companySelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('companies')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
    setValue,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectCompany(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createCompanyFn, isPending } = useMutation({
    mutationFn: createCompany,
  })

  const { mutateAsync: updateCompanyFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateCompany,
    })

  const cleanFields: FormData = {
    codeUid: '',
    name: '',
    description: '',
    document: '',
    documentTwo: '',
    documentThree: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
    country: '',
    hectareQuantity: '',
    usefullHectareQuantity: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!companySelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      codeUid: companySelected.codeUid,
      name: companySelected.name,
      description: companySelected.description,
      document: companySelected.document,
      documentTwo: companySelected.documentTwo,
      documentThree: companySelected.documentThree,
      email: companySelected.email,
      phone: formatSelectPhoneToFields(companySelected.phone),
      country: companySelected.country,
      hectareQuantity: `${companySelected.hectareQuantity}`,
      usefullHectareQuantity: `${companySelected.usefullHectareQuantity}`,
    }

    reset(companySelected ? fields : cleanFields)
  }, [companySelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        codeUid: data?.codeUid,
        name: data?.name,
        description: data?.description,
        document: data?.document,
        documentTwo: data?.documentTwo,
        documentThree: data?.documentThree,
        email: data?.email,
        phone: data?.phone.phone,
        country: data?.country,
        hectareQuantity: formatCurrencyToSend(data?.hectareQuantity),
        usefullHectareQuantity: formatCurrencyToSend(
          data?.usefullHectareQuantity,
        ),
      }

      if (companySelected && companySelected?.id) {
        const { updateCompanyDashboard } = await updateCompanyFn({
          ...formatedData,
          companyId: companySelected?.id,
        })

        dispatch(editUniqueData(updateCompanyDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createCompanyFn(formatedData)

      dispatch(pushData(responseData?.createCompanyDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    companySelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    handleSelectCountry,
    resetFields,
  }
}
