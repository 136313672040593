import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import { formatSelectValueToFields } from 'helpers/form/format-select-value-to-fields'
import { formatToSendData } from 'helpers/form/format-to-send-data'
import {
  createAgriculturalInputCultivation,
  updateAgriculturalInputCultivation,
} from 'services/agriculturals-inputs/agriculturals-inputs-cultivations/agriculturals-inputs-cultivations-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectAgriculturalInputCultivation,
} from 'slices/agriculturals-inputs/agriculturals-inputs-cultivations-reducer'
import { z } from 'zod'
import { useGetTypesCultivationsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-types-cultivations-dashboard'
import { useGetAgriculturalsInputsDashboard } from 'lib/hooks/queries/infinity-scroll/use-get-agriculturals-inputs-dashboard'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  agriculturalInputId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-cultivations-agricultural-input-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  typeCultivationId: z.union([
    z
      .string()
      .min(
        1,
        t('inform-field') +
          ' ' +
          t('agriculturals-inputs-cultivations-type-cultivation-id'),
      ),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  name: z
    .string()
    .min(
      1,
      t('inform-field') + ' ' + t('agriculturals-inputs-cultivations-name'),
    ),
})

export type FormData = z.infer<typeof schema>

const agriculturalInputCultivationModalFormSelector = (state: StateRoot) => {
  return state.agriculturalsInputsCultivationsReducer
}

export const selectorResult = createSelector(
  agriculturalInputCultivationModalFormSelector,
  (agriculturalInputCultivationReducer) => {
    return agriculturalInputCultivationReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, agriculturalInputCultivationSelected } =
    useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('agriculturals_inputs_cultivations')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const typeCultivationInfo = useGetTypesCultivationsDashboard()
  const agriculturalInputInfo = useGetAgriculturalsInputsDashboard()

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectAgriculturalInputCultivation(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createAgriculturalInputCultivationFn, isPending } =
    useMutation({
      mutationFn: createAgriculturalInputCultivation,
    })

  const {
    mutateAsync: updateAgriculturalInputCultivationFn,
    isPending: isLoadingUpdate,
  } = useMutation({
    mutationFn: updateAgriculturalInputCultivation,
  })

  const cleanFields: FormData = {
    agriculturalInputId: '',
    typeCultivationId: '',
    name: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!agriculturalInputCultivationSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      typeCultivationId: formatSelectValueToFields(
        agriculturalInputCultivationSelected.typeCultivation,
        ['id', 'name'],
      ),
      agriculturalInputId: formatSelectValueToFields(
        agriculturalInputCultivationSelected.agriculturalInput,
        ['id', 'name'],
      ),
      name: agriculturalInputCultivationSelected.name,
    }

    reset(agriculturalInputCultivationSelected ? fields : cleanFields)
  }, [agriculturalInputCultivationSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const selectedTypeCultivation = +formatToSendData(data?.typeCultivationId)
      const selectedAgriculturalInput = +formatToSendData(
        data?.agriculturalInputId,
      )

      const formatedData = {
        typeCultivationId: selectedTypeCultivation,
        agriculturalInputId: selectedAgriculturalInput,
        name: data?.name,
      }

      if (
        agriculturalInputCultivationSelected &&
        agriculturalInputCultivationSelected?.id
      ) {
        const { updateAgriculturalInputCultivationDashboard } =
          await updateAgriculturalInputCultivationFn({
            ...formatedData,
            agriculturalInputCultivationId:
              agriculturalInputCultivationSelected?.id,
          })

        dispatch(
          editUniqueData(updateAgriculturalInputCultivationDashboard?.data),
        )
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData =
        await createAgriculturalInputCultivationFn(formatedData)

      dispatch(
        pushData(
          responseData?.createAgriculturalInputCultivationDashboard?.data,
        ),
      )
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    agriculturalInputCultivationSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
    typeCultivationInfo,
    agriculturalInputInfo,
  }
}
