import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { z } from 'zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'helpers/form/valid-phone'

const schemaFilter = z.object({
  name: z.string().min(1, t('inform-field') + ' ' + t('companies-owners-name')),

  document: z.string(),
  documentTwo: z.string(),
  documentThree: z.string(),
  email: z
    .string()
    .min(1, t('inform-field') + ' ' + t('companies-owners-email')),

  phone: z
    .object({
      phone: z.string(),
      country: z.string(),
    })
    .refine((value) => isValidPhoneNumber(value?.phone, value?.country), {
      message: t('invalid-phone-field') + ' ' + t('companies-owners-phone'),
      path: ['phone'],
    }),
  priorityContact: z.union([
    z
      .string()
      .min(1, t('inform-field') + ' ' + t('companies-owners-priority-contact')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  active: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('companies-owners-active')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  deleted: z.union([
    z.string().min(1, t('inform-field') + ' ' + t('companies-owners-deleted')),
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ]),

  createdAtInitial: z.union([z.date(), z.string().min(1)]),
  createdAtFinal: z.union([z.date(), z.string().min(1)]),
})

export type FormDataFilter = z.infer<typeof schemaFilter>

export const useModalFormFilter = () => {
  const cleanFields: FormDataFilter = {
    name: '',
    document: '',
    documentTwo: '',
    documentThree: '',
    email: '',
    phone: {
      country: '',
      phone: '',
    },
    priorityContact: '',
    active: '',
    deleted: '',
    createdAtInitial: '',
    createdAtFinal: '',
  }

  const form = useForm<FormDataFilter>({
    resolver: zodResolver(schemaFilter),
    defaultValues: cleanFields,
  })

  const {
    control,
    register,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = form

  const handleSelectCountry = (country: string) => {
    setValue('phone.country', country)
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  return {
    errors,
    control,
    form,
    register,
    handleSelectCountry,
    resetFields,
    getValues,
  }
}
