import { gql } from 'graphql-request'

export const createTypeCoffeeColorDashboardGQL = gql`
  mutation CREATE_TYPE_COFFEE_COLOR_DASHBOARD(
    $name: String!
    $description: String
  ) {
    createTypeCoffeeColorDashboard(name: $name, description: $description) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const updateTypeCoffeeColorDashboardGQL = gql`
  mutation UPDATE_TYPE_COFFEE_COLOR_DASHBOARD(
    $typeCoffeeColorId: Int!
    $name: String
    $description: String
  ) {
    updateTypeCoffeeColorDashboard(
      typeCoffeeColorId: $typeCoffeeColorId
      name: $name
      description: $description
    ) {
      error
      status
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteTypeCoffeeColorDashboardGQL = gql`
  mutation DELETE_TYPE_COFFEE_COLOR_DASHBOARD(
    $typeCoffeeColorId: Int!
    $deleted: Boolean!
  ) {
    deleteTypeCoffeeColorDashboard(
      typeCoffeeColorId: $typeCoffeeColorId
      deleted: $deleted
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const activeTypeCoffeeColorDashboardGQL = gql`
  mutation ACTIVE_TYPE_COFFEE_COLOR_DASHBOARD(
    $typeCoffeeColorId: Int!
    $active: Boolean!
  ) {
    activeTypeCoffeeColorDashboard(
      typeCoffeeColorId: $typeCoffeeColorId
      active: $active
    ) {
      error
      status
      data {
        id
        companyId
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const deleteForeverTypeCoffeeColorDashboardGQL = gql`
  mutation DELETE_FOREVER_TYPE_COFFEE_COLOR_DASHBOARD(
    $typeCoffeeColorId: Int!
  ) {
    deleteForeverTypeCoffeeColorDashboard(typeCoffeeColorId: $typeCoffeeColorId)
  }
`

export const getTypesCoffeesColorsDashboardGQL = gql`
  query GET_TYPES_COFFEES_COLORS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
    $active: Boolean
    $deleted: Boolean
    $createdAtInitial: String
    $createdAtFinal: String
  ) {
    getTypesCoffeesColorsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
      active: $active
      deleted: $deleted
      createdAtInitial: $createdAtInitial
      createdAtFinal: $createdAtFinal
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompanyDashboardIdGQL = gql`
  query GET_COMPANY_DASHBOARD_ID($companyId: Int!) {
    getCompanyDashboard(companyId: $companyId) {
      error
      status
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getCompaniesDashboardGQL = gql`
  query GET_COMPANIES_DASHBOARD(
    $take: Int
    $skip: Int
    $active: Boolean
    $deleted: Boolean
  ) {
    getCompaniesDashboard(
      take: $take
      skip: $skip
      active: $active
      deleted: $deleted
    ) {
      error
      status
      totalPages
      data {
        id
        codeUid
        name
        description
        document
        documentTwo
        documentThree
        email
        phone
        country
        hectareQuantity
        usefullHectareQuantity
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`

export const getTypesCoffeesColorsContainsDashboardGQL = gql`
  query GET_TYPES_COFFEES_COLORS_CONTAINS_DASHBOARD(
    $take: Int
    $skip: Int
    $name: String
    $description: String
  ) {
    getTypesCoffeesColorsContainsDashboard(
      take: $take
      skip: $skip
      name: $name
      description: $description
    ) {
      error
      status
      totalPages
      data {
        id
        companyId
        company {
          id
          name
        }
        name
        description
        active
        deleted
        createdAt
        updatedAt
      }
    }
  }
`
