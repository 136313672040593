import { ModalFormTemplate } from 'components/common/modal/form-template'
import { t } from 'i18next'
import { useModalForm } from './use-form'
import { FormControl } from '../components/form'
import { FormProvider } from 'react-hook-form'

export const Form = () => {
  const {
    formOpen,
    exampleOtherTwoSelected,
    errors,
    control,
    form,
    isLoading,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
  } = useModalForm()

  const canEdit = exampleOtherTwoSelected
    ? exampleOtherTwoSelected && permissions.canEdit
    : true

  return (
    <ModalFormTemplate
      isOpen={formOpen}
      title={t('examples-others-twos-title')}
      subtitle={
        exampleOtherTwoSelected && exampleOtherTwoSelected?.id
          ? t('editing')
          : t('creating')
      }
      isLoading={isLoading}
      permissions={{ canEdit: !!canEdit }}
      handleConfirm={handleSubmit}
      handleClose={handleCloseModal}
    >
      <form className="mt-4 d-flex flex-wrap gap-3">
        <FormProvider {...form}>
          <FormControl control={control} errors={errors} register={register} />
        </FormProvider>
      </form>
    </ModalFormTemplate>
  )
}
