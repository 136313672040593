import { phoneUtil } from 'helpers/format/format-date'

export const isValidPhoneNumber = (phoneNumber: string, country: string) => {
  let phone: libphonenumber.PhoneNumber

  try {
    phone = phoneUtil.parse(phoneNumber, country)
  } catch (error) {
    console.error(error)
    return
  }

  return phoneUtil.isValidNumber(phone)
}
