import { graphClient } from 'services/graph-client'
import {
  activeEmployeeDashboardGQL,
  deleteEmployeeDashboardGQL,
  deleteForeverEmployeeDashboardGQL,
  createEmployeeDashboardGQL,
  updateEmployeeDashboardGQL,
} from './queries'
import { Employee } from 'types/employees/employees-types'

interface ResponseCreate {
  createEmployeeDashboard: {
    data: Employee
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateEmployeeDashboard: {
    data: Employee
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeEmployeeDashboard: {
    data: Employee
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteEmployeeDashboard: {
    data: Employee
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverEmployeeDashboard: string
}

interface DataActive {
  employeeId: number
  active: boolean
}

interface DataDeleted {
  employeeId: number
  deleted: boolean
}

interface DataDeleteForever {
  employeeId: number
}

interface DataCreate {
  farmId?: number
  employeeFunctionId: number
  typeEmployee: 'registered' | 'contracted'
  name: string
  documentOne?: string
  documentTwo?: string
  photo?: string
  monthSalaryCurrency?: number
  monthBonusCurrency?: number
  monthSalaryFamilyCurrency?: number
  daySalaryCurrency?: number
  birthday?: string
  email?: string
  phone?: string
}

interface DataUpdate {
  employeeId: number
  farmId?: number
  employeeFunctionId: number
  typeEmployee: 'registered' | 'contracted'
  name: string
  documentOne?: string
  documentTwo?: string
  photo?: string
  monthSalaryCurrency?: number
  monthBonusCurrency?: number
  monthSalaryFamilyCurrency?: number
  daySalaryCurrency?: number
  birthday?: string
  email?: string
  phone?: string
}

export const activeEmployee = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(activeEmployeeDashboardGQL, {
    ...data,
  })
}

export const deleteEmployee = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(deleteEmployeeDashboardGQL, {
    ...data,
  })
}

export const deleteForeverEmployee = async (data: DataDeleteForever) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverEmployeeDashboardGQL,
    { ...data },
  )
}

export const createEmployee = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(createEmployeeDashboardGQL, {
    ...data,
  })
}

export const updateEmployee = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(updateEmployeeDashboardGQL, {
    ...data,
  })
}
