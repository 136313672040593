import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { createSelector } from 'reselect'
import {
  createTypeTool,
  updateTypeTool,
} from 'services/types-coffees/types-tools/types-tools-cruds'
import { StateRoot } from 'slices'
import {
  closeForm,
  editUniqueData,
  pushData,
  selectTypeTool,
} from 'slices/types-coffees/types-tools-reducer'
import { z } from 'zod'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'

const schema = z.object({
  name: z.string().min(1, t('inform-field') + ' ' + t('types-tools-name')),

  description: z.string(),
})

export type FormData = z.infer<typeof schema>

const typeToolModalFormSelector = (state: StateRoot) => {
  return state.typesToolsReducer
}

export const selectorResult = createSelector(
  typeToolModalFormSelector,
  (typeToolReducer) => {
    return typeToolReducer
  },
)

export const useModalForm = () => {
  const dispatch = useDispatch()

  const { formOpen, typeToolSelected } = useSelector(selectorResult)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const permissions = useGetPermissions('types_tools')

  const {
    control,
    register,
    formState: { errors },
    handleSubmit: hookFormSubmit,
    reset,
  } = form

  const handleCloseModal = () => {
    reset(cleanFields)
    dispatch(selectTypeTool(null))
    dispatch(closeForm())
  }

  const { mutateAsync: createTypeToolFn, isPending } = useMutation({
    mutationFn: createTypeTool,
  })

  const { mutateAsync: updateTypeToolFn, isPending: isLoadingUpdate } =
    useMutation({
      mutationFn: updateTypeTool,
    })

  const cleanFields: FormData = {
    name: '',
    description: '',
  }

  const resetFields = useCallback(() => {
    reset(cleanFields)
  }, [])

  useEffect(() => {
    if (!typeToolSelected) {
      return resetFields()
    }

    const fields: Partial<FormData> = {
      name: typeToolSelected.name,
      description: typeToolSelected.description,
    }

    reset(typeToolSelected ? fields : cleanFields)
  }, [typeToolSelected])

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formatedData = {
        name: data?.name,
        description: data?.description,
      }

      if (typeToolSelected && typeToolSelected?.id) {
        const { updateTypeToolDashboard } = await updateTypeToolFn({
          ...formatedData,
          typeToolId: typeToolSelected?.id,
        })

        dispatch(editUniqueData(updateTypeToolDashboard?.data))
        toast.success(t('edit-with-success'))

        return handleCloseModal()
      }

      const responseData = await createTypeToolFn(formatedData)

      dispatch(pushData(responseData?.createTypeToolDashboard?.data))
      toast.success(t('created-with-success'))

      handleCloseModal()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        toast.error(error.message)
      }
    }
  })

  return {
    isLoading: isPending || isLoadingUpdate,
    formOpen,
    typeToolSelected,
    errors,
    control,
    form,
    permissions,
    handleSubmit,
    handleCloseModal,
    register,
    resetFields,
  }
}
