import { graphClient } from 'services/graph-client'
import {
  Company,
  FarmField,
  Farm,
  Harvest,
  HarvestForecast,
} from 'types/harvests/harvests-forecasts-types'

import {
  getCompaniesDashboardGQL,
  getFarmsFieldsDashboardGQL,
  getFarmsDashboardGQL,
  getHarvestsDashboardGQL,
  getHarvestsForecastsDashboardGQL,
  getHarvestsForecastsContainsDashboardGQL,
} from './queries'

interface Response {
  getHarvestsForecastsDashboard: {
    data: HarvestForecast[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getHarvestsForecastsContainsDashboard: {
    data: HarvestForecast[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
}

export interface GetParams {
  page?: number
  farmId?: number
  farmFieldId?: number
  harvestId?: number
  forecastPlant?: number
  litersSpentPerBag?: number
  bagPerHectare?: number
  totalBag?: number
  name?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getHarvestsForecastsDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(getHarvestsForecastsDashboardGQL, {
    ...params,
    take,
    skip,
  })
}

export const getHarvestsForecastsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(getHarvestsForecastsDashboardGQL, {
    take,
    skip,
  })
}

export const getHarvestsForecastsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getHarvestsForecastsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseFarmField {
  getFarmsFieldsDashboard: {
    data: FarmField[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsFieldsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarmField>(
    getFarmsFieldsDashboardGQL,
    { take, skip },
  )
}

interface ResponseFarm {
  getFarmsDashboard: {
    data: Farm[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarm>(getFarmsDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseHarvest {
  getHarvestsDashboard: {
    data: Harvest[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getHarvestsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseHarvest>(getHarvestsDashboardGQL, {
    take,
    skip,
  })
}
