import { createSlice } from '@reduxjs/toolkit'
import { OrderServiceEmployee } from 'types/orders/orders-services-employees-types'

interface InitialState {
  formOpen: boolean
  filterOpen: boolean
  exportOpen: boolean
  importOpen: boolean
  orderServiceEmployeeSelected: null | OrderServiceEmployee
  deleteModalOpen: boolean
  orderServiceEmployeeIdToDelete: null | string
  data: OrderServiceEmployee[]
  total: number
}

const initialState: InitialState = {
  formOpen: false,
  filterOpen: false,
  exportOpen: false,
  importOpen: false,
  deleteModalOpen: false,
  orderServiceEmployeeSelected: null,
  orderServiceEmployeeIdToDelete: null,
  data: [],
  total: 0,
}

const orderServiceEmployeeSlice = createSlice({
  initialState,
  name: 'orders-services-employees-slice',
  reducers: {
    openImport(state) {
      state.importOpen = true
    },
    closeImport(state) {
      state.importOpen = false
    },
    openForm(state) {
      state.formOpen = true
    },
    closeForm(state) {
      state.formOpen = false
    },
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
    selectOrderServiceEmployee(
      state,
      { payload }: { payload: OrderServiceEmployee | null },
    ) {
      state.orderServiceEmployeeSelected = payload
    },
    setData(state, { payload }: { payload: OrderServiceEmployee[] }) {
      state.data = payload
    },
    setTotal(state, { payload }: { payload: number }) {
      state.total = payload
    },
    pushData(state, { payload }: { payload: OrderServiceEmployee }) {
      if (state?.data?.length === 10) state.total = state.total + 1
      state.data = state.data.concat(payload)
    },
    editUniqueData(state, { payload }: { payload: OrderServiceEmployee }) {
      state.data = state.data.map((orderServiceEmployee) => {
        return orderServiceEmployee.id === payload.id
          ? { ...orderServiceEmployee, ...payload }
          : orderServiceEmployee
      })
    },
    deleteUniqueData(state, { payload }: { payload: { id: string } }) {
      if (state?.data?.length === 1) state.total = state.total - 1
      state.data = state.data.filter(
        (orderServiceEmployee) => +orderServiceEmployee.id !== +payload.id,
      )
    },
    openDeleteModal(state, { payload }: { payload: { id: string } }) {
      state.deleteModalOpen = true
      state.orderServiceEmployeeIdToDelete = payload.id
    },
    closeDeleteModal(state) {
      state.deleteModalOpen = false
      state.orderServiceEmployeeIdToDelete = null
    },
  },
})

export const {
  openImport,
  closeImport,
  openForm,
  closeForm,
  selectOrderServiceEmployee,
  setData,
  pushData,
  editUniqueData,
  deleteUniqueData,
  openDeleteModal,
  closeDeleteModal,
  openFilter,
  closeFilter,
  setTotal,
} = orderServiceEmployeeSlice.actions
export default orderServiceEmployeeSlice.reducer
