import { graphClient } from 'services/graph-client'
import {
  activeEmployeeFunctionDashboardGQL,
  deleteEmployeeFunctionDashboardGQL,
  deleteForeverEmployeeFunctionDashboardGQL,
  createEmployeeFunctionDashboardGQL,
  updateEmployeeFunctionDashboardGQL,
} from './queries'
import { EmployeeFunction } from 'types/employees/employees-functions-types'

interface ResponseCreate {
  createEmployeeFunctionDashboard: {
    data: EmployeeFunction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseUpdate {
  updateEmployeeFunctionDashboard: {
    data: EmployeeFunction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseActive {
  activeEmployeeFunctionDashboard: {
    data: EmployeeFunction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDelete {
  deleteEmployeeFunctionDashboard: {
    data: EmployeeFunction
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ResponseDeleteForever {
  deleteForeverEmployeeFunctionDashboard: string
}

interface DataActive {
  employeeFunctionId: number
  active: boolean
}

interface DataDeleted {
  employeeFunctionId: number
  deleted: boolean
}

interface DataDeleteForever {
  employeeFunctionId: number
}

interface DataCreate {
  farmId?: number
  name: string
  description?: string
  baseSalaryCurrency: number
}

interface DataUpdate {
  employeeFunctionId: number
  farmId?: number
  name: string
  description?: string
  baseSalaryCurrency: number
}

export const activeEmployeeFunction = async (data: DataActive) => {
  return await graphClient.request<ResponseActive>(
    activeEmployeeFunctionDashboardGQL,
    { ...data },
  )
}

export const deleteEmployeeFunction = async (data: DataDeleted) => {
  return await graphClient.request<ResponseDelete>(
    deleteEmployeeFunctionDashboardGQL,
    { ...data },
  )
}

export const deleteForeverEmployeeFunction = async (
  data: DataDeleteForever,
) => {
  return await graphClient.request<ResponseDeleteForever>(
    deleteForeverEmployeeFunctionDashboardGQL,
    { ...data },
  )
}

export const createEmployeeFunction = async (data: DataCreate) => {
  return await graphClient.request<ResponseCreate>(
    createEmployeeFunctionDashboardGQL,
    { ...data },
  )
}

export const updateEmployeeFunction = async (data: DataUpdate) => {
  return await graphClient.request<ResponseUpdate>(
    updateEmployeeFunctionDashboardGQL,
    { ...data },
  )
}
