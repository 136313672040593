import { graphClient } from 'services/graph-client'
import { DefaultTypeExpense } from 'types/defaults/defaults-types-expenses-types'

import {
  getDefaultsTypesExpensesDashboardGQL,
  getDefaultsTypesExpensesContainsDashboardGQL,
} from './queries'

interface Response {
  getDefaultsTypesExpensesDashboard: {
    data: DefaultTypeExpense[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getDefaultsTypesExpensesContainsDashboard: {
    data: DefaultTypeExpense[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
  description?: string
}

export interface GetParams {
  page?: number
  name?: string
  description?: string
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getDefaultsTypesExpensesDashboard = async (params: GetParams) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getDefaultsTypesExpensesDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getDefaultsTypesExpensesDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getDefaultsTypesExpensesDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getDefaultsTypesExpensesContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getDefaultsTypesExpensesContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}
