import { ControlledInputNumber } from 'components/controlled-components/controlled-input-number'
import { InputComponent } from 'components/common/inputs/input'
import { ControlledSelect } from 'components/controlled-components/controlled-select'
import { FormData, selectorResult } from '../modal/use-form'
import { t } from 'i18next'
import { Col, Container, Row } from 'reactstrap'
import { InputDecimalComponent } from 'components/common/inputs/input-decimal'
import { ControlledInputPhone } from 'components/controlled-components/controlled-input-phone'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useGetPermissions } from 'lib/hooks/use-get-permissions'
import { useSelector } from 'react-redux'

interface Props {
  control: Control<FormData>
  errors: FieldErrors<FormData>
  register: UseFormRegister<FormData>
  handleSelectCountry: (country: string) => void
}

export const FormControl = ({
  control,
  errors,
  register,
  handleSelectCountry,
}: Props) => {
  const permissions = useGetPermissions('farms')
  const { farmSelected } = useSelector(selectorResult)
  const disabled = !!(farmSelected && !permissions.canEdit)

  return (
    <Container fluid style={{ paddingLeft: 0 }}>
      <Row>
        <Col sm={4}>
          <InputComponent
            error={errors?.name?.message}
            {...register('name')}
            label={t('farms-name')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.description?.message}
            {...register('description')}
            label={t('farms-description')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.document?.message}
            {...register('document')}
            label={t('farms-document')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.documentTwo?.message}
            {...register('documentTwo')}
            label={t('farms-document-two')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.documentThree?.message}
            {...register('documentThree')}
            label={t('farms-document-three')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            type="email"
            error={errors?.email?.message}
            {...register('email')}
            label={t('farms-email')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <ControlledInputPhone
            control={control}
            name="phone.phone"
            label={t('farms-phone')}
            handleSelectCountry={handleSelectCountry}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.country?.message}
            {...register('country')}
            label={t('farms-country')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.state?.message}
            {...register('state')}
            label={t('farms-state')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputComponent
            error={errors?.city?.message}
            {...register('city')}
            label={t('farms-city')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputComponent
            error={errors?.geographicLocation?.message}
            {...register('geographicLocation')}
            label={t('farms-geographic-location')}
            disabled={disabled}
          />
        </Col>

        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.hectareQuantity?.message}
            {...register('hectareQuantity')}
            label={t('farms-hectare-quantity')}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col sm={4}>
          <InputDecimalComponent
            error={errors?.usefullHectareQuantity?.message}
            {...register('usefullHectareQuantity')}
            label={t('farms-usefull-hectare-quantity')}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  )
}
