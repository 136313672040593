import { graphClient } from 'services/graph-client'
import {
  Company,
  FarmField,
  Farm,
  OrderService,
  AgriculturalInput,
  OrderServiceAgriculturalInput,
} from 'types/orders/orders-services-agriculturals-inputs-types'

import {
  getCompaniesDashboardGQL,
  getFarmsFieldsDashboardGQL,
  getFarmsDashboardGQL,
  getOrdersServicesDashboardGQL,
  getAgriculturalsInputsDashboardGQL,
  getOrdersServicesAgriculturalsInputsDashboardGQL,
  getOrdersServicesAgriculturalsInputsContainsDashboardGQL,
} from './queries'

interface Response {
  getOrdersServicesAgriculturalsInputsDashboard: {
    data: OrderServiceAgriculturalInput[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

interface ContainsResponse {
  getOrdersServicesAgriculturalsInputsContainsDashboard: {
    data: OrderServiceAgriculturalInput[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export interface GetContainsParams {
  page?: number
  name?: string
}

export interface GetParams {
  page?: number
  farmId?: number
  farmFieldId?: number
  orderServiceId?: number
  agriculturalInputId?: number
  name?: string
  valueAgriculturalInput?: number
  quantityPerPlant?: number
  expectedTotalQuantity?: number
  realTotalQuantity?: number
  active?: boolean
  deleted?: boolean
  createdAtInitial?: string
  createdAtFinal?: string
}

export const getOrdersServicesAgriculturalsInputsDashboard = async (
  params: GetParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<Response>(
    getOrdersServicesAgriculturalsInputsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

export const getOrdersServicesAgriculturalsInputsDashboardHook = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<Response>(
    getOrdersServicesAgriculturalsInputsDashboardGQL,
    {
      take,
      skip,
    },
  )
}

export const getOrdersServicesAgriculturalsInputsContainsDashboard = async (
  params: GetContainsParams,
) => {
  const take = 10
  const skip = (params?.page ?? 0) * take

  return await graphClient.request<ContainsResponse>(
    getOrdersServicesAgriculturalsInputsContainsDashboardGQL,
    {
      ...params,
      take,
      skip,
    },
  )
}

interface ResponseCompany {
  getCompaniesDashboard: {
    data: Company[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getCompaniesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseCompany>(getCompaniesDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseFarmField {
  getFarmsFieldsDashboard: {
    data: FarmField[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsFieldsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarmField>(
    getFarmsFieldsDashboardGQL,
    { take, skip },
  )
}

interface ResponseFarm {
  getFarmsDashboard: {
    data: Farm[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getFarmsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseFarm>(getFarmsDashboardGQL, {
    take,
    skip,
  })
}

interface ResponseOrderService {
  getOrdersServicesDashboard: {
    data: OrderService[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getOrdersServicesDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseOrderService>(
    getOrdersServicesDashboardGQL,
    { take, skip },
  )
}

interface ResponseAgriculturalInput {
  getAgriculturalsInputsDashboard: {
    data: AgriculturalInput[]
    error: string | null
    success: string | null
    totalPages: number | null
  }
}

export const getAgriculturalsInputsDashboard = async (
  page: number,
  optionalTake?: number,
) => {
  const take = optionalTake ?? 10
  const skip = page * take

  return await graphClient.request<ResponseAgriculturalInput>(
    getAgriculturalsInputsDashboardGQL,
    { take, skip },
  )
}
