import { httpClient } from 'services/http-client'

interface Response {
  data: string
  status: number
}

export const sendFile = async (body: FormData) => {
  const { data } = await httpClient.post<Response>('upload-file', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}
